import React, { useContext } from "react";
import * as BiIcon from "react-icons/bi";
import { Link, matchPath, useLocation, useParams } from "react-router-dom";
import { userRouteSlug } from "../../../../routes/userRoutes";
import { getPurePathname } from "../../../../utils/string.util";
import { UserTemplateContext } from "../Controller";

const BarItem = ({ item }) => {
  const location = useLocation();
  const params = useParams();
  const pathname = getPurePathname(location, params);

  const isActive = matchPath(pathname, userRouteSlug.PANEL + item?.path);

  const Icon = BiIcon[item?.icon];

  return (
    <Link to={userRouteSlug.PANEL + item?.path} className="py-3 flex-1 flex justify-center">
      <Icon className={`text-2xl ${isActive ? "text-red-500" : "text-gray-600"}`} />
    </Link>
  );
};

const MobileBar = () => {
  const { menu } = useContext(UserTemplateContext);

  return (
    <div className="w-full fixed bottom-0 flex bg-white" style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.05)" }}>
      {menu
        .filter((item) => item?.isShowMenu)
        .slice(0, 2)
        .map((item, iItem) => (
          <BarItem key={iItem} item={item} />
        ))}
      <Link to="#" className="py-3 flex-1 flex justify-center">
        <BiIcon.BiMenu className="text-2xl" />
      </Link>
    </div>
  );
};

export default MobileBar;
