import { Button } from "@chakra-ui/button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Hero from "../../../assets/img/static/hero-1.png";
import MobileFooter from "../../../assets/img/static/mobile-footer.png";
import { publicRouteSlug } from "../../../routes/publicRoutes";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-12 h-screen flex flex-col md:grid md:grid-cols-2 gap-x-10">
      <div className="p-8 px-8 md:px-20 flex-1 flex flex-col justify-center">
        <h1 className="montserrat font-bold text-4xl md:text-5xl lg:text-6xl text-gray-800 mb-2">E-Procurement</h1>
        <p className="italic md:text-lg text-gray-600">{t("TEXT.APP_DESCRIPTION")}</p>
        <div className="mt-8 flex flex-col md:flex-row gap-4">
          <Link to={publicRouteSlug.REGISTER}>
            <Button
              color="white"
              className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
            >
              {t("TEXT.BECOME_VENDOR")}
            </Button>
          </Link>
          <Link to={publicRouteSlug.LOGIN}>
            <Button colorScheme="red" variant="outline">
              {t("TEXT.LOGIN_WITH_ACCOUNT")}
            </Button>
          </Link>
        </div>
      </div>
      <div className="hidden md:flex justify-center items-center">
        <img src={Hero} className="w-full" />
      </div>
      <div className="md:hidden">
        <img src={MobileFooter} className="w-full h-24" />
      </div>
    </div>
  );
};

export default Home;
