import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ProcurementForm from "./ProcurementForm";
import ProcurementSettingForm from "./ProcurementSettingForm";

const General = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <ProcurementForm />
      <ProcurementSettingForm />
    </div>
  );
};

export default General;
