import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const RegisterContext = createContext();
const { Provider } = RegisterContext;

const RegisterController = ({ children }) => {
  // ------------------
  // Form
  // ------------------
  const basicInformationForm = useForm();
  const companyProfileForm = useForm({ companyPhoneCode: "+62", companyFaxCode: "+62" });
  const documentForm = useForm();
  const accountForm = useForm();

  // Complete Registration
  const [isRegisterComplete, setRegisterComplete] = useState(false);

  // Handle Submit
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const handleSubmit = () => {
    let form = {
      ...basicInformationForm.getValues(),
      ...companyProfileForm.getValues(),
      ...documentForm.getValues(),
      ...accountForm.getValues(),
    };

    const formData = new FormData();
    formData.append("payload", JSON.stringify(form));
    formData.append("taxAttachment", form?.taxAttachment?.[0]);
    form?.taxableEntAttachment && formData.append("taxableEntAttachment", form?.taxableEntAttachment[0]);

    setLoadingSubmit(true);
    axios
      .post("/company/register", formData)
      .then(() => {
        setRegisterComplete(true);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <Provider
      value={{
        basicInformationForm,
        companyProfileForm,
        documentForm,
        accountForm,

        handleSubmit,
        loadingSubmit,
        isComplete: isRegisterComplete,
      }}
    >
      {children}
    </Provider>
  );
};

export default RegisterController;
