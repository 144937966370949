import { useState } from 'react';

const AnnouncementPanel = ({ isClosable, title, description, className, isLoading, bg }) => {
	const [isShow, setIsShow] = useState(true);

	return (
		<div className={`${isLoading && "hidden"} ${bg?? 'bg-color0'} text-white rounded-md z-0 px-4 py-3 ${isShow ? 'flex' : 'hidden'} justify-between items-center transition-all duration-300 relative ${className}`}>
			<div className="flex flex-1 flex-col gap-1">
				{title && <span className="font-semibold text-base">{title}</span>}
				{description && <span>{description}</span>}
			</div>
      {
        isClosable && 
          <div
            onClick={() => {
              setIsShow(false);
            }}
            className="cursor-pointer select-none absolute top-3 right-3 flex h-4 w-4 justify-center items-center text-base"
          >
            &#x2716;
          </div>
      }
		</div>
	);
};

export default AnnouncementPanel;
