import React, { useContext } from "react";
import { motion } from "framer-motion";
import ReqProcSubmissionTable from "./components/ReqProcSubmissionTable";
import ReqProcSubmissionController, { ReqProcSubmissionContext } from "./Controller";
import ReqProcSubmissionNew from "./components/ReqProcSubmissionNew";
import ModalDelete from "./components/ModalDelete";
import ReqProcSubmissionDetail from "./components/ReqProcSubmissionDetail";
import { ProcurementDetailContext } from "../../../Controller";

const ReqProcSubmission = () => {
  const {
    isCreate,
    isDetailMenu
  } = useContext(ReqProcSubmissionContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
      {isCreate ? (
        <ReqProcSubmissionNew />
        ) : isDetailMenu ? (
          <ReqProcSubmissionDetail />
        ) : (
        <div className="flex flex-col gap-6">
          <ReqProcSubmissionTable />
        </div>
      )}

      <ModalDelete />
    </motion.div>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.reqProcSubmission){
    return <div></div>
  }

  return (
    <ReqProcSubmissionController>
      <ReqProcSubmission />
    </ReqProcSubmissionController>
  )
};
