import React from "react";

const MobileNavbar = () => {
  return (
    <div
      className="bg-white p-2 px-4 flex justify-center items-center"
      style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.03)" }}
    >
      <div className="montserrat text-2xl font-bold">
        <span className="bg-clip-text text-transparent bg-gradient-primary">Eproc</span>
        <span className="mx-1 text-gray-500 text-xs font-semibold uppercase tracking-widest">Panel</span>
      </div>
    </div>
  );
};

export default MobileNavbar;
