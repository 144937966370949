import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BusinessPermitContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import moment from "moment";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import { CompanyProfileContext } from "../../../../Controller";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { businessPermitTypes, isCreate, loadingCompany, activeBusinessPermit, form } =
    useContext(BusinessPermitContext);
    const { companyOverview } = useContext(CompanyProfileContext)
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.TAX_NUMBER") }) }),
    },
  });

  const businessPermitType = form.watch("businessPermitType");

  return (
    <>
      {/* Business Permit Type */}
      {companyOverview?.country === "Indonesia" ?
        <FormControl isRequired>
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
            {t("LABEL.BUSINESS_PERMIT_TYPE")}
          </FormLabel>        
            {isEdit ? (
              <Select
                filter
                control={control}
                isRequired
                // controlProps={{
                //   rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_PERMIT_TYPE") }) },
                // }}
                name="businessPermitType"
                options={businessPermitTypes?.map((businessPermitType) => ({
                  label: businessPermitType?.description,
                  value: businessPermitType?.businessPermitType,
                }))}
                placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_PERMIT_TYPE")}`}
                isError={!!errors?.businessPermitType}
              />
            ) : loadingCompany ? (
              <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
            ) : (
              <FormDescription>{activeBusinessPermit?.businessPermitTypeForeignKey?.description}</FormDescription>
            )}
          {errors?.businessPermitType && (
            <FormHelperText className="text-red-400 text-xs">{errors?.businessPermitType?.message}</FormHelperText>
            )}
        </FormControl>
      : 
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
            {t("LABEL.BUSINESS_PERMIT_TYPE")}
          </FormLabel>        
            {isEdit ? (
              <InputText
                className={`w-full text-sm`}
                control={control}
                placeholder={t("LABEL.BUSINESS_PERMIT_TYPE")}
                {...register("businessPermitType")}
              />
            ) : (
              <FormDescription>{activeBusinessPermit?.businessPermitType}</FormDescription>
            )}          
        </FormControl>
      }

      {/* <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BUSINESS_PERMIT_TYPE")}
        </FormLabel>
        {companyOverview?.country !== "Indonesia" ? 
          isEdit ? (
            <InputText
              className={`w-full text-sm`}
              control={control}
              placeholder={t("LABEL.BUSINESS_PERMIT_TYPE")}
              {...register("businessPermitType")}
            />
          ) : (
            <FormDescription>{activeBusinessPermit?.businessPermitType}</FormDescription>
          )
          : isEdit ? (
            <Select
              filter
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_PERMIT_TYPE") }) },
              }}
              name="businessPermitType"
              options={businessPermitTypes.map((businessPermitType) => ({
                label: businessPermitType?.description,
                value: businessPermitType?.businessPermitType,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_PERMIT_TYPE")}`}
              isError={!!errors?.businessPermitType}
            />
          ) : loadingCompany ? (
            <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
          ) : (
            <FormDescription>{activeBusinessPermit?.businessPermitTypeForeignKey?.description}</FormDescription>
          ) }

        {errors?.businessPermitType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.businessPermitType?.message}</FormHelperText>
        )}
      </FormControl> */}

      {/* Business Permit Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BUSINESS_PERMIT_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.businessPermitNumber && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.BUSINESS_PERMIT_NUMBER")}
            {...register("businessPermitNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_PERMIT_NUMBER") }),
            })}
          />
        ) : (
          <FormDescription>{activeBusinessPermit?.businessPermitNumber}</FormDescription>
        )}
        {errors?.businessPermitNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.businessPermitNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Business Group */}
      {companyOverview?.country !== "Indonesia" ? 
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
            {t("LABEL.BUSINESS_GROUP")}
          </FormLabel>          
            {isEdit ? (
              <InputText
                className={`w-full text-sm`}
                control={control}
                placeholder={t("LABEL.BUSINESS_GROUP")}
                {...register("businessGroup")}
              />
            ) : loadingCompany ? (
              <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
            ) : (
              activeBusinessPermit?.businessGroup ? 
                <FormDescription>{activeBusinessPermit?.businessGroup}</FormDescription>
              : <FormDescription>-</FormDescription>
            )}
        </FormControl>
        :
        <FormControl isRequired>
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
            {t("LABEL.BUSINESS_GROUP")}
          </FormLabel>
            {isEdit ? (
                <Select
                  filter
                  control={control}
                  isRequired
                  // controlProps={{
                  //   rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_GROUP") }) },
                  // }}
                  name="businessGroup"
                  options={[
                    { label: t("LABEL.SMALL"), value: "SMALL" },
                    { label: t("LABEL.MEDIUM"), value: "MEDIUM" },
                    { label: t("LABEL.LARGE"), value: "LARGE" },
                  ]}
                  placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_GROUP")}`}
                  isError={!!errors?.businessGroup}
                />
              ) : loadingCompany ? (
                <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
              ) : (
                activeBusinessPermit?.businessGroup ? 
                  <FormDescription>{t(`LABEL.${activeBusinessPermit?.businessGroup}`)}</FormDescription>
                : <FormDescription>-</FormDescription>
            )}
          {errors?.businessGroup && (
            <FormHelperText className="text-red-400 text-xs">{errors?.businessGroup?.message}</FormHelperText>
          )}
        </FormControl> 
        }

      {/* <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BUSINESS_GROUP")}
        </FormLabel>
        {companyOverview?.country !== "Indonesia" ? 
          isEdit ? (
            <InputText
              className={`w-full text-sm`}
              control={control}
              placeholder={t("LABEL.BUSINESS_GROUP")}
              {...register("businessGroup")}
            />
          ) : loadingCompany ? (
            <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
          ) : (
            activeBusinessPermit?.businessGroup ? 
              <FormDescription>{activeBusinessPermit?.businessGroup}</FormDescription>
            : <FormDescription>-</FormDescription>
          )
          : 
          isEdit ? (
            <Select
              filter
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_GROUP") }) },
              }}
              name="businessGroup"
              options={[
                { label: t("LABEL.SMALL"), value: "SMALL" },
                { label: t("LABEL.MEDIUM"), value: "MEDIUM" },
                { label: t("LABEL.LARGE"), value: "LARGE" },
              ]}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_GROUP")}`}
              isError={!!errors?.businessGroup}
            />
          ) : loadingCompany ? (
            <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
          ) : (
            activeBusinessPermit?.businessGroup ? 
              <FormDescription>{t(`LABEL.${activeBusinessPermit?.businessGroup}`)}</FormDescription>
            : <FormDescription>-</FormDescription>
          )
        }
        {errors?.businessGroup && (
          <FormHelperText className="text-red-400 text-xs">{errors?.businessGroup?.message}</FormHelperText>
        )}
      </FormControl> */}

      {/* Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.BUSINESS_PERMIT") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.BUSINESS_PERMIT"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger("attachment");
            }}
          />
        ) : activeBusinessPermit?.attachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeBusinessPermit?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <span className="text-gray-300">{t("LABEL.EMPTY")}</span>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Valid From */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VALID_FROM")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="validFrom"
            placeholder={t("LABEL.VALID_FROM")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.VALID_FROM") }) },
            }}
            isError={!!errors?.validFrom}
          />
        ) : (
          <FormDescription>{moment(new Date(activeBusinessPermit?.validFrom)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.validFrom && (
          <FormHelperText className="text-red-400 text-xs">{errors?.validFrom?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Valid Until */}
      <FormControl isRequired={businessPermitType !== "NIB"}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VALID_UNTIL")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="validUntil"
            placeholder={t("LABEL.VALID_UNTIL")}
            control={control}
            controlProps={{
              rules: {
                required: {
                  value: businessPermitType !== "NIB",
                  message: t("INVALID.REQUIRED", { field: t("LABEL.VALID_UNTIL") }),
                },
              },
            }}
            isError={!!errors?.validUntil}
            disabled={businessPermitType === "NIB"}
          />
        ) : (
          <FormDescription>
            {activeBusinessPermit?.validUntil &&
              moment(new Date(activeBusinessPermit?.validUntil)).format("DD/MM/YYYY")}
          </FormDescription>
        )}
        {errors?.validUntil && (
          <FormHelperText className="text-red-400 text-xs">{errors?.validUntil?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Issued By */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ISSUED_BY")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.issuedBy && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ISSUED_BY")}
            {...register("issuedBy", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.ISSUED_BY") }),
            })}
          />
        ) : (
          <FormDescription>{activeBusinessPermit?.issuedBy}</FormDescription>
        )}
        {errors?.issuedBy && (
          <FormHelperText className="text-red-400 text-xs">{errors?.issuedBy?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
