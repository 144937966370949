import React, { useContext, useState, useEffect} from "react";
import { Checkbox, FormControl, FormHelperText, FormLabel, InputGroup, InputRightElement, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RequisitionerContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../components/inputForm/FormDescription";
import axios from "axios";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import { BiCheckDouble, BiError } from "react-icons/bi";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { activeRequisitioner, form, setIsValidRequisitionerCode, isValidRequisitionerCode, loadingValidationRequisitionerCode, setLoadingValidationRequisitionerCode} = useContext(RequisitionerContext);
  const { token } = useContext(AuthContext);

  const {
    register,
    formState: { errors },
    setError,
    clearErrors
  } = form;

  const requisitionerCode = form.watch('requisitionerCode');

  useEffect(() => {
    if(!isEdit) return;
    
    if (!requisitionerCode){
      setIsValidRequisitionerCode(true);
    }
    else{
      if (requisitionerCode == ""){
        setIsValidRequisitionerCode(true);
        return;
      }
      setLoadingValidationRequisitionerCode(true);
      axios
      .post(`${process.env.REACT_APP_TMS_API}/requisitioner/is-exist-code/${requisitionerCode}`, '',{ headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const req = res.data.requisitioner;
        if (!req){
          setIsValidRequisitionerCode(true);  
          return;
        }

        if (!activeRequisitioner){
          setIsValidRequisitionerCode(false);
          return;
        }

        if (activeRequisitioner.requisitionerId == req.requisitionerId){
          setIsValidRequisitionerCode(true);
          return;
        }

        setIsValidRequisitionerCode(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        setIsValidRequisitionerCode(false);
      })
      .finally(() => {
        setLoadingValidationRequisitionerCode(false);
      });
    }

  }, [requisitionerCode, isEdit])

  useEffect(() => {
    if (!isValidRequisitionerCode && isEdit){
      setError('requisitionerCode', {type: 'custom', message: t("INVALID.AVAILABLE", { field: t("LABEL.REQUISITIONER_CODE") })});
      return;
    }

    clearErrors('requisitionerCode');
  }, [isValidRequisitionerCode, isEdit]);

  return (
    <>
      {/* Requisitioner Code */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.REQUISITIONER_CODE")}
        </FormLabel>
        {isEdit ? (
          <InputGroup>
          <InputText
            className={`w-full text-sm ${errors?.requisitionerCode && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.REQUISITIONER_CODE")}
            {...register("requisitionerCode", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.REQUISITIONER_CODE") }),
            })}
          />
          <InputRightElement className="h-full">
            {
              (!requisitionerCode || requisitionerCode === "") ?
              (
                <></>
              ) :
              (
                <>
                  {
                    loadingValidationRequisitionerCode ? 
                    (
                      <Spinner color="red.500" />
                    ) :
                    (
                      <>
                        {
                          isValidRequisitionerCode ? 
                          (
                            <BiCheckDouble color="green" className="text-2xl" />
                          ) :
                          (
                            <BiError color="red" className="text-2xl" />
                          )
                        }
                      </>
                    )
                  }
                </>
              )
            }
          </InputRightElement>
        </InputGroup>
          
        ) : (
          <FormDescription>{activeRequisitioner?.requisitionerCode}</FormDescription>
        )}
        {errors?.requisitionerCode && (
          <FormHelperText className="text-red-400 text-xs">{errors?.requisitionerCode?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Description */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DESCRIPTION")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.description && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DESCRIPTION")}
            {...register("description", { required: t("INVALID.REQUIRED", { field: t("LABEL.DESCRIPTION") }) })}
          />
        ) : (
          <FormDescription>{activeRequisitioner?.description}</FormDescription>
        )}
        {errors?.description && (
          <FormHelperText className="text-red-400 text-xs">{errors?.description?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
