import moment from 'moment';
import { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { ProcurementDetailContext } from '../../../../Controller';
import { API_URLS } from '../../../../../../../../../utils/apiURLs.util';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../../controllers/HelperController';
import { OFFER_2_COVER_PHASES, OFFER_2_STEP_PHASES } from '../../../../../../../../../utils/offerPhases.util';
import { useTranslation } from 'react-i18next';

export const ProcurementOfferEvaluationContext = createContext();
const { Provider } = ProcurementOfferEvaluationContext;

const ProcurementOfferEvaluationController = ({ children }) => {
	const { t } = useTranslation();
	const { userData } = useContext(AuthContext);
	const { procurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);

	const [activePageIdx, setActivePageIdx] = useState(0);
	const [administratifOffers, setAdministratifOffers] = useState(null);
	const [commercialOffers, setCommercialOffers] = useState(null);
	const [loadingAdministratifOffers, setLoadingAdministratifOffers] = useState(false);
	const [loadingCommercialOffers, setLoadingCommercialOffers] = useState(false);
	const [procurementVendors, setProcurementVendors] = useState(null);
	const [loadingProcurementVendors, setLoadingProcurementVendors] = useState(false);
	const [handleDetailCommercialIdx, setHandleDetailCommercialIdx] = useState(null);
	const [handleDetailAdministratifIdx, setHandleDetailAdministratifIdx] = useState(null);

	const [materials, setMaterials] = useState(null);
	const [loadingMaterials, setLoadingMaterials] = useState(null);

	useEffect(async () => {
		if (!procurement) return;

		let reqRequisitionCodes = '';
		procurement.procurementRequisitionMaterials.forEach((req, idx) => {
			const id = req.requisitionMaterial.reqRequisitionMaterialId;
			if (idx !== procurement.procurementRequisitionMaterials.length - 1) {
				reqRequisitionCodes += id + ';';
			} else {
				reqRequisitionCodes += id;
			}
		});

		let mounted = true;

		setLoadingMaterials(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/req-requisition/by-code?code=${reqRequisitionCodes}`,
		});
		if (!mounted) return;
		setLoadingMaterials(false);

		if (!response?.reqRequisitions) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		const reqRequisitions = response.reqRequisitions;
		if (reqRequisitions.length > 0) {
			let mats = [];
			for (let j = 0; j < reqRequisitions.length; j++) {
				const req = reqRequisitions[j];
				for (let i = 0; i < req.reqRequisitionMaterial.length; i++) {
					const reqMat = req.reqRequisitionMaterial[i];
					const tempProcurementRequisitionMaterial = procurement.procurementRequisitionMaterials.find((procurementRequisitionMaterial) => {
						return procurementRequisitionMaterial.requisitionMaterial.reqRequisitionMaterialId === reqMat.reqRequisitionMaterialId;
					});

					const temp = {
						id: reqMat.material.materialId,
						qty: reqMat.quantity,
						price: reqMat.price,
						description: reqMat.material.description,
						unit: reqMat.material.measureUnit?.unit,
						unitDescription: reqMat.material.measureUnit?.description,
						currency: reqMat.currency,
						procurementRequisitionMaterialId: tempProcurementRequisitionMaterial.procurementRequisitionMaterialId,
					};
					mats.push(temp);
				}
			}
			setMaterials(mats);
		}

    return () => mounted = false;
	}, [procurement]);

	useEffect(async () => {
		if (!userData || !procurement) return;

		let mounted = true;
		const procNum = procurement.procurementNumber;

		setLoadingAdministratifOffers(true);
		setAdministratifOffers(null);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-administration-offer/by/${procNum}/`,
		});
		if (!mounted) return;
		setLoadingAdministratifOffers(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		setAdministratifOffers(response.data.procurementAdministrationOffers);

		return () => {
			mounted = false;
		};
	}, [procurement, userData]);

	useEffect(async () => {
		if (!userData || !procurement) return;

		let mounted = true;
		const procNum = procurement.procurementNumber;

		setLoadingCommercialOffers(true);
		setCommercialOffers(null);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-commercial-offer/by/${procNum}/`,
		});
		if (!mounted) return;
		setLoadingCommercialOffers(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		setCommercialOffers(response.data.procurementCommercialOffers);

		return () => {
			mounted = false;
		};
	}, [procurement, userData]);

	// PROCUREMENT SCHEDULE
	const [procurementSchedule, setProcurementSchedule] = useState(null);
	const [loadingProcurementSchedule, setLoadingProcurementSchedule] = useState(false);
	useEffect(async () => {
		if (!procurement) return;
		let mounted = true;
		setLoadingProcurementSchedule(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule/${procurement.procurementNumber}`,
		});
		if (!mounted) return;
		setLoadingProcurementSchedule(false);

		if (!response?.procurementSchedule) {
			setProcurementSchedule(null);
			return;
		}

		setProcurementSchedule(response.procurementSchedule[0]);

		return () => mounted = false;
	}, [procurement]);

	// isEditScheduleExtend
	const [isEditScheduleExtend, setIsEditScheduleExtend] = useState(null);
	// PROCUREMENT EXTENDED SCHEDULE FOR OFFER
	const [extendedSchedule, setExtendedSchedule] = useState(null);
	const [loadingExtendedSchedule, setLoadingExtendedSchedule] = useState(false);
	useEffect(async () => {
		if (!procurement) return;
		let mounted = true;
		setLoadingExtendedSchedule(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule-extend-offer/proc/${procurement.procurementId}`,
		});
		if (!mounted) return;
		setLoadingExtendedSchedule(false);
		if (!response?.success) {
			// TODO: Error
			return;
		}

		setExtendedSchedule(response?.data.procurementScheduleExtendOffer);
		return () => (mounted = false);
	}, [procurement]);

	// VALID SCHEDULE
	const [schedule, setSchedule] = useState(null);
	const [loadingSchedule, setLoadingSchedule] = useState(false);
	useEffect(() => {
		if (loadingProcurementSchedule || loadingExtendedSchedule) {
			setLoadingSchedule(true);
			return;
		} else {
			setLoadingSchedule(false);
		}

		if (!extendedSchedule) {
			setSchedule(procurementSchedule);
			return;
		}
		setSchedule(extendedSchedule);
	}, [procurementSchedule, extendedSchedule, loadingProcurementSchedule, loadingExtendedSchedule]);

	// Offer type
	const [is2Cover, setIs2Cover] = useState(null);
	useEffect(() => {
		setIs2Cover(null);
		if (!procurement) return;

		setIs2Cover(procurement.bidEntryMethod?.bidEntryMethod === '2 COVER');
	}, [procurement]);

	// OFFER EVALUATION PHASE
	const [offerEvaluationPhase, setOfferEvaluationPhase] = useState(OFFER_2_COVER_PHASES.PRA);
	useEffect(() => {
		setOfferEvaluationPhase(OFFER_2_COVER_PHASES.PRA);
		if (!schedule || is2Cover === null) return;

		if (is2Cover) {
			const start = schedule.tenderEvaluationStart;
			const end = schedule.tenderEvaluationEnd;

			if (!start || !end) {
				return;
			}

			const startDate = moment(start).unix();
			const endDate = moment(end).unix();
			const nowDate = moment().unix();

			if (nowDate < startDate) {
				setOfferEvaluationPhase(OFFER_2_COVER_PHASES.PRA);
				return;
			}
			if (nowDate >= startDate && nowDate < endDate) {
				setOfferEvaluationPhase(OFFER_2_COVER_PHASES.TIME);
				return;
			}
			setOfferEvaluationPhase(OFFER_2_COVER_PHASES.POST);
			return;
		}

		const startTech = schedule.technicalQuotationStart;
		const endTech = schedule.technicalQuotationEnd;
		const startComm = schedule.commercialQuotationStart;
		const endComm = schedule.commercialQuotationEnd;

		const startEvaluation = schedule.tenderEvaluationStart;
		const endEvaluation = schedule.tenderEvaluationEnd;

		let phase = OFFER_2_STEP_PHASES.PRA;

		if (!endTech || !startTech) {
			// console.log(1);
			phase = OFFER_2_STEP_PHASES.PRA;
		} else {
			// const startTechDate = moment(startTech).unix();
			const endTechDate = moment(endTech).unix();
			const nowDate = moment().unix();

			if (nowDate < endTechDate) {
				phase = OFFER_2_STEP_PHASES.PRA;
			} else {
				phase = OFFER_2_STEP_PHASES.TECHNICAL;
				if (endComm && startComm) {
					// const startCommDate = moment(startComm).unix();
					const endCommDate = moment(endComm).unix();

					if (nowDate > endCommDate) {
						phase = OFFER_2_STEP_PHASES.COMMERCIAL;

						if (startEvaluation && endEvaluation) {
							if (nowDate > moment(endEvaluation).unix()) {
								phase = OFFER_2_STEP_PHASES.POST;
							}
						}
					}
				}
			}
		}

		setOfferEvaluationPhase(phase);
	}, [schedule]);

	// EVALUATION
	const [evaluation, setEvaluation] = useState(null);
	const [loadingEvaluation, setLoadingEvaluation] = useState(false);

	useEffect(async () => {
		setEvaluation(null);
		if (!procurement || offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA) {
			return;
		}

		let mounted = true;
		const id = procurement.procurementId;
		const uri = `${API_URLS.REACT_APP_TMS_API}/procurement-offer-evaluation/get-or-create/${id}`;
		setLoadingEvaluation(true);
		const { response } = await httpRequest({
			url: uri,
			method: 'POST',
		});
		if (!mounted) return;
		setLoadingEvaluation(false);

		if (!response?.success) {
			// TODO: Error
			return;
		}

		setEvaluation(response.data.procurementOfferEvaluation);
		return () => {
			mounted = false;
		};
	}, [procurement, offerEvaluationPhase]);

	return (
		<Provider
			value={{
				activePageIdx,
				setActivePageIdx,
				commercialOffers,
				setCommercialOffers,
				administratifOffers,
				setAdministratifOffers,
				loadingAdministratifOffers,
				setLoadingAdministratifOffers,
				loadingCommercialOffers,
				setLoadingCommercialOffers,
				procurementVendors,
				setProcurementVendors,
				loadingProcurementVendors,
				setLoadingProcurementVendors,

				materials,
				setMaterials,
				loadingMaterials,
				setLoadingMaterials,

				evaluation,
				setEvaluation,
				loadingEvaluation,
				setLoadingEvaluation,

				procurementSchedule,
				setProcurementSchedule,
				loadingProcurementSchedule,
				setLoadingProcurementSchedule,
				offerEvaluationPhase,
				setOfferEvaluationPhase,

				is2Cover,
				setIs2Cover,

				loadingExtendedSchedule,
				setLoadingExtendedSchedule,
				extendedSchedule,
				setExtendedSchedule,

				isEditScheduleExtend,
				setIsEditScheduleExtend,
				loadingSchedule,
				setLoadingSchedule,
				schedule,
				setSchedule,

				handleDetailCommercialIdx,
				setHandleDetailCommercialIdx,
				handleDetailAdministratifIdx,
				setHandleDetailAdministratifIdx,
			}}
		>
			{children}
		</Provider>
	);
};

export default ProcurementOfferEvaluationController;
