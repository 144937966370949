import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { ProcurementContext } from "../Controller";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../../../routes/userRoutes";
import { AuthContext } from "../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../components/PrivateComponent";

const Row = ({ data }) => {
  const {t} = useTranslation();
  const { isGm, isSgm } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDetail = () => {
    navigate(
      userRouteSlug.PANEL +
        userRouteSlug.PROCUREMENT +
        `/${data?.procurementNumber}/detail/${isGm || isSgm ? "approval" : "general"}`
    );
  };

  const isCancelled = !!data?.approvedProcurementObjection;

  return (
    <Tr className={`hover:bg-gray-50 cursor-pointer`} onClick={handleDetail}>
      <Td pr={0} className={`${isCancelled? "text-red-500" : ""}`}>{data?.procurementNumber}</Td>
      <Td style={{maxWidth: "400px"}} className={`${isCancelled? "text-red-500" : ""}`}>{data?.title}</Td>
      <Td className={`${isCancelled? "text-red-500" : ""}`}>{moment(data?.createdAt).format("DD/MM/YYYY")}</Td>
      <Td style={{minWidth: "110px", maxWidth: "110px"}} className={`${isCancelled? "text-red-500" : ""}`}>
        <div className="h-full flex justify-end items-center relative">
          {isCancelled && <span className="absolute right-7 font-semibold italic">{t("LABEL.CANCELLED")}</span>}
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const ProcurementTable = () => {
  const { procurements, loadingProcurement, totalData, limit, tableForm, handleCreate } =
    useContext(ProcurementContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <PrivateComponent accessName="BUYER_EDIT">
        <div className="mb-2 flex justify-end">
          <Button size="sm" onClick={() => handleCreate(true)}>
            <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: t("LABEL.PROCUREMENT") })}
          </Button>
        </div>
      </PrivateComponent>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th style={{minWidth: "100px", }} pr={0} className="text-red-500">{t("LABEL.PROCUREMENT_NUMBER")}</Th>
            <Th className="text-red-500">{t("LABEL.PROCUREMENT_TITLE")}</Th>
            <Th className="text-red-500">{t("LABEL.CREATED_AT")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingProcurement ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !procurements.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            procurements.map((procurement, iProcurement) => <Row key={iProcurement} data={procurement} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default ProcurementTable;
