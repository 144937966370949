import { Spinner, Table, Tbody, Td, Th, Thead, Tr, Button } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OpenContext } from '../Controller';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { userRouteSlug } from '../../../../../../routes/userRoutes';
import { Pagination } from 'rsuite';

const Row = ({ data }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleDetail = () => {
		navigate(userRouteSlug.PANEL + userRouteSlug.PROCUREMENT + `/${data?.procurement?.procurementNumber}/detail/general`);
	};

	const now = moment().unix();
	const start = moment(data?.procurement?.procurementSchedules?.registrationStart).unix();
	const end = moment(data?.procurement?.procurementSchedules?.registrationEnd).unix();

	const canRegister = now >= start && now <= end;

	return (
		<Tr
			onClick={() => {
				if (!data?.isJoined) return;
				handleDetail();
			}}
			className={`hover:bg-gray-50 ${data?.isJoined ? 'cursor-pointer' : ''}`}
		>
			<Td>{data?.procurement?.procurementNumber}</Td>
			<Td style={{ maxWidth: '470px' }}>{data?.procurement?.title}</Td>
			<Td>{moment(data?.procurement?.procurementSchedules?.registrationEnd).format('LLL')}</Td>
			<Td>
				{data?.isJoined ? (
					<div className="text-center text-sm font-semibold italic">Tergabung</div>
				) : data?.isSuggested ? (
					<div className="flex flex-col items-center gap-2">
						<div className="text-center">Anda telah diundang</div>
						<Button onClick={handleDetail} className="text-xs" colorScheme="teal" size="xs">
							Lihat undangan
						</Button>
					</div>
				) : (
					<div className="flex flex-col items-center">
						<Button isDisabled={!canRegister} onClick={handleDetail} className="text-xs" colorScheme="teal" size="sm">
							Daftar Sekarang
						</Button>
					</div>
				)}
			</Td>
		</Tr>
	);
};

const OpenTable = () => {
	const { procurements, loadingProcurements, tableForm, pagination } = useContext(OpenContext);
	const { t } = useTranslation();

	const watchTableForm = tableForm.watch();

	return (
		<div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th className="text-red-500">{t('LABEL.PROCUREMENT_NUMBER')}</Th>
						<Th className="text-red-500">{t('LABEL.PROCUREMENT_TITLE')}</Th>
						<Th className="text-red-500">{t('LABEL.REGISTER_DATE_END')}</Th>
						<Th className="text-red-500 text-center">{t('LABEL.ACTION')}</Th>
						<Th />
					</Tr>
				</Thead>
				<Tbody>
					{loadingProcurements ? (
						<Tr>
							<Td colSpan={5}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					) : !procurements.length ? (
						<Tr>
							<Td colSpan={5} className="text-center">
								<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
							</Td>
						</Tr>
					) : (
						procurements.map((data, idx) => <Row key={idx} data={data} />)
					)}
				</Tbody>
			</Table>

			<div className="mt-3 flex justify-end">
				<Pagination
					next
					prev
					ellipsis
					boundaryLinks
					maxButtons={5}
					total={pagination?.totalData}
					limit={pagination?.limit}
					activePage={watchTableForm?.page}
					onChangePage={(page) => tableForm.setValue('page', page)}
				/>
			</div>
		</div>
	);
};

export default OpenTable;
