import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OfferContext } from "../Controller";
import { BsFillCaretRightFill, BsClock, BsCheck, BsXLg } from "react-icons/bs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../../../../../routes/userRoutes";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDetail = () => {
    navigate(userRouteSlug.PANEL + userRouteSlug.PROCUREMENT + `/${data?.procurementNumber}/detail/general`);
  };

  const status = data?.procurementVendors[0]?.status.status;
  const vendorStatus = {
    pending: { labelI18n: "LABEL.PENDING", icon: BsClock, color: "text-yellow-500" },
    accepted: { labelI18n: "LABEL.ACCEPTED", icon: BsCheck, color: "text-green-500" },
    rejected: { labelI18n: "LABEL.REJECTED", icon: BsXLg, color: "text-red-500" },
  };
  const activeStatus =
    status === "DRAFT" ? vendorStatus.pending : status === "ACCEPT" ? vendorStatus.accepted : vendorStatus.rejected;

  return (
    <Tr className="hover:bg-gray-50 cursor-pointer" onClick={handleDetail}>
      <Td>{data?.procurementNumber}</Td>
      <Td style={{ maxWidth: '470px' }} >{data?.title}</Td>
      <Td>{moment(data?.procurementSchedules[0]?.dateEnd).format("DD/MM/YYYY")}</Td>
      <Td>
        <div className={`flex gap-2 items-center font-semibold ${activeStatus.color}`}>
          <span>{t(activeStatus.labelI18n)}</span>
          <activeStatus.icon />
        </div>
      </Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const OfferTable = () => {
  const { procurements, loadingProcurement } = useContext(OfferContext);
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.PROCUREMENT_NUMBER")}</Th>
            <Th className="text-red-500">{t("LABEL.PROCUREMENT_TITLE")}</Th>
            <Th className="text-red-500">{t("LABEL.REGISTER_DATE_END")}</Th>
            <Th className="text-red-500">{t("Status")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingProcurement ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !procurements.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            procurements.map((procurement, iProcurement) => <Row key={iProcurement} data={procurement} />)
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default OfferTable;
