import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BankAccountContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import { InputTextarea } from "primereact/inputtextarea";
import { CompanyProfileContext } from "../../../../Controller";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { isCreate, loadingCompany, activeBankAccount, form, banks, currencies, bankAccounts } =
    useContext(BankAccountContext);
  const { companyOverview } = useContext(CompanyProfileContext)
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Bank */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>{t("Bank")}</FormLabel>
        {companyOverview?.country !== "Indonesia" ? 
            isEdit ? (
              <InputText
                className={`w-full text-sm ${errors?.bank?.message && "border-2 border-red-400 shadow-none"}`}
                control={control}
                placeholder={t("LABEL.BANK_ACCOUNT")}
                {...register("bank", {
                  required: t("INVALID.REQUIRED", { field: t("LABEL.BANK_ACCOUNT") }),
                })}
              />
            ) : (
              <FormDescription>{activeBankAccount?.bank}</FormDescription>
            )
           : isEdit ? (
            <Select
              filter
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("Bank") }) },
              }}
              name="bank"
              options={banks.map((bank) => ({
                label: `${bank?.bankCode} - ${bank?.bankName}`,
                value: bank?.bankCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("Bank")}`}
              isError={!!errors?.bank}
            />
          ) : loadingCompany ? (
            <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
          ) : (
            <FormDescription>{activeBankAccount?.bankForeignKey?.bankName}</FormDescription>
        )}
        {errors?.bank && <FormHelperText className="text-red-400 text-xs">{errors?.bank?.message}</FormHelperText>}
      </FormControl>

      {/* Currency */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CURRENCY")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CURRENCY") }) },
            }}
            name="currency"
            options={currencies.map((currency) => ({
              label: `${currency?.symbol} - ${currency?.description}`,
              value: currency?.currency,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CURRENCY")}`}
            isError={!!errors?.currency}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeBankAccount?.currency?.description}</FormDescription>
        )}
        {errors?.currency && (
          <FormHelperText className="text-red-400 text-xs">{errors?.currency?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Account Holder Name */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ACCOUNT_HOLDER_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.accountHolderName && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ACCOUNT_HOLDER_NAME")}
            {...register("accountHolderName", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.ACCOUNT_HOLDER_NAME") }),
            })}
          />
        ) : (
          <FormDescription>{activeBankAccount?.accountHolderName}</FormDescription>
        )}
        {errors?.accountHolderName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.accountHolderName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Account Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ACCOUNT_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.accountNumber && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ACCOUNT_NUMBER")}
            {...register("accountNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.ACCOUNT_NUMBER") }),
            })}
          />
        ) : (
          <FormDescription>{activeBankAccount?.accountNumber}</FormDescription>
        )}
        {errors?.accountNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.accountNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Bank Address */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BANK_ADDRESS")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${errors?.bankAddress && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.BANK_ADDRESS")}
            {...register("bankAddress", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.BANK_ADDRESS") }),
            })}
          />
        ) : (
          <FormDescription>{activeBankAccount?.bankAddress}</FormDescription>
        )}
        {errors?.bankAddress && (
          <FormHelperText className="text-red-400 text-xs">{errors?.bankAddress?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Swift Code */}
      {companyOverview?.country !== "Indonesia" && 
        <FormControl isRequired>
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>Swift Code</FormLabel>                      
            {isEdit ? (
              <InputText
                className={`w-full text-sm ${errors?.swiftCode?.message && "border-2 border-red-400 shadow-none"}`}
                control={control}
                placeholder="Swift Code"
                {...register("swiftCode", {
                  required: t("INVALID.REQUIRED", { field: "Swift Code" }),
                })}
              /> 
            ) : (
              <FormDescription>{activeBankAccount?.swiftCode}</FormDescription>
            )}
          {errors?.swiftCode && <FormHelperText className="text-red-400 text-xs">{errors?.swiftCode?.message}</FormHelperText>}
        </FormControl>
      }
    </>
  );
};

export default Form;
