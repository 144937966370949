import React, { useContext } from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ModalReject from "./ModalReject";
import AdminApprovalController, { AdminApprovalContext } from "./Controller";
import { BiError } from "react-icons/bi";
import ModalApprove from "./ModalApprove";

const AdminApproval = () => {
  const { t } = useTranslation();
  const { rejectMessage, setModalRejectOpen, setModalApproveOpen } = useContext(AdminApprovalContext);

  return (
    <>
      <div className="md:col-span-4 p-4 rounded-md bg-white ">
        <div className="flex items-center gap-2">
          <div className="flex-1 flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <BiError className="text-lg text-yellow-500" />
              <p className="text-left flex-1 font-semibold">{t("TEXT.FINISH_APPROVING")}</p>
            </div>
          </div>
          <Button colorScheme="red" size="sm" onClick={() => setModalRejectOpen(true)}>
            {t("LABEL.REJECT")}
          </Button>
          <Button colorScheme="teal" size="sm" onClick={() => setModalApproveOpen(true)}>
            {t("LABEL.APPROVE")}
          </Button>
        </div>

        {rejectMessage && (
          <div className="text-xs">
            <hr className="my-2" />
            <p className="p-2 bg-gray-50 ">
              <span className="font-semibold">{t("TEXT.PREVIOUS_REJECT_MESSAGE")}: </span>
              {rejectMessage}
            </p>
          </div>
        )}
      </div>

      <ModalReject />
      <ModalApprove />
    </>
  );
};

export default () => (
  <AdminApprovalController>
    <AdminApproval />
  </AdminApprovalController>
);
