import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../controllers/auth/AuthController";

export const ProcurementContext = createContext();
const { Provider } = ProcurementContext;

const ProcurementController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token, roles } = useContext(AuthContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const reqRequisitionMaterialsForm = useForm({
    defaultValues: {
      reqRequisitionMaterials: [],
    },
  });
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        procurementNumber,

        procurementSetting: {
          eAuction: false,
          prequalification: false,
          bidBond: false,
          bidDocumentPublicVisibility: false,
          aanwijzing: false,
        },
      });
      reqRequisitionMaterialsForm.reset({
        reqRequisitionMaterials: [],
      });
    }
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadProcurement(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Procurement
  const [procurements, setProcurements] = useState([]);
  const [loadingProcurement, setLoadingProcurement] = useState(false);
  const [reloadProcurement, setReloadProcurement] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getProcurement = async () => {
      setLoadingProcurement(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newProcurement = res?.data?.procurements;
            setProcurements(newProcurement);
            setTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setReloadProcurement(false);
          setLoadingProcurement(false);
        });
    };

    token && reloadProcurement && getProcurement();

    return () => {
      mounted = false;
    };
  }, [token, reloadProcurement]);

  // ---------------------------
  // Master
  // ---------------------------

  // Procurement Number
  const [procurementNumber, setProcurementNumber] = useState();
  const [reloadProcurementNumber, setReloadProcurementNumber] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getProcurementNumber = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement/generate-proc-number`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setProcurementNumber(res?.data?.newProcurementNumber);
          }
        })
        .finally(() => {
          setReloadProcurementNumber(false);
        });
    };

    token && reloadProcurementNumber && roles.find((role) => role.role === "Buyer") && getProcurementNumber();

    return () => {
      mounted = false;
    };
  }, [token, roles, reloadProcurementNumber]);

  // Procurement Type
  const [procurementTypes, setProcurementTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/procurement-type`).then((res) => {
        if (mounted) {
          setProcurementTypes(res?.data?.procurementTypes);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Bid Entry Method
  const [bidEntryMethods, setBidEntryMethods] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/bid-entry-method`).then((res) => {
        if (mounted) {
          setBidEntryMethods(res?.data?.bidEntryMethods);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Contract Type
  const [contractTypes, setContractTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/contract-type`).then((res) => {
        if (mounted) {
          setContractTypes(res?.data?.contractTypes);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Incoterm
  const [incoterms, setIncoterms] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/incoterm`).then((res) => {
        if (mounted) {
          setIncoterms(res?.data?.incoterms);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Winner Evaluation Method
  const [winnerEvaluationMethods, setwinnerEvaluationMethods] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/winner-evaluation-method`).then((res) => {
        if (mounted) {
          setwinnerEvaluationMethods(res?.data?.winnerEvaluationMethods);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createProcurement = (payload) => {
    const reqRequisitions = reqRequisitionMaterialsForm.getValues();

    setLoadingCreate(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement`,
        {
          ...payload,
          ...reqRequisitions,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadProcurement(true);
        setReloadProcurementNumber(true);
      });
  };

  return (
    <Provider
      value={{
        tableForm,
        procurements,
        loadingProcurement,
        totalData,
        limit,

        procurementTypes,
        bidEntryMethods,
        contractTypes,
        incoterms,
        winnerEvaluationMethods,

        form,
        reqRequisitionMaterialsForm,

        isCreate,
        handleCreate,
        loadingCreate,
        createProcurement,
      }}
    >
      {children}
    </Provider>
  );
};

export default ProcurementController;
