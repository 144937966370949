import numeral from 'numeral';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCrown } from 'react-icons/bi';
import { ProcurementDetailContext } from '../Controller';

const ProcurementWinner = () => {
  const {t} = useTranslation();
	const { procurement, procurementWinner, anyObjectionApproved } = useContext(ProcurementDetailContext);

	return procurement?.procurementWinnerProposal?.isWinner ? (
		<div className={`p-4 rounded-md flex flex-col items-center ${!anyObjectionApproved ? 'bg-yellow-400' : 'bg-color0 text-white'}`}>
			<BiCrown className={`text-2xl ${anyObjectionApproved ? 'invisible' : ''}`} />
			<span className={`text-base font-semibold ${anyObjectionApproved? "italic" : ""}`}>{anyObjectionApproved ? t("LABEL.CANCELLED") : procurementWinner?.vendorDetail?.companyName}</span>
			<span className={`${anyObjectionApproved ? 'invisible' : ''}`}>
				{numeral(+procurementWinner?.finalPrice).format('0,0.00')} {procurement?.currency?.currency}
			</span>
		</div>
	) : (
		<></>
	);
};

export default ProcurementWinner;
