import { Tooltip } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTrash } from 'react-icons/bi';
import { BsArrowLeftCircle } from 'react-icons/bs';
import PrivateComponent from '../../../../../../../../../components/PrivateComponent';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { useObjectionContext } from '../Controller';
import EvaluationForm from './EvaluationForm';
import Form from './Form';
import ModalDelete from '../../../../../../../../../components/ModalDelete';
import { useState } from 'react';

const ObjectionDetail = () => {
	const { t } = useTranslation();
	const { isSgm } = useContext(AuthContext);
	const { setActiveObjectionIdx, activeObjectionIdx, objections, deleteObjection, loadingDelete } = useObjectionContext();
	const activeObjection = objections?.[activeObjectionIdx];

	const [openModal, setOpenModal] = useState(false);

	return (
		<div className="flex flex-col gap-6">
			<div className="w-full grid md:grid-cols-2 gap-6">
				<div className="md:col-span-2 flex justify-between items-center gap-2">
					<button
						onClick={() => {
							setActiveObjectionIdx(null);
						}}
					>
						<BsArrowLeftCircle className="text-xl text-gray-600" />
					</button>

					{!activeObjection?.procurementObjectionEvaluation && (
						<PrivateComponent accessName="BUYER_EDIT">
							<Tooltip hasArrow label={t('TOOLTIP.DELETE')} placement="top">
								<button
									onClick={() => {
										setOpenModal(true);
									}}
								>
									<BiTrash className="text-lg text-red-500" />
								</button>
							</Tooltip>
							<ModalDelete
								isOpen={openModal}
								onNo={() => setOpenModal(false)}
								onYes={() => deleteObjection(activeObjectionIdx)}
								title={t('LABEL.DELETE', { field: t('LABEL.PROCUREMENT_OBJECTION') })}
								body={t('TEXT.DELETE_CONFIRMATION', {
									field: t('LABEL.PROCUREMENT_OBJECTION'),
								})}
                loading={loadingDelete}
							/>
						</PrivateComponent>
					)}
				</div>

				<Form isEdit={false} />
			</div>
			{(isSgm || activeObjection?.procurementObjectionEvaluation) && <EvaluationForm />}
		</div>
	);
};

export default ObjectionDetail;
