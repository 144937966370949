import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { useContext } from 'react';
import { HelperContext } from '../../../../../../controllers/HelperController';
import { useState } from 'react';
import { API_URLS } from '../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../Controller';
import { AuthContext } from '../../../../../../controllers/auth/AuthController';
import ConfirmationModal from './sections/Offer/components/ConfirmationModal';

const VendorRegistration = () => {
	const { userData } = useContext(AuthContext);
	const { procurement, setVendorStatus, setVendorAccess } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const register = async () => {
		setLoading(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor/register/${procurement?.procurementNumber}`,
			method: 'POST',
			payload: { vendor: userData?.company?.registrationCode },
		});
		setLoading(false);

		if (!response?.procurementVendor?.status) return showToast(t('ERROR.undefined'), { success: false });

		showToast(t('INSERT-200'));
		setVendorAccess({ view: true, edit: true });
		setVendorStatus(response.procurementVendor.status);
	};

	return (
		<div className="p-4 rounded-md bg-white">
			<div className="w-full flex gap-4">
				<div className="flex-1 flex flex-col justify-center">
					<p className="font-semibold text-gray-600">Apakah anda ingin mendaftar di pengadaan ini?</p>
				</div>
				<div className="flex items-center gap-2">
					<Button onClick={() => setIsOpen(true)} size="sm" colorScheme="green" className="transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
						Daftar Sekarang
					</Button>
				</div>
			</div>

			<ConfirmationModal
				body="Pastikan anda sudah memeriksa semua kelengkapan dari pengadaan ini."
				title="Pendaftaran Pengadaan"
				loading={loading}
				onYes={register}
				isOpen={isOpen}
				onNo={() => setIsOpen(false)}
			/>
		</div>
	);
};

export default VendorRegistration;
