import { AiOutlinePaperClip } from 'react-icons/ai';

const FileAttachment = ({ href, disabled }) => {
	return (
		<a target="_blank" className={`py-1 px-4 border-2 border-gray-300 bg-white rounded-lg flex items-center hover:text-blue-500 text-sm ${disabled? 'pointer-events-none text-gray-400' : ''}`} style={{ width: 'fit-content' }} href={href}>
			<AiOutlinePaperClip className="text-lg" />
			Click to view
		</a>
	);
};

export default FileAttachment