import moment from "moment";
import React, { useContext } from "react";
import { Timeline } from "rsuite";
import { GoPrimitiveDot } from "react-icons/go";
import { ActivityContext } from "../Controller";
import { Spinner } from "@chakra-ui/react";

const ActivityList = () => {
  const { activities, loadingActivities, parseActivity, getActivities } = useContext(ActivityContext);

  // Handle Scroll
  const handleScroll = (e) => {
    const isBottomArea = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 50;
    if (isBottomArea) {
      getActivities();
    }
  };

  return (
    <div className="bg-white rounded-md p-4 h-full overflow-auto" onScroll={handleScroll}>
      <Timeline>
        {activities.map((activity, iActivity) => (
          <Timeline.Item
            dot={<GoPrimitiveDot className="text-blue-500 text-lg" style={{ marginLeft: "-4px", marginTop: "-5px" }} />}
            key={iActivity}
          >
            <div className="flex flex-col pb-2">
              <span className="text-xs text-gray-600">{moment(activity.createdAt).fromNow()}</span>
              <span className="text-base font-semibold my-0">{parseActivity(activity.title)}</span>
              {activity?.description && (
                <div className="p-2 bg-gray-50 rounded-md my-1">
                  <span className="text-xs">{activity?.description}</span>
                </div>
              )}
            </div>
          </Timeline.Item>
        ))}
      </Timeline>

      {loadingActivities && (
        <div className="flex justify-center">
          <Spinner color="red" />
        </div>
      )}
    </div>
  );
};

export default ActivityList;
