import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../controllers/auth/AuthController";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const ActivityContext = createContext();
const { Provider } = ActivityContext;

const ActivityController = ({ children }) => {
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const params = useParams();

  // Active Company
  const regCode = params?.regCode || userData?.company?.registrationCode;

  // Company
  const [currentCompany, setCurrentCompany] = useState();
  useEffect(() => {
    let mounted = true;
    const getCurrentCompany = () => {
      axios.get(`company/${regCode}`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
        mounted && setCurrentCompany(res?.data?.company);
      });
    };

    userData?.type !== "user" && token && regCode && getCurrentCompany();

    return () => {
      mounted = false;
    };
  }, [userData, token, regCode]);

  // Activities
  const [activities, setActivities] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLast, setLast] = useState(false);
  const getActivities = () => {
    if (loadingActivity || isLast) return;

    setLoadingActivity(true);
    axios
      .get(`activity/${regCode}?offset=${offset}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setActivities([...activities, ...res?.data?.activities]);
        setLast(res?.data?.isLast);
        setOffset(offset + 10);
      })
      .finally(() => {
        setLoadingActivity(false);
      });
  };
  useEffect(() => {
    userData && token && getActivities();
  }, [userData, token]);

  // Parse Activity
  const parseActivity = (text) => {
    let newText = text.replace("[y/n]", userData?.type === "user" ? "Anda" : currentCompany?.companyName);
    return newText;
  };

  return <Provider value={{ regCode, activities, loadingActivity, parseActivity, getActivities }}>{children}</Provider>;
};

export default ActivityController;
