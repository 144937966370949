import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/auth/AuthController";

const CandidateWaitApproval = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  return (
    <div>
      <div className="col-span-3 p-4 px-6 bg-gradient-primary text-white rounded-lg shadow-md">
        <p>
          <b>{t("TEXT.WELCOME_USER", { user: userData?.name })}</b> {t("TEXT.CANDIDATE_WAIT_APPROVAL")}
        </p>
      </div>
    </div>
  );
};

export default CandidateWaitApproval;
