import { useToast } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../Controller";

export const AanwijzingContext = createContext();
const { Provider } = AanwijzingContext;

const AanwijzingController = ({ children }) => {
  const toast = useToast();
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        eventName: newForm?.eventName,
        aanwijzingFrom: newForm?.aanwijzingFrom
          ? new Date(newForm?.aanwijzingFrom)
          : null,
        aanwijzingUntil: newForm?.aanwijzingUntil
          ? new Date(newForm?.aanwijzingUntil)
          : null,
        venue: newForm?.venue,
        notes: newForm?.notes,
        signeeName: newForm?.signeeName,
        signeePosition: newForm?.signeePosition,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeAanwijzing, setActiveAanwijzing] = useState();
  const handleDetailMenu = (isOpen, data) => {
    setDetailMenu(isOpen);
    setActiveAanwijzing({ ...data });
    isOpen && resetForm(data);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadAanwijzing(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);
  const [aanwijzing, setAanwijzing] = useState([]);
  const [aanwijzingSchedule, setAanwijzingSchedule] = useState([]);
  const [loadingAanwijzing, setLoadingAanwijzing] = useState(false);
  const [reloadAanwijzing, setReloadAanwijzing] = useState(true);

  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const limitx = `limit=${limit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limitx}&${page}`;

    const getAanwijzing = async () => {
      setLoadingAanwijzing(true);

      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/${procurementNumber}${filter}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newAanwijzing = res?.data?.procurementAanwijzing;
            const newAanwijzingSchedule =
              res?.data?.procurementAanwijzing[0]?.procurement
                .procurementSchedules;
            setAanwijzing(newAanwijzing);
            setAanwijzingSchedule(newAanwijzingSchedule);
            setTotalData(res?.data?.pagination?.totalData);
            if (activeAanwijzing) {
              const newActiveAanwijzing =
                newAanwijzing.find(
                  (aanwijzing) =>
                    aanwijzing?.procurementRequirementId ===
                    activeAanwijzing?.procurementRequirementId
                ) || null;
              setActiveAanwijzing(newActiveAanwijzing);
              resetForm(newActiveAanwijzing);
            }
          }
        })
        .finally(() => {
          setReloadAanwijzing(false);
          setLoadingAanwijzing(false);
        });
    };

    procurementNumber && reloadAanwijzing && getAanwijzing();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadAanwijzing]);

  // -------------------------------
  // Create
  // -------------------------------

  const [isCreate, setCreate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const createAanwijzing = (payload) => {
    setLoadingCreate(true);

    const newPayload = {
      eventName: payload?.eventName,
      aanwijzingFrom: moment(payload?.aanwijzingFrom).format(),
      aanwijzingUntil: moment(payload?.aanwijzingUntil).format(),
      venue: payload?.venue,
      notes: payload?.notes,
    };

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/${procurement.procurementNumber}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadAanwijzing(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeAanwijzing);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateAanwijzing = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/${activeAanwijzing?.procurementAanwijzingId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadAanwijzing(true);
      });
  };

  // -------------------------------
  // Delete
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const handleDelete = (isOpen) => {
    setModalDeleteOpen(isOpen);
  };
  const deleteAanwijzing = () => {
    setLoadingDelete(true);

    axios
      .delete(
        `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/${activeAanwijzing?.procurementAanwijzingId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        handleDelete(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadAanwijzing(true);
      });
  };

  //Announce
  const [isAnnounce, setAnnounce] = useState(false);
  const [loadingAnnounce, setLoadingAnnounce] = useState(false);
  const [isAnnounceOpen, setIsAnnounceOpen] = useState();
  const [announceStatus, setAnnounceStatus] = useState();

  const handleAnnounceOpen = (isOpen, status = null) => {
    setIsAnnounceOpen(isOpen);
    setAnnounceStatus(status);
  };

  const announceAanwijzing = (payload) => {
    setLoadingAnnounce(true);

    let mounted = true;
    let newBodyEmail = [{}];

    const subjectEmailAanwijzing =
      "Invitation to Join Aanwijzing E-Procurement";

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/announce/${activeAanwijzing?.procurementAanwijzingId}`,
        {
          isAnnounced: announceStatus,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        axios
          .get(
            `${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (mounted) {
              const newVendor = res?.data?.procurementVendors;
              axios
                .get(
                  `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/${procurementNumber}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((res) => {
                  if (mounted) {
                    const newAanwijzing = res?.data?.procurementAanwijzing;
                    newVendor
                      ?.filter((status) => status?.status?.status === "ACCEPT")
                      .map((vendors) => {
                        axios
                          .get(
                            `${process.env.REACT_APP_API}/user/${vendors?.vendor}`,
                            {
                              headers: { Authorization: `Bearer ${token}` },
                            }
                          )
                          .then((res) => {
                            const newPayloads = {
                              vendor: vendors?.company?.companyName,
                              title: procurement?.title,
                              procurementNumber: procurement?.procurementNumber,
                              procurementType:
                                procurement?.procurementType?.procurementType,
                              aanwijzingFrom: newAanwijzing[0]?.aanwijzingFrom,
                              aanwijzingUntil:
                                newAanwijzing[0]?.aanwijzingUntil,
                              notes: newAanwijzing[0]?.notes,
                              venue: newAanwijzing[0]?.venue,
                            };

                            if (
                              announceStatus === "announce" &&
                              newPayloads?.vendor
                            ) {
                              axios
                                .post(
                                  `${process.env.REACT_APP_TMS_API}/procurement-aanwijzing/${procurement.procurementNumber}/send-email-aanwijzing/${res?.data?.users[0]?.email}/${subjectEmailAanwijzing}`,
                                  newPayloads,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(t(res?.data?.success));
                                })
                                .catch(() => {
                                  toast({
                                    title: "System Error",
                                    description: t("ERROR.undefined"),
                                    position: "top-right",
                                    status: "error",
                                    isClosable: true,
                                  });
                                });
                            }
                          });
                        // )
                      });
                  }
                });
            }
          });
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingAnnounce(false);
        setReloadAanwijzing(true);
        setIsAnnounceOpen(false);
      });
  };

  return (
    <Provider
      value={{
        tableForm,
        aanwijzing,
        aanwijzingSchedule,
        loadingAanwijzing,
        totalData,
        limit,

        form,
        activeAanwijzing,

        isCreate,
        handleCreate,
        loadingCreate,
        createAanwijzing,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateAanwijzing,

        handleAnnounceOpen,
        announceStatus,
        isAnnounceOpen,
        isAnnounce,
        setAnnounce,
        loadingAnnounce,
        setLoadingAnnounce,
        announceAanwijzing,

        isModalDeleteOpen,
        handleDelete,
        loadingDelete,
        deleteAanwijzing,
        setModalDeleteOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export default AanwijzingController;
