import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../../utils/apiURLs.util';
import { ProcurementOfferEvaluationContext } from '../../Controller';

export const EvaluationContext = createContext();
const { Provider } = EvaluationContext;

const EvaluationController = ({ children, evaluationType }) => {
	const { token } = useContext(AuthContext);
	const { evaluation, setEvaluation } = useContext(ProcurementOfferEvaluationContext);
	const { httpRequest, showToast } = useContext(HelperContext);

	const [errorNotEvaluated, setErrorNotEvaluated] = useState('');
	const [isActionModalOpen, setIsActionModalOpen] = useState(false);

	useEffect(() => {
		setErrorNotEvaluated('');
	}, [evaluation]);

	const [loadingSubmitEvaluation, setLoadingSubmitEvaluation] = useState(false);
	const [evaluationNote, setEvaluationNote] = useState('');
	const [errorEvaluationNote, setErrorEvaluationNote] = useState('');

	useEffect(() => {
		setErrorEvaluationNote('');
	}, [evaluationNote]);

	const submitEvaluation = async () => {
		if (!evaluationNote || evaluationNote === '') {
			setErrorEvaluationNote('Catatan wajib diisi');
			return;
		}
		const data = {
			statusData: {
				status: !evaluationType ? 'SUBMITTED' : null,
				statusAdministration: evaluationType === 'ADM' ? 'SUBMITTED' : null,
				statusCommercial: evaluationType === 'COM' ? 'SUBMITTED' : null,
			},
			noteData: {
				note: !evaluationType ? evaluationNote : null,
				noteAdministration: evaluationType === 'ADM' ? evaluationNote : null,
				noteCommercial: evaluationType === 'COM' ? evaluationNote : null,
			},
			token,
		};

		const id = evaluation.id;
		const uri = `${API_URLS.REACT_APP_TMS_API}/procurement-offer-evaluation/submit/${id}`;
		setLoadingSubmitEvaluation(true);
		const { response } = await httpRequest({
			url: uri,
			method: 'PATCH',
			payload: data,
		});
		setLoadingSubmitEvaluation(false);

		if (!response?.success) {
			showToast('Evaluasi gagal dikirim', {
				success: false,
			});
			return;
		}

		setEvaluation(response.data.procurementOfferEvaluation);
		if (response && response.success) {
			setIsActionModalOpen(false);
		}
		showToast('Evaluasi berhasil dikirim');
	};

	return (
		<Provider
			value={{
				isActionModalOpen,
				setIsActionModalOpen,
				errorNotEvaluated,
				setErrorNotEvaluated,
				evaluationType,
				submitEvaluation,
				setEvaluationNote,
				errorEvaluationNote,
				loadingSubmitEvaluation,
				evaluationNote,
			}}
		>
			{children}
		</Provider>
	);
};

export default EvaluationController;
