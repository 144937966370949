import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProcurementDetailContext } from "../Controller";

const ModalVendorProcurementAcceptance = () => {
  const { t } = useTranslation();
  const { isAcceptanceOpen, acceptanceStatus, handleAcceptanceOpen, loadingSubmitAcceptance, submitAcceptance } =
    useContext(ProcurementDetailContext);

  return (
    <Modal onClose={() => handleAcceptanceOpen(false)} size="md" isOpen={isAcceptanceOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>
          {t(acceptanceStatus === "approve" ? "LABEL.APPROVE" : "LABEL.REJECT")} {t("LABEL.PROCUREMENT")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.SUBMIT_VENDOR_PROCUREMENT_ACCEPTANCE")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleAcceptanceOpen(false)}>{t("LABEL.CANCEL")}</Button>
          <Button
            isLoading={loadingSubmitAcceptance}
            onClick={submitAcceptance}
            colorScheme={acceptanceStatus === "approve" ? "green" : "red"}
            className="transition-all duration-500"
          >
            {t(acceptanceStatus === "approve" ? "LABEL.APPROVE" : "LABEL.REJECT")} {t("LABEL.PROCUREMENT")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalVendorProcurementAcceptance;
