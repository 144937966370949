import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PrintDocumentButton from "../../../../../../../../../components/PrintDocumentButton";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../../Controller";
import NextStepButton from "../../../NextStepButton";
import { ProcScheduleContext } from "../Controller";
import Form from "./Form";

const SchduleDetail = () => {
  const { t } = useTranslation();
  const { procurement, procurementType } = useContext(ProcurementDetailContext);
  const {
    isCreate,
    isCreatePrequalification,
    isCreateAanwijzing,
    isCreateQuotation,
    isCreateTechnicalQuotation,
    isCreateCommercialQuotation,
    isCreateSign,
    signForm,
    isSign,
    createProcScheduleMail, 
    loadingMail,
    schedules,
    scheduleStatus
  } = useContext(ProcScheduleContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = signForm;

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="bg-white rounded-md px-6 pb-4"
    >
      { procurementType === "DIRECT" && (
        <div className="flex justify-end pt-4">
          <PrintDocumentButton isDisabled={!scheduleStatus?.submittedAt || scheduleStatus?.shouldBeReview} label="Cetak Usulan Pengadaan" url={`juksung-proposal/${procurement?.procurementNumber}`} />
        </div>
      ) }
      <div className="md:col-span-2 flex justify-between items-center gap-2 pt-4 pb-8">
        <div className="w-full grid md:grid-cols-2 gap-6">
          <Form
            isEdit={isCreate}
            isEditPrequalification={isCreatePrequalification}
            isEditAanwijzing={isCreateAanwijzing}
            isEditQuotation={isCreateQuotation}
            isEditTechnicalQuotation={isCreateTechnicalQuotation}
            isEditCommercialQuotation={isCreateCommercialQuotation}
            isEditSign={isCreateSign}
          />
        </div>
      </div>
      {userData?.type !== "user" ? (
        <div className="flex justify-end items-center gap-4 mb-4">
          {
            scheduleStatus?.submittedAt && !scheduleStatus?.shouldBeReview &&  (
              <NextStepButton isDisabled={!schedules || !scheduleStatus?.submittedAt} to="approval" url="approval" className='mt-0' toLabel={`Approval`} />
            )
          }
          <PrivateComponent accessName="BUYER_EDIT">
            {
              !(scheduleStatus?.submittedAt && !scheduleStatus?.shouldBeReview) && (
                <Button
                  size="sm"
                  colorScheme="teal"
                  isLoading={loadingMail}
                  onClick={signForm.handleSubmit(createProcScheduleMail)}
                  disabled={isSign}
                >
                  {t("LABEL.SAVE")}
                </Button>
              )
            }
          </PrivateComponent>
        </div>
      ) : (
        ""
      )}
    </motion.div>
  );
};

export default SchduleDetail;
