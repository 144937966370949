import moment from 'moment';
import { Fade, ScaleFade, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaClipboardList, FaUserAlt } from 'react-icons/fa';
import SchedulePanel from '../../components/SchedulePanel';
import { ProcurementOfferEvaluationContext } from '../Controller';
import { ProcurementDetailContext } from '../../../../../Controller';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import TitleBar from '../../../../../../../../../../components/TitleBar';
import { filterBuilder } from '../../../../../../../../../../utils/filterBuilder';

const EvaluationOverviewPage = () => {
	const { t } = useTranslation();
	const { procurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);
	const { procurementVendors, setProcurementVendors, loadingProcurementVendors, setLoadingProcurementVendors, is2Cover, schedule, loadingSchedule, evaluation } =
		useContext(ProcurementOfferEvaluationContext);

	useEffect(async () => {
		if (!procurement) return;
		let mounted = true;

		const filter = filterBuilder({
			snippets: ['procurementAdministrationOffer', 'procurementCommercialOffer', 'procurementOfferEvaluationVendor', 'status', 'procurementVendorPrequalification'],
		});
  
		setLoadingProcurementVendors(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor/test/${procurement.procurementNumber}?${filter}`,
		});
		if (!mounted) return;
		setLoadingProcurementVendors(false);

		if (!response?.procurementVendors) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		const vendors = response.procurementVendors?.filter(
			(vendor) => vendor.status?.status === 'ACCEPT' && (procurement.procurementSetting?.prequalification ? vendor.procurementVendorPrequalification?.status === 'ACCEPTED' : true)
		);
		setProcurementVendors(vendors);

		return () => {
			mounted = false;
		};
	}, [procurement]);

	return (
		<div className="flex flex-col">
			<div>
				<TitleBar title={t('LABEL.PROCUREMENT_SCHEDULE')} icon={FaClipboardList} />
				<Fade in={true}>
					{is2Cover ? (
						<>
							<SchedulePanel className="" loading={loadingSchedule} title="" start={schedule?.quotationStart} end={schedule?.quotationEnd} />
						</>
					) : (
						<div className="flex flex-col gap-4">
							<SchedulePanel className="" loading={loadingSchedule} title={t('LABEL.TECHNICAL')} start={schedule?.technicalQuotationStart} end={schedule?.technicalQuotationEnd} type="ADM" />
							<SchedulePanel className="" loading={loadingSchedule} title={t('LABEL.COMMERCIAL')} start={schedule?.commercialQuotationStart} end={schedule?.commercialQuotationEnd} type="COM" />
						</div>
					)}
				</Fade>
			</div>
			<div className="mt-3">
				<TitleBar title={t('LABEL.VENDOR')} icon={FaUserAlt} />
				<Fade in={true}>
					<Table>
						<Thead>
							<Tr>
								<Th
									style={{
										minWidth: '75px',
										maxWidth: '75px',
										width: '75px',
									}}
									className="text-red-500"
								>
									No.
								</Th>
								<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
								<Th className="text-red-500">{t('LABEL.STATUS')}</Th>
								<Th className="text-red-500">{t('LABEL.LAST_QUOTATION', { type: t('LABEL.PROCUREMENT_TECHNICAL_TENDER') })}</Th>
								<Th className="text-red-500">{t('LABEL.LAST_QUOTATION', { type: t('LABEL.PROCUREMENT_COMMERCIAL_TENDER') })}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{loadingProcurementVendors ? (
								<Tr>
									<Td colSpan={5}>
										<div className="w-full h-full flex justify-center items-center">
											<Spinner color="red.500" />
										</div>
									</Td>
								</Tr>
							) : (
								<>
									{!procurementVendors ? (
										<Tr>
											<Td colSpan={5} className="text-center">
												<p className="text-sm text-gray-500">{t('ERROR.undefined')}</p>
											</Td>
										</Tr>
									) : procurementVendors?.length === 0 ? (
										<Tr>
											<Td colSpan={5} className="text-center">
												<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
											</Td>
										</Tr>
									) : (
										procurementVendors?.map((vendor, idx) => {
											return (
												<Tr key={idx}>
													<Td
														style={{
															minWidth: '75px',
															maxWidth: '75px',
															width: '75px',
														}}
													>
														{idx + 1}
													</Td>
													<Td>{vendor.company?.companyName}</Td>
													<Td className="uppercase">{vendor.status?.status === 'ACCEPT' ? t('LABEL.REGISTERED') : 'TIDAK MENDAFTAR'}</Td>
													<Td>
														{!vendor.procurementAdministrationOffer ? (
															<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
														) : vendor.procurementAdministrationOffer.status === 'SUBMITTED' ? (
															<div className="font-bold">{moment(vendor.procurementAdministrationOffer.updateAt).format('LLL')}</div>
														) : (
															<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
														)}
													</Td>
													<Td>
														{!vendor.procurementCommercialOffer ? (
															<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
														) : vendor.procurementCommercialOffer.status === 'SUBMITTED' ? (
															<div className="font-bold">{moment(vendor.procurementCommercialOffer.updateAt).format('LLL')}</div>
														) : (
															<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
														)}
													</Td>
												</Tr>
											);
										})
									)}
								</>
							)}
						</Tbody>
					</Table>
				</Fade>
			</div>
		</div>
	);
};

export default EvaluationOverviewPage;
