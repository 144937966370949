import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import VendorNew from "./components/VendorNew";
import VendorSchedule from "./components/VendorSchedule";
import VendorTable from "./components/VendorTable";
import VendorController, { VendorContext } from "./Controller";
import { ProcurementDetailContext } from "../../../Controller";
import AnnouncementPanel from "../../../../../../../../components/AnnouncementPanel";
import { vendorLimit } from "../../../../../../../../utils/vendorLimit";

const Vendor = () => {
  const { t, i18n } = useTranslation();
  const { isCreate, vendor, loadingVendor } = useContext(VendorContext);
  const { procurementType} = useContext(ProcurementDetailContext);

  const minVendor = vendorLimit[procurementType]?.SUGGESTED_MIN;
  const maxVendor = vendorLimit[procurementType]?.MAX;
  const isValid = vendor && vendor?.length >= minVendor 

  const procurementLabel = procurementType === "GENERAL"? (i18n.language === "id" ? "Seleksi Umum": "General Selection") : procurementType === "LIMITED" ? (i18n.language === "id" ? "Seleksi Terbatas": "Limited") : procurementType === "DIRECT" ?(i18n.language === "id" ? "Penunjukan Langsung": "Direct Selection"): "-"

  return (
    <>
      {isCreate ? (
        <VendorNew />
      ) : (
        <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
          {
            isValid === false && (
              minVendor !== maxVendor? (
                <AnnouncementPanel isLoading={loadingVendor} description={t("TEXT.The minimum number of vendors for this procurement is N vendors_DESCRIPTION", {
                  field: procurementLabel,
                  n: minVendor
                })} title={t("TEXT.The minimum number of vendors for this procurement is N vendors", {
                  field: minVendor
                })} />
              ) : (
                <AnnouncementPanel isLoading={loadingVendor} title={t("TEXT.The number of vendors for this procurement is N vendors_DESCRIPTION", {
                  field: procurementLabel,
                  n: minVendor
                })} />
              )
            )
          }
          <VendorSchedule />
          <VendorTable />
        </motion.div>
      )}
    </>
  );
};

export default () => (
  <VendorController>
    <Vendor />
  </VendorController>
);
