import { Button } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import Form from "./components/Form";
import { BiTrash } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import GeneralController, { GeneralContext } from "./Controller";
import ModalDelete from "./components/ModalDelete";
import PrivateComponent from "../../../../../../components/PrivateComponent";

const General = () => {
  const { t } = useTranslation();
  const [isEdit, setEdit] = useState(false);
  const { setModalDeleteOpen } = useContext(GeneralContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <div className="md:col-span-2 flex justify-end items-center gap-2">
          {isEdit ? (
            <div className="flex gap-2">
              <Button size="xs" onClick={() => setEdit(false)}>
                {t("LABEL.CANCEL")}
              </Button>
              <Button size="xs" colorScheme="teal">
                {t("LABEL.SAVE")}
              </Button>
            </div>
          ) : (
            <div className="flex gap-2">
              <PrivateComponent accessName="BUYER_EDIT">
                <button onClick={() => setModalDeleteOpen(true)}>
                  <BiTrash className="text-lg text-red-500" />
                </button>
              </PrivateComponent>
            </div>
          )}
        </div>

        <Form isEdit={isEdit} />
        <ModalDelete />
      </div>
    </motion.div>
  );
};

export default () => (
  <GeneralController>
    <General />
  </GeneralController>
);
