import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import PrintDocumentButton from "../../../../../../../../../components/PrintDocumentButton";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../../Controller";
import { NegotiationContext } from "../Controller";
import FormSchedule from "./Form/FormSchedule";

const NegotiationNew = () => {
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    form,
    handleNegotiationSessionNewOpen,
    loadingCreate,
    loadingUpdate,
    createNegotiation,
    isEdit,
    handleCreate,
    priceGroup,
    activeVendor,
  } = useContext(NegotiationContext);

  const { userData } = useContext(AuthContext);

  const isDone = priceGroup?.find((note) => note.status === "APPROVED");

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="bg-white rounded-md p-6"
    >
      <div className="w-full grid md:grid-cols-1 gap-6">
        <div className="md:col-span-2 flex justify-between items-center gap-2">
          <button onClick={() => handleCreate(false, null)}>
            <BsArrowLeftCircle className="text-xl text-gray-600" />
          </button>
          <div className="flex gap-4 items-center">
            <PrintDocumentButton isDisabled={!isDone} url={`ba-negotiation/${procurement?.procurementNumber}/${activeVendor?.companyRegCode}`} className={`${!procurement?.procurementWinnerProposal?.isWinner? "mr-8" : ""}`} label="Cetak Negosiasi" />

            {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
              isEdit ? (
                <PrivateComponent accessName="BUYER_EDIT">
                  <Button
                    size="sm"
                    disabled={isDone}
                    colorScheme="teal"
                    isLoading={loadingCreate}
                    onClick={form.handleSubmit(createNegotiation)}
                  >
                    {t("LABEL.SAVE")}
                  </Button>
                </PrivateComponent>
              ) : (
                <PrivateComponent accessName="BUYER_EDIT">
                  <Button
                    size="sm"
                    colorScheme="gray"
                    isLoading={loadingUpdate}
                    onClick={() => handleNegotiationSessionNewOpen(true)}
                    disabled={isEdit || isDone}
                  >
                    {t("LABEL.EDIT", {
                      field: t("LABEL.PROCUREMENT_NEGOTIATION"),
                    })}
                  </Button>
                </PrivateComponent>
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <FormSchedule />
      </div>
    </motion.div>
  );
};

export default NegotiationNew;
