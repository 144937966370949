import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VendorContext } from "../Controller";

const ModalDelete = () => {
  const { t } = useTranslation();
  const { isModalDeleteOpen, handleDelete, loadingDelete, deleteVendor } = useContext(VendorContext);

  return (
    <Modal onClose={() => handleDelete(false)} size="md" isOpen={isModalDeleteOpen}>
      <ModalOverlay />
      <ModalContent className="bg-red-500 text-white bg-opacity-80">
        <ModalHeader>{t("LABEL.DELETE", { field: t("LABEL.VENDOR") })}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.DELETE_CONFIRMATION", { field: t("LABEL.VENDOR") })}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleDelete(false)} colorScheme="whiteAlpha">
            {t("LABEL.CANCEL")}
          </Button>
          <Button isLoading={loadingDelete} onClick={deleteVendor} colorScheme="blackAlpha">
            {t("LABEL.DELETE", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDelete;
