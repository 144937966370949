import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VendorContext } from "../Controller";

const ModalPropose = () => {
  const { t } = useTranslation();
  const { isModalProposeOpen, handlePropose, loadingPropose, proposeWinner } = useContext(VendorContext);

  return (
    <Modal onClose={() => handlePropose(false)} size="md" isOpen={isModalProposeOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>{t("LABEL.PROPOSE_WINNER")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.PROPOSE_WINNER_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handlePropose(false)} colorScheme="whiteAlpha">
            {t("LABEL.CANCEL")}
          </Button>
          <Button isLoading={loadingPropose} onClick={proposeWinner} colorScheme="green">
            {t("LABEL.PROPOSE_WINNER")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPropose;
