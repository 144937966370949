import React, { Fragment, useContext } from "react";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import ReqRequisitionController, { ReqRequisitionContext } from "./Controller";
import { AuctionContext } from "../../../Controller";

const ReqRequisition = () => {
  const { auction } = useContext(AuctionContext);
  const auctionMaterials = auction?.auctionMaterials || [];
  const reqRequisitions = [];

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="flex flex-col">
        {auctionMaterials?.map((auctionMaterial, iAuctionMaterial) => (
          <div key={iAuctionMaterial} className="grid md:grid-cols-4 p-3" style={{ borderBottom: "1px solid #eee" }}>
            <span className="md:col-span-2 flex gap-2 items-center">
              <div className="w-2 h-2 rounded-full bg-red-500" />
              {auctionMaterial?.reqRequisitionMaterial?.material?.description}
            </span>
            <span>
              Qty: {auctionMaterial?.reqRequisitionMaterial?.quantity} {`${auctionMaterial?.reqRequisitionMaterial?.material?.measureUnit?.unit} (${auctionMaterial?.reqRequisitionMaterial?.material?.measureUnit?.description})`}
            </span>
            <span className="text-right font-semibold">
              {numeral(
                (auctionMaterial?.reqRequisitionMaterial?.price / auctionMaterial?.reqRequisitionMaterial?.valuation) *
                  auctionMaterial?.reqRequisitionMaterial?.quantity
              ).format("0,0.00")}{" "}
              {auctionMaterial?.reqRequisitionMaterial?.currency?.currency}
            </span>
          </div>
        ))}

        {reqRequisitions?.map((reqRequisition, iReqRequisition) => (
          <Fragment key={iReqRequisition}>
            <div className="flex justify-between p-3 bg-gray-50" style={{ borderBottom: "1px solid #eee" }}>
              <span>
                {reqRequisition?.reqRequisitionCode}{" "}
                <span className="font-semibold text-red-500">({reqRequisition?.requisitioner?.description})</span>
              </span>
              <span>{moment(reqRequisition?.createdAt).format("DD/MM/YYYY")}</span>
            </div>
            <div className="flex flex-col">
              {reqRequisition?.reqRequisitionMaterial?.map((requisitionMaterial, iRequisitionMaterial) => (
                <div
                  key={iRequisitionMaterial}
                  className="grid md:grid-cols-4 p-3"
                  style={{ borderBottom: "1px solid #eee" }}
                >
                  <span className="md:col-span-2 flex gap-2 items-center">
                    <div className="w-2 h-2 rounded-full bg-red-500" />
                    {requisitionMaterial?.material?.description}
                  </span>
                  <span>Qty: {requisitionMaterial?.quantity}</span>
                  <span className="text-right font-semibold">
                    {numeral(requisitionMaterial?.materialPrice).format("0,0.00")}{" "}
                    {requisitionMaterial?.currency?.currency}
                  </span>
                </div>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </motion.div>
  );
};

export default () => (
  <ReqRequisitionController>
    <ReqRequisition />
  </ReqRequisitionController>
);
