import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { RegisterContext } from "../../../Controller";

export const AccountContext = createContext();

const AccountController = ({ children }) => {
  const { accountForm } = useContext(RegisterContext);

  // -------------------------------
  // Validation
  // -------------------------------

  // Email Availability
  const email = accountForm.watch("userEmail");
  const [isEmailAvailable, setEmailAvailable] = useState();
  const [loadingEmailAvailable, setLoadingEmailAvailable] = useState(false);
  const [emailAvailableTimeout, setEmailAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkEmail = () => {
      setLoadingEmailAvailable(true);

      axios
        .post("/user/check-email", {
          email,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setEmailAvailable(available);
        })
        .finally(() => {
          setLoadingEmailAvailable(false);
          accountForm.trigger("email");
        });
    };

    clearTimeout(emailAvailableTimeout);
    const newTimeout = setTimeout(() => {
      email ? checkEmail() : setEmailAvailable();
    }, 500);
    setEmailAvailableTimeout(newTimeout);

    return () => {
      mounted = false;
    };
  }, [email]);

  return (
    <AccountContext.Provider value={{ isEmailAvailable, loadingEmailAvailable }}>{children}</AccountContext.Provider>
  );
};

export default AccountController;
