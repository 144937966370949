import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../Controller";

export const AdminApprovalContext = createContext();
const { Provider } = AdminApprovalContext;

const AdminApprovalController = ({ children }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { regCode, setReloadCompanyApprovalInfo, setReloadCompanyOverview, getCompanyNeedReapprovals } =
    useContext(CompanyProfileContext);
  const { token } = useContext(AuthContext);

  // Reject Message
  const [rejectMessage, setRejectMessage] = useState();
  useEffect(() => {
    let mounted = true;

    const getRejectMessage = () => {
      axios
        .get(`/vendor-approval/reject-message/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted && res?.data?.rejectMessage) setRejectMessage(res?.data?.rejectMessage);
        });
    };

    token && regCode && getRejectMessage();

    return () => {
      mounted = false;
    };
  }, [regCode, token]);

  // Reject
  const [isModalRejectOpen, setModalRejectOpen] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const submitReject = ({ rejectMessage }) => {
    setLoadingReject(true);

    axios
      .post(
        `/vendor-approval/reject/${regCode}`,
        {
          rejectMessage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setReloadCompanyApprovalInfo(true);
        setReloadCompanyOverview(true);
      })
      .finally(() => {
        setLoadingReject(false);
        setModalRejectOpen(false);
      });
  };

  // Approve
  const [isModalApproveOpen, setModalApproveOpen] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const submitApprove = () => {
    setLoadingApprove(true);

    axios
      .post(
        `/vendor-approval/approve/${regCode}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setReloadCompanyApprovalInfo(true);
        setReloadCompanyOverview(true);
        getCompanyNeedReapprovals();
      })
      .finally(() => {
        setLoadingApprove(false);
        setModalApproveOpen(false);
      });
  };

  return (
    <Provider
      value={{
        rejectMessage,

        isModalRejectOpen,
        setModalRejectOpen,
        loadingReject,
        submitReject,

        isModalApproveOpen,
        setModalApproveOpen,
        loadingApprove,
        submitApprove,
      }}
    >
      {children}
    </Provider>
  );
};

export default AdminApprovalController;
