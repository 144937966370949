import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import { BiReceipt, BiCategoryAlt } from "react-icons/bi";
import General from "./components/sections/General";
import ReqRequisition from "./components/sections/ReqRequisition";
import Document from "./components/sections/Document";
import Vendor from "./components/sections/Vendor";
import { useToast } from "@chakra-ui/react";
import ReqProcSubmission from "./components/sections/ReqProcSubmission";
import ProcSchedule from "./components/sections/ProcSchedule";
import Approval from "./components/sections/Approval";
import Aanwijzing from "./components/sections/Aanwijzing";
import ProcurementOfferIndex from "./components/sections/Offer";
import PrequalificationSwitchIndex from "./components/sections/Prequalification/PrequalificationSwitchIndex";
import Auction from "./components/sections/Auction";
import Negotiation from "./components/sections/Negotiation";
import WinningProposal from "./components/sections/WinningProposal";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../../../../routes/userRoutes";
import { HelperContext } from "../../../../../controllers/HelperController";
import { API_URLS } from "../../../../../utils/apiURLs.util";
import Participant from "./components/sections/Participant";
import Objection from "./components/sections/Objection";

export const ProcurementDetailContext = createContext();
const { Provider } = ProcurementDetailContext;

const ProcurementDetailController = ({ children }) => {
  const toast = useToast();
  const { showToast, httpRequest } = useContext(HelperContext);
  const { t } = useTranslation();
  const params = useParams();
  const { token, userData } = useContext(AuthContext);
  const regCode = userData?.company?.registrationCode;
  const { procurementNumber } = params;
  const navigate = useNavigate();

  // ---------------------------
  // Sections
  // ---------------------------

  const sectionCategories = [
    {
      name: "Administration",
      labelI18n: "LABEL.ADMINISTRATION",
      Icon: BiReceipt,
    },
    {
      name: "Tender",
      labelI18n: "LABEL.TENDER_PROCESS",
      Icon: BiCategoryAlt,
    },
  ];

  const sections = [
    {
      name: "General",
      category: "Administration",
      labelI18n: "LABEL.GENERAL",
      to: "/general",
      Component: General,
    },
    {
      name: "ReqRequisition",
      category: "Administration",
      labelI18n: "LABEL.REQ_REQUISITION",
      to: "/req-requisition",
      Component: ReqRequisition,
    },
    {
      name: "Document",
      category: "Administration",
      labelI18n: "LABEL.DOCUMENT",
      to: "/document",
      Component: Document,
    },
    {
      name: "Vendor",
      category: "Administration",
      labelI18n: "LABEL.VENDOR",
      to: "/vendor",
      Component: Vendor,
      restrict: ["user"],
    },
    {
      name: "ReqProcSubmission",
      category: "Administration",
      labelI18n: "LABEL.PROCUREMENT_REQUIREMENT",
      to: "/req-proc-submission",
      Component: ReqProcSubmission,
    },
    {
      name: "ProcSchedule",
      category: "Administration",
      labelI18n: "LABEL.PROCUREMENT_SCHEDULE",
      to: "/proc-schedule",
      Component: ProcSchedule,
    },
    {
      name: "Approval",
      category: "Administration",
      labelI18n: "LABEL.PROCUREMENT_APPROVAL",
      to: "/approval",
      Component: Approval,
      restrict: ["user"],
    },
    {
      name: "Aanwijzing",
      category: "Administration",
      labelI18n: "LABEL.PROCUREMENT_AANWIJZING",
      to: "/aanwijzing",
      Component: Aanwijzing,
    },
    {
      name: "Participant",
      category: "Tender",
      labelI18n: "LABEL.PARTICIPANT",
      to: "/participant",
      Component: Participant,
      restrict: ["user"],
    },
    {
      name: "Prequalification",
      category: "Tender",
      labelI18n: "LABEL.PREQUALIFICATION",
      to: "/prequalification",
      Component: PrequalificationSwitchIndex,
    },
    {
      name: "Offer",
      category: "Tender",
      labelI18n: "LABEL.OFFER",
      to: "/offer",
      Component: ProcurementOfferIndex,
    },
    {
      name: "Auction",
      category: "Tender",
      labelI18n: "LABEL.AUCTION",
      to: "/auction",
      Component: Auction,
    },
    {
      name: "Negotiation",
      category: "Tender",
      labelI18n: "LABEL.PROCUREMENT_NEGOTIATION",
      to: "/negotiation",
      Component: Negotiation,
    },
    {
      name: "WinningProposal",
      category: "Tender",
      labelI18n: "LABEL.WINNING_PROPOSAL",
      to: "/winning-proposal",
      Component: WinningProposal,
      restrict: ["user"],
    },
    {
      name: "Objection",
      category: "Tender",
      labelI18n: "LABEL.PROCUREMENT_OBJECTION",
      to: "/objection",
      Component: Objection,
      restrict: ["user"],
    },
  ];

  const [activeSection, setActiveSection] = useState();

  useEffect(() => {
    setActiveSection(sections.find((section) => section.to?.includes(params?.section)));
  }, [params]);

  // ---------------------------
  // Data
  // ---------------------------

  // Check Vendor Procurement
  const [vendorAccess, setVendorAccess] = useState({});
  useEffect(() => {
    let mounted = true;

    const checkVendorProcurement = async () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement/can-access/${regCode}/${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const access = res?.data;
            if (!access?.view && !access?.edit) {
              navigate(userRouteSlug.PANEL);
              return;
            }
            setVendorAccess(access);
            setReloadProcurement(true);
          }
        });
    };

    if (token && procurementNumber) {
      if (userData?.type === "user") checkVendorProcurement();
      else setReloadProcurement(true);
    }

    return () => {
      mounted = false;
    };
  }, [token, procurementNumber]);

  // Procurement Winner
  const [procurementWinner, setProcurementWinner] = useState();
  const [reloadProcurementWinner, setReloadProcurementWinner] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getProcurementWinner = async () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-winner-proposal/winner/${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newProcurementWinner = res?.data?.procurementWinner;
            setProcurementWinner(newProcurementWinner);
          }
        })
        .finally(() => {
          setReloadProcurementWinner(false);
        });
    };

    token && reloadProcurementWinner && procurementNumber && getProcurementWinner();

    return () => {
      mounted = false;
    };
  }, [token, reloadProcurementWinner, procurementNumber]);

  // Procurement
  const [procurement, setProcurement] = useState();
  const [loadingProcurement, setLoadingProcurement] = useState(false);
  const [reloadProcurement, setReloadProcurement] = useState(false);
  useEffect(() => {
    let mounted = true;

    const getProcurement = async () => {
      setLoadingProcurement(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement?search=${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newProcurement = res?.data?.procurements?.[0];
            setProcurement(newProcurement);
          }
        })
        .finally(() => {
          setReloadProcurement(false);
          setLoadingProcurement(false);
        });
    };

    token && reloadProcurement && procurementNumber && getProcurement();

    return () => {
      mounted = false;
    };
  }, [token, reloadProcurement, procurementNumber]);

  // Vendor Status
  const [vendorStatus, setVendorStatus] = useState();
  const [reloadVendorStatus, setReloadVendorStatus] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getProcurementVendorStatus = async () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-vendor/status/${params?.procurementNumber}/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newStatus = res?.data?.status;
            setVendorStatus(newStatus);
          }
        })
        .finally(() => {
          setReloadVendorStatus(false);
        });
    };

    token && regCode && reloadVendorStatus && getProcurementVendorStatus();

    return () => {
      mounted = false;
    };
  }, [token, regCode, reloadVendorStatus]);

  // Vendor Procurement Acceptance
  const [isAcceptanceOpen, setAcceptanceOpen] = useState(false);
  const [acceptanceStatus, setAcceptanceStatus] = useState();
  const handleAcceptanceOpen = (isOpen, status = null) => {
    setAcceptanceOpen(isOpen);
    setAcceptanceStatus(status);
  };
  const [loadingSubmitAcceptance, setLoadingSubmitAcceptance] = useState(false);
  const submitAcceptance = () => {
    setLoadingSubmitAcceptance(true);

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-vendor/status/${procurementNumber}/${regCode}`,
        {
          isApprove: acceptanceStatus === "approve",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (!res?.data?.success) {
          if (res?.data?.message?.includes("not valid")) {
            showToast("Gagal, Jadwal registrasi sudah berakhir", { success: false });
            setTimeout(() => {
              navigate(userRouteSlug.PANEL);
            }, 1000);
          }
        } else {
          setVendorAccess({ view: true, edit: true });
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
        }
        setAcceptanceOpen(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingSubmitAcceptance(false);
        setReloadVendorStatus(true);
      });
  };

  // -------------------------------
  // Get Data Duration
  // -------------------------------

  const [duration, setDurations] = useState([]);
  const [loadingDurations, setLoadingDurations] = useState(false);
  const [reloadDuration, setReloadDuration] = useState(true);

  useEffect(() => {
    let mounted = true;

    const getDuration = async () => {
      setLoadingDurations(true);

      axios
        .get(`${process.env.REACT_APP_TMS_API}/duration`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newDuration = res?.data?.duration;
            setDurations(newDuration);
          }
        })
        .finally(() => {
          setReloadDuration(false);
          setLoadingDurations(false);
        });
    };

    token && reloadDuration && getDuration();

    return () => {
      mounted = false;
    };
  }, [token, reloadDuration]);

  // Get Req Requisitions
  const [reqRequisitions, setReqRequisitions] = useState([]);
  const [loadingReqRequisitions, setLoadingReqRequisitions] = useState(false);
  useEffect(() => {
    let mounted = true;

    const getReqRequisitionMaterial = () => {
      const reqRequisitionMaterialIds = procurement?.procurementRequisitionMaterials
        ?.map((material) => material?.requisitionMaterial?.reqRequisitionMaterialId)
        ?.join(";");

      axios
        .get(`${process.env.REACT_APP_TMS_API}/req-requisition/by-code?code=${reqRequisitionMaterialIds}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newReqRequisitions = res?.data?.reqRequisitions;
            setReqRequisitions(newReqRequisitions);
          }
        })
        .finally(() => {
          setLoadingReqRequisitions(false);
        });
    };

    procurement && getReqRequisitionMaterial();

    return () => {
      mounted = false;
    };
  }, [procurement]);

  // Approval
  const [isApproved, setIsApproved] = useState(null);
  const [isApprovedByGm, setIsApprovedByGm] = useState(null);
  useEffect(async () => {
    if (!procurement) return;
    let mounted = true;

    const { response } = await httpRequest({
      url: `${API_URLS.REACT_APP_TMS_API}/procurement/is-approved/${procurement.procurementNumber}`,
      method: "POST",
    });
    if (!mounted) return;

    setIsApproved(response?.isApproved);
    setIsApprovedByGm(response?.isApprovedByGm);

    return () => (mounted = false);
  }, [procurement]);

  const [procurementType, setProcurementType] = useState();
  useEffect(() => {
    if (!procurement) return;

    const type =
      procurement?.procurementType?.procurementType === "LIMITED SELECTION"
        ? "LIMITED"
        : procurement?.procurementType?.procurementType === "GENERAL SELECTION"
        ? "GENERAL"
        : "DIRECT";

    setProcurementType(type);
  }, [procurement]);

  // Schedule
  const [procurementSchedule, setProcurementSchedule] = useState();
  const [loadingProcurementSchedule, setLoadingProcurementSchedule] = useState(false);
  useEffect(async () => {
    if (!procurement) return;
    let mounted = true;

    setLoadingProcurementSchedule(true);
    const { response } = await httpRequest({
      url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}`,
    });
    if (!mounted) return;
    setLoadingProcurementSchedule(false);
    setProcurementSchedule(response?.procurementSchedule[0]);

    return () => {
      mounted = false;
    };
  }, [procurement]);

  const [canOffer, setCanOffer] = useState();
  useEffect(() => {
    if (!procurement || !userData || userData?.type === "admin") return;
    if (!procurement.procurementSetting?.prequalification) return setCanOffer(true);
    let mounted = true;

    (async () => {
      const {response} = await httpRequest({
        url: `${API_URLS.REACT_APP_TMS_API}/procurement-prequalification-evaluation/can-offer/${userData?.company?.registrationCode}/${procurement.procurementNumber}`,
        method: 'POST'
      })
      if (!mounted) return;

      setCanOffer(response?.canOffer)
    })();

    return () => {
      mounted = false;
    }
  }, [procurement, userData]);

  // OBJECTION
  const [anyObjectionApproved, setAnyObjectionApproved] = useState(false);
  useEffect(() => {
    if (!procurement || !procurement?.procurementWinnerProposal?.isWinner) return;
    let mounted = true;

    (async () => {
      const {response} = await httpRequest({
        url:`${API_URLS.REACT_APP_TMS_API}/procurement-objection/is-approved/${procurement.procurementNumber}`,
      })
      if (!mounted) return;
      if (!response?.success) return;

      setAnyObjectionApproved(response?.data?.isApproved);
    })()

    return () => {
      mounted = false;
    }
  }, [procurement])
  
  return (
    <Provider
      value={{
        procurement,
        setProcurement,
        setReloadProcurement,
        loadingProcurement,

        procurementWinner,
        setReloadProcurementWinner,

        duration,
        loadingDurations,

        reqRequisitions,
        loadingReqRequisitions,

        sectionCategories,
        sections,
        activeSection,

        vendorStatus,

        isAcceptanceOpen,
        acceptanceStatus,
        handleAcceptanceOpen,
        loadingSubmitAcceptance,
        submitAcceptance,

        isApproved,
        setIsApproved,
        isApprovedByGm,
        setIsApprovedByGm,
        procurementType,
        setProcurementType,

        procurementSchedule,
        setProcurementSchedule,
        loadingProcurementSchedule,
        setLoadingProcurementSchedule,
        vendorAccess, setVendorAccess,
        setVendorStatus,
        canOffer,
        anyObjectionApproved, setAnyObjectionApproved
      }}
    >
      {children}
    </Provider>
  );
};

export default ProcurementDetailController;
