import React, { Fragment, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import moment from "moment";
import numeral from "numeral";
import ReqRequisitionController, { ReqRequisitionContext } from "./Controller";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";

const ReqRequisition = () => {
  const { reqRequisitions, totalPrice, currency } = useContext(
    ReqRequisitionContext
  );
  const { userData } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="bg-white rounded-md p-6"
    >
      <div className="flex justify-end w-full mb-4">
        <div className="bg-gray-200 flex justify-end rounded-3xl p-2">
          <span className="text-left">
            {t("LABEL.PROCUREMENT_NEGOTIATION_TOTAL_PRICE")}
            {": "}
          </span>
          <span className="ml-2 font-bold">
            {numeral(totalPrice).format("0,0.00")}
            {"  "}
            {currency}
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        {reqRequisitions?.map((reqRequisition, iReqRequisition) => (
          <Fragment key={iReqRequisition}>
            <div
              className="flex justify-between p-3 bg-gray-50"
              style={{ borderBottom: "1px solid #eee" }}
            >
              <span>
                {reqRequisition?.reqRequisitionCode}{" "}
                <span className="font-semibold text-red-500">
                  ({reqRequisition?.requisitioner?.description})
                </span>
              </span>
              <span>
                {moment(reqRequisition?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="flex flex-col">
              {reqRequisition?.reqRequisitionMaterial?.map(
                (requisitionMaterial, iRequisitionMaterial) => (
                  <div
                    key={iRequisitionMaterial}
                    className={`grid  ${
                      userData?.type === "user"
                        ? "md:grid-cols-3"
                        : "md:grid-cols-4"
                    } p-3`}
                    style={{ borderBottom: "1px solid #eee" }}
                  >
                    <span className="md:col-span-2 flex gap-2 items-center">
                      <div className="w-2 h-2 rounded-full bg-red-500" />
                      {requisitionMaterial?.material?.description}
                    </span>
                    <span
                      className={`${
                        userData?.type === "user" ? "text-right" : ""
                      } `}
                    >
                      Qty: { numeral(requisitionMaterial?.quantity).format("0,0") } {`${requisitionMaterial?.material?.measureUnit?.unit} (${requisitionMaterial?.material?.measureUnit?.description})`}
                    </span>
                    {userData?.type !== "user" ? (
                      <span className="text-right font-semibold">
                        {numeral(requisitionMaterial?.materialPrice).format(
                          "0,0.00"
                        )}{" "}
                        {requisitionMaterial?.currency?.currency}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                )
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </motion.div>
  );
};

export default () => (
  <ReqRequisitionController>
    <ReqRequisition />
  </ReqRequisitionController>
);
