import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BranchContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { emailPattern, websitePattern } from "../../../../../../../utils/regex.util";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { loadingCompany, activeBranch, form, countries, provinces, cities, districts } = useContext(BranchContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Country */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.COUNTRY")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.COUNTRY") }) },
            }}
            name="country"
            options={countries.map((country) => ({
              label: country?.description,
              value: country?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.COUNTRY")}`}
            isError={!!errors?.country}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeBranch?.country?.description}</FormDescription>
        )}

        {errors?.country && (
          <FormHelperText className="text-red-400 text-xs">{errors?.country?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Province */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PROVINCE")}
        </FormLabel>
        {isEdit ? (
          provinces === null ? (
            <InputText
              className={`w-full text-sm ${errors?.province && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.PROVINCE")}
              {...register("province", { required: t("INVALID.REQUIRED", { field: t("LABEL.PROVINCE") }) })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.PROVINCE") }) },
              }}
              name="province"
              options={provinces.map((province) => ({
                label: province?.description,
                value: province?.regionCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.PROVINCE")}`}
              isSearchable
              isDisabled={!provinces.length}
              isError={!!errors?.province}
            />
          )
        ) : (
          <FormDescription>
            {activeBranch?.provinceObj
              ? _.startCase(activeBranch?.provinceObj?.description?.toLowerCase())
              : activeBranch?.province}
          </FormDescription>
        )}
        {errors?.province && (
          <FormHelperText className="text-red-400 text-xs">{errors?.province?.message}</FormHelperText>
        )}
      </FormControl>

      {/* City */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CITY")}
        </FormLabel>
        {isEdit ? (
          cities === null ? (
            <InputText
              className={`w-full text-sm ${errors?.city && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.CITY")}
              {...register("city", { required: t("INVALID.REQUIRED", { field: t("LABEL.CITY") }) })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CITY") }) },
              }}
              name="city"
              options={cities.map((city) => ({
                label: city?.description,
                value: city?.regionCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CITY")}`}
              isSearchable
              isDisabled={!cities.length}
              isError={!!errors?.city}
            />
          )
        ) : (
          <FormDescription>
            {activeBranch?.cityObj
              ? _.startCase(activeBranch?.cityObj?.description?.toLowerCase())
              : activeBranch?.city}
          </FormDescription>
        )}
        {errors?.city && <FormHelperText className="text-red-400 text-xs">{errors?.city?.message}</FormHelperText>}
      </FormControl>

      {/* District */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DISTRICT")}
        </FormLabel>
        {isEdit ? (
          districts === null ? (
            <InputText
              className={`w-full text-sm ${errors?.city && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.DISTRICT")}
              {...register("district", { required: t("INVALID.REQUIRED", { field: t("LABEL.DISTRICT") }) })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.DISTRICT") }) },
              }}
              name="district"
              options={districts.map((district) => ({
                label: district?.description,
                value: district?.regionCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.DISTRICT")}`}
              isSearchable
              isDisabled={!districts.length}
              isError={!!errors?.district}
            />
          )
        ) : (
          <FormDescription>
            {activeBranch?.districtObj
              ? _.startCase(activeBranch?.districtObj?.description?.toLowerCase())
              : activeBranch?.district}
          </FormDescription>
        )}
        {errors?.district && (
          <FormHelperText className="text-red-400 text-xs">{errors?.district?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Address */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ADDRESS")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${errors?.address && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ADDRESS")}
            {...register("address", { required: t("INVALID.REQUIRED", { field: t("LABEL.ADDRESS") }) })}
          />
        ) : (
          <FormDescription>{activeBranch?.address}</FormDescription>
        )}
        {errors?.address && (
          <FormHelperText className="text-red-400 text-xs">{errors?.address?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Postal Code */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.POSTAL_CODE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.postalCode && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.POSTAL_CODE")}
            type="number"
            {...register("postalCode", { required: t("INVALID.REQUIRED", { field: t("LABEL.POSTAL_CODE") }) })}
          />
        ) : (
          <FormDescription>{activeBranch?.postalCode}</FormDescription>
        )}
        {errors?.postalCode && (
          <FormHelperText className="text-red-400 text-xs">{errors?.postalCode?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Phone Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PHONE")}
        </FormLabel>
        {isEdit ? (
          <div className="flex">
            <div className="w-20">
              <InputText
                className={`w-full rounded-r-none text-sm ${
                  errors?.phoneCode && "border-2 border-r-0 border-red-400 shadow-none"
                }`}
                {...register("phoneCode", {
                  required: t("INVALID.REQUIRED", { field: t("LABEL.PHONE_CODE") }),
                  pattern: {
                    value: /\+\d+/,
                  },
                })}
                defaultValue="+62"
              />
            </div>
            <div className="w-full">
              <InputText
                className={`w-full rounded-l-none text-sm ${
                  errors?.phoneNumber && "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.PHONE")}
                type="number"
                {...register("phoneNumber", {
                  required: t("INVALID.REQUIRED", { field: t("LABEL.PHONE") }),
                })}
              />
              {errors?.phoneNumber && (
                <FormHelperText className="text-red-400 text-xs">{errors?.phoneNumber?.message}</FormHelperText>
              )}
            </div>
          </div>
        ) : (
          <FormDescription>{activeBranch?.phoneNumber}</FormDescription>
        )}
      </FormControl>

      {/* Fax Number */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.FAX")}
        </FormLabel>
        {isEdit ? (
          <div className="flex">
            <div className="w-20">
              <InputText className={`w-full rounded-r-none text-sm`} {...register("faxCode")} defaultValue="+62" />
            </div>
            <div className="w-full">
              <InputText
                className={`w-full rounded-l-none text-sm ${
                  errors?.faxNumber && "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.FAX")}
                type="number"
                {...register("faxNumber")}
              />
              {errors?.faxNumber && (
                <FormHelperText className="text-red-400 text-xs">{errors?.faxNumber?.message}</FormHelperText>
              )}
            </div>
          </div>
        ) : (
          <FormDescription>{activeBranch?.fax}</FormDescription>
        )}
      </FormControl>

      {/* Email */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.EMAIL")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.email && "border-2 border-red-400 shadow-none"}`}
            placeholder="email@company.com"
            {...register("email", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.EMAIL") }),
              pattern: {
                value: emailPattern,
                message: t("INVALID.EMAIL", { field: t("LABEL.EMAIL") }),
              },
            })}
          />
        ) : (
          <FormDescription>{activeBranch?.email}</FormDescription>
        )}
        {errors?.email && <FormHelperText className="text-red-400 text-xs">{errors?.email?.message}</FormHelperText>}
      </FormControl>

      {/* Website */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.WEBSITE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.website && "border-2 border-red-400 shadow-none"}`}
            placeholder="company.com"
            {...register("website", {
              pattern: {
                value: websitePattern,
                message: t("INVALID.EMAIL", { field: t("LABEL.WEBSITE") }),
              },
            })}
          />
        ) : (
          <FormDescription>{activeBranch?.website}</FormDescription>
        )}
        {errors?.website && (
          <FormHelperText className="text-red-400 text-xs">{errors?.website?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
