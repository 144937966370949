import React from "react";
import { useTranslation } from "react-i18next";
import VendorFilter from "./components/VendorFilter";
import VendorTable from "./components/VendorTable";
import VendorController from "./Controller";

const Vendor = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.VENDOR")}</h2>
      <div className="grid md:grid-cols-4 gap-4 md:gap-8">
        <div>
          <VendorFilter />
        </div>
        <div className="md:col-span-3">
          <VendorTable />
        </div>
      </div>
    </div>
  );
};

export default () => (
  <VendorController>
    <Vendor />
  </VendorController>
);
