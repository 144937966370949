import React from 'react';
import { motion } from 'framer-motion';
import PrequalificationController from './Controller';
import { useTranslation } from 'react-i18next';
import OverviewPage from './components/OverviewPage';

const Prequalification = () => {
	const { t } = useTranslation();

	return (
		<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
			<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-8">
				<OverviewPage />
			</div>
		</motion.div>
	);
};

export default () => (
	<PrequalificationController>
		<Prequalification />
	</PrequalificationController>
);
