import { Spinner } from '@chakra-ui/react';
import numeral from 'numeral';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { ProcurementDetailContext } from '../../../../../../../Controller';
import { ProcurementOfferEvaluationContext } from '../../../Controller';

const ItemCard = ({ material }) => {
	const { t } = useTranslation();
	return (
		<div className="bg-gradient-to-br from-color0 via-color1 to-color2 rounded-md shadow-lg" style={{ padding: '3px' }}>
			<div className="flex justify-between px-2 py-1 rounded-md bg-white hover:bg-gray-50">
				<div className="w-1/3">
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t("LABEL.DESCRIPTION")}</div>
						<div style={{ maxHeight: '70px', overflow: 'auto' }}>{material.description}</div>
					</div>
				</div>
				<div className="w-1/3 flex flex-col gap-1">
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">Qty</div>
						<div className="w-2/3">{numeral(+material.qty).format('0,0')}</div>
					</div>
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">UoM</div>
						<div className="w-2/3">{`${material.unit} (${material.unitDescription})`}</div>
					</div>
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">{t("LABEL.CURRENCY")}</div>
						<div className="w-2/3">{`${material.currency.currency} (${material.currency.description})`}</div>
					</div>
				</div>
				<div className="w-1/4 flex flex-col gap-1">
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t("LABEL.PRICE")}</div>
						<div className="font-semibold">
							{numeral(+material.price).format('0,0.00')} {material.currency?.currency}
						</div>
					</div>
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t("LABEL.MATERIAL_PRICE")}</div>
						<div className="font-bold">
							{numeral(+material.price * material.qty).format('0,0.00')} {material.currency?.currency}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const EvaluationItemTable = () => {
	const { t } = useTranslation();
	const { materials, loadingMaterials } = useContext(ProcurementOfferEvaluationContext);
	const { procurement } = useContext(ProcurementDetailContext);

	return (
		<div className="mt-5">
			<TitleBar title={t('LABEL.PROCUREMENT_ITEM')} />
			<div className="flex flex-col gap-3">
				{loadingMaterials || !materials || !procurement ? (
					<div className="flex justify-center">
						<Spinner color="red.500" />
					</div>
				) : (
					<>
						{materials.map((material, idx) => {
							return <ItemCard key={idx} material={material} />;
						})}
					</>
				)}
			</div>
		</div>
	);
};

export default EvaluationItemTable;
