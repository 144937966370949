import { Spinner } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SchedulePanel = ({ title, start, end, loading, className }) => {
	const { t } = useTranslation();
	return (
		<div className={`flex ${className}`}>
			<div className="w-1/4 flex items-center justify-center text-base font-semibold italic text-center">{title}</div>
			<div className="font-bold flex flex-col gap-2 py-2 w-2/4">
				<div className="flex items-center gap-5 text-green-600">
					<div className="text-right w-1/3">Mulai</div>
					<div className="w-1/2 flex items-center">{loading ? <Spinner color="red.500" /> : <>{!start ? <>-</> : <>{start ? moment(start).format('LLL') : <>-</>}</>}</>}</div>
				</div>
				<div className="flex items-center gap-5 text-color0">
					<div className="text-right w-1/3">Batas akhir</div>
					<div className="w-1/2 flex items-center">{loading ? <Spinner color="red.500" /> : <>{!end ? <>-</> : <>{end ? moment(end).format('LLL') : <>-</>}</>}</>}</div>
				</div>
			</div>
		</div>
	);
};

export default SchedulePanel;
