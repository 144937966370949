const Stepper = ({ items = [], active = 0 }) => {
  return (
    <div className={`grid grid-cols-${items.length} bg-gray-200 text-sm`}>
      {items.map((item, iItem) => (
        <div
          key={iItem}
          className={`p-4 flex justify-center items-center gap-x-2 ${
            iItem === active && active !== items.length - 1 && "rounded-r-xl"
          }`}
          style={{ backgroundColor: iItem <= active && "#ff1b53" }}
        >
          <div
            className={`w-7 h-7 flex justify-center items-center rounded-full font-semibold ${
              iItem <= active ? "bg-white text-red-500" : "bg-gray-300 text-white"
            }`}
          >
            {iItem + 1}
          </div>
          <span
            className={`hidden md:inline font-semibold ${iItem <= active ? "text-white" : "text-gray-400"} uppercase`}
          >
            {item?.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
