import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ShareholderContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import InputMask from "../../../../../../../components/inputForm/InputMask";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { shareholders, isCreate, loadingCompany, activeShareholder, form, countries } = useContext(ShareholderContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("taxAttachment");
  register("idCardAttachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.ID_CARD_NUMBER") }) }),
    },
  });

  const citizenship = form.watch("citizenship");

  // Max Share
  let maxShare = 100;
  const activeShareholders = shareholders?.filter((shareholder) => !shareholder?.isDelete);
  if (activeShareholders?.length) {
    // Subtract by existing share percentage
    maxShare -= activeShareholders
      ?.filter((shareholder) => !shareholder?.isDelete)
      ?.map((shareholder) => shareholder?.sharePercentage)
      ?.reduce((a, b) => a + b);
  }
  if (!isCreate)
    // Add max value with current share if updating
    maxShare += activeShareholder?.sharePercentage;

  return (
    <>
      {/* Shareholder Name */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.SHAREHOLDER_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.shareholderName && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.SHAREHOLDER_NAME")}
            {...register("shareholderName", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.SHAREHOLDER_NAME") }),
            })}
          />
        ) : (
          <FormDescription>{activeShareholder?.shareholderName}</FormDescription>
        )}
        {errors?.shareholderName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.shareholderName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Citizenship */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CITIZENSHIP")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CITIZENSHIP") }) },
            }}
            name="citizenship"
            options={countries.map((country) => ({
              label: country?.description,
              value: country?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CITIZENSHIP")}`}
            isError={!!errors?.citizenship}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeShareholder?.citizenship?.description}</FormDescription>
        )}

        {errors?.citizenship && (
          <FormHelperText className="text-red-400 text-xs">{errors?.citizenship?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Share Percentage */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.SHARE_PERCENTAGE")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <InputText
              type="number"
              placeholder={t("LABEL.SHARE_PERCENTAGE")}
              className={`w-full text-sm ${errors?.sharePercentage && "border-2 border-red-400 shadow-none"}`}
              {...register("sharePercentage", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.SHARE_PERCENTAGE") }),
                validate: {
                  min: (v) => v > 0 || t("INVALID.MIN", { field: t("LABEL.SHARE_PERCENTAGE"), length: "0.01" }),
                },
                max: {
                  value: maxShare,
                  message: t("INVALID.MAX", { field: t("LABEL.SHARE_PERCENTAGE"), length: maxShare }),
                },
              })}
            />
            <span className="p-inputgroup-addon">%</span>
          </div>
        ) : (
          <FormDescription>{activeShareholder?.sharePercentage}%</FormDescription>
        )}
        {errors?.sharePercentage && (
          <FormHelperText className="text-red-400 text-xs">{errors?.sharePercentage?.message}</FormHelperText>
        )}
      </FormControl>

      <div />

      {/* Tax Number */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.TAX_NUMBER")}
        </FormLabel>
        {isEdit ? (
          citizenship === "ID" ? (
            <InputMask
              mask={"99.999.999.9-999.999"}
              placeholder={t("LABEL.TAX_NUMBER")}
              name="taxNumber"
              control={control}
            />
          ) : (
            <InputText
              placeholder={t("LABEL.TAX_NUMBER")}
              className={`w-full text-sm ${errors?.taxNumber && "border-2 border-red-400 shadow-none"}`}
              {...register("taxNumber")}
            />
          )
        ) : (
          <FormDescription>{activeShareholder?.taxNumber}</FormDescription>
        )}
        {errors?.taxNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.taxNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Tax Attachment */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.TAX_NUMBER") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.taxAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.TAX_NUMBER"),
            })}`}
            name="taxAttachment"
            fileList={form.getValues("taxAttachment")}
            onChange={(files) => {
              setValue("taxAttachment", files);
              trigger("taxAttachment");
            }}
          />
        ) : activeShareholder?.taxAttachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeShareholder?.taxAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <span className="text-gray-300">{t("LABEL.EMPTY")}</span>
        )}
        {errors?.taxAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.taxAttachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Id Card Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ID_CARD_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            placeholder={t("LABEL.ID_CARD_NUMBER")}
            className={`w-full text-sm ${errors?.idCardNumber && "border-2 border-red-400 shadow-none"}`}
            {...register("idCardNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.ID_CARD_NUMBER") }),
            })}
          />
        ) : (
          <FormDescription>{activeShareholder?.idCardNumber}</FormDescription>
        )}
        {errors?.idCardNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.idCardNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Id Card Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.ID_CARD_NUMBER") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.idCardAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.ID_CARD_NUMBER"),
            })}`}
            name="idCardAttachment"
            fileList={form.getValues("idCardAttachment")}
            onChange={(files) => {
              setValue("idCardAttachment", files);
              trigger("idCardAttachment");
            }}
          />
        ) : activeShareholder?.idCardAttachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeShareholder?.idCardAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <span className="text-gray-300">{t("LABEL.EMPTY")}</span>
        )}
        {errors?.idCardAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.idCardAttachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
