import axios from "axios";
import { saveAs } from "file-saver";
import { createContext } from "react";

export const FileserviceContext = createContext();
const { Provider } = FileserviceContext;

const FileserviceController = ({ children }) => {
  // Download File
  const downloadFile = async (filename, category = "users") => {
    axios
      .get(`${process.env.REACT_APP_FILESERVICE_API}/files/${category}/${filename}`, {
        responseType: "blob",
      })
      .then((response) => {
        const filename = response.headers?.["content-disposition"]?.substring(21);
        const file = new Blob([response.data], { type: response.headers["content-type"] });

        saveAs(file, filename);
      })
      .catch((err) => console.error(err));
  };

  const getFile = async (filename, category = "users") => {
    let file;
    await axios
      .get(`${process.env.REACT_APP_FILESERVICE_API}/files/${category}/${filename}`, {
        responseType: "blob",
      })
      .then((response) => {
        const filename = response.headers?.["content-disposition"]?.substring(21);
        file = new File([response.data], filename, { type: response.headers["content-type"] });
      })
      .catch((err) => console.error(err));

    return file;
  };

  return <Provider value={{ downloadFile, getFile }}>{children}</Provider>;
};
export default FileserviceController;
