import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import FinancialReportDetail from "./components/FinancialReportDetail";
import FinancialReportList from "./components/FinancialReportList";
import FinancialReportNew from "./components/FinancialReportNew";
import FinancialReportController, { FinancialReportContext } from "./Controller";

const FinancialReport = () => {
  const { isDetailMenu, isCreate } = useContext(FinancialReportContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <FinancialReportNew /> : isDetailMenu ? <FinancialReportDetail /> : <FinancialReportList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <FinancialReportController>
    <FinancialReport />
  </FinancialReportController>
);
