import { useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/auth/AuthController";

export const DashboardContext = createContext();
const { Provider } = DashboardContext;

const DashboardController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token, roles, refetchToken } = useContext(AuthContext);

  // Company Detail
  const [loadingCompanyOverview, setLoadingCompanyOverview] = useState(false);
  const [companyOverview, setCompanyOverview] = useState();
  useEffect(() => {
    let mounted = true;

    const getCompanyOverview = () => {
      setLoadingCompanyOverview(true);
      axios
        .get(`company/${userData?.company?.registrationCode}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (mounted) {
            setCompanyOverview(res?.data?.company);
          }
        })
        .finally(() => {
          setLoadingCompanyOverview(false);
        });
    };

    token && userData?.type === "user" && getCompanyOverview();

    return () => {
      mounted = true;
    };
  }, [userData, token]);

  // ---------------------------
  // Applicant
  // ---------------------------

  // Verify Submit Approval
  const [submitApprovalFails, setSubmitApprovalFails] = useState([]);
  const getSubmitApprovalFails = async () => {
    const isUser = userData?.type === "user";
    if (isUser) {
      axios
        .get(`/company/verify-submit-approval/${userData?.company?.registrationCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setSubmitApprovalFails(res.data?.fails);
        });
    }
  };
  useEffect(() => {
    userData && token && getSubmitApprovalFails();
  }, [userData, token, roles]);

  return (
    <Provider
      value={{
        loadingCompanyOverview,
        companyOverview,

        submitApprovalFails,
      }}
    >
      {children}
    </Provider>
  );
};

export default DashboardController;
