import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Datepicker from "../../../../../../../../../../components/inputForm/Datepicker";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcScheduleContext } from "../../Controller";
import { ProcurementDetailContext } from "../../../../../Controller";
import { AuthContext } from "../../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../../components/PrivateComponent";

const TechnicalQuotationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    technicalquotationForm,
    schedules,
    loadingTechnicalQuotation,
    handleCreateTechnicalQuotation,
    createTechnicalQuotationProcSchedule,
  } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = technicalquotationForm;

  return (
    <>
      {/* Technical Quotation */}
      <hr className="md:col-span-2" />
      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4`}>
          {t("LABEL.PROCUREMENT_TECHNICAL_TENDER")}
        </FormLabel>

        <div
          className={`md:col-span-2 grid ${
            userData?.type !== "user" ? "md:grid-cols-3" : "md:grid-cols-2"
          } gap-6 items-center`}
        >
          {/* Start Technical Quotation */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_START", {
                field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
              })}
            </FormLabel>
            {isEdit ? (
              <Datepicker
                format="yyyy-MM-dd HH:mm:ss"
                name="technicalQuotationStart"
                placeholder={t("LABEL.PROCUREMENT_START", {
                  field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
                })}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_START", {
                        field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
                      }),
                    }),
                  },
                }}
                isError={!!errors?.technicalQuotationStart}
              />
            ) : (
              <FormDescription>
                {schedules[0]?.technicalQuotationStart &&
                  moment(
                    new Date(schedules[0]?.technicalQuotationStart)
                  ).format("LLL")}
              </FormDescription>
            )}
            {errors?.technicalQuotationStart && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.technicalQuotationStart?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* End Technical Quotation */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_END", {
                field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
              })}
            </FormLabel>
            {isEdit ? (
              <Datepicker
                format="yyyy-MM-dd HH:mm:ss"
                name="technicalQuotationEnd"
                placeholder={t("LABEL.PROCUREMENT_END", {
                  field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
                })}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_END", {
                        field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
                      }),
                    }),
                  },
                }}
                isError={!!errors?.technicalQuotationEnd}
              />
            ) : (
              <FormDescription>
                {schedules[0]?.technicalQuotationEnd &&
                  moment(new Date(schedules[0]?.technicalQuotationEnd)).format(
                    "LLL"
                  )}
              </FormDescription>
            )}
            {errors?.technicalQuotationEnd && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.technicalQuotationEnd?.message}
              </FormHelperText>
            )}
          </FormControl>
          {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
            isEdit ? (
              <PrivateComponent accessName="BUYER_EDIT">
                <Button
                  size="sm"
                  colorScheme="teal"
                  isLoading={loadingTechnicalQuotation}
                  onClick={technicalquotationForm.handleSubmit(
                    createTechnicalQuotationProcSchedule
                  )}
                >
                  {t("LABEL.SAVE")}
                </Button>
              </PrivateComponent>
            ) : (
              <PrivateComponent accessName="BUYER_EDIT">
                <Button
                  size="sm"
                  colorScheme="gray"
                  isLoading={loadingTechnicalQuotation}
                  onClick={() =>
                    handleCreateTechnicalQuotation(true, schedules)
                  }
                  disabled={!schedules[0]?.registrationStart ? true : false}
                >
                  {t("LABEL.EDIT", {
                    field: t("LABEL.PROCUREMENT_TECHNICAL_TENDER"),
                  })}
                </Button>
              </PrivateComponent>
            )
          ) : (
            ""
          )}
        </div>
      </FormControl>
    </>
  );
};

export default TechnicalQuotationForm;
