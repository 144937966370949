import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../controllers/auth/AuthController";
import { userRouteSlug } from "../../../routes/userRoutes";
import { getPurePathname } from "../../../utils/string.util";

export const UserTemplateContext = createContext();
const { Provider } = UserTemplateContext;

const UserTemplateController = ({ children }) => {
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  // Get Menu List
  const [menu, setMenu] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(false);
  useEffect(() => {
    let mounted = true;
    setLoadingMenu(true);

    if (token) {
      axios
        .get("/menu", { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (mounted) {
            setMenu(res.data?.menu);
          }
        })
        .finally(() => {
          setLoadingMenu(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [token]);

  // Verify Current Path
  useEffect(() => {
    if (menu.length) {
      let pathname = getPurePathname(location, params);
      const isPathExist = menu.find((item) => matchPath(pathname, userRouteSlug.PANEL + item?.path));
      if (!isPathExist) navigate(userRouteSlug.PANEL + "/404");
    }
  }, [location, menu]);

  return <Provider value={{ menu, loadingMenu }}>{children}</Provider>;
};
export default UserTemplateController;
