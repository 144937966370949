import { Button } from 'rsuite';
import { FaRegFilePdf } from 'react-icons/fa';
import { useState } from 'react';
import { useContext } from 'react';
import { HelperContext } from '../controllers/HelperController';
import { API_URLS } from '../utils/apiURLs.util';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import PrivateComponent from './PrivateComponent';

const PrintDocumentButton = ({ label, url, className, isDisabled }) => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const [loading, setLoading] = useState(false);

	const getDocument = async () => {
		setLoading(true);
		const { response, headers } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/pdf/${url}`,
			responseType: 'blob',
		});
		setLoading(false);

		const filename = headers?.['content-disposition']?.substring(21);

		const file = new Blob([response], { type: headers?.['content-type'] });
		if (!file || !filename) return showToast(t('ERROR.undefined'), { success: false });

		saveAs(file, filename);
	};

	return (
		<PrivateComponent accessName="BUYER_EDIT">
			<Button
				onClick={() => {
					getDocument();
				}}
        disabled={isDisabled || loading}
				loading={loading}
				className={`${loading || isDisabled ? 'opacity-50' : ''} bg-color0 text-white flex items-center gap-1 ${className}`}
			>
				<FaRegFilePdf className="text-lg" />
				<span>{label}</span>
			</Button>
		</PrivateComponent>
	);
};

export default PrintDocumentButton;
