import { Spinner } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheckDouble } from 'react-icons/bi';
import { OFFER_2_COVER_PHASES, OFFER_2_STEP_PHASES } from '../../../../../../../../../utils/offerPhases.util';
import { ProcurementOfferContext } from '../Controller';

const OfferNavbar = () => {
	const { t } = useTranslation();
	const { activePageIdx, setActivePageIdx } = useContext(ProcurementOfferContext);

	const { administratifOffer, commercialOffer, loadingAdministratifOffer, loadingCommercialOffer, offerPhase, is2Cover } = useContext(ProcurementOfferContext);

	return (
		<div className="offer-navbar mb-3">
			<ul className="h-full flex">
				<li
					className={
						'select-none transition-all duration-300 h-full w-56 gap-1 items-center py-2 flex justify-center font-bold cursor-pointer rounded-t-md ' +
						(activePageIdx === 0 ? 'bg-color0 text-white' : 'bg-white text-color1 hover:bg-gray-200')
					}
					onClick={() => {
						setActivePageIdx(0);
					}}
				>
					{t('LABEL.OVERVIEW')}
				</li>

				<li
					className={`select-none transition-all duration-300 h-full w-56 gap-1 items-center py-2 flex justify-center font-bold rounded-t-md ${
						is2Cover
							? offerPhase === OFFER_2_COVER_PHASES.PRA
								? 'text-red-200 hover:bg-gray-200 cursor-default'
								: activePageIdx === 1
								? 'bg-color0 text-white cursor-pointer'
								: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
							: offerPhase < OFFER_2_STEP_PHASES.TECHNICAL
							? 'text-red-200 hover:bg-gray-200 cursor-default'
							: activePageIdx === 1
							? 'bg-color0 text-white cursor-pointer'
							: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
					}`}
					onClick={() => {
						if (is2Cover && offerPhase === OFFER_2_COVER_PHASES.PRA) return;
						if (!is2Cover && offerPhase < OFFER_2_STEP_PHASES.TECHNICAL) return;
						setActivePageIdx(1);
					}}
				>
					{t('LABEL.TECHNICAL')}
					{loadingAdministratifOffer && <Spinner className="w-4 h-4" color={activePageIdx === 1 ? 'white' : 'red.500'} />}
					{!loadingAdministratifOffer && administratifOffer !== null && administratifOffer !== undefined && (
						<BiCheckDouble className="w-5 h-5" color={administratifOffer.status === 'DRAFT' ? 'orange' : 'green'} />
					)}
				</li>

				<li
					className={`select-none transition-all duration-300 h-full w-56 gap-1 items-center py-2 flex justify-center font-bold rounded-t-md ${
						is2Cover
							? offerPhase === OFFER_2_COVER_PHASES.PRA
								? 'text-red-200 hover:bg-gray-200 cursor-default'
								: activePageIdx === 2
								? 'bg-color0 text-white cursor-pointer'
								: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
							: offerPhase < OFFER_2_STEP_PHASES.COMMERCIAL
							? 'text-red-200 hover:bg-gray-200 cursor-default'
							: activePageIdx === 2
							? 'bg-color0 text-white cursor-pointer'
							: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
					}`}
					onClick={() => {
						if (is2Cover && offerPhase === OFFER_2_COVER_PHASES.PRA) return;
						if (!is2Cover && offerPhase < OFFER_2_STEP_PHASES.COMMERCIAL) return;
						setActivePageIdx(2);
					}}
				>
					{t('LABEL.COMMERCIAL')}
					{loadingCommercialOffer && <Spinner className="w-4 h-4" color={activePageIdx === 2 ? 'white' : 'red.500'} />}
					{!loadingCommercialOffer && commercialOffer !== null && commercialOffer !== undefined && (
						<BiCheckDouble className="w-5 h-5" color={commercialOffer.status === 'DRAFT' ? 'orange' : 'green'} />
					)}
				</li>
			</ul>
			<div className="bg-color0" style={{ height: '2px' }} />
		</div>
	);
};

export default OfferNavbar;
