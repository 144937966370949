import {
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import moment from "moment";
import { AanwijzingContext } from "../Controller";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import NextStepButton from "../../../NextStepButton";
import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";
import { ProcurementDetailContext } from "../../../../Controller";

const Row = ({ data }) => {
  const { handleDetailMenu } = useContext(AanwijzingContext);

  return (
    <Tr
      className="hover:bg-gray-50 cursor-pointer"
      onClick={() => handleDetailMenu(true, data)}
    >
      <Td>{data.eventName}</Td>
      <Td>{data?.venue}</Td>
      <Td>{moment(data?.aanwijzingFrom).format("LLL")}</Td>
      <Td>{moment(data?.aanwijzingUntil).format("LLL")}</Td>
      <Td>{data?.status}</Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const AanwijzingTable = () => {
  const {procurement} = useContext(ProcurementDetailContext);
  const {
    handleCreate,
    aanwijzing,
    loadingAanwijzing,
    totalData,
    limit,
    tableForm,
  } = useContext(AanwijzingContext);
  const { userData } = useContext(AuthContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-between items-center">
        <span
          className="font-semibold text-gray-700"
          style={{ borderBottom: "1px solid #777" }}
        >
          {t("LABEL.PROCUREMENT_AANWIJZING")}
        </span>
        <div className="mb-2 gap-2 flex justify-between items-center">
          {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
            <PrivateComponent accessName="BUYER_EDIT">
              <Button size="sm" onClick={() => handleCreate(true)}>
                <BiPlus className="mr-1" />{" "}
                {t("LABEL.ADD", { field: t("LABEL.PROCUREMENT_AANWIJZING") })}
              </Button>
            </PrivateComponent>
          ) : (
            ""
          )}
        </div>
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_AANWIJZING_EVENT_NAME")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_AANWIJZING_VENUE")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_AANWIJZING_FROM")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_AANWIJZING_UNTIL")}
            </Th>
            <Th className="text-red-500">{t("LABEL.STATUS")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingAanwijzing ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !aanwijzing.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">
                  {t("TEXT.DATA_NOT_FOUND")}
                </p>
              </Td>
            </Tr>
          ) : (
            aanwijzing.map((aanwijzing, iAanwijzing) => (
              <Row key={iAanwijzing} data={aanwijzing} />
            ))
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
      <NextStepButton isDisabled={!(totalData > 0)} to="participant" url="participant" className='mt-4' toLabel={`Peserta Pengadaan`} />
    </div>
  );
};

export default AanwijzingTable;
