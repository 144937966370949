import React, { useContext } from "react";
import { motion } from "framer-motion";
import { AuctionContext } from "../../../../Controller";
import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import numeral from "numeral";
import { BiCrown } from "react-icons/bi";
import { userRouteSlug } from "../../../../../../../routes/userRoutes";
import { Link } from "react-router-dom";
import { AuctionLiveContext } from "../Controller";
import moment from "moment";

const AuctionMaterial = ({ data }) => {
  const { bidMaterialRank, auctionStatus } = useContext(AuctionLiveContext);

  const materialPrice =
    (data?.reqRequisitionMaterial?.price / data?.reqRequisitionMaterial?.valuation) *
    data?.reqRequisitionMaterial?.quantity;

  return (
    <>
      <Tr>
        <Td className="bg-gray-50 p-3 flex gap-2 justify-between items-center">
          <div className="w-2 h-2 bg-red-500 rounded-full" />
          <span className="flex-1">{data?.reqRequisitionMaterial?.material?.description}</span>
          <span>Qty: {data?.reqRequisitionMaterial?.quantity}</span>
          <span className="flex-1 text-right font-semibold">
            {numeral(materialPrice).format("0,0.00")} {data?.reqRequisitionMaterial?.currency?.currency}
          </span>
        </Td>
      </Tr>

      <Tr>
        {bidMaterialRank
          ?.filter(
            (bid) =>
              data?.reqRequisitionMaterial?.material?.materialId ===
              bid?.auctionMaterial?.reqRequisitionMaterial?.material?.materialId
          )
          ?.map((bidHistory, iBidHistory) => (
            <Td className="p-3 flex items-center gap-2" key={iBidHistory}>
              <div className="w-4 mr-4 flex justify-center">
                {iBidHistory === 0 ? (
                  <BiCrown className="text-yellow-500" />
                ) : (
                  <span className="font-semibold">{iBidHistory + 1}</span>
                )}
              </div>
              {auctionStatus === "active" ? (
                <span className="font-semibold flex-1">{window.btoa(bidHistory?.vendor).substring(0, 6)}</span>
              ) : (
                <Link
                  className="font-semibold text-blue-600 hover:text-blue-500 flex-1"
                  to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${bidHistory?.vendor}`}
                  target="_blank"
                >
                  {bidHistory?.vendor}
                </Link>
              )}
              <span className="flex-1">{moment(bidHistory?.createdAt).format("DD/MM/YYYY H:m:s")}</span>
              <span className="w-40 text-right">
                {numeral(bidHistory?.subPrice).format("0,0.00")}{" "}
                {bidHistory?.auctionMaterial?.reqRequisitionMaterial?.currency?.currency}
              </span>
            </Td>
          ))}
      </Tr>
    </>
  );
};

const Material = () => {
  const { auction } = useContext(AuctionContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
      <div className="w-full bg-white rounded-md text-sm p-4">
        <Table variant="simple">
          <Tbody>
            {auction?.auctionMaterials?.map((auctionMaterial, iAuctionMaterial) => (
              <AuctionMaterial key={iAuctionMaterial} data={auctionMaterial} />
            ))}
          </Tbody>
        </Table>
      </div>
    </motion.div>
  );
};

export default Material;
