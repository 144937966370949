import axios from "axios";
import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../routes/userRoutes";
import { cookiesVars } from "../../utils/cookies.util";

export const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthController = ({ children }) => {
  const navigate = useNavigate();

  // User Data
  const [userData, setUserData] = useState();
  const [roles, setRoles] = useState([]);
  const [access, setAccess] = useState([]);
  const [token, setToken] = useState();
  const [isLogin, setIsLogin] = useState(null);

  // Verify Token
  const [loadingVerify, setLoadingVerify] = useState(false);
  useEffect(() => {
    const token = Cookies.get(cookiesVars.USER_DATA);

    if (token) {
      setLoadingVerify(true);
      axios
        .post("auth/verify-token", { token })
        .then((res) => {
          const { user } = res.data;
          setUserData(user);
          setIsLogin(true);
          setToken(token);
        })
        .catch((err) => {
          Cookies.remove(cookiesVars.USER_DATA);
          setIsLogin(false);
        })
        .finally(() => {
          setLoadingVerify(false);
        });
    } else {
      setIsLogin(false);
    }
  }, []);

  // Access
  useEffect(() => {
    let mounted = true;

    if (token) {
      axios.get("/access", { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
        const newAccess = res.data?.access;

        if (mounted) {
          setAccess(newAccess);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [token]);

  // Roles
  useEffect(() => {
    let mounted = true;

    const getRoles = () => {
      axios.get(`/role`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
        const roles = res.data?.roles;

        if (mounted) {
          setRoles(roles);
        }
      });
    };

    token && getRoles();

    return () => {
      mounted = false;
    };
  }, [token]);

  // Login
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState();
  const login = ({ username, password }) => {
    setLoginLoading(true);

    axios
      .post("auth/login", {
        username,
        password,
      })
      .then((res) => {
        const { user, token } = res.data;

        // Set User Data & Login State
        setUserData(user);
        setIsLogin(true);
        setToken(token);
        Cookies.set(cookiesVars.USER_DATA, token);
        setLoginError();

        // Redirect To Panel
        navigate(userRouteSlug.PANEL);
      })
      .catch((err) => {
        const res = err?.response?.data;
        setLoginError(`ERROR.${res?.message}`);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  // Logout
  const logout = () => {
    // setUserData();
    // setIsLogin(false);
    Cookies.remove(cookiesVars.USER_DATA);
    window.location.reload();
  };

  // Refetch Token
  const refetchToken = () => {
    axios
      .post(
        "auth/refetch-token",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const { user, token } = res.data;

        setUserData(user);
        setToken(token);
        Cookies.set(cookiesVars.USER_DATA, token);
      });
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isBuyer, setIsBuyer] = useState(false);
  const [isGm, setIsGm] = useState(false);
  const [isSgm, setIsSgm] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  useEffect(() => {
    roles?.map((role) => {
      if (role.role === "GM") setIsGm(true);
      if (role.role === "SGM") setIsSgm(true);
      if (role.role === "Buyer") setIsBuyer(true);
      if (role.role === "Vendor") setIsVendor(true);
    })
  }, [roles])

  useEffect(() => {
    if (!userData) return;
    setIsAdmin(userData?.type === "admin");
    setIsUser(userData?.type === "user");
  }, [userData])

  return (
    <Provider
      value={{
        userData,
        token,
        access,
        roles,
        isLogin,
        loadingVerify,
        login,
        loginLoading,
        loginError,
        logout,
        refetchToken,
        
        isBuyer,
        isGm,
        isSgm,
        isVendor,
        isAdmin,
        isUser
      }}
    >
      {children}
    </Provider>
  );
};
export default AuthController;
