import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaClipboardList, FaUserAlt } from 'react-icons/fa';
import SchedulePanel from './SchedulePanel';
import { ProcurementDetailContext } from '../../../../../Controller';
import TitleBar from '../../../../../../../../../../components/TitleBar';
import VendorTable from './VendorTable';
import Action from './Action';

const OverviewPage = () => {
	const { t } = useTranslation();

	const { procurementSchedule, loadingProcurementSchedule } = useContext(ProcurementDetailContext);

	return (
		<div className="flex flex-col gap-6">
			<div>
				<TitleBar icon={FaClipboardList} title="Jadwal Prakualifikasi" />
				<SchedulePanel start={procurementSchedule?.prequalificationStart} end={procurementSchedule?.prequalificationEnd} loading={loadingProcurementSchedule} />
			</div>
			<div>
				<TitleBar icon={FaUserAlt} title={t('LABEL.VENDOR')} />
				<VendorTable />
        <Action />
			</div>
		</div>
	);
};

export default OverviewPage;
