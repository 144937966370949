import { Button } from "@chakra-ui/button";
import { useContext, useState } from "react";
import DynamicComponent from "../../../components/DynamicComponent";
import PublicAuth from "../../../controllers/auth/PublicAuth";
import CompleteSection from "./components/CompleteSection";
import AccountForm from "./components/forms/AccountForm";
import BasicInformationForm from "./components/forms/BasicInformationForm";
import CompanyProfileForm from "./components/forms/CompanyProfileForm";
import DocumentForm from "./components/forms/DocumentForm";
import Stepper from "./components/Stepper";
import RegisterController, { RegisterContext } from "./Controller";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { AnimatePresence, motion } from "framer-motion";
import BaseCard from "../../templates/public/BaseCard";

const Register = () => {
  const { t } = useTranslation();
  const {
    basicInformationForm,
    companyProfileForm,
    documentForm,
    accountForm,
    handleSubmit,
    loadingSubmit,
    isComplete,
  } = useContext(RegisterContext);

  const sliderItems = [
    {
      name: "Basic Information",
      label: t("LABEL.BASIC_INFORMATION"),
      Component: BasicInformationForm,
      form: basicInformationForm,
    },
    {
      name: "Company Profile",
      label: t("LABEL.COMPANY_PROFILE"),
      Component: CompanyProfileForm,
      form: companyProfileForm,
    },
    { name: "Document", label: t("LABEL.DOCUMENT"), Component: DocumentForm, form: documentForm },
    { name: "Account", label: t("LABEL.ACCOUNT"), Component: AccountForm, form: accountForm },
  ];
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0);
  const [isCaptchaPassed, setCaptchaPassed] = useState(false);

  return (
    <>
      <PublicAuth />

      <BaseCard>
        {!isComplete ? (
          <>
            <Stepper items={sliderItems} active={sliderActiveIndex} />
            <div className="flex flex-col items-center p-6">
              <span className="text-sm text-gray-500">
                {t("LABEL.STEP")} {sliderActiveIndex + 1}
              </span>
              <h1 className="montserrat font-semibold text-2xl text-gray-700">
                {sliderItems[sliderActiveIndex]?.label}
              </h1>
            </div>
            <div className="flex-1 flex items-center">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  key={sliderItems[sliderActiveIndex]?.name}
                  className="mt-4 w-full px-8 md:px-80 py-1 grid md:grid-cols-2 gap-12 gap-y-6 md:gap-y-4"
                >
                  <DynamicComponent component={sliderItems[sliderActiveIndex]?.Component} />
                </motion.div>
              </AnimatePresence>
            </div>
            <div className="flex justify-between items-end p-8 md:p-16 md:px-80">
              {sliderActiveIndex != 0 ? (
                <Button
                  className="bg-gray-200 text-gray-600 text-sm"
                  onClick={() => setSliderActiveIndex(sliderActiveIndex - 1)}
                >
                  {t("LABEL.BACK")}
                </Button>
              ) : (
                <div />
              )}
              {sliderActiveIndex != sliderItems.length - 1 ? (
                <Button
                  className="bg-gradient-primary text-white text-sm"
                  onClick={sliderItems[sliderActiveIndex]?.form?.handleSubmit(() =>
                    setSliderActiveIndex(sliderActiveIndex + 1)
                  )}
                >
                  {t("LABEL.NEXT")}
                </Button>
              ) : (
                <div className="flex flex-col items-end gap-4">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(val) => setCaptchaPassed(!!val)}
                  />
                  <div>
                    <Button
                      className="bg-gradient-primary text-white text-sm"
                      onClick={sliderItems[sliderActiveIndex]?.form.handleSubmit(() => handleSubmit())}
                      disabled={!isCaptchaPassed}
                      isLoading={loadingSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <CompleteSection />
        )}
      </BaseCard>
    </>
  );
};

export default () => (
  <RegisterController>
    <Register />
  </RegisterController>
);
