import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VendorContext } from "../Controller";

const ModalReject = () => {
  const { t } = useTranslation();
  const { isModalRejectOpen, handleReject, loadingReject, rejectWinner } = useContext(VendorContext);

  return (
    <Modal onClose={() => handleReject(false)} size="md" isOpen={isModalRejectOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>{t("LABEL.REJECT_WINNER")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.REJECT_WINNER_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleReject(false)} colorScheme="whiteAlpha">
            {t("LABEL.CANCEL")}
          </Button>
          <Button isLoading={loadingReject} onClick={rejectWinner} colorScheme="red">
            {t("LABEL.REJECT_WINNER")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalReject;
