import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiLock } from "react-icons/bi";
import { BsFillCaretRightFill } from "react-icons/bs";
import { AccountContext } from "../Controller";
import ModalChangePassword from "./ModalChangePassword";

const AccountOptions = () => {
  const { t } = useTranslation();
  const { setModalChangePasswordOpen } = useContext(AccountContext);

  return (
    <div className="flex flex-col gap-3 text-gray-600">
      <button
        className="bg-white hover:bg-gray-50 rounded-md p-4 text-left flex justify-between items-center"
        onClick={() => setModalChangePasswordOpen(true)}
      >
        <div className="flex items-center gap-2">
          <BiLock />
          <span className="font-semibold">{t("LABEL.CHANGE_PASSWORD")}</span>
        </div>
        <BsFillCaretRightFill />
      </button>

      <ModalChangePassword />
    </div>
  );
};

export default AccountOptions;
