import { Button, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEditAlt, BiError } from "react-icons/bi";
import PrivateComponent from "../PrivateComponent";
import { AuthContext } from "../../controllers/auth/AuthController";
import ModalConfirmSubmitReapproval from "./ModalConfirmSubmitReapproval";
import axios from "axios";

const InfoNeedApproval = ({ isFailsExist = false }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { userData, token, roles, refetchToken } = useContext(AuthContext);

  // Reject Message
  const [rejectMessage, setRejectMessage] = useState();
  useEffect(() => {
    let mounted = true;

    const getRejectMessage = () => {
      axios
        .get(`/vendor-approval/reject-message/${userData?.company?.registrationCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted && res?.data?.rejectMessage) setRejectMessage(res?.data?.rejectMessage);
        });
    };

    token && userData?.type === "user" && getRejectMessage();

    return () => {
      mounted = false;
    };
  }, [userData, token]);

  // Is Company Need Reapproval
  const [needReapprovals, setNeedReapprovals] = useState([]);
  const getCompanyNeedReapprovals = async () => {
    const isVendorOrAdmin = !!roles?.find((role) => role.role === "Vendor" || role.role === "Admin");
    if (isVendorOrAdmin) {
      axios
        .get(`/need-reapproval/${userData?.company?.registrationCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setNeedReapprovals(res.data?.needReapproval);
        });
    }
  };
  useEffect(() => {
    userData && token && getCompanyNeedReapprovals();
  }, [userData, token, roles]);

  // Submit Reapproval
  const [isModalConfirmSubmitReapprovalOpen, setModalConfirmSubmitReapprovalOpen] = useState(false);
  const [loadingSubmitReapproval, setLoadingSubmitReapproval] = useState(false);
  const submitReapproval = () => {
    setLoadingSubmitReapproval(true);

    axios
      .post(
        `/vendor-approval/submit-approval/${userData?.company?.registrationCode}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        refetchToken();
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setModalConfirmSubmitReapprovalOpen(false);
        setLoadingSubmitReapproval(false);
      });
  };

  // Is DPT Expired
  const [dptExpiration, setDptExpiration] = useState();
  useEffect(() => {
    let mounted = true;

    const getDptExpiration = () => {
      axios
        .get(`/company/dpt-expiration/${userData?.company?.registrationCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted && res?.data?.expiration) setDptExpiration(res?.data?.expiration);
        });
    };

    token && getDptExpiration();

    return () => {
      mounted = false;
    };
  }, [userData, token]);

  if (!needReapprovals?.length && !dptExpiration?.isExpired) return <></>;
  return (
    <>
      <div className="bg-white rounded-md p-4 flex justify-between items-center gap-8">
        <div className="flex-1 flex items-center gap-4">
          <BiEditAlt className="text-blue-500 text-2xl" />
          <div className="w-full flex flex-col">
            <span className="text-gray-600 font-semibold text-base">
              {!!needReapprovals?.length ? t("TEXT.DATA_NEED_APPROVAL_AFTER_ADJUSTMENT") : t("TEXT.DPT_EXPIRED")}
            </span>
            <span className="text-xs text-gray-600">
              {!!needReapprovals?.length ? t("TEXT.SUBMIT_APPROVAL_AFTER_ADJUSTMENT") : t("TEXT.SUBMIT_DPT_EXTENSION")}
            </span>
            {rejectMessage && (
              <>
                <hr className="my-2" />
                <p className="font-semibold text-xs text-gray-600 flex items-center gap-2">
                  <BiError className="text-yellow-500" />
                  {t("TEXT.DATA_REJECTED")}
                </p>
                <p className="text-xs p-2 bg-gray-100 rounded-md">{rejectMessage}</p>
              </>
            )}
          </div>
        </div>

        <div>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => setModalConfirmSubmitReapprovalOpen(true)}
            disabled={isFailsExist}
          >
            {t("TEXT.SUBMIT_REAPPROVAL")}
          </Button>
          {isFailsExist && (
            <div className="mt-1 flex gap-1 items-center">
              <BiError className="text-yellow-500" />
              <p className="text-xs">{t("TEXT.COMPLETE_YOUR_PROFILE")}</p>
            </div>
          )}
        </div>
      </div>

      <ModalConfirmSubmitReapproval
        isOpen={isModalConfirmSubmitReapprovalOpen}
        setOpen={setModalConfirmSubmitReapprovalOpen}
        loading={loadingSubmitReapproval}
        submit={submitReapproval}
      />
    </>
  );
};

export default ({ ...props }) => (
  <PrivateComponent accessName="VENDOR_SUBMIT_REAPPROVAL">
    <InfoNeedApproval {...props} />
  </PrivateComponent>
);
