import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NegotiationContext } from "../../Controller";

const ModalPropose = () => {
  const { t } = useTranslation();
  const {
    handleUpdateNotesOpen,
    isOpenUpdate,
    loadingNotesUpdate,
    updateStatusNotes,
  } = useContext(NegotiationContext);

  return (
    <Modal
      onClose={() => handleUpdateNotesOpen(false)}
      size="md"
      isOpen={isOpenUpdate}
    >
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>{t("LABEL.PROCUREMENT_NEGOTIATION_PROPOSE")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.PROPOSE_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleUpdateNotesOpen(false)}>
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingNotesUpdate}
            onClick={updateStatusNotes}
            className="transition-all duration-500"
          >
            {t("LABEL.SAVE")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPropose;
