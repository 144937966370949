import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import BankAccountDetail from "./components/BankAccountDetail";
import BankAccountList from "./components/BankAccountList";
import BankAccountNew from "./components/BankAccountNew";
import BankAccountController, { BankAccountContext } from "./Controller";

const BankAccount = () => {
  const { isDetailMenu, isCreate } = useContext(BankAccountContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <BankAccountNew /> : isDetailMenu ? <BankAccountDetail /> : <BankAccountList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <BankAccountController>
    <BankAccount />
  </BankAccountController>
);
