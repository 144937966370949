import { useToast } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const ExpertContext = createContext();
const { Provider } = ExpertContext;

const ExpertController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        expertName: newForm?.expertName,
        birthDate: newForm?.birthDate ? new Date(newForm?.birthDate) : null,
        idCardNumber: newForm?.idCardNumber,
        lastEducation: newForm?.lastEducation,
        studyField: newForm?.studyField,
        employmentStatus: newForm?.employmentStatus?.employmentStatus,
        experiences: newForm?.expertExperiences?.map((experience) => ({
          experienceName: experience?.experienceName,
          sinceYear: new Date(`01/01/${experience?.sinceYear}`),
          untilYear: new Date(`01/01/${experience?.untilYear}`),
        })),
        certificates: newForm?.expertCertificates?.map((certificate) => ({
          expertCertificateId: certificate?.expertCertificateId,
          certificateName: certificate?.certificateName,
        })),
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeExpert, setActiveExpert] = useState();
  const handleDetailMenu = (isOpen, expert = null) => {
    setDetailMenu(isOpen);
    setActiveExpert(expert);
    isOpen && resetForm(expert);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Experts
  const [experts, setExperts] = useState([]);
  const [loadingExperts, setLoadingExperts] = useState(false);
  const [reloadExperts, setReloadExperts] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getExperts = async () => {
      setLoadingExperts(true);
      axios
        .get(`/expert/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newExperts = res?.data?.experts;
            setExperts(newExperts);

            if (activeExpert) {
              const newActiveExpert = newExperts.find((expert) => expert?.expertId === activeExpert?.expertId) || null;
              setActiveExpert(newActiveExpert);
              resetForm(newActiveExpert);
            }
          }
        })
        .finally(() => {
          setReloadExperts(false);
          setLoadingExperts(false);
        });
    };

    userData && token && reloadExperts && getExperts();

    if (reloadExperts) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadExperts]);

  // -------------------------------
  // Master
  // -------------------------------

  // Expert Type
  const [employmentStatus, setEmploymentStatus] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/employment-status`).then((res) => {
      if (mounted) {
        setEmploymentStatus(res?.data?.employmentStatus);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createExpert = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        birthDate: new Date(payload?.birthDate),
        experiences: payload?.experiences?.map((experience) => ({
          ...experience,
          sinceYear: moment(experience?.sinceYear).format("YYYY"),
          untilYear: moment(experience?.sinceYear).format("YYYY"),
        })),
      })
    );
    formData.append("expertAttachment", payload?.expertAttachment[0]);
    formData.append("idCardAttachment", payload?.idCardAttachment[0]);
    payload?.certificates?.map((certificate) => {
      formData.append("certificateAttachments", certificate?.attachment?.[0]);
    });

    axios
      .post(`expert/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadExperts(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeExpert);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateExpert = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        ...(payload?.birthDate ? { birthDate: new Date(payload.birthDate) } : {}),
        experiences: payload?.experiences?.map((experience) => ({
          ...experience,
          sinceYear: moment(experience?.sinceYear).format("YYYY"),
          untilYear: moment(experience?.sinceYear).format("YYYY"),
        })),
      })
    );
    payload?.idCardAttachment && formData.append("idCardAttachment", payload?.idCardAttachment[0]);
    payload?.expertAttachment && formData.append("expertAttachment", payload?.expertAttachment[0]);
    payload?.certificates?.map((certificate, iCertificate) => {
      formData.append(
        "certificateAttachments",
        certificate?.attachment?.[0] ||
          new File([""], activeExpert?.expertCertificates?.[iCertificate]?.certificateAttachment)
      );
    });

    axios
      .patch(`expert/${regCode}/${activeExpert?.expertId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadExperts(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteExpert = () => {
    setLoadingDelete(true);

    axios
      .delete(`expert/${regCode}/${activeExpert?.expertId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadExperts(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingExperts,
        experts,
        activeExpert,

        employmentStatus,

        isCreate,
        handleCreate,
        loadingCreate,
        createExpert,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateExpert,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteExpert,
      }}
    >
      {children}
    </Provider>
  );
};

export default ExpertController;
