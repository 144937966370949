import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Controller";
import { IoIosArrowForward } from "react-icons/io";
import _ from "lodash";
import { motion } from "framer-motion";
import { BiPencil, BiPlus } from "react-icons/bi";
import { BsFillStarFill } from "react-icons/bs";
import PrivateComponent from "../../../../../../../components/PrivateComponent";
import { Spinner } from "@chakra-ui/react";

const User = ({ data }) => {
  const { t } = useTranslation();
  const { handleDetailMenu } = useContext(UserContext);

  const isMain = data?.isMain;

  return (
    <button
      className={`text-left p-4 flex justify-between items-center bg-white text-gray-600 `}
      style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
      onClick={() => handleDetailMenu(true, data)}
    >
      <div className={`${data?.isDelete && "opacity-60"}`}>
        <p className={`text-sm font-semibold text-red-500 flex items-center`}>
          {isMain && <BsFillStarFill className="mr-1 text-yellow-500" />}
          {data?.email}
          {data?.isDelete && ` (${t("LABEL.DELETED")})`}
        </p>
        <h2 className="font-semibold text-lg">{data?.name}</h2>
        {(!data?.isApproved || data?.isDelete) && (
          <div className={`flex items-center gap-1 mt-2 text-xs text-blue-500`}>
            <BiPencil />
            <p>{t("TEXT.DATA_NEED_APPROVAL")}</p>
          </div>
        )}
      </div>
      <div>
        <IoIosArrowForward />
      </div>
    </button>
  );
};

const UserList = () => {
  const { users, loadingUsers, handleCreate } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="grid md:grid-cols-2 gap-4">
      {loadingUsers ? (
        <div
          className={`h-20 md:col-span-2 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
          style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {!users.length ? (
            <PrivateComponent accessName="CREATE_COMPANY_PROFILE" doHasAccess={false}>
              <div
                className="p-3 bg-white bg-opacity-40 text-center text-sm text-gray-500"
                style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
              >
                {t("TEXT.DATA_NOT_FOUND")}
              </div>
            </PrivateComponent>
          ) : (
            users.map((user, iUser) => <User key={iUser} data={user} />)
          )}
          <PrivateComponent accessName="CREATE_COMPANY_PROFILE">
            {users.length < 3 && (
              <button
                className={`h-20 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
                style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
                onClick={() => handleCreate(true)}
              >
                <BiPlus />
                <p>{t("LABEL.ADD", { field: t("LABEL.PIC") })}</p>
              </button>
            )}
          </PrivateComponent>
        </>
      )}
    </motion.div>
  );
};

export default UserList;
