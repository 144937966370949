import React, { useContext } from "react";
import BaseCard from "../../templates/public/BaseCard";
import CompletePage from "./components/CompletePage";
import EmailForm from "./components/EmailForm";
import ForgetPasswordController, { ForgetPasswordContext } from "./Controller";

const ForgetPassword = () => {
  const { isSubmitted } = useContext(ForgetPasswordContext);

  return <BaseCard>{isSubmitted ? <CompletePage /> : <EmailForm />}</BaseCard>;
};

export default () => (
  <ForgetPasswordController>
    <ForgetPassword />
  </ForgetPasswordController>
);
