import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../Controller";

export const ReqRequisitionContext = createContext();
const { Provider } = ReqRequisitionContext;

const ReqRequisitionController = ({ children }) => {
  const { token } = useContext(AuthContext);
  const { procurement } = useContext(ProcurementDetailContext);

  // Get Req Requisitions
  const [reqRequisitions, setReqRequisitions] = useState([]);
  const [loadingReqRequisitions, setLoadingReqRequisitions] = useState(false);
  const [totalPrice, setTotalPrice] = useState([]);
  const [currency, setCurrency] = useState();

  useEffect(() => {
    let mounted = true;

    const getReqRequisitionMaterial = () => {
      const reqRequisitionMaterialIds =
        procurement?.procurementRequisitionMaterials
          ?.map(
            (material) =>
              material?.requisitionMaterial?.reqRequisitionMaterialId
          )
          ?.join(";");

      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/req-requisition/by-code?code=${reqRequisitionMaterialIds}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newReqRequisitions = res?.data?.reqRequisitions;
            setReqRequisitions(newReqRequisitions);
            setTotalPrice(
              newReqRequisitions?.reduce(
                (prev, curr) =>
                  prev +
                  curr?.reqRequisitionMaterial
                    ?.map((val) => parseInt(val?.materialPrice))
                    .reduce((a, b) => a + b),
                0
              )
            );
            newReqRequisitions?.map((reqRequisition, iReqRequisition) => {
              reqRequisition?.reqRequisitionMaterial.map((currencies) => {
                setCurrency(currencies?.currency?.currency);
              });
            });
          }
        })
        .finally(() => {
          setLoadingReqRequisitions(false);
        });
    };

    procurement && getReqRequisitionMaterial();

    return () => {
      mounted = false;
    };
  }, [procurement]);

  return (
    <Provider
      value={{ reqRequisitions, loadingReqRequisitions, totalPrice, currency }}
    >
      {children}
    </Provider>
  );
};

export default ReqRequisitionController;
