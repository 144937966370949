import { Spinner, Table, Tbody, Td, Button, Th, Thead, Tr } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcurementOfferEvaluationContext } from '../../../Controller';
import { useState } from 'react';
import { useEffect } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { API_URLS } from '../../../../../../../../../../../../utils/apiURLs.util';
import { EvaluationContext } from '../Controller';
import { HelperContext } from '../../../../../../../../../../../../controllers/HelperController';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../../../utils/offerPhases.util';
import PrivateComponent from '../../../../../../../../../../../../components/PrivateComponent';
import { ProcurementDetailContext } from '../../../../../../../Controller';
import PrintDocumentButton from '../../../../../../../../../../../../components/PrintDocumentButton';

const Row = ({ vendor, idx }) => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { evaluation, setEvaluation, offerEvaluationPhase } = useContext(ProcurementOfferEvaluationContext);
	const { evaluationType } = useContext(EvaluationContext);

	const [evaluationVendor, setEvaluationVendor] = useState(null);
	const [idxVendorInEvaluation, setIdxVendorInEvaluation] = useState(null);
	const [isQualified, setIsQualified] = useState(false);
	const [loadingAccept, setLoadingAccept] = useState(false);
	const [loadingReject, setLoadingReject] = useState(false);

	useEffect(() => {
		setIsQualified(false);
		if (vendor.status.status !== 'ACCEPT') return;
		const admOffer = vendor.procurementAdministrationOffer;
		const comOffer = vendor.procurementCommercialOffer;

		if (!evaluationType) {
			if (!admOffer || !comOffer) return;
			if (admOffer.status !== 'SUBMITTED' || comOffer.status !== 'SUBMITTED') return;
		} else {
			if (evaluationType === 'COM') {
				if (!comOffer) return;
				if (comOffer.status !== 'SUBMITTED') return;
			}

			if (evaluationType === 'ADM') {
				if (!admOffer) return;
				if (admOffer.status !== 'SUBMITTED') return;
			}
		}
		setIsQualified(true);
	}, [vendor]);

	useEffect(() => {
		if (!evaluation || !isQualified) return;
		const temp = evaluation.procurementOfferEvaluationVendors.find((evalVendor, i) => {
			if (evalVendor.procurementVendorId === vendor.procurementVendorId) {
				setIdxVendorInEvaluation(i);
				return true;
			}
		});
		setEvaluationVendor(temp);
	}, [evaluation, isQualified, evaluationVendor]);

	const sendEvaluation = async (status) => {
		const procurementVendorId = vendor.procurementVendorId;
		const procurementOfferEvaluationId = evaluation.id;

		if (status === 'ACCEPTED') setLoadingAccept(true);
		else setLoadingReject(true);

		const uri = `${API_URLS.REACT_APP_TMS_API}/procurement-offer-evaluation-vendor`;
		const { response } = await httpRequest({
			url: uri,
			method: 'POST',
			payload: {
				procurementVendorId,
				procurementOfferEvaluationId,
				status: !evaluationType ? status : null,
				statusAdministration: evaluationType === 'ADM' ? status : null,
				statusCommercial: evaluationType === 'COM' ? status : null,
			},
		});
		if (status === 'ACCEPTED') setLoadingAccept(false);
		else setLoadingReject(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		evaluation.procurementOfferEvaluationVendors.push(response.data.procurementOfferEvaluationVendor);
		setEvaluation({
			...evaluation,
		});
	};

	const updateStatus = async (status) => {
		const id = evaluationVendor.id;
		if (status === 'ACCEPTED') setLoadingAccept(true);
		else setLoadingReject(true);

		const uri = `${API_URLS.REACT_APP_TMS_API}/procurement-offer-evaluation-vendor/${id}/`;
		const { response } = await httpRequest({
			url: uri,
			method: 'PATCH',
			payload: {
				status: !evaluationType ? status : null,
				statusAdministration: evaluationType === 'ADM' ? status : null,
				statusCommercial: evaluationType === 'COM' ? status : null,
			},
		});
		if (status === 'ACCEPTED') setLoadingAccept(false);
		else setLoadingReject(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		const newVendor = response.data.procurementOfferEvaluationVendor;
		evaluation.procurementOfferEvaluationVendors[idxVendorInEvaluation] = newVendor;
		setEvaluation({ ...evaluation });
	};

	const statusKey = !evaluationType ? 'status' : evaluationType === 'COM' ? 'statusCommercial' : 'statusAdministration';

	return (
		<Tr className="hover:bg-gray-50">
			<Td
				style={{
					minWidth: '75px',
					maxWidth: '75px',
					width: '75px',
				}}
			>
				{idx + 1}
			</Td>
			<Td className="w-3/6">{vendor.company?.companyName}</Td>
			<Td className="flex gap-4">
				{!isQualified ? (
					<div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div>
				) : (
					<>
						{evaluation[statusKey] !== 'SUBMITTED' ? (
							<>
								{offerEvaluationPhase === OFFER_2_COVER_PHASES.POST ? (
									<div className="text-color2 font-semibold">{t('TEXT.POST_EVALUATION_WARNING')}</div>
								) : (
									<PrivateComponent accessName="BUYER_EDIT" placeholder={<div className=" mt-auto mb-auto text-color2 font-semibold italic">Pending</div>}>
										<Button
											color={evaluationVendor && evaluationVendor[statusKey] === 'ACCEPTED' ? 'white' : 'green'}
											className={`transition-all duration-500 flex gap-1 items-center justify-center ${evaluationVendor && evaluationVendor[statusKey] === 'ACCEPTED' ? 'bg-green-500' : ''}`}
											onClick={() => {
												if (!evaluationVendor) {
													sendEvaluation('ACCEPTED');
													return;
												}
												updateStatus('ACCEPTED');
											}}
											disabled={loadingReject}
											isLoading={loadingAccept}
										>
											<BiCheck className="text-xl" />
											{t('LABEL.QUALIFIED')}
										</Button>
										<Button
											color={evaluationVendor && evaluationVendor[statusKey] === 'REJECTED' ? 'white' : 'red'}
											className={`transition-all duration-500 flex gap-1 items-center justify-center ${evaluationVendor && evaluationVendor[statusKey] === 'REJECTED' ? 'bg-color0' : ''}`}
											onClick={() => {
												if (!evaluationVendor) {
													sendEvaluation('REJECTED');
													return;
												}
												updateStatus('REJECTED');
											}}
											disabled={loadingAccept}
											isLoading={loadingReject}
										>
											<BiX className="text-xl" />
											{t('LABEL.NOT_QUALIFIED')}
										</Button>
									</PrivateComponent>
								)}
							</>
						) : (
							<>
								{evaluationVendor && evaluationVendor[statusKey] === 'ACCEPTED' ? (
									<div className="font-bold text-green-500 italic">{t('LABEL.QUALIFIED')}</div>
								) : (
									<div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div>
								)}
							</>
						)}
					</>
				)}
			</Td>
		</Tr>
	);
};

const VendorEvaluation = () => {
	const { t } = useTranslation();
	const { procurement } = useContext(ProcurementDetailContext);
	const { procurementVendors, loadingProcurementVendors } = useContext(ProcurementOfferEvaluationContext);
	const { errorNotEvaluated, evaluationType } = useContext(EvaluationContext);

	return (
		<div className="mt-3">
			<TitleBar
				title={t('LABEL.VENDOR')}
				rightElement={evaluationType === 'ADM' ? undefined : <PrintDocumentButton label="Cetak BAEP" url={`ba-offer-evaluation/${procurement?.procurementNumber}`} />}
			/>
			<Table className={`${errorNotEvaluated === '' ? '' : 'border-2 border-color0'}`}>
				<Thead>
					<Tr>
						<Th
							style={{
								minWidth: '75px',
								maxWidth: '75px',
								width: '75px',
							}}
							className="text-red-500"
						>
							No.
						</Th>
						<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
						<Th className="text-red-500">{t('LABEL.ACTION')}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loadingProcurementVendors || !procurementVendors ? (
						<Tr>
							<Td colSpan={3}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					) : (
						<>
							{procurementVendors.length === 0 ? (
								<Tr>
									<Td colSpan={3} className="text-center">
										<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
									</Td>
								</Tr>
							) : (
								procurementVendors.map((vendor, idx) => {
									return <Row vendor={vendor} idx={idx} key={idx} />;
								})
							)}
						</>
					)}
				</Tbody>
			</Table>
			{errorNotEvaluated !== '' && <div className="mt-1 text-red-400 text-xs text-center">{errorNotEvaluated}</div>}
		</div>
	);
};

export default VendorEvaluation;
