import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ProcurementNew from "./components/ProcurementNew";
import ProcurementFilter from "./components/ProcurementFilter";
import ProcurementTable from "./components/ProcurementTable";
import ProcurementController, { ProcurementContext } from "./Controller";

const Procurement = () => {
  const { t } = useTranslation();
  const { isCreate } = useContext(ProcurementContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.PROCUREMENT")}</h2>
      </div>

      {isCreate ? (
        <ProcurementNew />
      ) : (
        <div className="grid md:grid-cols-4 gap-4 md:gap-8">
          <div>
            <ProcurementFilter />
          </div>
          <div className="md:col-span-3">
            <ProcurementTable />
          </div>
        </div>
      )}
    </div>
  );
};

export default () => (
  <ProcurementController>
    <Procurement />
  </ProcurementController>
);
