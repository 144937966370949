import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import ShareholderDetail from "./components/ShareholderDetail";
import ShareholderList from "./components/ShareholderList";
import ShareholderNew from "./components/ShareholderNew";
import ShareholderController, { ShareholderContext } from "./Controller";

const Shareholder = () => {
  const { isDetailMenu, isCreate } = useContext(ShareholderContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <ShareholderNew /> : isDetailMenu ? <ShareholderDetail /> : <ShareholderList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <ShareholderController>
    <Shareholder />
  </ShareholderController>
);
