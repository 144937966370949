import { Button, FormControl, FormLabel } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailPattern } from "../../../../utils/regex.util";
import { ForgetPasswordContext } from "../Controller";

const EmailForm = () => {
  const { loadingResetPassword, submitResetPassword } = useContext(ForgetPasswordContext);
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  return (
    <div className="h-full flex justify-center items-center">
      <div className="flex flex-col gap-8">
        <div className="text-center">
          <h1 className="font-bold text-xl md:text-2xl montserrat text-transparent bg-clip-text bg-gradient-primary">
            Reset Password
          </h1>
          <p className="text-sm text-gray-500">{t("TEXT.EMAIL_RESET_PASSWORD")}</p>
          <hr className="my-2" />
        </div>
        <div className="flex gap-4 items-center">
          <InputText
            className={`w-full text-sm`}
            placeholder="email@company.com"
            {...register("email", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.YOURS", { field: "Email" }) }),
              pattern: {
                value: emailPattern,
                message: t("INVALID.EMAIL", { field: t("LABEL.YOURS", { field: "Email" }) }),
              },
            })}
          />
          <Button
            className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 text-white"
            onClick={handleSubmit((val) => submitResetPassword(val?.email))}
            isLoading={loadingResetPassword}
          >
            {t("LABEL.SUBMIT", { field: "" })}
          </Button>
        </div>

        <div />
      </div>
    </div>
  );
};

export default EmailForm;
