import AdministratifForm from './AdministratifForm';
import AdministratifItemTable from './AdministratifItemTable';
import AdministratifRequirementTable from './AdministratifRequirementTable';
import AdministratifAction from './AdministratifAction';
import AdministratifController, { AdministratifContext } from './Controller';
import { Spinner } from '@chakra-ui/react';
import { ProcurementOfferContext } from '../../Controller';
import { useContext } from 'react';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../utils/offerPhases.util';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const OfferAdministratifPage = () => {
	const { t } = useTranslation();
	const { administratifOffer, isNewAdministratifOffer, loadingAdministratifOffer, offerPhase, is2Cover, schedule } = useContext(ProcurementOfferContext);
	const { isEdit } = useContext(AdministratifContext);

	return (
		<div>
			{loadingAdministratifOffer && administratifOffer === undefined && (
				<div className="flex justify-center items-center" style={{ minHeight: '300px' }}>
					<Spinner color="red.500" width={10} height={10} />
				</div>
			)}
			{!loadingAdministratifOffer && (
				<>
					{(offerPhase === OFFER_2_COVER_PHASES.POST && !administratifOffer) ||
					(!is2Cover && !administratifOffer && (!schedule?.technicalQuotationEnd ? true : moment().unix() > moment(schedule?.technicalQuotationEnd).unix())) ? (
						<>
							<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-4">
								<p className="text-gray-700 text-base flex items-center font-semibold text-center justify-center">{t('TEXT.OFFER_NOT_SUBMITTED')}</p>
							</div>
						</>
					) : (
						<>
							<AdministratifForm isEdit={isEdit} isNewAdministratifOffer={isNewAdministratifOffer} administratifOffer={administratifOffer} />
							<AdministratifItemTable />
							<AdministratifRequirementTable />
							<AdministratifAction />
						</>
					)}
				</>
			)}
		</div>
	);
};

export default () => (
	<AdministratifController>
		<OfferAdministratifPage />
	</AdministratifController>
);
