import React, { useContext } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "@chakra-ui/react";
import { BsBuilding } from "react-icons/bs";
import { BiDownload } from "react-icons/bi";
import axios from "axios";
import { AuthContext } from "../../controllers/auth/AuthController";
import PrivateComponent from "../PrivateComponent";
import { saveAs } from "file-saver";

const CompanyOverview = ({ loadingCompanyOverview, companyOverview }) => {
  const { token } = useContext(AuthContext);

  const { t } = useTranslation();
  const downloadDPT = async () => {
    axios
      .get(`/company/download-dpt/${companyOverview?.registrationCode}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const filename = response.headers?.["content-disposition"]?.substring(21);
        const file = new Blob([response.data], { type: response.headers["content-type"] });
        saveAs(file, filename);
      });
  };

  return (
    <div className="p-4 rounded-md bg-gradient-primary text-white">
      {loadingCompanyOverview ? (
        <div className="flex justify-center items-center">
          <Spinner color="white" />
        </div>
      ) : (
        <div className="w-full flex gap-4">
          <div className="flex justify-center items-center">
            <BsBuilding className="text-5xl" />
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <h2 className="m-0 font-bold text-xl">{companyOverview?.companyName}</h2>
            <span>
              {_.startCase(companyOverview?.city?.toLowerCase())}, {companyOverview?.country}
            </span>
          </div>
          <div className="flex flex-col text-right">
            <span className="font-bold text-xl">
              {t(companyOverview?.vendorStatus?.labelI18n || companyOverview?.vendorStatus?.label)}
            </span>
            <span>
              {t("LABEL.REGISTRATION_ID")}: <b>{companyOverview?.registrationCode}</b>
            </span>
            <PrivateComponent accessName="DPT_DOWNLOAD">
              {companyOverview?.vendorStatus?.vendorStatus === "Vendor" && (
                <button onClick={downloadDPT} className="flex text-right items-center justify-end gap-1 font-semibold">
                  <span>{t("LABEL.DOWNLOAD")} DPT</span>
                  <BiDownload />
                </button>
              )}
            </PrivateComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyOverview;
