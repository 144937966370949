import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { VendorContext } from "../Controller";
import { BiPlus, BiTrash, BiDetail } from "react-icons/bi";
import { BsClock, BsCheck, BsX } from "react-icons/bs";
import _ from "lodash";
import { userRouteSlug } from "../../../../../../../../../routes/userRoutes";
import { Link } from "react-router-dom";
import ModalDelete from "./ModalDelete";
import moment from "moment";
import { HelperContext } from "../../../../../../../../../controllers/HelperController";
import { ProcurementDetailContext } from "../../../../Controller";
import { vendorLimit } from "../../../../../../../../../utils/vendorLimit";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import NextStepButton from "../../../NextStepButton";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { showToast } = useContext(HelperContext);
  const { procurement, isApproved, isApprovedByGm } = useContext(ProcurementDetailContext);
  const { procurementSchedule, handleDelete, vendor } = useContext(VendorContext);

  const vendorStatus = {
    draft: { labelI18n: "Draft", icon: () => "", color: "text-gray-900" },
    pending: { labelI18n: "LABEL.PENDING", icon: BsClock, color: "text-yellow-500" },
    accepted: { labelI18n: "LABEL.ACCEPTED", icon: BsCheck, color: "text-green-500" },
    rejected: { labelI18n: "LABEL.REJECTED", icon: BsX, color: "text-red-500" },
  };

  const activeStatus = !isApproved
    ? vendorStatus.draft
    : data?.status?.status === "ACCEPT"
    ? vendorStatus.accepted
    : data?.status?.status === "REJECT"
    ? vendorStatus.rejected
    : vendorStatus.pending;

  return (
    <Tr className="hover:bg-gray-50">
      <Td>
        <Link
          className="font-semibold text-blue-600 hover:text-blue-500"
          to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${data?.vendor}`}
          target="_blank"
        >
          {data?.vendor}
        </Link>
      </Td>
      <Td>{data?.company?.companyName}</Td>
      <Td>
        {_.startCase(data?.company?.city?.toLowerCase())}, {data?.company?.country}
      </Td>
      <Td>
        <div className={`flex gap-2 items-center font-semibold ${activeStatus.color}`}>
          <span>{t(activeStatus.labelI18n)}</span>
          <activeStatus.icon />
        </div>
      </Td>
      <Td className="px-4">
        <div className="w-full h-full flex gap-3 items-center">
          {(!procurementSchedule?.submittedAt || procurementSchedule?.shouldBeReview) && (
            <PrivateComponent accessName="BUYER_EDIT">
              <button
                onClick={() => {
                  handleDelete(true, { procurementVendorId: data?.procurementVendorId });
                }}
              >
                <BiTrash className="text-lg text-red-500" />
              </button>
            </PrivateComponent>
          )}
        </div>
      </Td>
    </Tr>
  );
};

const VendorTable = () => {
  const { vendor, loadingRequisition, totalData, limit, tableForm, handleCreate, procurementSchedule } =
    useContext(VendorContext);
  const { procurementType, isApproved } = useContext(ProcurementDetailContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  const minVendor = vendorLimit[procurementType]?.SUGGESTED_MIN;
  const maxVendor = vendorLimit[procurementType]?.MAX;

  const isValid = vendor && vendor?.length >= minVendor 

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-end">
        {(!procurementSchedule?.submittedAt || procurementSchedule?.shouldBeReview) && vendor?.length < maxVendor && (
          <PrivateComponent accessName="BUYER_EDIT">
            <Button size="sm" onClick={() => handleCreate(true)}>
              <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: t("LABEL.VENDOR") })}
            </Button>
          </PrivateComponent>
        )}
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.REGISTRATION_ID")}</Th>
            <Th className="text-red-500">{t("LABEL.COMPANY_NAME")}</Th>
            <Th className="text-red-500">
              {t("LABEL.CITY")}, {t("LABEL.COUNTRY")}
            </Th>
            <Th className="text-red-500">{t("Status")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingRequisition ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !vendor.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            vendor.map((vendor, iVendor) => <Row key={iVendor} data={vendor} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
      
      <NextStepButton isDisabled={!isValid} url="req-proc-submission" to="reqProcSubmission" className='mt-4' toLabel={"Dokumen Persyaratan"} />
      <ModalDelete />
    </div>
  );
};

export default VendorTable;
