import { Spinner } from '@chakra-ui/react';
import numeral from 'numeral';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { HelperContext } from '../../../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../../../utils/apiURLs.util';
import { ProcurementOfferEvaluationContext } from '../../../Controller';
import { CommercialEvaluationContext } from '../Controller';
import ItemCard from './ItemCard';

const OfferItemEvaluation = () => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { offer, setOffer, loadingOffer } = useContext(CommercialEvaluationContext);
	const { materials, loadingMaterials } = useContext(ProcurementOfferEvaluationContext);

	const [subTotal, setSubTotal] = useState(null);
	const [loadingAccept, setLoadingAccept] = useState(null);
	const [loadingReject, setLoadingReject] = useState(null);

	useEffect(() => {
		if (!materials) return;
		const len = materials.length;
		let temp = [];
		for (let i = 0; i < len; i++) temp.push(false);
		setLoadingAccept([...temp]);
		setLoadingReject([...temp]);
	}, [materials]);

	useEffect(() => {
		if (materials && offer) {
			let temp = 0;
			offer.procurementCommercialOfferRequisitionMaterials.forEach((val, idx) => {
				temp += val.price * materials[idx].qty;
			});
			setSubTotal(temp);
		} else {
			setSubTotal(null);
		}
	}, [materials, offer]);

	const idxExistMaterial = (requisitionMaterialId) => {
		for (let i = 0; i < offer.procurementCommercialOfferRequisitionMaterials.length; i++) {
			const mat = offer.procurementCommercialOfferRequisitionMaterials[i];
			if (mat.requisitionMaterialId === requisitionMaterialId) {
				return i;
			}
		}

		return null;
	};

	const sendEvaluation = async (idxExist, idx, status) => {
		const id = offer.procurementCommercialOfferRequisitionMaterials[idxExist].id;
		const payload = { status, price: null };
		const uri = `${API_URLS.REACT_APP_TMS_API}/procurement-commercial-offer-requisition-material/${id}`;

		if (status === 'ACCEPTED') {
			loadingAccept[idx] = true;
			setLoadingAccept([...loadingAccept]);
		} else {
			loadingReject[idx] = true;
			setLoadingReject([...loadingReject]);
		}
		const { response } = await httpRequest({
			url: uri,
			method: 'PATCH',
			payload,
		});
		if (status === 'ACCEPTED') {
			loadingAccept[idx] = false;
			setLoadingAccept([...loadingAccept]);
		} else {
			loadingReject[idx] = false;
			setLoadingReject([...loadingReject]);
		}

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		offer.procurementCommercialOfferRequisitionMaterials[idxExist] = response.data.procurementCommercialOfferRequisitionMaterial;
		setOffer({
			...offer,
		});
	};

	return (
		<div className="mt-5">
			<TitleBar title={t('LABEL.PROCUREMENT_ITEM')} />
			{!loadingMaterials && materials && materials.length !== 0 && (
				<div className="flex justify-end font-bold mt-3 items-center">
					<div className="bg-color0 flex rounded-md text-white">
						<div className="px-6 py-1">Sub Total :</div>
						<div className="text-center py-1" style={{ width: '400px' }}>
							{subTotal === null || subTotal === 0 ? (
								<>-</>
							) : (
								<>
									{numeral(+subTotal).format('0,0.00')} {materials[0]?.currency.currency}
								</>
							)}
						</div>
					</div>
				</div>
			)}
			<div className="mt-3 flex flex-col gap-3">
				{loadingOffer ? (
					<div className="flex justify-center ">
						<Spinner color="red.500" />
					</div>
				) : (
					<>
						{!offer || !materials || !loadingReject || !loadingAccept ? (
							<>{t('ERROR.undefined')}</>
						) : (
							<>
								{materials.map((mat, idx) => {
									const idxSubmittedMat = offer ? idxExistMaterial(mat.procurementRequisitionMaterialId) : null;
									return (
										<ItemCard
											key={idx}
											material={mat}
											idx={idx}
											idxSubmitted={idxSubmittedMat}
											offer={offer}
											loadingReject={loadingReject}
											setLoadingReject={setLoadingReject}
											loadingAccept={loadingAccept}
											setLoadingAccept={setLoadingAccept}
											sendEvaluation={sendEvaluation}
										/>
									);
								})}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default OfferItemEvaluation;
