import React, { useContext, useState } from "react";
import { Button, Checkbox, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AdminContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../components/inputForm/FormDescription";
import CheckboxGroup from "../../../../../components/inputForm/CheckboxGroup";
import { Password } from "primereact/password";

const RoleItem = ({ role, defaultChecked = false }) => {
  const [active, setActive] = useState(defaultChecked);

  return (
    <Checkbox
      className={`m-1 rounded-md p-2 px-3 ${active ? "bg-blue-100" : "bg-gray-50"}`}
      onChange={() => setActive(!active)}
      value={role}
    >
      <span className="text-sm text-gray-600">{role}</span>
    </Checkbox>
  );
};

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { isCreate, loadingCompany, activeAdmin, form, adminRoles } = useContext(AdminContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Admin Name */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ADMIN_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.name && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ADMIN_NAME")}
            {...register("name", { required: t("INVALID.REQUIRED", { field: t("LABEL.ADMIN_NAME") }) })}
          />
        ) : (
          <FormDescription>{activeAdmin?.name}</FormDescription>
        )}
        {errors?.name && <FormHelperText className="text-red-400 text-xs">{errors?.name?.message}</FormHelperText>}
      </FormControl>

      {/* Admin Username */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ADMIN_USERNAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.username && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ADMIN_USERNAME")}
            {...register("username", { required: t("INVALID.REQUIRED", { field: t("LABEL.ADMIN_USERNAME") }) })}
          />
        ) : (
          <FormDescription>{activeAdmin?.username}</FormDescription>
        )}
        {errors?.username && (
          <FormHelperText className="text-red-400 text-xs">{errors?.username?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Admin Password */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("Password")}
        </FormLabel>
        {isEdit ? (
          <Password
            toggleMask
            feedback={false}
            className="w-full"
            inputClassName={`w-full text-sm ${errors?.password && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("Password")}
            {...register("password", {
              required: t("INVALID.REQUIRED", { field: t("Password") }),
            })}
            onChange={(e) => form.setValue("password", e.target.value)}
          />
        ) : (
          <FormDescription>{activeAdmin?.password}</FormDescription>
        )}
        {errors?.password && (
          <FormHelperText className="text-red-400 text-xs">{errors?.password?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Admin Role */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ADMIN_ROLE")}
        </FormLabel>
        {isEdit ? (
          <CheckboxGroup name="roles" control={control}>
            <div className="grid grid-cols-2 gap-1 rounded-md p-2" style={{ border: `1px solid #ccc` }}>
              {adminRoles.map((role, iRole) => (
                <RoleItem key={iRole} role={role} defaultChecked={activeAdmin?.roles?.includes(role)} />
              ))}
            </div>
          </CheckboxGroup>
        ) : (
          <FormDescription>
            {activeAdmin?.roles?.length ? (
              <ul className="ml-4" style={{ listStyle: "disc" }}>
                {activeAdmin?.roles?.map((role, iRole) => (
                  <li key={iRole} className="text-sm">
                    {role}
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </FormDescription>
        )}
        {errors?.roles && <FormHelperText className="text-red-400 text-xs">{errors?.roles?.message}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default Form;
