import { motion } from "framer-motion";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Check from "../../../../assets/img/static/check.png";
import { RegisterContext } from "../Controller";

const CompleteSection = () => {
  const { t } = useTranslation();
  const { accountForm } = useContext(RegisterContext);

  return (
    <div className="h-full flex flex-col gap-y-8 justify-center items-center p-10">
      <motion.img initial={{ y: -50 }} animate={{ y: 0 }} src={Check} className="h-20 md:h-28" />

      <motion.div initial={{ y: 50 }} animate={{ y: 0 }} className="text-center">
        <h1 className="font-bold text-2xl md:text-3xl montserrat text-transparent bg-clip-text bg-gradient-primary mb-4 md:mb-2">
          {t("TEXT.REGISTRATION_COMPLETE")}
        </h1>
        <p className="text-gray-600">
          {t("TEXT.CHECK_EMAIL_FOR_ACTIVATION", { email: accountForm?.getValues("userEmail") || "" })}
        </p>
      </motion.div>
    </div>
  );
};

export default CompleteSection;
