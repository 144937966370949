import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { createContext, useContext } from 'react';
import { AuthContext } from './auth/AuthController';

export const HelperContext = createContext();
const { Provider } = HelperContext;

const HelperController = ({ children }) => {
	const { token } = useContext(AuthContext);
	const toast = useToast();

	const showToast = (msg, { isClosable = true, title, status, success = true, position = 'top-right' } = {}) => {
		toast({
			description: msg,
			isClosable,
			position,
			status: status ?? success ? 'success' : 'error',
			title,
		});
	};

	const httpRequest = async ({ method = 'GET', url, payload, auth = true, responseType }) => {
		let response;
		let error;
		let headers;
		if (auth && !token) return { response, error, headers };
		try {
			const res = await axios({
				method: method,
				url: url,
				data: payload,
				responseType,
				headers: { Authorization: `Bearer ${auth ? token : ''}` },
			});

			headers = res.headers;
			response = res.data;
			if (response?.statusCode === 403) showToast(`You don't have permission to access`, { success: false, title: 'Forbidden' });
		} catch (err) {
			error = err.response;
			if (error?.status === 403 || error?.data?.statusCode === 403) showToast(`You don't have permission to access`, { success: false, title: 'Forbidden' });
		}
		return { response, error, headers };
	};
	return <Provider value={{ showToast, httpRequest }}>{children}</Provider>;
};
export default HelperController;
