import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import ExpertDetail from "./components/ExpertDetail";
import ExpertList from "./components/ExpertList";
import ExpertNew from "./components/ExpertNew";
import ExpertController, { ExpertContext } from "./Controller";

const Expert = () => {
  const { isDetailMenu, isCreate } = useContext(ExpertContext);

  return (
    <div>
      <AnimatePresence>{isCreate ? <ExpertNew /> : isDetailMenu ? <ExpertDetail /> : <ExpertList />}</AnimatePresence>
    </div>
  );
};

export default () => (
  <ExpertController>
    <Expert />
  </ExpertController>
);
