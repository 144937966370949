import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { useContext, useEffect } from "react";
import Uploader from "../../../../../../components/inputForm/Uploader";
import { RegisterContext } from "../../../Controller";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import InputMask from "../../../../../../components/inputForm/InputMask";
import DocumentController, { DocumentContext } from "./Controller";
import { Spinner } from "@chakra-ui/react";
import { BsCheckLg, BsXLg } from "react-icons/bs";

const DocumentForm = () => {
  const { t } = useTranslation();
  const { documentForm: form, companyProfileForm } = useContext(RegisterContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;
  const { isTaxNumberAvailable, loadingTaxNumberAvailable } = useContext(DocumentContext);

  const country = companyProfileForm.watch("country");
  const taxableEntNumber = form.watch("taxableEntNumber");

  // register("taxAttachment", {
  //   required: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.NPWP_NUMBER") }) }),
  // });
  useEffect(() => {
    if(country === "ID") {
      register("taxAttachment", {
        required: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.NPWP_NUMBER") }) }),
      });
    } 

    register("taxableEntAttachment", {
      required: {
        value: !!taxableEntNumber,
        message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.TAXABLE_ENT") }) }),
      },
    });
    trigger("taxableEntAttachment");
  }, [country, taxableEntNumber]);

  return (
    <>
      {/* Tax Number */}
      {country === "ID" ? 
        <FormControl isRequired>
          <FormLabel className="text-sm">{t("LABEL.NPWP_NUMBER")}</FormLabel>       
          <div className="p-inputgroup">
            {country === "ID" ? (
              <InputMask
                mask={"99.999.999.9-999.999"}
                placeholder={t("LABEL.NPWP_NUMBER")}
                name="taxNumber"
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", { field: t("LABEL.NPWP_NUMBER") }),
                    validate: {
                      nameAvailable: (v) => isTaxNumberAvailable || t("INVALID.AVAILABLE", { field: v }),
                    },
                  },
                }}
                isError={errors?.taxNumber}
              />
            ) : (
              <InputText
                placeholder="Tax Number"
                className={`w-full text-sm ${errors?.taxNumber && "border-2 border-red-400 shadow-none"}`}
                {...register("taxNumber")}
              />
            )}
            <span
              className={`p-inputgroup-addon border-l-0 bg-transparent ${
                errors?.taxNumber && "border-2 border-red-400 shadow-none"
              }`}
            >
              {loadingTaxNumberAvailable ? (
                <Spinner size="xs" />
              ) : isTaxNumberAvailable && !errors?.taxNumber ? (
                <BsCheckLg className="text-green-500" />
              ) : (
                isTaxNumberAvailable === false && <BsXLg className="text-red-500" />
              )}
            </span>
          </div>
          {errors?.taxNumber && (
            <FormHelperText className="text-red-400 text-xs">{errors?.taxNumber?.message}</FormHelperText>
          )}
        </FormControl>
        : 
        <FormControl >
            <FormLabel className="text-sm">Tax Number</FormLabel>       
            <div className="p-inputgroup">
              {country === "ID" ? (
                <InputMask
                  mask={"99.999.999.9-999.999"}
                  placeholder={t("LABEL.NPWP_NUMBER")}
                  name="taxNumber"
                  control={control}
                  controlProps={{
                    rules: {
                      required: t("INVALID.REQUIRED", { field: t("LABEL.NPWP_NUMBER") }),
                      validate: {
                        nameAvailable: (v) => isTaxNumberAvailable || t("INVALID.AVAILABLE", { field: v }),
                      },
                    },
                  }}
                  isError={errors?.taxNumber}
                />
              ) : (
                <InputText
                  placeholder="Tax Number"
                  className={`w-full text-sm ${errors?.taxNumber && "border-2 border-red-400 shadow-none"}`}
                  {...register("taxNumber")}
                />
              )}
              <span
                className={`p-inputgroup-addon border-l-0 bg-transparent ${
                  errors?.taxNumber && "border-2 border-red-400 shadow-none"
                }`}
              >
                {loadingTaxNumberAvailable ? (
                  <Spinner size="xs" />
                ) : isTaxNumberAvailable && !errors?.taxNumber ? (
                  <BsCheckLg className="text-green-500" />
                ) : (
                  isTaxNumberAvailable === false && <BsXLg className="text-red-500" />
                )}
              </span>
            </div>
            {errors?.taxNumber && (
              <FormHelperText className="text-red-400 text-xs">{errors?.taxNumber?.message}</FormHelperText>
            )}
          </FormControl>
        }
      

      {/* Tax Attachment */}
      {country === "ID" ? 
          <FormControl isRequired>
            <FormLabel className="text-sm">{t("LABEL.ATTACHMENT", { field: t("LABEL.NPWP_NUMBER") })}</FormLabel>
            <Uploader
              className={`${errors?.taxAttachment && "border-2 border-red-400 shadow-none"}`}
              label={`${t("LABEL.UPLOAD")} ${t("LABEL.ATTACHMENT", { field: t("LABEL.NPWP_NUMBER") })}`}
              name="taxAttachment"
              fileList={form.getValues("taxAttachment")}
              onChange={(files) => {
                setValue("taxAttachment", files);
                trigger("taxAttachment");
              }}
              // {...register("taxAttachment", {
              //   required: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.NPWP_NUMBER") }) }),
              // })}
            />
            {errors?.taxAttachment && (
              <FormHelperText className="text-red-400 text-xs">{errors?.taxAttachment?.message}</FormHelperText>
            )}
          </FormControl>
      :
          <FormControl>
            <FormLabel className="text-sm">Tax Number Attachment</FormLabel>
            <Uploader
              // className={`${errors?.taxAttachment && "border-2 border-red-400 shadow-none"}`}
              label={`${t("LABEL.UPLOAD")} Tax Number Attachment`}
              name="taxAttachment"
              fileList={form.getValues("taxAttachment")}
              onChange={(files) => {
                setValue("taxAttachment", files);
                trigger("taxAttachment");
              }}
            />
            {/* {errors?.taxAttachment && (
              <FormHelperText className="text-red-400 text-xs">{errors?.taxAttachment?.message}</FormHelperText>
            )} */}
          </FormControl>
      }

      {country === "ID" && (
        <>
          {/* Taxable Entrepreneur */}
          <FormControl>
            <FormLabel className="text-sm">{t("LABEL.TAXABLE_ENT")}</FormLabel>
            <InputText
              placeholder={t("LABEL.TAXABLE_ENT")}
              className={`default-chakra-input text-sm ${
                errors?.taxableEntNumber && "border-2 border-red-400 shadow-none"
              }`}
              {...register("taxableEntNumber")}
            />
            {errors?.taxableEntNumber && (
              <FormHelperText className="text-red-400 text-xs">{errors?.taxableEntNumber?.message}</FormHelperText>
            )}
          </FormControl>

          {/* Taxable Entrepreneur Attachment */}
          <FormControl>
            <FormLabel className="text-sm">{t("LABEL.ATTACHMENT", { field: t("LABEL.TAXABLE_ENT") })}</FormLabel>
            <Uploader
              className={`${errors?.taxableEntAttachment && "border-2 border-red-400 shadow-none"}`}
              label={`${t("LABEL.UPLOAD")} ${t("LABEL.ATTACHMENT", { field: t("LABEL.TAXABLE_ENT") })}`}
              name="taxableEntAttachment"
              onChange={(files) => {
                setValue("taxableEntAttachment", files);
                trigger("taxableEntAttachment");
              }}
            />
            {errors?.taxableEntAttachment && (
              <FormHelperText className="text-red-400 text-xs">{errors?.taxableEntAttachment?.message}</FormHelperText>
            )}
          </FormControl>
        </>
      )}

      {/* Reference Number */}
      <FormControl>
        <FormLabel className="text-sm">{t("LABEL.REFERENCE_NUMBER")}</FormLabel>
        <InputText
          placeholder={t("LABEL.REFERENCE_NUMBER")}
          className={`default-chakra-input text-sm ${errors?.referenceNumber && "border-2 border-red-400 shadow-none"}`}
          {...register("referenceNumber")}
        />
        {errors?.referenceNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.referenceNumber?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default () => (
  <DocumentController>
    <DocumentForm />
  </DocumentController>
);
