import React, { useContext } from "react";
import AuctionLiveController, { AuctionLiveContext } from "./Controller";
import { AuctionContext } from "../../../Controller";
import { Spinner } from "@chakra-ui/react";
import Counter from "./components/Counter";
import Material from "./components/Material";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import moment from "moment";
import BidForm from "./components/BidForm";
import History from "./components/History";
import Rank from "./components/Rank";
import LeadRank from "./components/LeadRank";
import WinnerPrice from "./components/WinnerPrice";

const AuctionLive = () => {
  const { userData } = useContext(AuthContext);
  const { loadingAuction, auction } = useContext(AuctionContext);
  const { auctionStatus, isLeading } = useContext(AuctionLiveContext);

  return (
    <>
      {loadingAuction ? (
        <div className="flex justify-center">
          <Spinner color="red" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div
            className={
              auction?.isTopRankVisible &&
              isLeading &&
              auction?.isBestPriceVisible &&
              auctionStatus === "finish" &&
              `grid grid-cols-2 gap-4`
            }
          >
            {userData?.type === "user" && (
              <>
                <LeadRank />
                <WinnerPrice />
              </>
            )}
          </div>
          <Counter />
          {userData?.type !== "user" ? (
            <>
              <Rank />
              <Material />
            </>
          ) : (
            auctionStatus === "active" && (
              <>
                <BidForm />
                <History />
              </>
            )
          )}
        </div>
      )}
    </>
  );
};

export default () => (
  <AuctionLiveController>
    <AuctionLive />
  </AuctionLiveController>
);
