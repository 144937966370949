import { Button, FormControl, FormLabel } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPaperPlane } from 'react-icons/bi';
import FormDescription from '../../../../../../../../../../components/inputForm/FormDescription';
import PrivateComponent from '../../../../../../../../../../components/PrivateComponent';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../../Controller';
import NextStepButton from '../../../../NextStepButton';
import { PrequalificationEvaluationContext } from '../Controller';
import ActionModal from './ActionModal';

const Action = () => {
	const { t } = useTranslation();
	const { procurement, procurementSchedule } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);
	const { vendors, evaluation, setEvaluation } = useContext(PrequalificationEvaluationContext);

	const [note, setNote] = useState({
		text: '',
		error: '',
	});

	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const canSubmit = vendors && procurementSchedule?.prequalificationEnd && moment().unix() >= moment(procurementSchedule?.prequalificationEnd).unix() && !vendors?.find((vendor) => vendor.procurementVendorPrequalification?.status === 'PENDING');

	const submit = async () => {
		setOpenModal(false);

		const payload = {
			procurementNumber: procurement?.procurementNumber,
			note: note?.text,
		};
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-prequalification-evaluation`,
			method: 'POST',
			payload,
		});
		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		setEvaluation(response?.data?.procurementPrequalificationEvaluation);
		showToast(t('INSERT-200'));
	};

	return (
		<div className="flex justify-between mt-8 items-center">
			{evaluation ? (
				<div className="flex flex-col w-full">
					<div className="flex justify-between items-center">
						<FormControl className="w-1/3 ml-5">
							<FormLabel className={`text-color0 uppercase font-semibold text-xs`}>{t('LABEL.EVALUATION_NOTE')}</FormLabel>
							<FormDescription>{evaluation?.note}</FormDescription>
						</FormControl>
						<div className="mr-5 text-green-500 text-xl font-bold italic">{t('LABEL.SUBMITTED')}</div>
					</div>
					<NextStepButton to="offer" url="offer" className="mt-4" toLabel="Penawaran" />
				</div>
			) : (
				<PrivateComponent accessName="BUYER_EDIT" placeholder={<div className="mr-5 text-color2 text-xl font-bold italic ml-auto">Pending</div>}>
					<ActionModal isOpen={openModal} setIsOpen={setOpenModal} vendors={vendors} setNote={setNote} note={note} onSubmit={submit} />
					<Button
						className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 mr-5 bg-pos-0 hover:bg-pos-100 shadow-lg ml-auto"
						onClick={() => {
							setOpenModal(true);
						}}
						isLoading={loading}
						isDisabled={!canSubmit || loading}
					>
						<BiPaperPlane className="text-2xl" />
						{t('LABEL.SUBMIT', { field: '' })}
					</Button>
				</PrivateComponent>
			)}
		</div>
	);
};

export default Action;
