import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import PrequalificationEvaluationController from './Controller';
import { useTranslation } from 'react-i18next';
import OverviewPage from './components/OverviewPage';
import AnnouncementPanel from '../../../../../../../../../components/AnnouncementPanel';
import { ProcurementDetailContext } from '../../../../Controller';
import moment from 'moment';

const PrequalificationEvaluation = () => {
	const { t } = useTranslation();
	const { procurementSchedule } = useContext(ProcurementDetailContext);

	const start = procurementSchedule?.prequalificationStart;
	const end = procurementSchedule?.prequalificationEnd;
	const now = moment().unix();
	const canEval = start && end && now >= moment(end).unix();

	return (
		<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
			{!canEval && <AnnouncementPanel bg="bg-white" className="text-gray-700" title={t('TEXT.PRA_EVALUATION_WARNING')} />}
			<div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
				<OverviewPage />
			</div>
		</motion.div>
	);
};

export default () => (
	<PrequalificationEvaluationController>
		<PrequalificationEvaluation />
	</PrequalificationEvaluationController>
);
