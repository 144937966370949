import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const ForgetPasswordContext = createContext();
const { Provider } = ForgetPasswordContext;

const ForgetPasswordController = ({ children }) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const submitResetPassword = (email) => {
    setLoadingResetPassword(true);
    axios
      .post(
        "/user/reset-password",
        {
          email,
        },
        {}
      )
      .finally(() => {
        setLoadingResetPassword(false);
        setSubmitted(true);
      });
  };

  return <Provider value={{ loadingResetPassword, submitResetPassword, isSubmitted }}>{children}</Provider>;
};

export default ForgetPasswordController;
