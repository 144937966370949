import { Spinner } from "@chakra-ui/react";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../Controller";

const NotificationItem = ({ title, date, desc, isRead }) => {
  return (
    <div className="flex items-center gap-3">
      <div>
        <div className={`w-2 h-2  rounded-full ${isRead ? "bg-gray-200" : "bg-red-500"}`} />
      </div>
      <div className="flex-1 flex flex-col">
        <span className="text-xs text-gray-600">{moment(date).fromNow()}</span>
        <span className="text-base font-semibold my-0">{title}</span>
        {desc && (
          <div className="my-1 p-2 bg-gray-50 rounded-md w-full">
            <span className="text-xs">{desc}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const NotificationList = () => {
  const { t } = useTranslation();
  const { notifications, loadingNotification, getNotifications } = useContext(NotificationContext);

  // Handle Scroll
  const handleScroll = (e) => {
    const isBottomArea = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 50;
    if (isBottomArea) {
      getNotifications();
    }
  };

  return (
    <div className="p-4 bg-white rounded-md flex flex-col gap-6 h-full overflow-auto" onScroll={handleScroll}>
      {!!notifications?.length ? (
        notifications.map((notification, iNotification) => (
          <NotificationItem
            key={iNotification}
            title={notification?.title}
            date={notification?.createdAt}
            desc={notification?.description}
            isRead={notification?.isRead}
          />
        ))
      ) : (
        <div className="flex justify-center">
          <span className="text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</span>
        </div>
      )}

      {loadingNotification && (
        <div className="flex justify-center">
          <Spinner color="red" />
        </div>
      )}
    </div>
  );
};

export default NotificationList;
