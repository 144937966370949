import { Table, Thead, Tr, Th, Tbody, Td, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { AdministratifContext } from './Controller';
import { useTranslation } from 'react-i18next';
import { ProcurementOfferContext } from '../../Controller';
import { ProcurementDetailContext } from '../../../../../Controller';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import Uploader from '../../../../../../../../../../components/inputForm/Uploader';
import FileAttachment from '../../../../../../../../../../components/FileAttachment';
import TitleBar from '../../../../../../../../../../components/TitleBar';

const AdministratifRequirementTable = () => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { procurement } = useContext(ProcurementDetailContext);
	const { administratifOffer } = useContext(ProcurementOfferContext);
	const { requirements, setRequirements, form } = useContext(AdministratifContext);
	const [loading, setLoading] = useState(true);

	const {
		register,
		formState: { errors },
    getValues, setValue
	} = form;

	useEffect(async () => {
		setRequirements(null);
		if (!procurement) return;

		let mounted = true;
		setLoading(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-requirement/${procurement.procurementNumber}`,
		});
		if (!mounted) return;
		setLoading(false);

		if (!response?.procurementRequirement) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		const admReqs = response.procurementRequirement.filter((req) => req.requirementProcurementCover === 'ADMINISTRATIF & TEKNIKAL');
		setRequirements(admReqs);
	}, [procurement]);

	const alreadySubmitted = (procurementAdministrationOfferId, procurementRequirementId) => {
		for (let i = 0; i < administratifOffer.procurementAdministrationOfferRequirements.length; i++) {
			const req = administratifOffer.procurementAdministrationOfferRequirements[i];
			if (req.procurementAdministrationOfferId === procurementAdministrationOfferId && req.procurementRequirementId === procurementRequirementId) {
				return i;
			}
		}

		return null;
	};

	return (
		<div>
			<TitleBar className="mt-4" title={t("LABEL.PROCUREMENT_REQUIREMENT")} />
			<Table>
				<Thead>
					<Tr>
						<Th className="text-red-500">No.</Th>
						<Th className="text-red-500">{t("LABEL.DESCRIPTION")}</Th>
						<Th className="text-red-500 text-center">{t("LABEL.PROCUREMENT_REQUIREMENT_REQUIRED")}</Th>
						<Th className="text-red-500 text-center">{t("LABEL.DOCUMENT")}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loading && (
						<Tr>
							<Td colSpan={4}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					)}

					{requirements ? (
						<>
							{requirements?.length !== 0 ? (
								<>
									{requirements.map((req, idx) => {
										const idxExist = administratifOffer === null ? null : alreadySubmitted(administratifOffer.procurementAdministrationOfferId, req.procurementRequirementId);

                    register(`document_${idx}`, { required: req.isRequired ? 'Wajib diisi' : null });

										return (
											<Tr key={idx}>
												<Td className="w-4">{idx + 1}</Td>
												<Td className="w-2/5">{req.description}</Td>
												<Td className='w-1/12 text-center'>{req.isRequired ? t('LABEL.YES') : t('LABEL.NO')}</Td>
												<Td className='text-center flex justify-center w-full'>
													{idxExist === null ? (
														<>
															{administratifOffer && administratifOffer.status === 'SUBMITTED' ? (
																<>
																	<div className="text-color0">{t("LABEL.NOT_SUBMITTED")}</div>
																</>
															) : (
                                <Uploader
                                  className={`py-1 ${errors[`document_${idx}`] && 'border-2 border-red-400 shadow-none'}`}
                                  label={req.description}
                                  name={`document_${idx}`}
                                  fileList={getValues(`document_${idx}`)}
                                  onChange={(files) => {
                                    setValue(`document_${idx}`, files);
                                  }}
                                />
															)}
														</>
													) : (
                            <FileAttachment href={`${process.env.REACT_APP_FILESERVICE_API}/files/users/${administratifOffer.procurementAdministrationOfferRequirements[idxExist].documentFilename}`} />
													)}
												</Td>
											</Tr>
										);
									})}
								</>
							) : (
								<>
									<Tr>
										<Td colSpan={4} className="text-center">
											<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
										</Td>
									</Tr>
								</>
							)}
						</>
					) : (
						<>
							<Tr>
								<Td colSpan={4} className="text-center">
									<p className="text-sm text-gray-500">{t('ERROR.undefined')}</p>
								</Td>
							</Tr>
						</>
					)}
				</Tbody>
			</Table>
		</div>
	);
};

export default AdministratifRequirementTable;
