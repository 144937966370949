import React from "react";
import { useTranslation } from "react-i18next";
import JoinTable from "./components/JoinTable";
import JoinController from "./Controller";

const Join = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.PROCUREMENT_OFFER")}</h2>

      <JoinTable />
    </div>
  );
};

export default () => (
  <JoinController>
    <Join />
  </JoinController>
);
