import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CompanyOverview from "../../../../../components/CompanyOverview";
import InfoNeedApproval from "../../../../../components/VendorReapproval/InfoNeedApproval";
import { DashboardContext } from "../../controllers/DashboardController";

const VendorDashboard = () => {
  const { t } = useTranslation();
  const { loadingCompanyOverview, companyOverview, submitApprovalFails } = useContext(DashboardContext);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <CompanyOverview loadingCompanyOverview={loadingCompanyOverview} companyOverview={companyOverview} />
      </div>

      <InfoNeedApproval isFailsExist={!!submitApprovalFails?.length} />
    </div>
  );
};

export default VendorDashboard;
