import { useToast } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../Controller";

export const NegotiationContext = createContext();
const { Provider } = NegotiationContext;

const NegotiationController = ({ children }) => {
  const toast = useToast();
  const { token, userData } = useContext(AuthContext);
  const { t } = useTranslation();
  const { procurement, reqRequisitions } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        companyRegCode: newForm?.companyRegCode,
        currency: newForm?.currency,
        negotiationStart:
          activeNegotiation?.negotiationStart === undefined
            ? tempNegotiation?.negotiationStart
            : new Date(activeNegotiation?.negotiationStart),
        negotiationEnd:
          activeNegotiation?.negotiationEnd === undefined
            ? tempNegotiation?.negotiationEnd
            : new Date(activeNegotiation?.negotiationEnd),
        reqRequisitionsCode: reqRequisitions[0]?.reqRequisitionCode,
      });
    }
  };

  const formNotes = useForm();
  const resetNotesForm = (newForm) => {
    if (newForm) {
      formNotes.reset({
        notes: newForm?.notes,
        status: newForm?.status,
        createdBy: userData?.type === "user" ? "Vendor" : "Buyer",
      });
    }
  };

  const formBid = useForm();
  const resetBidForm = (newForm) => {
    if (newForm) {
      formBid.reset({
        notes: newForm?.notes ? newForm?.notes : null,
        status: newForm?.status ? newForm?.status : null,
        price: newForm?.price,
        // materialCode: newForm?.material,
      });
    }
  };

  const formVendorBid = useForm();
  const resetVendorBidForm = (newForm) => {
    if (newForm) {
      newForm?.map((data, key) => {
        formVendorBid.reset({
          notes: data?.notes ? data?.notes : null,
          status: data?.status ? data?.status : null,
          price: data?.price,
          materialCode: data?.material,
        });
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [detailNegotiation, setDetailNegotiation] = useState();
  const [measureUnit, setMeasureUnit] = useState();
  const [activeNegotiation, setActiveNegotiation] = useState();
  const [tempNegotiation, setTempNegotiation] = useState();
  const [dataReqRequisition, setDataReqRequisition] = useState();
  const [materialCode, setMaterialCode] = useState();

  const handleDetailMenu = (isOpen, data, dataReq, materialCode) => {
    setDetailMenu(isOpen);
    setDataReqRequisition(dataReq);
    setMaterialCode(materialCode);

    let valueMeasureUnit = {};

    axios
      .get(`${process.env.REACT_APP_TMS_API}/material`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const tempMaterialIds = dataReq?.reqRequisitionMaterial?.map(
          (materialIds) => materialIds?.material?.materialId
        );
        const measureUnit = res?.data?.material
          ?.filter(
            (measureUnit) => measureUnit?.materialId === tempMaterialIds?.[0]
          )
          .map((materials) => materials?.measureUnit?.unit);
        setMeasureUnit(measureUnit);
        const tempMaterialPrice = dataReq?.reqRequisitionMaterial?.map(
          (materialIds) => materialIds
        );
        measureUnit?.map((dataUnit) => {
          valueMeasureUnit = {
            unit: dataUnit,
          };
        });
      });

    setDetailNegotiation({ ...data, ...dataReq });
    isOpen && resetForm(data);
  };

  // -------------------------------
  // Get Data Time and Notes Negotiation
  // -------------------------------

  const [isCreate, setCreate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [totalDataNotes, setTotalDataNotes] = useState(0);
  const [limitNotes] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [reloadNotes, setReloadNotes] = useState(true);

  const [negotiation, setNegotiation] = useState([]);
  const [loadingNegotiation, setLoadingNegotiation] = useState(false);
  const [reloadNegotiation, setReloadNegotiation] = useState(true);
  const [activeVendor, setActiveVendor] = useState();

  const [reloadBid, setReloadBid] = useState(true);

  // Filter Time
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadNegotiation(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Filter Notes
  const tableFormNotes = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watchNotes = useWatch({
    control: tableFormNotes.control,
    defaultValue: null,
  });
  let [filterNotesTimeout, setFilterNotesTimeput] = useState();
  useEffect(() => {
    if (!watchNotes) return;
    clearTimeout(filterNotesTimeout);
    const newTimeout = setTimeout(() => {
      setReloadNotes(true);
    }, 500);
    setFilterNotesTimeput(newTimeout);
  }, [watchNotes]);

  // Filter Bid
  const tableFormBid = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watchBid = useWatch({
    control: tableFormBid.control,
    defaultValue: null,
  });
  let [filterBidTimeout, setFilterBidTimeput] = useState();
  useEffect(() => {
    if (!watchBid) return;
    clearTimeout(filterBidTimeout);
    const newTimeout = setTimeout(() => {
      setReloadBid(true);
    }, 500);
    setFilterBidTimeput(newTimeout);
  }, [watchBid]);

  const handleCreate = (isNewCreate, data) => {
    setCreate(isNewCreate);
    setNegotiationNew(isNewCreate);
    setActiveVendor(data);
    setEdit(false);

    // -------------------------------
    // Ttme
    // Get Data Time Negotiation
    // -------------------------------

    getNegotiation(data);

    // -------------------------------
    // Notes
    // Get Data Notes
    // -------------------------------

    getNotes(data);

    isNewCreate && resetForm(data);
  };

  const getNegotiation = async (vendorId) => {
    const payloadTime = tableForm.getValues();
    // Filter Time
    const limitTime = `limit=${limit}`;
    const pageTime = `page=${payloadTime?.page}`;
    const filterTime = `?${limitTime}&${pageTime}`;

    setLoadingNegotiation(true);
    axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/time/${procurementNumber}/${vendorId?.companyRegCode}${filterTime}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newNegotiation = res?.data?.procurementNegotiation;
        const filterNegotiation = newNegotiation?.find(
          (dataNegotiation) =>
            dataNegotiation.notes !== "" && dataNegotiation.status === "DRAFT"
        );
        setNegotiation(filterNegotiation);
        setActiveNegotiation(newNegotiation[0]);
        resetForm(newNegotiation[0]);
        setTotalData(res?.data?.pagination?.totalData);
      })
      .finally(() => {
        setReloadNegotiation(false);
        setReloadBid(false);
        setLoadingNegotiation(false);
      });
  };

  const getNotes = async (vendorId) => {
    const payloadNote = tableFormNotes.getValues();

    // Filter Notes
    const limitNote = `limit=${limitNotes}`;
    const pageNote = `page=${payloadNote?.page}`;
    const filterNote = `?${limitNote}&${pageNote}`;
    setLoadingNotes(true);
    axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/${vendorId?.companyRegCode}${filterNote}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newNotes = res?.data?.procurementNegotiationOffer;
        setNotes(newNotes);
        setTotalDataNotes(res?.data?.pagination?.totalData);
      })
      .finally(() => {
        setReloadNotes(true);
        setReloadBid(true);
        setLoadingNotes(false);
      });
  };

  // -------------------------------
  // Create Time Negotiation
  // -------------------------------

  const createNegotiation = (payload) => {

    // Schedule Validation
    const start = moment(payload?.negotiationStart).unix();
    const end = moment(payload?.negotiationEnd).unix();
    const now = moment().add(1, 'minutes').unix();
    
    if (start !== moment(activeNegotiation?.negotiationStart).unix() && start < now){
      return form.setError('negotiationStart', {message: 'Waktu mulai tidak boleh lebih kecil dari waktu sekarang'});        
    }
    if (end !== moment(activeNegotiation?.negotiationEnd).unix() && end< now){
      return form.setError('negotiationEnd', {message: 'Waktu akhir tidak boleh lebih kecil dari waktu sekarang'});
    }
    if (start > end){
      return form.setError('negotiationStart', {message: 'Mulai harus lebih kecil'});
    }
    // End Schedule Validation


    setTempNegotiation(payload);
    !activeNegotiation?.negotiationStart
      ? axios
          .post(
            `${process.env.REACT_APP_TMS_API}/procurement-negotiation/${procurement.procurementNumber}`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            toast({
              description: t(res?.data?.success),
              position: "top-right",
              status: "success",
              isClosable: true,
            });
            setCreate(false);
            setEdit(false);
            setEditNegotiationSessionNew(true);
            getNegotiation(payload);
          })
          .catch(() => {
            toast({
              title: "System Error",
              description: t("ERROR.undefined"),
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingCreate(false);
            setLoadingUpdate(false);
            setReloadNegotiation(true);
            setReloadBid(true);
          })
      : axios
          .patch(
            `${process.env.REACT_APP_TMS_API}/procurement-negotiation/${activeNegotiation?.procurementNegotiationId}`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            toast({
              description: t(res?.data?.success),
              position: "top-right",
              status: "success",
              isClosable: true,
            });
            setCreate(false);
            setEdit(false);
            setEditNegotiationSessionNew(true);
            getNegotiation(payload);
          })
          .catch(() => {
            toast({
              title: "System Error",
              description: t("ERROR.undefined"),
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingCreate(false);
            setLoadingUpdate(false);
            setReloadNegotiation(true);
            setReloadBid(true);
          });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeNegotiation);
  }, [isEdit]);

  // Update
  const updateNegotiation = (payload) => {
    setLoadingUpdate(true);
    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/${activeNegotiation?.procurementNegotiationId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadNegotiation(true);
        setReloadBid(true);
      });
  };

  //Negotiation
  const [isEditNegotiationSessionNew, setEditNegotiationSessionNew] =
    useState(true);
  const [isNegotiationNew, setNegotiationNew] = useState(false);
  const [loadingNegotiationSessionNew, setLoadingNegotiationSessionNew] =
    useState(false);
  const handleNegotiationSessionNewOpen = (isOpen) => {
    setEdit(isOpen);
    getNegotiation(activeVendor);
  };

  // -------------------------------
  //  Get Data Vendor
  // -------------------------------

  // Vendor
  const [vendor, setVendor] = useState([]);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [reloadVendor, setReloadVendor] = useState(true);
  useEffect(() => {
    if (userData?.type === "user") return;

    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getVendor = async () => {
      setLoadingVendor(true);
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}${filter}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newRequisition = res?.data?.procurementVendors;
            setVendor(newRequisition);
            setTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setReloadVendor(false);
          setLoadingVendor(false);
        });
    };

    token && procurementNumber && reloadVendor && getVendor();

    return () => {
      mounted = false;
    };
  }, [token, reloadVendor, procurementNumber]);

  // Vendor By Choosen
  const regCode = userData?.company?.registrationCode;
  // Procurement
  const [procurements, setProcurements] = useState([]);
  const [loadingProcurement, setLoadingProcurement] = useState(false);
  const [reloadProcurement, setReloadProcurement] = useState(true);
  useEffect(() => {
    let mounted = true;
    const getProcurement = async () => {
      setLoadingProcurement(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement/offer/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newProcurement = res?.data?.procurements;
            setProcurements(newProcurement);
          }
        })
        .finally(() => {
          setReloadProcurement(false);
          setLoadingProcurement(false);
        });
    };
    token && reloadProcurement && regCode && getProcurement();
    return () => {
      mounted = false;
    };
  }, [token, reloadProcurement, regCode]);

  // -------------------------------
  //  Create Notes
  // -------------------------------

  const [isNotesNew, setNotesNew] = useState(false);
  const [loadingNotesNew, setLoadingNotesNew] = useState(false);
  const [loadingNotesUpdate, setLoadingNotesUpdate] = useState(false);

  const handleCreateNotes = (isNewCreateNotes) => {
    setNotesNew(isNewCreateNotes);
    isNewCreateNotes && resetNotesForm({});
  };
  const createNotesNegotiation = (payload) => {
    setLoadingNotesNew(true);
    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/${activeNegotiation?.procurementNegotiationId}/${activeVendor?.companyRegCode}`,
        {
          payload,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setNotesNew(false);
        getNotes(activeVendor);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingNotesNew(false);
        setReloadNotes(true);
        setReloadBid(true);
      });
  };

  // -------------------------------
  // Update Status Notes
  // -------------------------------

  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isActiveNotes, setActiveNotes] = useState(false);
  const [negotiationNotesStatus, setNegotiationeNotesStatus] = useState();
  const [isFirstPropose, setIsFirstPropose] = useState();

  // Update
  const handleUpdateNotesOpen = (
    isUpdateOpen,
    data,
    status,
    isFirstPropose
  ) => {
    setNegotiationeNotesStatus(status);
    setActiveNotes({ ...data });
    setIsOpenUpdate(isUpdateOpen);
    setIsFirstPropose(isFirstPropose);
    resetNotesForm(data);
  };

  const updateStatusNotes = (payload) => {
    setLoadingNotesUpdate(true);

    const subjectEmailNegotiation = "Offering to Negotiation E-Procurement";

    const filterNotes = notes
      ?.filter(
        (filterNotes) =>
          filterNotes?.negotiation?.procurementNegotiationId ===
            isActiveNotes?.negotiation &&
          filterNotes?.negotiation?.companyRegCode ===
            isActiveNotes?.company_reg_code &&
          filterNotes?.status === "DRAFT" &&
          filterNotes?.notes === isActiveNotes?.notes
      )
      .map((dataNotes, iDatanotes) => dataNotes);

    axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newVendor = res?.data?.procurementVendors;

        const companyName = newVendor
          ?.filter(
            (filterVendor) =>
              filterVendor?.company?.registrationCode ===
              filterNotes?.[0]?.negotiation?.companyRegCode
          )
          .map((dataVendor) => dataVendor);

        const newPayloads = {
          vendor: companyName[0]?.company?.companyName,
          title: procurement?.title,
          procurementType: procurement?.procurementType?.procurementType,
          negotiationStart: new Date(
            filterNotes[0]?.negotiation?.negotiationStart
          ),
          negotiationEnd: new Date(filterNotes[0]?.negotiation?.negotiationEnd),
        };

        axios
          .get(`${process.env.REACT_APP_API}/user/${companyName[0]?.vendor}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if(isFirstPropose === 1) {
               axios
                  .post(
                    `${process.env.REACT_APP_TMS_API}/procurement-negotiation/${procurement.procurementNumber}/send-email-negotiation/${res?.data?.users[0]?.email}/${subjectEmailNegotiation}`,
                    newPayloads,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                  .then((res) => {
                    console.log(t(res?.data?.success));
                  })
                  .catch(() => {
                    toast({
                      title: "System Error",
                      description: t("ERROR.undefined"),
                      position: "top-right",
                      status: "error",
                      isClosable: true,
                    });
                  })
              }
          });
      });

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/${filterNotes?.[0]?.procurementNegotiationOfferId}`,
        {
          isProposed: negotiationNotesStatus,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        getNotes(activeVendor);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingNotesUpdate(false);
        setReloadNotes(true);
        setReloadBid(true);
        setIsOpenUpdate(false);
      });
  };

  const [isMakeBid, setMakeBid] = useState(false);
  const [loadingBidNegotiation, setLoadingBidNegotiation] = useState();
  const [isView, setView] = useState(false);
  const [activeNegotiationBid, setActiveNegotiationBid] = useState();
  const [vendorPrice, setVendorPrice] = useState();
  const [activeNegotiationMaterialVendor, setActiveNegotiationMaterialVendor] =
    useState([]);
  const [activeNegotiationMaterial, setActiveNegotiationMaterial] = useState();
  const [
    loadingNegotiationMaterialVendor,
    setLoadingNegotiationMaterialVendor,
  ] = useState(false);
  const [loadingNegotiationMaterial, setLoadingNegotiationMaterial] =
    useState(false);

  const handleCreateNegotiationBid = (isOpen, data, isView, dataPrice) => {
    setMakeBid(isOpen);
    setView(isView);
    setLoadingNegotiationMaterialVendor(true);
    setLoadingNegotiationMaterial(true);

    const filterNotes = notes
      ?.filter(
        (filter) =>
          filter?.negotiation?.procurementNegotiationId ===
            (data?.negotiation?.procurementNegotiationId
              ? data?.negotiation?.procurementNegotiationId
              : data?.negotiation) &&
          filter?.negotiation?.companyRegCode ===
            (data?.company_reg_code
              ? data?.company_reg_code
              : data?.negotiation?.companyRegCode) &&
          filter?.status !== "DRAFT" &&
          filter?.notes === data?.notes
      )
      .map((dataNotes, iDatanotes) => dataNotes);

    setActiveNegotiationBid(filterNotes?.[0]);

    let valueMeasureUnit = {};
    let valueNotes = {};
    let vendorMaterial = [];

    axios
      .get(`${process.env.REACT_APP_TMS_API}/material`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const tempMaterialIds =
          reqRequisitions?.[0].reqRequisitionMaterial?.map(
            (materialIds) => materialIds?.material?.materialId
          );
        const measureUnit = res?.data?.material
          ?.filter(
            (measureUnit) => measureUnit?.materialId === tempMaterialIds?.[0]
          )
          .map((materials) => materials?.measureUnit?.unit);
        const tempMaterialPrice =
          reqRequisitions?.[0].reqRequisitionMaterial?.map(
            (materialIds) => materialIds
          );
        measureUnit?.map((dataUnit) => {
          valueMeasureUnit = {
            unit: dataUnit,
          };
        });
        valueNotes = {
          notes: data?.notes,
          status: data?.status,
        };

        const tempBidForm = {
          ...dataPrice?.[0],
          ...valueMeasureUnit,
          ...valueNotes,
        };

        resetBidForm(tempBidForm);
      });

    axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-commercial-offer-requisition-material`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newPassOffer =
          res?.data?.data?.procurementCommercialOfferRequisitionMaterials;

        axios
          .get(
            `${process.env.REACT_APP_TMS_API}/procurement-negotiation/initial-number/${procurement?.procurementNumber}/${activeVendor?.companyRegCode}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            const newPrice = res?.data?.initialNumber;
            setActiveNegotiationMaterial(newPrice);
            vendorMaterial.push(newPrice);
            setActiveNegotiationMaterialVendor(newPrice);
          })
          .finally(() => {
            setLoadingNegotiationMaterialVendor(false);
            setLoadingNegotiationMaterial(false);
            resetVendorBidForm(vendorMaterial);
            setReloadBid(true);
          });
      });
  };

  // -------------------------------
  // Update Bid Negotiation
  // -------------------------------

  const [isBidApproveOpenUpdate, setIsBidApproveOpenUpdate] = useState(false);
  const [negotiationBidApproveStatus, setNegotiationeBidApproveStatus] =
    useState();
  const [loadingBidApproveUpdate, setLoadingBidApproveUpdate] = useState(false);

  const [isBidRejectOpenUpdate, setIsBidRejectOpenUpdate] = useState(false);
  const [negotiationBidRejectStatus, setNegotiationeBidRejectStatus] =
    useState();
  const [loadingBidRejectUpdate, setLoadingBidRejectUpdate] = useState(false);

  // Update Status Bid
  const handleUpdateApproveBidOpen = (isBidApproveUpdateOpen, data, status) => {
    setNegotiationeBidApproveStatus(status);
    const filterNotes = notes
      ?.filter(
        (filterNotes) =>
          filterNotes?.negotiation?.procurementNegotiationId ===
            data?.negotiation &&
          filterNotes?.negotiation?.companyRegCode === data?.company_reg_code &&
          filterNotes?.status === "PROPOSED" &&
          filterNotes?.notes === data?.notes
      )
      .map((dataNotes, iDatanotes) => dataNotes);
    setActiveNegotiationBid(filterNotes?.[0]);
    setIsBidApproveOpenUpdate(isBidApproveUpdateOpen);
  };

  const handleUpdateRejectBidOpen = (isBidRejectUpdateOpen, data, status) => {
    setNegotiationeBidRejectStatus(status);
    const filterNotes = notes
      ?.filter(
        (filterNotes) =>
          filterNotes?.negotiation?.procurementNegotiationId ===
            data?.negotiation &&
          filterNotes?.negotiation?.companyRegCode === data?.company_reg_code &&
          filterNotes?.status === "PROPOSED" &&
          filterNotes?.notes === data?.notes
      )
      .map((dataNotes, iDatanotes) => dataNotes);
    setActiveNegotiationBid(filterNotes?.[0]);
    setIsBidRejectOpenUpdate(isBidRejectUpdateOpen);
  };

  const updateNegotiationBid = (payload) => {
    payload.data?.map((data, i) => {
      data.newPrice = payload?.price;
      data.createdBy = userData?.type === "user" ? "Vendor" : "Buyer";

      if (
        Object.keys(payload?.price)[i] === data?.reqRequisitionMaterialId &&
        Object.values(payload?.price)[i] !== undefined
      ) {
        data.price = Object.values(payload?.price)[i];
      } else {
        data.price = data?.price;
      }
    });

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/vendor/${activeNegotiation?.procurementNegotiationId}/${activeVendor?.companyRegCode}`,
        {
          payload,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setNotesNew(false);
        getNotes(activeVendor);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingBidNegotiation(false);
        setReloadNotes(true);
        setReloadBid(true);
        setMakeBid(false);
        setView(true);
      });
  };

  const updateStatusApproveBid = (payload) => {
    setLoadingBidApproveUpdate(true);

    const step = activeNegotiationBid?.step
      ? activeNegotiationBid?.step
      : activeNegotiationBid?.procurementNegotiationOfferId;

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/${step}`,
        {
          isProposed: negotiationBidApproveStatus,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        getNotes(activeVendor);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingBidApproveUpdate(false);
        setReloadNotes(true);
        setReloadBid(true);
        setIsBidApproveOpenUpdate(false);
      });
  };

  const updateStatusRejectBid = (payload) => {
    setLoadingBidRejectUpdate(true);

    const step = activeNegotiationBid?.step
      ? activeNegotiationBid?.step
      : activeNegotiationBid?.procurementNegotiationOfferId;

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/${step}`,
        {
          isProposed: negotiationBidRejectStatus,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        getNotes(activeVendor);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingBidRejectUpdate(false);
        setReloadNotes(true);
        setReloadBid(true);
        setIsBidRejectOpenUpdate(false);
      });
  };

  // -------------------------------
  // Get Data Penawaran
  // -------------------------------
  const [passOffer, setPassOffer] = useState([]);
  const [loadingPassOffer, setLoadingPassOffer] = useState(false);
  const [passOfferVendor, setPassOfferVendor] = useState([]);
  const [loadingPassOfferVendor, setLoadingPassOfferVendor] = useState(false);
  const [loadingPassAuction, setLoadingPassAuction] = useState(false);
  const [totArray, setTotArray] = useState([{}]);
  const [vendorNegotiating, setVendorNegotiating] = useState([])

  let arr = [];
  useEffect(() => {
    setLoadingPassOffer(true);
    setLoadingPassOfferVendor(true);
    setLoadingPassAuction(true);

    let vendorMaterial = [];
    axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/material/${dataReqRequisition?.reqRequisitionCode}/${materialCode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newPassOffer = res?.data?.negotiationMaterial;
        setPassOffer(newPassOffer);
      })
      .finally(() => {
        setLoadingPassOffer(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/vendor/${procurement?.procurementNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newPassOfferVendor = res?.data;
        newPassOfferVendor?.negotiationVendor?.map((vendor) =>
          axios
            .get(
              `${process.env.REACT_APP_TMS_API}/procurement-negotiation/initial-number/${procurement?.procurementNumber}/${vendor?.companyRegCode}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((res) => {
              const newPrice = res?.data?.initialNumber;
              setActiveNegotiationMaterial(newPrice);
              vendorMaterial.push(newPrice);

              const newArray = newPassOfferVendor?.negotiationVendor
                ?.filter((zz) => zz?.companyRegCode === vendor?.companyRegCode)
                .map((val) => [
                  {
                    data: val,
                    matPrice: newPrice?.reduce(
                      (previousValue, currentValue) =>
                        +(previousValue?.subPrice
                          ? previousValue.subPrice
                          : previousValue) + +currentValue?.subPrice
                    ),
                  },
                ])
                .flat();
              arr.push(...newArray);
            })
            .finally(() => {
              setLoadingNegotiationMaterialVendor(false);
              setLoadingNegotiationMaterial(false);
              setTotArray(arr);
              resetVendorBidForm(vendorMaterial);

            })
        );
      })
      .finally(() => {
        setLoadingPassOfferVendor(false);
      });

      axios
      .get(
        `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        const newNotes = res?.data?.procurementNegotiationOfferList;
        setVendorNegotiating(newNotes);
      })
      
  }, [procurement, dataReqRequisition]);

  // -------------------------------
  // Get Data Data Vendor Group
  // -------------------------------

  const [priceGroup, setPriceGroup] = useState([]);
  const [loadingPriceGroup, setLoadingPriceGroup] = useState(true);
  useEffect(() => {
    const getPriceGroup = async () => {
      setLoadingPriceGroup(true);
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-negotiation/notes/group/${procurement?.procurementNumber}/${activeVendor?.companyRegCode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setPriceGroup(res?.data?.negotiationVendorGroupBy);
        })
        .finally(() => {
          setReloadNotes(false);
          setReloadBid(false);
          setLoadingPriceGroup(false);
        });
    };
    activeVendor && reloadNotes && getPriceGroup();
  }, [procurement, activeVendor, reloadNotes]);

  return (
    <Provider
      value={{
        tableForm,
        tableFormNotes,
        tableFormBid,
        negotiation,
        notes,
        loadingNegotiation,
        loadingNotes,
        totalData,
        totalDataNotes,
        limit,
        limitNotes,

        form,
        formNotes,
        formBid,
        activeNegotiation,
        detailNegotiation,
        measureUnit,

        isCreate,
        handleCreate,
        loadingCreate,
        createNegotiation,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateNegotiation,
        updateStatusNotes,

        handleNegotiationSessionNewOpen,
        isNegotiationNew,
        setNegotiationNew,
        loadingNegotiationSessionNew,
        setLoadingNegotiationSessionNew,
        isEditNegotiationSessionNew,
        setEditNegotiationSessionNew,

        isNotesNew,
        setNotesNew,
        loadingNotesNew,
        setLoadingNotesNew,
        loadingNotesUpdate,
        setLoadingNotesUpdate,
        handleCreateNotes,
        createNotesNegotiation,
        handleUpdateNotesOpen,

        isOpenUpdate,
        setIsOpenUpdate,
        isActiveNotes,
        setActiveNotes,

        vendor,
        loadingVendor,
        reloadVendor,
        activeVendor,

        procurements,
        loadingProcurement,

        isMakeBid,
        handleCreateNegotiationBid,
        updateNegotiationBid,
        loadingBidNegotiation,

        isBidApproveOpenUpdate,
        negotiationBidApproveStatus,
        loadingBidApproveUpdate,
        updateStatusApproveBid,
        handleUpdateApproveBidOpen,

        isBidRejectOpenUpdate,
        negotiationBidRejectStatus,
        loadingBidRejectUpdate,
        updateStatusRejectBid,
        handleUpdateRejectBidOpen,

        activeNegotiationBid,
        isView,

        passOffer,
        loadingPassOffer,
        vendorPrice,

        passOfferVendor,
        loadingPassOfferVendor,
        activeNegotiationMaterialVendor,
        loadingNegotiationMaterialVendor,
        formVendorBid,
        activeNegotiationMaterial,
        loadingNegotiationMaterial,
        priceGroup,
        loadingPriceGroup,
        totArray,
        reloadBid,

        vendorNegotiating
      }}
    >
      {children}
    </Provider>
  );
};

export default NegotiationController;
