import moment from "moment";

moment.updateLocale("id", {
  relativeTime: {
    future: "%s lagi",
    past: "%s yang lalu",
    s: "Beberapa detik",
    ss: "%d detik",
    m: "1 menit",
    mm: "%d menit",
    h: "1 jam",
    hh: "%d jam",
    d: "1 hari",
    dd: "%d hari",
    w: "1 minggu",
    ww: "%d minggu",
    M: "1 bulan",
    MM: "%d bulan",
    y: "1 tahun",
    yy: "%d tahun",
  },
});
