import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const CertificateContext = createContext();
const { Provider } = CertificateContext;

const CertificateController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        certificateType: newForm?.certificateType?.certificateType,
        description: newForm?.description,
        validFrom: newForm?.validFrom ? new Date(newForm?.validFrom) : null,
        validUntil: newForm?.validUntil ? new Date(newForm?.validFrom) : null,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeCertificate, setActiveCertificate] = useState();
  const handleDetailMenu = (isOpen, certificate = null) => {
    setDetailMenu(isOpen);
    setActiveCertificate(certificate);
    isOpen && resetForm(certificate);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Certificates
  const [certificates, setCertificates] = useState([]);
  const [loadingCertificates, setLoadingCertificates] = useState(false);
  const [reloadCertificates, setReloadCertificates] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getCertificates = async () => {
      setLoadingCertificates(true);
      axios
        .get(`/certificate/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newCertificates = res?.data?.certificates;
            setCertificates(newCertificates);

            if (activeCertificate) {
              const newActiveCertificate =
                newCertificates.find(
                  (certificate) => certificate?.certificateId === activeCertificate?.certificateId
                ) || null;
              setActiveCertificate(newActiveCertificate);
              resetForm(newActiveCertificate);
            }
          }
        })
        .finally(() => {
          setReloadCertificates(false);
          setLoadingCertificates(false);
        });
    };

    userData && token && reloadCertificates && getCertificates();

    if (reloadCertificates) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadCertificates]);

  // -------------------------------
  // Master
  // -------------------------------

  // Certificate Type
  const [certificateTypes, setCertificateTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/certificate-type`).then((res) => {
      if (mounted) {
        setCertificateTypes(res?.data?.certificateType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createCertificate = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        validFrom: new Date(payload?.validFrom),
        validUntil: new Date(payload?.validUntil),
      })
    );
    formData.append("attachment", payload?.attachment[0]);

    axios
      .post(`certificate/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadCertificates(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeCertificate);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateCertificate = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        ...(payload?.validFrom ? { validFrom: new Date(payload.validFrom) } : {}),
        ...(payload?.validUntil ? { validUntil: new Date(payload.validUntil) } : {}),
      })
    );
    payload?.attachment && formData.append("attachment", payload?.attachment[0]);

    axios
      .patch(`certificate/${regCode}/${activeCertificate?.certificateId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadCertificates(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteCertificate = () => {
    setLoadingDelete(true);

    axios
      .delete(`certificate/${regCode}/${activeCertificate?.certificateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadCertificates(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingCertificates,
        certificates,
        activeCertificate,

        certificateTypes,

        isCreate,
        handleCreate,
        loadingCreate,
        createCertificate,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateCertificate,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteCertificate,
      }}
    >
      {children}
    </Provider>
  );
};

export default CertificateController;
