import { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HelperContext } from '../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../utils/apiURLs.util';
import { filterBuilder } from '../../../../../../../../utils/filterBuilder';
import { ProcurementDetailContext } from '../../../Controller';

const ObjectionContext = createContext();
export const useObjectionContext = () => useContext(ObjectionContext);

export const ObjectionController = ({ children }) => {
	const { t } = useTranslation();
	const { procurement } = useContext(ProcurementDetailContext);
	const { showToast, httpRequest } = useContext(HelperContext);

	const [isCreate, setIsCreate] = useState(false);

	const form = useForm();
	useEffect(() => {
		if (!isCreate) {
			form.reset({});
		}
	}, [isCreate]);

	const [vendors, setVendors] = useState([]);
	const [loadingVendors, setLoadingVendors] = useState(false);
	useEffect(() => {
		if (!procurement) return;
		let mounted = true;

		(async () => {
			const filter = filterBuilder({
				snippets: ['procurementOfferEvaluationVendor'],
			});

			setLoadingVendors(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor/test/${procurement.procurementNumber}?${filter}`,
			});
			if (!mounted) return;
			setLoadingVendors(false);

			if (!response?.procurementVendors) {
				showToast(t('ERROR.undefined'), { success: false });
				return;
			}
			const qualifiedVendors = response.procurementVendors?.filter((vendor) => {
				const qualified = vendor?.procurementOfferEvaluationVendor?.status === 'ACCEPTED' || vendor?.procurementOfferEvaluationVendor?.statusCommercial;
				return qualified;
			});
			setVendors(qualifiedVendors);
		})();

		return () => {
			mounted = false;
		};
	}, [procurement]);

	const [objections, setObjections] = useState([]);
	const [loadingObjections, setLoadingObjections] = useState(false);
	useEffect(() => {
		if (!procurement || !vendors) return;
		let mounted = true;
		(async () => {
			setLoadingObjections(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-objection/proc/${procurement.procurementNumber}`,
			});
			if (!mounted) return;
			setLoadingObjections(false);

			const objectionVendors = response?.data?.procurementObjections
				?.map((objection) => {
					const vendor = vendors?.find((vendor) => vendor.procurementVendorId === objection.procurementVendorId);
					objection.procurementVendor = vendor;
					return objection;
				})
				?.filter((objection) => objection.procurementVendor);
			setObjections(objectionVendors);
		})();

		return () => {
			mounted = false;
		};
	}, [procurement, vendors]);

	const [loadingCreate, setLoadingCreate] = useState(false);
	const createNewObjection = (payload) => {
		const formData = new FormData();

		payload.attachment && formData.append('attachment', payload.attachment[0]);
		const vendor = vendors?.find((vendor) => vendor.vendor === payload.objector);

		const data = {
			procurementNumber: procurement?.procurementNumber,
			procurementVendorId: vendor?.procurementVendorId,
			description: payload.description,
		};
		formData.append('data', JSON.stringify(data));

		(async () => {
			setLoadingCreate(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-objection`,
				method: 'POST',
				payload: formData,
			});
			setLoadingCreate(false);

			if (!response?.success) {
				showToast(t('ERROR.undefined'), { success: false });
				return;
			}
			const objection = response?.data?.procurementObjection;
			if (objection) {
				const vendor = vendors?.find((vendor) => vendor.procurementVendorId === objection.procurementVendorId);
				objection.procurementVendor = vendor;
				setObjections([...objections, objection]);
			}
			setIsCreate(false);
			showToast('Berhasil membuat sanggahan');
		})();
	};

	const [loadingDelete, setLoadingDelete] = useState(false);
	const deleteObjection = async (idx) => {
		const objection = objections?.[idx];
		if (!objection) return;

		setLoadingDelete(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-objection/${objection.id}`,
			method: 'DELETE',
		});
		setLoadingDelete(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		setActiveObjectionIdx(null);
		showToast(t('DELETE-200'));
		const temp = objections?.filter((_, i) => i !== idx);
		setObjections(temp);
	};

	const [activeObjectionIdx, setActiveObjectionIdx] = useState(null);

	return (
		<ObjectionContext.Provider
			value={{
				isCreate,
				setIsCreate,
				vendors,
				loadingVendors,
				form,
				createNewObjection,
				loadingCreate,
				objections,
				setObjections,
				loadingObjections,
				activeObjectionIdx,
				setActiveObjectionIdx,
				deleteObjection,
				loadingDelete,
			}}
		>
			{children}
		</ObjectionContext.Provider>
	);
};
