import { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HelperContext } from '../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../Controller';

export const ParticipantContext = createContext();
const { Provider } = ParticipantContext;

const ParticipantController = ({ children }) => {
	const { httpRequest } = useContext(HelperContext);
	const { procurement } = useContext(ProcurementDetailContext);

	const tableForm = useForm({
		defaultValues: {
			page: 1,
		},
	});

	const vendorsPage = tableForm.watch('page');

	const [pagination, setPagination] = useState({});
	const [vendors, setVendors] = useState([]);
	const [loadingVendors, setLoadingVendors] = useState(false);

	useEffect(() => {
		if (!procurement) return;
		let mounted = true;

		(async () => {
			const search = `search=${''}`;
			const limit = `limit=${pagination?.limit || 10}`;
			const page = `page=${vendorsPage}`;
			const filter = `?${search}&${limit}&${page}&status='ACCEPT'`;

			setLoadingVendors(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor/${procurement?.procurementNumber}${filter}`,
			});
			if (!mounted) return;
			setLoadingVendors(false);

			if (!response?.procurementVendors) {
				setVendors(null);
				return;
			}
			setPagination(response?.pagination);
			setVendors(response.procurementVendors);
		})();
    
		return () => {
			mounted = false;
		};
	}, [procurement, vendorsPage]);

	return (
		<Provider
			value={{
				vendors,
				setVendors,
				loadingVendors,
				tableForm,
				pagination,
			}}
		>
			{children}
		</Provider>
	);
};

export default ParticipantController;
