import React, { useContext } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CompetencyContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { emailPattern, websitePattern } from "../../../../../../../utils/regex.util";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";

const Form = ({ isEdit }) => {
  const { t, i18n } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeCompetency, form, competencyVendorTypes, competencies, isic, subIsic } =
    useContext(CompetencyContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate || !activeCompetency?.attachment,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") }) }),
    },
  });

  return (
    <>
      {/* Business Field */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BUSINESS_FIELD")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_FIELD") }) },
            }}
            name="businessField"
            options={isic.map((isicItem) => ({
              label: `${isicItem?.isicCode} - ${
                i18n.language === "id" ? isicItem?.description : isicItem?.descriptionEn
              }`,
              value: isicItem?.isicCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_FIELD")}`}
            isError={!!errors?.businessField}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{_.startCase(activeCompetency?.businessField?.description?.toLowerCase())}</FormDescription>
        )}
        {errors?.businessField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.businessField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Sub Business Field */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.SUB_BUSINESS_FIELD")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.SUB_BUSINESS_FIELD") }) },
            }}
            name="subBusinessField"
            options={subIsic.map((isicItem) => ({
              label: `${isicItem?.isicCode} - ${
                i18n.language === "id" ? isicItem?.description : isicItem?.descriptionEn
              }`,
              value: isicItem?.isicCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.SUB_BUSINESS_FIELD")}`}
            disabled={!subIsic.length}
            isError={!!errors?.subBusinessField}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {_.startCase(activeCompetency?.subBusinessField?.description?.toLowerCase())}
          </FormDescription>
        )}
        {errors?.subBusinessField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.subBusinessField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Description */}
      <FormControl isRequired className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DESCRIPTION")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${errors?.description && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DESCRIPTION")}
            {...register("description", { required: t("INVALID.REQUIRED", { field: t("LABEL.DESCRIPTION") }) })}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeCompetency?.description}</FormDescription>
        )}
        {errors?.description && (
          <FormHelperText className="text-red-400 text-xs">{errors?.description?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Competency Vendor Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VENDOR_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.VENDOR_TYPE") }) },
            }}
            name="competencyVendorType"
            options={competencyVendorTypes.map((competencyVendorType) => ({
              label: t(competencyVendorType?.labelI18n || competencyVendorType?.label),
              value: competencyVendorType?.competencyVendorType,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.VENDOR_TYPE")}`}
            isError={!!errors?.competencyVendorType}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {t(activeCompetency?.vendorType?.labelI18n || activeCompetency?.vendorType?.label)}
          </FormDescription>
        )}

        {errors?.competencyVendorType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.competencyVendorType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Competency Attachment */}
      <FormControl isRequired={isCreate || !activeCompetency?.attachment}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_PROFILE") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.COMPANY_PROFILE"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : activeCompetency?.attachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeCompetency?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <FormDescription />
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
