import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../Controller";

export const DocumentContext = createContext();
const { Provider } = DocumentContext;

const DocumentController = ({ children }) => {
  const toast = useToast();
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        isPublic: newForm?.isPublic,
      });
    }
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Get Internal Document
  const [internalTotalData, setInternalTotalData] = useState(0);
  const [internalLimit] = useState(10);
  const internalTableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });

  const [internalDocuments, setInternalDocuments] = useState([]);
  const [loadingInternalDocuments, setLoadingInternalDocuments] = useState(false);
  const [reloadInternalDocuments, setReloadInternalDocuments] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = internalTableForm.getValues();

    // Filter
    const limit = `limit=${internalLimit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limit}&${page}`;

    const getInternalDocuments = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-document/${procurementNumber}/false${filter}`)
        .then((res) => {
          if (mounted) {
            const newDocuments = res?.data?.procurementDocuments;
            setInternalDocuments(newDocuments);
            setInternalTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setLoadingInternalDocuments(false);
          setReloadInternalDocuments(false);
        });
    };

    procurementNumber && reloadInternalDocuments && getInternalDocuments();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadInternalDocuments]);

  // Get Public Document
  const [publicTotalData, setPublicTotalData] = useState(0);
  const [publicLimit] = useState(10);
  const publicTableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });

  const [publicDocuments, setPublicDocuments] = useState([]);
  const [loadingPublicDocuments, setLoadingPublicDocuments] = useState(false);
  const [reloadPublicDocuments, setReloadPublicDocuments] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = publicTableForm.getValues();

    // Filter
    const limit = `limit=${publicLimit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limit}&${page}`;

    const getPublicDocuments = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-document/${procurementNumber}/true${filter}`)
        .then((res) => {
          if (mounted) {
            const newDocuments = res?.data?.procurementDocuments;
            setPublicDocuments(newDocuments);
            setPublicTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setLoadingPublicDocuments(false);
          setReloadPublicDocuments(false);
        });
    };

    procurementNumber && reloadPublicDocuments && getPublicDocuments();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadPublicDocuments]);

  // -------------------------------
  // Create
  // -------------------------------

  const [isCreate, setCreate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const handleCreate = (isNewCreate, isPublic = false) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({ isPublic });
  };
  const createDocument = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    formData.append("attachment", payload?.attachment[0]);

    axios
      .post(`${process.env.REACT_APP_TMS_API}/procurement-document/${procurement.procurementNumber}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadInternalDocuments(true);
        setReloadPublicDocuments(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Delete
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleteProcurementDocument, setDeleteProcurementDocument] = useState();
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const handleDelete = (isOpen, procurement) => {
    setModalDeleteOpen(isOpen);
    setDeleteProcurementDocument(procurement);
  };
  const deleteDocument = () => {
    setLoadingDelete(true);

    axios
      .delete(
        `${process.env.REACT_APP_TMS_API}/procurement-document/${deleteProcurementDocument?.procurementDocumentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        handleDelete(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadInternalDocuments(true);
        setReloadPublicDocuments(true);
      });
  };

  return (
    <Provider
      value={{
        form,

        internalDocuments,
        loadingInternalDocuments,
        internalTotalData,
        internalLimit,
        internalTableForm,

        publicDocuments,
        loadingPublicDocuments,
        publicTotalData,
        publicLimit,
        publicTableForm,

        isCreate,
        handleCreate,
        loadingCreate,
        createDocument,

        deleteProcurementDocument,
        isModalDeleteOpen,
        handleDelete,
        loadingDelete,
        deleteDocument,
      }}
    >
      {children}
    </Provider>
  );
};

export default DocumentController;
