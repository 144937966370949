import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus, BiDownload, BiTrash } from "react-icons/bi";
import moment from "moment";
import { DocumentContext } from "../Controller";
import { FileserviceContext } from "../../../../../../../../../controllers/FileserviceController";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import { ProcurementDetailContext } from "../../../../Controller";

const Row = ({ data }) => {
  const {procurement} = useContext(ProcurementDetailContext);
  const { downloadFile } = useContext(FileserviceContext);
  const { t } = useTranslation();
  const { handleDelete } = useContext(DocumentContext);
  const { userData } = useContext(AuthContext);

  return (
    <Tr className="hover:bg-gray-50">
      <Td>{data?.title}</Td>
      <Td>
        <Button className="w-full" size="sm" onClick={() => downloadFile(data?.attachment, "procurement")}>
          <BiDownload className="mr-2" />
          {t("LABEL.DOWNLOAD")}
        </Button>
      </Td>
      <Td>{moment(data?.createdAt).format("DD/MM/YYYY")}</Td>
      <Td className="px-4">
        {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
          <PrivateComponent accessName="BUYER_EDIT">
            <div className="w-full h-full flex justify-end items-center">
              <button onClick={() => handleDelete(true, data)}>
                <BiTrash className="text-lg text-red-500" />
              </button>
            </div>
          </PrivateComponent>
        ) : (
          ""
        )}
      </Td>
    </Tr>
  );
};

const DocumentTable = ({ documents, loadingDocuments, totalData, limit, tableForm, isPublic = false }) => {
  const { procurement } = useContext(ProcurementDetailContext);
  const { handleCreate } = useContext(DocumentContext);
  const { userData } = useContext(AuthContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-between items-center">
        <span className="font-semibold text-gray-700" style={{ borderBottom: "1px solid #777" }}>
          {isPublic ? t("LABEL.PUBLIC_DOCUMENT") : t("LABEL.INTERNAL_DOCUMENT")}
        </span>
        {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner && (
          <PrivateComponent accessName="BUYER_EDIT">
            <Button size="sm" onClick={() => handleCreate(true, isPublic)}>
              <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: t("LABEL.DOCUMENT") })}
            </Button>
          </PrivateComponent>
        )}
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.DOCUMENT_TITLE")}</Th>
            <Th className="text-red-500">{t("LABEL.ATTACHMENT", { field: "" })}</Th>
            <Th className="text-red-500">{t("LABEL.CREATED_AT")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingDocuments ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !documents.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            documents.map((document, iDocument) => <Row key={iDocument} data={document} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default DocumentTable;
