import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import RequisitionerNew from "./components/RequisitionerNew";
import RequisitionerFilter from "./components/RequisitionerFilter";
import RequisitionTable from "./components/RequisitionerTable";
import RequisitionerController, { RequisitionerContext } from "./Controller";
import RequisitionerDetail from "./components/RequisitionerDetail";

const Requisitioner = () => {
  const { t } = useTranslation();
  const { isDetailMenu, isCreate } = useContext(RequisitionerContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.REQUISITIONER")}</h2>
      </div>

      {isCreate ? (
        <RequisitionerNew />
      ) : isDetailMenu ? (
        <RequisitionerDetail />
      ) : (
        <div className="grid md:grid-cols-4 gap-4 md:gap-8">
          <div>
            <RequisitionerFilter />
          </div>
          <div className="md:col-span-3">
            <RequisitionTable />
          </div>
        </div>
      )}
    </div>
  );
};

export default () => (
  <RequisitionerController>
    <Requisitioner />
  </RequisitionerController>
);
