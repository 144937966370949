import {
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import { ReqProcSubmissionContext } from "../Controller";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import NextStepButton from "../../../NextStepButton";
import { ProcurementDetailContext } from "../../../../Controller";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { handleDetailMenu } = useContext(ReqProcSubmissionContext);
  const { userData } = useContext(AuthContext);

  return (
    <Tr
      className={`hover:bg-gray-50 ${
        userData?.type !== "user" ? "cursor-pointer" : ""
      } `}
      onClick={() =>
        userData?.type !== "user" ? handleDetailMenu(true, data) : ""
      }
    >
      <Td>{data?.description}</Td>
      <Td>{data?.requirementProcurementStep}</Td>
      <Td>{data?.requirementProcurementCover}</Td>
      <Td>{data?.isRequired ? "Ya" : "Tidak"}</Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          {userData?.type !== "user" ? (
            <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
          ) : (
            ""
          )}
        </div>
      </Td>
    </Tr>
  );
};

const ReqProcSubmissionTable = () => {
  const {
    handleCreate,
    reqProcSubmission,
    loadingReqProcSubmission,
    totalData,
    limit,
    tableForm,
  } = useContext(ReqProcSubmissionContext);
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const { procurement } = useContext(ProcurementDetailContext);

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-between items-center">
        <span
          className="font-semibold text-gray-700"
          style={{ borderBottom: "1px solid #777" }}
        >
          {t("LABEL.PROCUREMENT_REQUIREMENT")}
        </span>
        {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
          <PrivateComponent accessName="BUYER_EDIT">
            <Button size="sm" onClick={() => handleCreate(true)}>
              <BiPlus className="mr-1" />{" "}
              {t("LABEL.ADD", { field: t("LABEL.PROCUREMENT_REQUIREMENT") })}
            </Button>
          </PrivateComponent>
        ) : (
          ""
        )}
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">
              {t("LABEL.DESCRIPTION_PROCUREMENT_REQUIREMENT")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_REQUIREMENT_STEP")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_REQUIREMENT_COVER")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_REQUIREMENT_REQUIRED")}
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingReqProcSubmission ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !reqProcSubmission.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">
                  {t("TEXT.DATA_NOT_FOUND")}
                </p>
              </Td>
            </Tr>
          ) : (
            reqProcSubmission.map((reqProcSubmission, iReqProcSubmission) => (
              <Row key={iReqProcSubmission} data={reqProcSubmission} />
            ))
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
      <NextStepButton isDisabled={
        !totalData || 
        (procurement?.procurementSetting?.prequalification && !reqProcSubmission?.find(req => req.requirementProcurementStep === "PRAKUALIFIKASI"))
        } url="proc-schedule" to="procSchedule" className='mt-4' toLabel={`Jadwal Pengadaan`} />
    </div>
  );
};

export default ReqProcSubmissionTable;
