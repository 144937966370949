import React from "react";
import { useTranslation } from "react-i18next";
import NotificationList from "./components/NotificationList";
import NotificationController, { NotificationContext } from "./Controller";

const Notification = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.NOTIFICATION")}</h2>

      <NotificationList />
    </div>
  );
};

export default () => (
  <NotificationController>
    <Notification />
  </NotificationController>
);
