import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Datepicker from "../../../../../../../../../../components/inputForm/Datepicker";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcScheduleContext } from "../../Controller";
import { ProcurementDetailContext } from "../../../../../Controller";
import { AuthContext } from "../../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../../components/PrivateComponent";

const QuotationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    quotationForm,
    schedules,
    loadingQuotation,
    handleCreateQuotation,
    createQuotationProcSchedule,
  } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = quotationForm;

  return (
    <>
      {/* Quotatioh */}

      <>
        <hr className="md:col-span-2" />
        <FormControl className="md:col-span-2">
          <FormLabel
            className={`uppercase font-bold text-sm text-red-500 mb-4`}
          >
            {t("LABEL.PROCUREMENT_TENDER")}
          </FormLabel>

          <div
            className={`md:col-span-2 grid ${
              userData?.type !== "user" ? "md:grid-cols-3" : "md:grid-cols-2"
            } gap-6 items-center`}
          >
            {/* Start Quotation */}
            <FormControl isRequired={isEdit}>
              <FormLabel
                className={`uppercase font-semibold text-xs ${
                  !isEdit && "text-red-500"
                }`}
              >
                {t("LABEL.PROCUREMENT_START", {
                  field: t("LABEL.PROCUREMENT_TENDER"),
                })}
              </FormLabel>
              {isEdit ? (
                <Datepicker
                  format="yyyy-MM-dd HH:mm:ss"
                  name="quotationStart"
                  placeholder={t("LABEL.PROCUREMENT_START", {
                    field: t("LABEL.PROCUREMENT_TENDER"),
                  })}
                  control={control}
                  controlProps={{
                    rules: {
                      required: t("INVALID.REQUIRED", {
                        field: t("LABEL.PROCUREMENT_START", {
                          field: t("LABEL.PROCUREMENT_TENDER"),
                        }),
                      }),
                    },
                  }}
                  isError={!!errors?.quotationStart}
                />
              ) : (
                <FormDescription>
                  {schedules[0]?.quotationStart &&
                    moment(new Date(schedules[0]?.quotationStart)).format(
                      "LLL"
                    )}
                </FormDescription>
              )}
              {errors?.quotationStart && (
                <FormHelperText className="text-red-400 text-xs">
                  {errors?.quotationStart?.message}
                </FormHelperText>
              )}
            </FormControl>

            {/* End Quotation */}
            <FormControl isRequired={isEdit}>
              <FormLabel
                className={`uppercase font-semibold text-xs ${
                  !isEdit && "text-red-500"
                }`}
              >
                {t("LABEL.PROCUREMENT_END", {
                  field: t("LABEL.PROCUREMENT_TENDER"),
                })}
              </FormLabel>
              {isEdit ? (
                <Datepicker
                  format="yyyy-MM-dd HH:mm:ss"
                  name="quotationEnd"
                  placeholder={t("LABEL.PROCUREMENT_END", {
                    field: t("LABEL.PROCUREMENT_TENDER"),
                  })}
                  control={control}
                  controlProps={{
                    rules: {
                      required: t("INVALID.REQUIRED", {
                        field: t("LABEL.PROCUREMENT_END", {
                          field: t("LABEL.PROCUREMENT_TENDER"),
                        }),
                      }),
                    },
                  }}
                  isError={!!errors?.quotationEnd}
                />
              ) : (
                <FormDescription>
                  {schedules[0]?.quotationEnd &&
                    moment(new Date(schedules[0]?.quotationEnd)).format("LLL")}
                </FormDescription>
              )}
              {errors?.quotationEnd && (
                <FormHelperText className="text-red-400 text-xs">
                  {errors?.quotationEnd?.message}
                </FormHelperText>
              )}
            </FormControl>
            {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
              isEdit ? (
                <PrivateComponent accessName="BUYER_EDIT">
                  <Button
                    size="sm"
                    colorScheme="teal"
                    isLoading={loadingQuotation}
                    onClick={quotationForm.handleSubmit(
                      createQuotationProcSchedule
                    )}
                  >
                    {t("LABEL.SAVE")}
                  </Button>
                </PrivateComponent>
              ) : (
                <PrivateComponent accessName="BUYER_EDIT">
                  <Button
                    size="sm"
                    colorScheme="gray"
                    isLoading={loadingQuotation}
                    onClick={() => handleCreateQuotation(true, schedules)}
                    disabled={!schedules[0]?.aanwijzingEnd ? true : false}
                  >
                    {t("LABEL.EDIT", { field: t("LABEL.PROCUREMENT_TENDER") })}
                  </Button>
                </PrivateComponent>
              )
            ) : (
              ""
            )}
          </div>
        </FormControl>
      </>
    </>
  );
};

export default QuotationForm;
