import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { publicRouteSlug } from "../../../../routes/publicRoutes";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import UserCheck from "../../../../assets/img/static/user-check.png";
import { Button, Spinner } from "@chakra-ui/react";
import { ActivationContext } from "../Controller";

const CompletePage = () => {
  const { t } = useTranslation();
  const { loadingActivate } = useContext(ActivationContext);
  return (
    <div className="h-full flex flex-col gap-y-8 justify-center items-center p-10">
      {loadingActivate ? (
        <Spinner size="xl" color="red.500" />
      ) : (
        <>
          <motion.img initial={{ y: -50 }} animate={{ y: 0 }} src={UserCheck} className="h-20 md:h-28" />

          <motion.div initial={{ y: 50 }} animate={{ y: 0 }} className="text-center">
            <h1 className="font-bold text-2xl md:text-3xl montserrat text-transparent bg-clip-text bg-gradient-primary mb-4 md:mb-2">
              {t("TEXT.ACCOUNT_ACTIVATED")}
            </h1>
            <p className="text-gray-600 mb-8">{t("TEXT.LOGIN_AFTER_ACTIVATION")}</p>
            <Link to={publicRouteSlug.LOGIN}>
              <Button className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 text-white">
                {t("TEXT.LOGIN_WITH_ACCOUNT")}
              </Button>
            </Link>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default CompletePage;
