import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const UserContext = createContext();
const { Provider } = UserContext;

const UserController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        username: newForm?.userAccount?.username,
        name: newForm?.name,
        email: newForm?.email,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeUser, setActiveUser] = useState();
  const handleDetailMenu = (isOpen, user = null) => {
    setDetailMenu(isOpen);
    setActiveUser(user);
    isOpen && resetForm(user);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Users
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getUsers = async () => {
      setLoadingUsers(true);
      axios
        .get(`/user/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newUsers = res?.data?.users;
            setUsers(newUsers);

            if (activeUser) {
              const newActiveUser = newUsers.find((user) => user?.userId === activeUser?.userId) || null;
              setActiveUser(newActiveUser);
              resetForm(newActiveUser);
            }
          }
        })
        .finally(() => {
          setReloadUsers(false);
          setLoadingUsers(false);
        });
    };

    userData && token && reloadUsers && getUsers();

    if (reloadUsers) {
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadUsers]);

  // -------------------------------
  // Validation
  // -------------------------------

  // Username Availability
  const username = form.watch("username");
  const [isUsernameAvailable, setUsernameAvailable] = useState();
  const [loadingUsernameAvailable, setLoadingUsernameAvailable] = useState(false);
  const [usernameAvailableTimeout, setUsernameAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkUsername = () => {
      setLoadingUsernameAvailable(true);

      axios
        .post("/user/check-username", {
          username,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setUsernameAvailable(username === activeUser?.userAccount?.username || available);
        })
        .finally(() => {
          setLoadingUsernameAvailable(false);
          form.trigger("username");
        });
    };

    clearTimeout(usernameAvailableTimeout);
    const newTimeout = setTimeout(() => {
      username ? checkUsername() : setUsernameAvailable();
    }, 500);
    setUsernameAvailableTimeout(newTimeout);

    return () => {
      mounted = false;
    };
  }, [username]);

  // Email Availability
  const email = form.watch("email");
  const [isEmailAvailable, setEmailAvailable] = useState();
  const [loadingEmailAvailable, setLoadingEmailAvailable] = useState(false);
  const [emailAvailableTimeout, setEmailAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkEmail = () => {
      setLoadingEmailAvailable(true);

      axios
        .post("/user/check-email", {
          email,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setEmailAvailable(email === activeUser?.email || available);
        })
        .finally(() => {
          setLoadingEmailAvailable(false);
          form.trigger("email");
        });
    };

    clearTimeout(emailAvailableTimeout);
    const newTimeout = setTimeout(() => {
      email ? checkEmail() : setEmailAvailable();
    }, 500);
    setEmailAvailableTimeout(newTimeout);

    return () => {
      mounted = false;
    };
  }, [email]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createUser = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`user/${regCode}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadUsers(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeUser);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateUser = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(`user/${regCode}/${activeUser?.userId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadUsers(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteUser = () => {
    setLoadingDelete(true);

    axios
      .delete(`user/${regCode}/${activeUser?.userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadUsers(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  // -------------------------------
  // Star Data
  // -------------------------------

  const [isModalStarOpen, setModalStarOpen] = useState(false);
  const [loadingStar, setLoadingStar] = useState(false);
  const starUser = () => {
    setLoadingStar(true);

    axios
      .patch(
        `user/star/${regCode}/${activeUser?.userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadUsers(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingStar(false);
        setModalStarOpen(false);
      });
  };

  return (
    <Provider
      value={{
        form,
        loadingUsers,
        users,
        activeUser,

        isEmailAvailable,
        loadingEmailAvailable,
        isUsernameAvailable,
        loadingUsernameAvailable,

        isCreate,
        handleCreate,
        loadingCreate,
        createUser,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateUser,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteUser,

        isModalStarOpen,
        setModalStarOpen,
        loadingStar,
        starUser,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserController;
