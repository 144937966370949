import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VendorContext } from "../Controller";
import _ from "lodash";
import { userRouteSlug } from "../../../../../../../../../routes/userRoutes";
import { Link } from "react-router-dom";
import ModalPropose from "./ModalPropose";
import { BiCrown } from "react-icons/bi";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import ModalApprove from "./ModalApprove";
import ModalReject from "./ModalReject";
import numeral from "numeral";
import { ProcurementDetailContext } from "../../../../Controller";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import PrintDocumentButton from "../../../../../../../../../components/PrintDocumentButton";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { roles } = useContext(AuthContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { handlePropose, handleApprove, handleReject, winnerProposed, finalPrice } = useContext(VendorContext);

  const activeFinalPrice = finalPrice?.find((priceData) => priceData?.vendor === data?.vendor);

  return (
    <Tr className="hover:bg-gray-50">
      <Td>
        <Link
          className="font-semibold text-blue-600 hover:text-blue-500"
          to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${data?.vendor}`}
          target="_blank"
        >
          {data?.vendor}
        </Link>
      </Td>
      <Td>{data?.company?.companyName}</Td>
      <Td className="text-right">
        {numeral(activeFinalPrice?.price).format("0,0.00")} {procurement?.currency?.currency}
      </Td>
      <Td className="px-4">
        <div className="w-full h-full flex gap-3 items-center">
          {winnerProposed?.vendor === data?.vendor ? (
            roles?.find((role) => role?.role === winnerProposed?.approvalStep?.approver) ? (
              
              <div className="flex gap-2 items-center">
                <Button size="sm" colorScheme="red" onClick={() => handleReject(true, { vendor: data?.vendor })}>
                  {t("LABEL.REJECT")}
                </Button>
                <Button size="sm" colorScheme="blue" onClick={() => handleApprove(true, { vendor: data?.vendor })}>
                  {t("LABEL.APPROVE_WINNER")}
                </Button>
              </div>
              
            ) : winnerProposed?.isWinner ? (
              <div className="flex gap-2 items-center font-semibold">
                <BiCrown className="text-yellow-500" /> <span>{t("LABEL.WINNER")}</span>
              </div>
            ) : (
              <div className="flex gap-2 items-center font-semibold">
                <BiCrown className="text-yellow-500" /> <span>{t("LABEL.PROPOSED")}</span>
              </div>
            )
          ) : (
            !winnerProposed && (
              <PrivateComponent accessName="BUYER_EDIT">
                <Button
                  size="sm"
                  colorScheme="green"
                  onClick={() => handlePropose(true, { vendor: data?.vendor, price: +activeFinalPrice?.price })}
                >
                  {t("LABEL.PROPOSE_WINNER")}
                </Button>
              </PrivateComponent>
            )
          )}
        </div>
      </Td>
    </Tr>
  );
};

const VendorTable = () => {
  const { procurement } = useContext(ProcurementDetailContext);
  const { vendor, loadingRequisition } = useContext(VendorContext);
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.REGISTRATION_ID")}</Th>
            <Th className="text-red-500">{t("LABEL.COMPANY_NAME")}</Th>
            <Th className="text-red-500 text-right">{t("LABEL.MATERIAL_PRICE")}</Th>
            <Th>
              <div className="flex justify-end">
                <PrintDocumentButton isDisabled={!procurement?.procurementWinnerProposal?.isWinner} className="py-1" url={`sppbj/${procurement?.procurementNumber}`} label="Cetak SPPBJ" />
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {loadingRequisition ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !vendor.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            vendor.map((vendor, iVendor) => <Row key={iVendor} data={vendor} />)
          )}
        </Tbody>
      </Table>

      <ModalPropose />
      <ModalApprove />
      <ModalReject />
    </div>
  );
};

export default VendorTable;
