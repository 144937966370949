import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AdminApprovalContext } from "./Controller";

const ModalReject = () => {
  const {
    handleSubmit,
    getValues,
    register,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const { isModalRejectOpen, setModalRejectOpen, loadingReject, submitReject } = useContext(AdminApprovalContext);

  return (
    <Modal onClose={() => setModalRejectOpen(false)} size="xl" isOpen={isModalRejectOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>
          {t("LABEL.REJECT")} {t("LABEL.COMPANY_PROFILE")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-2">
            <p>{t("TEXT.INPUT_REJECT_MESSAGE")}</p>
            <InputTextarea
              className={`w-full text-sm ${errors?.rejectMessage && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("TEXT.INPUT_REJECT_MESSAGE_PLACEHOLDER")}
              {...register("rejectMessage", { required: t("INVALID.REQUIRED", { field: t("LABEL.ADDRESS") }) })}
            />
          </div>
        </ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => setModalRejectOpen(false)}>{t("LABEL.CANCEL")}</Button>
          <Button isLoading={loadingReject} onClick={handleSubmit(() => submitReject(getValues()))} colorScheme="red">
            {t("LABEL.REJECT")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalReject;
