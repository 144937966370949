import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import ProcurementOfferController, { ProcurementOfferContext } from './Controller';
import OfferNavbar from './components/OfferNavbar';
import OfferOverviewPage from './components/OfferOverviewPage';
import OfferAdministratifPage from './components/administratif';
import OfferCommercialPage from './components/commercial';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../utils/offerPhases.util';
import { ProcurementDetailContext } from '../../../Controller';
import { useTranslation } from 'react-i18next';

const pages = [<OfferOverviewPage />, <OfferAdministratifPage />, <OfferCommercialPage />];

const ProcurementOffer = () => {
	const { t } = useTranslation();
	const { activePageIdx, offerPhase } = useContext(ProcurementOfferContext);
	const { vendorStatus } = useContext(ProcurementDetailContext);

	return (
		<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
			{(offerPhase === OFFER_2_COVER_PHASES.PRA || offerPhase === OFFER_2_COVER_PHASES.POST) && (
				<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-4">
					{offerPhase === OFFER_2_COVER_PHASES.PRA && (
						<p className="bg-white text-gray-700 text-base flex items-center font-semibold">
							{vendorStatus && vendorStatus.status === 'DRAFT' ? <>{t('TEXT.NOT_REGISTERED')}</> : <>{t('TEXT.PRA_QUOTATION_WARNING')}</>}
						</p>
					)}
					{offerPhase === OFFER_2_COVER_PHASES.POST && <p className="bg-white text-gray-700 text-base flex items-center font-semibold">{t('TEXT.POST_QUOTATION_WARNING')}</p>}
				</div>
			)}

			<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-8">
				<OfferNavbar />
				<div>{pages[activePageIdx]}</div>
			</div>
		</motion.div>
	);
};

export default () => (
	<ProcurementOfferController>
		<ProcurementOffer />
	</ProcurementOfferController>
);
