import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { NegotiationContext } from "../Controller";
import { ProcurementDetailContext } from "../../../../Controller";

const Row = (props) => {
  return (
    <Tr className="hover:bg-gray-50">
      <Td>{props?.data?.material?.description}</Td>
      <Td>{props?.req.reqRequisitionCode}</Td>
      <Td>{props?.data?.material?.materialCode}</Td>
    </Tr>
  );
};

const NegotiationTable = () => {
  const { totalData, limit, tableForm } = useContext(NegotiationContext);
  const { reqRequisitions, loadingReqRequisitions } = useContext(
    ProcurementDetailContext
  );
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-between items-center">
        <span
          className="font-semibold text-gray-700"
          style={{ borderBottom: "1px solid #777" }}
        >
          {t("LABEL.PROCUREMENT_NEGOTIATION")}
        </span>
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_NEGOTIATION_DESCRIPTION")}
            </Th>
            <Th className="text-red-500">{t("LABEL.REQUISITION_CODE")}</Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_NEGOTIATION_NOMOR_PK_ITEM")}
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingReqRequisitions ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !reqRequisitions?.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">
                  {t("TEXT.DATA_NOT_FOUND")}
                </p>
              </Td>
            </Tr>
          ) : (
            reqRequisitions?.map((reqRequisition, iReqRequisition) => (
              <Fragment key={iReqRequisition}>
                {reqRequisition?.reqRequisitionMaterial?.map(
                  (requisitionMaterial, iRequisitionMaterial) => (
                    <Row
                      key={iRequisitionMaterial}
                      data={requisitionMaterial}
                      req={reqRequisition}
                    />
                  )
                )}
              </Fragment>
            ))
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default NegotiationTable;
