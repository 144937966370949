import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import { BsCaretDownFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const NavbarItem = ({ item, className = "" }) => {
  return (
    <div className={`font-medium text-gray-600 ${className}`}>
      {item?.items?.length ? (
        <Popover trigger={"hover"} placement={"bottom-start"}>
          <PopoverTrigger>
            <Link to={item?.path || "#"} className="transition hover:text-gray-400 flex items-center gap-x-1">
              <span>{item?.label}</span>
              <BsCaretDownFill style={{ fontSize: "0.6rem" }} />
            </Link>
          </PopoverTrigger>
          <PopoverContent className="mt-2">
            <div className="flex flex-col overflow-hidden">
              {item.items?.map((child, iChild) => (
                <Link key={iChild} to="#" className="p-3 px-4 transition hover:bg-gray-100 hover:text-gray-400">
                  {child?.label}
                </Link>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      ) : (
        <Link to={item?.path || "#"} onClick={() => item?.onClick()} className="transition hover:text-gray-400">
          {item?.label}
        </Link>
      )}
    </div>
  );
};

export default NavbarItem;
