import { Spinner, Table, TableCaption, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCaretRightFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { VendorContext } from "../Controller";
import _ from "lodash";
import { Pagination } from "rsuite";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`${data?.registrationCode}`);
  };

  return (
    <Tr className="hover:bg-gray-50 cursor-pointer" onClick={handleClick}>
      <Td>{data?.registrationCode}</Td>
      <Td>{data?.companyName}</Td>
      <Td>
        {_.startCase(data?.city?.toLowerCase())}, {data?.country}
      </Td>
      <Td>{t(data?.vendorStatus?.labelI18n || data?.vendorStatus?.label)}</Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const VendorTable = () => {
  const { t } = useTranslation();
  const { companies, loadingCompanies, totalData, limit, tableForm } = useContext(VendorContext);

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.REGISTRATION_ID")}</Th>
            <Th className="text-red-500">{t("LABEL.COMPANY_NAME")}</Th>
            <Th className="text-red-500">
              {t("LABEL.CITY")}, {t("LABEL.COUNTRY")}
            </Th>
            <Th className="text-red-500">{t("LABEL.VENDOR_STATUS")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingCompanies ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !companies.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            companies.map((company, iCompany) => <Row key={iCompany} data={company} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default VendorTable;
