import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Button as ButtonRsuite } from 'rsuite';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserAlt } from 'react-icons/fa';
import TitleBar from '../../../../../../../../../components/TitleBar';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { ProcurementDetailContext } from '../../../../Controller';
import { useObjectionContext } from '../Controller';
import moment from 'moment';

const Row = ({ objection, idx }) => {
	const { setActiveObjectionIdx } = useObjectionContext();

	return (
		<Tr className="hover:bg-gray-50">
			<Td>{objection?.procurementVendor?.company?.companyName}</Td>
			<Td>
				<div className="text-center font-semibold">{!objection?.procurementObjectionEvaluation ? 'DRAFT' : !objection?.procurementObjectionEvaluation?.isApproved ? 'REJECTED' : 'APPROVED'}</div>
			</Td>
			<Td
				style={{
					width: '100px',
				}}
			>
				<div>
					<Button colorScheme="teal" onClick={() => setActiveObjectionIdx(idx)} size="xs">
						Lihat Sanggahan
					</Button>
				</div>
			</Td>
		</Tr>
	);
};

const ObjectionTable = ({}) => {
	const { t } = useTranslation();
	const { isBuyer } = useContext(AuthContext);
	const { anyObjectionApproved, procurementSchedule } = useContext(ProcurementDetailContext);
	const { loadingObjections, objections, setIsCreate } = useObjectionContext();

	const isEnded = procurementSchedule?.objectionEnd && moment().unix() > moment(procurementSchedule?.objectionEnd).unix();
	const canSubmit = procurementSchedule?.objectionStart && moment().unix() > moment(procurementSchedule?.objectionStart).unix() && !isEnded;

	return (
		<div>
			<TitleBar
				icon={FaUserAlt}
				title="Daftar Sanggahan"
				rightElement={
					isBuyer && (
						<button
							disabled={anyObjectionApproved || !canSubmit}
							onClick={() => {
								setIsCreate(true);
							}}
							className={`text-white transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 px-5 rounded-md self-stretch font-semibold ${
								anyObjectionApproved || !canSubmit ? 'opacity-50' : ''
							}`}
						>
							+ Tambah
						</button>
					)
				}
			/>
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th className="text-red-500">Nama Penyedia</Th>
						<Th className="text-red-500 text-center">Status</Th>
						<Th className="text-red-500 text-center">{t('LABEL.ACTION')}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loadingObjections ? (
						<Tr>
							<Td colSpan={3}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					) : !objections?.length ? (
						<Tr>
							<Td colSpan={5} className="text-center">
								<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
							</Td>
						</Tr>
					) : (
						objections?.map((objection, idx) => {
							return <Row key={idx} idx={idx} objection={objection} />;
						})
					)}
				</Tbody>
			</Table>
		</div>
	);
};

export default ObjectionTable;
