import { FormControl, FormLabel } from "@chakra-ui/react";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import FormDescription from "../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../Controller";

const ProcurementForm = () => {
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        {/* Title */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.PROCUREMENT_TITLE")}
          </FormLabel>
          <FormDescription>{procurement?.title}</FormDescription>
        </FormControl>

        {/* Procurement Number */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.PROCUREMENT_NUMBER")}
          </FormLabel>
          <FormDescription>{procurement?.procurementNumber}</FormDescription>
        </FormControl>

        {/* Procurement Type */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.PROCUREMENT_TYPE")}
          </FormLabel>
          <FormDescription>{t(procurement?.procurementType?.labelI18n)}</FormDescription>
        </FormControl>

        {/* Bid Entry Method */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.BID_ENTRY_METHOD")}
          </FormLabel>
          <FormDescription>{t(procurement?.bidEntryMethod?.labelI18n)}</FormDescription>
        </FormControl>

        {/* Incoterm */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>{t("Incoterm")}</FormLabel>
          <FormDescription>
            {t(procurement?.incoterm?.incotermCode)} - {t(procurement?.incoterm?.description)}
          </FormDescription>
        </FormControl>
      </div>
    </motion.div>
  );
};

export default ProcurementForm;
