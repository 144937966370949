import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsUpload } from "react-icons/bs";

const Uploader = ({ label = "", isDisabled, onChange = () => "", ...props }) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);
  const [currentLabel, setCurrentLabel] = useState(label);
  useEffect(() => {
    setCurrentLabel(props?.fileList?.[0]?.name || label);
  }, [label, props?.fileList]);

  const handleInput = () => {
    inputRef.current.click();
  };

  const uploadLimit = 10_000_000; // 10 MB
  const handleFileChange = (e) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];

      if (file?.size > uploadLimit) {
        alert(t("TEXT.MAX_FILE_SIZE_ALERT", { value: "10 MB" }));
        return;
      }

      if (file?.type !== "application/pdf") {
        alert(t("TEXT.FILE_MUST_PDF"));
        return;
      }

      setCurrentLabel(file.name);
    } else {
      setCurrentLabel(label);
    }

    onChange(e.target.files?.length ? e.target.files : null);
  };

  return (
    <div>
      <button
        className={`default-chakra-input text-sm text-gray-600 flex justify-center items-center gap-2 ${props?.className}`}
        onClick={handleInput}
        disabled={isDisabled}
      >
        {currentLabel === label && <BsUpload className={`${isDisabled && "text-gray-300"}`} />}
        <span className={`${isDisabled && "text-gray-300"}`}>{currentLabel}</span>
      </button>
      <input type="file" className="hidden" ref={inputRef} onChange={handleFileChange} accept=".pdf" />
    </div>
  );
};

export default Uploader;
