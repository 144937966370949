import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ActivityList from "./components/ActivityList";
import ActivityController, { ActivityContext } from "./Controller";
import { userRouteSlug } from "../../../routes/userRoutes";
import { BiCaretLeftCircle } from "react-icons/bi";
import { AuthContext } from "../../../controllers/auth/AuthController";

const Activity = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const { regCode } = useContext(ActivityContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        {userData?.type !== "user" && (
          <Link to={userRouteSlug.PANEL + `/vendor/${regCode}`}>
            <BiCaretLeftCircle className="text-xl text-gray-500 hover:text-gray-700" />
          </Link>
        )}
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.ACTIVITY")}</h2>
      </div>

      <ActivityList />
    </div>
  );
};

export default () => (
  <ActivityController>
    <Activity />
  </ActivityController>
);
