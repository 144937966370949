import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AdminApprovalContext } from "./Controller";

const ModalApprove = () => {
  const { t } = useTranslation();
  const { isModalApproveOpen, setModalApproveOpen, loadingApprove, submitApprove } = useContext(AdminApprovalContext);

  return (
    <Modal onClose={() => setModalApproveOpen(false)} isOpen={isModalApproveOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>
          {t("LABEL.APPROVE")} {t("LABEL.COMPANY_PROFILE")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>{t("TEXT.CONFIRM_APPROVE", { field: t("LABEL.COMPANY_PROFILE") })}</p>
        </ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => setModalApproveOpen(false)}>{t("LABEL.CANCEL")}</Button>
          <Button isLoading={loadingApprove} onClick={() => submitApprove()} colorScheme="teal">
            {t("LABEL.APPROVE")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalApprove;
