import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NegotiationContext } from "../../Controller";

const ModalReject = () => {
  const { t } = useTranslation();
  const {
    handleUpdateRejectBidOpen,
    isBidRejectOpenUpdate,
    loadingBidRejectUpdate,
    updateStatusRejectBid,
  } = useContext(NegotiationContext);

  return (
    <Modal
      onClose={() => handleUpdateRejectBidOpen(false)}
      size="md"
      isOpen={isBidRejectOpenUpdate}
    >
      <ModalOverlay />
      <ModalContent className="bg-red-500 text-white bg-opacity-80">
        <ModalHeader>{t("LABEL.PROCUREMENT_NEGOTIATION_REJECT")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.REJECT_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button
            onClick={() => handleUpdateRejectBidOpen(false)}
            colorScheme="whiteAlpha"
          >
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingBidRejectUpdate}
            onClick={updateStatusRejectBid}
            className="transition-all duration-500"
            colorScheme="blackAlpha"
          >
            {t("LABEL.YES", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalReject;
