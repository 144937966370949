import numeral from 'numeral';
import { Table, Thead, Tr, Th, Tbody, Td, Spinner } from '@chakra-ui/react';
import { useContext } from 'react';
import { ProcurementOfferContext } from '../../Controller';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../../../../../../../../../components/TitleBar';

const AdministratifItemTable = () => {
  const {t} = useTranslation();
	const { materials, loadingMaterials } = useContext(ProcurementOfferContext);

	return (
		<div>
			<TitleBar className="mt-4" title={t("LABEL.PROCUREMENT_ITEM")} />
			<Table>
				<Thead>
					<Tr>
						<Th className="text-red-500" style={{ maxWidth: '50px' }}>
							No.
						</Th>
						<Th className="text-red-500">{t("LABEL.DESCRIPTION")}</Th>
						<Th className="text-red-500" style={{ maxWidth: '100px' }}>
							{t("LABEL.QUANTITY")}
						</Th>
						<Th className="text-red-500">UoM</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loadingMaterials && (
						<Tr>
							<Td colSpan={4}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					)}

					{!loadingMaterials &&
						materials &&
						materials.map((mat, idx) => {
							return (
								<Tr key={idx}>
									<Td style={{ maxWidth: '50px' }}>{idx + 1}</Td>
									<Td>{mat.description}</Td>
									<Td style={{ maxWidth: '100px' }}>{numeral(+mat.qty).format('0,0')}</Td>
									<Td>
										{mat.unit} {mat.unitDescription !== '' ? ` (${mat.unitDescription})` : ''}
									</Td>
								</Tr>
							);
						})}
				</Tbody>
			</Table>
		</div>
	);
};

export default AdministratifItemTable;
