import { Spinner } from '@chakra-ui/react';
import { useContext } from 'react';
import CommercialForm from './CommercialForm';
import CommercialItemTable from './CommercialItemTable';
import CommercialRequirementTable from './CommercialRequirementTable';
import CommercialAction from './CommercialAction';
import CommercialController, { CommercialContext } from './Controller';
import { ProcurementOfferContext } from '../../Controller';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../utils/offerPhases.util';
import { useTranslation } from 'react-i18next';

const OfferCommercialPage = () => {
	const { t } = useTranslation();
	const { commercialOffer, isNewCommercialOffer, loadingCommercialOffer, offerPhase } = useContext(ProcurementOfferContext);
	const { isEdit } = useContext(CommercialContext);

	return (
		<div>
			{loadingCommercialOffer && commercialOffer === undefined && (
				<div className="flex justify-center items-center" style={{ minHeight: '300px' }}>
					<Spinner color="red.500" width={10} height={10} />
				</div>
			)}
			{!loadingCommercialOffer && (
				<>
					{offerPhase === OFFER_2_COVER_PHASES.POST && !commercialOffer ? (
						<>
							<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-4">
								<p className="text-gray-700 text-base flex items-center font-semibold text-center justify-center">{t('TEXT.OFFER_NOT_SUBMITTED')}</p>
							</div>
						</>
					) : (
						<>
							<CommercialForm isEdit={isEdit} isNewCommercialOffer={isNewCommercialOffer} commercialOffer={commercialOffer} />
							<CommercialRequirementTable />
							<CommercialItemTable />
							<CommercialAction />
						</>
					)}
				</>
			)}
		</div>
	);
};

export default () => (
	<CommercialController>
		<OfferCommercialPage />
	</CommercialController>
);
