import { Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BaseCard from "../templates/public/BaseCard";

const Page404 = ({ buttonRedirectTo = "/" }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{ height: "80vh" }}
      className="md:h-full flex flex-col justify-center items-center gap-4 px-10 md:p-0 text-center md:text-left"
    >
      <h1 className="montserrat font-bold text-7xl md:text-8xl text-transparent bg-clip-text bg-gradient-primary">
        404
      </h1>
      <p className="font-medium text-gray-700">{t("TEXT.NOT_FOUND")}</p>
      <Link to={buttonRedirectTo}>
        <Button className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 text-white">
          {t("TEXT.BACK_TO_DASHBOARD")}
        </Button>
      </Link>
    </div>
  );
};

export const Page404BaseCard = ({ buttonRedirectTo }) => (
  <BaseCard>
    <Page404 buttonRedirectTo={buttonRedirectTo} />
  </BaseCard>
);

export default Page404;
