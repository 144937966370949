import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../controllers/auth/AuthController";

export const AccountContext = createContext();
const { Provider } = AccountContext;

const AccountController = ({ children }) => {
  const toast = useToast();
  const { token, userData } = useContext(AuthContext);
  const { t } = useTranslation();

  // -------------------------------
  // Update User
  // -------------------------------
  const [isEdit, setEdit] = useState(false);
  const updateForm = useForm();
  const resetUpdateForm = (newForm) => {
    updateForm.reset({
      username: newForm?.userAccount?.username,
      name: newForm?.name,
      email: newForm?.email,
    });
  };

  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);
  const updateUser = () => {
    setLoadingUpdateUser(true);

    axios
      .patch(`user/${userData?.company?.registrationCode}/${user?.userId}`, updateForm?.getValues(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadUser(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdateUser(false);
      });
  };

  // -------------------------------
  // User
  // -------------------------------

  const [user, setUser] = useState();
  const [loadingUser, setLoadingUser] = useState(false);
  const [reloadUser, setReloadUser] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getUser = () => {
      setLoadingUser(true);

      axios
        .get(`user/${userData?.company?.registrationCode}/${userData?.userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newUser = res?.data?.user;
            setUser(newUser);
            resetUpdateForm(newUser);
          }
        })
        .finally(() => {
          setReloadUser(false);
          setLoadingUser(false);
        });
    };

    token && userData && reloadUser && getUser();

    return () => {
      mounted = false;
    };
  }, [token, userData, reloadUser]);

  // Email Availability
  const email = updateForm.watch("email");
  const [isEmailAvailable, setEmailAvailable] = useState();
  const [loadingEmailAvailable, setLoadingEmailAvailable] = useState(false);
  const [emailAvailableTimeout, setEmailAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkEmail = () => {
      setLoadingEmailAvailable(true);

      axios
        .post("/user/check-email", {
          email,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setEmailAvailable(email === user?.email || available);
        })
        .finally(() => {
          setLoadingEmailAvailable(false);
          updateForm.trigger("email");
        });
    };

    clearTimeout(emailAvailableTimeout);
    const newTimeout = setTimeout(() => {
      email ? checkEmail() : setEmailAvailable();
    }, 500);
    setEmailAvailableTimeout(newTimeout);

    return () => {
      mounted = false;
    };
  }, [email]);

  // -------------------------------
  // Change Password
  // -------------------------------
  const [isModalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const submitChangePassword = (currentPassword, newPassword) => {
    setLoadingChangePassword(true);

    axios
      .patch(
        `user/change-password/${userData?.company?.registrationCode}/${userData?.userAccountId}`,
        {
          currentPassword,
          newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setReloadUser(true);
        setModalChangePasswordOpen(false);
      })
      .catch((err) => {
        const res = err?.response?.data;

        toast({
          title: "System Error",
          description: t(`ERROR.${res?.message}`),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingChangePassword(false);
      });
  };

  return (
    <Provider
      value={{
        isEdit,
        setEdit,
        user,
        loadingUser,
        updateForm,
        resetUpdateForm,
        isEmailAvailable,
        loadingEmailAvailable,

        loadingUpdateUser,
        updateUser,

        isModalChangePasswordOpen,
        setModalChangePasswordOpen,
        loadingChangePassword,
        submitChangePassword,
      }}
    >
      {children}
    </Provider>
  );
};

export default AccountController;
