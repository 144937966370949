import { FormControl, FormLabel, Spinner } from '@chakra-ui/react';
import moment from 'moment';
import numeral from 'numeral';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FileAttachment from '../../../../../../../../../../../../components/FileAttachment';
import FormDescription from '../../../../../../../../../../../../components/inputForm/FormDescription';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { CommercialEvaluationContext } from '../Controller';

const OfferFormEvaluation = () => {
  const {t} = useTranslation();
	const { offer, loadingOffer } = useContext(CommercialEvaluationContext);

	const fieldName = {
		offerNumber: t("LABEL.OFFER_NOTE"),
		offerDate: t("LABEL.OFFER_DATE"),
		offerExpired: t("LABEL.OFFER_EXPIRED"),
		offerLetter: t("LABEL.OFFER_LETTER"),
		offerNote: t("LABEL.OFFER_NOTE"),
		offerIncoterm: t("LABEL.OFFER_INCOTERM"),
		offerIncotermDescription: t("LABEL.OFFER_INCOTERM_DESCRIPTION"),
		offerGuaranteeValue: t("LABEL.OFFER_GUARANTEE_VALUE"),
		offerGuaranteeFile: t("LABEL.OFFER_GUARANTEE_FILE"),
		offerGuaranteeExpired: t("LABEL.OFFER_GUARANTEE_EXPIRED"),
	};

	return (
		<div>
			<TitleBar title={t("LABEL.CONCEPT")} />
			{(loadingOffer || !offer) && (
				<div className="flex justify-center items-center" style={{ minHeight: '25vh' }}>
					<Spinner color="red.500" />
				</div>
			)}
			{!loadingOffer && offer && (
				<div className="flex justify-between gap-5">
					<div className="w-1/2 flex flex-col gap-5">
						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerNumber}</FormLabel>

							<FormDescription>{offer?.offerNumber}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerDate}</FormLabel>

							<FormDescription>{moment(new Date(offer?.startAt)).format('yyyy-MM-DD')}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerExpired}</FormLabel>

							<FormDescription>{moment(new Date(offer?.expiredAt)).format('yyyy-MM-DD')}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerLetter}</FormLabel>

							<FormDescription>
                <FileAttachment href={`${process.env.REACT_APP_FILESERVICE_API}/files/users/${offer?.letterFilename}`} />
							</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerNote}</FormLabel>

							<FormDescription>{offer?.note}</FormDescription>
						</FormControl>
					</div>
					<div className="w-1/2 flex flex-col gap-5">
						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerGuaranteeValue}</FormLabel>

							<FormDescription>{offer?.guaranteeValue === null ? t("LABEL.EMPTY") : numeral(+offer?.guaranteeValue).format('0,0.00')}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerGuaranteeFile}</FormLabel>

							<FormDescription>
								{offer?.guaranteeFilename !== null ? (
                  <FileAttachment href={`${process.env.REACT_APP_FILESERVICE_API}/files/users/${offer?.guaranteeFilename}`} />
								) : (
									<>{t("LABEL.EMPTY")}</>
								)}
							</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerGuaranteeExpired}</FormLabel>

							<FormDescription>{offer?.guaranteeExpiredAt !== null ? moment(new Date(offer?.guaranteeExpiredAt)).format('yyyy-MM-DD') : <>{t("LABEL.EMPTY")}</>}</FormDescription>
						</FormControl>

            <FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerIncoterm}</FormLabel>

							<FormDescription>{offer?.incotermId}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerIncotermDescription}</FormLabel>

							<FormDescription>{offer.incotermDescription}</FormDescription>
						</FormControl>
					</div>
				</div>
			)}
		</div>
	);
};

export default OfferFormEvaluation;
