import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { BiPlus } from "react-icons/bi";
import { BsFillCaretRightFill } from "react-icons/bs";
import moment from "moment";
import { AuctionContext } from "../Controller";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../../../../../../../../routes/userRoutes";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import NextStepButton from "../../../NextStepButton";
import { ProcurementDetailContext } from "../../../../Controller";

const Row = ({ data }) => {
  const navigate = useNavigate();
  const auctionPath = userRouteSlug.PANEL + userRouteSlug.AUCTION + `/${data?.procurementAuctionId}` + `/general`;

  return (
    <Tr className="hover:bg-gray-50 cursor-pointer" onClick={() => navigate(auctionPath)}>
      <Td>{data?.title}</Td>
      <Td>{moment(data?.timeStart).format("DD/MM/YYYY")}</Td>
      <Td>{moment(data?.timeEnd).format("DD/MM/YYYY")}</Td>
      <Td>
        <ul className="ml-4" style={{ listStyle: "disc" }}>
          {data?.auctionMaterials?.map((material, iMaterial) => (
            <li key={iMaterial}>{material?.reqRequisitionMaterial?.material?.description}</li>
          ))}
        </ul>
      </Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const AuctionTable = ({ auctions, loadingAuctions, totalData, limit, tableForm, isPublic = false }) => {
  const {procurement} = useContext(ProcurementDetailContext);
  const { handleCreate } = useContext(AuctionContext);
  const { userData } = useContext(AuthContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  const canNext = auctions?.length > 0 && !(auctions?.find(auction => {
    const now = moment().unix();
    const end = moment(auction?.timeEnd).unix();
    if (now < end) return true;
    return false;
  }));

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-end items-center">
        {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner && (
          <PrivateComponent accessName="BUYER_EDIT">
            <Button size="sm" onClick={() => handleCreate(true, isPublic)}>
              <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: t("LABEL.AUCTION") })}
            </Button>
          </PrivateComponent>
        )}
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.AUCTION_TITLE")}</Th>
            <Th className="text-red-500">{t("LABEL.AUCTION_START")}</Th>
            <Th className="text-red-500">{t("LABEL.AUCTION_END")}</Th>
            <Th className="text-red-500">{t("Item Material")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingAuctions ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !auctions.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            auctions.map((auction, iAuction) => <Row key={iAuction} data={auction} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
      <NextStepButton isDisabled={!canNext} to="negotiation" url="negotiation" className='mt-4' toLabel={`Negosiasi`} />
    </div>
  );
};

export default AuctionTable;
