import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CompanyOverview from "../../../../../../components/CompanyOverview";
import { userRouteSlug } from "../../../../../../routes/userRoutes";
import VendorMenu from "./components/VendorMenu";
import VendorOverviewController, { VendoroverviewContext } from "./Controller";
import { BiCaretLeftCircle } from "react-icons/bi";

const VendorOverview = () => {
  const { t } = useTranslation();
  const { loadingCompanyOverview, companyOverview } = useContext(VendoroverviewContext);

  return (
    <div className="flex grid md:grid-cols-3 gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <Link to={userRouteSlug.PANEL + `/vendor`}>
          <BiCaretLeftCircle className="text-xl text-gray-500 hover:text-gray-700" />
        </Link>
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.VENDOR")}</h2>
      </div>

      <div className="md:col-span-3">
        <CompanyOverview loadingCompanyOverview={loadingCompanyOverview} companyOverview={companyOverview} />
      </div>

      <VendorMenu />
    </div>
  );
};

export default () => (
  <VendorOverviewController>
    <VendorOverview />
  </VendorOverviewController>
);
