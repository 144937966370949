import { Route, Routes } from "react-router";
import PublicTemplate from "../pages/templates/public";
import Home from "../pages/public/Home";
import Login from "../pages/public/Login";
import Register from "../pages/public/Register";
import Activation from "../pages/public/Activation";
import { Page404BaseCard } from "../pages/misc/Page404";
import ForgetPassword from "../pages/public/ForgetPassword";

export const publicRouteSlug = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  ACTIVATION: "/activate",
  FORGET_PASSWORD: "/forget",
};

const publicRoutes = [
  { path: publicRouteSlug.LOGIN, element: <Login /> },
  { path: publicRouteSlug.REGISTER, element: <Register /> },
  { path: publicRouteSlug.ACTIVATION, element: <Activation /> },
  { path: publicRouteSlug.FORGET_PASSWORD, element: <ForgetPassword /> },
  {
    element: <PublicTemplate />,
    children: [{ path: publicRouteSlug.HOME, element: <Home /> }],
  },
  { path: "*", element: <Page404BaseCard buttonRedirectTo={publicRouteSlug.HOME} /> },
];

export default publicRoutes;
