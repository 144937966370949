import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ShareholderContext } from "../Controller";
import { IoIosArrowForward } from "react-icons/io";
import _ from "lodash";
import { motion } from "framer-motion";
import { BiPlus, BiPencil } from "react-icons/bi";
import PrivateComponent from "../../../../../../../components/PrivateComponent";
import { Spinner } from "@chakra-ui/react";
import SharePercentage from "./SharePercentage";
import SubmitApprovalWarning from "../../../SubmitApprovalWarning";

const Shareholder = ({ data }) => {
  const { t } = useTranslation();
  const { handleDetailMenu } = useContext(ShareholderContext);

  const isMain = data?.isOwner;

  return (
    <button
      className={`text-left p-4 flex justify-between items-center ${
        isMain ? "bg-gradient-primary text-white" : "bg-white text-gray-600"
      }`}
      style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
      onClick={() => handleDetailMenu(true, data)}
    >
      <div className={`${data?.isDelete && "opacity-60"}`}>
        <p className={`text-sm font-semibold text-red-500`}>
          {data?.sharePercentage}%{data?.isDelete && ` (${t("LABEL.DELETED")})`}
        </p>
        <h2 className="font-semibold text-lg">{data?.shareholderName}</h2>
        {(!data?.isApproved || data?.isDelete) && (
          <div className={`flex items-center gap-1 mt-2 text-xs text-blue-500`}>
            <BiPencil />
            <p>{t("TEXT.DATA_NEED_APPROVAL")}</p>
          </div>
        )}
      </div>
      <div>
        <IoIosArrowForward />
      </div>
    </button>
  );
};

const ShareholderList = () => {
  const { loadingShareholders, handleCreate, shareholders } = useContext(ShareholderContext);
  const { t } = useTranslation();
  const activeShareholders = shareholders?.filter((shareholder) => !shareholder?.isDelete);

  const totalSharePercentage = activeShareholders?.length
    ? activeShareholders?.map((shareholder) => shareholder?.sharePercentage).reduce((a, b) => a + b)
    : 0;

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="grid md:grid-cols-2 gap-4">
      <SubmitApprovalWarning menu="Shareholder" className="md:col-span-2" />
      {/* <SharePercentage shareholders={activeShareholders} /> */}

      {loadingShareholders ? (
        <div
          className={`h-20 md:col-span-2 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
          style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {!shareholders?.length ? (
            <PrivateComponent accessName="CREATE_COMPANY_PROFILE" doHasAccess={false}>
              <div
                className="md:col-span-2 p-3 bg-white bg-opacity-40 text-center text-sm text-gray-500"
                style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
              >
                {t("TEXT.DATA_NOT_FOUND")}
              </div>
            </PrivateComponent>
          ) : (
            shareholders.map((shareholder, iShareholder) => <Shareholder key={iShareholder} data={shareholder} />)
          )}
          <PrivateComponent accessName="CREATE_COMPANY_PROFILE">
            {totalSharePercentage < 100 && (
              <button
                className={`h-12 md:h-20 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
                style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
                onClick={() => handleCreate(true)}
              >
                <BiPlus />
                <p>{t("LABEL.ADD", { field: t("LABEL.SHAREHOLDER") })}</p>
              </button>
            )}
          </PrivateComponent>
        </>
      )}
    </motion.div>
  );
};

export default ShareholderList;
