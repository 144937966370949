import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { ProcScheduleContext } from "../Controller";
import _ from "lodash";
import { ProcurementDetailContext } from "../../../../Controller";
import RegistrationForm from "./StepTender/RegistrationForm";
import PrequalificationForm from "./StepTender/PrequalificationForm";
import DocumentDownloadForm from "./StepTender/DocumentDownloadForm";
import AanwijzingForm from "./StepTender/AanwijzingForm";
import BidOpeningForm from "./StepTender/BidOpeningForm";
import BidEvaluationForm from "./StepTender/BidEvaluationForm";
import ClarificationForm from "./StepTender/ClarificationForm";
import AuctionForm from "./StepTender/AuctionForm";
import QuotationForm from "./StepTender/QuotationForm";
import VendorWinnerLetterForm from "./StepTender/VendorWinnerLetterForm";
import WinnerForm from "./StepTender/WinnerForm";
import ObejctionForm from "./StepTender/ObjectionForm";
import WinnerConfirmationForm from "./StepTender/WinnerConfirmationForm";
import PoMakingForm from "./StepTender/PoMakingForm";
import SignContractForm from "./StepTender/SignContractForm";
import SignTimeForm from "./StepTender/SignTimeForm";
import CommercialQuotationForm from "./StepTender/CommercialQuotationForm";
import TechnicalQuotationForm from "./StepTender/TechnicalQuotationForm";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";

const Form = (props) => {
  const { t } = useTranslation();
  const { form } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <RegistrationForm isEdit={props.isEdit} />
      <PrequalificationForm isEdit={props.isEditPrequalification} />
      <DocumentDownloadForm isEdit={props.isEdit} />
      <AanwijzingForm isEdit={props.isEditAanwijzing} />
      {procurement?.bidEntryMethod?.bidEntryMethod === "2 STEP" ? (
        <>
          <TechnicalQuotationForm isEdit={props.isEditTechnicalQuotation} />
          <CommercialQuotationForm isEdit={props.isEditCommercialQuotation} />
        </>
      ) : procurement?.bidEntryMethod?.bidEntryMethod === "2 COVER" ? (
        <>
          {" "}
          <QuotationForm isEdit={props.isEditQuotation} />
        </>
      ) : (
        ""
      )}
      <BidOpeningForm isEdit={props.isEdit} />
      <BidEvaluationForm isEdit={props.isEdit} />
      <ClarificationForm isEdit={props.isEdit} />
      <AuctionForm isEdit={props.isEdit} />
      <VendorWinnerLetterForm isEdit={props.isEdit} />
      <WinnerForm isEdit={props.isEdit} />
      <ObejctionForm isEdit={props.isEdit} />
      {userData?.type !== "user" ? (
        <>
          <WinnerConfirmationForm isEdit={props.isEdit} />
          <PoMakingForm isEdit={props.isEdit} />
          <SignContractForm isEdit={props.isEditSign} />
          <SignTimeForm isEdit={props.isEdit} />{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Form;
