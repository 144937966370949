import { ObjectionController, useObjectionContext } from './Controller';
import { motion } from 'framer-motion';
import TitleBar from '../../../../../../../../components/TitleBar';
import ObjectionSchedule from './components/ObjectionSchedule';
import ObjectionTable from './components/ObjectionTable';
import ObjectionNew from './components/ObjectionNew';
import { useContext } from 'react';
import { ProcurementDetailContext } from '../../../Controller';
import ObjectionDetail from './components/ObjectionDetail';

const Objection = () => {
	const { isCreate, activeObjectionIdx } = useObjectionContext();

	return (
		<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
			{isCreate ? (
				<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
					<div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
						<ObjectionNew />
					</div>
				</motion.div>
			) : activeObjectionIdx !== null ? (
				<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
					<div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
						<ObjectionDetail />
					</div>
				</motion.div>
			) : (
				<div className="flex flex-col gap-6 bg-white p-4 rounded-md">
					<ObjectionSchedule />
					<ObjectionTable />
				</div>
			)}
		</motion.div>
	);
};

export default () => {
	const { procurement, procurementType } = useContext(ProcurementDetailContext);

	if (!procurement?.procurementWinnerProposal?.isWinner || !(procurementType === 'GENERAL' || procurementType === 'LIMITED')) {
		return <div></div>;
	}

	return (
		<ObjectionController>
			<Objection />
		</ObjectionController>
	);
};
