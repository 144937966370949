import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import * as BiIcon from "react-icons/bi";
import { Link, useLocation, useParams, matchPath } from "react-router-dom";
import { userRouteSlug } from "../../../../routes/userRoutes";
import { UserTemplateContext } from "../Controller";
import { useTranslation } from "react-i18next";
import { getPurePathname } from "../../../../utils/string.util";

const SidebarItem = ({ to = "#", labelI18n = "", label = "", Icon = () => "", items = [] }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const pathname = getPurePathname(location, params);

  const [showItems, setShowItems] = useState(false);
  const isActive = matchPath(pathname, userRouteSlug.PANEL + to);

  return (
    <div className={`${isActive ? "text-red-500" : "text-gray-700"} `}>
      <Link
        to={!!to ? userRouteSlug.PANEL + to?.replace("/[param]", "") : "#"}
        className="my-2 flex justify-between items-center"
        onClick={() => items.length && setShowItems(!showItems)}
      >
        <div className="flex items-center gap-4">
          <Icon className="text-lg" />
          <span className="font-semibold">{labelI18n ? t(labelI18n) : label}</span>
        </div>
        <AiFillCaretDown className={`${!items.length && "hidden"} text-sm transform ${showItems && "rotate-180"}`} />
      </Link>

      <AnimatePresence>
        {showItems && items.length && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className={`ml-4 flex flex-col overflow-hidden`}
          >
            {items.map((item, iItem) => (
              <SidebarItem
                key={iItem}
                Icon={BiIcon[item?.icon]}
                label={item?.name}
                labelI18n={item?.nameI18n}
                to={item?.path}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Sidebar = () => {
  const { menu } = useContext(UserTemplateContext);

  return (
    <div className="h-screen flex flex-col p-8 px-12 gap-8">
      <div className="montserrat text-3xl font-bold">
        <span className="bg-clip-text text-transparent bg-gradient-primary">Eproc</span>
        <span className="mx-1 text-gray-500 text-xs font-semibold uppercase tracking-widest">Panel</span>
      </div>
      <div className="flex-1 overflow-auto flex flex-col">
        {menu
          .filter((m) => !m?.parent)
          .map(
            (item, iItem) =>
              item?.isShowMenu && (
                <SidebarItem
                  key={iItem}
                  Icon={BiIcon[item?.icon]}
                  label={item?.name}
                  labelI18n={item?.nameI18n}
                  to={item?.path}
                  items={menu?.filter((m) => m?.parent === item?.name)}
                />
              )
          )}
      </div>
    </div>
  );
};

export default Sidebar;
