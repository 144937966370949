import React, { useContext, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MaterialContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../components/inputForm/FormDescription";
import Select from "../../../../../components/inputForm/Select";
import { useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import { BiCheckDouble, BiError } from "react-icons/bi";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    activeMaterial,
    form,
    materialGroups,
    measureUnits,
    setIsValidMaterialCode,
    isValidMaterialCode,
    loadingValidationMaterialCode,
    setLoadingValidationMaterialCode,
  } = useContext(MaterialContext);
  const { token } = useContext(AuthContext);

  const {
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = form;

  const materialCode = form.watch("materialCode");

  useEffect(() => {
    if (!isEdit) return;

    if (!materialCode) {
      setIsValidMaterialCode(true);
    } else {
      if (materialCode == "") {
        setIsValidMaterialCode(true);
        return;
      }
      setLoadingValidationMaterialCode(true);
      axios
        .post(`${process.env.REACT_APP_TMS_API}/material/is-exist-code/${materialCode}`, "", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const mat = res.data.material;
          if (!mat) {
            setIsValidMaterialCode(true);
            return;
          }

          if (!activeMaterial) {
            setIsValidMaterialCode(false);
            return;
          }

          if (activeMaterial.materialId == mat.materialId) {
            setIsValidMaterialCode(true);
            return;
          }

          setIsValidMaterialCode(false);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          setIsValidMaterialCode(false);
        })
        .finally(() => {
          setLoadingValidationMaterialCode(false);
        });
    }
  }, [materialCode, isEdit]);

  useEffect(() => {
    if (!isValidMaterialCode && isEdit) {
      setError("materialCode", {
        type: "custom",
        message: t("INVALID.AVAILABLE", { field: t("LABEL.MATERIAL_CODE") }),
      });
      return;
    }

    clearErrors("materialCode");
  }, [isValidMaterialCode, isEdit]);

  return (
    <>
      {/* Material Group */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.MATERIAL_GROUP")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.MATERIAL_GROUP") }) },
            }}
            name="materialGroup"
            options={materialGroups.map((materialGroup) => ({
              label: `${materialGroup?.materialGroup} - ${materialGroup?.description}`,
              value: materialGroup?.materialGroup,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.MATERIAL_GROUP")}`}
            isError={!!errors?.materialGroup}
          />
        ) : (
          <FormDescription>
            {activeMaterial?.materialGroup?.materialGroup} - {activeMaterial?.materialGroup?.description}
          </FormDescription>
        )}

        {errors?.materialGroup && (
          <FormHelperText className="text-red-400 text-xs">{errors?.materialGroup?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Material Code */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.MATERIAL_CODE")}
        </FormLabel>
        {isEdit ? (
          <InputGroup>
            <InputText
              className={`w-full text-sm ${errors?.materialCode && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.MATERIAL_CODE")}
              {...register("materialCode", { required: t("INVALID.REQUIRED", { field: t("LABEL.MATERIAL_CODE") }) })}
              disabled
            />
            <InputRightElement className="h-full">
              {!materialCode || materialCode === "" ? (
                <></>
              ) : (
                <>
                  {loadingValidationMaterialCode ? (
                    <Spinner color="red.500" />
                  ) : (
                    <>
                      {isValidMaterialCode ? (
                        <BiCheckDouble color="green" className="text-2xl" />
                      ) : (
                        <BiError color="red" className="text-2xl" />
                      )}
                    </>
                  )}
                </>
              )}
            </InputRightElement>
          </InputGroup>
        ) : (
          <FormDescription>{activeMaterial?.materialCode}</FormDescription>
        )}
        {errors?.materialCode && (
          <FormHelperText className="text-red-400 text-xs">{errors?.materialCode?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Description */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DESCRIPTION")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.description && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DESCRIPTION")}
            {...register("description", { required: t("INVALID.REQUIRED", { field: t("LABEL.DESCRIPTION") }) })}
          />
        ) : (
          <FormDescription>{activeMaterial?.description}</FormDescription>
        )}
        {errors?.description && (
          <FormHelperText className="text-red-400 text-xs">{errors?.description?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Measure Unit */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.MEASURE_UNIT")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.MEASURE_UNIT") }) },
            }}
            name="measureUnit"
            options={measureUnits.map((measureUnit) => ({
              label: `${measureUnit.unit} ${measureUnit.description ? `(${measureUnit.description})` : ""}`,
              value: measureUnit?.unit,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.MEASURE_UNIT")}`}
            isError={!!errors?.measureUnit}
          />
        ) : (
          <FormDescription>
            {activeMaterial?.measureUnit?.description} ({activeMaterial?.measureUnit?.unit})
          </FormDescription>
        )}

        {errors?.measureUnit && (
          <FormHelperText className="text-red-400 text-xs">{errors?.measureUnit?.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>Status</FormLabel>
        <FormDescription>
          {!activeMaterial?.requisitionMaterial ? t("LABEL.AVAILABLE") : t("LABEL.UNAVAILABLE")}
        </FormDescription>
      </FormControl>
    </>
  );
};

export default Form;
