import { Button, FormControl, FormHelperText, FormLabel, Textarea } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDownload } from 'react-icons/bi';
import FileAttachment from '../../../../../../../../../components/FileAttachment';
import FormDescription from '../../../../../../../../../components/inputForm/FormDescription';
import Select from '../../../../../../../../../components/inputForm/Select';
import Uploader from '../../../../../../../../../components/inputForm/Uploader';
import { FileserviceContext } from '../../../../../../../../../controllers/FileserviceController';
import { API_URLS } from '../../../../../../../../../utils/apiURLs.util';
import { useObjectionContext } from '../Controller';

const Form = ({ isEdit }) => {
	const { t } = useTranslation();
	const { downloadFile } = useContext(FileserviceContext);
	const { vendors, form, activeObjectionIdx, objections } = useObjectionContext();
	const {
		control,
		register,
		getValues,
		setValue,
		formState: { errors },
	} = form;

	register('attachment', { required: 'Lampiran wajib diisi' });

	const activeObjection = objections?.[activeObjectionIdx];

	return (
		<>
			<FormControl isRequired={isEdit} className="md:col-span-1">
				<FormLabel className={`uppercase font-semibold text-xs ${!isEdit && 'text-red-500'}`}>Penyanggah</FormLabel>
				{isEdit ? (
					<>
						<Select
							filter
							control={control}
							controlProps={{
								rules: {
									required: t('INVALID.REQUIRED', {
										field: 'Penyanggah',
									}),
								},
							}}
							name="objector"
							options={vendors?.map((vendor) => {
								return {
									label: vendor?.company?.companyName || vendor?.company?.registrationCode || 'Unknown',
									value: vendor?.company?.registrationCode,
								};
							})}
							placeholder={`${t('LABEL.CHOOSE')} Penyanggah`}
							isError={!!errors?.objector}
						/>
						{errors?.objector && <FormHelperText className="text-red-400 text-xs">{errors.objector.message}</FormHelperText>}
					</>
				) : (
					<FormDescription>{activeObjection?.procurementVendor?.company?.companyName}</FormDescription>
				)}
			</FormControl>

			<FormControl isRequired={isEdit} className="md:col-span-1">
				<FormLabel className={`uppercase font-semibold text-xs ${!isEdit && 'text-red-500'}`}>Lampiran</FormLabel>
				{isEdit ? (
					<>
						<Uploader
							className={`${errors?.attachment && 'border-2 border-red-400 shadow-none'}`}
							label="Upload Lampiran"
							name={'attachment'}
							fileList={getValues('attachment')}
							onChange={(files) => {
								setValue('attachment', files);
							}}
						/>
						{errors.attachment && <FormHelperText className="text-red-400 text-xs">{errors.attachment.message}</FormHelperText>}
					</>
				) : (
					<FormDescription>
						<Button className="px-10" size="sm" onClick={() => downloadFile(activeObjection?.attachmentFilename)}>
							<BiDownload className="mr-2" />
							{t('LABEL.DOWNLOAD')}
						</Button>
					</FormDescription>
				)}
			</FormControl>

			<FormControl isRequired={isEdit} className="md:col-span-1">
				<FormLabel className={`uppercase font-semibold text-xs ${!isEdit && 'text-red-500'}`}>Deskripsi</FormLabel>
				{isEdit ? (
					<>
						<Textarea
							name="offerIncotermDescription"
							className={`w-full text-sm ${errors.description && 'border-2 border-red-400 shadow-none'}`}
							placeholder={'Deskripsi'}
							required={true}
							{...register('description', { required: `${'deskripsi'} wajib diisi` })}
						/>

						{errors.description && <FormHelperText className="text-red-400 text-xs">{errors.description.message}</FormHelperText>}
					</>
				) : (
					<FormDescription>{activeObjection?.description}</FormDescription>
				)}
			</FormControl>
		</>
	);
};

export default Form;
