export const OFFER_2_COVER_PHASES = {
	PRA: 0,
	TIME: 4,
	POST: 10,
};

export const OFFER_2_STEP_PHASES = {
	PRA: 0,
	TECHNICAL: 2,
	COMMERCIAL: 3,
	POST: 10,
};
