import React, { useContext } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TaxContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import { CompanyProfileContext } from "../../../../Controller";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeTax, form, taxTypes, taxes } = useContext(TaxContext);
  const { companyOverview } = useContext( CompanyProfileContext )
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  // register("attachment", {
  //   required: {
  //     value: isCreate,
  //     message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") }) }),
  //   },
  // });

  return (
    <>
      {/* Tax Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.TAX_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.TAX_TYPE") }) },
            }}
            name="taxType"
            options={taxTypes.map((taxType) => ({
              label: `(${taxType?.taxType}) - ${t(taxType?.labelI18n || taxType?.label)}`,
              value: taxType?.taxType,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.TAX_TYPE")}`}
            isError={!!errors?.taxType}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {activeTax?.taxType?.labelI18n ? t(activeTax?.taxType?.labelI18n) : activeTax?.taxType?.label}
          </FormDescription>
        )}

        {errors?.taxType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.taxType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Tax Number */}
      {companyOverview?.country !== "Indonesia" ? 
         <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
            Tax ID
          </FormLabel>
          {isEdit ? (
            <InputText
              className={`w-full text-sm ${errors?.taxNumber && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.TAX_NUMBER")}
              {...register("taxNumber")}
            />
          ) : (
            <FormDescription>{activeTax?.taxNumber}</FormDescription>
          )}
          {errors?.taxNumber && (
            <FormHelperText className="text-red-400 text-xs">{errors?.taxNumber?.message}</FormHelperText>
          )}
        </FormControl>
       :  <FormControl isRequired>
            <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
              {t("LABEL.TAX_NUMBER")}
            </FormLabel>
            {isEdit ? (
              <InputText
                className={`w-full text-sm ${errors?.taxNumber && "border-2 border-red-400 shadow-none"}`}
                placeholder={t("LABEL.TAX_NUMBER")}
                {...register("taxNumber", { required: t("INVALID.REQUIRED", { field: t("LABEL.TAX_NUMBER") }) })}
              />
            ) : (
              <FormDescription>{activeTax?.taxNumber}</FormDescription>
            )}
            {errors?.taxNumber && (
              <FormHelperText className="text-red-400 text-xs">{errors?.taxNumber?.message}</FormHelperText>
            )}
          </FormControl>}
     

      {/* Tax Attachment */}
      {companyOverview?.country !== "Indonesia" ? 
       <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          Tax ID Attachment
        </FormLabel>
        {isEdit ? (
          <Uploader
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.TAX"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeTax?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {/* {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )} */}
      </FormControl>
      : 
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.TAX") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.TAX"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
            {...register("attachment", {
              required: {
                value: isCreate,
                message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") }) }),
              },
            })}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeTax?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>}
      
    </>
  );
};

export default Form;
