import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/auth/AuthController";

export const AdminContext = createContext();
const { Provider } = AdminContext;

const AdminController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const regCode = "";

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        name: newForm?.name,
        username: newForm?.username,
        password: ".....",
        roles: newForm?.roles,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeAdmin, setActiveAdmin] = useState();
  const handleDetailMenu = (isOpen, data = null) => {
    setDetailMenu(isOpen);
    setActiveAdmin({ ...data, password: "....." });
    isOpen && resetForm(data);
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadAdmin(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Admin
  const [admin, setAdmin] = useState([]);
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [reloadAdmin, setReloadAdmin] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getCompanies = async () => {
      setLoadingAdmin(true);
      axios
        .get(`/admin${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newAdmin = res?.data?.admin;
            setAdmin(newAdmin);
            setTotalData(res?.data?.pagination?.totalData);
            if (activeAdmin) {
              const newActiveAdmin = newAdmin.find((admin) => admin?.adminId === activeAdmin?.adminId) || null;
              setActiveAdmin(newActiveAdmin);
              resetForm(newActiveAdmin);
            }
          }
        })
        .finally(() => {
          setReloadAdmin(false);
          setLoadingAdmin(false);
        });
    };

    token && reloadAdmin && getCompanies();

    return () => {
      mounted = false;
    };
  }, [token, reloadAdmin]);

  // ---------------------------
  // Master
  // ---------------------------

  const [adminRoles, setAdminRoles] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`/role/admin-role`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
        if (mounted) {
          setAdminRoles(res?.data?.adminRoles);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createAdmin = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`admin`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadAdmin(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeAdmin);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateAdmin = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(`admin/${activeAdmin?.adminId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadAdmin(true);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteAdmin = () => {
    setLoadingDelete(true);

    axios
      .delete(`admin/${activeAdmin?.adminId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadAdmin(true);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        tableForm,
        admin,
        loadingAdmin,
        totalData,
        limit,
        adminRoles,

        form,
        activeAdmin,

        isCreate,
        handleCreate,
        loadingCreate,
        createAdmin,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateAdmin,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteAdmin,
      }}
    >
      {children}
    </Provider>
  );
};

export default AdminController;
