import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../controllers/auth/AuthController";

export const ReqRequisitionContext = createContext();
const { Provider } = ReqRequisitionContext;

const ReqRequisitionController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        reqRequisitionCode: newForm?.reqRequisitionCode || reqRequisitionCode,
        requisitioner: newForm?.requisitioner?.requisitionerCode,
        requisitionMaterials: newForm?.reqRequisitionMaterial?.map((reqMaterial) => ({
          ...reqMaterial,
          material: reqMaterial?.material?.materialCode,
          currency: reqMaterial?.currency?.currency,
          price: +reqMaterial?.price,
        })),
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeReqRequisition, setActiveReqRequisition] = useState();
  const handleDetailMenu = (isOpen, data = null) => {
    setDetailMenu(isOpen);
    setActiveReqRequisition({ ...data, password: "....." });
    isOpen && resetForm(data);
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadReqRequisition(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Req Requisition
  const [reqRequisition, setReqRequisition] = useState([]);
  const [loadingReqRequisition, setLoadingReqRequisition] = useState(false);
  const [reloadReqRequisition, setReloadReqRequisition] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getCompanies = async () => {
      setLoadingReqRequisition(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/req-requisition${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newReqRequisition = res?.data?.reqRequisitions;
            setReqRequisition(newReqRequisition);
            setTotalData(res?.data?.pagination?.totalData);
            if (activeReqRequisition) {
              const newActiveReqRequisition =
                newReqRequisition.find(
                  (reqRequisition) => reqRequisition?.reqRequisitionId === activeReqRequisition?.reqRequisitionId
                ) || null;
              setActiveReqRequisition(newActiveReqRequisition);
              resetForm(newActiveReqRequisition);
            }
          }
        })
        .finally(() => {
          setReloadReqRequisition(false);
          setLoadingReqRequisition(false);
        });
    };

    token && reloadReqRequisition && getCompanies();

    return () => {
      mounted = false;
    };
  }, [token, reloadReqRequisition]);

  // ---------------------------
  // Master
  // ---------------------------

  // Req Requisition Code
  const [reqRequisitionCode, setReqRequisitionCode] = useState();
  const [reloadReqRequisitionCode, setReloadReqRequisitionCode] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getReqRequisitionCode = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/req-requisition/generate-requisition-code`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setReqRequisitionCode(res?.data?.newReqRequisitionCode);
          }
        })
        .finally(() => {
          setReloadReqRequisitionCode(false);
        });
    };

    token && reloadReqRequisitionCode && getReqRequisitionCode();

    return () => {
      mounted = false;
    };
  }, [token, reloadReqRequisitionCode]);

  // Requisitioner
  const [requisitioners, setRequisitioners] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/requisitioner`).then((res) => {
        if (mounted) {
          setRequisitioners(res?.data?.requisitioners);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Material
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/material`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (mounted) {
          setMaterials(res?.data?.material);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Currency
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`currency`).then((res) => {
        if (mounted) {
          setCurrencies(res?.data?.currency);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createReqRequisition = (payload) => {
    setLoadingCreate(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/req-requisition`,
        {
          ...payload,
          requisitionMaterials: payload?.requisitionMaterials?.map((reqMaterial) => ({
            ...reqMaterial,
            valuation: +reqMaterial?.valuation,
            quantity: +reqMaterial?.quantity,
          })),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadReqRequisition(true);
        setReloadReqRequisitionCode(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeReqRequisition);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateReqRequisition = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/req-requisition/${activeReqRequisition?.reqRequisitionId}`,
        {
          ...payload,
          requisitionMaterials: payload?.requisitionMaterials?.map((reqMaterial) => ({
            ...reqMaterial,
            valuation: +reqMaterial?.valuation,
            quantity: +reqMaterial?.quantity,
          })),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadReqRequisition(true);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteReqRequisition = () => {
    setLoadingDelete(true);

    axios
      .delete(`${process.env.REACT_APP_TMS_API}/req-requisition/${activeReqRequisition?.reqRequisitionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadReqRequisition(true);
        setReloadReqRequisitionCode(true);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        tableForm,
        reqRequisition,
        loadingReqRequisition,
        totalData,
        limit,

        requisitioners,
        materials,
        currencies,

        form,
        activeReqRequisition,

        isCreate,
        handleCreate,
        loadingCreate,
        createReqRequisition,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateReqRequisition,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteReqRequisition,
      }}
    >
      {children}
    </Provider>
  );
};

export default ReqRequisitionController;
