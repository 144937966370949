import { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";
import { AuctionContext } from "../../../Controller";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";

export const AuctionLiveContext = createContext();
const AuctionLiveController = ({ children }) => {
  const { t } = useTranslation();
  const { token, userData } = useContext(AuthContext);
  const { auction } = useContext(AuctionContext);
  const toast = useToast();
  const auctionId = auction?.procurementAuctionId;

  // ---------------------------
  // Web Socket
  // ---------------------------
  const connectWebsocket = () => {
    const socket = io(process.env.REACT_APP_NOTIFICATION_WS, {
      port: 81,
      query: { auctionId: auction?.procurementAuctionId },
    });

    socket.on("refreshAuction", () => {
      setReloadBidHistory(true);
      setReloadBidMaterialRank(true);
      setReloadBidRank(true);
      setReloadMinimumBids(true);
      setReloadLeading(true);
      setReloadWinningPrice(true);
    });
  };
  useEffect(() => {
    auction && connectWebsocket();
  }, [auction]);

  // ---------------------------
  // Auction Live
  // ---------------------------

  // Auction Status
  const [auctionStatus, setAuctionStatus] = useState();
  const [targetClock, setTargetClock] = useState();

  const checkAuctionStatus = () => {
    const newAuctionStatus = moment().isBefore(auction?.timeStart)
      ? "pending"
      : moment().isAfter(auction?.timeEnd)
      ? "finish"
      : "active";

    setAuctionStatus(newAuctionStatus);

    const newTargetClock = newAuctionStatus === "pending" ? moment(auction?.timeStart) : moment(auction?.timeEnd);
    setTargetClock(newTargetClock);
  };
  useEffect(() => {
    auction && checkAuctionStatus();
  }, [auction]);

  // Countdown
  const [timeRemaining, setTimeRemaining] = useState("0:00:00");
  const setTime = () => {
    if (auctionStatus === "finish") {
      setTimeRemaining("0:00:00");
      return;
    }

    const duration = moment.duration(targetClock.diff(moment()));
    const newTimeRemaining = Math.floor(duration.as("h")) + moment.utc(duration.as("ms")).format(":mm:ss");

    setTimeRemaining(newTimeRemaining);

    if (duration.asMilliseconds() <= 0) {
      checkAuctionStatus();
    }
  };

  useEffect(() => {
    auctionStatus !== "finish" ? targetClock && setTimeout(() => setTime(), 1000) : setTime();
  }, [auctionStatus, targetClock, timeRemaining]);

  // Bid Form
  const form = useForm();
  const [loadingBid, setLoadingBid] = useState(false);
  const bidAuction = () => {
    const bidMaterial = form.getValues("bid");
    const payload = {
      auctionMaterials: Object.keys(bidMaterial)?.map((auctionMaterialId) => ({
        auctionMaterialId,
        price: bidMaterial[auctionMaterialId],
      })),
    };

    setLoadingBid(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-auction/bid/${userData?.company?.registrationCode}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setReloadBidHistory(true);
        form.reset({});
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingBid(false);
      });
  };

  // Bid History
  const [bidHistory, setBidHistory] = useState([]);
  const [loadingBidHistory, setLoadingBidHistory] = useState(false);
  const [reloadBidHistory, setReloadBidHistory] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBidHistory = async () => {
      setLoadingBidHistory(true);
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-auction/bid-history/${auctionId}/${userData?.company?.registrationCode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newBidHistory = res?.data?.history;
            setBidHistory(newBidHistory);
          }
        })
        .finally(() => {
          setReloadBidHistory(false);
          setLoadingBidHistory(false);
        });
    };

    reloadBidHistory && token && userData?.type === "user" && auctionId && getBidHistory();

    return () => {
      mounted = false;
    };
  }, [token, auctionId, userData, reloadBidHistory]);

  // Bid Material Rank
  const [bidMaterialRank, setBidMaterialRank] = useState([]);
  const [loadingBidMaterialRank, setLoadingBidMaterialRank] = useState(false);
  const [reloadBidMaterialRank, setReloadBidMaterialRank] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBidMaterialRank = async () => {
      setLoadingBidMaterialRank(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-auction/bid-material-rank/${auctionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newBidMaterialRank = res?.data?.bidMaterialRank;
            setBidMaterialRank(newBidMaterialRank);
          }
        })
        .finally(() => {
          setReloadBidMaterialRank(false);
          setLoadingBidMaterialRank(false);
        });
    };

    reloadBidMaterialRank && token && userData?.type !== "user" && auctionId && getBidMaterialRank();

    return () => {
      mounted = false;
    };
  }, [token, auctionId, userData, reloadBidMaterialRank]);

  // Bid Material Rank
  const [bidRank, setBidRank] = useState([]);
  const [loadingBidRank, setLoadingBidRank] = useState(false);
  const [reloadBidRank, setReloadBidRank] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBidRank = async () => {
      setLoadingBidRank(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-auction/bid-rank/${auctionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newBidRank = res?.data?.bidRanks;
            setBidRank(newBidRank);
          }
        })
        .finally(() => {
          setReloadBidRank(false);
          setLoadingBidRank(false);
        });
    };

    reloadBidRank && token && userData?.type !== "user" && auctionId && getBidRank();

    return () => {
      mounted = false;
    };
  }, [token, auctionId, userData, reloadBidRank]);

  // Minimum Bid
  const [minimumBids, setMinimumBids] = useState([]);
  const [loadingMinimumBids, setLoadingMinimumBids] = useState(false);
  const [reloadMinimumBids, setReloadMinimumBids] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getMinimumBids = async () => {
      setLoadingMinimumBids(true);
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-auction/minimum-bid/${auctionId}/${userData?.company?.registrationCode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newMinimumBids = res?.data?.minimumBids;
            setMinimumBids(newMinimumBids);
          }
        })
        .finally(() => {
          setReloadMinimumBids(false);
          setLoadingMinimumBids(false);
        });
    };

    reloadMinimumBids && token && userData?.type === "user" && auctionId && getMinimumBids();

    return () => {
      mounted = false;
    };
  }, [token, auctionId, userData, reloadMinimumBids]);

  // Is Leading
  const [isLeading, setLeading] = useState(false);
  const [reloadLeading, setReloadLeading] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getIsLeading = async () => {
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-auction/is-leading/${auctionId}/${userData?.company?.registrationCode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newIsLeading = res?.data?.isLeading;
            setLeading(newIsLeading);
          }
        })
        .finally(() => {
          setReloadLeading(false);
        });
    };

    reloadLeading && token && userData?.type === "user" && auctionId && getIsLeading();

    return () => {
      mounted = false;
    };
  }, [token, userData, reloadLeading, auctionId]);

  // Winning Price
  const [winningPrice, setWinningPrice] = useState(0);
  const [reloadWinningPrice, setReloadWinningPrice] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getWinningPrice = async () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-auction/winning-price/${auctionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newWinningPrice = res?.data?.winningPrice;
            setWinningPrice(newWinningPrice);
          }
        })
        .finally(() => {
          setReloadWinningPrice(false);
        });
    };

    reloadWinningPrice && token && userData?.type === "user" && auctionId && getWinningPrice();

    return () => {
      mounted = false;
    };
  }, [token, userData, reloadLeading, auctionId]);

  return (
    <AuctionLiveContext.Provider
      value={{
        auctionStatus,
        timeRemaining,

        form,
        loadingBid,
        bidAuction,

        bidHistory,
        loadingBidHistory,

        bidMaterialRank,
        loadingBidMaterialRank,

        bidRank,
        loadingBidRank,

        minimumBids,
        loadingMinimumBids,

        isLeading,
        winningPrice,
      }}
    >
      {children}
    </AuctionLiveContext.Provider>
  );
};

export default AuctionLiveController;
