import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import { ProcurementContext } from "../Controller";
import Form from "./Form";
import ProcurementSettingForm from "./ProcurementSettingForm";
import ReqRequisitionForm from "./ReqRequisition/ReqRequisitionForm";

const ProcurementNew = () => {
  const { t } = useTranslation();
  const { form, handleCreate, loadingCreate, createProcurement } = useContext(ProcurementContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">

        <div className="md:col-span-2 flex justify-between items-center gap-2">
          <button className="flex text-xl text-gray-600 items-center gap-3" onClick={() => handleCreate(false)}>
            <BsArrowLeftCircle />
            <div className="text-lg text-gray-800">{t("LABEL.CANCEL")}</div>
          </button>
        </div>
        
        <Form isEdit={true} />
        <ReqRequisitionForm />
        <ProcurementSettingForm />

        <div className="md:col-span-2  flex justify-end">
          <Button size="sm" colorScheme="teal" isLoading={loadingCreate} onClick={form.handleSubmit(createProcurement)}>
            {t("LABEL.SAVE")}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default ProcurementNew;
