import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const ShareholderContext = createContext();
const { Provider } = ShareholderContext;

const ShareholderController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        shareholderName: newForm?.shareholderName,
        citizenship: newForm?.citizenship?.regionCode,
        sharePercentage: newForm?.sharePercentage,
        taxNumber: newForm?.taxNumber,
        idCardNumber: newForm?.idCardNumber,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeShareholder, setActiveShareholder] = useState();
  const handleDetailMenu = (isOpen, shareholder = null) => {
    setDetailMenu(isOpen);
    setActiveShareholder(shareholder);
    isOpen && resetForm(shareholder);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Shareholders
  const [shareholders, setShareholders] = useState([]);
  const [loadingShareholders, setLoadingShareholders] = useState(false);
  const [reloadShareholders, setReloadShareholders] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getShareholders = async () => {
      setLoadingShareholders(true);
      axios
        .get(`/shareholder/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newShareholders = res?.data?.shareholders;
            setShareholders(newShareholders);

            if (activeShareholder) {
              const newActiveShareholder =
                newShareholders.find(
                  (shareholder) => shareholder?.shareholderId === activeShareholder?.shareholderId
                ) || null;
              setActiveShareholder(newActiveShareholder);
              resetForm(newActiveShareholder);
            }
          }
        })
        .finally(() => {
          setReloadShareholders(false);
          setLoadingShareholders(false);
        });
    };

    userData && token && reloadShareholders && getShareholders();

    if (reloadShareholders) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadShareholders]);

  // -------------------------------
  // Master
  // -------------------------------

  // Countries
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/regions`).then((res) => {
      if (mounted) {
        setCountries(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createShareholder = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    payload?.taxAttachment && formData.append("taxAttachment", payload?.taxAttachment[0]);
    formData.append("idCardAttachment", payload?.idCardAttachment[0]);

    axios
      .post(`shareholder/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadShareholders(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeShareholder);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateShareholder = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    payload?.taxAttachment && formData.append("taxAttachment", payload?.taxAttachment[0]);
    payload?.idCardAttachment && formData.append("idCardAttachment", payload?.idCardAttachment[0]);

    axios
      .patch(`shareholder/${regCode}/${activeShareholder?.shareholderId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadShareholders(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteShareholder = () => {
    setLoadingDelete(true);

    axios
      .delete(`shareholder/${regCode}/${activeShareholder?.shareholderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadShareholders(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingShareholders,
        shareholders,
        activeShareholder,

        countries,

        isCreate,
        handleCreate,
        loadingCreate,
        createShareholder,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateShareholder,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteShareholder,
      }}
    >
      {children}
    </Provider>
  );
};

export default ShareholderController;
