import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { Spinner } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import Select from "../../../../../../components/inputForm/Select";
import { RegisterContext } from "../../../Controller";
import BasicInformationController, { BasicInformationContext } from "./Controller";

const BasicInformationForm = () => {
  const { t, i18n } = useTranslation();
  const { isNameAvailable, loadingNameAvailable, companyTypes, isic, subIsic } = useContext(BasicInformationContext);
  const { basicInformationForm: form } = useContext(RegisterContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Company Type */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_TYPE")}</FormLabel>
        <Select
          filter
          control={control}
          controlProps={{
            rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_TYPE") }) },
          }}
          name="companyType"
          options={companyTypes.map((companyType) => ({
            label: companyType?.companyType,
            value: companyType?.companyTypeCode,
          }))}
          placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.COMPANY_TYPE")}`}
          isError={!!errors?.companyType}
        />
        {errors?.companyType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Company Name */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_NAME")}</FormLabel>
        <InputText
          className={`w-full text-sm ${errors?.companyName && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("LABEL.COMPANY_NAME")}
          {...register("companyName", {
            required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_NAME") }),
          })}
          onChange={(e) => form.setValue("companyName", e.target.value.toUpperCase())}
        />
        {errors?.companyName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Business Field */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.BUSINESS_FIELD")}</FormLabel>
        <Select
          filter
          control={control}
          controlProps={{
            rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_FIELD") }) },
          }}
          name="businessField"
          options={isic.map((isicItem) => ({
            label: `${isicItem?.isicCode} - ${
              i18n.language === "id" ? isicItem?.description : isicItem?.descriptionEn
            }`,
            value: isicItem?.isicCode,
          }))}
          placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_FIELD")}`}
          isError={!!errors?.businessField}
        />
        {errors?.businessField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.businessField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Sub Business Field */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.SUB_BUSINESS_FIELD")}</FormLabel>
        <Select
          filter
          control={control}
          controlProps={{
            rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.SUB_BUSINESS_FIELD") }) },
          }}
          name="subBusinessField"
          options={subIsic.map((isicItem) => ({
            label: `${isicItem?.isicCode} - ${
              i18n.language === "id" ? isicItem?.description : isicItem?.descriptionEn
            }`,
            value: isicItem?.isicCode,
          }))}
          placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.SUB_BUSINESS_FIELD")}`}
          disabled={!subIsic.length}
          isError={!!errors?.subBusinessField}
        />
        {errors?.subBusinessField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.subBusinessField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Director Name */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_OWNER")} / CEO</FormLabel>
        <InputText
          className={`w-full text-sm ${errors?.companyOwner && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("LABEL.COMPANY_OWNER")}
          {...register("companyOwner", { required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_OWNER") }) })}
        />
        {errors?.companyOwner && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyOwner?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default () => (
  <BasicInformationController>
    <BasicInformationForm />
  </BasicInformationController>
);
