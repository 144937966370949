import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";

const WinnerForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { prequalificationForm, schedules } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {/* Persetujuan Usulan Daftar Pemenang */}
      <hr className="md:col-span-2" />
      <FormControl className="md:col-span-2">
        <FormLabel
          className={`uppercase font-bold text-sm text-red-500 mb-4`}
        >
          {t("LABEL.PROCUREMENT_PROPOSED_LIST_WINNER_APPROVED")}
        </FormLabel>

        <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
          {/* Start Persetujuan Usulan Daftar Pemenang */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_START", {
                field: t("LABEL.PROCUREMENT_PROPOSED_LIST_WINNER_APPROVED"),
              })}
            </FormLabel>

            <FormDescription>
              {schedules[0]?.proposedListWinnerApprovedStart &&
                moment(
                  new Date(schedules[0]?.proposedListWinnerApprovedStart)
                ).format("LLL")}
            </FormDescription>

            {errors?.proposedListWinnerApprovedStart && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.proposedListWinnerApprovedStart?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* End Persetujuan Usulan Daftar Pemenang */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_END", {
                field: t("LABEL.PROCUREMENT_PROPOSED_LIST_WINNER_APPROVED"),
              })}
            </FormLabel>

            <FormDescription>
              {schedules[0]?.proposedListWinnerApprovedEnd &&
                moment(
                  new Date(schedules[0]?.proposedListWinnerApprovedEnd)
                ).format("LLL")}
            </FormDescription>

            {errors?.proposedListWinnerApprovedEnd && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.proposedListWinnerApprovedEnd?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </FormControl>
    </>
  );
};

export default WinnerForm;
