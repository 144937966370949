import { Button, FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ActivationContext } from "../Controller";

const NewPassword = () => {
  const { t } = useTranslation();
  const { form, createPassword, loadingCreatePassword } = useContext(ActivationContext);
  const {
    register,
    watch,
    formState: { errors },
  } = form;

  const password = watch("password");

  return (
    <div className="h-full flex flex-col gap-y-8 justify-center items-center p-10">
      <h1 className="font-bold text-xl md:text-2xl montserrat text-transparent bg-clip-text bg-gradient-primary mb-4 md:mb-2">
        {t("TEXT.CREATE_NEW_PASSWORD")}
      </h1>

      <div className="w-full md:w-1/3 flex flex-col gap-4">
        {/* Password */}
        <FormControl isRequired>
          <FormLabel className="text-sm">Password</FormLabel>
          <Password
            toggleMask
            className="w-full"
            inputClassName={`w-full text-sm ${errors?.password && "border-2 border-red-400 shadow-none"}`}
            placeholder="Password"
            {...register("password", {
              required: t("INVALID.REQUIRED", { field: "Password" }),
              minLength: { value: 8, message: t("INVALID.MIN_LENGTH", { field: "Password", length: 8 }) },
            })}
            onChange={(e) => form.setValue("password", e.target.value)}
          />
          {errors?.password && (
            <FormHelperText className="text-red-400 text-xs">{errors?.password?.message}</FormHelperText>
          )}
        </FormControl>

        {/* Password Confirmation */}
        <FormControl isRequired>
          <FormLabel className="text-sm">{t("LABEL.PASSWORD_CONFIRMATION")}</FormLabel>
          <Password
            toggleMask
            feedback={false}
            className="w-full"
            inputClassName={`w-full text-sm ${errors?.passwordConfirmation && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.PASSWORD_CONFIRMATION")}
            {...register("passwordConfirmation", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.PASSWORD_CONFIRMATION") }),
              validate: { confirmPassword: (v) => v === password || t("INVALID.PASSWORD_CONFIRMATION") },
            })}
            onChange={(e) => form.setValue("passwordConfirmation", e.target.value)}
          />
          {errors?.passwordConfirmation && (
            <FormHelperText className="text-red-400 text-xs">{errors?.passwordConfirmation?.message}</FormHelperText>
          )}
        </FormControl>
      </div>
      <Button
        onClick={form.handleSubmit(createPassword)}
        className="w-full md:w-32 transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 text-white"
        isLoading={loadingCreatePassword}
      >
        {t("LABEL.SAVE")}
      </Button>
    </div>
  );
};

export default NewPassword;
