export const vendorLimit = {
	LIMITED: {
		MIN: 3,
		MAX: 999,
    SUGGESTED_MIN: 3,
	},
	GENERAL: {
		MIN: 1,
		MAX: 999,
    SUGGESTED_MIN: 0,
	},
	DIRECT: {
		MIN: 1,
		MAX: 1,
    SUGGESTED_MIN: 1,
	},
};
