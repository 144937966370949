import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const GeneralContext = createContext();
const { Provider } = GeneralContext;

const GeneralController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { regCode, setReloadCompanyOverview, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    form.reset({
      companyName: newForm?.companyName,
      companyType: newForm?.companyType?.companyTypeCode,
      businessField: newForm?.businessField?.isicCode,
      subBusinessField: newForm?.subBusinessField?.isicCode,
      companyOwner: newForm?.bod?.[0]?.bodName,
      registrationCode: newForm?.registrationCode,
      companyDescription: newForm?.description,
    });
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Company
  const [company, setCompany] = useState();
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [reloadCompany, setReloadCompany] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getCompany = async () => {
      setLoadingCompany(true);
      axios
        .get(`/company/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newCompany = res?.data?.company;
            setCompany(newCompany);
            resetForm(newCompany);
          }
        })
        .finally(() => {
          setReloadCompany(false);
          setLoadingCompany(false);
        });
    };

    userData && token && reloadCompany && getCompany();

    if (reloadCompany) {
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadCompany]);

  // -------------------------------
  // Master
  // -------------------------------

  // Company Types
  const [companyTypes, setCompanyTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get("/company-types").then((res) => {
      if (mounted) {
        setCompanyTypes(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Validation
  // -------------------------------

  // Company Name Availability
  const companyName = form.watch("companyName");
  const [isNameAvailable, setNameAvailable] = useState();
  const [loadingNameAvailable, setLoadingNameAvailable] = useState(false);
  const [nameAvailableTimeout, setNameAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkCompanyName = () => {
      setLoadingNameAvailable(true);

      axios
        .post("/company/check-company-name", {
          companyName,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setNameAvailable(companyName === company?.companyName || available);
        })
        .finally(() => {
          setLoadingNameAvailable(false);
          form.trigger("companyName");
        });
    };

    if (company) {
      clearTimeout(nameAvailableTimeout);
      const newTimeout = setTimeout(() => {
        companyName ? checkCompanyName() : setNameAvailable();
      }, 500);
      setNameAvailableTimeout(newTimeout);
    }

    return () => {
      mounted = false;
    };
  }, [company, companyName]);

  // -------------------------------
  // Update Data
  // -------------------------------

  // Handle Edit
  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(company);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateRegistration = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(`company/${regCode}`, payload, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadCompany(true);
        setReloadCompanyOverview(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  return (
    <Provider
      value={{
        loadingCompany,
        company,

        form,
        isNameAvailable,
        loadingNameAvailable,
        companyTypes,

        isEdit,
        setEdit,
        updateRegistration,
        loadingUpdate,
      }}
    >
      {children}
    </Provider>
  );
};

export default GeneralController;
