import { Button, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit, BiTrash } from "react-icons/bi";
import {
  BsArrowLeftCircle,
  BsMegaphone,
  BsCheckLg,
  BsXLg,
} from "react-icons/bs";
import PrintDocumentButton from "../../../../../../../../../components/PrintDocumentButton";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../../Controller";
import { AanwijzingContext } from "../Controller";
import Form from "./Form";
import ModalAanwijzingAnnounce from "./ModalAanwijzingAnnounce";
import ModalDelete from "./ModalDelete";

const AanwijzingDetail = () => {
  const { t } = useTranslation();
  const {procurement} = useContext(ProcurementDetailContext);
  const {
    isEdit,
    setEdit,
    handleAnnounceOpen,
    handleDetailMenu,
    form,
    loadingUpdate,
    aanwijzing,
    updateAanwijzing,
    activeAanwijzing,
    setModalDeleteOpen,
  } = useContext(AanwijzingContext);
  const { userData } = useContext(AuthContext);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="bg-white rounded-md p-6"
    >
      <div className="w-full grid md:grid-cols-2 gap-6">
        <>
          {isEdit ? (
            <div className="md:col-span-2 flex justify-end items-center gap-2">
              <div className="flex gap-2">
                <Button size="xs" onClick={() => setEdit(false)}>
                  {t("LABEL.CANCEL")}
                </Button>

                <Button
                  size="xs"
                  colorScheme="teal"
                  isLoading={loadingUpdate}
                  onClick={form.handleSubmit(updateAanwijzing)}
                >
                  {t("LABEL.SAVE")}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="md:col-span-2 flex justify-between items-center gap-2">
                <button onClick={() => handleDetailMenu(false)}>
                  <BsArrowLeftCircle className="text-xl text-gray-600" />
                </button>
                {userData?.type !== "user" ? (
                  <div className="flex gap-4">
                    <PrintDocumentButton url={`ba-aanwijzing/${procurement?.procurementNumber}/${activeAanwijzing?.procurementAanwijzingId}`} className={`${!procurement?.procurementWinnerProposal?.isWinner? "mr-8" : ""}`} label="Cetak Dokumen" />

                    {!procurement?.procurementWinnerProposal?.isWinner && (
                      <>
                        {aanwijzing[0].status === "ANNOUNCED" ? (
                          <>
                            <Tooltip
                              hasArrow
                              label={t("TOOLTIP.ANNOUNCEMENT_FINISHED")}
                              placement="top"
                            >
                              <PrivateComponent accessName="BUYER_EDIT">
                                <button
                                  onClick={() => handleAnnounceOpen(true, "finish")}
                                >
                                  <BsCheckLg className="text-lg text-green-500" />
                                </button>
                              </PrivateComponent>
                            </Tooltip>
                            <Tooltip
                              hasArrow
                              label={t("TOOLTIP.ANNOUNCEMENT_CANCELLED")}
                              placement="top"
                            >
                              <PrivateComponent accessName="BUYER_EDIT">
                                <button
                                  onClick={() => handleAnnounceOpen(true, "cancel")}
                                >
                                  <BsXLg className="text-lg text-red-500" />
                                </button>
                              </PrivateComponent>
                            </Tooltip>{" "}
                          </>
                        ) : aanwijzing[0].status === "DRAFT" ? (
                          <Tooltip
                            hasArrow
                            label={t("TOOLTIP.ANNOUNCEMENT")}
                            placement="top"
                          >
                            <PrivateComponent accessName="BUYER_EDIT">
                              <button
                                onClick={() => handleAnnounceOpen(true, "announce")}
                              >
                                <BsMegaphone className="text-lg text-gray-500" />
                              </button>
                            </PrivateComponent>
                          </Tooltip>
                        ) : (
                          ""
                        )}

                        <Tooltip hasArrow label={t("TOOLTIP.EDIT")} placement="top">
                          <PrivateComponent accessName="BUYER_EDIT">
                            <button onClick={() => setEdit(true)}>
                              <BiEdit className="text-lg text-gray-500" />
                            </button>
                          </PrivateComponent>
                        </Tooltip>
                        {!activeAanwijzing?.roles?.includes("Super Admin") && (
                          <Tooltip
                            hasArrow
                            label={t("TOOLTIP.DELETE")}
                            placement="top"
                          >
                            <PrivateComponent accessName="BUYER_EDIT">
                              <button onClick={() => setModalDeleteOpen(true)}>
                                <BiTrash className="text-lg text-red-500" />
                              </button>
                            </PrivateComponent>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </>
      </div>
      <div className="md:col-span-2 flex justify-between items-center gap-2 py-8">
        <div className="w-full grid md:grid-cols-1 gap-6">
          <Form isEdit={isEdit} />
          <ModalAanwijzingAnnounce />
          <ModalDelete />
        </div>
      </div>
    </motion.div>
  );
};

export default AanwijzingDetail;
