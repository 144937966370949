import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const ModalConfirmSubmitReapproval = ({ isOpen, setOpen, loading, submit }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={() => setOpen(false)} size="md" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>{t("TEXT.SUBMIT_REAPPROVAL")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.CONFIRM_SUBMIT_REAPPROVAL")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => setOpen(false)}>{t("LABEL.CANCEL")}</Button>
          <Button isLoading={loading} onClick={submit} colorScheme="blue">
            {t("LABEL.SUBMIT", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmSubmitReapproval;
