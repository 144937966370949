import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { HelperContext } from "../../../../../../../../controllers/HelperController";
import { ProcurementDetailContext } from "../../../Controller";

export const VendorContext = createContext();
const { Provider } = VendorContext;

const VendorController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const {showToast, httpRequest} = useContext(HelperContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { token } = useContext(AuthContext);
  const procurementNumber = procurement?.procurementNumber;
  const [chosenVendors, setChosenVendors] = useState({});

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        requisitionerCode: newForm?.requisitionerCode,
        description: newForm?.description,
      });
    }
  };

  const scheduleForm = useForm();
  const resetScheduleForm = (newForm) => {
    if (newForm) {
      scheduleForm.reset({
        dateStart: new Date(newForm?.dateStart || null),
        dateEnd: new Date(newForm?.dateEnd || null),
      });
    }
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadVendor(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Vendor
  const [vendor, setVendor] = useState([]);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [reloadVendor, setReloadVendor] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}&suggested=true`;

    const getVendor = async () => {
      setLoadingVendor(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newRequisition = res?.data?.procurementVendors;
            setVendor(newRequisition);
            setTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setReloadVendor(false);
          setLoadingVendor(false);
        });
    };

    token && procurementNumber && reloadVendor && getVendor();

    return () => {
      mounted = false;
    };
  }, [token, reloadVendor, procurementNumber]);

  // Schedule
  const [procurementSchedule, setProcurementSchedule] = useState([]);
  const [loadingProcurementSchedule, setLoadingProcurementSchedule] = useState(false);
  const [reloadProcurementSchedule, setReloadProcurementSchedule] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getProcurementSchedule = () => {
      setLoadingProcurementSchedule(true);

      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setProcurementSchedule(res?.data?.procurementSchedule?.[0]);
          }
        })
        .finally(() => {
          setLoadingProcurementSchedule(false);
          setReloadProcurementSchedule(false);
        });
    };

    token && procurementNumber && reloadProcurementSchedule && getProcurementSchedule();

    return () => {
      mounted = false;
    };
  }, [token, procurementNumber, reloadProcurementSchedule]);

  // ---------------------------
  // Master
  // ---------------------------

  // Vendor
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios
        .get(`company`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setVendors(res?.data?.companies);
          }
        });

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createVendor = () => {
    const regCodes = Object.keys(chosenVendors);
    if (regCodes.length === 0){
      form.setError('vendor', {
        message: t('INVALID.REQUIRED', { field: t('LABEL.VENDOR') })
      })
      return;
    }
    form.clearErrors('vendor')
  
    setLoadingCreate(true);
    axios
      .post(`${process.env.REACT_APP_TMS_API}/procurement-vendor/many/${procurementNumber}`, {
        vendors: regCodes
      }, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.error === "" || !res?.data?.success) {
          toast({
            description: t("INVALID.AVAILABLE", {
              field: t("LABEL.VENDOR"),
            }),
            position: "top-right",
            status: "error",
            isClosable: true,
          });
          setCreate(true);
        } else {
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
          setCreate(false);
        }
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadVendor(true);
        setChosenVendors({});
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [vendorDelete, setVendorDelete] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteVendor = () => {
    setLoadingDelete(true);

    axios
      .delete(`${process.env.REACT_APP_TMS_API}/procurement-vendor/${vendorDelete?.procurementVendorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (!res?.data?.success){
          showToast('Gagal menghapus vendor', {success: false});
        }
        else{
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
        }
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadVendor(true);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const handleDelete = (isOpen, vendor = null) => {
    setModalDeleteOpen(isOpen);
    isOpen && setVendorDelete(vendor);
  };

  // -------------------------------
  // Update Vendor Schedule
  // -------------------------------

  const [isUpdateSchedule, setUpdateSchedule] = useState(false);
  const handleUpdateSchedule = (isOpen) => {
    setUpdateSchedule(isOpen);
    isOpen && resetScheduleForm(procurementSchedule);
  };
  const [loadingUpdateSchedule, setLoadingUpdateSchedule] = useState(false);
  const updateSchedule = (payload) => {
    setLoadingUpdateSchedule(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}`,
        {
          ...payload,
          scheduleType: "VENDOR_REGISTRATION",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setUpdateSchedule(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdateSchedule(false);
        setReloadProcurementSchedule(true);
      });
  };

  
  const [isics, setIsics] = useState();
  useEffect(async () => {
    let mounted = true;
    const {response} = await httpRequest({
      url: 'isic'
    });
    if (!mounted) return;
    setIsics(response?.data);
    return () => mounted = false;
  }, []);

  return (
    <Provider
      value={{
        tableForm,
        vendor,
        loadingVendor,
        totalData,
        limit,

        vendors,
        procurementSchedule,
        loadingProcurementSchedule,

        form,
        scheduleForm,

        isCreate,
        handleCreate,
        loadingCreate,
        createVendor,

        isModalDeleteOpen,
        handleDelete,
        loadingDelete,
        deleteVendor,

        isUpdateSchedule,
        handleUpdateSchedule,
        loadingUpdateSchedule,
        updateSchedule,

        isics, setIsics,
        chosenVendors, setChosenVendors
      }}
    >
      {children}
    </Provider>
  );
};

export default VendorController;
