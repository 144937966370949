import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Password } from "primereact/password";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../Controller";

const ModalChangePassword = () => {
  const { t } = useTranslation();
  const { isModalChangePasswordOpen, setModalChangePasswordOpen, loadingChangePassword, submitChangePassword } =
    useContext(AccountContext);

  const form = useForm();
  const {
    register,
    formState: { errors },
  } = form;

  const password = form.watch("password");
  const handleSubmit = (e) => {
    e.preventDefault();
    form.handleSubmit(submitChangePassword(form.getValues("currentPassword"), form.getValues("password")));
  };

  return (
    <Modal onClose={() => setModalChangePasswordOpen(false)} isOpen={isModalChangePasswordOpen}>
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalHeader>{t("LABEL.CHANGE_PASSWORD")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex flex-col gap-2">
              {/* Current Password */}
              <FormControl isRequired>
                <FormLabel className="text-sm">{t("LABEL.CURRENT_PASSWORD")}</FormLabel>
                <Password
                  feedback={false}
                  toggleMask
                  className="w-full"
                  inputClassName={`w-full text-sm ${errors?.currentPassword && "border-2 border-red-400 shadow-none"}`}
                  placeholder={t("LABEL.CURRENT_PASSWORD")}
                  {...register("currentPassword", {
                    required: t("INVALID.REQUIRED", { field: t("LABEL.CURRENT_PASSWORD") }),
                  })}
                  onChange={(e) => form.setValue("currentPassword", e.target.value)}
                />
                {errors?.currentPassword && (
                  <FormHelperText className="text-red-400 text-xs">{errors?.currentPassword?.message}</FormHelperText>
                )}
              </FormControl>

              <hr className="my-2" />

              {/* Password */}
              <FormControl isRequired>
                <FormLabel className="text-sm">{t("LABEL.NEW_PASSWORD")}</FormLabel>
                <Password
                  toggleMask
                  className="w-full"
                  inputClassName={`w-full text-sm ${errors?.password && "border-2 border-red-400 shadow-none"}`}
                  placeholder={t("LABEL.NEW_PASSWORD")}
                  {...register("password", {
                    required: t("INVALID.REQUIRED", { field: t("LABEL.NEW_PASSWORD") }),
                    minLength: {
                      value: 8,
                      message: t("INVALID.MIN_LENGTH", { field: t("LABEL.NEW_PASSWORD"), length: 8 }),
                    },
                  })}
                  onChange={(e) => form.setValue("password", e.target.value)}
                />
                {errors?.password && (
                  <FormHelperText className="text-red-400 text-xs">{errors?.password?.message}</FormHelperText>
                )}
              </FormControl>

              {/* Confirmation Password */}
              <FormControl isRequired>
                <FormLabel className="text-sm">{t("LABEL.PASSWORD_CONFIRMATION")}</FormLabel>
                <Password
                  feedback={false}
                  toggleMask
                  className="w-full"
                  inputClassName={`w-full text-sm ${
                    errors?.passwordConfirmation && "border-2 border-red-400 shadow-none"
                  }`}
                  placeholder={t("LABEL.PASSWORD_CONFIRMATION")}
                  type="password"
                  {...register("passwordConfirmation", {
                    required: t("INVALID.REQUIRED", { field: t("LABEL.PASSWORD_CONFIRMATION") }),
                    validate: { confirmPassword: (v) => v === password || t("INVALID.PASSWORD_CONFIRMATION") },
                  })}
                  onChange={(e) => form.setValue("passwordConfirmation", e.target.value)}
                />
                {errors?.passwordConfirmation && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.passwordConfirmation?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </ModalBody>
          <ModalFooter className="flex gap-2">
            <Button onClick={() => setModalChangePasswordOpen(false)}>{t("LABEL.CANCEL")}</Button>
            <Button isLoading={loadingChangePassword} type="submit" colorScheme="teal">
              {t("LABEL.CHANGE", { field: "" })}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ModalChangePassword;
