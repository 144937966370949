import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AanwijzingContext } from "../Controller";

const ModalDelete = () => {
  const { t } = useTranslation();
  const { isModalDeleteOpen, handleDelete, loadingDelete, deleteAanwijzing } =
    useContext(AanwijzingContext);

  return (
    <Modal
      onClose={() => handleDelete(false)}
      size="md"
      isOpen={isModalDeleteOpen}
    >
      <ModalOverlay />
      <ModalContent className="bg-red-500 text-white bg-opacity-80">
        <ModalHeader>
          {t("LABEL.DELETE", { field: t("LABEL.PROCUREMENT_AANWIJZING") })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {t("TEXT.DELETE_CONFIRMATION", {
            field: t("LABEL.PROCUREMENT_AANWIJZING"),
          })}
        </ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleDelete(false)} colorScheme="whiteAlpha">
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingDelete}
            onClick={deleteAanwijzing}
            colorScheme="blackAlpha"
          >
            {t("LABEL.DELETE", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDelete;
