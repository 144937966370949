import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../controllers/auth/AuthController";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const NotificationContext = createContext();
const { Provider } = NotificationContext;

const NotificationController = ({ children }) => {
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);

  // Notifications
  const [notifications, setNotifications] = useState([]);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLast, setLast] = useState(false);
  const getNotifications = () => {
    if (loadingNotification || isLast) return;

    setLoadingNotification(true);
    axios
      .get(`notify/${userData?.company?.registrationCode}/${userData?.username}?offset=${offset}&limit=10`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setNotifications([...notifications, ...res?.data?.notifications]);
        setLast(res?.data?.isLast);
        setOffset(offset + 10);
      })
      .finally(() => {
        setLoadingNotification(false);
      });
  };
  useEffect(() => {
    userData && token && getNotifications();
  }, [userData, token]);

  // Read Notification
  useEffect(() => {
    userData && axios.post("notify/read", { username: userData?.username });
  }, [userData]);

  return <Provider value={{ notifications, loadingNotification, getNotifications }}>{children}</Provider>;
};

export default NotificationController;
