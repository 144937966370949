import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheck, BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import FileAttachment from '../../../../../../../../../../components/FileAttachment';
import PrivateComponent from '../../../../../../../../../../components/PrivateComponent';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import { userRouteSlug } from '../../../../../../../../../../routes/userRoutes';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../../Controller';
import NextStepButton from '../../../../NextStepButton';
import { PrequalificationEvaluationContext } from '../Controller';

const Row = ({ vendor, idx, canEval }) => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { setVendors, vendors, evaluation } = useContext(PrequalificationEvaluationContext);

	const [loadingAccept, setLoadingAccept] = useState(false);
	const [loadingReject, setLoadingReject] = useState(false);

	const submitStatus = async (status) => {
		if (status === 'ACCEPTED') setLoadingAccept(true);
		else setLoadingReject(true);

		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor-prequalification/status/${vendor.procurementVendorPrequalification?.id}/${status}`,
			method: 'PATCH',
		});

		if (status === 'ACCEPTED') setLoadingAccept(false);
		else setLoadingReject(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		vendors[idx].procurementVendorPrequalification = response?.data?.procurementVendorPrequalification;
		setVendors([...vendors]);
	};

	return (
		<Tr className="hover:bg-gray-50">
			<Td
				style={{
					width: '140px',
				}}
			>
				<Link className="font-semibold text-blue-600 hover:text-blue-500" to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${vendor?.vendor}`} target="_blank">
					{vendor?.vendor}
				</Link>
			</Td>
			<Td>{vendor?.company?.companyName}</Td>
			<Td
				style={{
					width: '190px',
				}}
			>
				<div className="flex justify-center">
					{!vendor?.procurementVendorPrequalification ? (
						<div>{t('LABEL.NOT_SUBMITTED')}</div>
					) : (
						<FileAttachment disabled={!canEval} href={`${API_URLS.REACT_APP_FILESERVICE_API}/files/users/${vendor?.procurementVendorPrequalification?.filename}`} />
					)}
				</div>
			</Td>
			<Td
				style={{
					width: '210px',
				}}
			>
				<div className="flex justify-center items-center gap-4">
					{evaluation || !vendor?.procurementVendorPrequalification ? (
						vendor?.procurementVendorPrequalification?.status === 'ACCEPTED' ? (
							<div className="font-bold text-green-500 italic">{t('LABEL.QUALIFIED')}</div>
						) : (
							<div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div>
						)
					) : (
						<PrivateComponent accessName="BUYER_EDIT" placeholder={<div className="mt-auto mb-auto text-color2 font-semibold italic">Pending</div>}>
							<Button
								color={vendor.procurementVendorPrequalification?.status === 'ACCEPTED' ? 'white' : 'green'}
								className={`transition-all duration-500 flex gap-1 items-center justify-center ${vendor.procurementVendorPrequalification?.status === 'ACCEPTED' ? 'bg-green-500' : ''}`}
								onClick={() => {
									submitStatus('ACCEPTED');
								}}
								isLoading={loadingAccept}
								disabled={loadingReject || loadingAccept || !canEval}
							>
								<BiCheck className="text-1xl" />
								{t('LABEL.QUALIFIED')}
							</Button>
							<Button
								color={vendor.procurementVendorPrequalification?.status === 'REJECTED' ? 'white' : 'red'}
								className={`transition-all duration-500 flex gap-1 items-center justify-center ${vendor.procurementVendorPrequalification?.status === 'REJECTED' ? 'bg-color0' : ''}`}
								onClick={() => {
									submitStatus('REJECTED');
								}}
								isLoading={loadingReject}
								disabled={loadingReject || loadingAccept || !canEval}
							>
								<BiX className="text-1xl" />
								{t('LABEL.FAIL')}
							</Button>
						</PrivateComponent>
					)}
				</div>
			</Td>
		</Tr>
	);
};

const VendorTable = () => {
	const { t } = useTranslation();
	const { procurementSchedule } = useContext(ProcurementDetailContext);
	const { vendors, loadingVendors } = useContext(PrequalificationEvaluationContext);

	const start = procurementSchedule?.prequalificationStart;
	const end = procurementSchedule?.prequalificationEnd;
	const now = moment().unix();
	const canEval = start && end && now >= moment(end).unix();

	return (
		<div className="w-full bg-white rounded-md text-sm col-span-3">
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th className="text-red-500">{t('LABEL.REGISTRATION_ID')}</Th>
						<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
						<Th className="text-red-500 text-center">{t('LABEL.DOCUMENT')}</Th>
						<Th className="text-red-500 text-center">{t('LABEL.ACTION')}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loadingVendors ? (
						<Tr>
							<Td colSpan={4}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					) : !vendors ? (
						<Tr>
							<Td colSpan={4}>
								<div className="w-full h-full flex justify-center items-center">{t('ERROR.undefined')}</div>
							</Td>
						</Tr>
					) : (
						vendors?.map((vendor, idx) => <Row canEval={canEval} vendor={vendor} key={idx} idx={idx} />)
					)}
				</Tbody>
			</Table>
		</div>
	);
};

export default VendorTable;
