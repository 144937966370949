import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Datepicker from "../../../../../../../../../../components/inputForm/Datepicker";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { NegotiationContext } from "../../Controller";
import NegotiationDetail from "../NegotiationDetail";
import { AuthContext } from "../../../../../../../../../../controllers/auth/AuthController";

const FormSchedule = () => {
  const { isVendor } = useContext(AuthContext);
  const { t } = useTranslation();
  const { activeNegotiation, form, isEdit, priceGroup } =
    useContext(NegotiationContext);

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const now = moment().unix();
  const isOverStart = !activeNegotiation?.negotiationStart
    ? false
    : now > moment(activeNegotiation?.negotiationStart).unix();
  const isOverEnd = !activeNegotiation?.negotiationEnd
    ? false
    : now > moment(activeNegotiation.negotiationEnd).unix();

  if (
    isVendor &&
    (!priceGroup || !activeNegotiation || priceGroup?.length === 0)
  ) {
    return (
      <div className="font-semibold text-xl text-center">
        {t("TEXT.STATUS_NEGOTIATION_NOTHING")}
      </div>
    );
  }

  return (
    <>
      {/* Negotiation Schedule */}
      <FormControl className="md:col-span-2">
        <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
          {/* Start */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_AANWIJZING_FROM")}
            </FormLabel>

            {isEdit ? (
              <Datepicker
                disabled={isOverStart}
                format="yyyy-MM-dd HH:mm:ss"
                name="negotiationStart"
                placeholder={t("LABEL.PROCUREMENT_AANWIJZING_FROM")}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_AANWIJZING_FROM"),
                    }),
                  },
                }}
                isError={!!errors?.negotiationStart}
              />
            ) : (
              <FormDescription>
                {activeNegotiation?.negotiationStart &&
                  moment(new Date(activeNegotiation?.negotiationStart)).format(
                    "LLL"
                  )}
              </FormDescription>
            )}

            {errors?.negotiationStart && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.negotiationStart?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* End */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_AANWIJZING_UNTIL")}
            </FormLabel>

            {isEdit ? (
              <Datepicker
                disabled={isOverEnd}
                format="yyyy-MM-dd HH:mm:ss"
                name="negotiationEnd"
                placeholder={t("LABEL.PROCUREMENT_AANWIJZING_UNTIL")}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_AANWIJZING_UNTIL"),
                    }),
                  },
                }}
                isError={!!errors?.negotiationEnd}
              />
            ) : (
              <FormDescription>
                {activeNegotiation?.negotiationEnd &&
                  moment(new Date(activeNegotiation?.negotiationEnd)).format(
                    "LLL"
                  )}
              </FormDescription>
            )}

            {errors?.negotiationEnd && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.negotiationEnd?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <NegotiationDetail />
      </FormControl>
    </>
  );
};

export default FormSchedule;
