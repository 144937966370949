import { Button, Checkbox, FormControl, FormLabel, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuctionContext } from "../Controller";
import CheckboxGroup from "../../../../../../../../../components/inputForm/CheckboxGroup";
import moment from "moment";
import numeral from "numeral";
import { useWatch } from "react-hook-form";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { form } = useContext(AuctionContext);
  const auctionMaterialsWatch = useWatch({ control: form.control, name: "auctionMaterials" });

  // Manually add error if Req Requisition is empty
  const handleError = () => {
    const newRequisition = auctionMaterialsWatch;
    if (!newRequisition?.length)
      form?.setError("auctionMaterials", {
        message: t("INVALID.REQUIRED", { field: t("LABEL.REQ_REQUISITION") }),
      });
    else form?.clearErrors("auctionMaterials");
  };
  // Initial error
  useEffect(() => {
    handleError();
  }, [auctionMaterialsWatch]);

  return (
    <>
      {data?.reqRequisitionMaterial?.map((material, iMaterial) => (
        <Tr key={iMaterial}>
          <Td colSpan={4}>
            <div className="flex justify-between items-center">
              <Checkbox className="m-1 w-10" value={material.reqRequisitionMaterialId} />
              <span className="flex-1">{material.material.description}</span>
              <span className="flex-1">Qty: {material.quantity}</span>
              <span className="flex-1">
                {material.currency.currency} {numeral(material.materialPrice).format("0,0.00")}
              </span>
            </div>
          </Td>
        </Tr>
      ))}
    </>
  );
};

const RequisitionMaterialTable = () => {
  const { reqRequisitions, form } = useContext(AuctionContext);
  const { t } = useTranslation();
  const { control } = form;

  return (
    <div className="w-full bg-white rounded-md text-sm col-span-2">
      <FormControl isRequired={true}>
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4 text-red-500`}>Material</FormLabel>

        <Table variant="simple">
          <Tbody>
            {!reqRequisitions.length ? (
              <Tr>
                <Td colSpan={5} className="text-center">
                  <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
                </Td>
              </Tr>
            ) : (
              <CheckboxGroup name="auctionMaterials" control={control}>
                {reqRequisitions.map((admin, iReqRequisition) => (
                  <Row key={iReqRequisition} data={admin} />
                ))}
              </CheckboxGroup>
            )}
          </Tbody>
        </Table>
      </FormControl>
    </div>
  );
};

export default RequisitionMaterialTable;
