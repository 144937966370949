import React, { useContext } from "react";
import { motion } from "framer-motion";
import { BiCrown } from "react-icons/bi";
import numeral from "numeral";
import { AuctionLiveContext } from "../Controller";
import { AuctionContext } from "../../../../Controller";

const Rank = () => {
  const { auction } = useContext(AuctionContext);
  const { bidRank, auctionStatus } = useContext(AuctionLiveContext);
  const currency = auction?.procurement?.currency;
  const winner = bidRank?.[0];

  return (
    <>
      {bidRank && (
        <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
          <div className="w-full bg-white rounded-md text-sm p-4 flex gap-4 divide-x-2 divide-gray-200">
            <div className="w-1/4 justify-center items-center flex flex-col">
              <BiCrown className="text-4xl text-yellow-500" />
              <h2 className="m-0 font-bold text-2xl text-yellow-500">
                {auctionStatus === "active" ? window.btoa(winner?.vendor).substring(0, 6) : winner?.vendor}
              </h2>
              <p className="font-semibold text-gray-600">
                {numeral(winner?.total).format("0,0.00")} {currency?.currency}
              </p>
            </div>
            <div className="flex-1 flex flex-col divide-y-2 divide-gray-100">
              {bidRank?.map((rank, iRank) => (
                <div key={iRank} className="bg-gray-50 p-3 flex gap-2 justify-between items-center">
                  <div className="w-4 mr-2 flex justify-center">
                    {iRank === 0 ? (
                      <BiCrown className="text-yellow-500" />
                    ) : (
                      <span className="font-semibold">{iRank + 1}</span>
                    )}
                  </div>
                  <span className="flex-1">
                    {auctionStatus === "active" ? window.btoa(rank?.vendor).substring(0, 6) : rank?.vendor}
                  </span>
                  <span className="flex-1 text-right font-semibold">
                    {numeral(rank?.total).format("0,0.00")} {currency?.currency}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Rank;
