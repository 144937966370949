import { Outlet } from "react-router";
import NavbarController from "./Controller";
import Navbar from "./components/Navbar";

const PublicTemplate = () => {
  return (
    <div>
      <NavbarController>
        <Navbar />
        <Outlet />
      </NavbarController>
    </div>
  );
};

export default PublicTemplate;
