import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const FinancialReportContext = createContext();
const { Provider } = FinancialReportContext;

const FinancialReportController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        reportDate: newForm?.reportDate ? new Date(newForm?.reportDate) : null,
        currency: newForm?.currency?.currency,
        isAudited: newForm?.isAudited || false,
        publicAccountant: newForm?.publicAccountant,

        currentAsset: newForm?.currentAsset || 0,
        fixedAsset: newForm?.fixedAsset || 0,
        otherAsset: newForm?.otherAsset || 0,
        currentDebt: newForm?.currentDebt || 0,
        otherDebt: newForm?.otherDebt || 0,
        paidUpCapital: newForm?.paidUpCapital || 0,
        retainedEarning: newForm?.retainedEarning || 0,
        annualIncome: newForm?.annualIncome || 0,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeFinancialReport, setActiveFinancialReport] = useState();
  const handleDetailMenu = (isOpen, financialReport = null) => {
    setDetailMenu(isOpen);
    setActiveFinancialReport(financialReport);
    isOpen && resetForm(financialReport);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // FinancialReports
  const [financialReports, setFinancialReports] = useState([]);
  const [loadingFinancialReports, setLoadingFinancialReports] = useState(false);
  const [reloadFinancialReports, setReloadFinancialReports] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getFinancialReports = async () => {
      setLoadingFinancialReports(true);
      axios
        .get(`/financial-report/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newFinancialReports = res?.data?.financialReports;
            setFinancialReports(newFinancialReports);

            if (activeFinancialReport) {
              const newActiveFinancialReport =
                newFinancialReports.find(
                  (financialReport) => financialReport?.financialReportId === activeFinancialReport?.financialReportId
                ) || null;
              setActiveFinancialReport(newActiveFinancialReport);
              resetForm(newActiveFinancialReport);
            }
          }
        })
        .finally(() => {
          setReloadFinancialReports(false);
          setLoadingFinancialReports(false);
        });
    };

    userData && token && reloadFinancialReports && getFinancialReports();

    if (reloadFinancialReports) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadFinancialReports]);

  // -------------------------------
  // Master
  // -------------------------------

  // Currency
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/currency`).then((res) => {
      if (mounted) {
        setCurrencies(res?.data?.currency);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createFinancialReport = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        reportDate: new Date(payload?.reportDate),
      })
    );
    formData.append("attachment", payload?.attachment[0]);

    axios
      .post(`financial-report/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadFinancialReports(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeFinancialReport);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateFinancialReport = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        ...(payload?.reportDate ? { reportDate: new Date(payload.reportDate) } : {}),
      })
    );
    payload?.attachment && formData.append("attachment", payload?.attachment[0]);

    axios
      .patch(`financial-report/${regCode}/${activeFinancialReport?.financialReportId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadFinancialReports(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteFinancialReport = () => {
    setLoadingDelete(true);

    axios
      .delete(`financial-report/${regCode}/${activeFinancialReport?.financialReportId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadFinancialReports(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingFinancialReports,
        financialReports,
        activeFinancialReport,

        currencies,

        isCreate,
        handleCreate,
        loadingCreate,
        createFinancialReport,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateFinancialReport,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteFinancialReport,
      }}
    >
      {children}
    </Provider>
  );
};

export default FinancialReportController;
