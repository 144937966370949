import { useContext } from 'react';
import { AuthContext } from '../../../../../../../../controllers/auth/AuthController';
import { motion } from 'framer-motion';
import { Spinner } from '@chakra-ui/react';
import { ProcurementDetailContext } from '../../../Controller';
import Prequalification from '.';
import PrequalificationEvaluation from './evaluation';

const PrequalificationSwitchIndex = () => {
	const { loadingVerify, loginLoading, userData } = useContext(AuthContext);

	const { procurement } = useContext(ProcurementDetailContext);

	if (!procurement?.procurementSetting?.prequalification || !procurement?.procurementStep?.prequalification) {
		return <div></div>;
	}

	return (
		<>
			{loadingVerify || loginLoading || !userData ? (
				<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
					<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-8 flex items-center justify-center" style={{ minHeight: '50vh' }}>
						<Spinner color="red.500" width="100px" height="100px" />
					</div>
				</motion.div>
			) : (
				<>{userData.type === 'user' ? <Prequalification /> : <PrequalificationEvaluation /> }</>
			)}
		</>
	);
};

export default PrequalificationSwitchIndex;
