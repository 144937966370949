import { Spinner } from '@chakra-ui/react';
import { useContext } from 'react';
import { ProcurementOfferEvaluationContext } from '../../Controller';
import Action from './components/Action';
import EvaluationItemTable from './components/ItemTable';
import VendorEvaluation from './components/VendorEvaluation';
import VendorOffer from './components/VendorOffer';
import EvaluationController from './Controller';

const EvaluationPage = ({ evaluationType }) => {
	const { loadingEvaluation, evaluation } = useContext(ProcurementOfferEvaluationContext);

	return (
		<div>
			{loadingEvaluation || !evaluation ? (
				<div className="flex justify-center">
					<Spinner color="red.500" />
				</div>
			) : (
				<>
					<VendorEvaluation />
					{evaluationType !== 'ADM' && (
						<>
							<VendorOffer />
							<EvaluationItemTable />
						</>
					)}
					<Action />
				</>
			)}
		</div>
	);
};

export default ({ evaluationType }) => {
	return (
		<EvaluationController evaluationType={evaluationType}>
			<EvaluationPage evaluationType={evaluationType} />
		</EvaluationController>
	);
};
