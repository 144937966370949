import { useToast } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const BusinessPermitContext = createContext();
const { Provider } = BusinessPermitContext;

const BusinessPermitController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals, companyOverview } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      console.log(newForm)
      form.reset({
        businessPermitType: newForm?.businessPermitTypeForeignKey?.businessPermitType ? newForm?.businessPermitTypeForeignKey?.businessPermitType : newForm?.businessPermitType,
        businessPermitNumber: newForm?.businessPermitNumber,
        businessGroup: newForm?.businessGroup,
        validFrom: newForm?.validFrom ? new Date(newForm?.validFrom) : null,
        validUntil: newForm?.validUntil ? new Date(newForm?.validUntil) : null,
        issuedBy: newForm?.issuedBy,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeBusinessPermit, setActiveBusinessPermit] = useState();
  const handleDetailMenu = (isOpen, businessPermit = null) => {
    setDetailMenu(isOpen);
    setActiveBusinessPermit(businessPermit);
    isOpen && resetForm(businessPermit);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // BusinessPermits
  const [businessPermits, setBusinessPermits] = useState([]);
  const [loadingBusinessPermits, setLoadingBusinessPermits] = useState(false);
  const [reloadBusinessPermits, setReloadBusinessPermits] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBusinessPermits = async () => {
      setLoadingBusinessPermits(true);
      axios
        .get(`/business-permit/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newBusinessPermits = res?.data?.businessPermits;
            setBusinessPermits(newBusinessPermits);

            if (activeBusinessPermit) {
              const newActiveBusinessPermit =
                newBusinessPermits.find(
                  (businessPermit) => businessPermit?.businessPermitId === activeBusinessPermit?.businessPermitId
                ) || null;
              setActiveBusinessPermit(newActiveBusinessPermit);
              resetForm(newActiveBusinessPermit);
            }
          }
        })
        .finally(() => {
          setReloadBusinessPermits(false);
          setLoadingBusinessPermits(false);
        });
    };

    userData && token && reloadBusinessPermits && getBusinessPermits();

    if (reloadBusinessPermits) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadBusinessPermits]);

  // -------------------------------
  // Master
  // -------------------------------

  // Countries
  const [businessPermitTypes, setBusinessPermitTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/business-permit-type`).then((res) => {
      if (mounted) {
        setBusinessPermitTypes(res?.data?.businessPermitType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createBusinessPermit = (payload) => {
    
    if (companyOverview?.country === "Indonesia" && (!payload?.businessPermitType || !payload?.businessGroup)) {
      toast({
        title: "System Error",
        description: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_PERMIT_TYPE") }) + " & " + t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_GROUP") }),
        position: "top-right",
        status: "error",
        isClosable: true,
      });
    } else {
      setLoadingCreate(true);
      
      const formData = new FormData();
      formData.append(
        "payload",
        JSON.stringify({
          ...payload,
          validFrom: moment(payload?.validFrom).format("MM/DD/YYYY"),
          validUntil:
            payload?.businessPermitType !== "NIB" ? moment(payload?.validUntil).format("MM/DD/YYYY") : undefined,
        })
      );
      formData.append("attachment", payload?.attachment[0]);

      axios
        .post(`business-permit/${regCode}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
          setCreate(false);
          setReloadBusinessPermits(true);
        })
        .catch(() => {
          toast({
            title: "System Error",
            description: t("ERROR.undefined"),
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          setLoadingCreate(false);
        });
    } 
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeBusinessPermit);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateBusinessPermit = (payload) => {

    if (companyOverview?.country === "Indonesia" && (!payload?.businessPermitType || !payload?.businessGroup)) {
      toast({
        title: "System Error",
        description: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_PERMIT_TYPE") }) + "&" + t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_GROUP") }),
        position: "top-right",
        status: "error",
        isClosable: true,
      });
    } else {
      setLoadingUpdate(true);

      const formData = new FormData();
      formData.append(
        "payload",
        JSON.stringify({
          ...payload,
          validFrom: moment(payload?.validFrom).format("MM/DD/YYYY"),
          validUntil: moment(payload?.validUntil).format("MM/DD/YYYY"),
        })
      );
      payload?.attachment && formData.append("attachment", payload?.attachment[0]);

      axios
        .patch(`business-permit/${regCode}/${activeBusinessPermit?.businessPermitId}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
          setEdit(false);
          setReloadBusinessPermits(true);
        })
        .catch(() => {
          toast({
            title: "System Error",
            description: t("ERROR.undefined"),
            position: "top-right",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          setLoadingUpdate(false);
        });
    }
    
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteBusinessPermit = () => {
    setLoadingDelete(true);

    axios
      .delete(`business-permit/${regCode}/${activeBusinessPermit?.businessPermitId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadBusinessPermits(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingBusinessPermits,
        businessPermits,
        activeBusinessPermit,

        businessPermitTypes,

        isCreate,
        handleCreate,
        loadingCreate,
        createBusinessPermit,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateBusinessPermit,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteBusinessPermit,
      }}
    >
      {children}
    </Provider>
  );
};

export default BusinessPermitController;
