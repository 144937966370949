import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import BODDetail from "./components/BODDetail";
import BODList from "./components/BODList";
import BODNew from "./components/BODNew";
import BODController, { BODContext } from "./Controller";

const BOD = () => {
  const { isDetailMenu, isCreate } = useContext(BODContext);

  return (
    <div>
      <AnimatePresence>{isCreate ? <BODNew /> : isDetailMenu ? <BODDetail /> : <BODList />}</AnimatePresence>
    </div>
  );
};

export default () => (
  <BODController>
    <BOD />
  </BODController>
);
