import React, { useContext } from "react";
import { motion } from "framer-motion";
import NegotiationListMaterialTable from "./components/NegotiationListMaterialTable";
import NegotiationController, { NegotiationContext } from "./Controller";
import NegotiationNew from "./components/NegotiationNew";
import FormNotes from "./components/Form/FormNotes";
import ModalPropose from "./components/Modal/ModalPropose";
import ModalApprove from "./components/Modal/ModalApprove";
import ModalReject from "./components/Modal/ModalReject";
import NegotiationListVendorTable from "./components/NegotiationListVendorTable";
import NegotiationBid from "./components/NegotiationBid";
import { ProcurementDetailContext } from "../../../Controller";

const Negotiation = () => {
  const { isNegotiationNew, isNotesNew, isMakeBid } =
    useContext(NegotiationContext);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
    >
      {isMakeBid ? (
        <NegotiationBid />
      ) : isNotesNew ? (
        <FormNotes />
      ) : isNegotiationNew ? (
        <NegotiationNew />
      ) : (
        <div className="flex flex-col gap-6">
          <NegotiationListMaterialTable />
          <NegotiationListVendorTable />
        </div>
      )}
      <ModalPropose />
      <ModalApprove />
      <ModalReject />
    </motion.div>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.negotiation){
    return <div></div>
  }

  return (
    <NegotiationController>
      <Negotiation />
    </NegotiationController>
  )
};
