import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const BankAccountContext = createContext();
const { Provider } = BankAccountContext;

const BankAccountController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        bank: newForm?.bankForeignKey?.bankCode ? newForm?.bankForeignKey?.bankCode : newForm?.bank,
        currency: newForm?.currency?.currency,
        accountHolderName: newForm?.accountHolderName,
        accountNumber: newForm?.accountNumber,
        bankAddress: newForm?.bankAddress,
        swiftCode: newForm?.swiftCode ? newForm?.swiftCode : ""
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeBankAccount, setActiveBankAccount] = useState();
  const handleDetailMenu = (isOpen, bankAccount = null) => {
    setDetailMenu(isOpen);
    setActiveBankAccount(bankAccount);
    isOpen && resetForm(bankAccount);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // BankAccounts
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loadingBankAccounts, setLoadingBankAccounts] = useState(false);
  const [reloadBankAccounts, setReloadBankAccounts] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBankAccounts = async () => {
      setLoadingBankAccounts(true);
      axios
        .get(`/bank-account/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newBankAccounts = res?.data?.bankAccounts;
            setBankAccounts(newBankAccounts);

            if (activeBankAccount) {
              const newActiveBankAccount =
                newBankAccounts.find(
                  (bankAccount) => bankAccount?.bankAccountId === activeBankAccount?.bankAccountId
                ) || null;
              setActiveBankAccount(newActiveBankAccount);
              resetForm(newActiveBankAccount);
            }
          }
        })
        .finally(() => {
          setReloadBankAccounts(false);
          setLoadingBankAccounts(false);
        });
    };

    userData && token && reloadBankAccounts && getBankAccounts();

    if (reloadBankAccounts) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadBankAccounts]);

  // -------------------------------
  // Master
  // -------------------------------

  // Bank
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/bank`).then((res) => {
      if (mounted) {
        setBanks(res?.data?.bank);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Currency
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/currency`).then((res) => {
      if (mounted) {
        setCurrencies(res?.data?.currency);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createBankAccount = (payload) => {
    setLoadingCreate(true);

    // console.log(payload)
    axios
      .post(
        `bank-account/${regCode}`,
        { payload },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadBankAccounts(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeBankAccount);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateBankAccount = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(
        `bank-account/${regCode}/${activeBankAccount?.bankAccountId}`,
        { payload },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadBankAccounts(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteBankAccount = () => {
    setLoadingDelete(true);

    axios
      .delete(`bank-account/${regCode}/${activeBankAccount?.bankAccountId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadBankAccounts(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingBankAccounts,
        bankAccounts,
        activeBankAccount,

        banks,
        currencies,

        isCreate,
        handleCreate,
        loadingCreate,
        createBankAccount,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateBankAccount,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteBankAccount,
      }}
    >
      {children}
    </Provider>
  );
};

export default BankAccountController;
