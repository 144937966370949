import { useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/auth/AuthController";

export const AdminContext = createContext();
const { Provider } = AdminContext;

const AdminController = ({ children }) => {
  const { token } = useContext(AuthContext);

  // ---------------------------
  // Vendor Approval
  // ---------------------------

  const [companiesToApprove, setCompaniesToApprove] = useState([]);
  const [loadingCompaniesToApprove, setLoadingCompaniesToApprove] = useState(false);
  const [reloadCompaniesToApprove, setReloadCompaniesToApprove] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getCompaniesToApprove = async () => {
      setLoadingCompaniesToApprove(true);
      axios
        .get(`/vendor-approval/company`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newCompaniesToApprove = res?.data?.companies;
            setCompaniesToApprove(newCompaniesToApprove);
          }
        })
        .finally(() => {
          setReloadCompaniesToApprove(false);
          setLoadingCompaniesToApprove(false);
        });
    };

    token && reloadCompaniesToApprove && getCompaniesToApprove();

    return () => {
      mounted = false;
    };
  }, [token, reloadCompaniesToApprove]);

  return <Provider value={{ companiesToApprove, loadingCompaniesToApprove }}>{children}</Provider>;
};

export default AdminController;
