import { Table, Tbody, Td, Th, Thead, Tr, Spinner, Button } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { CommercialEvaluationContext } from '../Controller';
import { BiCheck, BiX } from 'react-icons/bi';
import { API_URLS } from '../../../../../../../../../../../../utils/apiURLs.util';
import { useTranslation } from 'react-i18next';
import { ProcurementOfferEvaluationContext } from '../../../Controller';
import { HelperContext } from '../../../../../../../../../../../../controllers/HelperController';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../../../utils/offerPhases.util';
import FileAttachment from '../../../../../../../../../../../../components/FileAttachment';
import PrivateComponent from '../../../../../../../../../../../../components/PrivateComponent';

const OfferRequirementEvaluation = () => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { offer, setOffer, requirements, loadingRequirements } = useContext(CommercialEvaluationContext);
	const { evaluation, is2Cover, offerEvaluationPhase } = useContext(ProcurementOfferEvaluationContext);

	const [loadingAccept, setLoadingAccept] = useState(null);
	const [loadingReject, setLoadingReject] = useState(null);

	useEffect(async () => {
		if (!requirements) return;
		const len = requirements.length;
		let temp = [];
		for (let i = 0; i < len; i++) temp.push(false);
		setLoadingAccept([...temp]);
		setLoadingReject([...temp]);
	}, [requirements]);

	const alreadySubmitted = (procurementCommercialOfferId, procurementRequirementId) => {
		for (let i = 0; i < offer.procurementCommercialOfferRequirements.length; i++) {
			const req = offer.procurementCommercialOfferRequirements[i];
			if (req.procurementCommercialOfferId === procurementCommercialOfferId && req.procurementRequirementId === procurementRequirementId) {
				return i;
			}
		}

		return null;
	};

	const sendEvaluation = async (idxExist, idx, status) => {
		const id = offer.procurementCommercialOfferRequirements[idxExist].id;
		const payload = { status };
		const uri = `${API_URLS.REACT_APP_TMS_API}/procurement-commercial-offer-requirement/status/${id}`;

		if (status === 'ACCEPTED') {
			loadingAccept[idx] = true;
			setLoadingAccept([...loadingAccept]);
		} else {
			loadingReject[idx] = true;
			setLoadingReject([...loadingReject]);
		}
		const { response } = await httpRequest({
			url: uri,
			method: 'PATCH',
			payload,
		});
		if (status === 'ACCEPTED') {
			loadingAccept[idx] = false;
			setLoadingAccept([...loadingAccept]);
		} else {
			loadingReject[idx] = false;
			setLoadingReject([...loadingReject]);
		}
		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		offer.procurementCommercialOfferRequirements[idxExist] = response.data.procurementCommercialOfferRequirement;
		setOffer({
			...offer,
		});
	};

	const statusKey = is2Cover ? 'status' : 'statusCommercial';

	return (
		<div className="mt-5">
			<TitleBar title={t('LABEL.PROCUREMENT_REQUIREMENT')} />
			<Table>
				<Thead>
					<Tr>
						<Th className="text-red-500 pr-1">No.</Th>
						<Th className="text-red-500 w-1/3">{t('LABEL.DESCRIPTION')}</Th>
						<Th className="text-red-500 text-center px-1">{t('LABEL.PROCUREMENT_REQUIREMENT_REQUIRED')}</Th>
						<Th className="text-red-500 text-center">{t('LABEL.DOCUMENT')}</Th>
						<Th className="text-red-500 text-center">{t('LABEL.ACTION')}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loadingRequirements && (
						<Tr>
							<Td colSpan={5}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					)}

					{!loadingRequirements && requirements && loadingAccept && loadingReject && (
						<>
							{requirements.length === 0 ? (
								<>
									<Tr>
										<Td colSpan={5} className="text-center">
											<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
										</Td>
									</Tr>
								</>
							) : (
								<>
									{requirements.map((req, idx) => {
										const idxExist = offer === null ? null : alreadySubmitted(offer.id, req.procurementRequirementId);
										return (
											<Tr key={idx}>
												<Td className="w-4 pr-1 text-center">{idx + 1}</Td>
												<Td className="w-1/3">{req.description}</Td>
												<Td className='text-center px-1'>{req.isRequired ? t('LABEL.YES') : t('LABEL.NO')}</Td>
												<Td className='text-center'>
													{idxExist === null ? (
														<>
															<div className="text-color0">{t('LABEL.NOT_SUBMITTED')}</div>
														</>
													) : (
                            <div className='flex justify-center'>
                              <FileAttachment href={`${API_URLS.REACT_APP_FILESERVICE_API}/files/users/${offer.procurementCommercialOfferRequirements[idxExist].documentFilename}`} />
                            </div>
													)}
												</Td>
												<Td className='px-1'>
													{idxExist !== null && (
														<div className="flex justify-center items-center gap-3">
															{evaluation && evaluation[statusKey] === 'SUBMITTED' ? (
																<>
																	{offer.procurementCommercialOfferRequirements[idxExist].status === 'ACCEPTED' && (
																		<div className="text-green-500 font-semibold text-xl mt-auto mb-auto italic">{t('LABEL.QUALIFIED')}</div>
																	)}
																	{(offer.procurementCommercialOfferRequirements[idxExist].status === 'REJECTED' || offer.procurementCommercialOfferRequirements[idxExist].status === 'PENDING') && (
																		<div className="text-color0 font-semibold text-xl mt-auto mb-auto italic">{t('LABEL.FAIL')}</div>
																	)}
																</>
															) : (
																<>
																	{offerEvaluationPhase === OFFER_2_COVER_PHASES.POST ? (
																		<div></div>
																	) : (
																		<PrivateComponent accessName="BUYER_EDIT" placeholder={<div className=' mt-auto mb-auto text-color2 font-semibold italic'>Pending</div>}>
																			<Button
																				color={offer.procurementCommercialOfferRequirements[idxExist].status === 'ACCEPTED' ? 'white' : 'green'}
																				className={`transition-all duration-500 flex gap-1 items-center justify-center ${
																					offer.procurementCommercialOfferRequirements[idxExist].status === 'ACCEPTED' ? 'bg-green-500' : ''
																				}`}
																				onClick={() => {
																					if (offer.procurementCommercialOfferRequirements[idxExist].status === 'ACCEPTED') return;
																					sendEvaluation(idxExist, idx, 'ACCEPTED');
																				}}
																				disabled={loadingReject[idx]}
																				isLoading={loadingAccept[idx]}
																			>
																				<BiCheck className="text-1xl" />
																				{t('LABEL.QUALIFIED')}
																			</Button>
																			<Button
																				color={offer.procurementCommercialOfferRequirements[idxExist].status === 'REJECTED' ? 'white' : 'red'}
																				className={`transition-all duration-500 flex gap-1 items-center justify-center ${
																					offer.procurementCommercialOfferRequirements[idxExist].status === 'REJECTED' ? 'bg-color0' : ''
																				}`}
																				onClick={() => {
																					if (offer.procurementCommercialOfferRequirements[idxExist].status === 'REJECTED') return;
																					sendEvaluation(idxExist, idx, 'REJECTED');
																				}}
																				disabled={loadingAccept[idx]}
																				isLoading={loadingReject[idx]}
																			>
																				<BiX className="text-1xl" />
																				{t('LABEL.FAIL')}
																			</Button>
																		</PrivateComponent>
																	)}
																</>
															)}
														</div>
													)}
												</Td>
											</Tr>
										);
									})}
								</>
							)}
						</>
					)}
				</Tbody>
			</Table>
		</div>
	);
};

export default OfferRequirementEvaluation;
