import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import UserDetail from "./components/UserDetail";
import UserList from "./components/UserList";
import UserNew from "./components/UserNew";
import UserController, { UserContext } from "./Controller";

const User = () => {
  const { isDetailMenu, isCreate } = useContext(UserContext);

  return (
    <div>
      <AnimatePresence>{isCreate ? <UserNew /> : isDetailMenu ? <UserDetail /> : <UserList />}</AnimatePresence>
    </div>
  );
};

export default () => (
  <UserController>
    <User />
  </UserController>
);
