import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const BODContext = createContext();
const { Provider } = BODContext;

const BODController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        bodType: newForm?.bodType?.bodType,
        bodName: newForm?.bodName,
        citizenship: newForm?.citizenship?.regionCode,
        bodPosition: newForm?.position,
        isOwner: newForm?.isOwner === true,
        taxNumber: newForm?.taxNumber,
        idCardNumber: newForm?.idCardNumber,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeBOD, setActiveBOD] = useState();
  const handleDetailMenu = (isOpen, bod = null) => {
    setDetailMenu(isOpen);
    setActiveBOD(bod);
    isOpen && resetForm(bod);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // BODs
  const [bods, setBODs] = useState([]);
  const [loadingBODs, setLoadingBODs] = useState(false);
  const [reloadBODs, setReloadBODs] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBODs = async () => {
      setLoadingBODs(true);
      axios
        .get(`/bod/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newBODs = res?.data?.bods;
            setBODs(newBODs);

            if (activeBOD) {
              const newActiveBod = newBODs.find((bod) => bod?.bodId === activeBOD?.bodId) || null;
              setActiveBOD(newActiveBod);
              resetForm(newActiveBod);
            }
          }
        })
        .finally(() => {
          setReloadBODs(false);
          setLoadingBODs(false);
        });
    };

    userData && token && reloadBODs && getBODs();

    if (reloadBODs) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadBODs]);

  // -------------------------------
  // Master
  // -------------------------------

  // Shareholder
  const [shareholders, setShareholders] = useState([]);
  useEffect(() => {
    let mounted = true;

    const getShareholders = async () => {
      axios
        .get(`/shareholder/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newShareholders = res?.data?.shareholders;
            setShareholders(newShareholders);
          }
        });
    };

    userData && token && getShareholders();

    return () => {
      mounted = false;
    };
  }, [userData, token]);

  // Countries
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/regions`).then((res) => {
      if (mounted) {
        setCountries(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // BOD Types
  const [bodTypes, setbodTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/bod-type`).then((res) => {
      if (mounted) {
        setbodTypes(res?.data?.bodType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createBOD = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    payload?.taxAttachment && formData.append("taxAttachment", payload?.taxAttachment[0]);
    formData.append("idCardAttachment", payload?.idCardAttachment[0]);

    axios
      .post(`bod/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadBODs(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeBOD);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateBOD = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    payload?.taxAttachment && formData.append("taxAttachment", payload?.taxAttachment[0]);
    payload?.idCardAttachment && formData.append("idCardAttachment", payload?.idCardAttachment[0]);

    axios
      .patch(`bod/${regCode}/${activeBOD?.bodId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadBODs(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteBOD = () => {
    setLoadingDelete(true);

    axios
      .delete(`bod/${regCode}/${activeBOD?.bodId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadBODs(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  // -------------------------------
  // Star Data
  // -------------------------------

  const [isModalStarOpen, setModalStarOpen] = useState(false);
  const [loadingStar, setLoadingStar] = useState(false);
  const starBOD = () => {
    setLoadingStar(true);

    axios
      .patch(
        `bod/star/${regCode}/${activeBOD?.bodId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadBODs(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingStar(false);
        setModalStarOpen(false);
      });
  };

  return (
    <Provider
      value={{
        form,
        loadingBODs,
        bods,
        activeBOD,

        shareholders,
        countries,
        bodTypes,

        isCreate,
        handleCreate,
        loadingCreate,
        createBOD,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateBOD,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteBOD,

        isModalStarOpen,
        setModalStarOpen,
        loadingStar,
        starBOD,
      }}
    >
      {children}
    </Provider>
  );
};

export default BODController;
