import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelperContext } from '../../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../../../Controller';
import { ProcurementOfferEvaluationContext } from '../../Controller';

export const CommercialEvaluationContext = createContext();
const { Provider } = CommercialEvaluationContext;

const CommercialEvaluationController = ({ children }) => {
	const { t } = useTranslation();
	const { procurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);
	const { commercialOffers, handleDetailCommercialIdx } = useContext(ProcurementOfferEvaluationContext);

	const [offer, setOffer] = useState(null);
	const [requirements, setRequirements] = useState(null);
	const [loadingRequirements, setLoadingRequirements] = useState(null);

	useEffect(async () => {
		setRequirements(null);
		if (!procurement) return;
		let mounted = true;

		setLoadingRequirements(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-requirement/${procurement.procurementNumber}`,
		});
		if (!mounted) return;
		setLoadingRequirements(false);
		if (!response?.procurementRequirement) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		const requirements = response.procurementRequirement.filter((req) => req.requirementProcurementCover === 'KOMERSIAL');
		setRequirements(requirements);
	}, [procurement]);

	useEffect(() => {
		if (!commercialOffers || handleDetailCommercialIdx === null) {
			setOffer(null);
			return;
		}
		setOffer(commercialOffers[handleDetailCommercialIdx]);
	}, [commercialOffers, handleDetailCommercialIdx]);

	return <Provider value={{ offer, setOffer, requirements, setRequirements, loadingRequirements, setLoadingRequirements }}>{children}</Provider>;
};

export default CommercialEvaluationController;
