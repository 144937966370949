export const filterBuilder = (filters = {}) => {
	let query = '';
	const keys = Object.keys(filters);

	keys.forEach((key, idx) => {
		const value = filters[key];
		let filterValue = '';
		if (Array.isArray(value)) {
			if (value.length === 0) return;
			value.forEach((val, idx) => (filterValue += idx !== value.length - 1 ? `${val},` : val));
		} else {
			filterValue += value;
		}
		if (idx === 0) query += `${key}=${filterValue}`;
		else query += `&${key}=${filterValue}`;
	});

	return query;
};
