import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { RequisitionerContext } from "../Controller";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import PrivateComponent from "../../../../../components/PrivateComponent";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { handleDetailMenu } = useContext(RequisitionerContext);

  return (
    <Tr className="hover:bg-gray-50 cursor-pointer" onClick={() => handleDetailMenu(true, data)}>
      <Td>{data?.requisitionerCode}</Td>
      <Td>{data?.description}</Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const RequisitionerTable = () => {
  const { requisitioner, loadingRequisition, totalData, limit, tableForm, handleCreate } =
    useContext(RequisitionerContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <PrivateComponent accessName="BUYER_EDIT">
        <div className="mb-2 flex justify-end">
          <Button size="sm" onClick={() => handleCreate(true)}>
            <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: t("LABEL.REQUISITIONER") })}
          </Button>
        </div>
      </PrivateComponent>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.REQUISITIONER_CODE")}</Th>
            <Th className="text-red-500">{t("LABEL.DESCRIPTION")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingRequisition ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !requisitioner.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            requisitioner.map((requisitioner, iRequisitioner) => <Row key={iRequisitioner} data={requisitioner} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default RequisitionerTable;
