import { useTranslation } from "react-i18next";
import PrivateComponent from "../../../components/PrivateComponent";
import CompanyToApprove from "./components/admin/CompanyToApprove";
import AdminController from "./controllers/AdminController";
import ApplicantFirstStep from "./components/vendors/ApplicantFirstStep";
import CandidateWaitApproval from "./components/vendors/CandidateWaitApproval";
import DashboardController from "./controllers/DashboardController";
import VendorDashboard from "./components/vendors/VendorDashboard";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.DASHBOARD")}</h2>

      {/* Applicant */}
      <PrivateComponent accessName="APPLICANT_FIRST_STEPS">
        <ApplicantFirstStep />
      </PrivateComponent>

      {/* Candidate */}
      <PrivateComponent accessName="CANDIDATE_WAIT_APPROVAL">
        <CandidateWaitApproval />
      </PrivateComponent>

      {/* Vendor */}
      <PrivateComponent accessName="VENDOR_DASHBOARD">
        <VendorDashboard />
      </PrivateComponent>

      {/* Admin */}
      <PrivateComponent accessName="ADMIN_COMPANY_TO_APPROVE">
        <AdminController>
          <CompanyToApprove />
        </AdminController>
      </PrivateComponent>
    </div>
  );
};

export default () => (
  <DashboardController>
    <Dashboard />
  </DashboardController>
);
