import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";

const ClarificationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { prequalificationForm, schedules } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {schedules[0]?.tenderEvaluationStart ? (
        <>
          {/* Clarification */}
          <hr className="md:col-span-2" />
          <FormControl className="md:col-span-2">
            <FormLabel
              className={`uppercase font-bold text-sm text-red-500 mb-4`}
            >
              {t("LABEL.PROCUREMENT_KLARIFIKASI")}
            </FormLabel>

            <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
              {/* Start Clarification */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_START", {
                    field: t("LABEL.PROCUREMENT_KLARIFIKASI"),
                  })}
                </FormLabel>

                <FormDescription>
                  {schedules[0]?.clarificationStart &&
                    moment(new Date(schedules[0]?.clarificationStart)).format(
                      "LLL"
                    )}
                </FormDescription>

                {errors?.clarificationStart && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.clarificationStart?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* End Clarification */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_END", {
                    field: t("LABEL.PROCUREMENT_KLARIFIKASI"),
                  })}
                </FormLabel>

                <FormDescription>
                  {schedules[0]?.clarificationEnd &&
                    moment(new Date(schedules[0]?.clarificationEnd)).format(
                      "LLL"
                    )}
                </FormDescription>

                {errors?.clarificationEnd && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.clarificationEnd?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </FormControl>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ClarificationForm;
