import Logo from "../../../../assets/img/static/logo/logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import { BiMenu } from "react-icons/bi";
import { useContext } from "react";
import { NavbarContext } from "../Controller";
import NavbarItem from "./NavbarItem";
import MobileSidebar from "./MobileSidebar";
import { publicRouteSlug } from "../../../../routes/publicRoutes";
import TranslationHandler from "../../../../components/TranslationHandler";
import { AuthContext } from "../../../../controllers/auth/AuthController";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { AiFillCaretDown } from "react-icons/ai";
import { userRouteSlug } from "../../../../routes/userRoutes";

const Navbar = () => {
  const { t } = useTranslation();
  const { isLogin, userData, logout } = useContext(AuthContext);
  const { navbarItems, setOpenMobileSidebar } = useContext(NavbarContext);

  return (
    <div className="fixed w-full z-10">
      <div className="bg-white p-4 md:p-4 px-8 md:px-20 flex justify-between items-center text-gray-800">
        <div className="flex items-center gap-x-8">
          <div>
            <Link to={publicRouteSlug.HOME} className="py-1">
              <img src={Logo} className="w-40" />
            </Link>
          </div>
          <div className="hidden md:inline">
            <TranslationHandler />
          </div>
        </div>

        {/* Desktop Version */}
        <div className="hidden lg:flex gap-x-8 items-center">
          {navbarItems.map((item, iItem) => (
            <NavbarItem key={iItem} item={item} />
          ))}
          <div className="h-8" style={{ borderLeft: "1px solid #ccc" }} />
          {isLogin ? (
            // If signed in, show user menu
            <>
              <NavbarItem
                item={{
                  label: t("TEXT.ENTER_PANEL"),
                  path: userRouteSlug.PANEL,
                }}
                className="font-semibold text-red-500"
              />
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<AiFillCaretDown className="text-xs" />}
                  className="bg-transparent shadow-none text-gray-600"
                >
                  {userData?.name}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => logout()}>{t("LABEL.LOGOUT")}</MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            // If not signed in, show login and register button
            <>
              <NavbarItem item={{ label: t("LABEL.LOGIN"), path: publicRouteSlug.LOGIN }} />
              <Link to={publicRouteSlug.REGISTER}>
                <Button
                  color="white"
                  className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
                >
                  {t("LABEL.REGISTER")}
                </Button>
              </Link>
            </>
          )}
        </div>

        {/* Mobile Version */}
        <div className="lg:hidden">
          <Button
            className="text-gray-600 p-1"
            bgColor="white"
            border="1px solid #bbb"
            onClick={() => setOpenMobileSidebar(true)}
          >
            <BiMenu className="text-xl" />
          </Button>
          <MobileSidebar />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
