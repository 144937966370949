import { Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiError } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AuthContext } from "../../controllers/auth/AuthController";
import { userRouteSlug } from "../../routes/userRoutes";
import ModalConfirmSubmitApproval from "./ModalConfirmSubmitApproval";

const SubmitApplicantApproval = ({ showCompleteDataButton = false }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { userData, token, refetchToken } = useContext(AuthContext);

  // Modal Confirm
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Reject Message
  const [rejectMessage, setRejectMessage] = useState();
  useEffect(() => {
    let mounted = true;

    const getRejectMessage = () => {
      axios
        .get(`/vendor-approval/reject-message/${userData?.company?.registrationCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted && res?.data?.rejectMessage) setRejectMessage(res?.data?.rejectMessage);
        });
    };

    token && userData?.type === "user" && getRejectMessage();

    return () => {
      mounted = false;
    };
  }, [userData, token]);

  // Submit Approval
  const submit = () => {
    setLoading(true);

    axios
      .post(
        `/vendor-approval/submit-approval/${userData?.company?.registrationCode}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        refetchToken();
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  return (
    <div className="p-4 bg-white rounded-md">
      <div className="flex justify-between items-center gap-4">
        {rejectMessage ? (
          <div className="flex-1">
            <p className="font-semibold text-gray-600 flex items-center gap-2">
              <BiError className="text-yellow-500" />
              {t("TEXT.DATA_REJECTED")}
            </p>
            <p className="text-xs p-2 bg-gray-100 rounded-md">{rejectMessage}</p>
          </div>
        ) : (
          <p className="font-semibold text-gray-600">{t("TEXT.SUBMIT_YOUR_PROFILE")}</p>
        )}
        <div className="flex flex-col">
          {rejectMessage && showCompleteDataButton && (
            <Link to={`${userRouteSlug.PANEL}${userRouteSlug.COMPANY_PROFILE}/general`} className="mb-3">
              <Button className="w-full" size="sm">
                {t("TEXT.COMPLETE_DATA")}
              </Button>
            </Link>
          )}
          <Button
            size="sm"
            color="white"
            className=" transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
            onClick={() => setOpen(true)}
          >
            {t("TEXT.SUBMIT_APPROVAL")}
          </Button>
        </div>
      </div>

      <ModalConfirmSubmitApproval isOpen={isOpen} setOpen={setOpen} loading={loading} submit={submit} />
    </div>
  );
};

export default SubmitApplicantApproval;
