import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import id from "./id.json";

export const languages = [
  {
    name: "id",
    nativeName: "Bahasa",
    countryCode: "id",
  },
  {
    name: "en",
    nativeName: "English",
    countryCode: "us",
  },
];

i18n.use(initReactI18next).init({
  fallbackLng: "id",
  resources: {
    id: { translation: id },
    en: { translation: en },
  },
});

export default i18n;
