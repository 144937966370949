import React, { useContext } from "react";
import { AuthContext } from "../../controllers/auth/AuthController";

const PrivateComponent = ({ accessName, doHasAccess = true, children, placeholder }) => {
  const { access } = useContext(AuthContext);

  // Show the component if user has specific access
  const isShown = doHasAccess ? access?.includes(accessName) : !access?.includes(accessName);

  return <>{isShown ? children : placeholder}</>;
};

export default PrivateComponent;
