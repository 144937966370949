import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BODContext } from "../Controller";
import Form from "./Form";

const BODNew = () => {
  const { t } = useTranslation();
  const { form, handleCreate, loadingCreate, createBOD } = useContext(BODContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <div className="md:col-span-2 flex justify-between items-center gap-2">
          <button onClick={() => handleCreate(false)}>
            <BsArrowLeftCircle className="text-xl text-gray-600" />
          </button>
          <Button size="xs" colorScheme="teal" isLoading={loadingCreate} onClick={form.handleSubmit(createBOD)}>
            {t("LABEL.SAVE")}
          </Button>
        </div>

        <Form isEdit={true} />
      </div>
    </motion.div>
  );
};

export default BODNew;
