import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompetencyContext } from "../Controller";

const ModalStar = () => {
  const { t } = useTranslation();
  const { isModalStarOpen, setModalStarOpen, loadingStar, starCompetency } = useContext(CompetencyContext);

  return (
    <Modal onClose={() => setModalStarOpen(false)} size="md" isOpen={isModalStarOpen}>
      <ModalOverlay />
      <ModalContent className=" bg-opacity-90">
        <ModalHeader>{t("TEXT.CHANGE_STATUS", { field: t("LABEL.COMPETENCY") })}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.CHANGE_STATUS_CONFIRMATION", { field: t("LABEL.COMPETENCY") })}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => setModalStarOpen(false)}>{t("LABEL.CANCEL")}</Button>
          <Button isLoading={loadingStar} onClick={starCompetency} colorScheme="green">
            {t("LABEL.YES")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalStar;
