import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import ExperienceDetail from "./components/ExperienceDetail";
import ExperienceList from "./components/ExperienceList";
import ExperienceNew from "./components/ExperienceNew";
import ExperienceController, { ExperienceContext } from "./Controller";

const Experience = () => {
  const { isDetailMenu, isCreate } = useContext(ExperienceContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <ExperienceNew /> : isDetailMenu ? <ExperienceDetail /> : <ExperienceList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <ExperienceController>
    <Experience />
  </ExperienceController>
);
