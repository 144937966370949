import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import ParticipantController from './Controller';
import ParticipantTable from './components/ParticipantTable';
import { ProcurementDetailContext } from '../../../Controller';

const Participant = () => {
	return (
		<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
			<ParticipantTable />
		</motion.div>
	);
};

export default () => {
	const { procurement } = useContext(ProcurementDetailContext);
	if (!procurement?.procurementStep?.participant) {
		return <div></div>;
	}

	return (
		<ParticipantController>
			<Participant />
		</ParticipantController>
	);
};
