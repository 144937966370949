import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const ExperienceContext = createContext();
const { Provider } = ExperienceContext;

const ExperienceController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        businessField: newForm?.businessField?.isicCode,
        subBusinessField: newForm?.subBusinessField?.isicCode,
        jobName: newForm?.jobName,
        jobLocation: newForm?.jobLocation,
        jobAssignor: newForm?.jobAssignor,

        address: newForm?.address,
        country: newForm?.country?.regionCode,
        province: newForm?.provinceObj ? newForm?.provinceObj?.regionCode : newForm?.province,
        city: newForm?.cityObj ? newForm?.cityObj?.regionCode : newForm?.city,
        district: newForm?.districtObj ? newForm?.districtObj?.regionCode : newForm?.district,
        postalCode: newForm?.postalCode,

        procContractNumber: newForm.procContractNumber,
        validFrom: newForm?.validFrom ? new Date(newForm?.validFrom) : null,
        validUntil: newForm?.validUntil ? new Date(newForm?.validUntil) : null,
        currency: newForm?.currency?.currency,
        contractValue: newForm.contractValue || 0,

        mhoDate: newForm?.mhoDate ? new Date(newForm?.mhoDate) : null,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeExperience, setActiveExperience] = useState();
  const handleDetailMenu = (isOpen, experience = null) => {
    setDetailMenu(isOpen);
    setActiveExperience(experience);
    isOpen && resetForm(experience);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Experiences
  const [experiences, setExperiences] = useState([]);
  const [loadingExperiences, setLoadingExperiences] = useState(false);
  const [reloadExperiences, setReloadExperiences] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getExperiences = async () => {
      setLoadingExperiences(true);
      axios
        .get(`/experience/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newExperiences = res?.data?.experiences;
            setExperiences(newExperiences);

            if (activeExperience) {
              const newActiveExperience =
                newExperiences.find((experience) => experience?.experienceId === activeExperience?.experienceId) ||
                null;
              setActiveExperience(newActiveExperience);
              resetForm(newActiveExperience);
            }
          }
        })
        .finally(() => {
          setReloadExperiences(false);
          setLoadingExperiences(false);
        });
    };

    userData && token && reloadExperiences && getExperiences();

    if (reloadExperiences) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadExperiences]);

  // -------------------------------
  // Master
  // -------------------------------

  // ISIC / KBLI
  const [isic, setIsic] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get("/isic").then((res) => {
      if (mounted) {
        setIsic(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Sub ISIC / KBLI
  const businessField = form.watch("businessField");
  const [subIsic, setSubIsic] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/isic/${businessField}`).then((res) => {
      if (mounted) {
        setSubIsic(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, [businessField]);

  // Countries
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/regions`).then((res) => {
      if (mounted) {
        setCountries(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Provinces
  const country = form.watch("country");
  const [provinces, setProvinces] = useState(null);
  useEffect(() => {
    let mounted = true;
    country !== activeExperience?.country?.regionCode && form.setValue("province", null);
    setProvinces(null);

    const getProvinces = () => {
      axios.get(`/regions?regionCode=${country}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setProvinces(res?.data?.data) : setProvinces(null);
        }
      });
    };

    country && getProvinces();

    return () => {
      mounted = false;
    };
  }, [country]);

  // Cities
  const province = form.watch("province");
  const [cities, setCities] = useState(null);
  useEffect(() => {
    let mounted = true;
    province !== activeExperience?.province && form.setValue("city", null);
    setCities(null);

    const getCities = () => {
      axios.get(`/regions?regionCode=${province}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setCities(res?.data?.data) : setCities(null);
        }
      });
    };

    province && getCities();
    return () => {
      mounted = false;
    };
  }, [province]);

  // Districts
  const city = form.watch("city");
  const [districts, setDistricts] = useState(null);
  useEffect(() => {
    let mounted = true;
    city !== activeExperience?.city && form.setValue("district", null);
    setDistricts(null);

    const getDistricts = () => {
      axios.get(`/regions?regionCode=${city}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setDistricts(res?.data?.data) : setDistricts(null);
        }
      });
    };

    city && getDistricts();

    return () => {
      mounted = false;
    };
  }, [city]);

  // Currency
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get("/currency").then((res) => {
      if (mounted) {
        setCurrencies(res?.data?.currency);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createExperience = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        validFrom: new Date(payload?.validFrom),
        validUntil: new Date(payload?.validUntil),
        mhoDate: new Date(payload?.mhoDate),
      })
    );
    formData.append("mhoAttachment", payload?.mhoAttachment[0]);
    formData.append("workReferenceAttachment", payload?.workReferenceAttachment[0]);

    axios
      .post(`experience/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadExperiences(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeExperience);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateExperience = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        ...(payload?.validFrom ? { validFrom: new Date(payload.validFrom) } : {}),
        ...(payload?.validUntil ? { validUntil: new Date(payload.validUntil) } : {}),
        ...(payload?.mhoDate ? { mhoDate: new Date(payload.mhoDate) } : {}),
      })
    );
    payload?.mhoAttachment && formData.append("mhoAttachment", payload?.mhoAttachment[0]);
    payload?.workReferenceAttachment && formData.append("workReferenceAttachment", payload?.workReferenceAttachment[0]);

    axios
      .patch(`experience/${regCode}/${activeExperience?.experienceId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadExperiences(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteExperience = () => {
    setLoadingDelete(true);

    axios
      .delete(`experience/${regCode}/${activeExperience?.experienceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadExperiences(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingExperiences,
        experiences,
        activeExperience,

        isic,
        subIsic,
        countries,
        provinces,
        cities,
        districts,
        currencies,

        isCreate,
        handleCreate,
        loadingCreate,
        createExperience,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateExperience,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteExperience,
      }}
    >
      {children}
    </Provider>
  );
};

export default ExperienceController;
