import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheckDouble } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';
import PrintDocumentButton from '../../../../../../../../../../components/PrintDocumentButton';
import TitleBar from '../../../../../../../../../../components/TitleBar';
import { ProcurementDetailContext } from '../../../../../Controller';
import { ProcurementOfferEvaluationContext } from '../Controller';

const Row = ({ vendor, idx, offerType, handleOpenDetail, idxOffer }) => {
	const { t } = useTranslation();
	const { commercialOffers, administratifOffers } = useContext(ProcurementOfferEvaluationContext);

	const [inProgress, setInProgress] = useState(null);

	useEffect(() => {
		if (!commercialOffers || !administratifOffers) return;
		if (idxOffer === null) return;

		if (offerType === 'ADM') {
			const offer = administratifOffers[idxOffer];
			const total = offer.procurementAdministrationOfferRequirements?.length;
			let evaluated = 0;
			for (let i = 0; i < total; i++) {
				if (offer.procurementAdministrationOfferRequirements[i].status !== 'PENDING') evaluated++;
			}
			if (evaluated === total) return setInProgress(false);
			if (evaluated === 0) return setInProgress(null);
			setInProgress(true);
		} else {
			const offer = commercialOffers[idxOffer];
			const total = offer.procurementCommercialOfferRequirements?.length;
			const totalMaterial = offer.procurementCommercialOfferRequisitionMaterials.length;
			let evaluated = 0;
			for (let i = 0; i < total; i++) {
				if (offer.procurementCommercialOfferRequirements[i].status !== 'PENDING') evaluated++;
			}
			for (let i = 0; i < totalMaterial; i++) {
				if (offer.procurementCommercialOfferRequisitionMaterials[i].status !== 'PENDING') evaluated++;
			}
			if (evaluated === total + totalMaterial) return setInProgress(false);
			if (evaluated === 0) return setInProgress(null);
			setInProgress(true);
		}
	}, [commercialOffers, administratifOffers]);

	return (
		<Tr
			className={
				vendor?.status?.status !== 'ACCEPT'
					? ''
					: offerType === 'ADM'
					? vendor.procurementAdministrationOffer && vendor.procurementAdministrationOffer.status === 'SUBMITTED'
						? 'hover:bg-gray-50 cursor-pointer'
						: ''
					: vendor.procurementCommercialOffer && vendor.procurementCommercialOffer.status === 'SUBMITTED'
					? 'hover:bg-gray-50 cursor-pointer'
					: ''
			}
			onClick={() => {
				if (idxOffer === null) return;
				handleOpenDetail(idxOffer);
			}}
		>
			<Td
				style={{
					minWidth: '75px',
					maxWidth: '75px',
					width: '75px',
				}}
			>
				{idx + 1}
			</Td>
			<Td>{vendor.company?.companyName}</Td>
			<Td className="uppercase">{vendor.status.status === 'ACCEPT' ? t('LABEL.REGISTERED') : 'TIDAK MENDAFTAR'}</Td>
			<Td>
				{offerType === 'ADM' ? (
					<>
						{!vendor.procurementAdministrationOffer ? (
							<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
						) : vendor.procurementAdministrationOffer.status === 'SUBMITTED' ? (
							<div className="font-bold">{moment(vendor.procurementAdministrationOffer.updateAt).format('LLL')}</div>
						) : (
							<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
						)}
					</>
				) : (
					<>
						{!vendor.procurementCommercialOffer ? (
							<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
						) : vendor.procurementCommercialOffer.status === 'SUBMITTED' ? (
							<div className="font-bold">{moment(vendor.procurementCommercialOffer.updateAt).format('LLL')}</div>
						) : (
							<>{t('LABEL.OFFER_NOT_SUBMITTED')}</>
						)}
					</>
				)}
			</Td>
			<Td className="px-4">
				<div className="w-full h-full flex justify-end items-center">{inProgress !== null && <BiCheckDouble className="text-xl" color={inProgress ? 'orange' : 'green'} />}</div>
			</Td>
		</Tr>
	);
};

const OfferTable = ({ offerType }) => {
	const { t } = useTranslation();
	const { procurement } = useContext(ProcurementDetailContext);

	const { procurementVendors, loadingProcurementVendors, setHandleDetailCommercialIdx, setHandleDetailAdministratifIdx, commercialOffers, administratifOffers } =
		useContext(ProcurementOfferEvaluationContext);

	const handleOpenDetail = (idx) => {
		if (offerType === 'ADM') {
			setHandleDetailAdministratifIdx(idx);
		} else {
			setHandleDetailCommercialIdx(idx);
		}
	};

	return (
		<div>
			<TitleBar title={t('LABEL.VENDOR')} rightElement={offerType !== 'COM' ? undefined : <PrintDocumentButton label="Cetak BAPP" url={`ba-offer-opening/${procurement?.procurementNumber}`} />} />
			<div>
				<Table>
					<Thead>
						<Tr>
							<Th
								style={{
									minWidth: '75px',
									maxWidth: '75px',
									width: '75px',
								}}
								className="text-red-500"
							>
								No.
							</Th>
							<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
							<Th className="text-red-500">Status</Th>
							<Th className="text-red-500">{t('LABEL.LAST_QUOTATION', { type: offerType === 'ADM' ? t('LABEL.PROCUREMENT_TECHNICAL_TENDER') : t('LABEL.PROCUREMENT_COMMERCIAL_TENDER') })}</Th>
							<Th />
						</Tr>
					</Thead>
					<Tbody>
						{loadingProcurementVendors || !procurementVendors || !administratifOffers || !commercialOffers ? (
							<Tr>
								<Td colSpan={5}>
									<div className="w-full h-full flex justify-center items-center">
										<Spinner color="red.500" />
									</div>
								</Td>
							</Tr>
						) : (
							<>
								{procurementVendors.length === 0 ? (
									<Tr>
										<Td colSpan={5} className="text-center">
											<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
										</Td>
									</Tr>
								) : (
									procurementVendors.map((vendor, idx) => {
										let idxOffer = null;
										if (offerType === 'ADM') {
											administratifOffers &&
												administratifOffers.find((offer, idx) => {
													if (offer.procurementVendorId === vendor.procurementVendorId) idxOffer = idx;
												});
										} else {
											commercialOffers &&
												commercialOffers.forEach((offer, idx) => {
													if (offer.procurementVendorId === vendor.procurementVendorId) idxOffer = idx;
												});
										}

										return <Row key={idx} idx={idx} offerType={offerType} idxOffer={idxOffer} handleOpenDetail={handleOpenDetail} vendor={vendor} />;
									})
								)}
							</>
						)}
					</Tbody>
				</Table>
			</div>
		</div>
	);
};

export default OfferTable;
