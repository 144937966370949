import React, { useContext, useEffect } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ExperienceContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import InputNumber from "../../../../../../../components/inputForm/InputNumber";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import numeral from "numeral";

const Form = ({ isEdit }) => {
  const { t, i18n } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const {
    isCreate,
    loadingCompany,
    activeExperience,
    form,
    experiences,
    isic,
    subIsic,
    countries,
    provinces,
    cities,
    districts,
    currencies,
  } = useContext(ExperienceContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("mhoAttachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.MHO") }) }),
    },
  });

  register("workReferenceAttachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.WORK_REFERENCE_LETTER") }) }),
    },
  });

  const currency = form.watch("currency");
  const activeCurrency = currencies.find((c) => c?.currency === currency);

  return (
    <>
      {/* Business Field */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BUSINESS_FIELD")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BUSINESS_FIELD") }) },
            }}
            name="businessField"
            options={isic.map((isicItem) => ({
              label: `${isicItem?.isicCode} - ${
                i18n.language === "id" ? isicItem?.description : isicItem?.descriptionEn
              }`,
              value: isicItem?.isicCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BUSINESS_FIELD")}`}
            isError={!!errors?.businessField}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{_.startCase(activeExperience?.businessField?.description?.toLowerCase())}</FormDescription>
        )}
        {errors?.businessField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.businessField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Sub Business Field */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.SUB_BUSINESS_FIELD")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.SUB_BUSINESS_FIELD") }) },
            }}
            name="subBusinessField"
            options={subIsic.map((isicItem) => ({
              label: `${isicItem?.isicCode} - ${
                i18n.language === "id" ? isicItem?.description : isicItem?.descriptionEn
              }`,
              value: isicItem?.isicCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.SUB_BUSINESS_FIELD")}`}
            disabled={!subIsic.length}
            isError={!!errors?.subBusinessField}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {_.startCase(activeExperience?.subBusinessField?.description?.toLowerCase())}
          </FormDescription>
        )}
        {errors?.subBusinessField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.subBusinessField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Job Name */}
      <FormControl isRequired className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.JOB_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.jobName && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.JOB_NAME")}
            {...register("jobName", { required: t("INVALID.REQUIRED", { field: t("LABEL.JOB_NAME") }) })}
          />
        ) : (
          <FormDescription>{activeExperience?.jobName}</FormDescription>
        )}
        {errors?.jobName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.jobName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Job Location */}
      <FormControl isRequired className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.JOB_LOCATION")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.jobLocation && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.JOB_LOCATION")}
            {...register("jobLocation", { required: t("INVALID.REQUIRED", { field: t("LABEL.JOB_LOCATION") }) })}
          />
        ) : (
          <FormDescription>{activeExperience?.jobLocation}</FormDescription>
        )}
        {errors?.jobLocation && (
          <FormHelperText className="text-red-400 text-xs">{errors?.jobLocation?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Job Assignor */}
      <FormControl isRequired className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.JOB_ASSIGNOR")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.jobAssignor && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.JOB_ASSIGNOR")}
            {...register("jobAssignor", { required: t("INVALID.REQUIRED", { field: t("LABEL.JOB_ASSIGNOR") }) })}
          />
        ) : (
          <FormDescription>{activeExperience?.jobAssignor}</FormDescription>
        )}
        {errors?.jobAssignor && (
          <FormHelperText className="text-red-400 text-xs">{errors?.jobAssignor?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Country */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.COUNTRY")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.COUNTRY") }) },
            }}
            name="country"
            options={countries.map((country) => ({
              label: country?.description,
              value: country?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.COUNTRY")}`}
            isError={!!errors?.country}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeExperience?.country?.description}</FormDescription>
        )}

        {errors?.country && (
          <FormHelperText className="text-red-400 text-xs">{errors?.country?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Province */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PROVINCE")}
        </FormLabel>
        {isEdit ? (
          provinces === null ? (
            <InputText
              className={`w-full text-sm ${errors?.province && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.PROVINCE")}
              {...register("province", { required: t("INVALID.REQUIRED", { field: t("LABEL.PROVINCE") }) })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.PROVINCE") }) },
              }}
              name="province"
              options={provinces.map((province) => ({
                label: province?.description,
                value: province?.regionCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.PROVINCE")}`}
              isSearchable
              isDisabled={!provinces.length}
              isError={!!errors?.province}
            />
          )
        ) : (
          <FormDescription>
            {activeExperience?.provinceObj
              ? _.startCase(activeExperience?.provinceObj?.description?.toLowerCase())
              : activeExperience?.province}
          </FormDescription>
        )}
        {errors?.province && (
          <FormHelperText className="text-red-400 text-xs">{errors?.province?.message}</FormHelperText>
        )}
      </FormControl>

      {/* City */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CITY")}
        </FormLabel>
        {isEdit ? (
          cities === null ? (
            <InputText
              className={`w-full text-sm ${errors?.city && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.CITY")}
              {...register("city", { required: t("INVALID.REQUIRED", { field: t("LABEL.CITY") }) })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CITY") }) },
              }}
              name="city"
              options={cities.map((city) => ({
                label: city?.description,
                value: city?.regionCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CITY")}`}
              isSearchable
              isDisabled={!cities.length}
              isError={!!errors?.city}
            />
          )
        ) : (
          <FormDescription>
            {activeExperience?.cityObj
              ? _.startCase(activeExperience?.cityObj?.description?.toLowerCase())
              : activeExperience?.city}
          </FormDescription>
        )}
        {errors?.city && <FormHelperText className="text-red-400 text-xs">{errors?.city?.message}</FormHelperText>}
      </FormControl>

      {/* District */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DISTRICT")}
        </FormLabel>
        {isEdit ? (
          districts === null ? (
            <InputText
              className={`w-full text-sm ${errors?.city && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.DISTRICT")}
              {...register("district", { required: t("INVALID.REQUIRED", { field: t("LABEL.DISTRICT") }) })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.DISTRICT") }) },
              }}
              name="district"
              options={districts.map((district) => ({
                label: district?.description,
                value: district?.regionCode,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.DISTRICT")}`}
              isSearchable
              isDisabled={!districts.length}
              isError={!!errors?.district}
            />
          )
        ) : (
          <FormDescription>
            {activeExperience?.districtObj
              ? _.startCase(activeExperience?.districtObj?.description?.toLowerCase())
              : activeExperience?.district}
          </FormDescription>
        )}
        {errors?.district && (
          <FormHelperText className="text-red-400 text-xs">{errors?.district?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Address */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ADDRESS")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${errors?.address && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ADDRESS")}
            {...register("address", { required: t("INVALID.REQUIRED", { field: t("LABEL.ADDRESS") }) })}
          />
        ) : (
          <FormDescription>{activeExperience?.address}</FormDescription>
        )}
        {errors?.address && (
          <FormHelperText className="text-red-400 text-xs">{errors?.address?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Postal Code */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.POSTAL_CODE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.postalCode && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.POSTAL_CODE")}
            type="number"
            {...register("postalCode", { required: t("INVALID.REQUIRED", { field: t("LABEL.POSTAL_CODE") }) })}
          />
        ) : (
          <FormDescription>{activeExperience?.postalCode}</FormDescription>
        )}
        {errors?.postalCode && (
          <FormHelperText className="text-red-400 text-xs">{errors?.postalCode?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Proc Contract Number */}
      <FormControl isRequired className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PROC_CONTRACT_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.procContractNumber && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.PROC_CONTRACT_NUMBER")}
            {...register("procContractNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.PROC_CONTRACT_NUMBER") }),
            })}
          />
        ) : (
          <FormDescription>{activeExperience?.procContractNumber}</FormDescription>
        )}
        {errors?.procContractNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.procContractNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Valid From */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VALID_FROM")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="validFrom"
            placeholder={t("LABEL.VALID_FROM")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.VALID_FROM") }) },
            }}
            isError={!!errors?.validFrom}
          />
        ) : (
          <FormDescription>{moment(new Date(activeExperience?.validFrom)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.validFrom && (
          <FormHelperText className="text-red-400 text-xs">{errors?.validFrom?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Valid Until */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VALID_UNTIL")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="validUntil"
            placeholder={t("LABEL.VALID_UNTIL")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.VALID_UNTIL") }) },
            }}
            isError={!!errors?.validUntil}
          />
        ) : (
          <FormDescription>{moment(new Date(activeExperience?.validUntil)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.validUntil && (
          <FormHelperText className="text-red-400 text-xs">{errors?.validUntil?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Currency */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CURRENCY")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CURRENCY") }) },
            }}
            name="currency"
            options={currencies.map((currency) => ({
              label: `${currency?.symbol} - ${currency?.description}`,
              value: currency?.currency,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CURRENCY")}`}
            isError={!!errors?.currency}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeExperience?.currency?.description}</FormDescription>
        )}
        {errors?.currency && (
          <FormHelperText className="text-red-400 text-xs">{errors?.currency?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Contract Value */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CONTRACT_VALUE")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              name="contractValue"
              control={control}
              placeholder={t("LABEL.CONTRACT_VALUE")}
              isError={!!errors?.contractValue}
            />
          </div>
        ) : (
          <FormDescription>
            {activeExperience?.currency?.symbol} {numeral(activeExperience?.contractValue).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.contractValue && (
          <FormHelperText className="text-red-400 text-xs">{errors?.contractValue?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* MHO Date */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.MHO_DATE")}
        </FormLabel>
        {isEdit ? (
          <Datepicker name="mhoDate" placeholder={t("LABEL.MHO_DATE")} control={control} />
        ) : (
          <FormDescription>
            {activeExperience?.mhoDate ? moment(new Date(activeExperience?.mhoDate)).format("DD/MM/YYYY") : null}
          </FormDescription>
        )}
        {errors?.mhoDate && (
          <FormHelperText className="text-red-400 text-xs">{errors?.mhoDate?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Work Reference Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.WORK_REFERENCE_LETTER") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.workReferenceAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.WORK_REFERENCE_LETTER"),
            })}`}
            name="workReferenceAttachment"
            fileList={form.getValues("workReferenceAttachment")}
            onChange={(files) => {
              setValue("workReferenceAttachment", files);
              trigger("workReferenceAttachment");
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeExperience?.workReferenceAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.workReferenceAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.workReferenceAttachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* MHO Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.MHO") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.mhoAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.MHO"),
            })}`}
            name="mhoAttachment"
            fileList={form.getValues("mhoAttachment")}
            onChange={(files) => {
              setValue("mhoAttachment", files);
              trigger("mhoAttachment");
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeExperience?.mhoAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.mhoAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.mhoAttachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
