import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AuctionContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../../../../../components/inputForm/FormDescription";
import SelectButton from "../../../../../../../../../components/inputForm/SelectButton";
import Datepicker from "../../../../../../../../../components/inputForm/Datepicker";
import moment from "moment";
import Select from "../../../../../../../../../components/inputForm/Select";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { activeAuction, form, winnerEvaluationMethods, rankMethods } = useContext(AuctionContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Title */}
      <FormControl isRequired={isEdit} className="col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.AUCTION_TITLE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.title && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.AUCTION_TITLE")}
            {...register("title", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.AUCTION_TITLE") }),
            })}
          />
        ) : (
          <FormDescription>{activeAuction?.title}</FormDescription>
        )}
        {errors?.title && <FormHelperText className="text-red-400 text-xs">{errors?.title?.message}</FormHelperText>}
      </FormControl>

      {/* Time Start */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.AUCTION_START")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="timeStart"
            placeholder={t("LABEL.AUCTION_START")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.AUCTION_START") }) },
            }}
            format="yyyy-MM-dd HH:mm:ss"
            isError={!!errors?.timeStart}
          />
        ) : (
          <FormDescription>{moment(new Date(activeAuction?.timeStart)).format("DD/MM/YYYY H:i:s")}</FormDescription>
        )}
        {errors?.timeStart && (
          <FormHelperText className="text-red-400 text-xs">{errors?.timeStart?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Time End */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.AUCTION_END")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="timeEnd"
            placeholder={t("LABEL.AUCTION_END")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.AUCTION_END") }) },
            }}
            format="yyyy-MM-dd HH:mm:ss"
            isError={!!errors?.timeEnd}
          />
        ) : (
          <FormDescription>{moment(new Date(activeAuction?.timeEnd)).format("DD/MM/YYYY H:i:s")}</FormDescription>
        )}
        {errors?.timeEnd && (
          <FormHelperText className="text-red-400 text-xs">{errors?.timeEnd?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Is Best Price Visible */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.BEST_PRICE_VISIBILITY")}</FormLabel>
        <SelectButton
          className="w-full grid grid-cols-2"
          control={control}
          name="isBestPriceVisible"
          value={form?.getValues("isBestPriceVisible")}
          options={[
            { label: t("LABEL.YES"), value: true },
            { label: t("LABEL.NO"), value: false },
          ]}
        />
        {errors?.isBestPriceVisible && (
          <FormHelperText className="text-red-400 text-xs">{errors?.isBestPriceVisible?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Is Best Price Visible */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.TOP_RANK_VISIBILITY")}</FormLabel>
        <SelectButton
          className="w-full grid grid-cols-2"
          control={control}
          name="isTopRankVisible"
          value={form?.getValues("isTopRankVisible")}
          options={[
            { label: t("LABEL.YES"), value: true },
            { label: t("LABEL.NO"), value: false },
          ]}
        />
        {errors?.isTopRankVisible && (
          <FormHelperText className="text-red-400 text-xs">{errors?.isTopRankVisible?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
