import React, { useContext } from "react";
import { motion } from "framer-motion";
import AanwijzingTable from "./components/AanwijzingTable";
import AanwijzingController, { AanwijzingContext } from "./Controller";
import AanwijzingNew from "./components/AanwijzingNew";
import ModalDelete from "./components/ModalDelete";
import AanwijzingDetail from "./components/AanwijzingDetail";
import ModalAanwijzingAnnounce from "./components/ModalAanwijzingAnnounce";
import { ProcurementDetailContext } from "../../../Controller";

const Aanwijzing = () => {
  const {
    isCreate,
    isDetailMenu
  } = useContext(AanwijzingContext);
  
  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
    >
      {isCreate ? (
        <AanwijzingNew />
      ) : isDetailMenu ? (
        <AanwijzingDetail />
      ) : (
        <div className="flex flex-col gap-6">
          <AanwijzingTable />
        </div>
      )}

      <ModalDelete />
      <ModalAanwijzingAnnounce />
    </motion.div>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.aanwijzing){
    return <div></div>
  }
  if (!procurement?.procurementSetting?.aanwijzing){
    return <div></div>
  }

  return (
    <AanwijzingController>
      <Aanwijzing />
    </AanwijzingController>
  )
};
