import { InputNumber as InputNumberComponent } from "primereact/inputnumber";
import { useController } from "react-hook-form";

const InputNumber = ({ control, minFractionDigits, name, controlProps = {}, ...props }) => {
  const { field } = useController({
    name,
    control,
    ...controlProps,
  });

  const { onChange, ...fieldWithoutOnChange } = field;

  return (
    <InputNumberComponent
      {...fieldWithoutOnChange}
      {...props}
      onValueChange={field.onChange}
      className={`w-full text-sm ${props?.isError && "border-2 border-red-400 shadow-none"}`}
      mode="decimal"
      locale="de-DE"
      minFractionDigits={minFractionDigits?? 2}
    />
  );
};

export default InputNumber;
