import { Spinner, Button, FormControl, FormHelperText, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiEdit, BiExit, BiPaperPlane } from 'react-icons/bi';
import Datepicker from '../../../../../../../../../components/inputForm/Datepicker';
import PrivateComponent from '../../../../../../../../../components/PrivateComponent';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../Controller';
import { ProcurementOfferEvaluationContext } from '../evaluation/Controller';

const SchedulePanel = ({ title, start, end, loading, className, type }) => {
	const { t } = useTranslation();
	const toast = useToast();
	const { userData } = useContext(AuthContext);
	const { showToast, httpRequest } = useContext(HelperContext);
	const { procurement } = useContext(ProcurementDetailContext);
	const { extendedSchedule, setExtendedSchedule, schedule } = useContext(ProcurementOfferEvaluationContext);

	const [isEdit, setIsEdit] = useState(false);
	const [canEditStart, setCanEditStart] = useState(false);
	const [canEditEnd, setCanEditEnd] = useState(false);
	const [loadingCurrentTime, setLoadingCurrentTime] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const {
		control,
		formState: { errors },
		reset,
		handleSubmit,
		setError,
	} = useForm();

	useEffect(() => {
		if (!isEdit) {
			reset({
				start: start ? new Date(start) : null,
				end: end ? new Date(end) : null,
			});
		}
	}, [isEdit, start, end]);

	const requestEdit = async () => {
		setLoadingCurrentTime(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule-extend-offer/can-edit/${procurement.procurementId}/${type ?? '2COVER'}`,
		});
		setLoadingCurrentTime(false);

		if (!response?.success) {
			showToast('Gagal mendapatkan status penawaran', {
				success: false,
			});
			return;
		}

		const { start, end } = response.data;
		setCanEditStart(start);
		setCanEditEnd(end);
		setIsEdit(true);
	};

	const submitCreate = async (data) => {
		const newValue = {
			procurementId: procurement.procurementId,
			technicalQuotationStart: null,
			technicalQuotationEnd: null,
			commercialQuotationStart: null,
			commercialQuotationEnd: null,
			quotationStart: null,
			quotationEnd: null,
		};
		const startKey = !type ? 'quotationStart' : type === 'COM' ? 'commercialQuotationStart' : 'technicalQuotationStart';
		if (canEditStart) newValue[startKey] = new Date(data.start);
		const endKey = !type ? 'quotationEnd' : type === 'COM' ? 'commercialQuotationEnd' : 'technicalQuotationEnd';
		if (canEditEnd) newValue[endKey] = new Date(data.end);

		setLoadingSubmit(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule-extend-offer`,
			method: 'POST',
			payload: newValue,
		});
		setLoadingSubmit(false);
		if (!response?.success) {
			showToast('Gagal mengubah jadwal penawaran', {
				success: false,
			});
			return;
		}

		setExtendedSchedule(response?.data?.procurementScheduleExtendOffer);
		setIsEdit(false);
		showToast('Jadwal berhasil diubah');
	};

	const submitEdit = async (data) => {
		const payload = {};
		const startKey = !type ? 'quotationStart' : type === 'COM' ? 'commercialQuotationStart' : 'technicalQuotationStart';
		const endKey = !type ? 'quotationEnd' : type === 'COM' ? 'commercialQuotationEnd' : 'technicalQuotationEnd';

		if (canEditStart) payload[startKey] = data.start;
		if (canEditEnd) payload[endKey] = data.end;

		setLoadingSubmit(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule-extend-offer/${schedule.id}`,
			method: 'PATCH',
			payload,
		});
		setLoadingSubmit(false);

		if (!response?.success) {
			showToast('Gagal mengubah jadwal', {
				success: false,
			});
			return;
		}
		setExtendedSchedule(response?.data?.procurementScheduleExtendOffer);
		setIsEdit(false);
		showToast('Jadwal berhasil diubah');
	};

	const submit = (data) => {
		const startDate = moment(data.start).unix();
		const endDate = moment(data.end).unix();
		const nowDate = moment().add(1, 'minutes').unix();

		if (endDate < startDate) {
			// setError('start', {
			// 	message: 'Mulai penawaran harus lebih kecil',
			// });
			toast({
				description: "Mulai penawaran harus lebih kecil",
				position: "top-right",
				status: "error",
				isClosable: true,
			});
			// return;
		} else if (canEditStart && startDate < nowDate) {
			// setError('start', {
			// 	message: 'Mulai penawaran tidak boleh lebih kecil dari waktu sekarang',
			// });
			toast({
				description: "Mulai penawaran tidak boleh lebih kecil dari waktu sekarang",
				position: "top-right",
				status: "error",
				isClosable: true,
			});
			// return;
		} else if (canEditEnd && endDate < nowDate) {
			// setError('end', {
			// 	message: 'Batas penawaran tidak boleh lebih kecil dari waktu sekarang',
			// });
			toast({
				description: "Batas penawaran tidak boleh lebih kecil dari waktu sekarang",
				position: "top-right",
				status: "error",
				isClosable: true,
			});
			// return;
		} else {
			if (!extendedSchedule) {
				submitCreate(data);
			} else {
				submitEdit(data);
			}
		}

	};

	return (
		<div className={`flex ${className}`}>
			<div className="w-1/5 flex items-center justify-center text-base font-semibold italic text-center">{title}</div>
			<div className="font-bold flex flex-col gap-2 py-2 w-3/5">
				<div className="flex items-center gap-5 text-green-600">
					<div className="text-right w-1/4">{t('LABEL.START_AT')}</div>
					<div className="w-3/4 flex items-center">
						{!isEdit ? (
							<>{loading ? <Spinner color="red.500" /> : <>{!start ? <>-</> : <>{start ? moment(start).format('LLL') : <>-</>}</>}</>}</>
						) : (
							<div className="w-3/4">
								<FormControl>
									<Datepicker
										disabled={!canEditStart}
										format="yyyy-MM-dd HH:mm:ss"
										name="start"
										placeholder="Mulai penawaran"
										control={control}
										controlProps={{
											rules: {
												required: t('INVALID.REQUIRED', {
													field: t('LABEL.PROCUREMENT_START', {
														field: t('LABEL.PROCUREMENT_TECHNICAL_TENDER'),
													}),
												}),
											},
										}}
										isError={!!errors?.start}
									/>
									{errors?.start && <FormHelperText className="text-red-400 text-xs">{errors?.start?.message}</FormHelperText>}
								</FormControl>
							</div>
						)}
					</div>
				</div>
				<div className="flex items-center gap-5 text-color0">
					<div className="text-right w-1/4">{t('LABEL.END_AT')}</div>
					<div className="w-3/4 flex items-center">
						{!isEdit ? (
							<>{loading ? <Spinner color="red.500" /> : <>{!end ? <>-</> : <>{end ? moment(end).format('LLL') : <>-</>}</>}</>}</>
						) : (
							<div className="w-3/4">
								<FormControl>
									<Datepicker
										disabled={!canEditEnd}
										format="yyyy-MM-dd HH:mm:ss"
										name="end"
										placeholder="Batas penawaran"
										control={control}
										controlProps={{
											rules: {
												required: t('INVALID.REQUIRED', {
													field: t('LABEL.PROCUREMENT_START', {
														field: t('LABEL.PROCUREMENT_TECHNICAL_TENDER'),
													}),
												}),
											},
										}}
										isError={!!errors?.end}
									/>
									{errors?.end && <FormHelperText className="text-red-400 text-xs">{errors?.end?.message}</FormHelperText>}
								</FormControl>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="w-1/4 flex justify-center flex-col items-start gap-3">
				{userData && userData.type === 'admin' && !procurement?.procurementWinnerProposal?.isWinner && (
					<PrivateComponent accessName="BUYER_EDIT">
						{!isEdit ? (
							<Button
								className="font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-size-200 bg-pos-0 hover:bg-pos-100 text-sm"
								onClick={() => {
									requestEdit();
								}}
								isLoading={loadingCurrentTime}
							>
								<BiEdit className="text-xl" />
								{t('LABEL.EDIT', { field: '' })}
							</Button>
						) : (
							<>
								<Button
									disabled={!canEditEnd && !canEditStart}
									className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 shadow-lg text-sm"
									onClick={handleSubmit(submit)}
									isLoading={loadingSubmit}
								>
									<BiPaperPlane className="text-xl" />
									{t('LABEL.SUBMIT', { field: '' })}
								</Button>
								<Button
									className="font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-size-200 bg-pos-0 hover:bg-pos-100 text-sm"
									onClick={() => {
										setIsEdit(false);
									}}
								>
									<BiExit className="text-xl" />
									{t('LABEL.CANCEL', { field: '' })}
								</Button>
							</>
						)}
					</PrivateComponent>
				)}
			</div>
		</div>
	);
};

export default SchedulePanel;
