import React, { useContext } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Spinner } from "@chakra-ui/react";
import { BiTask } from "react-icons/bi";
import { AuctionContext } from "../Controller";
import moment from "moment";

const AuctionOverview = () => {
  const { t } = useTranslation();
  const { auction, loadingAuction } = useContext(AuctionContext);

  return (
    <div className="p-4 rounded-md bg-gradient-primary text-white">
      {loadingAuction ? (
        <div className="flex justify-center items-center">
          <Spinner color="white" />
        </div>
      ) : (
        <div className="w-full flex gap-4">
          <div className="flex justify-center items-center">
            <BiTask className="text-4xl" />
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <h2 className="m-0 font-bold text-2xl">{auction?.title}</h2>
          </div>
          <div className="flex flex-col text-right justify-center">
            <span className="font-bold">{t("LABEL.CREATED_AT")}</span>
            <span>{moment(auction?.createdAt).format("DD/MM/YYYY")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuctionOverview;
