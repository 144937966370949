import { Button, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useContext } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import { CommercialContext } from './Controller';
import ConfirmationModal from '../ConfirmationModal';
import { ProcurementOfferContext } from '../../Controller';
import { AuthContext } from '../../../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../../Controller';
import { useTranslation } from 'react-i18next';

const CommercialAction = () => {
  const {t} = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { commercialOffer, setCommercialOffer, materials } = useContext(ProcurementOfferContext);
	const { userData } = useContext(AuthContext);
	const { procurement } = useContext(ProcurementDetailContext);
	const { form, priceInputs, isAnyError, requirements } = useContext(CommercialContext);

	const { handleSubmit, trigger } = form;

	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const submit = async (data) => {
		const formData = new FormData();

		data.offerLetter && formData.append('letter', data.offerLetter[0]);
		data.offerGuaranteeFile && formData.append('guarantee', data.offerGuaranteeFile[0]);

    // SETUP DATA PENAWARAN
		const payload = {
			offer: {
				companyRegCode: userData.company.registrationCode,
				procurementNumber: procurement.procurementNumber,
				startAt: data.offerDate,
				expiredAt: data.offerExpired,
				offerNumber: data.offerNumber,
				note: data.offerNote,
				incotermId: data.offerIncoterm,
				incotermDescription: data.offerIncotermDescription,
				guaranteeValue: data.offerGuaranteeValue === '' ? null : data.offerGuaranteeValue,
				guaranteeExpiredAt: data.offerGuaranteeExpired === '' ? null : data.offerGuaranteeExpired,
			},
			requisitionMaterials: [],
		};

    // SETUP REQUIREMENTS
		for (let i = 0; i < requirements.length; i++) {
      if (!data[`document_${i}`]) continue;
			const file = data[`document_${i}`][0];
			if (!file) continue;
			const req = requirements[i];

			const ext = file.name.match(/\.[0-9a-z]+$/i)[0];
			formData.append('requirements', file, req.procurementRequirementId + ext);
		}

    // SETUP DATA MATERIAL
		for (let i = 0; i < materials.length; i++) {
			const material = materials[i];
			const price = priceInputs[i];
			const temp = {
				requisitionMaterialId: material.procurementRequisitionMaterialId,
				price: price,
			};
			payload.requisitionMaterials.push(temp);
		}

		formData.append('data', JSON.stringify(payload));

		setLoading(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-commercial-offer`,
			method: 'POST',
			payload: formData,
		});
		setLoading(false);

		if (!response?.success) {
			showToast('Gagal membuat penawaran', { success: false });
			return;
		}

		setCommercialOffer(response?.data?.procurementCommercialOffer);
		showToast('Penawaran berhasil dibuat');
	};

	return (
		<div className="mt-10 flex justify-end gap-5">
			{commercialOffer && commercialOffer.status === 'SUBMITTED' ? (
				<div className="mr-5 text-green-500 text-xl font-bold italic">{t("LABEL.SUBMITTED")}</div>
			) : (
				<>
					<Button
						onClick={async () => {
							const valid = await trigger('', { shouldFocus: true });
							if (!valid) return;
							const anyError = isAnyError();
							if (anyError) return;
							setOpenModal(true);
						}}
						isLoading={loading}
						color="white"
						className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 shadow-lg hover:bg-pos-100 flex gap-1"
					>
						<BiPaperPlane className="text-2xl" />
						<p>{t("LABEL.SUBMIT_ONLY")}</p>
					</Button>
					<ConfirmationModal
						loading={loading}
						isOpen={openModal}
						title={t("LABEL.COMMERCIAL")}
						onNo={() => {
							setOpenModal(false);
						}}
						onYes={handleSubmit(submit)}
					/>
				</>
			)}
		</div>
	);
};

export default CommercialAction;
