import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import { Dropdown } from "primereact/dropdown";
import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretUp } from "react-icons/ai";
import { BiError, BiPencil } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userRouteSlug } from "../../../../routes/userRoutes";
import { CompanyProfileContext } from "../Controller";

const DropdownItem = ({ data }) => {
  const navigate = useNavigate();
  const { submitApprovalFails, needReapprovals } = useContext(CompanyProfileContext);
  const { t } = useTranslation();
  const { section, regCode } = useParams();
  const isActive = `/${section}` === data?.to;
  const hasChildren = Array.isArray(data?.items);

  const [isChildrenShow, setChildrenShow] = useState(true);
  const hasSubmitApprovalFail = submitApprovalFails.find((fail) => fail?.path === data?.to);
  const doesNeedReapproval = needReapprovals.find((need) =>
    data?.to?.includes(need?.companyProfileSection?.sectionPath)
  );

  const handleClick = () => {
    const path = regCode
      ? userRouteSlug.PANEL + userRouteSlug.MASTER_VENDOR + `/${regCode}` + "/profile" + data?.to
      : userRouteSlug.PANEL + userRouteSlug.COMPANY_PROFILE + data?.to;

    hasChildren ? setChildrenShow(!isChildrenShow) : navigate(path);
  };

  return (
    <>
      <button
        className={`p-4 rounded-sm flex justify-between items-center ${
          isActive ? "bg-gray-50" : hasChildren ? "" : ""
        }`}
        onClick={handleClick}
      >
        <span className={`text-gray-600 flex items-center gap-2 ${hasChildren ? "font-bold" : "font-medium"}`}>
          {data?.Icon && <data.Icon className="text-base" />}
          <span className={`text-left ${isActive ? "font-semibold text-red-500" : ""}`}>{t(data?.labelI18n)}</span>
        </span>
        {hasChildren ? (
          <AiFillCaretUp className={`text-xs text-gray-600 ${isChildrenShow && "transform rotate-180"}`} />
        ) : (
          <div className="flex gap-1">
            {hasSubmitApprovalFail && <BiError className="text-yellow-500" />}
            {doesNeedReapproval && <BiPencil className="text-blue-500" />}
          </div>
        )}
      </button>
      {!data?.isLastItem && <hr className="border-gray-100" />}
      <AnimatePresence>
        {hasChildren && isChildrenShow && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className="flex flex-col overflow-hidden"
          >
            {data.items.map((item, iItem) => (
              <DropdownItem key={iItem} data={item} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const ProfileDropdown = () => {
  const { activeSection, sectionCategories, sections } = useContext(CompanyProfileContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sectionMap = sectionCategories.map((sectionCategory) => ({
    ...sectionCategory,
    items: sections
      .filter((section) => section.category === sectionCategory.name)
      .map((section, iSection, arrSection) => ({
        ...section,
        isLastItem: iSection === arrSection - 1,
      })),
  }));

  return (
    <div className="bg-white rounded-md text-sm">
      <div className="hidden md:flex flex-col">
        {sectionMap.map((item, iItem) => (
          <DropdownItem key={iItem} data={item} />
        ))}
      </div>
      <div className="md:hidden p-4">
        <Dropdown
          className="w-full border-0"
          value={activeSection?.name}
          options={sections.map((section) => ({
            label: t(section?.labelI18n),
            value: section?.name,
            to: section?.to,
          }))}
          onChange={({ value }) =>
            navigate(
              userRouteSlug.PANEL +
                userRouteSlug.COMPANY_PROFILE +
                sections?.find((section) => section?.name === value)?.to
            )
          }
        />
      </div>
    </div>
  );
};

export default ProfileDropdown;
