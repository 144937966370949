import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import MaterialNew from "./components/MaterialNew";
import MaterialFilter from "./components/MaterialFilter";
import MaterialTable from "./components/MaterialTable";
import MaterialController, { MaterialContext } from "./Controller";
import MaterialDetail from "./components/MaterialDetail";

const Material = () => {
  const { t } = useTranslation();
  const { isDetailMenu, isCreate } = useContext(MaterialContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("Material")}</h2>
      </div>

      {isCreate ? (
        <MaterialNew />
      ) : isDetailMenu ? (
        <MaterialDetail />
      ) : (
        <div className="grid md:grid-cols-4 gap-4 md:gap-8">
          <div>
            <MaterialFilter />
          </div>
          <div className="md:col-span-3">
            <MaterialTable />
          </div>
        </div>
      )}
    </div>
  );
};

export default () => (
  <MaterialController>
    <Material />
  </MaterialController>
);
