import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { languages } from "../../i18n/i18n";

const TranslationHandler = ({ className = "" }) => {
  const { i18n } = useTranslation();
  const handleChangeLang = (lang) => i18n.changeLanguage(lang);

  return (
    <div>
      <Dropdown
        className={`p-0 border-0 shadow-none bg-transparent ${className}`}
        options={languages.map((language) => ({
          label: language?.nativeName,
          value: language?.name,
        }))}
        onChange={(e) => handleChangeLang(e.value)}
        value={i18n.language}
      />
    </div>
  );
};

export default TranslationHandler;
