import React from "react";
import OpenTable from "./components/OpenTable";
import OpenController from './Controller'

const Open = () => {
  
  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <h2 className="montserrat text-2xl text-gray-700 font-medium">Pengadaan Terbuka</h2>

      <OpenTable />
    </div>
  );
};

export default () => (
  <OpenController>
    <Open />
  </OpenController>
);
