import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";
import { InputText } from "primereact/inputtext";
import PrivateComponent from "../../../../../../../../../../components/PrivateComponent";

const SignContractForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    signForm,
    schedules,
    loadingSign,
    handleCreateSign,
    createSignProcSchedule,
    scheduleStatus
  } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = signForm;

  return (
    <>
      <hr className="md:col-span-2" />
      <FormControl className="md:col-span-2">
        <FormLabel
          className={`uppercase font-bold text-sm text-red-500 mb-4`}
        >
          {t("LABEL.PROCUREMENT_SCHEDULER_SIGNEE")}
        </FormLabel>

        <div className="md:col-span-2 grid md:grid-cols-2 gap-6 items-center">
          {/* Proposeed By */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_SCHEDULER_PROPOSED_BY")}
            </FormLabel>
            {isEdit ? (
              <InputText
                name="proposedBy"
                className={`w-full text-sm ${
                  errors?.proposedBy && "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.PROCUREMENT_SCHEDULER_PROPOSED_BY")}
                {...register("proposedBy", {
                  required: t("INVALID.REQUIRED", {
                    field: t("LABEL.PROCUREMENT_SCHEDULER_PROPOSED_BY"),
                  }),
                })}
              />
            ) : (
              <FormDescription>{schedules[0]?.proposedBy}</FormDescription>
            )}
            {errors?.proposedBy && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.proposedBy?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Position */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_SCHEDULER_POSITION")}
            </FormLabel>
            {isEdit ? (
              <InputText
                name="proposedPosition"
                className={`w-full text-sm ${
                  errors?.proposedPosition &&
                  "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.PROCUREMENT_SCHEDULER_POSITION")}
                {...register("proposedPosition", {
                  required: t("INVALID.REQUIRED", {
                    field: t("LABEL.PROCUREMENT_SCHEDULER_POSITION"),
                  }),
                })}
              />
            ) : (
              <FormDescription>
                {schedules[0]?.proposedPosition}
              </FormDescription>
            )}
            {errors?.proposedPosition && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.proposedPosition?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Approved By */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_SCHEDULER_APPROVED_BY")}
            </FormLabel>
            {isEdit ? (
              <InputText
                name="approvedBy"
                className={`w-full text-sm ${
                  errors?.approvedBy && "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.PROCUREMENT_SCHEDULER_APPROVED_BY")}
                {...register("approvedBy", {
                  required: t("INVALID.REQUIRED", {
                    field: t("LABEL.PROCUREMENT_SCHEDULER_APPROVED_BY"),
                  }),
                })}
              />
            ) : (
              <FormDescription>{schedules[0]?.approvedBy}</FormDescription>
            )}
            {errors?.approvedBy && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.approvedBy?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Position */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_SCHEDULER_POSITION")}
            </FormLabel>
            {isEdit ? (
              <InputText
                name="approvedPosition"
                className={`w-full text-sm ${
                  errors?.approvedPosition &&
                  "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.PROCUREMENT_SCHEDULER_POSITION")}
                {...register("approvedPosition", {
                  required: t("INVALID.REQUIRED", {
                    field: t("LABEL.PROCUREMENT_SCHEDULER_POSITION"),
                  }),
                })}
              />
            ) : (
              <FormDescription>
                {schedules[0]?.approvedPosition}
              </FormDescription>
            )}
            {errors?.approvedPosition && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.approvedPosition?.message}
              </FormHelperText>
            )}
          </FormControl>
          {isEdit ? (
            <Button
              size="sm"
              colorScheme="teal"
              isLoading={loadingSign}
              onClick={signForm.handleSubmit(createSignProcSchedule)}
            >
              {t("LABEL.SAVE")}
            </Button>
          ) : (
            scheduleStatus?.submittedAt && !scheduleStatus?.shouldBeReview ? (
              <>
              </>
            ) : 
            (
              <PrivateComponent accessName="BUYER_EDIT">
                <Button
                  size="sm"
                  colorScheme="gray"
                  isLoading={loadingSign}
                  onClick={() => handleCreateSign(true, schedules)}
                  disabled={!schedules[0]?.registrationStart ? true : false}
                >
                  {t("LABEL.EDIT", { field: t("LABEL.PROCUREMENT_SIGN_CONTRACT") })}
                </Button>
              </PrivateComponent>
            )
          )}
        </div>
      </FormControl>
    </>
  );
};

export default SignContractForm;
