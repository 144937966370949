import { Button, Checkbox, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { ReqRequisitionContext } from "./Controller";
import CheckboxGroup from "../../../../../components/inputForm/CheckboxGroup";
import moment from "moment";
import numeral from "numeral";
import { useWatch } from "react-hook-form";

const Row = ({ data, currency }) => {
  const { t } = useTranslation();
  const { form } = useContext(ReqRequisitionContext);
  const reqRequisitionMaterialsWatch = useWatch({ control: form.control, name: "reqRequisitionMaterials" });

  // Manually add error if Req Requisition is empty
  const handleError = () => {
    const newRequisition = reqRequisitionMaterialsWatch;
    if (!newRequisition?.length)
      form?.setError("reqRequisitionMaterials", {
        message: t("INVALID.REQUIRED", { field: t("LABEL.REQ_REQUISITION") }),
      });
    else form?.clearErrors("reqRequisitionMaterials");
  };
  // Initial error
  useEffect(() => {
    handleError();
  }, [reqRequisitionMaterialsWatch]);

  return (
    <>
      <Tr className="hover:bg-gray-50 bg-gray-50 font-semibold">
        <Td>{data?.reqRequisitionCode}</Td>
        <Td>{data?.requisitioner?.description}</Td>
        <Td>{moment(data?.createdAt).format("DD/MM/YYYY")}</Td>
      </Tr>
      {data?.reqRequisitionMaterial?.map((material, iMaterial) => (
        <Tr key={iMaterial}>
          <Td colSpan={4}>
            <div className="flex justify-between items-center">
              <Checkbox
                className="m-1 w-10"
                value={material.reqRequisitionMaterialId}
                isDisabled={currency && material?.currency?.currency !== currency?.currency}
              />
              <span className="flex-1">{material.material.description}</span>
              <span className="flex-1">Qty: {material.quantity}</span>
              <span className="flex-1">
                {material.currency.currency} {numeral(material.materialPrice).format("0,0.00")}
              </span>
            </div>
          </Td>
        </Tr>
      ))}
    </>
  );
};

const ReqRequisitionTable = () => {
  const { reqRequisition, loadingReqRequisition, totalData, limit, tableForm, form } =
    useContext(ReqRequisitionContext);
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = form;

  const watchTableForm = tableForm.watch();
  const reqRequisitionMaterialsWatch = useWatch({ control, name: "reqRequisitionMaterials" });

  // Active Currency
  const activeCurrency = reqRequisition
    ?.map((requisition) => requisition?.reqRequisitionMaterial)
    ?.flat()
    ?.find(
      (requisitionMaterial) => requisitionMaterial?.reqRequisitionMaterialId === reqRequisitionMaterialsWatch[0]
    )?.currency;

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <Table variant="simple">
        <Tbody>
          {loadingReqRequisition ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !reqRequisition.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            <CheckboxGroup name="reqRequisitionMaterials" control={control}>
              {reqRequisition.map((admin, iReqRequisition) => (
                <Row key={iReqRequisition} data={admin} currency={activeCurrency} />
              ))}
            </CheckboxGroup>
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-between">
        <span className={`${errors?.reqRequisitionMaterials?.message && "text-red-500"} `}>
          {errors?.reqRequisitionMaterials?.message || t("TEXT.REQUISITION_SAME_CURRENCY")}
        </span>
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default ReqRequisitionTable;
