import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AdminNew from "./components/AdminNew";
import AdminFilter from "./components/AdminFilter";
import AdminTable from "./components/AdminTable";
import AdminController, { AdminContext } from "./Controller";
import AdminDetail from "./components/AdminDetail";

const Admin = () => {
  const { t } = useTranslation();
  const { isDetailMenu, isCreate } = useContext(AdminContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("Admin")}</h2>
      </div>

      {isCreate ? (
        <AdminNew />
      ) : isDetailMenu ? (
        <AdminDetail />
      ) : (
        <div className="grid md:grid-cols-4 gap-4 md:gap-8">
          <div>
            <AdminFilter />
          </div>
          <div className="md:col-span-3">
            <AdminTable />
          </div>
        </div>
      )}
    </div>
  );
};

export default () => (
  <AdminController>
    <Admin />
  </AdminController>
);
