import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { RegisterContext } from "../../../Controller";

export const BasicInformationContext = createContext();

const BasicInformationController = ({ children }) => {
  const { basicInformationForm } = useContext(RegisterContext);

  // -------------------------------
  // Master
  // -------------------------------

  // Company Types
  const [companyTypes, setCompanyTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get("/company-types").then((res) => {
      if (mounted) {
        setCompanyTypes(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // ISIC / KBLI
  const [isic, setIsic] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get("/isic").then((res) => {
      if (mounted) {
        setIsic(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Sub ISIC / KBLI
  const businessField = basicInformationForm.watch("businessField");
  const [subIsic, setSubIsic] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/isic/${businessField}`).then((res) => {
      if (mounted) {
        setSubIsic(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, [businessField]);

  // -------------------------------
  // Validation
  // -------------------------------

  // Company Name Availability
  const companyName = basicInformationForm.watch("companyName");
  const [isNameAvailable, setNameAvailable] = useState();
  const [loadingNameAvailable, setLoadingNameAvailable] = useState(false);
  const [nameAvailableTimeout, setNameAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkCompanyName = () => {
      setLoadingNameAvailable(true);

      axios
        .post("/company/check-company-name", {
          companyName,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setNameAvailable(available);
        })
        .finally(() => {
          setLoadingNameAvailable(false);
          basicInformationForm.trigger("companyName");
        });
    };

    clearTimeout(nameAvailableTimeout);
    const newTimeout = setTimeout(() => {
      companyName ? checkCompanyName() : setNameAvailable();
    }, 500);
    setNameAvailableTimeout(newTimeout);

    return () => {
      mounted = false;
    };
  }, [companyName]);

  return (
    <BasicInformationContext.Provider value={{ isNameAvailable, loadingNameAvailable, companyTypes, isic, subIsic }}>
      {children}
    </BasicInformationContext.Provider>
  );
};

export default BasicInformationController;
