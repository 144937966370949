import React, { useContext } from "react";
import AuctionController, { AuctionContext } from "./Controller";
import AuctionDropdown from "./components/AuctionDropdown";
import { useTranslation } from "react-i18next";
import AuctionOverview from "./components/AuctionOverview";
import { BiCaretLeftCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { userRouteSlug } from "../../../routes/userRoutes";

const Auction = () => {
  const { t } = useTranslation();
  const { activeSection, auction } = useContext(AuctionContext);

  const PROCUREMENT_URL = userRouteSlug.PANEL + userRouteSlug.PROCUREMENT + "/" + auction?.procurement?.procurementNumber + "/detail" + userRouteSlug.AUCTION;

  return (
    <div className="flex flex-col gap-4 md:gap-6 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <Link to={PROCUREMENT_URL}>
          <BiCaretLeftCircle className="text-xl text-gray-500 hover:text-gray-700" />
        </Link>
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.AUCTION")}</h2>
      </div>

      <AuctionOverview />

      <div className="grid md:grid-cols-4 gap-6">
        {activeSection?.name !== "Auction" && (
          <div>
            <AuctionDropdown />
          </div>
        )}

        <div className={`${activeSection?.name === "Auction" ? "md:col-span-4" : "md:col-span-3"}  `}>
          {activeSection && <activeSection.Component />}
        </div>
      </div>
    </div>
  );
};

export default () => (
  <AuctionController>
    <Auction />
  </AuctionController>
);
