import { InputMask as InputMaskComponent } from "primereact/inputmask";
import { useController } from "react-hook-form";

const InputMask = ({ control, name, controlProps = {}, ...props }) => {
  const { field } = useController({
    name,
    control,
    ...controlProps,
  });

  return (
    <InputMaskComponent
      {...props}
      {...field}
      className={`w-full text-sm ${props?.isError && "border-red-400 border-2"} ${props?.disabled && "bg-gray-100"} ${
        props?.className
      }`}
    />
  );
};

export default InputMask;
