import { Button, Spinner } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VendorContext } from "../../Controller";
import moment from "moment";

const VendorSchedule = () => {
  const { procurementSchedule, loadingProcurementSchedule } = useContext(VendorContext);
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-md p-4 flex justify-between items-center">
      {loadingProcurementSchedule ? (
        <Spinner color="red" />
      ) : (
        <>
          <span>
            {procurementSchedule && moment(procurementSchedule?.registrationStart).isBefore(new Date()) ? (
              <div className="flex flex-col gap-1">
                <span className="text-gray-700 font-semibold text-base">{t("TEXT.VENDOR_SCHEDULE_ON_GOING")}</span>
                <span className="text-gray-700">{t("TEXT.VENDOR_SCHEDULE_ON_GOING_NOTE")}</span>
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <span className="text-gray-700 font-semibold text-base">{t("TEXT.NO_VENDOR_SCHEDULE")}</span>
                <span className="text-gray-700">{t("TEXT.NO_VENDOR_SCHEDULE_NOTE")}</span>
              </div>
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default VendorSchedule;
