import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";

const BidEvaluationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { prequalificationForm, schedules } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {schedules[0]?.registrationStart ? (
        <>
          {/* Bid Evaluation */}
          <hr className="md:col-span-2" />
          <FormControl className="md:col-span-2">
            <FormLabel
              className={`uppercase font-bold text-sm text-red-500 mb-4`}
            >
              {t("LABEL.PROCUREMENT_BID_EVALUATION")}
            </FormLabel>

            <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
              {/* Start Evaluation */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_START", {
                    field: t("LABEL.PROCUREMENT_BID_EVALUATION"),
                  })}
                </FormLabel>

                <FormDescription>
                  {schedules[0]?.tenderEvaluationStart &&
                    moment(
                      new Date(schedules[0]?.tenderEvaluationStart)
                    ).format("LLL")}
                </FormDescription>

                {errors?.tenderEvaluationStart && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.tenderEvaluationStart?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* End Evaluation */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_END", {
                    field: t("LABEL.PROCUREMENT_BID_EVALUATION"),
                  })}
                </FormLabel>

                <FormDescription>
                  {schedules[0]?.tenderEvaluationEnd &&
                    moment(new Date(schedules[0]?.tenderEvaluationEnd)).format(
                      "LLL"
                    )}
                </FormDescription>

                {errors?.tenderEvaluationEnd && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.tenderEvaluationEnd?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </FormControl>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default BidEvaluationForm;
