import numeral from 'numeral';
import { FormControl, FormHelperText } from '@chakra-ui/react';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

const ItemCard = ({ idx, material, idxSubmitted, offer, setPriceInputs, priceInputs, isEdit, errors }) => {
	const { t } = useTranslation();

	return (
		<div className="bg-gradient-to-br from-color0 via-color1 to-color2 rounded-md shadow-lg" style={{ padding: '3px' }}>
			<div className="flex justify-between px-2 py-1 rounded-md bg-white">
				<div className="w-1/4">
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t('LABEL.DESCRIPTION')}</div>
						<div style={{ maxHeight: '70px', overflow: 'auto' }}>{material.description}</div>
					</div>
				</div>
				<div className="w-1/3 flex flex-col gap-1">
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">Qty</div>
						<div className="w-2/3">{numeral(+material.qty).format('0,0')}</div>
					</div>
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">UoM</div>
						<div className="w-2/3">{`${material.unit} (${material.unitDescription})`}</div>
					</div>
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">{t('LABEL.CURRENCY')}</div>
						<div className="w-2/3">{`${material.currency.currency} (${material.currency.description})`}</div>
					</div>
				</div>
				<div className="w-1/3 flex flex-col gap-1">
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t('LABEL.PRICE')}</div>
						<div className="font-semibold">
							{idxSubmitted !== null && !isEdit ? (
								<>
									{numeral(+offer?.procurementCommercialOfferRequisitionMaterials[idxSubmitted].price).format('0,0.00')} {material.currency.currency}
								</>
							) : (
								<FormControl>
									<InputNumber
										className={`text-sm ${errors[idx] && 'border-2 border-red-400 shadow-none'}`}
										mode="decimal"
										locale="de-DE"
										minFractionDigits={2}
										value={priceInputs[idx] === '' ? undefined : priceInputs[idx]}
										placeholder="0,00"
										onChange={(e) => {
											if (!e.value) {
												priceInputs[idx] = '';
											} else {
												priceInputs[idx] = e.value;
											}
											setPriceInputs([...priceInputs]);
										}}
									/>
									{errors[idx] && <FormHelperText className="text-green-600 text-xs">{errors[idx]}</FormHelperText>}
								</FormControl>
							)}
						</div>
					</div>
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t('LABEL.MATERIAL_PRICE')}</div>
						<div className="font-bold">
							{priceInputs[idx] === '' ? (
								'Belum ada'
							) : (
								<>
									{numeral(+priceInputs[idx] * material.qty).format('0,0.00')} {material.currency.currency}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ItemCard;
