import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { AuctionContext } from "../../../Controller";

export const VendorContext = createContext();
const { Provider } = VendorContext;

const VendorController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { auction } = useContext(AuctionContext);
  const procurement = auction?.procurement;
  const { token } = useContext(AuthContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        requisitionerCode: newForm?.requisitionerCode,
        description: newForm?.description,
      });
    }
  };

  const scheduleForm = useForm();
  const resetScheduleForm = (newForm) => {
    if (newForm) {
      scheduleForm.reset({
        dateStart: new Date(newForm?.dateStart || null),
        dateEnd: new Date(newForm?.dateEnd || null),
      });
    }
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadVendor(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Vendor
  const [vendor, setVendor] = useState([]);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [reloadVendor, setReloadVendor] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getVendor = async () => {
      setLoadingVendor(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-auction/auction-vendor/${auction?.procurementAuctionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newAuctionVendors = res?.data?.auctionVendors;
            setVendor(newAuctionVendors);
          }
        })
        .finally(() => {
          setReloadVendor(false);
          setLoadingVendor(false);
        });
    };

    token && auction && reloadVendor && getVendor();

    return () => {
      mounted = false;
    };
  }, [token, reloadVendor, auction]);

  // Schedule
  const [procurementSchedule, setProcurementSchedule] = useState([]);
  const [loadingProcurementSchedule, setLoadingProcurementSchedule] = useState(false);
  const [reloadProcurementSchedule, setReloadProcurementSchedule] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getProcurementSchedule = () => {
      setLoadingProcurementSchedule(true);

      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setProcurementSchedule(res?.data?.procurementSchedule);
          }
        })
        .finally(() => {
          setLoadingProcurementSchedule(false);
          setReloadProcurementSchedule(false);
        });
    };

    token && procurementNumber && reloadProcurementSchedule && getProcurementSchedule();

    return () => {
      mounted = false;
    };
  }, [token, procurementNumber, reloadProcurementSchedule]);

  // ---------------------------
  // Master
  // ---------------------------

  // Vendor
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios
        .get(`company`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setVendors(res?.data?.companies);
          }
        });

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createVendor = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.error === "") {
          toast({
            description: t("INVALID.AVAILABLE", {
              field: t("LABEL.VENDOR"),
            }),
            position: "top-right",
            status: "error",
            isClosable: true,
          });
          setCreate(true);
        } else {
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
          setCreate(false);
        }
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadVendor(true);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [vendorDelete, setVendorDelete] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteVendor = () => {
    setLoadingDelete(true);

    axios
      .delete(`${process.env.REACT_APP_TMS_API}/procurement-vendor/${vendorDelete?.procurementVendorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadVendor(true);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const handleDelete = (isOpen, vendor = null) => {
    setModalDeleteOpen(isOpen);
    isOpen && setVendorDelete(vendor);
  };

  // -------------------------------
  // Update Vendor Schedule
  // -------------------------------

  const [isUpdateSchedule, setUpdateSchedule] = useState(false);
  const handleUpdateSchedule = (isOpen) => {
    setUpdateSchedule(isOpen);
    isOpen && resetScheduleForm(procurementSchedule);
  };
  const [loadingUpdateSchedule, setLoadingUpdateSchedule] = useState(false);
  const updateSchedule = (payload) => {
    setLoadingUpdateSchedule(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}`,
        {
          ...payload,
          scheduleType: "VENDOR_REGISTRATION",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setUpdateSchedule(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdateSchedule(false);
        setReloadProcurementSchedule(true);
      });
  };

  return (
    <Provider
      value={{
        tableForm,
        vendor,
        loadingVendor,
        totalData,
        limit,

        vendors,
        procurementSchedule,
        loadingProcurementSchedule,

        form,
        scheduleForm,

        isCreate,
        handleCreate,
        loadingCreate,
        createVendor,

        isModalDeleteOpen,
        handleDelete,
        loadingDelete,
        deleteVendor,

        isUpdateSchedule,
        handleUpdateSchedule,
        loadingUpdateSchedule,
        updateSchedule,
      }}
    >
      {children}
    </Provider>
  );
};

export default VendorController;
