import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsArrowLeftCircle } from "react-icons/bs";
import { AdminContext } from "../Controller";
import Form from "./Form";
import ModalDelete from "./ModalDelete";

const AdminDetail = () => {
  const { t } = useTranslation();
  const { isEdit, setEdit, handleDetailMenu, form, loadingUpdate, updateAdmin, activeAdmin, setModalDeleteOpen } =
    useContext(AdminContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <div className="md:col-span-2 flex justify-between items-center gap-2">
          <button onClick={() => handleDetailMenu(false)}>
            <BsArrowLeftCircle className="text-xl text-gray-600" />
          </button>

          <>
            {isEdit ? (
              <div className="flex gap-2">
                <Button size="xs" onClick={() => setEdit(false)}>
                  {t("LABEL.CANCEL")}
                </Button>
                <Button size="xs" colorScheme="teal" isLoading={loadingUpdate} onClick={form.handleSubmit(updateAdmin)}>
                  {t("LABEL.SAVE")}
                </Button>
              </div>
            ) : (
              <div className="flex gap-2">
                <button onClick={() => setEdit(true)}>
                  <BiEdit className="text-lg text-gray-500" />
                </button>
                {!activeAdmin?.roles?.includes("Super Admin") && (
                  <button onClick={() => setModalDeleteOpen(true)}>
                    <BiTrash className="text-lg text-red-500" />
                  </button>
                )}
              </div>
            )}
          </>
        </div>

        <Form isEdit={isEdit} />
        <ModalDelete />
      </div>
    </motion.div>
  );
};

export default AdminDetail;
