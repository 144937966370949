import { Spinner } from "@chakra-ui/react";
import React, { useContext } from "react";
import { BiUser } from "react-icons/bi";
import { AccountContext } from "../Controller";

const AccountOverview = () => {
  const { user, loadingUser } = useContext(AccountContext);

  return (
    <div className="p-4 bg-gradient-primary text-white rounded-md flex items-center md:col-span-2">
      {loadingUser ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <BiUser className="text-4xl" />
          <div className="flex flex-col">
            <span className="font-bold text-2xl">{user?.name}</span>
            <span>{user?.company?.companyName}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountOverview;
