import { Button, FormControl, FormHelperText, FormLabel, Textarea } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiPaperPlane } from 'react-icons/bi';
import FormDescription from '../../../../../../../../../components/inputForm/FormDescription';
import SelectButton from '../../../../../../../../../components/inputForm/SelectButton';
import TitleBar from '../../../../../../../../../components/TitleBar';
import { HelperContext } from '../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../Controller';
import ConfirmationModal from '../../Offer/components/ConfirmationModal';
import { useObjectionContext } from '../Controller';

const EvaluationForm = ({ isEdit }) => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { setAnyObjectionApproved } = useContext(ProcurementDetailContext);
	const { activeObjectionIdx, objections, setObjections } = useObjectionContext();

	const [openModal, setOpenModal] = useState(false);

	const {
		control,
		register,
		handleSubmit,
		getValues,
		trigger,
		watch,
		formState: { errors },
	} = useForm();

	register('approve', { required: 'Approval wajib diisi' });
	const approve = watch('approve');

	const [loading, setLoading] = useState(false);

	const activeObjection = objections?.[activeObjectionIdx];

	const submit = async (data) => {
		const payload = {
			procurementObjectionId: activeObjection?.id,
			isApproved: data?.approve === 'ACCEPT',
			description: data.description,
		};
		setLoading(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-objection-evaluation`,
			method: 'POST',
			payload,
		});
		setLoading(false);
		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
    
		setAnyObjectionApproved(data?.approve === 'ACCEPT');
		objections[activeObjectionIdx].procurementObjectionEvaluation = response?.data?.procurementObjectionEvaluation;
		setObjections([...objections]);

		showToast(t('INSERT-200'));
		setOpenModal(false);
	};

	return (
		<div className="flex flex-col mt-4">
			<TitleBar title="Persetujuan Sanggahan" className={'m-0'} />
			<FormControl isRequired={isEdit}>
				<FormLabel className={`uppercase font-semibold text-xs ${!isEdit && 'text-red-500'}`}>Komentar</FormLabel>
				{!activeObjection?.procurementObjectionEvaluation ? (
					<>
						<Textarea
							name="offerIncotermDescription"
							className={`w-full text-sm ${errors.description && 'border-2 border-red-400 shadow-none'}`}
							placeholder={'Komentar'}
							required={true}
							{...register('description', { required: `${'Komentar'} wajib diisi` })}
						/>

						{errors.description && <FormHelperText className="text-red-400 text-xs">{errors.description.message}</FormHelperText>}
					</>
				) : (
					<FormDescription>{activeObjection?.procurementObjectionEvaluation?.description}</FormDescription>
				)}
			</FormControl>

			<div className="mt-4 flex">
				<FormControl isRequired={isEdit} className="w-1/2">
					{!activeObjection?.procurementObjectionEvaluation ? (
						<>
							<SelectButton
								className={`w-full grid grid-cols-2 ${errors.approve && 'border-2 border-red-400 shadow-none'}`}
								control={control}
								name="approve"
								value={getValues('approve')}
								options={[
									{ label: t('LABEL.ACCEPT'), value: 'ACCEPT' },
									{ label: t('LABEL.REJECT'), value: 'REJECT' },
								]}
							/>

							{errors.approve && <FormHelperText className="text-red-400 text-xs">{errors.approve.message}</FormHelperText>}
						</>
					) : (
						<></>
					)}
				</FormControl>
				<div className="w-1/2 flex items-center justify-end pr-4">
					{!activeObjection?.procurementObjectionEvaluation ? (
						<Button
							onClick={async () => {
								const valid = await trigger('', { shouldFocus: true });
								if (!valid) return;
								setOpenModal(true);
							}}
							className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 shadow-lg text-sm"
						>
							<BiPaperPlane className="text-xl" />
							{t('LABEL.SUBMIT', { field: '' })}
						</Button>
					) : activeObjection?.procurementObjectionEvaluation?.isApproved ? (
						<div className="text-green-500 text-xl font-bold italic">{t('LABEL.APPROVED')}</div>
					) : (
						<div className="text-red-600 text-xl font-bold italic">{t('LABEL.REJECTED')}</div>
					)}
				</div>
			</div>
			<ConfirmationModal
				loading={loading}
				onYes={handleSubmit(submit)}
				title={approve === 'ACCEPT' ? 'Sanggahan Disetujui' : 'Sanggahan Ditolak'}
				body={''}
				isOpen={openModal}
				onNo={() => setOpenModal(false)}
			/>
		</div>
	);
};

export default EvaluationForm;
