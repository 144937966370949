import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { InputTextarea } from "primereact/inputtextarea";
import { NegotiationContext } from "../../Controller";
import { motion } from "framer-motion";
import { BsArrowLeftCircle } from "react-icons/bs";

const FormNotes = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    formNotes,
    createNotesNegotiation,
    loadingNotesNew,
    handleCreateNotes,
  } = useContext(NegotiationContext);
  const {
    register,
    formState: { errors },
  } = formNotes;

  return (
    <>
      <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 100 }}
        className="bg-white rounded-md p-6"
      >
        <div className="w-full grid md:grid-cols-1 gap-6">
          <div className="md:col-span-2 flex justify-between items-center gap-2">
            <button onClick={() => handleCreateNotes(false)}>
              <BsArrowLeftCircle className="text-xl text-gray-600" />
            </button>
            <Button
              size="sm"
              colorScheme="teal"
              isLoading={loadingNotesNew}
              onClick={formNotes.handleSubmit(createNotesNegotiation)}
            >
              {t("LABEL.SAVE")}
            </Button>
          </div>
          {/* Notes */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.NOTES")}
            </FormLabel>

            <InputTextarea
              className={`w-full text-sm ${
                errors?.notes && "border-2 border-red-400 shadow-none"
              }`}
              placeholder={t("LABEL.NOTES")}
              {...register("notes", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.NOTES") }),
              })}
              rows={5}
              cols={30}
            />
            {errors?.notes && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.notes?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </motion.div>
    </>
  );
};

export default FormNotes;
