import React from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiBuilding, BiHistory } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VendorMenuList = ({ to = "#", title = "", Icon = () => "", bottomLine = true }) => {
  return (
    <Link to={to}>
      <div className="flex justify-between items-center py-4">
        <div className="flex items-center gap-2">
          <Icon className="text-xl text-red-500" />
          <p className="font-semibold text-gray-600">{title}</p>
        </div>
        <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
      </div>

      {bottomLine && <hr />}
    </Link>
  );
};

const VendorMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-md overflow-hidden px-4">
      <VendorMenuList Icon={BiBuilding} title={t("LABEL.COMPANY_PROFILE")} to="profile/general" />
      <VendorMenuList Icon={BiHistory} title={t("LABEL.ACTIVITY")} to="activity" bottomLine={false} />
    </div>
  );
};

export default VendorMenu;
