import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const BranchContext = createContext();
const { Provider } = BranchContext;

const BranchController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { regCode, setReloadCompanyOverview, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      const phone = newForm?.phoneNumber?.split(" ");
      const fax = newForm?.fax?.split(" ");

      form.reset({
        country: newForm?.country?.regionCode,
        province: newForm?.provinceObj ? newForm?.provinceObj?.regionCode : newForm?.province,
        city: newForm?.cityObj ? newForm?.cityObj?.regionCode : newForm?.city,
        district: newForm?.districtObj ? newForm?.districtObj?.regionCode : newForm?.district,
        address: newForm?.address,
        postalCode: newForm?.postalCode,
        phoneCode: phone?.[0] || "+62",
        phoneNumber: phone?.[1] || null,
        faxCode: fax?.[0] || "+62",
        faxNumber: fax?.[1] || null,
        email: newForm?.email,
        website: newForm?.website,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeBranch, setActiveBranch] = useState();
  const handleDetailMenu = (isOpen, branch = null) => {
    setDetailMenu(isOpen);
    setActiveBranch(branch);
    isOpen && resetForm(branch);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Branches
  const [branches, setBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [reloadBranches, setReloadBranches] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getBranches = async () => {
      setLoadingBranches(true);
      axios
        .get(`/branch/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newBranches = res?.data?.branches;
            setBranches(newBranches);
            if (activeBranch) {
              const newActiveBranch = newBranches.find((branch) => branch?.branchId === activeBranch?.branchId) || null;
              setActiveBranch(newActiveBranch);
              resetForm(newActiveBranch);
            }
          }
        })
        .finally(() => {
          setReloadBranches(false);
          setLoadingBranches(false);
        });
    };

    userData && token && reloadBranches && getBranches();

    if (reloadBranches) {
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadBranches]);

  // -------------------------------
  // Master
  // -------------------------------

  // Countries
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/regions`).then((res) => {
      if (mounted) {
        setCountries(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Provinces
  const country = form.watch("country");
  const [provinces, setProvinces] = useState(null);
  useEffect(() => {
    let mounted = true;
    country !== activeBranch?.country?.regionCode && form.setValue("province", null);
    setProvinces(null);

    const getProvinces = () => {
      axios.get(`/regions?regionCode=${country}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setProvinces(res?.data?.data) : setProvinces(null);
        }
      });
    };

    country && getProvinces();

    return () => {
      mounted = false;
    };
  }, [country]);

  // Cities
  const province = form.watch("province");
  const [cities, setCities] = useState(null);
  useEffect(() => {
    let mounted = true;
    province !== activeBranch?.province && form.setValue("city", null);
    setCities(null);

    const getCities = () => {
      axios.get(`/regions?regionCode=${province}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setCities(res?.data?.data) : setCities(null);
        }
      });
    };

    province && getCities();
    return () => {
      mounted = false;
    };
  }, [province]);

  // Districts
  const city = form.watch("city");
  const [districts, setDistricts] = useState(null);
  useEffect(() => {
    let mounted = true;
    city !== activeBranch?.city && form.setValue("district", null);
    setDistricts(null);

    const getDistricts = () => {
      axios.get(`/regions?regionCode=${city}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setDistricts(res?.data?.data) : setDistricts(null);
        }
      });
    };

    city && getDistricts();

    return () => {
      mounted = false;
    };
  }, [city]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createBranch = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`branch/${regCode}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadBranches(true);
        setReloadCompanyOverview(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeBranch);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateBranch = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(`branch/${regCode}/${activeBranch?.branchId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadBranches(true);
        setReloadCompanyOverview(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteBranch = () => {
    setLoadingDelete(true);

    axios
      .delete(`branch/${regCode}/${activeBranch?.branchId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadBranches(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingBranches,
        branches,
        activeBranch,

        countries,
        provinces,
        cities,
        districts,

        isCreate,
        handleCreate,
        loadingCreate,
        createBranch,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateBranch,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteBranch,
      }}
    >
      {children}
    </Provider>
  );
};

export default BranchController;
