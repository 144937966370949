import { Box, ScaleFade } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCaretLeftCircle } from 'react-icons/bi';
import { ProcurementOfferEvaluationContext } from '../../Controller';
import OfferTable from '../OfferTable';
import OfferFormEvaluation from './components/OfferForm';
import OfferRequirementEvaluation from './components/OfferRequirement';
import AdministratifEvaluationController, { AdministratifEvaluationContext } from './Controller';

const AdministratifEvaluationPage = () => {
	const { t } = useTranslation();
	const { setHandleDetailAdministratifIdx } = useContext(ProcurementOfferEvaluationContext);
	const { offer } = useContext(AdministratifEvaluationContext);

	return (
		<div>
			{!offer && <OfferTable offerType="ADM" />}
			{offer && (
				<ScaleFade in={offer}>
					<div className="flex font-semibold items-center mb-3 gap-3">
						<BiCaretLeftCircle
							className="text-2xl cursor-pointer text-gray-500"
							onClick={() => {
								setHandleDetailAdministratifIdx(null);
							}}
						/>
						<div className="text-lg text-gray-500">{t('LABEL.OFFER_DETAIL')}</div>
					</div>
					<OfferFormEvaluation />
					<OfferRequirementEvaluation />
				</ScaleFade>
			)}
		</div>
	);
};

export default () => {
	return (
		<AdministratifEvaluationController>
			<AdministratifEvaluationPage />
		</AdministratifEvaluationController>
	);
};
