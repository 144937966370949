import React, { useContext } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import ReqRequisitionTable from "./ReqRequisitionTable";
import ReqRequisitionController, { ReqRequisitionContext } from "./Controller";

const ReqRequisitionForm = () => {
  const { t } = useTranslation();
  const { tableForm } = useContext(ReqRequisitionContext);
  const { register } = tableForm;

  return (
    <>
      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4`}>{t("Material")}</FormLabel>

        <div className="flex flex-col">
          {/* Search */}
          <div className="p-input-icon-right md:col-span-2">
            <InputText
              className={`w-full text-sm`}
              placeholder={`${t("LABEL.SEARCH")} ${t("LABEL.REQUISITION_CODE")}`}
              {...register("search")}
            />
            <BiSearch />
          </div>

          {/* Req Requisition Table */}
          <ReqRequisitionTable />
        </div>
      </FormControl>
    </>
  );
};

export default () => (
  <ReqRequisitionController>
    <ReqRequisitionForm />
  </ReqRequisitionController>
);
