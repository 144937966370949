import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { Spinner } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { emailPattern } from "../../../../../../utils/regex.util";
import { RegisterContext } from "../../../Controller";
import AccountController, { AccountContext } from "./Controller";

const AccountForm = () => {
  const { t } = useTranslation();
  const { isEmailAvailable, loadingEmailAvailable } = useContext(AccountContext);
  const { accountForm: form } = useContext(RegisterContext);
  const {
    register,
    watch,
    formState: { errors },
  } = form;

  const password = watch("password");

  return (
    <>
      {/* User Name */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.YOURS", { field: t("LABEL.NAME") })}</FormLabel>
        <InputText
          className={`w-full text-sm ${errors?.userName && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("LABEL.YOURS", { field: t("LABEL.NAME") })}
          {...register("userName", {
            required: t("INVALID.REQUIRED", { field: t("LABEL.YOURS", { field: t("LABEL.NAME") }) }),
          })}
        />
        {errors?.userName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.userName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* User Email */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.YOURS", { field: "Email" })}</FormLabel>
        <div className="p-inputgroup">
          <InputText
            className={`w-full text-sm border-r-0 ${errors?.userEmail && "border-2 border-red-400 shadow-none"}`}
            placeholder="email@company.com"
            {...register("userEmail", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.YOURS", { field: "Email" }) }),
              pattern: {
                value: emailPattern,
                message: t("INVALID.EMAIL", { field: t("LABEL.YOURS", { field: "Email" }) }),
              },
              validate: {
                nameAvailable: (v) => isEmailAvailable || t("INVALID.AVAILABLE", { field: v }),
              },
            })}
          />
          <span
            className={`p-inputgroup-addon border-l-0 bg-transparent ${
              errors?.userEmail && "border-2 border-red-400 shadow-none"
            }`}
          >
            {loadingEmailAvailable ? (
              <Spinner size="xs" />
            ) : isEmailAvailable && !errors?.userEmail ? (
              <BsCheckLg className="text-green-500" />
            ) : (
              isEmailAvailable === false && <BsXLg className="text-red-500" />
            )}
          </span>
        </div>
        {errors?.userEmail ? (
          <FormHelperText className="text-red-400 text-xs">{errors?.userEmail?.message}</FormHelperText>
        ) : (
          <FormHelperText className="text-xs">{t("TEXT.EMAIL_FOR_ACTIVATION")}</FormHelperText>
        )}
      </FormControl>

      {/* Password */}
      <FormControl isRequired>
        <FormLabel className="text-sm">Password</FormLabel>
        <Password
          toggleMask
          className="w-full"
          inputClassName={`w-full text-sm ${errors?.password && "border-2 border-red-400 shadow-none"}`}
          placeholder="Password"
          {...register("password", {
            required: t("INVALID.REQUIRED", { field: "Password" }),
            minLength: { value: 8, message: t("INVALID.MIN_LENGTH", { field: "Password", length: 8 }) },
          })}
          onChange={(e) => form.setValue("password", e.target.value)}
        />
        {errors?.password && (
          <FormHelperText className="text-red-400 text-xs">{errors?.password?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Password Confirmation */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.PASSWORD_CONFIRMATION")}</FormLabel>
        <Password
          feedback={false}
          toggleMask
          className="w-full"
          inputClassName={`w-full text-sm ${errors?.passwordConfirmation && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("LABEL.PASSWORD_CONFIRMATION")}
          type="password"
          {...register("passwordConfirmation", {
            required: t("INVALID.REQUIRED", { field: t("LABEL.PASSWORD_CONFIRMATION") }),
            validate: { confirmPassword: (v) => v === password || t("INVALID.PASSWORD_CONFIRMATION") },
          })}
          onChange={(e) => form.setValue("passwordConfirmation", e.target.value)}
        />
        {errors?.passwordConfirmation && (
          <FormHelperText className="text-red-400 text-xs">{errors?.passwordConfirmation?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default () => (
  <AccountController>
    <AccountForm />
  </AccountController>
);
