import moment from 'moment';
import numeral from 'numeral';
import { InputText } from 'primereact/inputtext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, FormHelperText, Textarea } from '@chakra-ui/react';
import Select from '../../../../../../../../../../components/inputForm/Select';
import InputNumber from '../../../../../../../../../../components/inputForm/InputNumber';
import FormDescription from '../../../../../../../../../../components/inputForm/FormDescription';
import { CommercialContext } from './Controller';
import { ProcurementDetailContext } from '../../../../../Controller';
import Uploader from '../../../../../../../../../../components/inputForm/Uploader';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import FileAttachment from '../../../../../../../../../../components/FileAttachment';
import TitleBar from '../../../../../../../../../../components/TitleBar';

const CommercialForm = ({ isEdit, commercialOffer }) => {
	const { t } = useTranslation();
	const { form, incoterms } = useContext(CommercialContext);
	const { procurement } = useContext(ProcurementDetailContext);

	const {
		register,
		control,
		formState: { errors },
		getValues,
		setValue,
	} = form;

	const fieldName = {
		offerNumber: t('LABEL.OFFER_NUMBER'),
		offerDate: t('LABEL.OFFER_DATE'),
		offerExpired: t('LABEL.OFFER_EXPIRED'),
		offerLetter: t('LABEL.OFFER_LETTER'),
		offerNote: t('LABEL.OFFER_NOTE'),
		offerIncoterm: t('LABEL.OFFER_INCOTERM'),
		offerIncotermDescription: t('LABEL.OFFER_INCOTERM_DESCRIPTION'),
		offerGuaranteeValue: t('LABEL.OFFER_GUARANTEE_VALUE'),
		offerGuaranteeFile: t('LABEL.OFFER_GUARANTEE_FILE'),
		offerGuaranteeExpired: t('LABEL.OFFER_GUARANTEE_EXPIRED'),
	};

	register('offerGuaranteeFile', { required: procurement?.procurementSetting?.bidBond ? 'Berkas jaminan penawaran wajib diisi' : null });
	register('offerLetter', { required: `${fieldName.offerLetter} wajib diisi` });

	const chosenIncoterm = incoterms?.find((incoterm) => incoterm?.incotermCode === commercialOffer?.incotermId);

	return (
		<div>
			<TitleBar title={t('LABEL.CONCEPT')} />
			<div className="flex justify-between gap-5">
				<div className="w-1/2 flex flex-col gap-5">
					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerNumber}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerNumber}
									className={`w-full text-sm ${errors.offerNumber && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerNumber}
									required={true}
									{...register('offerNumber', { required: `${fieldName.offerNumber} wajib diisi` })}
								/>
								{errors.offerNumber && <FormHelperText className="text-red-400 text-xs">{errors.offerNumber.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{commercialOffer?.offerNumber}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerDate}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerDate}
									className={`w-full text-sm ${errors.offerDate && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerDate}
									required={true}
									type="date"
									{...register('offerDate', { required: `${fieldName.offerDate} wajib diisi` })}
								/>
								{errors.offerDate && <FormHelperText className="text-red-400 text-xs">{errors.offerDate.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{moment(new Date(commercialOffer?.startAt)).format('yyyy-MM-DD')}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerExpired}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerExpired}
									className={`w-full text-sm ${errors.offerExpired && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerExpired}
									required={true}
									type="date"
									{...register('offerExpired', { required: `${fieldName.offerExpired} wajib diisi` })}
								/>
								{errors.offerExpired && <FormHelperText className="text-red-400 text-xs">{errors.offerExpired.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{moment(new Date(commercialOffer?.expiredAt)).format('yyyy-MM-DD')}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerLetter}</FormLabel>

						{isEdit ? (
							<>
								<Uploader
									className={`${errors?.offerLetter && 'border-2 border-red-400 shadow-none'}`}
									label={fieldName.offerLetter}
									name={fieldName.offerLetter}
									fileList={getValues('offerLetter')}
									onChange={(files) => {
										setValue('offerLetter', files);
									}}
								/>
								{errors.offerLetter && <FormHelperText className="text-red-400 text-xs">{errors.offerLetter.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>
								<FileAttachment href={`${API_URLS.REACT_APP_FILESERVICE_API}/files/users/${commercialOffer?.letterFilename}`} />
							</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerNote}</FormLabel>

						{isEdit ? (
							<>
								<Textarea
									name={fieldName.offerNote}
									className={`w-full text-sm ${errors.offerNote && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerNote}
									required={true}
									{...register('offerNote', { required: `${fieldName.offerNote} wajib diisi` })}
								/>

								{errors.offerNote && <FormHelperText className="text-red-400 text-xs">{errors.offerNote.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{commercialOffer?.note}</FormDescription>
						)}
					</FormControl>
				</div>
				<div className="w-1/2 flex flex-col gap-5">
					<FormControl isRequired={procurement?.procurementSetting?.bidBond}>
						<FormLabel className={`${!isEdit && 'text-red-500'} ${isEdit && !procurement?.procurementSetting?.bidBond && 'text-gray-400'} uppercase font-semibold text-xs`}>
							{fieldName.offerGuaranteeValue}
						</FormLabel>

						{isEdit ? (
							<>
								<InputNumber
									disabled={!procurement?.procurementSetting?.bidBond}
									className={`w-full text-sm ${errors?.offerGuaranteeValue && 'border-2 border-red-400 shadow-none'}`}
									placeholder="0,00"
									name="offerGuaranteeValue"
									control={control}
									controlProps={{
										rules: { required: procurement?.procurementSetting?.bidBond ? t('INVALID.REQUIRED', { field: t('LABEL.PRICE') }) : null },
									}}
									isError={!!errors?.offerGuaranteeValue}
								/>
								{errors.offerGuaranteeValue && <FormHelperText className="text-red-400 text-xs">{errors.offerGuaranteeValue.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{!commercialOffer?.guaranteeValue ? 'Tidak ada' : numeral(+commercialOffer?.guaranteeValue).format('0,0.00')}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={procurement?.procurementSetting?.bidBond}>
						<FormLabel className={`${!isEdit && 'text-red-500'} ${isEdit && !procurement?.procurementSetting?.bidBond && 'text-gray-400'} uppercase font-semibold text-xs`}>
							{fieldName.offerGuaranteeFile}
						</FormLabel>

						{isEdit ? (
							<>
								<Uploader
									isDisabled={!procurement?.procurementSetting?.bidBond}
									className={`${errors?.offerGuaranteeFile && 'border-2 border-red-400 shadow-none'}`}
									label={fieldName.offerGuaranteeFile}
									name={fieldName.offerGuaranteeFile}
									fileList={getValues('offerGuaranteeFile')}
									onChange={(files) => {
										setValue('offerGuaranteeFile', files);
									}}
								/>
								{errors.offerGuaranteeFile && <FormHelperText className="text-red-400 text-xs">{errors.offerGuaranteeFile.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>
								{commercialOffer?.guaranteeFilename !== null ? <FileAttachment href={`${process.env.REACT_APP_FILESERVICE_API}/files/users/${commercialOffer?.guaranteeFilename}`} /> : <>Tidak ada</>}
							</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={procurement?.procurementSetting?.bidBond}>
						<FormLabel className={`${!isEdit && 'text-red-500'} ${isEdit && !procurement?.procurementSetting?.bidBond && 'text-gray-400'} uppercase font-semibold text-xs`}>
							{fieldName.offerGuaranteeExpired}
						</FormLabel>

						{isEdit ? (
							<>
								<InputText
									disabled={!procurement?.procurementSetting?.bidBond}
									name={fieldName.offerGuaranteeExpired}
									className={`w-full text-sm ${errors.offerGuaranteeExpired && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerGuaranteeExpired}
									required={true}
									type="date"
									{...register('offerGuaranteeExpired', { required: procurement?.procurementSetting?.bidBond ? 'Batas akhir jaminan penawaran wajib diisi' : null })}
								/>
								{errors.offerGuaranteeExpired && <FormHelperText className="text-red-400 text-xs">{errors.offerGuaranteeExpired.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{commercialOffer?.guaranteeExpiredAt ? moment(new Date(commercialOffer?.guaranteeExpiredAt)).format('yyyy-MM-DD') : <>Tidak ada</>}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerIncoterm}</FormLabel>

						{isEdit ? (
							<>
								<Select
									filter
									className="text-sm"
									control={control}
									controlProps={{
										rules: { required: 'Incoterm wajib diisi' },
									}}
									name="offerIncoterm"
									options={incoterms.map((incoterm) => ({
										label: `${incoterm?.incotermCode} - ${incoterm?.description}`,
										value: incoterm?.incotermCode,
									}))}
									placeholder={'Pilih Incoterm'}
									isSearchable
									isError={errors?.offerIncoterm}
								/>

								{errors.offerIncoterm && <FormHelperText className="text-red-400 text-xs">{errors.offerIncoterm.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{chosenIncoterm && chosenIncoterm?.incotermCode + ' - ' + chosenIncoterm?.description}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerIncotermDescription}</FormLabel>

						{isEdit ? (
							<>
								<Textarea
									name="offerIncotermDescription"
									className={`w-full text-sm ${errors.offerIncotermDescription && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerIncotermDescription}
									required={true}
									{...register('offerIncotermDescription', { required: `${fieldName.offerIncotermDescription} wajib diisi` })}
								/>

								{errors.offerIncotermDescription && <FormHelperText className="text-red-400 text-xs">{errors.offerIncotermDescription.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{commercialOffer.incotermDescription}</FormDescription>
						)}
					</FormControl>
				</div>
			</div>
		</div>
	);
};

export default CommercialForm;
