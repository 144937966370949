import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AuctionContext } from "../../../Controller";

export const ReqRequisitionContext = createContext();
const { Provider } = ReqRequisitionContext;

const ReqRequisitionController = ({ children }) => {
  const { auction } = useContext(AuctionContext);

  return <Provider value={{}}>{children}</Provider>;
};

export default ReqRequisitionController;
