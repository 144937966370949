import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaClipboardList, FaFile } from 'react-icons/fa';
import FileAttachment from '../../../../../../../../../components/FileAttachment';
import Uploader from '../../../../../../../../../components/inputForm/Uploader';
import TitleBar from '../../../../../../../../../components/TitleBar';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../Controller';
import ConfirmationModal from '../../Offer/components/ConfirmationModal';
import SchedulePanel from './SchedulePanel';
import { PrequalificationContext } from '../Controller';
import moment from 'moment';

const OverviewPage = () => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { userData } = useContext(AuthContext);
	const { procurementSchedule, loadingProcurementSchedule, procurement } = useContext(ProcurementDetailContext);
	const { loadingPrequalificationRequirement, prequalificationRequirement, vendorPrequalification, setVendorPrequalification, evaluation } = useContext(PrequalificationContext);

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const {
		setValue,
		register,
		getValues,
		formState: { errors },
		handleSubmit,
		trigger,
	} = useForm();
	register('prequalification', { required: !prequalificationRequirement?.isRequired ? false : `Prequalification wajib diisi` });

	const submit = async (data) => {
		const formData = new FormData();

		data.prequalification && formData.append('prequalification', data.prequalification[0]);

		const payload = {
			companyRegCode: userData.company.registrationCode,
			procurementNumber: procurement.procurementNumber,
			procurementRequirementId: prequalificationRequirement?.procurementRequirementId,
		};

		formData.append('data', JSON.stringify(payload));

		setLoading(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor-prequalification`,
			method: 'POST',
			payload: formData,
		});
		setLoading(false);

		if (!response?.success) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		showToast(t('INSERT-200'));
		setVendorPrequalification(response?.data?.procurementVendorPrequalification);
	};

	const isEnded = procurementSchedule?.prequalificationEnd && moment().unix() > moment(procurementSchedule?.prequalificationEnd).unix();
  const canSubmit = procurementSchedule?.prequalificationStart && moment().unix() > moment(procurementSchedule?.prequalificationStart).unix() && !isEnded

	return (
		<div className="flex flex-col gap-6">
			<div>
				<TitleBar icon={FaClipboardList} title="Jadwal Prakualifikasi" />
				<SchedulePanel start={procurementSchedule?.prequalificationStart} end={procurementSchedule?.prequalificationEnd} loading={loadingProcurementSchedule} />
			</div>
			<div>
				<TitleBar icon={FaFile} title="Dokumen Prakualifikasi" />

				<div className="mt-4">
					<div className="items-end flex flex-col">
						<div className="w-full">
							<Table>
								<Thead>
									<Tr>
										<Th className="text-red-500">{t('LABEL.DESCRIPTION')}</Th>
										<Th className="text-red-500 text-center">{t('LABEL.PROCUREMENT_REQUIREMENT_REQUIRED')}</Th>
										<Th className="text-red-500 text-center">{t('LABEL.DOCUMENT')}</Th>
										<Th className="text-red-500 text-center">{t('LABEL.STATUS')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{loadingPrequalificationRequirement ? (
										<Tr>
											<Td colSpan={4}>
												<div className="w-full h-full flex justify-center items-center">
													<Spinner color="red.500" />
												</div>
											</Td>
										</Tr>
									) : !prequalificationRequirement ? (
										<Tr>
											<Td colSpan={4}>
												<div className="w-full h-full flex justify-center items-center">
													<div className="text-center font-semibold">{t('ERROR.undefined')}</div>
												</div>
											</Td>
										</Tr>
									) : (
										<Tr>
											<Td>{prequalificationRequirement?.description}</Td>
											<Td className="text-center">{prequalificationRequirement?.isRequired ? t('LABEL.YES') : t('LABEL.NO')}</Td>
											<Td>
												<div className="flex justify-center">
													{!vendorPrequalification && !isEnded? (
														<Uploader
                              isDisabled={!canSubmit}
															className={`${errors?.prequalification && 'border-2 border-red-400 shadow-none'}`}
															label={'Upload Dokumen'}
															name={'prequalification'}
															fileList={getValues('prequalification')}
															onChange={(files) => {
																setValue('prequalification', files);
															}}
														/>
													) : vendorPrequalification ? (
														<FileAttachment href={`${API_URLS.REACT_APP_FILESERVICE_API}/files/users/${vendorPrequalification?.filename}`} />
													) : (
														<>{t('LABEL.NOT_SUBMITTED')}</>
													)}
												</div>
											</Td>
											<Td className="px-0">
												<div className="text-center flex justify-center">
													{!vendorPrequalification ? (
														isEnded ? (
															<div className='font-semibold italic'>{t('LABEL.NOT_QUALIFIED')}</div>
														) : (
															<>-</>
														)
													) : !evaluation ? (
														<div style={{ width: '250px' }}>Buyer akan mengevaluasi dokumen ini setelah jadwal berakhir</div>
													) : (
														<>
															{vendorPrequalification?.status !== 'ACCEPTED' ? (
																<div className="font-bold italic text-color0">{t('LABEL.NOT_QUALIFIED')}</div>
															) : (
																<div className="font-bold italic text-green-500">{t('LABEL.QUALIFIED')}</div>
															)}
														</>
													)}
												</div>
											</Td>
										</Tr>
									)}
								</Tbody>
							</Table>
						</div>
						{!vendorPrequalification && !(evaluation || moment().unix() > moment(procurementSchedule?.prequalificationEnd).unix()) && (
							<>
								<Button
                  disabled={!canSubmit}
									onClick={async () => {
										const val = await trigger();
										if (!val) return;
										setIsOpenModal(true);
									}}
									colorScheme="teal"
									className="mt-4"
								>
									{t('LABEL.SUBMIT_ONLY')}
								</Button>

								<ConfirmationModal onYes={handleSubmit(submit)} title="Upload Dokumen Prakualifikasi" isOpen={isOpenModal} loading={loading} onNo={() => setIsOpenModal(false)} />
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverviewPage;
