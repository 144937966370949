import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import BusinessPermitDetail from "./components/BusinessPermitDetail";
import BusinessPermitList from "./components/BusinessPermitList";
import BusinessPermitNew from "./components/BusinessPermitNew";
import BusinessPermitController, { BusinessPermitContext } from "./Controller";

const BusinessPermit = () => {
  const { isDetailMenu, isCreate } = useContext(BusinessPermitContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <BusinessPermitNew /> : isDetailMenu ? <BusinessPermitDetail /> : <BusinessPermitList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <BusinessPermitController>
    <BusinessPermit />
  </BusinessPermitController>
);
