import React, { useContext } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";
import { ProcurementDetailContext } from "../Controller";
import ModalVendorProcurementAcceptance from "./ModalVendorProcurementAcceptance";

const VendorProcurementAcceptance = () => {
  const { t } = useTranslation();
  const { handleAcceptanceOpen } = useContext(ProcurementDetailContext);

  return (
    <div className="p-4 rounded-md bg-white">
      <div className="w-full flex gap-4">
        <div className="flex-1 flex flex-col justify-center">
          <p className="font-semibold text-gray-600">{t("TEXT.ACCEPT_PROCUREMENT_OFFER")}</p>
        </div>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            colorScheme="red"
            className="transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100"
            onClick={() => handleAcceptanceOpen(true, "reject")}
          >
            {t("LABEL.REJECT")}
          </Button>
          <Button
            size="sm"
            colorScheme="green"
            className="transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100"
            onClick={() => handleAcceptanceOpen(true, "approve")}
          >
            {t("LABEL.APPROVE")}
          </Button>
        </div>
      </div>

      <ModalVendorProcurementAcceptance />
    </div>
  );
};

export default VendorProcurementAcceptance;
