import React, { useContext } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DeedContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeDeed, form, deedTypes, deeds } = useContext(DeedContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") }) }),
    },
  });

  const activeDeeds = deeds?.filter((deed) => deed?.isApproved && !deed?.isDelete);

  return (
    <>
      {/* Deed Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DEED_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.DEED_TYPE") }) },
            }}
            name="deedType"
            options={deedTypes.map((deedType) => ({
              label: deedType?.labelI18n ? t(deedType?.labelI18n) : deedType?.label,
              value: deedType?.deedType,
              disabled: isCreate
                ? activeDeeds?.length
                  ? deedType?.deedType?.includes("Pendirian")
                  : deedType?.deedType?.includes("Perubahan")
                : deedType?.deedType !== activeDeed?.deedType?.deedType,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.DEED_TYPE")}`}
            isError={!!errors?.deedType}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {activeDeed?.deedType?.labelI18n ? t(activeDeed?.deedType?.labelI18n) : activeDeed?.deedType?.label}
          </FormDescription>
        )}

        {errors?.deedType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.deedType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Deed Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DEED_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.deedNumber && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DEED_NUMBER")}
            {...register("deedNumber", { required: t("INVALID.REQUIRED", { field: t("LABEL.DEED_NUMBER") }) })}
          />
        ) : (
          <FormDescription>{activeDeed?.deedNumber}</FormDescription>
        )}
        {errors?.deedNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.deedNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Deed Date */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DEED_DATE")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="deedDate"
            placeholder={t("LABEL.DEED_DATE")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.DEED_DATE") }) },
            }}
            isError={!!errors?.deedDate}
          />
        ) : (
          <FormDescription>{moment(new Date(activeDeed?.deedDate)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.deedDate && (
          <FormHelperText className="text-red-400 text-xs">{errors?.deedDate?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Notary */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.NOTARY")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.notary && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.NOTARY")}
            {...register("notary", { required: t("INVALID.REQUIRED", { field: t("LABEL.NOTARY") }) })}
          />
        ) : (
          <FormDescription>{activeDeed?.notary}</FormDescription>
        )}
        {errors?.notary && <FormHelperText className="text-red-400 text-xs">{errors?.notary?.message}</FormHelperText>}
      </FormControl>

      {/* SK Menkumham Number */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.SK_MENKUMHAM_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.skMenkumhamNumber && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.SK_MENKUMHAM_NUMBER")}
            {...register("skMenkumhamNumber")}
          />
        ) : (
          <FormDescription>{activeDeed?.skMenkumhamNumber}</FormDescription>
        )}
        {errors?.skMenkumhamNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.skMenkumhamNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* SK Menkumham Date */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.SK_MENKUMHAM_DATE")}
        </FormLabel>
        {isEdit ? (
          <Datepicker name="skMenkumhamDate" placeholder={t("LABEL.SK_MENKUMHAM_DATE")} control={control} />
        ) : (
          <FormDescription>
            {activeDeed?.skMenkumhamDate ? moment(new Date(activeDeed?.skMenkumhamDate)).format("DD/MM/YYYY") : null}
          </FormDescription>
        )}
        {errors?.skMenkumhamDate && (
          <FormHelperText className="text-red-400 text-xs">{errors?.skMenkumhamDate?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Deed Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.COMPANY_DEED"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeDeed?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
