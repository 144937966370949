import React, { useContext } from "react";
import TranslationHandler from "../../../../components/TranslationHandler";
import { Button, Menu, MenuButton, MenuItem as MenuItemComponent, MenuList } from "@chakra-ui/react";
import { AiFillCaretDown } from "react-icons/ai";
import { AuthContext } from "../../../../controllers/auth/AuthController";
import { useTranslation } from "react-i18next";
import { BiLogOut, BiUser, BiHistory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../../../routes/userRoutes";
import Notification from "./Notification";

const MenuItem = ({ Icon, label, onClick }) => {
  return (
    <MenuItemComponent onClick={onClick}>
      <div className="flex items-center gap-2">
        <Icon />
        <span className="font-semibold">{label}</span>
      </div>
    </MenuItemComponent>
  );
};

const Navbar = () => {
  const { t } = useTranslation();
  const { userData, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center py-7 pr-12">
      <TranslationHandler />
      <div className="flex items-center">
        <Notification />
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<AiFillCaretDown className="text-xs" />}
            className="bg-transparent shadow-none text-gray-700"
          >
            {userData?.name}
          </MenuButton>
          <MenuList>
            {userData?.type === "user" && (
              <>
                <MenuItem
                  Icon={BiUser}
                  label={t("LABEL.ACCOUNT")}
                  onClick={() => navigate(`${userRouteSlug.PANEL}${userRouteSlug.ACCOUNT}`)}
                />
                <MenuItem
                  Icon={BiHistory}
                  label={t("LABEL.ACTIVITY")}
                  onClick={() => navigate(`${userRouteSlug.PANEL}${userRouteSlug.ACTIVITY}`)}
                />
              </>
            )}
            <MenuItem Icon={BiLogOut} label={t("LABEL.LOGOUT")} onClick={() => logout()} />
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
