import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";

const ObejctionForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { prequalificationForm, schedules } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {/* Sanggahan */}
      <hr className="md:col-span-2" />
      <FormControl className="md:col-span-2">
        <FormLabel
          className={`uppercase font-bold text-sm text-red-500 mb-4`}
        >
          {t("LABEL.PROCUREMENT_OBJECTION")}
        </FormLabel>

        <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
          {/* Start Sanggahan */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_START", {
                field: t("LABEL.PROCUREMENT_OBJECTION"),
              })}
            </FormLabel>

            <FormDescription>
              {schedules[0]?.objectionStart &&
                moment(new Date(schedules[0]?.objectionStart)).format("LLL")}
            </FormDescription>

            {errors?.objectionStart && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.objectionStart?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* End Sanggahan */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_END", {
                field: t("LABEL.PROCUREMENT_OBJECTION"),
              })}
            </FormLabel>

            <FormDescription>
              {schedules[0]?.objectionEnd &&
                moment(new Date(schedules[0]?.objectionEnd)).format("LLL")}
            </FormDescription>

            {errors?.objectionEnd && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.objectionEnd?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </FormControl>
    </>
  );
};

export default ObejctionForm;
