import {
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Spinner,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Table,
	ModalHeader,
	ModalFooter,
	Textarea,
	FormControl,
	FormLabel,
	Button,
	FormHelperText,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiExit, BiPaperPlane } from 'react-icons/bi';
import TitleBar from '../../../../../../../../../../components/TitleBar';

const Row = ({ vendor, idx }) => {
	const { t } = useTranslation();

	const isQualified = vendor?.procurementVendorPrequalification?.status === 'ACCEPTED';

	return (
		<Tr className="hover:bg-gray-50">
			<Td
				style={{
					minWidth: '75px',
					maxWidth: '75px',
					width: '75px',
				}}
			>
				{idx + 1}
			</Td>
			<Td
				style={{
					width: '140px',
				}}
			>
				{vendor.company?.registrationCode}
			</Td>
			<Td>
				<div className="text-center">
					{!isQualified ? <div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div> : <div className="font-bold italic text-green-500 ">{t('LABEL.QUALIFIED')}</div>}
				</div>
			</Td>
		</Tr>
	);
};

const ActionModal = ({ isOpen, setIsOpen, vendors, setNote, note, onSubmit }) => {
	const { t } = useTranslation();

	return (
		<Modal isOpen={isOpen} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Evaluasi Prakualifikasi</ModalHeader>
				<ModalBody>
					<div>
						<TitleBar title={t('LABEL.VENDOR')} />
						<div className="overflow-y-scroll" style={{ maxHeight: '45vh' }}>
							<Table>
								<Thead>
									<Tr>
										<Th
											style={{
												minWidth: '75px',
												maxWidth: '75px',
												width: '75px',
											}}
											className="text-red-500"
										>
											No.
										</Th>
										<Th className="text-red-500">{t('LABEL.REGISTRATION_ID')}</Th>
										<Th className="text-red-500 text-center">{t('LABEL.ACTION')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{vendors?.length === 0 ? (
										<Tr>
											<Td colSpan={3} className="text-center">
												<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
											</Td>
										</Tr>
									) : (
										vendors?.map((vendor, idx) => {
											return <Row vendor={vendor} idx={idx} key={idx} />;
										})
									)}
								</Tbody>
							</Table>
						</div>
					</div>
					<div className="text-base mt-5">
						{t('TEXT.MAKE_SURE_WARNING_EVALUATION')} <strong className="italic text-color0">{t('LABEL.CANNOT_CANCEL')}</strong>.
					</div>
					<div className="mt-5">
						<FormControl isRequired>
							<FormLabel className={`uppercase font-semibold text-xs`}>{t('LABEL.EVALUATION_NOTE')}</FormLabel>
							<Textarea
								placeholder={t('LABEL.EVALUATION_NOTE')}
								className={`${note?.error !== '' ? 'border-2 border-color0' : ''}`}
								value={note?.text}
								onChange={(e) => {
									setNote({
										error: '',
										text: e.target.value,
									});
								}}
							/>
							{note?.error && <FormHelperText className="text-red-400 text-xs">{note?.error}</FormHelperText>}
						</FormControl>
					</div>
				</ModalBody>
				<ModalFooter className="flex gap-6">
					<Button
						className="text-black font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gray-200 hover:bg-gray-300"
						onClick={() => {
							setIsOpen(false);
						}}
					>
						<BiExit className="text-2xl" />
						{t('LABEL.CANCEL', { field: '' })}
					</Button>
					<Button
						className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
						onClick={() => {
							if (!note?.text) {
								setNote((state) => ({
									...state,
									error: 'Catatan wajib diisi!',
								}));
								return;
							}

							onSubmit();
						}}
					>
						<BiPaperPlane className="text-2xl" />
						{t('LABEL.SUBMIT', { field: '' })}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ActionModal;
