import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../controllers/auth/AuthController";

export const JoinContext = createContext();
const { Provider } = JoinContext;

const JoinController = ({ children }) => {
  const { token, userData } = useContext(AuthContext);
  const regCode = userData?.company?.registrationCode;

  // Procurement
  const [procurements, setProcurements] = useState([]);
  const [loadingProcurement, setLoadingProcurement] = useState(false);
  const [reloadProcurement, setReloadProcurement] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getProcurement = async () => {
      setLoadingProcurement(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement/join/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newProcurement = res?.data?.procurements;
            setProcurements(newProcurement);
          }
        })
        .finally(() => {
          setReloadProcurement(false);
          setLoadingProcurement(false);
        });
    };

    token && reloadProcurement && regCode && getProcurement();

    return () => {
      mounted = false;
    };
  }, [token, reloadProcurement, regCode]);

  return <Provider value={{ procurements, loadingProcurement }}>{children}</Provider>;
};

export default JoinController;
