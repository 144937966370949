import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const DeedContext = createContext();
const { Provider } = DeedContext;

const DeedController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        deedType: newForm?.deedType?.deedType,
        deedNumber: newForm?.deedNumber,
        deedDate: newForm?.deedDate ? new Date(newForm?.deedDate) : null,
        notary: newForm?.notary,
        skMenkumhamNumber: newForm?.skMenkumhamNumber,
        skMenkumhamDate: newForm?.skMenkumhamDate ? new Date(newForm?.skMenkumhamDate) : null,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeDeed, setActiveDeed] = useState();
  const handleDetailMenu = (isOpen, deed = null) => {
    setDetailMenu(isOpen);
    setActiveDeed(deed);
    isOpen && resetForm(deed);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Deeds
  const [deeds, setDeeds] = useState([]);
  const [loadingDeeds, setLoadingDeeds] = useState(false);
  const [reloadDeeds, setReloadDeeds] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getDeeds = async () => {
      setLoadingDeeds(true);
      axios
        .get(`/deed/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newDeeds = res?.data?.deeds;
            setDeeds(newDeeds);

            if (activeDeed) {
              const newActiveDeed = newDeeds.find((deed) => deed?.deedId === activeDeed?.deedId) || null;
              setActiveDeed(newActiveDeed);
              resetForm(newActiveDeed);
            }
          }
        })
        .finally(() => {
          setReloadDeeds(false);
          setLoadingDeeds(false);
        });
    };

    userData && token && reloadDeeds && getDeeds();

    if (reloadDeeds) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadDeeds]);

  // -------------------------------
  // Master
  // -------------------------------

  // Deed Type
  const [deedTypes, setDeedTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/deed-type`).then((res) => {
      if (mounted) {
        setDeedTypes(res?.data?.deedType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createDeed = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        deedDate: new Date(payload?.deedDate),
        skMenkumhamDate: payload?.skMenkumhamDate ? new Date(payload?.skMenkumhamDate) : undefined,
      })
    );
    formData.append("attachment", payload?.attachment[0]);

    axios
      .post(`deed/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadDeeds(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeDeed);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateDeed = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        ...(payload?.deedDate ? { deedDate: new Date(payload.deedDate) } : {}),
        ...(payload?.skMenkumhamDate ? { skMenkumhamDate: new Date(payload.skMenkumhamDate) } : {}),
      })
    );
    payload?.attachment && formData.append("attachment", payload?.attachment[0]);

    axios
      .patch(`deed/${regCode}/${activeDeed?.deedId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadDeeds(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteDeed = () => {
    setLoadingDelete(true);

    axios
      .delete(`deed/${regCode}/${activeDeed?.deedId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadDeeds(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingDeeds,
        deeds,
        activeDeed,

        deedTypes,

        isCreate,
        handleCreate,
        loadingCreate,
        createDeed,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateDeed,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteDeed,
      }}
    >
      {children}
    </Provider>
  );
};

export default DeedController;
