import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// CSS + Fonts
import "./assets/css";

// PrimeReact
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

// Rsuite
import "rsuite/dist/rsuite.min.css";

// Axios
import "./config/axios";

// Numeral
import "./config/numeral";

// Moment
import "./config/moment";

// i18n
import "./i18n/i18n";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
