import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import { ApprovalContext } from "../Controller";
import Form from "./Form";

const ApprovalNew = () => {
  const { t } = useTranslation();
  const { handleCreate, setModalRejectOpen, setModalApproveOpen } =
    useContext(ApprovalContext);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="bg-white rounded-md p-6"
    >
      <div className="w-full grid md:grid-cols-1 gap-6">
        <div className="md:col-span-2 flex justify-between items-center gap-2">
          <button onClick={() => handleCreate(false)}>
            <BsArrowLeftCircle className="text-xl text-gray-600" />
          </button>
          <div className="md:col-span-2 flex justify-between items-center gap-2">
            <Button
              size="sm"
              colorScheme="teal"
              onClick={() => setModalApproveOpen(true)}
            >
              {t("LABEL.APPROVED")}
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              onClick={() => setModalRejectOpen(true)}
            >
              {t("LABEL.REJECTED")}
            </Button>
          </div>
        </div>
        <Form isEdit={true} />
      </div>
    </motion.div>
  );
};

export default ApprovalNew;
