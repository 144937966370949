import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { publicRouteSlug } from "../../routes/publicRoutes";
import { AuthContext } from "./AuthController";

const UserAuth = () => {
  const navigate = useNavigate();
  const { isLogin } = useContext(AuthContext);

  // Guard Panel
  useEffect(() => {
    isLogin === false && navigate(publicRouteSlug.LOGIN);
  }, [isLogin]);

  return <></>;
};

export default UserAuth;
