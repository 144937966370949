import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { AuthContext } from "../controllers/auth/AuthController";
import axios from "axios";

export const NotificationContext = createContext();
const { Provider } = NotificationContext;

const NotificationController = ({ children }) => {
  const { userData, isLogin, token } = useContext(AuthContext);

  // ---------------------------
  // Notification
  // ---------------------------

  const [notifications, setNotifications] = useState([]);
  const [nowDate, setNowDate] = useState(new Date());
  const [hasNewMessage, setHasNewMessage] = useState(false);
  useEffect(() => {
    let mounted = true;

    const getNotifications = () => {
      axios
        .get(`/notify/${userData?.company?.registrationCode}/${userData?.username}?limit=5`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newNotifications = res?.data?.notifications;
            setNotifications(newNotifications);

            // Show ping if exist unread message
            if (newNotifications?.find((notify) => !notify?.isRead)) setHasNewMessage(true);
          }
        });
    };

    userData?.type === "user" && token && getNotifications();

    return () => {
      mounted = false;
    };
  }, [userData, token]);

  const handleOpen = () => {
    // Read Notification
    axios.post("notify/read", { username: userData?.username });

    setNowDate(new Date());
    setHasNewMessage(false);
  };

  // ---------------------------
  // Web Socket
  // ---------------------------
  const connectWebsocket = () => {
    const socket = io(process.env.REACT_APP_NOTIFICATION_WS, {
      query: { regCode: userData?.company?.registrationCode },
    });

    socket.on("getNotification", (params) => handleGetNotification(params));
  };
  useEffect(() => {
    isLogin && userData?.type === "user" && connectWebsocket();
  }, [isLogin]);

  // Get Notification From Websocket
  const handleGetNotification = ({ message }) => {
    setNowDate(new Date());
    setNotifications((prev) => [message, ...prev.slice(0, 4)]);
    setHasNewMessage(true);
  };

  return <Provider value={{ hasNewMessage, notifications, handleOpen, nowDate }}>{children}</Provider>;
};
export default NotificationController;
