import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BiExit, BiPaperPlane } from 'react-icons/bi';

const ConfirmationModal = ({ isOpen, onNo, onYes, loading, title, body, cannotCancelledAlert = true }) => {
	const { t } = useTranslation();

	return (
		<Modal size="md" isOpen={isOpen} onClose={onNo}>
			<ModalOverlay />
			<ModalContent className="bg-opacity-80">
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<div>{body ?? t('TEXT.MAKE_SURE_WARNING') }</div> {t('TEXT.CONTINUE_CONFIRMATION')} {cannotCancelledAlert && <b className="text-color0">{t('LABEL.CANNOT_CANCEL')}</b>}
				</ModalBody>
				<ModalFooter className="flex gap-4">
					<Button className="font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-size-200 bg-pos-0 hover:bg-pos-100" disabled={loading} onClick={onNo}>
						<BiExit className="text-xl" />
						{t('LABEL.CANCEL', { field: '' })}
					</Button>
					<Button
						className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 shadow-lg text-sm"
						onClick={onYes}
						isLoading={loading}
					>
						<BiPaperPlane className="text-xl" />
						{t('LABEL.SUBMIT', { field: '' })}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmationModal;
