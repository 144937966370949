import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PrivateComponent from '../../../../../../components/PrivateComponent';
import { HelperContext } from '../../../../../../controllers/HelperController';
import { userRouteSlug } from '../../../../../../routes/userRoutes';
import { API_URLS } from '../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../Controller';
import ConfirmationModal from './sections/Offer/components/ConfirmationModal';

const NextStepButton = ({ toLabel, url, to, isDisabled, className }) => {
	const { procurement, setProcurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);

	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const navigate = useNavigate();

	const BASE_PROCUREMENT_URL = userRouteSlug.PANEL + userRouteSlug.PROCUREMENT + '/' + procurement?.procurementNumber + '/detail';

	const next = async () => {
		if (!procurement) return;
		setLoading(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-step/${procurement?.procurementStep?.id}/next/${to}`,
			method: 'PATCH',
		});
		setLoading(false);

		if (!response?.success) {
			showToast('Something wrong!', { success: false });
			return;
		}

		procurement.procurementStep = response.data.procurementStep;
		setProcurement({ ...procurement });

		navigate(`${BASE_PROCUREMENT_URL}/${url}`);
	};

	return (
		<PrivateComponent accessName="BUYER_EDIT">
			<Button
				size="sm"
				disabled={isDisabled || loading}
				onClick={() => {
					setOpenModal(true);
				}}
        hidden={procurement?.procurementStep[to]}
				className={`text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 text-xs ml-auto ${className}`}
			>
				Lanjutkan ke {toLabel}
			</Button>
			<ConfirmationModal
				cannotCancelledAlert={false}
				isOpen={openModal}
				loading={loading}
				title={`Apakah anda yakin akan melanjutkan ke ${toLabel}`}
				onNo={() => {
					setOpenModal(false);
				}}
				onYes={next}
			/>
		</PrivateComponent>
	);
};

export default NextStepButton;
