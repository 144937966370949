import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";

const PoMakingForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { prequalificationForm, schedules } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {/* Pembuatan PO */}
      <hr className="md:col-span-2" />
      <FormControl className="md:col-span-2">
        <FormLabel
          className={`uppercase font-bold text-sm text-red-500 mb-4`}
        >
          {t("LABEL.PROCUREMENT_PO_MAKING")}
        </FormLabel>

        <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
          {/* Start Pembuatan PO */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_START", {
                field: t("LABEL.PROCUREMENT_PO_MAKING"),
              })}
            </FormLabel>

            <FormDescription>
              {schedules[0]?.poMakingStart &&
                moment(new Date(schedules[0]?.poMakingStart)).format("LLL")}
            </FormDescription>

            {errors?.poMakingStart && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.poMakingStart?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* End Pembuatan PO */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_END", {
                field: t("LABEL.PROCUREMENT_PO_MAKING"),
              })}
            </FormLabel>

            <FormDescription>
              {schedules[0]?.poMakingEnd &&
                moment(new Date(schedules[0]?.poMakingEnd)).format("LLL")}
            </FormDescription>

            {errors?.poMakingEnd && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.poMakingEnd?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </FormControl>
    </>
  );
};

export default PoMakingForm;
