import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { HelperContext } from "../../../../../../../../controllers/HelperController";
import { API_URLS } from "../../../../../../../../utils/apiURLs.util";
import { ProcurementDetailContext } from "../../../Controller";

export const AuctionContext = createContext();
const { Provider } = AuctionContext;

const AuctionController = ({ children }) => {
  const toast = useToast();
  const { httpRequest, showToast } = useContext(HelperContext);
  const { token, userData } = useContext(AuthContext);
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        isBestPriceVisible: newForm?.isBestPriceVisible || true,
        isTopRankVisible: newForm?.isTopRankVisible || true,
      });
    }
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Get Internal Auction
  const [internalTotalData, setInternalTotalData] = useState(0);
  const [internalLimit] = useState(10);
  const internalTableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });

  const [internalAuctions, setInternalAuctions] = useState([]);
  const [loadingInternalAuctions, setLoadingInternalAuctions] = useState(false);
  const [reloadInternalAuctions, setReloadInternalAuctions] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = internalTableForm.getValues();

    // Filter
    const limit = `limit=${internalLimit}`;
    const page = `page=${payload?.page}`;
    const regCode = userData?.type === "user" ? `&regCode=${userData?.company?.registrationCode}` : "";
    const filter = `?${limit}&${page}${regCode}`;

    const getInternalAuctions = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-auction/${procurementNumber}${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newAuctions = res?.data?.auctions;
            setInternalAuctions(newAuctions);
            setInternalTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setLoadingInternalAuctions(false);
          setReloadInternalAuctions(false);
        });
    };

    procurementNumber && reloadInternalAuctions && userData && getInternalAuctions();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadInternalAuctions, userData]);

  // ---------------------------
  // Master
  // ---------------------------

  // Winner Evaluation Method
  const [winnerEvaluationMethods, setWinnerEvaluationMethods] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/winner-evaluation-method`).then((res) => {
        if (mounted) {
          setWinnerEvaluationMethods(res?.data?.winnerEvaluationMethods);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Rank Method
  const [rankMethods, setRankMethods] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/auction-rank-method`).then((res) => {
        if (mounted) {
          setRankMethods(res?.data?.rankMethods);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Req Requisitions
  const [reqRequisitions, setReqRequisitions] = useState([]);
  const [loadingReqRequisitions, setLoadingReqRequisitions] = useState(false);
  useEffect(() => {
    let mounted = true;

    const getReqRequisitionMaterial = () => {
      const reqRequisitionMaterialIds = procurement?.procurementRequisitionMaterials
        ?.map((material) => material?.requisitionMaterial?.reqRequisitionMaterialId)
        ?.join(";");

      axios
        .get(`${process.env.REACT_APP_TMS_API}/req-requisition/by-code?code=${reqRequisitionMaterialIds}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newReqRequisitions = res?.data?.reqRequisitions;
            setReqRequisitions(newReqRequisitions);
          }
        })
        .finally(() => {
          setLoadingReqRequisitions(false);
        });
    };

    procurement && getReqRequisitionMaterial();

    return () => {
      mounted = false;
    };
  }, [procurement]);

  // -------------------------------
  // Create
  // -------------------------------

  const [isCreate, setCreate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const createAuction = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`${process.env.REACT_APP_TMS_API}/procurement-auction/${procurement.procurementNumber}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        if (res?.data?.procurementAuctionId){
          const {response} = await httpRequest({
            url: `${API_URLS.REACT_APP_TMS_API}/procurement-auction/send-auction-email/${res.data.procurementAuctionId}`,
            method: 'POST'
          })
          if (!response?.sent) showToast('Email undangan lelang gagal dikirim', {success: false});
          else showToast('Email undangan lelang berhasil dikirim');
        }
        setCreate(false);
        setReloadInternalAuctions(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  return (
    <Provider
      value={{
        form,

        internalAuctions,
        loadingInternalAuctions,
        internalTotalData,
        internalLimit,
        internalTableForm,

        winnerEvaluationMethods,
        rankMethods,
        reqRequisitions,

        isCreate,
        handleCreate,
        loadingCreate,
        createAuction,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuctionController;
