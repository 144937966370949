import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../Controller";

export const VendorContext = createContext();
const { Provider } = VendorContext;

const VendorController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { procurement, setReloadProcurement, setReloadProcurementWinner } = useContext(ProcurementDetailContext);
  const { token, roles } = useContext(AuthContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadVendor(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Vendor
  const [vendor, setVendor] = useState([]);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [reloadVendor, setReloadVendor] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getVendor = async () => {
      setLoadingVendor(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newVendor = res?.data?.procurementVendors?.filter(
              (vendor) =>
                !!vendor?.procurementCommercialOffer &&
                (vendor?.procurementOfferEvaluationVendor?.status === "ACCEPTED" ||
                  (vendor?.procurementOfferEvaluationVendor?.statusAdministration === "ACCEPTED" &&
                    vendor?.procurementOfferEvaluationVendor?.statusCommercial === "ACCEPTED"))
            );
            setVendor(newVendor);
            setTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setReloadVendor(false);
          setLoadingVendor(false);
        });
    };

    token && procurementNumber && reloadVendor && getVendor();

    return () => {
      mounted = false;
    };
  }, [token, reloadVendor, procurementNumber]);

  // ---------------------------
  // Master
  // ---------------------------

  // Winner Proposed
  const [winnerProposed, setWinnerProposed] = useState();
  const [reloadWinnerProposed, setReloadWinnerProposed] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getWinnerProposed = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-winner-proposal/${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setWinnerProposed(res?.data?.winnerProposed);
          }
        })
        .finally(() => {
          setReloadWinnerProposed(false);
        });
    };

    token && reloadWinnerProposed && procurementNumber && getWinnerProposed();

    return () => {
      mounted = false;
    };
  }, [token, reloadWinnerProposed, procurementNumber]);

  // Vendor
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios
        .get(`company`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setVendors(res?.data?.companies);
          }
        });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Final Price
  const [finalPrice, setFinalPrice] = useState();
  const [reloadFinalPrice, setReloadFinalPrice] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getFinalPrice = async () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-winner-proposal/final-price/${procurementNumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newFinalPrice = res?.data?.finalPrice;
            setFinalPrice(newFinalPrice);
          }
        })
        .finally(() => {
          setReloadFinalPrice(false);
        });
    };

    token && procurementNumber && reloadFinalPrice && getFinalPrice();

    return () => {
      mounted = false;
    };
  }, [token, procurementNumber, reloadFinalPrice]);

  // -------------------------------
  // Propose Winner
  // -------------------------------

  const [vendorProposed, setVendorProposed] = useState();
  const [loadingPropose, setLoadingPropose] = useState(false);
  const proposeWinner = () => {
    setLoadingPropose(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-winner-proposal/propose/${procurement?.procurementNumber}/${vendorProposed?.vendor}`,
        { price: vendorProposed?.price },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingPropose(false);
        setModalProposeOpen(false);
        setReloadVendor(true);
        setReloadWinnerProposed(true);
      });
  };

  const [isModalProposeOpen, setModalProposeOpen] = useState(false);
  const handlePropose = (isOpen, vendor = null) => {
    setModalProposeOpen(isOpen);
    isOpen && setVendorProposed(vendor);
  };

  // -------------------------------
  // Approve Winner
  // -------------------------------

  const [vendorApproved, setVendorApproved] = useState();
  const [loadingApproved, setLoadingApproved] = useState(false);
  const approveWinner = () => {
    setLoadingApproved(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-winner-proposal/approve/${procurement?.procurementNumber}/${vendorApproved.vendor}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingApproved(false);
        setModalApproveOpen(false);
        setReloadVendor(true);
        setReloadWinnerProposed(true);
        setReloadProcurement(true);
        setReloadProcurementWinner(true);
      });
  };

  const [isModalApproveOpen, setModalApproveOpen] = useState(false);
  const handleApprove = (isOpen, vendor = null) => {
    setModalApproveOpen(isOpen);
    isOpen && setVendorApproved(vendor);
  };

  // -------------------------------
  // Reject Winner
  // -------------------------------

  const [vendorRejected, setVendorRejected] = useState();
  const [loadingRejected, setLoadingRejected] = useState(false);
  const rejectWinner = () => {
    setLoadingRejected(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-winner-proposal/reject/${procurement?.procurementNumber}/${vendorRejected.vendor}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingRejected(false);
        setModalRejectOpen(false);
        setReloadVendor(true);
        setReloadWinnerProposed(true);
      });
  };

  const [isModalRejectOpen, setModalRejectOpen] = useState(false);
  const handleReject = (isOpen, vendor = null) => {
    setModalRejectOpen(isOpen);
    isOpen && setVendorRejected(vendor);
  };

  return (
    <Provider
      value={{
        tableForm,
        vendor,
        loadingVendor,
        totalData,
        limit,

        winnerProposed,
        vendorApproved,
        vendors,
        finalPrice,

        form,

        isModalProposeOpen,
        handlePropose,
        loadingPropose,
        proposeWinner,

        isModalApproveOpen,
        handleApprove,
        loadingApproved,
        approveWinner,

        isModalRejectOpen,
        handleReject,
        loadingRejected,
        rejectWinner,
      }}
    >
      {children}
    </Provider>
  );
};

export default VendorController;
