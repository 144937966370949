import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ExpertContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { emailPattern, websitePattern } from "../../../../../../../utils/regex.util";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload, BiPlus, BiTrash } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import { useFieldArray } from "react-hook-form";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeExpert, form, employmentStatus, experts } = useContext(ExpertContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("idCardAttachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.ID_CARD_NUMBER") }) }),
    },
  });

  register("expertAttachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.EXPERT") }) }),
    },
  });

  const experiencesFieldArray = useFieldArray({ control, name: "experiences" });
  const certificatesFieldArray = useFieldArray({ control, name: "certificates" });
  useEffect(() => {
    if (!experiencesFieldArray.fields.length && !certificatesFieldArray.fields.length) {
      experiencesFieldArray.append({});
      certificatesFieldArray.append({});
    }
  }, []);

  return (
    <>
      {/* Expert Name */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.EXPERT_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            autoFocus
            className={`w-full text-sm ${errors?.expertName && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.EXPERT_NAME")}
            {...register("expertName", { required: t("INVALID.REQUIRED", { field: t("LABEL.EXPERT_NAME") }) })}
          />
        ) : (
          <FormDescription>{activeExpert?.expertName}</FormDescription>
        )}
        {errors?.expertName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.expertName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Birth Date */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BIRTH_DATE")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="birthDate"
            placeholder={t("LABEL.BIRTH_DATE")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BIRTH_DATE") }) },
            }}
            isError={!!errors?.birthDate}
          />
        ) : (
          <FormDescription>{moment(new Date(activeExpert?.birthDate)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.birthDate && (
          <FormHelperText className="text-red-400 text-xs">{errors?.birthDate?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Id Card Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ID_CARD_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            placeholder={t("LABEL.ID_CARD_NUMBER")}
            className={`w-full text-sm ${errors?.idCardNumber && "border-2 border-red-400 shadow-none"}`}
            {...register("idCardNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.ID_CARD_NUMBER") }),
            })}
          />
        ) : (
          <FormDescription>{activeExpert?.idCardNumber}</FormDescription>
        )}
        {errors?.idCardNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.idCardNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Id Card Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.ID_CARD_NUMBER") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.idCardAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.ID_CARD_NUMBER"),
            })}`}
            name="idCardAttachment"
            fileList={form.getValues("idCardAttachment")}
            onChange={(files) => {
              setValue("idCardAttachment", files);
              trigger("idCardAttachment");
            }}
          />
        ) : activeExpert?.idCardAttachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeExpert?.idCardAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <span className="text-gray-300">{t("LABEL.EMPTY")}</span>
        )}
        {errors?.idCardAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.idCardAttachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Last Education */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.LAST_EDUCATION")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.lastEducation && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.LAST_EDUCATION")}
            {...register("lastEducation", { required: t("INVALID.REQUIRED", { field: t("LABEL.LAST_EDUCATION") }) })}
          />
        ) : (
          <FormDescription>{activeExpert?.lastEducation}</FormDescription>
        )}
        {errors?.lastEducation && (
          <FormHelperText className="text-red-400 text-xs">{errors?.lastEducation?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Study Field */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.STUDY_FIELD")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.studyField && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.STUDY_FIELD")}
            {...register("studyField", { required: t("INVALID.REQUIRED", { field: t("LABEL.STUDY_FIELD") }) })}
          />
        ) : (
          <FormDescription>{activeExpert?.studyField}</FormDescription>
        )}
        {errors?.studyField && (
          <FormHelperText className="text-red-400 text-xs">{errors?.studyField?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Employment Status */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.EMPLOYMENT_STATUS")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.EMPLOYMENT_STATUS") }) },
            }}
            name="employmentStatus"
            options={employmentStatus.map((status) => ({
              label: t(status?.labelI18n || status?.label),
              value: status?.employmentStatus,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.EMPLOYMENT_STATUS")}`}
            isError={!!errors?.employmentStatus}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {t(activeExpert?.employmentStatus?.labelI18n || activeExpert?.employmentStatus?.label)}
          </FormDescription>
        )}

        {errors?.employmentStatus && (
          <FormHelperText className="text-red-400 text-xs">{errors?.employmentStatus?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Expert Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.EXPERT") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.expertAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.EXPERT"),
            })}`}
            name="expertAttachment"
            fileList={form.getValues("expertAttachment")}
            onChange={(files) => {
              setValue("expertAttachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeExpert?.expertAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.expertAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.expertAttachment?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Experiences */}
      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4 ${!isEdit && "text-red-500"}`}>
          {t("LABEL.WORK_EXPERIENCE")}
        </FormLabel>

        {experiencesFieldArray.fields.map((experiences, iExpertExperience, arrExpertExperience) => (
          <div key={experiences.id} className="flex gap-2 items-center my-4">
            <div className="flex-1 grid grid-cols-2 md:grid-cols-4 gap-6">
              {/* Experience Name */}
              <FormControl className="col-span-2" isRequired>
                <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                  {t("LABEL.EXPERIENCE_NAME")}
                </FormLabel>
                {isEdit ? (
                  <InputText
                    className={`w-full text-sm ${
                      errors?.experiences?.[iExpertExperience]?.experienceName && "border-2 border-red-400 shadow-none"
                    }`}
                    placeholder={t("LABEL.EXPERIENCE_NAME")}
                    {...register(`experiences.${iExpertExperience}.experienceName`, {
                      required: t("INVALID.REQUIRED", { field: t("LABEL.EXPERIENCE_NAME") }),
                    })}
                  />
                ) : (
                  <FormDescription>
                    {activeExpert?.expertExperiences?.[iExpertExperience]?.experienceName}
                  </FormDescription>
                )}
                {errors?.experiences?.[iExpertExperience]?.experienceName && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.experiences?.[iExpertExperience]?.experienceName?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Since Year */}
              <FormControl isRequired>
                <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                  {t("LABEL.SINCE")}
                </FormLabel>
                {isEdit ? (
                  <Datepicker
                    name={`experiences.${iExpertExperience}.sinceYear`}
                    placeholder={t("LABEL.SINCE")}
                    control={control}
                    controlProps={{
                      rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.SINCE") }) },
                    }}
                    isError={!!errors?.experiences?.[iExpertExperience]?.sinceYear}
                    format="yyyy-MM"
                  />
                ) : (
                  <FormDescription>{activeExpert?.expertExperiences?.[iExpertExperience]?.sinceYear}</FormDescription>
                )}
                {errors?.experiences?.[iExpertExperience]?.sinceYear && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.experiences?.[iExpertExperience]?.sinceYear?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Until Year */}
              <FormControl isRequired>
                <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                  {t("LABEL.UNTIL")}
                </FormLabel>
                {isEdit ? (
                  <Datepicker
                    name={`experiences.${iExpertExperience}.untilYear`}
                    placeholder={t("LABEL.UNTIL")}
                    control={control}
                    controlProps={{
                      rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.UNTIL") }) },
                    }}
                    isError={!!errors?.experiences?.[iExpertExperience]?.untilYear}
                    format="yyyy-MM"
                  />
                ) : (
                  <FormDescription>{activeExpert?.expertExperiences?.[iExpertExperience]?.untilYear}</FormDescription>
                )}
                {errors?.experiences?.[iExpertExperience]?.untilYear && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.experiences?.[iExpertExperience]?.untilYear?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            {/* Able to delete if data more than 1 */}
            {isEdit && arrExpertExperience.length > 1 && (
              <div>
                <button className="p-2 mt-4" onClick={() => experiencesFieldArray.remove(iExpertExperience)}>
                  <BiTrash className="text-red-500 text-lg" />
                </button>
              </div>
            )}
          </div>
        ))}

        {isEdit && (
          <Button
            size="sm"
            className="w-full mt-4 flex justify-center items-center text-gray-500 text-xs"
            onClick={() => experiencesFieldArray.append({})}
          >
            <BiPlus />
            <p>{t("LABEL.ADD", { field: t("LABEL.WORK_EXPERIENCE") })}</p>
          </Button>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Certifications */}
      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4 ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CERTIFICATION")}
        </FormLabel>

        {certificatesFieldArray.fields.map((certificates, iExpertCertificate, arrExpertCertificate) => {
          register(`certificates.${iExpertCertificate}.attachment`, {
            required: {
              value: isCreate,
              message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.CERTIFICATION") }) }),
            },
          });

          return (
            <div key={certificates.id} className="flex gap-2 items-center my-4">
              <div className="flex-1 grid md:grid-cols-2 gap-6">
                {/* Certificate Name */}
                <FormControl isRequired>
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.CERTIFICATE_NAME")}
                  </FormLabel>
                  {isEdit ? (
                    <InputText
                      className={`w-full text-sm ${
                        errors?.certificates?.[iExpertCertificate]?.certificateName &&
                        "border-2 border-red-400 shadow-none"
                      }`}
                      placeholder={t("LABEL.CERTIFICATE_NAME")}
                      {...register(`certificates.${iExpertCertificate}.certificateName`, {
                        required: t("INVALID.REQUIRED", { field: t("LABEL.CERTIFICATE_NAME") }),
                      })}
                    />
                  ) : (
                    <FormDescription>
                      {activeExpert?.expertCertificates?.[iExpertCertificate]?.certificateName}
                    </FormDescription>
                  )}
                  {errors?.certificates?.[iExpertCertificate]?.certificateName && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.certificates?.[iExpertCertificate]?.certificateName?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Certificate Attachment */}
                <FormControl isRequired={isCreate}>
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.ATTACHMENT", { field: t("LABEL.CERTIFICATION") })}
                  </FormLabel>
                  {isEdit ? (
                    <Uploader
                      className={`${
                        errors?.certificates?.[iExpertCertificate]?.attachment && "border-2 border-red-400 shadow-none"
                      }`}
                      label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t(
                        "LABEL.ATTACHMENT",
                        { field: t("LABEL.CERTIFICATION") }
                      )}`}
                      name={`certificates.${iExpertCertificate}.attachment`}
                      fileList={form.getValues(`certificates.${iExpertCertificate}.attachment`)}
                      onChange={(files) => {
                        setValue(`certificates.${iExpertCertificate}.attachment`, files);
                        trigger();
                      }}
                    />
                  ) : (
                    <Button
                      className="w-full"
                      size="sm"
                      onClick={() =>
                        downloadFile(activeExpert?.expertCertificates?.[iExpertCertificate]?.certificateAttachment)
                      }
                    >
                      <BiDownload className="mr-2" />
                      {t("LABEL.DOWNLOAD")}
                    </Button>
                  )}
                  {errors?.certificates?.[iExpertCertificate]?.attachment && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.certificates?.[iExpertCertificate]?.attachment?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              {/* Able to delete if data more than 1 */}
              {isEdit && arrExpertCertificate.length > 1 && (
                <div>
                  <button className="p-2 mt-4" onClick={() => certificatesFieldArray.remove(iExpertCertificate)}>
                    <BiTrash className="text-red-500 text-lg" />
                  </button>
                </div>
              )}
            </div>
          );
        })}

        {isEdit && (
          <Button
            size="sm"
            className="w-full mt-4 flex justify-center items-center text-gray-500 text-xs"
            onClick={() => certificatesFieldArray.append({})}
          >
            <BiPlus />
            <p>{t("LABEL.ADD", { field: t("LABEL.CERTIFICATION") })}</p>
          </Button>
        )}
      </FormControl>
    </>
  );
};

export default Form;
