import { Button, FormControl, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import FormDescription from "../../../../components/inputForm/FormDescription";
import { emailPattern } from "../../../../utils/regex.util";
import { AccountContext } from "../Controller";

const AccountForm = () => {
  const { t } = useTranslation();
  const {
    isEdit,
    setEdit,
    user,
    updateForm,
    resetUpdateForm,
    isEmailAvailable,
    loadingEmailAvailable,

    loadingUpdateUser,
    updateUser,
  } = useContext(AccountContext);
  const {
    register,
    formState: { errors },
  } = updateForm;

  useEffect(() => {
    !isEdit && resetUpdateForm(user);
  }, [isEdit]);

  return (
    <div className="bg-white p-4 rounded-md grid md:grid-cols-2 gap-4">
      <div className="md:col-span-2 flex items-center gap-4">
        <div className="flex-1 flex items-center gap-2">
          <span className="font-semibold text-gray-600 whitespace-nowrap">{t("LABEL.ACCOUNT_INFO")}</span>
          <div className="w-full h-1" style={{ borderBottom: "1px solid #ddd" }} />
        </div>
        {isEdit ? (
          <div className="flex gap-2">
            <Button size="xs" onClick={() => setEdit(false)}>
              {t("LABEL.CANCEL")}
            </Button>
            <Button
              size="xs"
              colorScheme="teal"
              isLoading={loadingUpdateUser}
              onClick={updateForm.handleSubmit(updateUser)}
            >
              {t("LABEL.SAVE")}
            </Button>
          </div>
        ) : (
          <div className="flex gap-2">
            <button onClick={() => setEdit(true)}>
              <BiEdit className="text-lg text-gray-500" />
            </button>
          </div>
        )}
      </div>

      {/* Username */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("Username")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.username && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("Username")}
            {...register("username", { required: t("INVALID.REQUIRED", { field: t("Username") }) })}
          />
        ) : (
          <FormDescription>{user?.userAccount?.username}</FormDescription>
        )}
        {errors?.username && (
          <FormHelperText className="text-red-400 text-xs">{errors?.username?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Account Name */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ACCOUNT_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.name && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.ACCOUNT_NAME")}
            {...register("name", { required: t("INVALID.REQUIRED", { field: t("LABEL.ACCOUNT_NAME") }) })}
          />
        ) : (
          <FormDescription>{user?.name}</FormDescription>
        )}
        {errors?.name && <FormHelperText className="text-red-400 text-xs">{errors?.name?.message}</FormHelperText>}
      </FormControl>

      {/* Email */}
      <FormControl isRequired={isEdit} className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.EMAIL")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <InputText
              className={`w-full text-sm ${errors?.email && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.EMAIL")}
              {...register("email", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.EMAIL") }),
                pattern: {
                  value: emailPattern,
                  message: t("INVALID.EMAIL", { field: t("LABEL.EMAIL") }),
                },
                validate: {
                  nameAvailable: (v) => isEmailAvailable || t("INVALID.AVAILABLE", { field: v }),
                },
              })}
            />
            <span
              className={`p-inputgroup-addon bg-gray-50 border-l-0 ${
                errors?.email && "border-2 border-red-400 shadow-none"
              }`}
            >
              {loadingEmailAvailable ? (
                <Spinner size="xs" />
              ) : isEmailAvailable && !errors?.email ? (
                <BsCheckLg className="text-green-500" />
              ) : (
                isEmailAvailable === false && <BsXLg className="text-red-500" />
              )}
            </span>
          </div>
        ) : (
          <FormDescription>{user?.email}</FormDescription>
        )}
        {errors?.email && <FormHelperText className="text-red-400 text-xs">{errors?.email?.message}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default AccountForm;
