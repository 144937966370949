import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalContext } from "../Controller";

const ModalApproval = () => {
  const { t } = useTranslation();
  const {
    form,
    isModalRejectOpen,
    isModalApproveOpen,
    handleReject,
    handleCreate,
    loadingReject,
    loadingCreate,
    createApproval,
    rejectApproval,
  } = useContext(ApprovalContext);

  return isModalRejectOpen === true ? (
    <Modal
      onClose={() => handleReject(false)}
      size="md"
      isOpen={isModalRejectOpen}
    >
      <ModalOverlay />
      <ModalContent className="bg-red-500 text-white bg-opacity-80">
        <ModalHeader>{t("LABEL.REJECTED")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.REJECT_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleReject(false)} colorScheme="whiteAlpha">
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingReject}
            onClick={form.handleSubmit(rejectApproval)}
            colorScheme="blackAlpha"
          >
            {t("LABEL.REJECTED", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal
      onClose={() => handleCreate(true, true, false)}
      size="md"
      isOpen={isModalApproveOpen}
    >
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>{t("LABEL.APPROVED")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.APPROVE_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleCreate(true, true, false)}>
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingCreate}
            onClick={form.handleSubmit(createApproval)}
            colorScheme="blackAlpha"
          >
            {t("LABEL.APPROVED", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalApproval;
