import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Datepicker from "../../../../../../../../../../components/inputForm/Datepicker";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcScheduleContext } from "../../Controller";
import { ProcurementDetailContext } from "../../../../../Controller";
import { AuthContext } from "../../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../../components/PrivateComponent";

const AanwijzingForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    aanwijzingForm,
    schedules,
    loadingAanwijzing,
    handleCreateAanwijzing,
    createAanwijzingProcSchedule,
  } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = aanwijzingForm;

  return (
    <>
      {/* Aanwijzing */}
      {procurement?.procurementSetting?.aanwijzing === true ? (
        <>
          <hr className="md:col-span-2" />
          <FormControl className="md:col-span-2">
            <FormLabel
              className={`uppercase font-bold text-sm text-red-500 mb-4 `}
            >
              {t("LABEL.PROCUREMENT_AANWIJZING")}
            </FormLabel>

            <div
              className={`md:col-span-2 grid ${
                userData?.type !== "user" ? "md:grid-cols-3" : "md:grid-cols-2"
              } gap-6 items-center`}
            >
              {/* Start Aanwijzing */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_START", {
                    field: t("LABEL.PROCUREMENT_AANWIJZING"),
                  })}
                </FormLabel>
                {isEdit ? (
                  <Datepicker
                    format="yyyy-MM-dd HH:mm:ss"
                    name="aanwijzingStart"
                    placeholder={t("LABEL.PROCUREMENT_START", {
                      field: t("LABEL.PROCUREMENT_AANWIJZING"),
                    })}
                    control={control}
                    controlProps={{
                      rules: {
                        required: t("INVALID.REQUIRED", {
                          field: t("LABEL.PROCUREMENT_START", {
                            field: t("LABEL.PROCUREMENT_AANWIJZING"),
                          }),
                        }),
                      },
                    }}
                    isError={!!errors?.aanwijzingStart}
                  />
                ) : (
                  <FormDescription>
                    {schedules[0]?.aanwijzingStart &&
                      moment(new Date(schedules[0]?.aanwijzingStart)).format(
                        "LLL"
                      )}
                  </FormDescription>
                )}
                {errors?.aanwijzingStart && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.aanwijzingStart?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* End Aanwijzing */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_END", {
                    field: t("LABEL.PROCUREMENT_AANWIJZING"),
                  })}
                </FormLabel>
                {isEdit ? (
                  <Datepicker
                    format="yyyy-MM-dd HH:mm:ss"
                    name="aanwijzingEnd"
                    placeholder={t("LABEL.PROCUREMENT_END", {
                      field: t("LABEL.PROCUREMENT_AANWIJZING"),
                    })}
                    control={control}
                    controlProps={{
                      rules: {
                        required: t("INVALID.REQUIRED", {
                          field: t("LABEL.PROCUREMENT_END", {
                            field: t("LABEL.PROCUREMENT_AANWIJZING"),
                          }),
                        }),
                      },
                    }}
                    isError={!!errors?.aanwijzingEnd}
                  />
                ) : (
                  <FormDescription>
                    {schedules[0]?.aanwijzingEnd &&
                      moment(new Date(schedules[0]?.aanwijzingEnd)).format(
                        "LLL"
                      )}
                  </FormDescription>
                )}
                {errors?.aanwijzingEnd && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.aanwijzingEnd?.message}
                  </FormHelperText>
                )}
              </FormControl>
              {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
                isEdit ? (
                  <PrivateComponent accessName="BUYER_EDIT">
                    <Button
                      size="sm"
                      colorScheme="teal"
                      isLoading={loadingAanwijzing}
                      onClick={aanwijzingForm.handleSubmit(
                        createAanwijzingProcSchedule
                      )}
                    >
                      {t("LABEL.SAVE")}
                    </Button>
                  </PrivateComponent>
                ) : (
                  <PrivateComponent accessName="BUYER_EDIT">
                    <Button
                      size="sm"
                      colorScheme="gray"
                      isLoading={loadingAanwijzing}
                      onClick={() => handleCreateAanwijzing(true, schedules)}
                      disabled={!schedules[0]?.registrationStart ? true : false}
                    >
                      {t("LABEL.EDIT", {
                        field: t("LABEL.PROCUREMENT_AANWIJZING"),
                      })}
                    </Button>
                  </PrivateComponent>
                )
              ) : (
                ""
              )}
            </div>
          </FormControl>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AanwijzingForm;
