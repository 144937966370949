const TitleBar = ({ title, icon, className, rightElement }) => {
	const Icon = icon;
	return (
		<div className="flex items-center gap-2 justify-center mb-4">
			<div className={`bg-gray-200 py-2 rounded-md text-gray-600 font-bold px-5 flex text-sm gap-2 items-center flex-1 ${className}`}>
				{Icon && <Icon className="text-xl" />} <div className="uppercase">{title}</div>
			</div>
			{rightElement}
		</div>
	);
};

export default TitleBar;
