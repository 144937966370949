import React, { useContext } from "react";
import { motion } from "framer-motion";
import ApprovalTable from "./components/ApprovalTable";
import ApprovalController, { ApprovalContext } from "./Controller";
import ApprovalNew from "./components/ApprovalNew";
import ModalApproval from "./components/ModalApproval";
import ButtonFirstApproval from "./components/ButtonFirstApproval";
import ButtonSecondApproval from "./components/ButtonSecondApproval";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import AnnouncementPanel from "../../../../../../../../components/AnnouncementPanel";
import { ProcurementDetailContext } from "../../../Controller";
import { useTranslation } from "react-i18next";

const Approval = () => {
  const { t } = useTranslation();
  const { procurementSchedule, loadingProcurementSchedule } = useContext(
    ProcurementDetailContext
  );
  const {
    isCreate,
    firstLevelApprovals,
    loadingFirstLevelApprovals,
    firstLevelTotalData,
    firstLevelLimit,
    firstLevelTableForm,

    secondLevelApprovals,
    loadingSecondLevelApprovals,
    secondLevelTotalData,
    secondLevelLimit,
    secondLevelTableForm,
  } = useContext(ApprovalContext);

  const { roles } = useContext(AuthContext);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="flex flex-col gap-4"
    >
      {(!procurementSchedule || !procurementSchedule?.submittedAt) && (
        <AnnouncementPanel
          isLoading={loadingProcurementSchedule}
          title={t("TEXT.PROCUREMENT_SCHEDULE_HAS_NOT_BEEN_SET")}
          description={t(
            "TEXT.PROCUREMENT_SCHEDULE_HAS_NOT_BEEN_SET_DESCRIPTION"
          )}
        />
      )}
      {isCreate ? (
        <ApprovalNew />
      ) : (
        <div className="flex flex-col gap-6 relative">
          {(!procurementSchedule || !procurementSchedule?.submittedAt) && (
            <div className="absolute w-full h-full opacity-60 rounded-md bg-white z-50"></div>
          )}

          <ApprovalTable
            approvals={firstLevelApprovals}
            loadingApprovals={loadingFirstLevelApprovals}
            totalData={firstLevelTotalData}
            limit={firstLevelLimit}
            tableForm={firstLevelTableForm}
            isFirst={true}
            isButton={roles?.map(
              (dataRole) =>
                dataRole?.role === "GM" &&
                !procurementSchedule?.shouldBeReview && <ButtonFirstApproval />
            )}
          />
          <ApprovalTable
            approvals={secondLevelApprovals}
            loadingApprovals={loadingSecondLevelApprovals}
            totalData={secondLevelTotalData}
            limit={secondLevelLimit}
            tableForm={secondLevelTableForm}
            isSecond={true}
            isButton={roles?.map(
              (dataRole) =>
                dataRole?.role === "SGM" &&
                !procurementSchedule?.shouldBeReview && (
                  <ButtonSecondApproval
                    isActive={!firstLevelApprovals?.length ? false : true}
                  />
                )
            )}
          />
        </div>
      )}

      <ModalApproval />
    </motion.div>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.approval){
    return <div></div>
  }

  return (
    <ApprovalController>
      <Approval />
    </ApprovalController>
  )
};
