import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReqRequisitionContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../components/inputForm/FormDescription";
import Select from "../../../../components/inputForm/Select";
import { useFieldArray } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import InputNumber from "../../../../components/inputForm/InputNumber";
import numeral from "numeral";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { isCreate, activeReqRequisition, form, requisitioners, materials, currencies } =
    useContext(ReqRequisitionContext);
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const requisitionMaterialsFieldArray = useFieldArray({ control, name: "requisitionMaterials" });
  const defaultMaterial = {
    valuation: 1,
    quantity: 1,
  };

  useEffect(() => {
    !requisitionMaterialsFieldArray.fields.length && requisitionMaterialsFieldArray.append(defaultMaterial);
  }, []);

  const watchRequisitionMaterialsFieldArray = watch("requisitionMaterials");

  return (
    <>
      {/* Requisition Code */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.REQUISITION_CODE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.reqRequisitionCode && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.REQUISITION_CODE")}
            {...register("reqRequisitionCode", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.REQUISITION_CODE") }),
            })}
            disabled
          />
        ) : (
          <FormDescription>{activeReqRequisition?.reqRequisitionCode}</FormDescription>
        )}
        {errors?.reqRequisitionCode && (
          <FormHelperText className="text-red-400 text-xs">{errors?.reqRequisitionCode?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Requisitioner */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.REQUISITIONER")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.REQUISITIONER") }) },
            }}
            name="requisitioner"
            options={requisitioners.map((requisitioner) => ({
              label: requisitioner?.description,
              value: requisitioner?.requisitionerCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.REQUISITIONER")}`}
            isError={!!errors?.requisitioner}
          />
        ) : (
          <FormDescription>{activeReqRequisition?.requisitioner?.description}</FormDescription>
        )}

        {errors?.requisitioner && (
          <FormHelperText className="text-red-400 text-xs">{errors?.requisitioner?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Requisition Material */}
      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4 ${!isEdit && "text-red-500"}`}>
          {t("Material")}
        </FormLabel>

        {requisitionMaterialsFieldArray.fields.map((material, iMaterial, arrMaterial) => {
          const activeMaterial = watchRequisitionMaterialsFieldArray[iMaterial];

          return (
            <div key={material.id} className="flex gap-2 items-center my-4">
              <div className="flex-1 grid md:grid-cols-6 gap-6">
                {/* Material */}
                <FormControl isRequired={isEdit} className="md:col-span-3">
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("Material")}
                  </FormLabel>
                  {isEdit ? (
                    <Select
                      filter
                      control={control}
                      controlProps={{
                        rules: { required: t("INVALID.REQUIRED", { field: t("Material") }) },
                      }}
                      name={`requisitionMaterials.${iMaterial}.material`}
                      options={materials.map((material) => ({
                        label: `${material?.materialCode} - ${material?.description}`,
                        value: material?.materialCode,
                      }))}
                      placeholder={`${t("LABEL.CHOOSE")} ${t("Material")}`}
                      isError={!!errors?.requisitionMaterials?.[iMaterial]?.material}
                    />
                  ) : (
                    <FormDescription>
                      {activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.material?.description}
                    </FormDescription>
                  )}

                  {errors?.requisitionMaterials?.[iMaterial]?.material && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.requisitionMaterials?.[iMaterial]?.material?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Currency */}
                <FormControl isRequired={isEdit} className="md:col-span-3">
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.CURRENCY")}
                  </FormLabel>
                  {isEdit ? (
                    <Select
                      filter
                      control={control}
                      controlProps={{
                        rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CURRENCY") }) },
                      }}
                      name={`requisitionMaterials.${iMaterial}.currency`}
                      options={currencies.map((currency) => ({
                        label: `${currency?.currency} - ${currency?.description}`,
                        value: currency?.currency,
                      }))}
                      placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CURRENCY")}`}
                      isError={!!errors?.requisitionMaterials?.[iMaterial]?.currency}
                    />
                  ) : (
                    <FormDescription>
                      {activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.currency?.description}
                    </FormDescription>
                  )}

                  {errors?.requisitionMaterials?.[iMaterial]?.currency && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.requisitionMaterials?.[iMaterial]?.currency?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Price */}
                <FormControl isRequired className="md:col-span-2">
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.PRICE")}
                  </FormLabel>
                  {isEdit ? (
                    <div className="p-inputgroup">
                      <span className={`p-inputgroup-addon`}>
                        {watchRequisitionMaterialsFieldArray?.[iMaterial]?.currency}
                      </span>
                      <InputNumber
                        className={`w-full text-sm ${
                          errors?.requisitionMaterials?.[iMaterial]?.price && "border-2 border-red-400 shadow-none"
                        }`}
                        placeholder="0,00"
                        name={`requisitionMaterials.${iMaterial}.price`}
                        control={control}
                        controlProps={{
                          rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.PRICE") }) },
                        }}
                        isError={!!errors?.requisitionMaterials?.[iMaterial]?.price}
                      />
                    </div>
                  ) : (
                    <FormDescription>
                      {numeral(+activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.price).format("0,0.00")}{" "}
                      {activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.currency?.currency}
                    </FormDescription>
                  )}
                  {errors?.requisitionMaterials?.[iMaterial]?.price && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.requisitionMaterials?.[iMaterial]?.price?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Valuation */}
                <FormControl isRequired>
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.VALUATION")}
                  </FormLabel>
                  {isEdit ? (
                    <div className="p-inputgroup">
                      <InputText
                        type="number"
                        className={`w-full text-sm ${
                          errors?.requisitionMaterials?.[iMaterial]?.valuation && "border-2 border-red-400 shadow-none"
                        }`}
                        placeholder={t("LABEL.VALUATION")}
                        {...register(`requisitionMaterials.${iMaterial}.valuation`, {
                          required: t("INVALID.REQUIRED", { field: t("LABEL.VALUATION") }),
                        })}
                        isError={!!errors?.requisitionMaterials?.[iMaterial]?.valuation}
                      />
                    </div>
                  ) : (
                    <FormDescription>
                      {activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.valuation}
                    </FormDescription>
                  )}
                  {errors?.requisitionMaterials?.[iMaterial]?.valuation && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.requisitionMaterials?.[iMaterial]?.valuation?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Quantity */}
                <FormControl isRequired>
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.QUANTITY")}
                  </FormLabel>
                  {isEdit ? (
                    <div className="p-inputgroup">
                      <InputNumber
                        className={`w-full text-sm ${errors?.requisitionMaterials?.[iMaterial]?.quantity && 'border-2 border-red-400 shadow-none'}`}
                        placeholder="Jumlah"
                        name={`requisitionMaterials.${iMaterial}.quantity`}
                        control={control}
                        controlProps={{
                          rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.QUANTITY") }) },
                        }}
                        minFractionDigits={0}
                        isError={!!errors?.requisitionMaterials?.[iMaterial]?.quantity}
                      />
                    </div>
                  ) : (
                    <FormDescription>
                      {numeral(activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.quantity).format('0,0')}
                    </FormDescription>
                  )}
                  {errors?.requisitionMaterials?.[iMaterial]?.quantity && (
                    <FormHelperText className="text-red-400 text-xs">
                      {errors?.requisitionMaterials?.[iMaterial]?.quantity?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Material Price */}
                <FormControl isRequired className="md:col-span-2">
                  <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
                    {t("LABEL.MATERIAL_PRICE")}
                  </FormLabel>
                  {isEdit ? (
                    <div className="p-inputgroup">
                      <span className={`p-inputgroup-addon`}>{activeMaterial?.currency}</span>
                      <InputNumber
                        disabled
                        value={(+activeMaterial?.price / +activeMaterial?.valuation) * +activeMaterial?.quantity || 0}
                        name={`requisitionMaterials.${iMaterial}.materialPrice`}
                        control={control}
                        className={`w-full text-sm `}
                        placeholder={t("LABEL.MATERIAL_PRICE")}
                      />
                    </div>
                  ) : (
                    <FormDescription>
                      {numeral(
                        (activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.price /
                          activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.valuation) *
                          activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.quantity
                      ).format("0,0.00")}{" "}
                      {activeReqRequisition?.reqRequisitionMaterial?.[iMaterial]?.currency?.currency}
                    </FormDescription>
                  )}
                </FormControl>

                <hr className="md:col-span-6" />
              </div>

              {/* Able to delete if data more than 1 */}
              {isEdit && arrMaterial.length > 1 && (
                <div>
                  <button className="p-2 mt-4" onClick={() => requisitionMaterialsFieldArray.remove(iMaterial)}>
                    <BiTrash className="text-red-500 text-lg" />
                  </button>
                </div>
              )}
            </div>
          );
        })}

        {isEdit && (
          <Button
            size="sm"
            className="w-full mt-4 flex justify-center items-center text-gray-500 text-xs"
            onClick={() => requisitionMaterialsFieldArray.append(defaultMaterial)}
          >
            <BiPlus />
            <p>{t("LABEL.ADD", { field: "Material" })}</p>
          </Button>
        )}
      </FormControl>
    </>
  );
};

export default Form;
