import React, { useContext } from "react";
import { motion } from "framer-motion";
import ProcScheduleController, { ProcScheduleContext } from "./Controller";
import ProcScheduleDetail from "./components/ProcScheduleDetail";
import { ProcurementDetailContext } from "../../../Controller";
import AnnouncementPanel from "../../../../../../../../components/AnnouncementPanel";
import { vendorLimit } from "../../../../../../../../utils/vendorLimit";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { useTranslation } from "react-i18next";

const ProcSchedule = () => {
  const { t, i18n } = useTranslation();
  const { userData } = useContext(AuthContext);
  const { procurementType } = useContext(ProcurementDetailContext);
  const { isCreate, isDetailMenu, isEdit, vendors } =
    useContext(ProcScheduleContext);

  const minVendor = vendorLimit[procurementType]?.SUGGESTED_MIN;
  const isValid = vendors && vendors?.length >= minVendor;
  const procurementLabel =
    procurementType === "GENERAL"
      ? i18n.language === "id"
        ? "Seleksi Umum"
        : "General Selection"
      : procurementType === "LIMITED"
      ? i18n.language === "id"
        ? "Seleksi Terbatas"
        : "Limited"
      : procurementType === "DIRECT"
      ? i18n.language === "id"
        ? "Penunjukan Langsung"
        : "Direct Selection"
      : "-";

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="flex flex-col gap-4"
    >
      {!isValid && userData?.type === "admin" && (
        <AnnouncementPanel
          isLoading={!vendors}
          description={t(
            "TEXT.The minimum number of vendors for this procurement is N vendors_DESCRIPTION",
            {
              field: procurementLabel,
              n: minVendor,
            }
          )}
          title={t(
            "TEXT.The minimum number of vendors for this procurement is N vendors",
            {
              field: minVendor,
            }
          )}
        />
      )}
      <div className="relative">
        {!isValid && userData?.type === "admin" && (
          <div className="absolute w-full h-full opacity-60 rounded-md bg-white z-50"></div>
        )}
        <ProcScheduleDetail />
      </div>
    </motion.div>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.procSchedule){
    return <div></div>
  }

  return (
    <ProcScheduleController>
      <ProcSchedule />
    </ProcScheduleController>
  )
};
