import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import EquipmentDetail from "./components/EquipmentDetail";
import EquipmentList from "./components/EquipmentList";
import EquipmentNew from "./components/EquipmentNew";
import EquipmentController, { EquipmentContext } from "./Controller";

const Equipment = () => {
  const { isDetailMenu, isCreate } = useContext(EquipmentContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <EquipmentNew /> : isDetailMenu ? <EquipmentDetail /> : <EquipmentList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <EquipmentController>
    <Equipment />
  </EquipmentController>
);
