import React, { useContext, useEffect } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BODContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import SelectButton from "../../../../../../../components/inputForm/SelectButton";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import InputMask from "../../../../../../../components/inputForm/InputMask";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile, getFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeBOD, form, countries, bodTypes, shareholders } = useContext(BODContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  // Get From Shareholder
  const watchShareholder = form.watch("shareholder");
  useEffect(() => {
    if (!watchShareholder) return;

    const getFromShareholder = async () => {
      const activeShareholder = shareholders?.find((shareholder) => shareholder?.shareholderId === watchShareholder);

      form.setValue("bodName", activeShareholder?.shareholderName);
      form.setValue("citizenship", activeShareholder?.citizenship?.regionCode);
      form.setValue("taxNumber", activeShareholder?.taxNumber);
      form.setValue("idCardNumber", activeShareholder?.idCardNumber);

      const taxAttachment = await getFile(activeShareholder?.taxAttachment);
      form.setValue("taxAttachment", [taxAttachment]);
      trigger("taxAttachment");

      const idCardAttachment = await getFile(activeShareholder?.idCardAttachment);
      form.setValue("idCardAttachment", [idCardAttachment]);
      trigger("idCardAttachment");
    };

    getFromShareholder();
  }, [watchShareholder]);

  register("taxAttachment");
  register("idCardAttachment", {
    required: {
      value: isCreate || !activeBOD?.idCardAttachment,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.ID_CARD_NUMBER") }) }),
    },
  });

  const citizenship = form.watch("citizenship");

  return (
    <>
      {/* From Shareholder */}
      {isEdit && (
        <FormControl className="md:col-span-2">
          <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
            {t("LABEL.CHOOSE_FROM", { field: t("LABEL.SHAREHOLDER") })}
          </FormLabel>
          <Select
            filter
            control={control}
            name="shareholder"
            options={shareholders.map((shareholder) => ({
              label: `${shareholder?.shareholderName} - ${shareholder?.sharePercentage || 0}%`,
              value: shareholder?.shareholderId,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.SHAREHOLDER")}`}
          />
        </FormControl>
      )}

      <hr className="md:col-span-2" />

      {/* BOD Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BOD_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BOD_TYPE") }) },
            }}
            name="bodType"
            options={bodTypes.map((bodType) => ({
              value: bodType?.bodType,
              label: t(bodType?.labelI18n || bodType?.label),
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BOD_TYPE")}`}
            isError={!!errors?.bodType}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{t(activeBOD?.bodType?.labelI18n || activeBOD?.bodType?.label)}</FormDescription>
        )}

        {errors?.bodType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.bodType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* BOD Name */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DIRECTOR_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.bodName && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DIRECTOR_NAME")}
            {...register("bodName", { required: t("INVALID.REQUIRED", { field: t("LABEL.DIRECTOR_NAME") }) })}
          />
        ) : (
          <FormDescription>{activeBOD?.bodName}</FormDescription>
        )}
        {errors?.bodName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.bodName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Citizenship */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CITIZENSHIP")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CITIZENSHIP") }) },
            }}
            name="citizenship"
            options={countries.map((country) => ({
              label: country?.description,
              value: country?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CITIZENSHIP")}`}
            isError={!!errors?.citizenship}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeBOD?.citizenship?.description}</FormDescription>
        )}

        {errors?.citizenship && (
          <FormHelperText className="text-red-400 text-xs">{errors?.citizenship?.message}</FormHelperText>
        )}
      </FormControl>

      {/* BOD Position */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DIRECTOR_POSITION")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.bodPosition && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DIRECTOR_POSITION")}
            {...register("bodPosition", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.DIRECTOR_POSITION") }),
            })}
          />
        ) : (
          <FormDescription>{activeBOD?.position}</FormDescription>
        )}
        {errors?.bodPosition && (
          <FormHelperText className="text-red-400 text-xs">{errors?.bodPosition?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Tax Number */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.TAX_NUMBER")}
        </FormLabel>
        {isEdit ? (
          citizenship === "ID" ? (
            <InputMask
              mask={"99.999.999.9-999.999"}
              placeholder={t("LABEL.TAX_NUMBER")}
              name="taxNumber"
              control={control}
            />
          ) : (
            <InputText
              placeholder={t("LABEL.TAX_NUMBER")}
              className={`w-full text-sm ${errors?.taxNumber && "border-2 border-red-400 shadow-none"}`}
              {...register("taxNumber")}
            />
          )
        ) : (
          <FormDescription>{activeBOD?.taxNumber}</FormDescription>
        )}
        {errors?.taxNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.taxNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Tax Attachment */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.TAX_NUMBER") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.taxAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.TAX_NUMBER"),
            })}`}
            name="taxAttachment"
            fileList={form.getValues("taxAttachment")}
            onChange={(files) => {
              setValue("taxAttachment", files);
              trigger("taxAttachment");
            }}
          />
        ) : activeBOD?.taxAttachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeBOD?.taxAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <span className="text-gray-300">{t("LABEL.EMPTY")}</span>
        )}
        {errors?.taxAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.taxAttachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Id Card Number */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ID_CARD_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            placeholder={t("LABEL.ID_CARD_NUMBER")}
            className={`w-full text-sm ${errors?.idCardNumber && "border-2 border-red-400 shadow-none"}`}
            {...register("idCardNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.ID_CARD_NUMBER") }),
            })}
          />
        ) : (
          <FormDescription>{activeBOD?.idCardNumber}</FormDescription>
        )}
        {errors?.idCardNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.idCardNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Id Card Attachment */}
      <FormControl isRequired={isCreate || !activeBOD?.idCardAttachment}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.ID_CARD_NUMBER") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.idCardAttachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.ID_CARD_NUMBER"),
            })}`}
            name="idCardAttachment"
            fileList={form.getValues("idCardAttachment")}
            onChange={(files) => {
              setValue("idCardAttachment", files);
              trigger("idCardAttachment");
            }}
          />
        ) : activeBOD?.idCardAttachment ? (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeBOD?.idCardAttachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        ) : (
          <span className="text-gray-300">{t("LABEL.EMPTY")}</span>
        )}
        {errors?.idCardAttachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.idCardAttachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
