import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import VendorTable from "./components/VendorTable";
import VendorController, { VendorContext } from "./Controller";
import { ProcurementDetailContext } from "../../../Controller";

const WinningProposal = () => {
  const { t } = useTranslation();

  return (
    <>
      <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
        <VendorTable />
      </motion.div>
    </>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.winningProposal){
    return <div></div>
  }

  return (
    <VendorController>
      <WinningProposal />
    </VendorController>
  )
};
