import React, { useContext } from "react";
import { motion } from "framer-motion";
import { BiCrown } from "react-icons/bi";
import { AuctionLiveContext } from "../Controller";
import { AuctionContext } from "../../../../Controller";
import { useTranslation } from "react-i18next";

const LeadRank = () => {
  const { t } = useTranslation();
  const { auction } = useContext(AuctionContext);
  const { isLeading } = useContext(AuctionLiveContext);

  return isLeading && auction?.isTopRankVisible ? (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
      <div className="w-full bg-yellow-300 rounded-md text-sm p-4 flex items-center gap-2">
        <BiCrown className="text-xl" />
        <span className="font-semibold">{t("TEXT.AUCTION_LEADING")}</span>
      </div>
    </motion.div>
  ) : (
    <></>
  );
};

export default LeadRank;
