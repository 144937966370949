import { Spinner } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef } from "react";
import { Outlet, useParams } from "react-router-dom";
import UserAuth from "../../../controllers/auth/UserAuth";
import MobileBar from "./components/MobileBar";
import MobileNavbar from "./components/MobileNavbar";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import UserTemplateController, { UserTemplateContext } from "./Controller";
import BgTexture from "../../../assets/img/static/background-texture-1.png";

const UsersTemplate = () => {
  const params = useParams();
  const { loadingMenu } = useContext(UserTemplateContext);

  const sectionRef = useRef(null);
  useEffect(() => {
    sectionRef.current.scrollTo(0, 0);
  }, [params]);

  return (
    <div>
      <UserAuth />
      {loadingMenu && (
        <div className="absolute w-full h-screen bg-white flex justify-center items-center" style={{ zIndex: 10000 }}>
          <Spinner size="xl" color="red.500" />
        </div>
      )}

      {/* Desktop Version */}
      <div
        className="hidden md:grid md:grid-cols-5 bg-gray-100"
        style={{
          backgroundImage: `url(${BgTexture})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% auto",
          backgroundPosition: "bottom left",
        }}
      >
        <Sidebar />
        <div className="h-screen md:col-span-4 flex flex-col">
          <Navbar />
          <div className="flex-1 overflow-auto pr-12 mb-12" ref={sectionRef}>
            <Outlet />
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="md:hidden bg-gray-50" style={{ minHeight: "100vh" }}>
        <MobileNavbar />
        <div className="pb-12">
          <Outlet />
        </div>
        <MobileBar />
      </div>
    </div>
  );
};

export default () => (
  <UserTemplateController>
    <UsersTemplate />
  </UserTemplateController>
);
