import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import { Dropdown } from "primereact/dropdown";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretUp } from "react-icons/ai";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../controllers/auth/AuthController";
import { userRouteSlug } from "../../../../routes/userRoutes";
import { AuctionContext } from "../Controller";

const DropdownItem = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { section, auctionId } = useParams();
  const isActive = `/${section}` === data?.to;
  const hasChildren = Array.isArray(data?.items);

  const [isChildrenShow, setChildrenShow] = useState(true);

  const handleClick = () => {
    const path = userRouteSlug.PANEL + userRouteSlug.AUCTION + `/${auctionId}` + data?.to;

    hasChildren ? setChildrenShow(!isChildrenShow) : data.isTargetBlack ? window.open(path, "_blank") : navigate(path);
  };

  return (
    <>
      <button
        className={`p-4 rounded-sm flex justify-between items-center ${
          isActive ? "bg-gray-50" : hasChildren ? "" : ""
        }`}
        onClick={handleClick}
      >
        <span className={`text-gray-600 flex items-center gap-2 ${hasChildren ? "font-bold" : "font-medium"}`}>
          {data?.Icon && <data.Icon className="text-base" />}
          <span className={`text-left ${isActive ? "font-semibold text-red-500" : ""}`}>{t(data?.labelI18n)}</span>
        </span>
        {hasChildren && (
          <AiFillCaretUp className={`text-xs text-gray-600 ${isChildrenShow && "transform rotate-180"}`} />
        )}
        {data.isTargetBlack && <BsArrowUpRightSquare className={` text-gray-600`} />}
      </button>
      {!data?.isLastItem && <hr className="border-gray-100" />}
      <AnimatePresence>
        {hasChildren && isChildrenShow && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className="flex flex-col overflow-hidden"
          >
            {data.items.map((item, iItem) => (
              <DropdownItem key={iItem} data={item} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const AuctionDropdown = () => {
  const { userData } = useContext(AuthContext);
  const { activeSection, sectionCategories, sections } = useContext(AuctionContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // Redirect if access restricted menu
  useEffect(() => {
    if (
      sections.find((section) => section?.to?.includes(params.section) && section?.restrict?.includes(userData?.type))
    )
      navigate(userRouteSlug.PANEL + userRouteSlug.AUCTION + `/${params?.auctionId}` + "/general");
  }, [sections, params, userData]);

  const sectionMap = sectionCategories.map((sectionCategory) => ({
    ...sectionCategory,
    items: sections
      .filter((section) => section.category === sectionCategory.name)
      .filter((section) => !section?.restrict?.includes(userData?.type))
      .map((section, iSection, arrSection) => ({
        ...section,
        isLastItem: iSection === arrSection - 1,
      })),
  }));

  return (
    <div className="bg-white rounded-md text-sm">
      <div className="hidden md:flex flex-col">
        {sectionMap.map((item, iItem) => (
          <DropdownItem key={iItem} data={item} />
        ))}
      </div>
      <div className="md:hidden p-4">
        <Dropdown
          className="w-full border-0"
          value={activeSection?.name}
          options={sections.map((section) => ({
            label: t(section?.labelI18n),
            value: section?.name,
            to: section?.to,
          }))}
          onChange={({ value }) =>
            navigate(
              userRouteSlug.PANEL +
                userRouteSlug.AUCTION +
                `/${params?.auctionId}` +
                sections?.find((section) => section?.name === value)?.to
            )
          }
        />
      </div>
    </div>
  );
};

export default AuctionDropdown;
