import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { ProcurementDetailContext } from "../../../Controller";

export const ReqProcSubmissionContext = createContext();
const { Provider } = ReqProcSubmissionContext;

const ReqProcSubmissionController = ({ children }) => {
  const toast = useToast();
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        description: newForm?.description,
        requirementProcurementStep: newForm?.requirementProcurementStep,
        requirementProcurementCover: newForm?.requirementProcurementCover,
        isRequired: newForm?.isRequired,
      });
    }
  };

  // Requirement Procurement Submission Step
  const [reqProcSubmissionSteps, setReqProcSubmissionSteps] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios
        .get(`${process.env.REACT_APP_TMS_API}/requirement-procurement-step`)
        .then((res) => {
          if (mounted) {
            const newReqProcSubmissionSteps =
              res?.data?.requirementProcurementStep;
            let temp = [];
            procurement?.bidEntryMethod?.bidEntryMethod === "2 COVER"
              ? temp = newReqProcSubmissionSteps
                    ?.filter(
                      (steps) =>
                        steps?.requirementProcurementStep === "PENGADAAN"
                    )
                    .map((step) => step)
                
              : temp = newReqProcSubmissionSteps
                    ?.filter(
                      (steps) =>
                        steps?.requirementProcurementStep !== "PENGADAAN" && steps?.requirementProcurementStep !== "PRAKUALIFIKASI"
                    )
                    .map((step) => step)
            if (procurement?.procurementSetting?.prequalification) {
              const preqSteps = newReqProcSubmissionSteps?.filter((steps) => steps?.requirementProcurementStep === "PRAKUALIFIKASI");
              if (preqSteps?.length > 0) temp = [...temp, ...preqSteps];
            }

            setReqProcSubmissionSteps(temp)
          }
        });

    return () => {
      mounted = false;
    };
  }, [token, procurement]);

  // Requirement Procurement Submission Cover
  const reqProcSubmissionStep = form.watch("requirementProcurementStep");
  const [stepType, setStepType] = useState(null);
  useEffect(() => {
    let mounted = true;
    setStepType(null);

    const getStepType = () => {
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/requirement-procurement-cover?requirementProcurementStepCode=${reqProcSubmissionStep}`
        )
        .then((res) => {
          if (mounted) {
            res?.data?.data?.length
              ? setStepType(res?.data?.data)
              : setStepType(null);
          }
        });
    };
    reqProcSubmissionStep && getStepType();

    return () => {
      mounted = false;
    };
  }, [reqProcSubmissionStep]);

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeReqProcSubmission, setActiveReqProcSubmission] = useState();
  const handleDetailMenu = (isOpen, data) => {
    setDetailMenu(isOpen);
    setActiveReqProcSubmission({ ...data });
    isOpen && resetForm(data);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Get Public Document
  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadReqProcurement(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  const [reqProcSubmission, setReqProcSubmissions] = useState([]);
  const [loadingReqProcSubmissions, setLoadingReqProcSubmissions] =
    useState(false);
  const [reloadReqProcurement, setReloadReqProcurement] = useState(true);

  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const limitx = `limit=${limit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limitx}&${page}`;

    const getReqProcSubmission = async () => {
      setLoadingReqProcSubmissions(true);

      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-requirement/${procurementNumber}${filter}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newReqProcSubmission = res?.data?.procurementRequirement;
            setReqProcSubmissions(newReqProcSubmission);
            setTotalData(res?.data?.pagination?.totalData);
            if (activeReqProcSubmission) {
              const newActiveReqProcSubmission =
                newReqProcSubmission.find(
                  (reqProcSubmission) =>
                    reqProcSubmission?.procurementRequirementId ===
                    activeReqProcSubmission?.procurementRequirementId
                ) || null;
              setActiveReqProcSubmission(newActiveReqProcSubmission);
              resetForm(newActiveReqProcSubmission);
            }
          }
        })
        .finally(() => {
          setReloadReqProcurement(false);
          setLoadingReqProcSubmissions(false);
        });
    };

    procurementNumber && reloadReqProcurement && getReqProcSubmission();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadReqProcurement]);

  // -------------------------------
  // Create
  // -------------------------------

  const [isCreate, setCreate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const createReqProcSubmission = (payload) => {
    setLoadingCreate(true);

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-requirement/${procurement.procurementNumber}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadReqProcurement(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeReqProcSubmission);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateReqProcSubmission = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-requirement/${activeReqProcSubmission?.procurementRequirementId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadReqProcurement(true);
      });
  };

  // -------------------------------
  // Delete
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const handleDelete = (isOpen) => {
    setModalDeleteOpen(isOpen);
  };
  const deleteReqProcSubmission = () => {
    setLoadingDelete(true);

    axios
      .delete(
        `${process.env.REACT_APP_TMS_API}/procurement-requirement/${activeReqProcSubmission?.procurementRequirementId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        handleDelete(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadReqProcurement(true);
      });
  };

  return (
    <Provider
      value={{
        tableForm,
        reqProcSubmission,
        loadingReqProcSubmissions,
        totalData,
        limit,

        form,
        activeReqProcSubmission,

        reqProcSubmissionSteps,
        stepType,

        isCreate,
        handleCreate,
        loadingCreate,
        createReqProcSubmission,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateReqProcSubmission,

        isModalDeleteOpen,
        handleDelete,
        loadingDelete,
        deleteReqProcSubmission,
        setModalDeleteOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export default ReqProcSubmissionController;
