import { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { ProcurementOfferContext } from '../../Controller';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';

export const CommercialContext = createContext();
const { Provider } = CommercialContext;

const CommercialController = ({ children }) => {
	const { httpRequest } = useContext(HelperContext);
	const { commercialOffer, commercialForm, priceInputs, setPriceInputs, errors, setErrors } = useContext(ProcurementOfferContext);

	const [isEdit, setIsEdit] = useState(true);
	const [requirements, setRequirements] = useState(null);

	const form = commercialForm;

	// Incoterm
	const [incoterms, setIncoterms] = useState([]);
	useEffect(async () => {
		let mounted = true;
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/incoterm`,
		});
		if (!mounted) return;
		setIncoterms(response?.incoterms);

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (commercialOffer) {
			setIsEdit(false);
		}
	}, [commercialOffer]);

	const isAnyError = () => {
		let tempErrors = errors;
		let anyError = false;
		priceInputs.forEach((val, idx) => {
			if (val === '') {
				tempErrors[idx] = 'Harga Wajib diisi';
				anyError = true;
			} else if (val === '0') {
				tempErrors[idx] = 'Harga harus lebih dari 0';
				anyError = true;
			} else {
				tempErrors[idx] = null;
			}
		});

		setErrors([...tempErrors]);
		return anyError;
	};

	return (
		<Provider
			value={{
				isEdit,
				form,
				setIsEdit,
				requirements,
				setRequirements,
				incoterms,
				priceInputs,
				setPriceInputs,
				errors,
				setErrors,
				setIncoterms,
				isAnyError,
			}}
		>
			{children}
		</Provider>
	);
};

export default CommercialController;
