import React, { useContext } from "react";
import { motion } from "framer-motion";
import AuctionTable from "./components/AuctionTable";
import AuctionController, { AuctionContext } from "./Controller";
import AuctionNew from "./components/AuctionNew";
import { ProcurementDetailContext } from "../../../Controller";

const Auction = () => {
  const { isCreate, internalAuctions, loadingInternalAuctions, internalTotalData, internalLimit, internalTableForm } =
    useContext(AuctionContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }}>
      {isCreate ? (
        <AuctionNew />
      ) : (
        <div className="flex flex-col gap-6">
          <AuctionTable
            auctions={internalAuctions}
            loadingAuctions={loadingInternalAuctions}
            totalData={internalTotalData}
            limit={internalLimit}
            tableForm={internalTableForm}
            isPublic={false}
          />
        </div>
      )}
    </motion.div>
  );
};

export default () => {
  const { procurement } = useContext(ProcurementDetailContext);
  if (!procurement?.procurementStep?.auction){
    return <div></div>
  }
  if (!procurement?.procurementSetting?.eAuction){
    return <div></div>
  }

  return (
    <AuctionController>
      <Auction />
    </AuctionController>
  )
};
