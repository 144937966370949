import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BiCaretLeftCircle } from "react-icons/bi";
import ProcurementDropdown from "./components/ProcurementDropdown";
import ProcurementOverview from "./components/ProcurementOverview";
import ProcurementDetailController, { ProcurementDetailContext } from "./Controller";
import { userRouteSlug } from "../../../../../routes/userRoutes";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import VendorProcurementAcceptance from "./components/VendorProcurementAcceptance";
import ProcurementWinner from "./components/ProcurementWinner";
import AnnouncementPanel from "../../../../../components/AnnouncementPanel";
import VendorRegistration from "./components/VendorRegistration";

const ProcurementDetail = () => {
  const { t } = useTranslation();
  const { userData, isBuyer, isGm, isSgm, isUser } = useContext(AuthContext);
  const { activeSection, vendorStatus, procurement, procurementSchedule, canOffer } = useContext(ProcurementDetailContext);

  return (
    <div className="flex flex-col gap-4 md:gap-6 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        {userData?.type !== "user" && (
          <Link to={userRouteSlug.PANEL + userRouteSlug.PROCUREMENT}>
            <BiCaretLeftCircle className="text-xl text-gray-500 hover:text-gray-700" />
          </Link>
        )}
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.PROCUREMENT_DETAIL")}</h2>
      </div>

      {vendorStatus?.status === "DRAFT" && <VendorProcurementAcceptance />}
      {vendorStatus === null && <VendorRegistration />}
      <div className="flex gap-4">
        <ProcurementOverview />
        <ProcurementWinner />
      </div>
      {
        isBuyer && procurementSchedule?.shouldBeReview &&
        <AnnouncementPanel isClosable title={t("TEXT.PROCUREMENT_REJECTED_BUYER_ANNOUNCEMENT")} description={t("TEXT.PROCUREMENT_REJECTED_BUYER_ANNOUNCEMENT_DESCRIPTION")} />
      }
      {
        ((isGm || isSgm) && procurementSchedule?.shouldBeReview) && <AnnouncementPanel isClosable title={t("TEXT.PROCUREMENT_REJECTED_GM_ANNOUNCEMENT")} description={t("TEXT.PROCUREMENT_REJECTED_GM_ANNOUNCEMENT_DESCRIPTION")} />
      }
      {
        (isUser && procurement?.procurementSetting?.prequalification && canOffer === false) && <AnnouncementPanel isClosable title={"Anda tidak dapat melanjutkan pengadaan ini"} description={"Dokumen prakualifikasi yang diserahkan tidak memenuhi syarat dari pengadaan ini"} />
      }
      <div className="grid md:grid-cols-4 gap-6">
        <div>
          <ProcurementDropdown />
        </div>
        <div className="md:col-span-3 ">{activeSection && <activeSection.Component />}</div>
      </div>
    </div>
  );
};

export default () => (
  <ProcurementDetailController>
    <ProcurementDetail />
  </ProcurementDetailController>
);
