import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { useContext } from "react";
import Select from "../../../../../../components/inputForm/Select";
import { emailPattern, websitePattern } from "../../../../../../utils/regex.util";
import { RegisterContext } from "../../../Controller";
import CompanyProfileController, { CompanyProfileContext } from "./Controller";
import { useTranslation } from "react-i18next";

const CompanyProfileForm = () => {
  const { t } = useTranslation();
  const { countries, provinces, cities, districts } = useContext(CompanyProfileContext);
  const { companyProfileForm: form } = useContext(RegisterContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Company Description */}
      <FormControl isRequired className="col-span-2">
        <FormLabel className="text-sm">{t("LABEL.COMPANY_DESCRIPTION")}</FormLabel>
        <InputTextarea
          className={`w-full text-sm ${errors?.companyDescription && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("TEXT.WRITE_COMPANY_DESCRIPTION")}
          {...register("companyDescription", {
            required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_DESCRIPTION") }),
          })}
        />
        {errors?.companyDescription && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyDescription?.message}</FormHelperText>
        )}
      </FormControl>

      <div className="md:col-span-2 my-2 md:my-4">
        <hr />
      </div>

      {/* Country */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.COUNTRY")}</FormLabel>
        <Select
          filter
          className="text-sm"
          control={control}
          controlProps={{
            rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.COUNTRY") }) },
          }}
          name="country"
          options={countries.map((country) => ({
            label: country?.description,
            value: country?.regionCode,
          }))}
          placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.COUNTRY")}`}
          isSearchable
          isError={!!errors?.country}
        />
        {errors?.country && (
          <FormHelperText className="text-red-400 text-xs">{errors?.country?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Province */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.PROVINCE")}</FormLabel>
        {provinces === null ? (
          <InputText
            className={`w-full text-sm ${errors?.province && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.PROVINCE")}
            {...register("province", { required: t("INVALID.REQUIRED", { field: t("LABEL.PROVINCE") }) })}
          />
        ) : (
          <Select
            filter
            className="text-sm"
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.PROVINCE") }) },
            }}
            name="province"
            options={provinces.map((province) => ({
              label: province?.description,
              value: province?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.PROVINCE")}`}
            isSearchable
            isDisabled={!provinces.length}
            isError={!!errors?.province}
          />
        )}
        {errors?.province && (
          <FormHelperText className="text-red-400 text-xs">{errors?.province?.message}</FormHelperText>
        )}
      </FormControl>

      {/* City */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.CITY")}</FormLabel>
        {cities === null ? (
          <InputText
            className={`w-full text-sm ${errors?.city && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.CITY")}
            {...register("city", { required: t("INVALID.REQUIRED", { field: t("LABEL.CITY") }) })}
          />
        ) : (
          <Select
            filter
            className="text-sm"
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CITY") }) },
            }}
            name="city"
            options={cities.map((city) => ({
              label: city?.description,
              value: city?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CITY")}`}
            isSearchable
            isDisabled={!cities.length}
            isError={!!errors?.city}
          />
        )}
        {errors?.city && <FormHelperText className="text-red-400 text-xs">{errors?.city?.message}</FormHelperText>}
      </FormControl>

      {/* District */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.DISTRICT")}</FormLabel>
        {districts === null ? (
          <InputText
            className={`w-full text-sm ${errors?.city && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DISTRICT")}
            {...register("district", { required: t("INVALID.REQUIRED", { field: t("LABEL.DISTRICT") }) })}
          />
        ) : (
          <Select
            filter
            className="text-sm"
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.DISTRICT") }) },
            }}
            name="district"
            options={districts.map((district) => ({
              label: district?.description,
              value: district?.regionCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.DISTRICT")}`}
            isSearchable
            isDisabled={!districts.length}
            isError={!!errors?.district}
          />
        )}
        {errors?.district && (
          <FormHelperText className="text-red-400 text-xs">{errors?.district?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Address */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.ADDRESS")}</FormLabel>
        <InputTextarea
          className={`w-full text-sm ${errors?.address && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("LABEL.ADDRESS")}
          {...register("address", { required: t("INVALID.REQUIRED", { field: t("LABEL.ADDRESS") }) })}
        />
        {errors?.address && (
          <FormHelperText className="text-red-400 text-xs">{errors?.address?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Postal Code */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.POSTAL_CODE")}</FormLabel>
        <InputText
          className={`w-full text-sm ${errors?.postalCode && "border-2 border-red-400 shadow-none"}`}
          placeholder={t("LABEL.POSTAL_CODE")}
          type="number"
          {...register("postalCode", { required: t("INVALID.REQUIRED", { field: t("LABEL.POSTAL_CODE") }) })}
        />
        {errors?.postalCode && (
          <FormHelperText className="text-red-400 text-xs">{errors?.postalCode?.message}</FormHelperText>
        )}
      </FormControl>

      <div className="md:col-span-2 my-2 md:my-4">
        <hr />
      </div>

      {/* Phone Number */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_PHONE")}</FormLabel>
        <div className="flex">
          <div className="w-20">
            <InputText
              className={`w-full rounded-r-none text-sm ${
                errors?.companyPhoneCode && "border-2 border-r-0 border-red-400 shadow-none"
              }`}
              {...register("companyPhoneCode", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.PHONE_CODE") }),
                pattern: {
                  value: /\+\d+/,
                },
              })}
              defaultValue="+62"
            />
          </div>
          <div className="w-full">
            <InputText
              className={`w-full rounded-l-none text-sm ${
                errors?.companyPhoneNumber && "border-2 border-red-400 shadow-none"
              }`}
              placeholder={t("LABEL.COMPANY_PHONE")}
              type="number"
              {...register("companyPhoneNumber", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_PHONE") }),
              })}
            />
            {errors?.companyPhoneNumber && (
              <FormHelperText className="text-red-400 text-xs">{errors?.companyPhoneNumber?.message}</FormHelperText>
            )}
          </div>
        </div>
      </FormControl>

      {/* Fax Number */}
      <FormControl>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_FAX")}</FormLabel>
        <div className="flex">
          <div className="w-20">
            <InputText className={`w-full rounded-r-none text-sm`} {...register("companyFaxCode")} defaultValue="+62" />
          </div>
          <div className="w-full">
            <InputText
              className={`w-full rounded-l-none text-sm ${
                errors?.companyFaxNumber && "border-2 border-red-400 shadow-none"
              }`}
              placeholder={t("LABEL.COMPANY_FAX")}
              type="number"
              {...register("companyFaxNumber")}
            />
            {errors?.companyFaxNumber && (
              <FormHelperText className="text-red-400 text-xs">{errors?.companyFaxNumber?.message}</FormHelperText>
            )}
          </div>
        </div>
      </FormControl>

      {/* Email */}
      <FormControl isRequired>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_EMAIL")}</FormLabel>
        <InputText
          className={`w-full text-sm ${errors?.companyEmail && "border-2 border-red-400 shadow-none"}`}
          placeholder="email@company.com"
          {...register("companyEmail", {
            required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_EMAIL") }),
            pattern: {
              value: emailPattern,
              message: t("INVALID.EMAIL", { field: t("LABEL.COMPANY_EMAIL") }),
            },
          })}
        />
        {errors?.companyEmail && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyEmail?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Website */}
      <FormControl>
        <FormLabel className="text-sm">{t("LABEL.COMPANY_WEBSITE")}</FormLabel>
        <InputText
          className={`w-full text-sm ${errors?.companyWebsite && "border-2 border-red-400 shadow-none"}`}
          placeholder="company.com"
          {...register("companyWebsite", {
            pattern: {
              value: websitePattern,
              message: t("INVALID.EMAIL", { field: t("LABEL.COMPANY_WEBSITE") }),
            },
          })}
        />
        {errors?.companyWebsite && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyWebsite?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default () => (
  <CompanyProfileController>
    <CompanyProfileForm />
  </CompanyProfileController>
);
