import {
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { BsFillInfoCircleFill, BsCheckLg } from "react-icons/bs";
import { NegotiationContext } from "../Controller";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import numeral from "numeral";
import { ProcurementDetailContext } from "../../../../Controller";
import NextStepButton from "../../../NextStepButton";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { handleCreate, vendorNegotiating } = useContext(NegotiationContext);
  const { userData } = useContext(AuthContext);
  const { procurement, reqRequisitions } = useContext(ProcurementDetailContext);

  const currency = reqRequisitions?.map((material, iMaterial) =>
    material?.reqRequisitionMaterial?.map(
      (currency, iCurrency) => currency?.currency?.currency
    )
  );

  const isVendorNegotiating = vendorNegotiating?.find(
    (dataVend, iDataVend) =>
      dataVend?.negotiation?.companyRegCode === data?.data?.companyRegCode &&
      dataVend?.negotiation?.procurement?.procurementNumber ===
        procurement?.procurementNumber
  );

  return (
    <Tr
      className={`hover:bg-gray-50 ${
        userData?.type !== "user" && isVendorNegotiating ? "cursor-pointer" : ""
      } `}
      onClick={() =>
        userData?.type !== "user" && isVendorNegotiating
          ? handleCreate(true, data?.data)
          : ""
      }
    >
      <Td>{data?.data?.company_name}</Td>
      <Td>
        {currency?.map((curr) => curr[0]).slice(1)}{" "}
        {data?.matPrice || data?.matPrice?.subPrice
          ? numeral(
              parseInt(data?.matPrice) || parseInt(data?.matPrice?.subPrice)
            ).format("0,0.00")
          : ""}
      </Td>
      <Td className="w-1/3">
        {isVendorNegotiating?.status === "APPROVED" ? (
          <div className="text-xs italic text-green-500 font-bold flex gap-2">
            <BsCheckLg /> {t("TEXT.STATUS_NEGOTIATION_APPROVED")}
          </div>
        ) : isVendorNegotiating?.status === "PROPOSED" ? (
          <div className="text-xs italic text-yellow-500 font-bold flex gap-2 ">
            <BsFillInfoCircleFill className="animate-ping " />{" "}
            {t("TEXT.STATUS_NEGOTIATION_PROPOSED")}
          </div>
        ) : isVendorNegotiating?.status === "DRAFT" ? (
          <div className="text-xs italic text-blue-500 font-bold flex gap-2 ">
            <BsFillInfoCircleFill className="animate-ping " />{" "}
            {t("TEXT.STATUS_NEGOTIATION_DRAFT")}
          </div>
        ) : (
          <div className="text-xs italic text-gray-500 font-bold flex gap-2 ">
            <BsFillInfoCircleFill className="animate-ping " />{" "}
            {t("TEXT.STATUS_NEGOTIATION_NOTHING")}
          </div>
        )}
      </Td>
      <Td className="px-0">
        <div className="h-full gap-2 flex justify-center items-center">
          <Button
            size="xs"
            colorScheme="teal"
            className="font-medium text-xs"
            onClick={() => handleCreate(true, data?.data)}
          >
            {userData?.type === "user"
              ? t("LABEL.PROCUREMENT_NEGOTIATION_VIEW_BID")
              : t("TOOLTIP.NEGOTIATION_OPEN")}
          </Button>
        </div>
      </Td>
    </Tr>
  );
};

const NegotiationListVendorTable = () => {
  const { totalData, limit, tableForm, loadingpassOfferVendor, totArray } =
    useContext(NegotiationContext);

  const { userData } = useContext(AuthContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm col-span-3">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.VENDOR")}</Th>

            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_NEGOTIATION_TOTAL_PRICE")}
            </Th>
            <Th className="text-red-500">{t("LABEL.STATUS")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {userData?.type === "user" ? (
            loadingpassOfferVendor ? (
              <Tr>
                <Td colSpan={5}>
                  <div className="w-full h-full flex justify-center items-center">
                    <Spinner color="red.500" />
                  </div>
                </Td>
              </Tr>
            ) : !totArray?.length ? (
              <Tr>
                <Td colSpan={5} className="text-center">
                  <p className="text-sm text-gray-500">
                    {t("TEXT.DATA_NOT_FOUND")}
                  </p>
                </Td>
              </Tr>
            ) : (
              totArray
                ?.filter(
                  (regCode) =>
                    regCode?.data?.companyRegCode === userData?.username
                )
                .map((passOfferVendors, ipassOfferVendor) => (
                  <Row key={ipassOfferVendor} data={passOfferVendors} />
                ))
            )
          ) : loadingpassOfferVendor ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !totArray?.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">
                  {t("TEXT.DATA_NOT_FOUND")}
                </p>
              </Td>
            </Tr>
          ) : (
            totArray
              ?.sort(
                (a, b) =>
                  (a?.data?.matPrice || a?.matPrice?.subPrice) -
                  (b?.data?.matPrice || b?.matPrice?.subPrice)
              )
              ?.map((passOfferVendors, ipassOfferVendor) => (
                <Row key={ipassOfferVendor} data={passOfferVendors} />
              ))
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
      <NextStepButton
        to="winningProposal"
        url="winning-proposal"
        className="mt-4 mr-4 mb-4"
        toLabel={`Usulan Pemenang`}
      />
    </div>
  );
};

export default NegotiationListVendorTable;
