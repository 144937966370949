import Dashboard from "../pages/user/Dashboard";
import Vendor from "../pages/user/master/Vendor";
import UsersTemplate from "../pages/templates/users";
import Page404 from "../pages/misc/Page404";
import CompanyProfile from "../pages/user/CompanyProfile";
import { Navigate } from "react-router-dom";
import Account from "../pages/user/Account";
import Activity from "../pages/user/Activity";
import VendorOverview from "../pages/user/master/Vendor/components/VendorOverview";
import Notification from "../pages/user/Notification";
import Admin from "../pages/user/master/Admin";
import Material from "../pages/user/master/Material";
import ReqRequisition from "../pages/user/ReqRequisition";
import Procurement from "../pages/user/Procurement";
import ProcurementDetail from "../pages/user/Procurement/components/ProcurementDetail";
import Requisitioner from "../pages/user/master/Requisitioner";
import Offer from "../pages/user/Procurement/sections/Offer";
import Join from "../pages/user/Procurement/sections/Join";
import Open from "../pages/user/Procurement/sections/Open";
import Auction from "../pages/user/Auction";
import AuctionLive from "../pages/user/Auction/components/sections/AuctionLive";

export const userRouteSlug = {
  PANEL: "/panel",

  DASHBOARD: "/",
  COMPANY_PROFILE: "/profile",
  REQ_REQUISITION: "/req-requisition",
  PROCUREMENT: "/procurement",
  MASTER_VENDOR: "/vendor",
  MASTER_ADMIN: "/master/admin",
  MASTER_MATERIAL: "/master/material",
  MASTER_REQUISITIONER: "/master/requisitioner",
  AUCTION: "/auction",

  ACCOUNT: "/account",
  ACTIVITY: "/activity",
  NOTIFICATION: "/notification",
};

const userRoutes = [
  {
    path: userRouteSlug.PANEL,
    element: <UsersTemplate />,
    children: [
      { path: userRouteSlug.PANEL + userRouteSlug.DASHBOARD, element: <Dashboard /> },
      {
        path: userRouteSlug.PANEL + userRouteSlug.COMPANY_PROFILE,
        children: [
          { index: true, element: <Navigate to={userRouteSlug.PANEL + userRouteSlug.COMPANY_PROFILE + "/general"} /> },
          { path: ":section", element: <CompanyProfile /> },
        ],
      },
      { path: userRouteSlug.PANEL + userRouteSlug.REQ_REQUISITION, element: <ReqRequisition /> },
      {
        path: userRouteSlug.PANEL + userRouteSlug.PROCUREMENT,
        children: [
          { index: true, element: <Procurement /> },
          { path: "offer", element: <Offer /> },
          { path: "join", element: <Join /> },
          { path: "open", element: <Open /> },
          { path: ":procurementNumber/detail/:section", element: <ProcurementDetail /> },
        ],
      },
      {
        path: userRouteSlug.PANEL + userRouteSlug.MASTER_VENDOR,
        children: [
          { index: true, element: <Vendor /> },
          { path: ":regCode", element: <VendorOverview /> },
          { path: ":regCode/activity", element: <Activity /> },
          { path: ":regCode/profile/:section", element: <CompanyProfile /> },
        ],
      },
      { path: userRouteSlug.PANEL + userRouteSlug.MASTER_ADMIN, element: <Admin /> },
      { path: userRouteSlug.PANEL + userRouteSlug.MASTER_MATERIAL, element: <Material /> },
      { path: userRouteSlug.PANEL + userRouteSlug.MASTER_REQUISITIONER, element: <Requisitioner /> },
      {
        path: userRouteSlug.PANEL + userRouteSlug.AUCTION,
        children: [{ path: ":auctionId/:section", element: <Auction /> }],
      },

      { path: userRouteSlug.PANEL + userRouteSlug.ACCOUNT, element: <Account /> },
      { path: userRouteSlug.PANEL + userRouteSlug.ACTIVITY, element: <Activity /> },
      { path: userRouteSlug.PANEL + userRouteSlug.NOTIFICATION, element: <Notification /> },
      { path: "*", element: <Page404 buttonRedirectTo={userRouteSlug.PANEL + userRouteSlug.DASHBOARD} /> },
    ],
  },
];

export default userRoutes;
