import {
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  FormControl,
  FormHelperText,
  FormLabel,
  Tfoot,
} from "@chakra-ui/react";

import { InputTextarea } from "primereact/inputtextarea";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { NegotiationContext } from "../Controller";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import numeral from "numeral";
import { BsArrowLeftCircle } from "react-icons/bs";
import { motion } from "framer-motion";
import FormDescription from "../../../../../../../../../components/inputForm/FormDescription";
import { useFieldArray, useForm } from "react-hook-form";
import InputNumber from "../../../../../../../../../components/inputForm/InputNumber";

const Row = ({ update, index, value, control, form }) => {
  const { watch } = form;
  const { t } = useTranslation();
  const { isView, notes, activeNegotiationBid } =
    useContext(NegotiationContext);
  const { userData } = useContext(AuthContext);

  value.vendor = userData?.username;
  value.step = activeNegotiationBid?.step
    ? activeNegotiationBid?.step
    : activeNegotiationBid?.procurementNegotiationOfferId;

  const watchMaterialsPrice = watch();

  useEffect(() => {
    update(index, value);
  }, [index, value]);

  const priceUpdate = notes
    ?.filter((dataNotes, iDataNotes) => dataNotes?.step === value?.step)
    .map((note) => note);

  const measureUnit =
    value?.reqRequisitionMaterial?.material?.measureUnit ||
    value?.requisitionMaterial?.material?.measureUnit;

  return (
    <>
      <Tr>
        <Td>
          {value?.reqRequisitionMaterial?.material?.description ||
            value?.requisitionMaterial?.material?.description}
        </Td>
        <Td>
          {value?.reqRequisitionMaterial?.material?.materialCode ||
            value?.requisitionMaterial?.material?.materialCode}
        </Td>
        <Td>
          {value?.reqRequisitionMaterial?.currency?.currency ||
            value?.requisitionMaterial?.currency?.currency}
        </Td>
        <Td>
          {numeral(value?.reqRequisitionMaterial?.quantity || value?.requisitionMaterial?.quantity).format("0,0")}{" "}
          {`${measureUnit?.unit}`}
        </Td>
        <Td>
          {isView ? (
            <InputNumber
              className={`w-5 text-sm `}
              placeholder="0,00"
              name={`price[${
                value?.reqRequisitionMaterial?.reqRequisitionMaterialId ||
                value?.requisitionMaterial?.reqRequisitionMaterialId
              }]`}
              value={value?.price}
              control={control}
            />
          ) : (
            <FormDescription>
              {priceUpdate?.map((data, i) =>
                data?.reqRequisitionCode ===
                  (value?.reqRequisitionMaterial?.reqRequisition
                    ?.reqRequisitionCode ||
                    value?.requisitionMaterial?.reqRequisition
                      ?.reqRequisitionCode) &&
                data?.materialCode ===
                  (value?.reqRequisitionMaterial?.material?.materialCode ||
                    value?.requisitionMaterial?.material?.materialCode)
                  ? numeral(parseInt(data?.price)).format("0,0.00")
                  : ""
              )}
            </FormDescription>
          )}
        </Td>
        <Td>
          <FormDescription>
            {!priceUpdate?.length
              ? numeral(
                  watchMaterialsPrice?.price?.[
                    value?.reqRequisitionMaterial?.reqRequisitionMaterialId ||
                      value?.requisitionMaterial?.reqRequisitionMaterialId
                  ]
                    ? (watchMaterialsPrice?.price?.[
                        value?.reqRequisitionMaterial?.reqRequisitionMaterialId
                      ] ||
                      watchMaterialsPrice?.price?.[
                        value?.requisitionMaterial?.reqRequisitionMaterialId
                      ]
                        ? watchMaterialsPrice?.price?.[
                            value?.reqRequisitionMaterial
                              ?.reqRequisitionMaterialId
                          ] ||
                          watchMaterialsPrice?.price?.[
                            value?.requisitionMaterial?.reqRequisitionMaterialId
                          ]
                        : value?.price) *
                        (value?.reqRequisitionMaterial?.quantity ||
                          value?.requisitionMaterial?.quantity)
                    : value?.price *
                        (value?.reqRequisitionMaterial?.quantity ||
                          value?.requisitionMaterial?.quantity)
                ).format("0,0.00")
              : priceUpdate?.map((data, i) =>
                  data?.reqRequisitionCode ===
                    (value?.reqRequisitionMaterial?.reqRequisition
                      ?.reqRequisitionCode ||
                      value?.requisitionMaterial?.reqRequisition
                        ?.reqRequisitionCode) &&
                  data?.materialCode ===
                    (value?.reqRequisitionMaterial?.material?.materialCode ||
                      value?.requisitionMaterial?.material?.materialCode)
                    ? numeral(
                        parseInt(data?.price) *
                          (value?.reqRequisitionMaterial?.quantity ||
                            value?.requisitionMaterial?.quantity)
                      ).format("0,0.00")
                    : ""
                )}
          </FormDescription>
        </Td>
      </Tr>
    </>
  );
};

const NegotiationBid = () => {
  const {
    totalDataNotes,
    limitNotes,
    activeNegotiationMaterialVendor,
    loadingNegotiationMaterialVendor,
    handleCreateNegotiationBid,
    loadingBidNegotiation,
    isView,
    updateNegotiationBid,
    activeNegotiationBid,
    totArray,
    tableFormBid,
    notes,
  } = useContext(NegotiationContext);
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  const [totalPrice, setTotalPrice] = useState();

  const watchTableFormBid = tableFormBid.watch();

  const form = useForm();
  const { control, handleSubmit, errors, register, watch } = form;
  const { fields, append, update } = useFieldArray({
    control,
    name: "data",
    defaultValues: {
      data: [],
    },
  });

  const watchMaterialPrice = watch();

  useEffect(() => {
    const step = watchMaterialPrice?.data?.map((data) => data);

    let pricesUpdate = step?.map((dataStep, iDataStep) =>
      notes?.find(
        (dataNotes) =>
          dataNotes?.reqRequisitionMaterial?.reqRequisitionMaterialId ===
            dataStep?.reqRequisitionMaterialId &&
          dataNotes?.step === dataStep?.step
      )
    );

    if (watchMaterialPrice?.price) {
      const s1 = watchMaterialPrice?.data
        ?.map((d) => {
          return watchMaterialPrice?.price?.[d?.reqRequisitionMaterialId]
            ? (watchMaterialPrice?.price?.[d?.reqRequisitionMaterialId] *
                (d?.requisitionMaterial?.quantity ||
                  d?.reqRequisitionMaterial?.quantity)) /
                (d?.requisitionMaterial?.valuation ||
                  d?.reqRequisitionMaterial?.valuation)
            : d?.subPrice;
        })
        ?.reduce((a, b) => a + b, 0);
      setTotalPrice(s1);
    } else {
      const s1 = pricesUpdate
        ?.map((data, i) => {
          return (
            (parseInt(data?.price) *
              parseInt(data?.reqRequisitionMaterial?.quantity)) /
            parseInt(data?.reqRequisitionMaterial?.valuation)
          );
        })
        .reduce((a, b) => a + b, 0);
      setTotalPrice(s1);
    }
  }, [watchMaterialPrice, activeNegotiationMaterialVendor]);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
      className="bg-white rounded-md p-6"
    >
      <div className="w-full bg-white rounded-md text-sm col-span-3">
        <form onSubmit={handleSubmit((data) => updateNegotiationBid(data))}>
          <div className="md:col-span-2 flex justify-between items-center gap-2">
            <button onClick={() => handleCreateNegotiationBid(false)}>
              <BsArrowLeftCircle className="text-xl text-gray-600" />
            </button>
            {isView && (
              <Button
                size="sm"
                colorScheme="teal"
                isLoading={loadingBidNegotiation}
                type="submit"
              >
                {t("LABEL.SAVE")}
              </Button>
            )}
          </div>
          {/* Notes */}
          {isView ? (
            <FormControl isRequired={isView}>
              <FormLabel
                className={`uppercase font-semibold text-xs pt-6 ${
                  !isView && "text-red-500"
                }`}
              >
                {t("LABEL.NOTES")}
              </FormLabel>

              <InputTextarea
                className={`w-full text-sm ${
                  errors?.notes && "border-2 border-red-400 shadow-none"
                }`}
                placeholder={t("LABEL.NOTES")}
                {...register("notes", {
                  required: t("INVALID.REQUIRED", { field: t("LABEL.NOTES") }),
                })}
                rows={5}
                cols={30}
              />
              {errors?.notes && (
                <FormHelperText className="text-red-400 text-xs">
                  {errors?.notes?.message}
                </FormHelperText>
              )}
            </FormControl>
          ) : (
            <FormControl isRequired={isView}>
              <FormLabel
                className={`uppercase font-semibold text-xs pt-6 ${"text-red-500"}`}
              >
                {t("LABEL.NOTES")}
              </FormLabel>

              <FormDescription>{activeNegotiationBid?.notes}</FormDescription>
            </FormControl>
          )}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th className="text-red-500">{t("LABEL.VENDOR")}</Th>
                <Th className="text-red-500">{t("LABEL.MATERIAL_CODE")}</Th>
                <Th className="text-red-500">{t("LABEL.NOTES")}</Th>
                <Th className="text-red-500">{t("LABEL.QUANTITY")}</Th>
                <Th className="text-red-500">{t("LABEL.PRICE")}</Th>
                <Th className="text-red-500">
                  {t("LABEL.PROCUREMENT_NEGOTIATION_TOTAL_PRICE")}
                </Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {userData?.type === "user" ? (
                loadingNegotiationMaterialVendor ? (
                  <Tr>
                    <Td colSpan={5}>
                      <div className="w-full h-full flex justify-center items-center">
                        <Spinner color="red.500" />
                      </div>
                    </Td>
                  </Tr>
                ) : !totArray ? (
                  <Tr>
                    <Td colSpan={5} className="text-center">
                      <p className="text-sm text-gray-500">
                        {t("TEXT.DATA_NOT_FOUND")}
                      </p>
                    </Td>
                  </Tr>
                ) : (
                  activeNegotiationMaterialVendor?.map((field, index) => (
                    <Row
                      key={field.id}
                      control={control}
                      update={update}
                      index={index}
                      value={field}
                      form={form}
                    />
                  ))
                )
              ) : loadingNegotiationMaterialVendor ? (
                <Tr>
                  <Td colSpan={5}>
                    <div className="w-full h-full flex justify-center items-center">
                      <Spinner color="red.500" />
                    </div>
                  </Td>
                </Tr>
              ) : !activeNegotiationMaterialVendor ? (
                <Tr>
                  <Td colSpan={5} className="text-center">
                    <p className="text-sm text-gray-500">
                      {t("TEXT.DATA_NOT_FOUND")}
                    </p>
                  </Td>
                </Tr>
              ) : (
                activeNegotiationMaterialVendor?.map((field, index) => (
                  <Row
                    key={field.id}
                    control={control}
                    update={update}
                    index={index}
                    value={field}
                    form={form}
                  />
                ))
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th></Th>
                <Th></Th>
                <Th></Th>
                <Th>
                  <FormDescription>Total</FormDescription>
                </Th>
                <Th>
                  <FormDescription>
                    {numeral(totalPrice).format("0,0.00")}
                  </FormDescription>
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </form>

        <div className="mt-3 flex justify-end">
          <Pagination
            next
            prev
            ellipsis
            boundaryLinks
            maxButtons={5}
            total={totalDataNotes}
            limit={limitNotes}
            activePage={watchTableFormBid?.page}
            onChangePage={(page) => tableFormBid.setValue("page", page)}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default NegotiationBid;
