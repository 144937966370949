import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EquipmentContext } from "../Controller";
import { IoIosArrowForward } from "react-icons/io";
import _ from "lodash";
import { motion } from "framer-motion";
import { BiPencil, BiPlus } from "react-icons/bi";
import PrivateComponent from "../../../../../../../components/PrivateComponent";
import { Spinner } from "@chakra-ui/react";
import SubmitApprovalWarning from "../../../SubmitApprovalWarning";

const Equipment = ({ data }) => {
  const { handleDetailMenu } = useContext(EquipmentContext);
  const { t } = useTranslation();

  return (
    <button
      className={`text-left p-4 flex justify-between items-center bg-white text-gray-60`}
      style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
      onClick={() => handleDetailMenu(true, data)}
    >
      <div className={`${data?.isDelete && "opacity-60"}`}>
        <p className={`text-sm font-semibold text-red-500`}>
          {t(data?.equipmentType?.labelI18n || data?.equipmentType?.label)}
          {data?.isDelete && ` (${t("LABEL.DELETED")})`}
        </p>
        <h2 className="font-semibold text-lg">{data?.description}</h2>
        {(!data?.isApproved || data?.isDelete) && (
          <div className={`flex items-center gap-1 mt-2 text-xs text-blue-500`}>
            <BiPencil />
            <p>{t("TEXT.DATA_NEED_APPROVAL")}</p>
          </div>
        )}
      </div>
      <div>
        <IoIosArrowForward />
      </div>
    </button>
  );
};

const EquipmentList = () => {
  const { equipments, loadingEquipments, handleCreate } = useContext(EquipmentContext);
  const { t } = useTranslation();

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="grid gap-4">
      <SubmitApprovalWarning menu="Equipment" />

      {loadingEquipments ? (
        <div
          className={`h-20 md:col-span-2 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
          style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {!equipments.length ? (
            <PrivateComponent accessName="CREATE_COMPANY_PROFILE" doHasAccess={false}>
              <div
                className="p-3 bg-white bg-opacity-40 text-center text-sm text-gray-500"
                style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
              >
                {t("TEXT.DATA_NOT_FOUND")}
              </div>
            </PrivateComponent>
          ) : (
            equipments.map((equipment, iEquipment) => <Equipment key={iEquipment} data={equipment} />)
          )}
          <PrivateComponent accessName="CREATE_COMPANY_PROFILE">
            <button
              className={`h-12 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
              style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
              onClick={() => handleCreate(true)}
            >
              <BiPlus />
              <p>{t("LABEL.ADD", { field: t("LABEL.EQUIPMENT") })}</p>
            </button>
          </PrivateComponent>
        </>
      )}
    </motion.div>
  );
};

export default EquipmentList;
