import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Datepicker from "../../../../../../../../../../components/inputForm/Datepicker";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";
import { AuthContext } from "../../../../../../../../../../controllers/auth/AuthController";
import PrivateComponent from "../../../../../../../../../../components/PrivateComponent";

const RegistrationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const { form, schedules, loadingCreate, createProcSchedule, handleCreate } =
    useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Registration */}
      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4`}>
          {t("LABEL.PROCUREMENT_REGISTER")}
        </FormLabel>

        <div
          className={`md:col-span-2 grid ${
            userData?.type !== "user" ? "md:grid-cols-3" : "md:grid-cols-2"
          }  gap-6 items-center`}
        >
          {/* Start Registration */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_START", {
                field: t("LABEL.PROCUREMENT_REGISTER"),
              })}
            </FormLabel>
            {isEdit ? (
              <Datepicker
                format="yyyy-MM-dd HH:mm:ss"
                disabled={
                  !schedules[0]?.registrationStart
                    ? false
                    : moment().unix() >
                      moment(schedules[0]?.registrationStart).unix()
                }
                name="registrationStart"
                placeholder={t("LABEL.PROCUREMENT_START", {
                  field: t("LABEL.PROCUREMENT_REGISTER"),
                })}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_START", {
                        field: t("LABEL.PROCUREMENT_REGISTER"),
                      }),
                    }),
                  },
                }}
                isError={!!errors?.registrationStart}
              />
            ) : (
              <FormDescription>
                {schedules[0]?.registrationStart &&
                  moment(new Date(schedules[0]?.registrationStart)).format(
                    "LLL"
                  )}
              </FormDescription>
            )}
            {errors?.registrationStart && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.registrationStart?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* End Registration */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_END", {
                field: t("LABEL.PROCUREMENT_REGISTER"),
              })}
            </FormLabel>
            {isEdit ? (
              <Datepicker
                format="yyyy-MM-dd HH:mm:ss"
                name="registrationEnd"
                disabled={
                  !schedules[0]?.registrationEnd
                    ? false
                    : moment().unix() >
                      moment(schedules[0]?.registrationEnd).unix()
                }
                placeholder={t("LABEL.PROCUREMENT_END", {
                  field: t("LABEL.PROCUREMENT_REGISTER"),
                })}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_END", {
                        field: t("LABEL.PROCUREMENT_REGISTER"),
                      }),
                    }),
                  },
                }}
                isError={!!errors?.registrationEnd}
              />
            ) : (
              <FormDescription>
                {schedules[0]?.registrationEnd &&
                  moment(new Date(schedules[0]?.registrationEnd)).format("LLL")}
              </FormDescription>
            )}
            {errors?.registrationEnd && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.registrationEnd?.message}
              </FormHelperText>
            )}
          </FormControl>
          {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
            isEdit ? (
              <PrivateComponent accessName="BUYER_EDIT">
                <Button
                  size="sm"
                  colorScheme="teal"
                  disabled={
                    !schedules[0]?.registrationEnd ||
                    !schedules[0]?.registrationStart
                      ? false
                      : moment().unix() >
                          moment(schedules[0]?.registrationEnd).unix() &&
                        moment().unix() >
                          moment(schedules[0]?.registrationStart).unix()
                  }
                  isLoading={loadingCreate}
                  onClick={form.handleSubmit(createProcSchedule)}
                >
                  {t("LABEL.SAVE")}
                </Button>
              </PrivateComponent>
            ) : (
              <PrivateComponent accessName="BUYER_EDIT">
                <Button
                  size="sm"
                  colorScheme="gray"
                  isLoading={loadingCreate}
                  onClick={() => handleCreate(true, schedules)}
                >
                  {t("LABEL.EDIT", { field: t("LABEL.PROCUREMENT_REGISTER") })}
                </Button>
              </PrivateComponent>
            )
          ) : (
            ""
          )}
        </div>
      </FormControl>
    </>
  );
};

export default RegistrationForm;
