import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiError } from "react-icons/bi";
import PrivateComponent from "../../../../components/PrivateComponent";
import { CompanyProfileContext } from "../Controller";

const SubmitApprovalWarning = ({ menu, className = "" }) => {
  const { submitApprovalFails } = useContext(CompanyProfileContext);
  const { t } = useTranslation();

  const fail = submitApprovalFails.find((fail) => fail?.menu === menu);
  return (
    <>
      {fail && (
        <PrivateComponent accessName="CREATE_COMPANY_PROFILE">
          <div
            className={`p-4 bg-yellow-300 rounded-md font-medium flex items-center gap-2 ${className}`}
            style={{ boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
          >
            <BiError className="text-lg" />
            {fail?.actionMessageI18n ? t(fail?.actionMessageI18n) : t("LABEL.ADD", { field: t(fail?.menuI18n) })}
          </div>
        </PrivateComponent>
      )}
    </>
  );
};

export default SubmitApprovalWarning;
