import { createContext, useContext, useEffect, useState } from 'react';
import { HelperContext } from '../../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../../../Controller';
import { ProcurementOfferEvaluationContext } from '../../Controller';

export const AdministratifEvaluationContext = createContext();
const { Provider } = AdministratifEvaluationContext;

const AdministratifEvaluationController = ({ children }) => {
	const { procurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);
	const { administratifOffers, handleDetailAdministratifIdx } = useContext(ProcurementOfferEvaluationContext);
	const [offer, setOffer] = useState(null);
	const [requirements, setRequirements] = useState(null);
	const [loadingRequirements, setLoadingRequirements] = useState(false);

	useEffect(async () => {
		setRequirements(null);
		if (!procurement) return;
		let mounted = true;
		setLoadingRequirements(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-requirement/${procurement.procurementNumber}`,
		});
		if (!mounted) return;
		setLoadingRequirements(false);

		if (!response?.procurementRequirement) {
			// TODO: Error?
			showToast('Error fetching requriements', { success: false });
			return;
		}
		const requirements = response.procurementRequirement.filter((req) => req.requirementProcurementCover === 'ADMINISTRATIF & TEKNIKAL');
		setRequirements(requirements);
	}, [procurement]);

	useEffect(async () => {
		if (!administratifOffers || handleDetailAdministratifIdx === null) {
			setOffer(null);
      return;
		}
		setOffer(administratifOffers[handleDetailAdministratifIdx]);
	}, [administratifOffers, handleDetailAdministratifIdx]);

	return <Provider value={{ offer, setOffer, requirements, setRequirements, loadingRequirements, setLoadingRequirements }}>{children}</Provider>;
};

export default AdministratifEvaluationController;
