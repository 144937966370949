import React, { useContext } from "react";
import ActivationController, { ActivationContext } from "./Controller";
import BaseCard from "../../templates/public/BaseCard";
import CompletePage from "./components/CompletePage";
import NewPassword from "./components/NewPassword";

const Activation = () => {
  const { currentPage } = useContext(ActivationContext);

  return <BaseCard>{currentPage === "newPassword" ? <NewPassword /> : <CompletePage />}</BaseCard>;
};

export default () => (
  <ActivationController>
    <Activation />
  </ActivationController>
);
