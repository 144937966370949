import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { ProcurementOfferEvaluationContext } from './Controller';
import ProcurementOfferEvaluationController from './Controller';
import Navbar from './components/Navbar';
import EvaluationOverviewPage from './components/OverviewPage';
import AdministratifEvaluationPage from './components/administratif';
import CommercialEvaluationPage from './components/commercial';
import EvaluationPage from './components/evaluation';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../utils/offerPhases.util';
import { useTranslation } from 'react-i18next';
import AnnouncementPanel from '../../../../../../../../../components/AnnouncementPanel';

const ProcurementOfferEvaluation = () => {
	const { t } = useTranslation();
	const { activePageIdx, is2Cover, offerEvaluationPhase } = useContext(ProcurementOfferEvaluationContext);

	return (
		<motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
			{(offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA || offerEvaluationPhase === OFFER_2_COVER_PHASES.POST) && (
				<>
					{offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA && <AnnouncementPanel bg='bg-white' className='text-gray-700' title={t('TEXT.PRA_EVALUATION_WARNING')} />}
					{offerEvaluationPhase === OFFER_2_COVER_PHASES.POST && <AnnouncementPanel bg='bg-white' className='text-gray-700' title={t('TEXT.POST_EVALUATION_WARNING')} />}
				</>
			)}

			<div className="w-full bg-white rounded-md text-sm p-4 col-span-3 mb-8">
				<Navbar />
				<div>
					{activePageIdx === 0 && <EvaluationOverviewPage />}
					{activePageIdx === 1 && <AdministratifEvaluationPage />}
					{activePageIdx === 2 && <CommercialEvaluationPage />}

					{activePageIdx === 3 && <EvaluationPage evaluationType={!is2Cover ? 'COM' : null} />}
					{!is2Cover && activePageIdx === 4 && <EvaluationPage evaluationType="ADM" />}
				</div>
			</div>
		</motion.div>
	);
};

export default () => (
	<ProcurementOfferEvaluationController>
		<ProcurementOfferEvaluation />
	</ProcurementOfferEvaluationController>
);
