import { useTranslation } from "react-i18next";
import AccountForm from "./components/AccountForm";
import AccountOptions from "./components/AccountOptions";
import AccountOverview from "./components/AccountOverview";
import AccountController from "./Controller";

const Account = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.ACCOUNT")}</h2>

      <div className="grid md:grid-cols-2 gap-4">
        <AccountOverview />
        <AccountForm />
        <AccountOptions />
      </div>
    </div>
  );
};

export default () => (
  <AccountController>
    <Account />
  </AccountController>
);
