import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBullhorn, FaInfoCircle, FaClipboardList } from 'react-icons/fa';
import TitleBar from '../../../../../../../../../components/TitleBar';
import { ProcurementOfferContext } from '../Controller';
import SchedulePanelUser from './SchedulePanelUser';

const OfferOverviewPage = () => {
	const { t } = useTranslation();
	const { is2Cover, loadingSchedule, schedule } = useContext(ProcurementOfferContext);

	return (
		<div>
			<div>
				<TitleBar icon={FaClipboardList} title={t('LABEL.PROCUREMENT_SCHEDULE')} />
				{is2Cover ? (
					<>
						<SchedulePanelUser className="" loading={loadingSchedule} title="" start={schedule?.quotationStart} end={schedule?.quotationEnd} />
					</>
				) : (
					<div className="flex flex-col gap-4">
						<SchedulePanelUser className="" loading={loadingSchedule} title={t('LABEL.TECHNICAL')} start={schedule?.technicalQuotationStart} end={schedule?.technicalQuotationEnd} />
						<SchedulePanelUser className="" loading={loadingSchedule} title={t('LABEL.COMMERCIAL')} start={schedule?.commercialQuotationStart} end={schedule?.commercialQuotationEnd} />
					</div>
				)}
			</div>
			<div className="mt-3">
				<TitleBar className="mt-4" icon={FaBullhorn} title={t('LABEL.CAUTION')} />
				<div>
					<p>
						{t('TEXT.THIS_PROCUREMENT_IS')} {t('LABEL.PROCUREMENT_BID_TYPE', { type: is2Cover ? t('LABEL.BID_2_COVER') : t('LABEL.BID_2_STEP') })}. {t('TEXT.PROVIDER_SHOULD_DO')}
					</p>
					<p>&emsp;&emsp;1. {t('LABEL.TECHNICAL')}</p>
					<p>&emsp;&emsp;2. {t('LABEL.COMMERCIAL')}</p>
					<p>{t('TEXT.PROVIDER_SHOULD_CORRECT')}</p>
				</div>
			</div>
			<div className="mt-7">
				<TitleBar icon={FaInfoCircle} title={t('LABEL.IMPORTANT')} />
				<div>
					<p>{t('TEXT.CAUTION_SUBMITTED'
          )}</p>
					<p>&emsp;&emsp;1. {t('LABEL.TECHNICAL')}</p>
					<p>&emsp;&emsp;2. {t('LABEL.COMMERCIAL')}</p>
					<p>{t("LABEL.LAST_CAUTION")}</p>
				</div>
			</div>
		</div>
	);
};

export default OfferOverviewPage;
