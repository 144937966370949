import { ScaleFade } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCaretLeftCircle } from 'react-icons/bi';
import { ProcurementOfferEvaluationContext } from '../../Controller';
import OfferTable from '../OfferTable';
import OfferFormEvaluation from './components/OfferForm';
import OfferItemEvaluation from './components/OfferItem';
import OfferRequirementEvaluation from './components/OfferRequirement';
import CommercialEvaluationController, { CommercialEvaluationContext } from './Controller';

const CommercialEvaluationPage = () => {
	const { t } = useTranslation();
	const { setHandleDetailCommercialIdx } = useContext(ProcurementOfferEvaluationContext);
	const { offer } = useContext(CommercialEvaluationContext);

	return (
		<div>
			{!offer && <OfferTable offerType="COM" />}
			{offer && (
				<ScaleFade in={offer}>
					<div className="flex font-semibold items-center mb-3 gap-3">
						<BiCaretLeftCircle
							className="text-2xl cursor-pointer text-gray-500"
							onClick={() => {
								setHandleDetailCommercialIdx(null);
							}}
						/>
						<div className="text-lg text-gray-500">{t('LABEL.OFFER_DETAIL')}</div>
					</div>
					<OfferFormEvaluation />
					<OfferRequirementEvaluation />
					<OfferItemEvaluation />
				</ScaleFade>
			)}
		</div>
	);
};

export default () => {
	return (
		<CommercialEvaluationController>
			<CommercialEvaluationPage />
		</CommercialEvaluationController>
	);
};
