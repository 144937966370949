import React, { useContext, useEffect } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FinancialReportContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import SelectButton from "../../../../../../../components/inputForm/SelectButton";
import InputNumber from "../../../../../../../components/inputForm/InputNumber";
import numeral from "numeral";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeFinancialReport, form, currencies } = useContext(FinancialReportContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") }) }),
    },
  });

  const isAudited = form.watch("isAudited");
  useEffect(() => {
    !isAudited && form.setValue("publicAccountant", undefined);
  }, [isAudited]);

  const currency = form.watch("currency");
  const activeCurrency = currencies.find((c) => c?.currency === currency);

  const currentAsset = form.watch("currentAsset");
  const fixedAsset = form.watch("fixedAsset");
  const otherAsset = form.watch("otherAsset");
  useEffect(() => {
    form.setValue("totalAsset", currentAsset + fixedAsset + otherAsset);
  }, [currentAsset, fixedAsset, otherAsset]);

  const currentDebt = form.watch("currentDebt");
  const otherDebt = form.watch("otherDebt");
  useEffect(() => {
    form.setValue("totalDebt", currentDebt + otherDebt);
  }, [currentDebt, otherDebt]);

  const paidUpCapital = form.watch("paidUpCapital");
  const retainedEarning = form.watch("retainedEarning");
  useEffect(() => {
    form.setValue("totalCapital", paidUpCapital + retainedEarning);
  }, [paidUpCapital, retainedEarning]);

  return (
    <>
      {/* Report Date */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.REPORT_DATE")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="reportDate"
            placeholder={t("LABEL.REPORT_DATE")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.REPORT_DATE") }) },
            }}
            isError={!!errors?.reportDate}
          />
        ) : (
          <FormDescription>{moment(new Date(activeFinancialReport?.reportDate)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.reportDate && (
          <FormHelperText className="text-red-400 text-xs">{errors?.reportDate?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Currency */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CURRENCY")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CURRENCY") }) },
            }}
            name="currency"
            options={currencies.map((currency) => ({
              label: `${currency?.symbol} - ${currency?.description}`,
              value: currency?.currency,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CURRENCY")}`}
            isError={!!errors?.currency}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{activeFinancialReport?.currency?.description}</FormDescription>
        )}
        {errors?.currency && (
          <FormHelperText className="text-red-400 text-xs">{errors?.currency?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Is Audited */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.FINANCIAL_REPORT_AUDIT")}
        </FormLabel>
        {isEdit ? (
          <SelectButton
            className="w-full grid grid-cols-2"
            control={control}
            name="isAudited"
            value={form?.getValues("isAudited")}
            options={[
              { label: t("LABEL.YES"), value: true },
              { label: t("LABEL.NO"), value: false },
            ]}
          />
        ) : (
          <FormDescription>{activeFinancialReport?.isAudited ? t("LABEL.YES") : t("LABEL.NO")}</FormDescription>
        )}
        {errors?.isAudited && (
          <FormHelperText className="text-red-400 text-xs">{errors?.isAudited?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Public Accountant */}
      <FormControl isRequired={isAudited}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PUBLIC_ACCOUNTANT")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.publicAccountant && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.PUBLIC_ACCOUNTANT")}
            {...register("publicAccountant", {
              required: {
                value: isAudited,
                message: t("INVALID.REQUIRED", { field: t("LABEL.PUBLIC_ACCOUNTANT") }),
              },
            })}
            disabled={!isAudited}
          />
        ) : (
          <FormDescription>{activeFinancialReport?.publicAccountant}</FormDescription>
        )}
        {errors?.publicAccountant && (
          <FormHelperText className="text-red-400 text-xs">{errors?.publicAccountant?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Current Asset */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CURRENT_ASSET")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="currentAsset"
              control={control}
              placeholder={t("LABEL.CURRENT_ASSET")}
              isError={!!errors?.currentAsset}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.currentAsset).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.currentAsset && (
          <FormHelperText className="text-red-400 text-xs">{errors?.currentAsset?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Fixed Asset */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.FIXED_ASSET")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="fixedAsset"
              control={control}
              placeholder={t("LABEL.FIXED_ASSET")}
              isError={!!errors?.fixedAsset}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.fixedAsset).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.fixedAsset && (
          <FormHelperText className="text-red-400 text-xs">{errors?.fixedAsset?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Other Asset */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.OTHER_ASSET")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="otherAsset"
              control={control}
              placeholder={t("LABEL.OTHER_ASSET")}
              isError={!!errors?.otherAsset}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.otherAsset).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.otherAsset && (
          <FormHelperText className="text-red-400 text-xs">{errors?.otherAsset?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Total Asset */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.TOTAL_ASSET")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="totalAsset"
              control={control}
              placeholder={t("LABEL.TOTAL_ASSET")}
              disabled
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.totalAsset).format("0,0.00")}
          </FormDescription>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Current Debt */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CURRENT_DEBT")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="currentDebt"
              control={control}
              placeholder={t("LABEL.CURRENT_DEBT")}
              isError={!!errors?.currentDebt}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.currentDebt).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.currentDebt && (
          <FormHelperText className="text-red-400 text-xs">{errors?.currentDebt?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Other Asset */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.OTHER_DEBT")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="otherDebt"
              control={control}
              placeholder={t("LABEL.OTHER_DEBT")}
              isError={!!errors?.otherDebt}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.otherDebt).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.otherDebt && (
          <FormHelperText className="text-red-400 text-xs">{errors?.otherDebt?.message}</FormHelperText>
        )}
      </FormControl>

      <div />

      {/* Total Debt */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.TOTAL_DEBT")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="totalDebt"
              control={control}
              placeholder={t("LABEL.TOTAL_DEBT")}
              disabled
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.totalDebt).format("0,0.00")}
          </FormDescription>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Paid Up Capital */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PAID_UP_CAPITAL")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="paidUpCapital"
              control={control}
              placeholder={t("LABEL.PAID_UP_CAPITAL")}
              isError={!!errors?.currentDebt}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.paidUpCapital).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.paidUpCapital && (
          <FormHelperText className="text-red-400 text-xs">{errors?.paidUpCapital?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Retained Earning */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.RETAINED_EARNING")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="retainedEarning"
              control={control}
              placeholder={t("LABEL.RETAINED_EARNING")}
              isError={!!errors?.retainedEarning}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.retainedEarning).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.retainedEarning && (
          <FormHelperText className="text-red-400 text-xs">{errors?.retainedEarning?.message}</FormHelperText>
        )}
      </FormControl>

      <div />

      {/* Total Capital */}
      <FormControl>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.TOTAL_CAPITAL")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="totalCapital"
              control={control}
              placeholder={t("LABEL.TOTAL_CAPITAL")}
              disabled
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.totalCapital).format("0,0.00")}
          </FormDescription>
        )}
      </FormControl>

      <hr className="md:col-span-2" />

      {/* Annual Income */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ANNUAL_INCOME")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <span className={`p-inputgroup-addon`}>{activeCurrency?.symbol}</span>
            <InputNumber
              onFocus={(e) => e.target.select()}
              name="annualIncome"
              control={control}
              placeholder={t("LABEL.ANNUAL_INCOME")}
              isError={!!errors?.annualIncome}
            />
          </div>
        ) : (
          <FormDescription>
            {activeFinancialReport?.currency?.symbol} {numeral(activeFinancialReport?.annualIncome).format("0,0.00")}
          </FormDescription>
        )}
        {errors?.annualIncome && (
          <FormHelperText className="text-red-400 text-xs">{errors?.annualIncome?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Financial Report Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.FINANCIAL_REPORT") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.FINANCIAL_REPORT"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeFinancialReport?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
