import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { RegisterContext } from "../../../Controller";

export const DocumentContext = createContext();

const DocumentController = ({ children }) => {
  const { documentForm, companyProfileForm } = useContext(RegisterContext);

  // -------------------------------
  // Validation
  // -------------------------------

  // TaxNumber Availability
  const taxNumber = documentForm.watch("taxNumber");
  const country = companyProfileForm.watch("country");
  const [isTaxNumberAvailable, setTaxNumberAvailable] = useState();
  const [loadingTaxNumberAvailable, setLoadingTaxNumberAvailable] = useState(false);
  const [taxNumberAvailableTimeout, setTaxNumberAvailableTimeout] = useState();
  useEffect(() => {
    let mounted = true;

    const checkTaxNumber = () => {
      setLoadingTaxNumberAvailable(true);

      axios
        .post("/company/check-company-tax-number", {
          taxNumber,
          country,
        })
        .then((res) => {
          const { available } = res.data;
          mounted && setTaxNumberAvailable(available);
        })
        .finally(() => {
          setLoadingTaxNumberAvailable(false);
          documentForm.trigger("taxNumber");
        });
    };

    clearTimeout(taxNumberAvailableTimeout);
    const newTimeout = setTimeout(() => {
      taxNumber && country ? checkTaxNumber() : setTaxNumberAvailable();
    }, 500);
    setTaxNumberAvailableTimeout(newTimeout);

    return () => {
      mounted = false;
    };
  }, [taxNumber, country]);

  return (
    <DocumentContext.Provider value={{ isTaxNumberAvailable, loadingTaxNumberAvailable }}>
      {children}
    </DocumentContext.Provider>
  );
};

export default DocumentController;
