import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Datepicker from "../../../../../../../../../../components/inputForm/Datepicker";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";
import { AuthContext } from "../../../../../../../../../../controllers/auth/AuthController";

const PrequalificationForm = ({ isEdit }) => {
  const { t } = useTranslation();
  const {
    prequalificationForm,
    schedules,
    loadingPrequalification,
    handleCreatePrequalification,
    createPrequalificationProcSchedule,
  } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const { userData } = useContext(AuthContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {procurement?.procurementSetting?.prequalification === true ? (
        <>
          {/* Prequalification */}
          <FormControl className="md:col-span-2">
            <FormLabel
              className={`uppercase font-bold text-sm text-red-500 mb-4`}
            >
              {t("LABEL.PREQUALIFICATION")}
            </FormLabel>

            <div
              className={`md:col-span-2 grid ${
                userData?.type !== "user" ? "md:grid-cols-3" : "md:grid-cols-2"
              } gap-6 items-center`}
            >
              {/* Start Prequalification */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_START", {
                    field: t("LABEL.PREQUALIFICATION"),
                  })}
                </FormLabel>
                {isEdit ? (
                  <Datepicker
                    format="yyyy-MM-dd HH:mm:ss"
                    name="prequalificationStart"
                    placeholder={t("LABEL.PROCUREMENT_START", {
                      field: t("LABEL.PREQUALIFICATION"),
                    })}
                    control={control}
                    controlProps={{
                      rules: {
                        required: t("INVALID.REQUIRED", {
                          field: t("LABEL.PROCUREMENT_START", {
                            field: t("LABEL.PREQUALIFICATION"),
                          }),
                        }),
                      },
                    }}
                    isError={!!errors?.prequalificationStart}
                  />
                ) : (
                  <FormDescription>
                    {schedules[0]?.prequalificationStart &&
                      moment(
                        new Date(schedules[0]?.prequalificationStart)
                      ).format("LLL")}
                  </FormDescription>
                )}
                {errors?.prequalificationStart && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.prequalificationStart?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* End Prequalification */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_END", {
                    field: t("LABEL.PREQUALIFICATION"),
                  })}
                </FormLabel>
                {isEdit ? (
                  <Datepicker
                    format="yyyy-MM-dd HH:mm:ss"
                    name="prequalificationEnd"
                    placeholder={t("LABEL.PROCUREMENT_END", {
                      field: t("LABEL.PREQUALIFICATION"),
                    })}
                    control={control}
                    controlProps={{
                      rules: {
                        required: t("INVALID.REQUIRED", {
                          field: t("LABEL.PROCUREMENT_END", {
                            field: t("LABEL.PREQUALIFICATION"),
                          }),
                        }),
                      },
                    }}
                    isError={!!errors?.prequalificationEnd}
                  />
                ) : (
                  <FormDescription>
                    {schedules[0]?.prequalificationEnd &&
                      moment(
                        new Date(schedules[0]?.prequalificationEnd)
                      ).format("LLL")}
                  </FormDescription>
                )}
                {errors?.prequalificationEnd && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.prequalificationEnd?.message}
                  </FormHelperText>
                )}
              </FormControl>
              {userData?.type !== "user" && !procurement?.procurementWinnerProposal?.isWinner ? (
                isEdit ? (
                  <Button
                    size="sm"
                    colorScheme="teal"
                    isLoading={loadingPrequalification}
                    onClick={prequalificationForm.handleSubmit(
                      createPrequalificationProcSchedule
                    )}
                  >
                    {t("LABEL.SAVE")}
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    colorScheme="gray"
                    isLoading={loadingPrequalification}
                    onClick={() =>
                      handleCreatePrequalification(true, schedules)
                    }
                    disabled={!schedules[0]?.registrationStart ? true : false}
                  >
                    {t("LABEL.EDIT", { field: t("LABEL.PREQUALIFICATION") })}
                  </Button>
                )
              ) : (
                ""
              )}
            </div>
          </FormControl>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default PrequalificationForm;
