import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AdminApproval from "./components/AdminApproval";
import ProfileDropdown from "./components/ProfileDropdown";
import CompanyProfileController, { CompanyProfileContext } from "./Controller";
import CompanyOverview from "../../../components/CompanyOverview";
import SubmitApplicantApproval from "../../../components/ApplicantFirstStep/SubmitApplicantApproval";
import PrivateComponent from "../../../components/PrivateComponent";
import InfoNeedApproval from "../../../components/VendorReapproval/InfoNeedApproval";
import { BiCaretLeftCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { userRouteSlug } from "../../../routes/userRoutes";
import { AuthContext } from "../../../controllers/auth/AuthController";

const CompanyProfile = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const { activeSection, companyApprovalInfo, loadingCompanyOverview, companyOverview, submitApprovalFails, regCode } =
    useContext(CompanyProfileContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <div className="flex items-center gap-3">
        {userData?.type !== "user" && (
          <Link to={userRouteSlug.PANEL + `/vendor/${regCode}`}>
            <BiCaretLeftCircle className="text-xl text-gray-500 hover:text-gray-700" />
          </Link>
        )}
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.PROFILE")}</h2>
      </div>

      <div className="grid md:grid-cols-4 gap-8 gap-y-6">
        {/* Admin Approval */}
        {companyApprovalInfo && <AdminApproval />}

        {/* Applicant First Step */}
        <PrivateComponent accessName="APPLICANT_FIRST_STEPS">
          {!submitApprovalFails?.length && (
            <div className="md:col-span-4">
              <SubmitApplicantApproval />
            </div>
          )}
        </PrivateComponent>

        {/* Company Overview */}
        <div className="md:col-span-4 flex flex-col gap-6">
          <InfoNeedApproval isFailsExist={!!submitApprovalFails?.length} />
          <CompanyOverview loadingCompanyOverview={loadingCompanyOverview} companyOverview={companyOverview} />
        </div>

        <div>
          <ProfileDropdown />
        </div>
        <div className="md:col-span-3 ">{activeSection && <activeSection.Component />}</div>
      </div>
    </div>
  );
};

export default () => (
  <CompanyProfileController>
    <CompanyProfile />
  </CompanyProfileController>
);
