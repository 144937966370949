import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ProcurementContext } from "../Controller";
import Select from "../../../../components/inputForm/Select";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import SelectButton from "../../../../components/inputForm/SelectButton";

const ProcurementSettingForm = () => {
  const { t } = useTranslation();
  const { form, winnerEvaluationMethods } = useContext(ProcurementContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const watchProcurementType = form.watch('procurementType');
  
  useEffect(() => {
    if (watchProcurementType === "DIRECT APPOINTMENT") {
      return form.setValue('procurementSetting.eAuction', false);
    }
  }, [watchProcurementType]);

  useEffect(() => {
    if (watchProcurementType === "GENERAL SELECTION") {
      return form.setValue('procurementSetting.prequalification', true);
    } 
    return form.setValue('procurementSetting.prequalification', false);
  }, [watchProcurementType]);
  

  return (
    <>
      <hr className="md:col-span-2" />

      <FormControl className="md:col-span-2">
        <FormLabel className={`uppercase font-bold text-sm text-red-500 mb-4`}>
          {t("LABEL.PROCUREMENT_SETTING")}
        </FormLabel>

        <div className="md:col-span-2 grid md:grid-cols-4 gap-6">
          {/* Work Location */}
          <FormControl isRequired className="md:col-span-2">
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.WORK_LOCATION")}</FormLabel>
            <InputText
              className={`w-full text-sm ${
                errors?.procurementSetting?.workLocation && "border-2 border-red-400 shadow-none"
              }`}
              placeholder={t("LABEL.WORK_LOCATION")}
              {...register("procurementSetting.workLocation", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.WORK_LOCATION") }),
              })}
            />
            {errors?.procurementSetting?.workLocation && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.workLocation?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Winner Evaluation Method */}
          <FormControl isRequired className="md:col-span-2">
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.WINNER_EVALUATION_METHOD")}</FormLabel>
            <Select
              filter
              control={control}
              controlProps={{
                rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.WINNER_EVALUATION_METHOD") }) },
              }}
              name="procurementSetting.winnerEvaluationMethod"
              options={winnerEvaluationMethods.map((winnerEvaluationMethod) => ({
                label: winnerEvaluationMethod?.description,
                value: winnerEvaluationMethod?.winnerEvaluationMethod,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.WINNER_EVALUATION_METHOD")}`}
              isError={!!errors?.procurementSetting?.winnerEvaluationMethod}
            />

            {errors?.procurementSetting?.winnerEvaluationMethod && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.winnerEvaluationMethod?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* eAuction */}
          <FormControl isRequired>
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("e-Auction")}</FormLabel>
            <SelectButton
              disabled={watchProcurementType === "DIRECT APPOINTMENT"}
              className="w-full grid grid-cols-2"
              control={control}
              name="procurementSetting.eAuction"
              value={form?.getValues("procurementSetting.eAuction")}
              options={[
                { label: t("LABEL.YES"), value: true },
                { label: t("LABEL.NO"), value: false },
              ]}
            />
            {errors?.procurementSetting?.eAuction && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.eAuction?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Prequalification */}
          <FormControl isRequired>
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.PREQUALIFICATION")}</FormLabel>
            <SelectButton
              disabled
              className="w-full grid grid-cols-2"
              control={control}
              name="procurementSetting.prequalification"
              value={form?.getValues("procurementSetting.prequalification")}
              options={[
                { label: t("LABEL.YES"), value: true },
                { label: t("LABEL.NO"), value: false },
              ]}
            />
            {errors?.procurementSetting?.prequalification && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.prequalification?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Bid Bond */}
          <FormControl isRequired>
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("Bid Bond")}</FormLabel>
            <SelectButton
              className="w-full grid grid-cols-2"
              control={control}
              name="procurementSetting.bidBond"
              value={form?.getValues("procurementSetting.bidBond")}
              options={[
                { label: t("LABEL.YES"), value: true },
                { label: t("LABEL.NO"), value: false },
              ]}
            />
            {errors?.procurementSetting?.bidBond && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.bidBond?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Bid Document Public Visibility */}
          <FormControl isRequired>
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.BID_DOCUMENT_VISIBILITY")}</FormLabel>
            <SelectButton
              className="w-full grid grid-cols-2"
              control={control}
              name="procurementSetting.bidDocumentPublicVisibility"
              value={form?.getValues("procurementSetting.bidDocumentPublicVisibility")}
              options={[
                { label: t("LABEL.PUBLIC"), value: true },
                { label: t("LABEL.PRIVATE"), value: false },
              ]}
            />
            {errors?.procurementSetting?.bidDocumentPublicVisibility && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.bidDocumentPublicVisibility?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Aanwijzing */}
          <FormControl isRequired>
            <FormLabel className={`uppercase font-semibold text-xs`}>{t("Aanwijzing")}</FormLabel>
            <SelectButton
              className="w-full grid grid-cols-2"
              control={control}
              name="procurementSetting.aanwijzing"
              value={form?.getValues("procurementSetting.aanwijzing")}
              options={[
                { label: t("LABEL.YES"), value: true },
                { label: t("LABEL.NO"), value: false },
              ]}
            />
            {errors?.procurementSetting?.aanwijzing && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.procurementSetting?.aanwijzing?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </FormControl>

      <hr className="md:col-span-2" />
    </>
  );
};

export default ProcurementSettingForm;
