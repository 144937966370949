import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { AdminContext } from "../Controller";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { handleDetailMenu } = useContext(AdminContext);

  return (
    <Tr className="hover:bg-gray-50 cursor-pointer" onClick={() => handleDetailMenu(true, data)}>
      <Td>{data?.name}</Td>
      <Td>{data?.username}</Td>
      <Td>
        <ul>
          {data?.roles?.map((role, iRole) => (
            <li key={iRole}>{role}</li>
          ))}
        </ul>
      </Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const AdminTable = () => {
  const { admin, loadingAdmin, totalData, limit, tableForm, handleCreate } = useContext(AdminContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-end">
        <Button size="sm" onClick={() => handleCreate(true)}>
          <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: "Admin" })}
        </Button>
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("Admin")}</Th>
            <Th className="text-red-500">{t("LABEL.ADMIN_USERNAME")}</Th>
            <Th className="text-red-500">{t("LABEL.ADMIN_ROLE")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingAdmin ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !admin.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            admin.map((admin, iAdmin) => <Row key={iAdmin} data={admin} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default AdminTable;
