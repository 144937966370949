import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import moment from "moment";
import NextStepButton from "../../../NextStepButton";
import { ProcurementDetailContext } from "../../../../Controller";
const Row = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Tr className="hover:bg-gray-50">
      <Td>
        {data.approved_at === null
          ? moment(data?.rejected_at).format("LLL")
          : moment(data?.approved_at).format("LLL")}{" "}
      </Td>
      <Td>
        {data.approved_by === null ? data?.rejected_by : data?.approved_by}
      </Td>
      {/* <Td>{data?.email}</Td> */}
      <Td>{data?.notes}</Td>
      <Td>
        {data.is_approved === false ? t("LABEL.REJECTED") : t("LABEL.APPROVED")}
      </Td>
    </Tr>
  );
};

const ApprovalTable = ({
  approvals,
  loadingApprovals,
  totalData,
  limit,
  tableForm,
  isFirst,
  isButton,
}) => {
  const { t } = useTranslation();

  const { isApproved, procurement } = useContext(ProcurementDetailContext); 

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <div className="mb-2 flex justify-between items-center">
        <span
          className="font-semibold text-gray-700"
          style={{ borderBottom: "1px solid #777" }}
        >
          {isFirst
            ? t("LABEL.FIRST_LEVEL_APPROVAL")
            : t("LABEL.SECOND_LEVEL_APPROVAL")}
        </span>
        {isButton}
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">
              {t("LABEL.APPROVED_AT")}/{t("LABEL.REJECTED_AT")}
            </Th>
            <Th className="text-red-500">
              {t("LABEL.APPROVED_BY", { field: "" })}/
              {t("LABEL.REJECTED_BY", { field: "" })}
            </Th>
            {/* <Th className="text-red-500">{t("LABEL.EMAIL", { field: "" })}</Th> */}
            <Th className="text-red-500">{t("LABEL.NOTES")}</Th>
            <Th className="text-red-500">{t("LABEL.STATUS")}</Th>
            {/* <Th /> */}
          </Tr>
        </Thead>
        <Tbody>
          {loadingApprovals ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !approvals.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">
                  {t("TEXT.DATA_NOT_FOUND")}
                </p>
              </Td>
            </Tr>
          ) : (
            approvals.map((approval, iApproval) => (
              <Row key={iApproval} data={approval} />
            ))
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>

      {
        !isFirst && (
          <NextStepButton isDisabled={!isApproved} to={procurement?.procurementSetting?.aanwijzing? "aanwijzing" : "participant"} url={procurement?.procurementSetting?.aanwijzing? "aanwijzing" : "participant"} className='mt-4 mr-4 mb-4' toLabel={procurement?.procurementSetting?.aanwijzing? "Aanwijzing" : "Peserta"} />
        )
      }
    </div>
  );
};

export default ApprovalTable;
