import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { VendorContext } from "../Controller";
import _ from "lodash";
import { Link } from "react-router-dom";
import { userRouteSlug } from "../../../../../../../routes/userRoutes";

const Row = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Tr className="hover:bg-gray-50">
      <Td>
        <Link
          className="font-semibold text-blue-600 hover:text-blue-500"
          to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${data?.vendor}`}
          target="_blank"
        >
          {data?.vendor} - {data?.detail?.companyName}
        </Link>
      </Td>
      <Td>{data?.detail?.companyName}</Td>
      <Td>
        {_.startCase(data?.detail?.city?.toLowerCase())}, {data?.detail?.country}
      </Td>
    </Tr>
  );
};

const VendorTable = () => {
  const { vendor, loadingRequisition } = useContext(VendorContext);
  const { t } = useTranslation();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.REGISTRATION_ID")}</Th>
            <Th className="text-red-500">{t("LABEL.COMPANY_NAME")}</Th>
            <Th className="text-red-500">
              {t("LABEL.CITY")}, {t("LABEL.COUNTRY")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {loadingRequisition ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !vendor.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            vendor.map((vendor, iVendor) => <Row key={iVendor} data={vendor} />)
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export default VendorTable;
