import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const CompetencyContext = createContext();
const { Provider } = CompetencyContext;

const CompetencyController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        businessField: newForm?.businessField?.isicCode,
        subBusinessField: newForm?.subBusinessField?.isicCode,
        competencyVendorType: newForm?.vendorType?.competencyVendorType,
        description: newForm?.description,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeCompetency, setActiveCompetency] = useState();
  const handleDetailMenu = (isOpen, competency = null) => {
    setDetailMenu(isOpen);
    setActiveCompetency(competency);
    isOpen && resetForm(competency);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Competencies
  const [competencies, setCompetencies] = useState([]);
  const [loadingCompetencies, setLoadingCompetencies] = useState(false);
  const [reloadCompetencies, setReloadCompetencies] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getCompetencies = async () => {
      setLoadingCompetencies(true);
      axios
        .get(`/competency/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newCompetencies = res?.data?.competencies;
            setCompetencies(newCompetencies);

            if (activeCompetency) {
              const newActiveCompetency =
                newCompetencies.find((competency) => competency?.competencyId === activeCompetency?.competencyId) ||
                null;
              setActiveCompetency(newActiveCompetency);
              resetForm(newActiveCompetency);
            }
          }
        })
        .finally(() => {
          setReloadCompetencies(false);
          setLoadingCompetencies(false);
        });
    };

    userData && token && reloadCompetencies && getCompetencies();

    if (reloadCompetencies) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadCompetencies]);

  // -------------------------------
  // Master
  // -------------------------------

  // ISIC / KBLI
  const [isic, setIsic] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get("/isic").then((res) => {
      if (mounted) {
        setIsic(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Sub ISIC / KBLI
  const businessField = form.watch("businessField");
  const [subIsic, setSubIsic] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/isic/${businessField}`).then((res) => {
      if (mounted) {
        setSubIsic(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, [businessField]);

  // Competency Vendor Type
  const [competencyVendorTypes, setCompetencyVendorTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/competency-vendor-type`).then((res) => {
      if (mounted) {
        setCompetencyVendorTypes(res?.data?.competencyVendorType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createCompetency = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    formData.append("attachment", payload?.attachment[0]);

    axios
      .post(`competency/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadCompetencies(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeCompetency);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateCompetency = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    payload?.attachment && formData.append("attachment", payload?.attachment[0]);

    axios
      .patch(`competency/${regCode}/${activeCompetency?.competencyId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadCompetencies(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteCompetency = () => {
    setLoadingDelete(true);

    axios
      .delete(`competency/${regCode}/${activeCompetency?.competencyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadCompetencies(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  // -------------------------------
  // Star Data
  // -------------------------------

  const [isModalStarOpen, setModalStarOpen] = useState(false);
  const [loadingStar, setLoadingStar] = useState(false);
  const starCompetency = () => {
    setLoadingStar(true);

    axios
      .patch(
        `competency/star/${regCode}/${activeCompetency?.competencyId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadCompetencies(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingStar(false);
        setModalStarOpen(false);
      });
  };

  return (
    <Provider
      value={{
        form,
        loadingCompetencies,
        competencies,
        activeCompetency,

        competencyVendorTypes,
        isic,
        subIsic,

        isCreate,
        handleCreate,
        loadingCreate,
        createCompetency,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateCompetency,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteCompetency,

        isModalStarOpen,
        setModalStarOpen,
        loadingStar,
        starCompetency,
      }}
    >
      {children}
    </Provider>
  );
};

export default CompetencyController;
