import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AanwijzingContext } from "../Controller";

const ModalAanwijzingAnnounce = () => {
  const { t } = useTranslation();
  const {
    announceStatus,
    handleAnnounceOpen,
    isAnnounceOpen,
    loadingAnnounce,
    announceAanwijzing,
  } = useContext(AanwijzingContext);

  return (
    <Modal
      onClose={() => handleAnnounceOpen(false)}
      size="md"
      isOpen={isAnnounceOpen}
    >
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>
          {t("LABEL.PROCUREMENT_AANWIJZING_ANNOUNCE")}{" "}
          {t("LABEL.PROCUREMENT_AANWIJZING")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {announceStatus === "announce"
            ? t("TEXT.ANNOUNCEMENT_PROCUREMENT_AANWIJZING")
            : announceStatus === "finish"
            ? t("TEXT.ANNOUNCEMENT_PROCUREMENT_AANWIJZING_FINISHED")
            : announceStatus === "cancel"
            ? t("TEXT.ANNOUNCEMENT_PROCUREMENT_AANWIJZING_CANCELLED")
            : ""}
        </ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleAnnounceOpen(false)}>
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingAnnounce}
            onClick={announceAanwijzing}
            className="transition-all duration-500"
          >
            {announceStatus === "announce"
            ? t("LABEL.PROCUREMENT_AANWIJZING_ANNOUNCE")
            : announceStatus === "finish"
            ? t("LABEL.PROCUREMENT_AANWIJZING_FINISHED")
            : announceStatus === "cancel"
            ? t("LABEL.PROCUREMENT_AANWIJZING_CANCELLED")
            : ""}{" "}
            {t("LABEL.PROCUREMENT_AANWIJZING")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAanwijzingAnnounce;
