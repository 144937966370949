import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsArrowLeftCircle } from "react-icons/bs";
import PrivateComponent from "../../../../../../../components/PrivateComponent";
import { AuthContext } from "../../../../../../../controllers/auth/AuthController";
import { UserContext } from "../Controller";
import Form from "./Form";
import ModalDelete from "./ModalDelete";
import ModalStar from "./ModalStar";

const UserDetail = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const {
    isEdit,
    setEdit,
    handleDetailMenu,
    form,
    loadingUpdate,
    updateUser,
    activeUser,
    setModalDeleteOpen,
    setModalStarOpen,
    users,
  } = useContext(UserContext);

  // Is user logged in is a master/starred user
  const isMain = !!users?.find((user) => user?.userId === userData?.userId)?.isMain;

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <div className="md:col-span-2 flex justify-between items-center gap-2">
          <button onClick={() => handleDetailMenu(false)}>
            <BsArrowLeftCircle className="text-xl text-gray-600" />
          </button>
          {!activeUser?.isDelete && (
            <PrivateComponent accessName="UPDATE_COMPANY_PROFILE">
              {isEdit ? (
                <div className="flex gap-2">
                  <Button size="xs" onClick={() => setEdit(false)}>
                    {t("LABEL.CANCEL")}
                  </Button>
                  <Button
                    size="xs"
                    colorScheme="teal"
                    isLoading={loadingUpdate}
                    onClick={form.handleSubmit(updateUser)}
                  >
                    {t("LABEL.SAVE")}
                  </Button>
                </div>
              ) : (
                <div className="flex gap-2">
                  {/* Only master and not current user can toggle master */}
                  {isMain && activeUser?.userId !== userData?.userId && (
                    <button onClick={() => setModalStarOpen(true)}>
                      {activeUser?.isMain ? (
                        <AiFillStar className="text-lg text-yellow-500" />
                      ) : (
                        <AiOutlineStar className="text-lg text-gray-500" />
                      )}
                    </button>
                  )}

                  {/* Only master or current user can edit */}
                  {(isMain || activeUser?.userId === userData?.userId) && (
                    <button onClick={() => setEdit(true)}>
                      <BiEdit className="text-lg text-gray-500" />
                    </button>
                  )}

                  {/* Only master user can remove non-master user */}
                  {isMain && !activeUser?.isMain && (
                    <button onClick={() => setModalDeleteOpen(true)}>
                      <BiTrash className="text-lg text-red-500" />
                    </button>
                  )}
                </div>
              )}
            </PrivateComponent>
          )}
        </div>

        <Form isEdit={isEdit} />
        <ModalDelete />
        <ModalStar />
      </div>
    </motion.div>
  );
};

export default UserDetail;
