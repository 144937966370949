import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BiReceipt, BiCategoryAlt } from "react-icons/bi";
import General from "./components/sections/General";
import axios from "axios";
import { AuthContext } from "../../../controllers/auth/AuthController";
import ReqRequisition from "./components/sections/ReqRequisition";
import Vendor from "./components/sections/Vendor";
import AuctionLive from "./components/sections/AuctionLive";

export const AuctionContext = createContext();
const { Provider } = AuctionContext;

const AuctionController = ({ children }) => {
  const params = useParams();
  const { auctionId } = params;
  const { token } = useContext(AuthContext);

  // ---------------------------
  // Sections
  // ---------------------------

  const sectionCategories = [
    {
      name: "Administration",
      labelI18n: "LABEL.ADMINISTRATION",
      Icon: BiReceipt,
    },
  ];

  const sections = [
    {
      name: "General",
      category: "Administration",
      labelI18n: "LABEL.GENERAL",
      to: "/general",
      Component: General,
    },
    {
      name: "Material",
      category: "Administration",
      labelI18n: "Material",
      to: "/material",
      Component: ReqRequisition,
    },
    {
      name: "Vendor",
      category: "Administration",
      labelI18n: "LABEL.VENDOR",
      to: "/vendor",
      Component: Vendor,
      restrict: ["user"],
    },
    {
      name: "Auction",
      category: "Administration",
      labelI18n: "LABEL.AUCTION",
      isTargetBlack: true,
      to: "/live",
      Component: AuctionLive,
    },
  ];

  const [activeSection, setActiveSection] = useState();

  useEffect(() => {
    setActiveSection(sections.find((section) => section.to?.includes(params?.section)));
  }, [params]);

  // ---------------------------
  // Data
  // ---------------------------

  // Auction
  const [auction, setAuction] = useState();
  const [loadingAuction, setLoadingAuction] = useState(false);
  const [reloadAuction, setReloadAuction] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getAuction = async () => {
      setLoadingAuction(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/procurement-auction/by-id/${auctionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newAuction = res?.data?.auction;
            setAuction(newAuction);
          }
        })
        .finally(() => {
          setReloadAuction(false);
          setLoadingAuction(false);
        });
    };

    token && reloadAuction && auctionId && getAuction();

    return () => {
      mounted = false;
    };
  }, [token, reloadAuction, auctionId]);

  return (
    <Provider
      value={{
        sectionCategories,
        sections,
        activeSection,

        auction,
        loadingAuction,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuctionController;
