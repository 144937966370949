import { Spinner, Button, FormControl, FormHelperText } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiEdit, BiExit, BiPaperPlane } from 'react-icons/bi';
import Datepicker from '../../../../../../../../../../components/inputForm/Datepicker';
import PrivateComponent from '../../../../../../../../../../components/PrivateComponent';
import { HelperContext } from '../../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../../../Controller';

const SchedulePanel = ({ title, start, end, loading, className, type }) => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const { procurementSchedule, setProcurementSchedule, procurement } = useContext(ProcurementDetailContext);

	const [isEdit, setIsEdit] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const {
		control,
		formState: { errors },
		reset,
		handleSubmit,
		setError,
	} = useForm();

	useEffect(() => {
		if (!isEdit) {
			reset({
				start: start ? new Date(start) : null,
				end: end ? new Date(end) : null,
			});
		}
	}, [isEdit, start, end]);

	const validation = (data) => {
		const startDate = moment(data.start).unix();
		const endDate = moment(data.end).unix();
		const nowDate = moment().add(1, 'minutes').unix();

		if (endDate < startDate) {
			setError('start', {
				message: 'Mulai penawaran harus lebih kecil',
			});
			return false;
		}
		if (canEditStart && startDate < nowDate) {
			setError('start', {
				message: 'Mulai tidak boleh lebih kecil dari waktu sekarang',
			});
			return false;
		}

		if (canEditEnd && endDate < nowDate) {
			setError('end', {
				message: 'Batas akhir tidak boleh lebih kecil dari waktu sekarang',
			});
			return false;
		}

		return true;
	};

	const submit = async (data) => {
		const valid = validation(data);
		if (!valid || !procurementSchedule) return;

		const payload = {
			prequalificationStart: data.start,
			prequalificationEnd: data.end,
		};

		setLoadingSubmit(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule/prequalification/${procurementSchedule.procurementScheduleId}`,
			method: 'PATCH',
			payload,
		});
		setLoadingSubmit(false);

		if (!response?.procurementSchedule) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		setProcurementSchedule(response.procurementSchedule);
    setIsEdit(false);
		showToast('Jadwal berhasil diubah');
	};

	const now = moment().unix();
	const canEditStart = start && now < moment(start).unix();
	const canEditEnd = end && now < moment(end).unix();

	return (
		<div className={`flex ${className}`}>
			<div className="w-1/5 flex items-center justify-center text-base font-semibold italic text-center">{title}</div>
			<div className="font-bold flex flex-col gap-2 py-2 w-3/5">
				<div className="flex items-center gap-5 text-green-600">
					<div className="text-right w-1/4">{t('LABEL.START_AT')}</div>
					<div className="w-3/4 flex items-center">
						{!isEdit ? (
							<>{loading ? <Spinner color="red.500" /> : <>{!start ? <>-</> : <>{start ? moment(start).format('LLL') : <>-</>}</>}</>}</>
						) : (
							<div className="w-3/4">
								<FormControl>
									<Datepicker
										disabled={!canEditStart}
										format="yyyy-MM-dd HH:mm:ss"
										name="start"
										placeholder="Mulai"
										control={control}
										controlProps={{
											rules: {
												required: t('INVALID.REQUIRED', {
													field: t('LABEL.PROCUREMENT_START', {
														field: t('LABEL.PROCUREMENT_TECHNICAL_TENDER'),
													}),
												}),
											},
										}}
										isError={!!errors?.start}
									/>
									{errors?.start && <FormHelperText className="text-red-400 text-xs">{errors?.start?.message}</FormHelperText>}
								</FormControl>
							</div>
						)}
					</div>
				</div>
				<div className="flex items-center gap-5 text-color0">
					<div className="text-right w-1/4">{t('LABEL.END_AT')}</div>
					<div className="w-3/4 flex items-center">
						{!isEdit ? (
							<>{loading ? <Spinner color="red.500" /> : <>{!end ? <>-</> : <>{end ? moment(end).format('LLL') : <>-</>}</>}</>}</>
						) : (
							<div className="w-3/4">
								<FormControl>
									<Datepicker
										disabled={!canEditEnd}
										format="yyyy-MM-dd HH:mm:ss"
										name="end"
										placeholder="Batas Akhir"
										control={control}
										controlProps={{
											rules: {
												required: t('INVALID.REQUIRED', {
													field: t('LABEL.PROCUREMENT_START', {
														field: t('LABEL.PROCUREMENT_TECHNICAL_TENDER'),
													}),
												}),
											},
										}}
										isError={!!errors?.end}
									/>
									{errors?.end && <FormHelperText className="text-red-400 text-xs">{errors?.end?.message}</FormHelperText>}
								</FormControl>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="w-1/4 flex justify-center flex-col items-start gap-3">
				<PrivateComponent accessName="BUYER_EDIT">
					{!procurement?.procurementWinnerProposal?.isWinner && (
            !isEdit ? (
              <Button
                className="font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-size-200 bg-pos-0 hover:bg-pos-100 text-sm"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                <BiEdit className="text-xl" />
                {t('LABEL.EDIT', { field: '' })}
              </Button>
            ) : (
              <>
                <Button
                  disabled={!canEditEnd && !canEditStart}
                  className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100 shadow-lg text-sm"
                  onClick={handleSubmit(submit)}
                  isLoading={loadingSubmit}
                >
                  <BiPaperPlane className="text-xl" />
                  {t('LABEL.SUBMIT', { field: '' })}
                </Button>
                <Button
                  className="font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-size-200 bg-pos-0 hover:bg-pos-100 text-sm"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  <BiExit className="text-xl" />
                  {t('LABEL.CANCEL', { field: '' })}
                </Button>
              </>
            )
          )}
				</PrivateComponent>
			</div>
		</div>
	);
};

export default SchedulePanel;
