import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../../utils/apiURLs.util';
import { filterBuilder } from '../../../../../../../../../utils/filterBuilder';
import { ProcurementDetailContext } from '../../../../Controller';

export const PrequalificationEvaluationContext = createContext();
const { Provider } = PrequalificationEvaluationContext;

const PrequalificationEvaluationController = ({ children }) => {
	const { userData } = useContext(AuthContext);
	const { procurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);

  const [prequalificationRequirement, setPrequalificationRequirement] = useState();
	const [loadingPrequalificationRequirement, setLoadingPrequalificationRequirement] = useState(false);
	useEffect(() => {
		if (!procurement) return;
		let mounted = true;
		(async () => {
			setLoadingPrequalificationRequirement(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-requirement/${procurement.procurementNumber}`,
			});
			if (!mounted) return;
			setLoadingPrequalificationRequirement(false);

			if (!response?.procurementRequirement) {
				// TODO: Something wrong
				return;
			}
			const preqReq = response?.procurementRequirement?.filter((req) => req.requirementProcurementStep === 'PRAKUALIFIKASI')?.[0];
			setPrequalificationRequirement(preqReq);
		})();
		return () => {
			mounted = false;
		};
	}, [procurement]);

	const [vendors, setVendors] = useState([]);
	const [loadingVendors, setLoadingVendors] = useState(false);
	useEffect(() => {
		if (!procurement) return;
		let mounted = true;

		(async () => {
      const filter = filterBuilder({
        limit: 10,
        page: 1,
        snippets: ['procurementVendorPrequalification']
      });
    
			setLoadingVendors(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor/test/${procurement?.procurementNumber}?${filter}`,
			});
			if (!mounted) return;
			setLoadingVendors(false);
			setVendors(response?.procurementVendors);
		})();

		return () => {
			mounted = false;
		};
	}, [procurement]);

	const [evaluation, setEvaluation] = useState();
	useEffect(() => {
		if (!procurement) return;
		let mounted = true;

		(async () => {
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-prequalification-evaluation/${procurement.procurementNumber}`,
			});
			if (!mounted) return;
			if (!response?.success) {
				return;
			}
			setEvaluation(response?.data?.procurementPrequalificationEvaluation);
		})();

		return () => {
			mounted = false;
		};
	}, [procurement]);

	return (
		<Provider
			value={{
				vendors,
				setVendors,
				loadingVendors,
				evaluation,
				setEvaluation,

        loadingPrequalificationRequirement, setLoadingPrequalificationRequirement,
        prequalificationRequirement, setPrequalificationRequirement
			}}
		>
			{children}
		</Provider>
	);
};

export default PrequalificationEvaluationController;
