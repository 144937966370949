import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ProcurementContext } from "../Controller";
import moment from "moment";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../components/inputForm/FormDescription";
import Select from "../../../../components/inputForm/Select";
import Datepicker from "../../../../components/inputForm/Datepicker";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { isCreate, activeProcurement, form, procurementTypes, bidEntryMethods, contractTypes, incoterms } =
    useContext(ProcurementContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Title */}
      <FormControl isRequired={isEdit} className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PROCUREMENT_TITLE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.title && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.PROCUREMENT_TITLE")}
            {...register("title", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.PROCUREMENT_TITLE") }),
            })}
          />
        ) : (
          <FormDescription>{activeProcurement?.title}</FormDescription>
        )}
        {errors?.title && <FormHelperText className="text-red-400 text-xs">{errors?.title?.message}</FormHelperText>}
      </FormControl>

      {/* Procurement Number */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PROCUREMENT_NUMBER")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.procurementNumber && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.PROCUREMENT_NUMBER")}
            {...register("procurementNumber", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.PROCUREMENT_NUMBER") }),
            })}
            disabled
          />
        ) : (
          <FormDescription>{activeProcurement?.procurementNumber}</FormDescription>
        )}
        {errors?.procurementNumber && (
          <FormHelperText className="text-red-400 text-xs">{errors?.procurementNumber?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Procurement Type */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.PROCUREMENT_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.PROCUREMENT_TYPE") }) },
            }}
            name="procurementType"
            options={procurementTypes.map((procurementType) => ({
              label: t(procurementType?.labelI18n),
              value: procurementType?.procurementType,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.PROCUREMENT_TYPE")}`}
            isError={!!errors?.procurementType}
          />
        ) : (
          <FormDescription>{activeProcurement?.procurementType?.description}</FormDescription>
        )}

        {errors?.procurementType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.requisitioner?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Bid Entry Method */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BID_ENTRY_METHOD")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.BID_ENTRY_METHOD") }) },
            }}
            name="bidEntryMethod"
            options={bidEntryMethods.map((bidEntryMethod) => ({
              label: t(bidEntryMethod?.labelI18n),
              value: bidEntryMethod?.bidEntryMethod,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.BID_ENTRY_METHOD")}`}
            isError={!!errors?.bidEntryMethod}
          />
        ) : (
          <FormDescription>{activeProcurement?.bidEntryMethod?.description}</FormDescription>
        )}

        {errors?.bidEntryMethod && (
          <FormHelperText className="text-red-400 text-xs">{errors?.requisitioner?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Incoterm */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("Incoterm")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("Incoterm") }) },
            }}
            name="incoterm"
            options={incoterms.map((incoterm) => ({
              label: `${incoterm?.incotermCode} - ${incoterm?.description}`,
              value: incoterm?.incotermCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("Incoterm")}`}
            isError={!!errors?.incoterm}
          />
        ) : (
          <FormDescription>{activeProcurement?.incoterm?.description}</FormDescription>
        )}

        {errors?.incoterm && (
          <FormHelperText className="text-red-400 text-xs">{errors?.requisitioner?.message}</FormHelperText>
        )}
      </FormControl>

      <hr className="md:col-span-2" />
    </>
  );
};

export default Form;
