import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/auth/AuthController";

export const MaterialContext = createContext();
const { Provider } = MaterialContext;

const MaterialController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const regCode = "";

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        materialCode: newForm?.materialCode,
        materialGroup: newForm?.materialGroup?.materialGroup,
        measureUnit: newForm?.measureUnit?.unit,
        description: newForm?.description,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeMaterial, setActiveMaterial] = useState();
  const handleDetailMenu = (isOpen, data = null) => {
    setDetailMenu(isOpen);
    setActiveMaterial({ ...data, password: "....." });
    isOpen && resetForm(data);
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadMaterial(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Material
  const [material, setMaterial] = useState([]);
  const [loadingMaterial, setLoadingMaterial] = useState(false);
  const [reloadMaterial, setReloadMaterial] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getCompanies = async () => {
      setLoadingMaterial(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/material${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newMaterial = res?.data?.material;
            setMaterial(newMaterial);
            setTotalData(res?.data?.pagination?.totalData);
            if (activeMaterial) {
              const newActiveMaterial =
                newMaterial.find((material) => material?.materialId === activeMaterial?.materialId) || null;
              setActiveMaterial(newActiveMaterial);
              resetForm(newActiveMaterial);
            }
          }
        })
        .finally(() => {
          setReloadMaterial(false);
          setLoadingMaterial(false);
        });
    };

    token && reloadMaterial && getCompanies();

    return () => {
      mounted = false;
    };
  }, [token, reloadMaterial]);

  // ---------------------------
  // Master
  // ---------------------------

  // Req Requisition Code
  const [materialCode, setMaterialCode] = useState();
  const materialGroup = form.watch("materialGroup");
  useEffect(() => {
    let mounted = true;

    const getMaterialCode = () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/material/generate-material-code/${materialGroup}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newMaterialCode = res?.data?.newMaterialCode;
            setMaterialCode(newMaterialCode);
            form.setValue("materialCode", newMaterialCode);
          }
        });
    };

    token && materialGroup && getMaterialCode();

    return () => {
      mounted = false;
    };
  }, [token, materialGroup]);

  const [materialGroups, setMaterialGroups] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/material-group`).then((res) => {
        if (mounted) {
          setMaterialGroups(res?.data?.materialGroups);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  const [measureUnits, setMeasureUnits] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/measure-unit`).then((res) => {
        if (mounted) {
          setMeasureUnits(res?.data?.measureUnits);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createMaterial = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`${process.env.REACT_APP_TMS_API}/material`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadMaterial(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeMaterial);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateMaterial = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(`${process.env.REACT_APP_TMS_API}/material/${activeMaterial?.materialId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadMaterial(true);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteMaterial = () => {
    setLoadingDelete(true);

    axios
      .delete(`${process.env.REACT_APP_TMS_API}/material/${activeMaterial?.materialId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadMaterial(true);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  // Material Code Validation
  const [isValidMaterialCode, setIsValidMaterialCode] = useState(false);
  const [loadingValidationMaterialCode, setLoadingValidationMaterialCode] = useState(false);

  return (
    <Provider
      value={{
        tableForm,
        material,
        loadingMaterial,
        totalData,
        limit,

        materialGroups,
        measureUnits,

        form,
        activeMaterial,

        isCreate,
        handleCreate,
        loadingCreate,
        createMaterial,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateMaterial,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteMaterial,

        isValidMaterialCode,
        setIsValidMaterialCode,
        loadingValidationMaterialCode,
        setLoadingValidationMaterialCode,
      }}
    >
      {children}
    </Provider>
  );
};

export default MaterialController;
