import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReqRequisitionContext } from "../Controller";

const ModalDelete = () => {
  const { t } = useTranslation();
  const { isModalDeleteOpen, setModalDeleteOpen, loadingDelete, deleteReqRequisition } =
    useContext(ReqRequisitionContext);

  return (
    <Modal onClose={() => setModalDeleteOpen(false)} size="md" isOpen={isModalDeleteOpen}>
      <ModalOverlay />
      <ModalContent className="bg-red-500 text-white bg-opacity-80">
        <ModalHeader>{t("LABEL.DELETE", { field: t("LABEL.REQ_REQUISITION") })}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.DELETE_CONFIRMATION", { field: t("LABEL.REQ_REQUISITION") })}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => setModalDeleteOpen(false)} colorScheme="whiteAlpha">
            {t("LABEL.CANCEL")}
          </Button>
          <Button isLoading={loadingDelete} onClick={deleteReqRequisition} colorScheme="blackAlpha">
            {t("LABEL.DELETE", { field: "" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDelete;
