import React, { useContext } from "react";
import { motion } from "framer-motion";
import DocumentTable from "./components/DocumentTable";
import DocumentController, { DocumentContext } from "./Controller";
import DocumentNew from "./components/DocumentNew";
import ModalDelete from "./components/ModalDelete";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";

const Document = () => {
  const {
    isCreate,
    internalDocuments,
    loadingInternalDocuments,
    internalTotalData,
    internalLimit,
    internalTableForm,

    publicDocuments,
    loadingPublicDocuments,
    publicTotalData,
    publicLimit,
    publicTableForm,
  } = useContext(DocumentContext);
  const { userData } = useContext(AuthContext);

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 100 }}
    >
      {isCreate ? (
        <DocumentNew />
      ) : (
        <div className="flex flex-col gap-6">
          {userData?.type !== "user" ? (
            <>
              {" "}
              <DocumentTable
                documents={internalDocuments}
                loadingDocuments={loadingInternalDocuments}
                totalData={internalTotalData}
                limit={internalLimit}
                tableForm={internalTableForm}
                isPublic={false}
              />
              <DocumentTable
                documents={publicDocuments}
                loadingDocuments={loadingPublicDocuments}
                totalData={publicTotalData}
                limit={publicLimit}
                tableForm={publicTableForm}
                isPublic={true}
              />
            </>
          ) : (
            <DocumentTable
              documents={publicDocuments}
              loadingDocuments={loadingPublicDocuments}
              totalData={publicTotalData}
              limit={publicLimit}
              tableForm={publicTableForm}
              isPublic={true}
            />
          )}
        </div>
      )}

      <ModalDelete />
    </motion.div>
  );
};

export default () => (
  <DocumentController>
    <Document />
  </DocumentController>
);
