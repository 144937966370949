import { InputText } from "primereact/inputtext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { MaterialContext } from "../Controller";

const MaterialFilter = () => {
  const { t } = useTranslation();
  const { tableForm } = useContext(MaterialContext);
  const { register } = tableForm;

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 flex flex-col gap-4">
      {/* Search Bar */}
      <div className="p-input-icon-right">
        <InputText className={`w-full text-sm`} placeholder={t("LABEL.SEARCH")} {...register("search")} />
        <BiSearch />
      </div>
    </div>
  );
};

export default MaterialFilter;
