import React, { useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { AuctionContext } from "../../../../Controller";
import { Button, FormControl, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { AuctionLiveContext } from "../Controller";
import numeral from "numeral";
import InputNumber from "../../../../../../../components/inputForm/InputNumber";
import { useWatch } from "react-hook-form";

const AuctionMaterial = ({ data, value }) => {
  const { t } = useTranslation();
  const { form, minimumBids } = useContext(AuctionLiveContext);
  const {
    control,
    formState: { errors },
  } = form;

  const auctionMaterialId = data?.auctionMaterialId;
  const currency = data?.reqRequisitionMaterial?.currency?.currency;
  const minimumBid = minimumBids.find(
    (minimumBid) => minimumBid.reqRequisitionMaterialId === data?.reqRequisitionMaterial?.reqRequisitionMaterialId
  );

  return minimumBid ? (
    <>
      <Tr>
        <Td className="flex-1">{data?.reqRequisitionMaterial?.material?.description}</Td>
        <Td>Qty: {data?.reqRequisitionMaterial?.quantity}</Td>
        <Td className="w-80">
          <FormControl>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">{currency}</span>
              <InputNumber
                className={`w-full text-sm ${
                  errors?.bid?.[auctionMaterialId] && "border-2 border-red-400 shadow-none"
                }`}
                placeholder="0,00"
                name={`bid.${auctionMaterialId}`}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", { field: t("LABEL.PRICE") }),
                  },
                }}
                max={minimumBid?.price - 1}
                isError={!!errors?.bid?.[auctionMaterialId]}
              />
            </div>
            <span className="text-xs">
              Max: {numeral(minimumBid?.price - 1).format("0,0.00")} {currency}
            </span>
          </FormControl>
        </Td>
        <Td className="w-60 text-right font-semibold">
          {numeral((value * data?.reqRequisitionMaterial?.quantity) / data?.reqRequisitionMaterial?.valuation).format(
            "0,0.00"
          )}{" "}
          {currency}
        </Td>
      </Tr>
    </>
  ) : (
    <></>
  );
};

const BidForm = () => {
  const { t } = useTranslation();
  const { auction } = useContext(AuctionContext);
  const { form, bidAuction } = useContext(AuctionLiveContext);
  const currency = auction?.procurement?.currency;

  const bidWatch = useWatch({ name: "bid", control: form.control });
  const isValid = bidWatch && Object.values(bidWatch).filter((value) => !value).length === 0;

  useEffect(() => {
    console.log(bidWatch);
  }, [bidWatch]);

  const totalValue = auction?.auctionMaterials
    ?.map(
      (auctionMaterial) =>
        bidWatch?.[auctionMaterial?.auctionMaterialId] * auctionMaterial?.reqRequisitionMaterial?.quantity
    )
    ?.reduce((a, b) => (+a || 0) + (+b || 0));

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
      <div className="w-full bg-white rounded-md text-sm p-4">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t("Material")}</Th>
              <Th>{t("Qty")}</Th>
              <Th>{t("LABEL.UNIT_PRICE")}</Th>
              <Th className="text-right">{t("Sub Total")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {auction?.auctionMaterials?.map((auctionMaterial, iAuctionMaterial) => (
              <AuctionMaterial
                key={iAuctionMaterial}
                data={auctionMaterial}
                value={bidWatch?.[auctionMaterial?.auctionMaterialId]}
              />
            ))}
            <Tr className="bg-gray-50">
              <Td colSpan={2} />
              <Td className="font-semibold text-right">Total</Td>
              <Td className="font-semibold text-right">
                {numeral(totalValue).format("0,0.00")} {currency?.currency}
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <Button
          className="mt-4 w-full disabled:bg-yellow-500"
          colorScheme={isValid ? "red" : "gray"}
          disabled={!isValid}
          onClick={bidAuction}
        >
          <BiPlus className="mr-1" /> {t("LABEL.BID_AUCTION")}
        </Button>
      </div>
    </motion.div>
  );
};

export default BidForm;
