import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useContext } from 'react';

import _ from 'lodash';
import { userRouteSlug } from '../../../../../../../../../routes/userRoutes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ParticipantContext } from '../Controller';
import { AuthContext } from '../../../../../../../../../controllers/auth/AuthController';
import { Pagination } from 'rsuite';
import moment from 'moment';
import NextStepButton from '../../../NextStepButton';
import { ProcurementDetailContext } from '../../../../Controller';

const Row = ({ data }) => {
	const { isAdmin } = useContext(AuthContext);

	return (
		<Tr className="hover:bg-gray-50">
			<Td>
				{isAdmin ? (
					<Link className="font-semibold text-blue-600 hover:text-blue-500" to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${data?.vendor}`} target="_blank">
						{data?.vendor}
					</Link>
				) : (
					<span className="font-semibold">{data?.vendor}</span>
				)}
			</Td>
			<Td>{data?.company?.companyName}</Td>
			<Td>
				{_.startCase(data?.company?.city?.toLowerCase())}, {data?.company?.country}
			</Td>
			{isAdmin && <Td>{moment(data?.createdAt).format('LLL')}</Td>}
		</Tr>
	);
};

const ParticipantTable = () => {
	const { t } = useTranslation();
	const { isAdmin } = useContext(AuthContext);
	const { procurementType, procurement } = useContext(ProcurementDetailContext);
	const { loadingVendors, vendors, tableForm, pagination } = useContext(ParticipantContext);

	const watchTableForm = tableForm.watch();

	return (
		<div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <span className="font-semibold text-gray-700" style={{ borderBottom: "1px solid #777" }}>
          {t("LABEL.PARTICIPANT")}
        </span>
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th className="text-red-500">{t('LABEL.REGISTRATION_ID')}</Th>
						<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
						<Th className="text-red-500">
							{t('LABEL.CITY')}, {t('LABEL.COUNTRY')}
						</Th>
						{isAdmin && <Th className="text-red-500">Waktu Registrasi</Th>}
					</Tr>
				</Thead>
				<Tbody>
					{loadingVendors ? (
						<Tr>
							<Td colSpan={5}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					) : !vendors?.length ? (
						<Tr>
							<Td colSpan={5} className="text-center">
								<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
							</Td>
						</Tr>
					) : (
						vendors.map((vendor, idx) => <Row key={idx} data={vendor} />)
					)}
				</Tbody>
			</Table>

			<div className="mt-3 flex justify-end">
				<Pagination
					next
					prev
					ellipsis
					boundaryLinks
					maxButtons={5}
					total={pagination?.totalData || 0}
					limit={pagination?.limit || 10}
					activePage={watchTableForm?.page}
					onChangePage={(page) => tableForm.setValue('page', page)}
				/>
			</div>

			<NextStepButton isDisabled={vendors?.length < 1} url={procurement?.procurementSetting?.prequalification? "prequalification" : "offer"} to={procurement?.procurementSetting?.prequalification? "prequalification" : "offer"} className="mt-4" toLabel={procurement?.procurementSetting?.prequalification? "Prakualifikasi" : "Penawaran"} />
		</div>
	);
};

export default ParticipantTable;
