import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FinancialReportContext } from "../Controller";
import { IoIosArrowForward } from "react-icons/io";
import _ from "lodash";
import { motion } from "framer-motion";
import { BiPencil, BiPlus } from "react-icons/bi";
import PrivateComponent from "../../../../../../../components/PrivateComponent";
import { Spinner } from "@chakra-ui/react";
import moment from "moment";
import SubmitApprovalWarning from "../../../SubmitApprovalWarning";
import numeral from "numeral";

const FinancialReport = ({ data }) => {
  const { handleDetailMenu } = useContext(FinancialReportContext);
  const { t } = useTranslation();

  return (
    <button
      className={`text-left p-4 flex justify-between items-center bg-white text-gray-60`}
      style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
      onClick={() => handleDetailMenu(true, data)}
    >
      <div className={`w-full ${data?.isDelete && "opacity-60"}`}>
        <h2 className="font-semibold text-lg text-red-500 mb-2">
          {moment(new Date(data?.reportDate)).format("DD/MM/YYYY")} {data?.isDelete && ` (${t("LABEL.DELETED")})`}
        </h2>
        <div className="w-full grid md:grid-cols-3">
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{t("LABEL.TOTAL_ASSET")}</span>
            <span>
              {data?.currency?.symbol} {numeral(data?.totalAsset).format("0,0.00")}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{t("LABEL.TOTAL_DEBT")}</span>
            <span>
              {data?.currency?.symbol} {numeral(data?.totalDebt).format("0,0.00")}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-semibold">{t("LABEL.TOTAL_CAPITAL")}</span>
            <span>
              {data?.currency?.symbol} {numeral(data?.totalCapital).format("0,0.00")}
            </span>
          </div>
        </div>
        {(!data?.isApproved || data?.isDelete) && (
          <div className={`flex items-center gap-1 mt-2 text-xs text-blue-500`}>
            <BiPencil />
            <p>{t("TEXT.DATA_NEED_APPROVAL")}</p>
          </div>
        )}
      </div>
      <div>
        <IoIosArrowForward />
      </div>
    </button>
  );
};

const FinancialReportList = () => {
  const { financialReports, loadingFinancialReports, handleCreate } = useContext(FinancialReportContext);
  const { t } = useTranslation();

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="grid gap-4">
      <SubmitApprovalWarning menu="FinancialReport" />

      {loadingFinancialReports ? (
        <div
          className={`h-20 md:col-span-2 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
          style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {!financialReports.length ? (
            <PrivateComponent accessName="CREATE_COMPANY_PROFILE" doHasAccess={false}>
              <div
                className="p-3 bg-white bg-opacity-40 text-center text-sm text-gray-500"
                style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
              >
                {t("TEXT.DATA_NOT_FOUND")}
              </div>
            </PrivateComponent>
          ) : (
            financialReports.map((financialReport, iFinancialReport) => (
              <FinancialReport key={iFinancialReport} data={financialReport} />
            ))
          )}
          <PrivateComponent accessName="CREATE_COMPANY_PROFILE">
            <button
              className={`h-12 text-left p-4 flex justify-center items-center bg-white bg-opacity-40 text-gray-500 text-sm gap-1`}
              style={{ borderRadius: "0.3rem", boxShadow: "2px 2px 12px rgba(0,0,0,0.05)" }}
              onClick={() => handleCreate(true)}
            >
              <BiPlus />
              <p>{t("LABEL.ADD", { field: t("LABEL.FINANCIAL_REPORT") })}</p>
            </button>
          </PrivateComponent>
        </>
      )}
    </motion.div>
  );
};

export default FinancialReportList;
