import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../utils/apiURLs.util';
import { ProcurementDetailContext } from '../../../Controller';

export const PrequalificationContext = createContext();
const { Provider } = PrequalificationContext;

const PrequalificationController = ({ children }) => {
	const { userData } = useContext(AuthContext);
	const { procurement } = useContext(ProcurementDetailContext);
	const { httpRequest, showToast } = useContext(HelperContext);

	const [prequalificationRequirement, setPrequalificationRequirement] = useState();
	const [loadingPrequalificationRequirement, setLoadingPrequalificationRequirement] = useState(false);
	useEffect(() => {
		if (!procurement) return;
		let mounted = true;
		(async () => {
			setLoadingPrequalificationRequirement(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-requirement/${procurement.procurementNumber}`,
			});
			if (!mounted) return;
			setLoadingPrequalificationRequirement(false);

			if (!response?.procurementRequirement) {
				// TODO: Something wrong
				return;
			}
			const preqReq = response?.procurementRequirement?.filter((req) => req.requirementProcurementStep === 'PRAKUALIFIKASI')?.[0];
			setPrequalificationRequirement(preqReq);
		})();
		return () => {
			mounted = false;
		};
	}, [procurement]);

  const [vendorPrequalification, setVendorPrequalification] = useState();
	const [loadingVendorPrequalification, setLoadingVendorPrequalification] = useState(false);
	useEffect(async () => {
		if (!procurement || !userData || !prequalificationRequirement) return;

		let mounted = true;
		setLoadingVendorPrequalification(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor-prequalification/${procurement.procurementNumber}/${prequalificationRequirement?.procurementRequirementId}/${userData?.company?.registrationCode}`,
		});
		if (!mounted) return;
		setLoadingVendorPrequalification(false);

		if (!response?.success) {
			showToast('Something wrong', { success: false });
			return;
		}

		setVendorPrequalification(response?.data?.procurementVendorPrequalification);

		return () => {
			mounted = false;
		};
	}, [userData, procurement, prequalificationRequirement]);

	const [evaluation, setEvaluation] = useState();
	useEffect(() => {
		if (!procurement) return;
		let mounted = true;

		(async () => {
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement-prequalification-evaluation/${procurement.procurementNumber}`,
			});
			if (!mounted) return;
			if (!response?.success) {
				return;
			}
			setEvaluation(response?.data?.procurementPrequalificationEvaluation);
		})();

		return () => {
			mounted = false;
		};
	}, [procurement]);

	return (
		<Provider
			value={{
				setVendorPrequalification,
				vendorPrequalification,
				loadingVendorPrequalification,
				evaluation,
				setEvaluation,

				prequalificationRequirement,
				setPrequalificationRequirement,
				loadingPrequalificationRequirement,
				setLoadingPrequalificationRequirement,
			}}
		>
			{children}
		</Provider>
	);
};

export default PrequalificationController;
