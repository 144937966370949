import React, { useContext } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import FormDescription from "../../../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../../../Controller";
import { ProcScheduleContext } from "../../Controller";

const AuctionForm = ({ isEdit }) => {
  const [value, setValue] = React.useState(new Date());
  const { t } = useTranslation();
  const {
    prequalificationForm,
    schedules,
  } = useContext(ProcScheduleContext);
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    register,
    control,
    formState: { errors },
  } = prequalificationForm;

  return (
    <>
      {/* Auction */}
      {procurement?.procurementSetting?.eAuction === true ? (
        <>
          <hr className="md:col-span-2" />
          <FormControl className="md:col-span-2">
            <FormLabel
              className={`uppercase font-bold text-sm text-red-500 mb-4`}
            >
              {t("LABEL.AUCTION")}
            </FormLabel>

            <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
              {/* Start Auction */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_START", {
                    field: t("LABEL.AUCTION"),
                  })}
                </FormLabel>

                <FormDescription>
                  {schedules[0]?.auctionStart &&
                    moment(new Date(schedules[0]?.auctionStart)).format("LLL")}
                </FormDescription>

                {errors?.auctionStart && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.auctionStart?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* End Auction */}
              <FormControl isRequired={isEdit}>
                <FormLabel
                  className={`uppercase font-semibold text-xs ${
                    !isEdit && "text-red-500"
                  }`}
                >
                  {t("LABEL.PROCUREMENT_END", {
                    field: t("LABEL.AUCTION"),
                  })}
                </FormLabel>

                <FormDescription>
                  {schedules[0]?.auctionEnd &&
                    moment(new Date(schedules[0]?.auctionEnd)).format("LLL")}
                </FormDescription>

                {errors?.auctionEnd && (
                  <FormHelperText className="text-red-400 text-xs">
                    {errors?.auctionEnd?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </FormControl>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AuctionForm;
