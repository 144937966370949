import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import TaxDetail from "./components/TaxDetail";
import TaxList from "./components/TaxList";
import TaxNew from "./components/TaxNew";
import TaxController, { TaxContext } from "./Controller";

const Tax = () => {
  const { isDetailMenu, isCreate } = useContext(TaxContext);

  return (
    <div>
      <AnimatePresence>{isCreate ? <TaxNew /> : isDetailMenu ? <TaxDetail /> : <TaxList />}</AnimatePresence>
    </div>
  );
};

export default () => (
  <TaxController>
    <Tax />
  </TaxController>
);
