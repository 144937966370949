import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../controllers/auth/AuthController";
import { useForm, useWatch } from "react-hook-form";

export const VendorContext = createContext();
const { Provider } = VendorContext;

const VendorController = ({ children }) => {
  const { token } = useContext(AuthContext);

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadCompanies(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Company
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [reloadCompanies, setReloadCompanies] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const country = `country=${payload?.country || ""}`;
    const vendorStatus = `vendorStatus=${payload?.vendorStatus?.length ? payload?.vendorStatus?.join(";") : ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${country}&${vendorStatus}&${limit}&${page}`;

    const getCompanies = async () => {
      setLoadingCompanies(true);
      axios
        .get(`/company${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newCompanies = res?.data?.companies;
            setCompanies(newCompanies);
            setTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setReloadCompanies(false);
          setLoadingCompanies(false);
        });
    };

    token && reloadCompanies && getCompanies();

    return () => {
      mounted = false;
    };
  }, [token, reloadCompanies]);

  // ---------------------------
  // Master
  // ---------------------------

  // Countries
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/regions`).then((res) => {
      if (mounted) {
        setCountries(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Vendor Status
  const [vendorStatus, setVendorStatus] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/vendor-status`).then((res) => {
      if (mounted) {
        setVendorStatus(res?.data?.vendorStatus);
        tableForm.setValue(
          "vendorStatus",
          res?.data?.vendorStatus?.map((status) => status?.vendorStatus)
        );
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Provider value={{ tableForm, companies, loadingCompanies, countries, vendorStatus, totalData, limit }}>
      {children}
    </Provider>
  );
};

export default VendorController;
