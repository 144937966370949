import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { RegisterContext } from "../../../Controller";

export const CompanyProfileContext = createContext();

const CompanyProfileController = ({ children }) => {
  const { companyProfileForm } = useContext(RegisterContext);

  // -------------------------------
  // Master
  // -------------------------------

  // Countries
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/regions`).then((res) => {
      if (mounted) {
        setCountries(res?.data?.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Provinces
  const country = companyProfileForm.watch("country");
  const [provinces, setProvinces] = useState(null);
  useEffect(() => {
    let mounted = true;
    setProvinces(null);

    const getProvinces = () => {
      axios.get(`/regions?regionCode=${country}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setProvinces(res?.data?.data) : setProvinces(null);
        }
      });
    };

    country && getProvinces();

    return () => {
      mounted = false;
    };
  }, [country]);

  // Cities
  const province = companyProfileForm.watch("province");
  const [cities, setCities] = useState(null);
  useEffect(() => {
    let mounted = true;
    setCities(null);

    const getCities = () => {
      axios.get(`/regions?regionCode=${province}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setCities(res?.data?.data) : setCities(null);
        }
      });
    };

    province && getCities();

    return () => {
      mounted = false;
    };
  }, [province]);

  // Districts
  const city = companyProfileForm.watch("city");
  const [districts, setDistricts] = useState(null);
  useEffect(() => {
    let mounted = true;
    setDistricts(null);

    const getDistricts = () => {
      axios.get(`/regions?regionCode=${city}`).then((res) => {
        if (mounted) {
          res?.data?.data?.length ? setDistricts(res?.data?.data) : setDistricts(null);
        }
      });
    };

    city && getDistricts();

    return () => {
      mounted = false;
    };
  }, [city]);

  return (
    <CompanyProfileContext.Provider value={{ countries, provinces, cities, districts }}>
      {children}
    </CompanyProfileContext.Provider>
  );
};

export default CompanyProfileController;
