import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { GeneralContext } from "../Controller";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import Select from "../../../../../../../components/inputForm/Select";
import { InputTextarea } from "primereact/inputtextarea";
import _ from "lodash";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";

const Form = ({ isEdit }) => {
  const { t, i18n } = useTranslation();
  const { loadingCompany, company, form, isNameAvailable, loadingNameAvailable, companyTypes } =
    useContext(GeneralContext);
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Company Name */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.COMPANY_NAME")}
        </FormLabel>
        {isEdit ? (
          <div className="p-inputgroup">
            <InputText
              className={`w-full text-sm border-r-0 ${errors?.companyName && "border-2 border-red-400 shadow-none"}`}
              placeholder={t("LABEL.COMPANY_NAME")}
              {...register("companyName", {
                required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_NAME") }),
                validate: {
                  nameAvailable: (v) => isNameAvailable || t("INVALID.AVAILABLE", { field: v }),
                },
              })}
            />
            <span
              className={`p-inputgroup-addon bg-white border-l-0 ${
                errors?.companyName && "border-2 border-red-400 shadow-none"
              }`}
            >
              {loadingNameAvailable ? (
                <Spinner size="xs" />
              ) : isNameAvailable ? (
                <BsCheckLg className="text-green-500" />
              ) : (
                isNameAvailable === false && <BsXLg className="text-red-500" />
              )}
            </span>
          </div>
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{company?.companyName}</FormDescription>
        )}
        {errors?.companyName && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyName?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Company Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.COMPANY_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_TYPE") }) },
            }}
            name="companyType"
            options={companyTypes.map((companyType) => ({
              label: companyType?.companyType,
              value: companyType?.companyTypeCode,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.COMPANY_TYPE")}`}
            isError={!!errors?.companyType}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{company?.companyType?.companyType}</FormDescription>
        )}

        {errors?.companyType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Director Name */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.COMPANY_OWNER")} / CEO
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.companyOwner && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.COMPANY_OWNER")}
            {...register("companyOwner", { required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_OWNER") }) })}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{company?.bod?.[0]?.bodName}</FormDescription>
        )}
        {errors?.companyOwner && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyOwner?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Registration Id */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.REGISTRATION_ID")}
        </FormLabel>
        {isEdit ? (
          <InputText
            disabled
            className={`w-full text-sm`}
            placeholder={t("LABEL.REGISTRATION_ID")}
            {...register("registrationCode")}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{company?.registrationCode}</FormDescription>
        )}
      </FormControl>

      {/* Company Description */}
      <FormControl isRequired className="md:col-span-2">
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.COMPANY_DESCRIPTION")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${
              errors?.companyDescription && "bLABEL.REGISTRATION_IDorder-2 border-red-400 shadow-none"
            }`}
            placeholder={t("TEXT.WRITE_COMPANY_DESCRIPTION")}
            {...register("companyDescription", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.COMPANY_DESCRIPTION") }),
            })}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>{company?.description}</FormDescription>
        )}
        {errors?.companyDescription && (
          <FormHelperText className="text-red-400 text-xs">{errors?.companyDescription?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
