import { Button } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useObjectionContext } from '../Controller';
import Form from './Form';

const ObjectionNew = () => {
	const { t } = useTranslation();
	const { setIsCreate, createNewObjection, form, loadingCreate } = useObjectionContext();

	return (
		<div className="w-full grid md:grid-cols-2 gap-6">
			<div className="md:col-span-2 flex justify-between items-center gap-2">
				<button onClick={() => setIsCreate(false)}>
					<BsArrowLeftCircle className="text-xl text-gray-600" />
				</button>
				<Button size="sm" isLoading={loadingCreate} colorScheme="teal" onClick={form.handleSubmit(createNewObjection)}>
					{t('LABEL.SAVE')}
				</Button>
			</div>

			<Form isEdit={true} />
		</div>
	);
};

export default ObjectionNew;
