import { Button } from '@chakra-ui/react';
import numeral from 'numeral';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheck, BiX } from 'react-icons/bi';
import PrivateComponent from '../../../../../../../../../../../../components/PrivateComponent';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../../../utils/offerPhases.util';
import { ProcurementOfferEvaluationContext } from '../../../Controller';

const ItemCard = ({ idx, material, idxSubmitted, offer, loadingReject, loadingAccept, sendEvaluation }) => {
	const { t } = useTranslation();
	const { evaluation, is2Cover, offerEvaluationPhase } = useContext(ProcurementOfferEvaluationContext);

	const statusKey = is2Cover ? 'status' : 'statusCommercial';

	return (
		<div className="bg-gradient-to-br from-color0 via-color1 to-color2 rounded-md shadow-lg" style={{ padding: '3px' }}>
			<div className="flex justify-between px-2 py-1 rounded-md bg-white">
				<div className="w-1/3">
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t('LABEL.DESCRIPTION')}</div>
						<div style={{ maxHeight: '70px', overflow: 'auto' }}>{material.description}</div>
					</div>
				</div>
				<div className="w-1/3 flex flex-col gap-1">
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">Qty</div>
						<div className="w-2/3">{numeral(+material.qty).format('0,0')}</div>
					</div>
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">UoM</div>
						<div className="w-2/3">{`${material.unit} (${material.unitDescription})`}</div>
					</div>
					<div className="flex">
						<div className="w-1/3 font-semibold text-color0">{t('LABEL.CURRENCY')}</div>
						<div className="w-2/3">{`${material.currency.currency} (${material.currency.description})`}</div>
					</div>
				</div>
				<div className="w-1/4 flex flex-col gap-1">
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t('LABEL.PRICE')}</div>
						<div className="font-semibold">
							{idxSubmitted !== null && numeral(+offer?.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.price).format('0,0.00')} {idxSubmitted !== null && material.currency.currency}
							{idxSubmitted === null && `Tidak memberikan penawaran`}
						</div>
					</div>
					<div className="flex flex-col gap-1">
						<div className="font-semibold text-color0">{t('LABEL.MATERIAL_PRICE')}</div>
						<div className="font-bold">
							{idxSubmitted !== null && numeral(+offer?.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.price * material.qty).format('0,0.00')}{' '}
							{idxSubmitted !== null && material.currency.currency}
							{idxSubmitted === null && `Tidak memberikan penawaran`}
						</div>
					</div>
				</div>

				<div className=" mr-5 flex flex-col justify-between">
					{evaluation && evaluation[statusKey] === 'SUBMITTED' ? (
						<>
							{offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'ACCEPTED' && (
								<div className="text-green-500 font-semibold text-xl mt-auto mb-auto italic">{t('LABEL.QUALIFIED')}</div>
							)}
							{(offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'REJECTED' ||
								offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'PENDING') && (
								<div className="text-color0 font-semibold text-xl mt-auto mb-auto italic">{t('LABEL.FAIL')}</div>
							)}
						</>
					) : (
						<>
							{offerEvaluationPhase === OFFER_2_COVER_PHASES.POST ? (
								<div></div>
							) : (
								<>
									{idxSubmitted === null ? (
										<></>
									) : (
										<PrivateComponent accessName="BUYER_EDIT" placeholder={<div className=' mt-auto mb-auto text-color2 font-semibold text-xl italic'>Pending</div>}>
											<Button
												// width={'110px'}
												minWidth={'110px'}
												color={offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'ACCEPTED' ? 'white' : 'green'}
												className={`transition-all duration-500 flex gap-1 items-center justify-center ${
													offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'ACCEPTED' ? 'bg-green-500' : ''
												}`}
												onClick={() => {
													if (offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'ACCEPTED') return;
													sendEvaluation(idxSubmitted, idx, 'ACCEPTED');
												}}
												disabled={loadingReject[idx]}
												isLoading={loadingAccept[idx]}
											>
												<BiCheck className="text-1xl" />
												{t('LABEL.QUALIFIED')}
											</Button>
											<Button
												// width={'110px'}
												minWidth={'110px'}
												color={offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'REJECTED' ? 'white' : 'red'}
												className={`transition-all duration-500 flex gap-1 items-center justify-center ${
													offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'REJECTED' ? 'bg-color0' : ''
												}`}
												onClick={() => {
													if (offer.procurementCommercialOfferRequisitionMaterials[idxSubmitted]?.status === 'REJECTED') return;
													sendEvaluation(idxSubmitted, idx, 'REJECTED');
												}}
												disabled={loadingAccept[idx]}
												isLoading={loadingReject[idx]}
											>
												<BiX className="text-1xl" />
												{t('LABEL.FAIL')}
											</Button>
										</PrivateComponent>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ItemCard;
