import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuctionLiveContext } from "../Controller";
import { motion } from "framer-motion";
import { AuthContext } from "../../../../../../../controllers/auth/AuthController";
import { Button } from "@chakra-ui/react";

const Counter = () => {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);
  const { auctionStatus, timeRemaining } = useContext(AuctionLiveContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
      <div className="w-full bg-white rounded-md text-sm p-4 col-span-3 flex items-center justify-between">
        <p className="font-semibold">
          {auctionStatus === "pending"
            ? t("TEXT.AUCTION_NOT_STARTED")
            : auctionStatus === "active"
            ? t("TEXT.AUCTION_STARTED")
            : t("TEXT.AUCTION_FINISHED")}
        </p>
        <div className="flex flex-col items-end">
          <span className={`font-bold text-2xl ${auctionStatus === "active" ? "text-red-500" : "text-gray-800"}`}>
            {timeRemaining}
          </span>
          <p className="text-xs">
            {auctionStatus === "pending"
              ? t("TEXT.AUCTION_TIME_PENDING")
              : auctionStatus === "active" && t("TEXT.AUCTION_TIME_FINISH")}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default Counter;
