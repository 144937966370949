import {
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { FiSend } from "react-icons/fi";
import moment from "moment";
import { NegotiationContext } from "../Controller";
import { AuthContext } from "../../../../../../../../../controllers/auth/AuthController";
import { BsCheckLg, BsSearch, BsXLg } from "react-icons/bs";
import PrivateComponent from "../../../../../../../../../components/PrivateComponent";
import { ProcurementDetailContext } from "../../../../Controller";

const Row = ({ data, isButtonActive, isFirstPropose, isDone }) => {
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    handleUpdateNotesOpen,
    handleCreateNegotiationBid,
    handleUpdateRejectBidOpen,
    handleUpdateApproveBidOpen,
    passOfferVendor,
    activeVendor,
  } = useContext(NegotiationContext);
  const { userData } = useContext(AuthContext);

  return (
    <Tr
      className={`${
        data?.status === "REJECTED"
          ? `bg-red-100`
          : data?.status === "APPROVED"
          ? `bg-green-100`
          : (data?.createdby || data?.createdBy) === "Buyer"
          ? `bg-gray-100`
          : `bg-yellow-50`
      } `}
    >
      <Td>
        <div className="flex gap-2 items-center font-semibold">
          {!data?.status === "DRAFT" ? data?.status : data?.status}
        </div>
      </Td>
      <Td>{data?.createdby || data?.createdBy}</Td>
      <Td>{data?.notes}</Td>
      <Td>{moment(data?.created_at).format("LLL")}</Td>
      <Td className="px-4">
        {userData?.type === "user" ? (
          <div className="w-full h-full gap-2 flex justify-center items-center">
            {data?.price !== null ? (
              <Tooltip
                hasArrow
                label={t("LABEL.PROCUREMENT_NEGOTIATION_VIEW_BID")}
                placement="top"
              >
                {(data?.status === "APPROVED" || data?.status === "PROPOSED") &&
                isButtonActive ? (
                  <button
                    onClick={() =>
                      handleCreateNegotiationBid(
                        true,
                        data,
                        false,
                        passOfferVendor?.negotiationVendor
                          ?.filter(
                            (regCode) =>
                              regCode?.companyRegCode === userData?.username
                          )
                          .map(
                            (passOfferVendors, ipassOfferVendor) =>
                              passOfferVendors
                          )
                      )
                    }
                  >
                    <BsSearch style={{ fontSize: "1rem", color: "blue" }} />
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      handleCreateNegotiationBid(
                        true,
                        data,
                        false,
                        passOfferVendor?.negotiationVendor
                          ?.filter(
                            (regCode) =>
                              regCode?.companyRegCode === userData?.username
                          )
                          .map(
                            (passOfferVendors, ipassOfferVendor) =>
                              passOfferVendors
                          )
                      )
                    }
                  >
                    <BsSearch style={{ fontSize: "1rem", color: "blue" }} />
                  </button>
                )}
              </Tooltip>
            ) : (
              // ""
              <button
                className="text-blue-400"
                onClick={() =>
                  handleCreateNegotiationBid(
                    true,
                    data,
                    true,
                    passOfferVendor?.negotiationVendor
                      ?.filter(
                        (regCode) =>
                          regCode?.companyRegCode === userData?.username
                      )
                      .map(
                        (passOfferVendors, ipassOfferVendor) => passOfferVendors
                      )
                  )
                }
              >
                {isButtonActive && t("LABEL.BID_AUCTION")}
              </button>
            )}
          </div>
        ) : (
          <div className="w-full h-full gap-2 flex justify-center items-center italic">
            {data?.status === "DRAFT" && !procurement?.procurementWinnerProposal?.isWinner ? (
              <>
                <Tooltip
                  hasArrow
                  label={t("TOOLTIP.NEGOTIATION_PROPOSE")}
                  placement="top"
                >
                  <PrivateComponent accessName="BUYER_EDIT">
                    <button
                      disabled={isDone}
                      onClick={() =>
                        handleUpdateNotesOpen(
                          true,
                          data,
                          "propose",
                          isFirstPropose
                        )
                      }
                    >
                      <FiSend
                        style={{
                          fontSize: "1rem",
                          color: isDone ? "gray" : "blue",
                        }}
                      />
                    </button>
                  </PrivateComponent>
                </Tooltip>
              </>
            ) : data?.price !== null ? (
              data?.status === "APPROVED" ? (
                <Tooltip
                  hasArrow
                  label={t("LABEL.PROCUREMENT_NEGOTIATION_VIEW_BID")}
                  placement="top"
                >
                  <button
                    onClick={() =>
                      handleCreateNegotiationBid(
                        true,
                        data,
                        false,
                        passOfferVendor?.negotiationVendor
                          ?.filter(
                            (regCode) =>
                              regCode?.companyRegCode ===
                              activeVendor?.companyRegCode
                          )
                          .map(
                            (passOfferVendors, ipassOfferVendor) =>
                              passOfferVendors
                          )
                      )
                    }
                  >
                    <BsSearch style={{ fontSize: "1rem", color: "blue" }} />
                  </button>
                </Tooltip>
              ) : data?.status === "REJECTED" ? (
                <Tooltip
                  hasArrow
                  label={t("LABEL.PROCUREMENT_NEGOTIATION_VIEW_BID")}
                  placement="top"
                >
                  <button
                    onClick={() =>
                      handleCreateNegotiationBid(
                        true,
                        data,
                        false,
                        passOfferVendor?.negotiationVendor
                          ?.filter(
                            (regCode) =>
                              regCode?.companyRegCode ===
                              activeVendor?.companyRegCode
                          )
                          .map(
                            (passOfferVendors, ipassOfferVendor) =>
                              passOfferVendors
                          )
                      )
                    }
                  >
                    <BsSearch style={{ fontSize: "1rem", color: "blue" }} />
                  </button>
                </Tooltip>
              ) : (
                <>
                  <Tooltip
                    hasArrow
                    label={t("LABEL.PROCUREMENT_NEGOTIATION_VIEW_BID")}
                    placement="top"
                  >
                    <button
                      onClick={() =>
                        handleCreateNegotiationBid(
                          true,
                          data,
                          false,
                          passOfferVendor?.negotiationVendor
                            ?.filter(
                              (regCode) =>
                                regCode?.companyRegCode ===
                                activeVendor?.companyRegCode
                            )
                            .map(
                              (passOfferVendors, ipassOfferVendor) =>
                                passOfferVendors
                            )
                        )
                      }
                    >
                      <BsSearch style={{ fontSize: "1rem", color: "blue" }} />
                    </button>
                  </Tooltip>
                  <Tooltip hasArrow label={t("LABEL.REJECT")} placement="top">
                    <PrivateComponent accessName="BUYER_EDIT">
                      <button
                        onClick={() =>
                          handleUpdateRejectBidOpen(true, data, "reject")
                        }
                      >
                        <BsXLg style={{ fontSize: "1rem", color: "red" }} />
                      </button>
                    </PrivateComponent>
                  </Tooltip>
                  <Tooltip hasArrow label={t("LABEL.APPROVE")} placement="top">
                    <PrivateComponent accessName="BUYER_EDIT">
                      <button
                        onClick={() =>
                          handleUpdateApproveBidOpen(true, data, "approve")
                        }
                      >
                        <BsCheckLg
                          style={{ fontSize: "1rem", color: "green" }}
                        />
                      </button>
                    </PrivateComponent>
                  </Tooltip>
                </>
              )
            ) : (
              data?.updated_at? `${t("LABEL.PROCUREMENT_NEGOTIATION_PROPOSE_AT")} ${moment(data?.updated_at).format("LLL")}` : ""
            )}
          </div>
        )}
      </Td>
    </Tr>
  );
};

const NegotiationDetail = () => {
  const { procurement } = useContext(ProcurementDetailContext);
  const {
    activeNegotiation,
    totalDataNotes,
    limitNotes,
    tableFormNotes,
    handleCreateNotes,
    loadingNotes,
    priceGroup,
    loadingPriceGroup,
  } = useContext(NegotiationContext);
  const { t } = useTranslation();

  const { userData } = useContext(AuthContext);

  const watchTableFormNotes = tableFormNotes.watch();

  const isDone = priceGroup?.find((note) => note.status === "APPROVED");

  return (
    <div className="w-full bg-white rounded-md text-sm col-span-3">
      <div className="mt-5 flex justify-end items-center">
        {userData?.type !== "user" ? (
          <PrivateComponent accessName="BUYER_EDIT">
            <Button
              size="sm"
              colorScheme="teal"
              disabled={!activeNegotiation?.negotiationStart || isDone || procurement?.procurementWinnerProposal?.isWinner}
              onClick={() => handleCreateNotes(true)}
            >
              {t("LABEL.PROCUREMENT_NEGOTIATION_OPEN")}
            </Button>
          </PrivateComponent>
        ) : (
          ""
        )}
      </div>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.STATUS")}</Th>
            <Th className="text-red-500">
              {t("LABEL.PROCUREMENT_NEGOTIATION_INITIATOR")}
            </Th>
            <Th className="text-red-500">{t("LABEL.NOTES")}</Th>
            <Th className="text-red-500">{t("LABEL.CREATED_AT")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {userData?.type === "user" ? (
            priceGroup
              ?.filter((data, index) => data?.status !== "DRAFT")
              .sort((a, b) => b?.updated_at - a?.updated_at)
              .map((dataNote, iNote) =>
                loadingNotes ? (
                  <Tr>
                    <Td colSpan={5}>
                      <div className="w-full h-full flex justify-center items-center">
                        <Spinner color="red.500" />
                      </div>
                    </Td>
                  </Tr>
                ) : !dataNote ? (
                  <Tr>
                    <Td colSpan={5} className="text-center">
                      <p className="text-sm text-gray-500">
                        {t("TEXT.DATA_NOT_FOUND")}
                      </p>
                    </Td>
                  </Tr>
                ) : (
                  <Row
                    key={iNote}
                    data={dataNote}
                    isButtonActive={
                      priceGroup?.length <= 2 &&
                      iNote === 0 &&
                      (dataNote?.createdby || dataNote?.createdBy) ===
                        "Vendor" &&
                      iNote === 1 &&
                      (dataNote?.createdby || dataNote?.createdBy) === "Buyer"
                        ? false
                        : priceGroup?.length >= 2 &&
                          iNote > 0 &&
                          (dataNote?.createdby || dataNote?.createdBy) ===
                            "Buyer"
                        ? false
                        : true
                    }
                  />
                )
              )
          ) : loadingPriceGroup ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !priceGroup?.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">
                  {t("TEXT.DATA_NOT_FOUND")}
                </p>
              </Td>
            </Tr>
          ) : (
            priceGroup?.map((note, iNote) => (
              <Row
                key={iNote}
                data={note}
                isDone={isDone}
                isFirstPropose={priceGroup?.length}
              />
            ))
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalDataNotes}
          limit={limitNotes}
          activePage={watchTableFormNotes?.page}
          onChangePage={(page) => tableFormNotes.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default NegotiationDetail;
