import { Button, FormControl, FormLabel } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPaperPlane } from 'react-icons/bi';
import FormDescription from '../../../../../../../../../../../../components/inputForm/FormDescription';
import PrivateComponent from '../../../../../../../../../../../../components/PrivateComponent';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../../../utils/offerPhases.util';
import { ProcurementDetailContext } from '../../../../../../../Controller';
import NextStepButton from '../../../../../../NextStepButton';
import { ProcurementOfferEvaluationContext } from '../../../Controller';
import { EvaluationContext } from '../Controller';
import ActionModal from './ActionModal';

const Action = () => {
	const { t } = useTranslation();
	const { procurement } = useContext(ProcurementDetailContext);
	const { setIsActionModalOpen, setErrorNotEvaluated, evaluationType } = useContext(EvaluationContext);
	const { evaluation, procurementVendors, offerEvaluationPhase } = useContext(ProcurementOfferEvaluationContext);

	const noteKey = !evaluationType ? 'note' : evaluationType === 'COM' ? 'noteCommercial' : 'noteAdministration';
	const statusKey = !evaluationType ? 'status' : evaluationType === 'COM' ? 'statusCommercial' : 'statusAdministration';

	const handleOpen = () => {
		if (!evaluation || !procurementVendors) return;

		const qualifiedVendors = procurementVendors.filter((vendor) => {
			if (vendor.status.status !== 'ACCEPT') return false;
			const admOffer = vendor.procurementAdministrationOffer;
			const comOffer = vendor.procurementCommercialOffer;

			if (!evaluationType) {
				if (!admOffer || !comOffer) return false;
				if (admOffer.status !== 'SUBMITTED' || comOffer.status !== 'SUBMITTED') return false;
			} else {
				if (evaluationType === 'COM') {
					if (!comOffer) return false;
					if (comOffer.status !== 'SUBMITTED') return false;
				}

				if (evaluationType === 'ADM') {
					if (!admOffer) return false;
					if (admOffer.status !== 'SUBMITTED') return false;
				}
			}
			return true;
		});

		const evaluatedVendors = evaluation.procurementOfferEvaluationVendors.filter((vendor) => vendor[statusKey] !== null);

		const valid = qualifiedVendors.length === evaluatedVendors.length;

		if (!valid) {
			setErrorNotEvaluated('Semua vendor harus dievaluasi');
			return;
		} else {
			setErrorNotEvaluated('');
		}
		setIsActionModalOpen(true);
	};

	return (
		<div className="flex justify-between mt-10 items-center">
			{evaluation && evaluation[statusKey] === 'SUBMITTED' ? (
				<div className="flex flex-col w-full">
					<div className="flex justify-between mt-10 items-center">
						<FormControl className="w-1/3 ml-5">
							<FormLabel className={`text-color0 uppercase font-semibold text-xs`}>{t('LABEL.EVALUATION_NOTE')}</FormLabel>
							<FormDescription>{evaluation[noteKey]}</FormDescription>
						</FormControl>
						<div className="mr-5 text-green-500 text-xl font-bold italic">{t('LABEL.SUBMITTED')}</div>
					</div>
					{evaluationType !== 'ADM' && (
						<NextStepButton
							isDisabled={!(evaluation?.status || evaluation?.statusCommercial)}
							to={procurement?.procurementSetting?.eAuction ? 'auction' : 'negotiation'}
							url={procurement?.procurementSetting?.eAuction ? 'auction' : 'negotiation'}
							className="mt-4"
							toLabel={procurement?.procurementSetting?.eAuction ? 'Lelang' : 'Negosiasi'}
						/>
					)}
				</div>
			) : (
				<>
					{offerEvaluationPhase === OFFER_2_COVER_PHASES.POST ? (
						<div className="mr-5 ml-auto text-color2 text-xl font-bold italic">{t('TEXT.POST_EVALUATION_WARNING')}</div>
					) : (
						<PrivateComponent accessName="BUYER_EDIT" placeholder={<div className="mr-5 text-color2 text-xl font-bold italic ml-auto">Pending</div>}>
							<ActionModal />
							<Button
								className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 mr-5 bg-pos-0 hover:bg-pos-100 shadow-lg ml-auto"
								onClick={() => {
									handleOpen();
								}}
							>
								<BiPaperPlane className="text-2xl" />
								{t('LABEL.SUBMIT', { field: '' })}
							</Button>
						</PrivateComponent>
					)}
				</>
			)}
		</div>
	);
};

export default Action;
