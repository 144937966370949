import {
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Spinner,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Table,
	ModalHeader,
	ModalFooter,
	Textarea,
	FormControl,
	FormLabel,
	Button,
	FormHelperText,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiExit, BiPaperPlane } from 'react-icons/bi';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { ProcurementOfferEvaluationContext } from '../../../Controller';
import { EvaluationContext } from '../Controller';

const Row = ({ vendor, idx, evaluationVendor }) => {
	const { t } = useTranslation();
	const { evaluationType } = useContext(EvaluationContext);

	const [isQualified, setIsQualified] = useState(false);

	useEffect(() => {
		setIsQualified(false);
		if (vendor.status.status !== 'ACCEPT') return;
		const admOffer = vendor.procurementAdministrationOffer;
		const comOffer = vendor.procurementCommercialOffer;

		if (!evaluationType) {
			if (!admOffer || !comOffer) return;
			if (admOffer.status !== 'SUBMITTED' || comOffer.status !== 'SUBMITTED') return;
		} else {
			if (evaluationType === 'COM') {
				if (!comOffer) return;
				if (comOffer.status !== 'SUBMITTED') return;
			}

			if (evaluationType === 'ADM') {
				if (!admOffer) return;
				if (admOffer.status !== 'SUBMITTED') return;
			}
		}

		setIsQualified(true);
	}, [vendor]);

	const statusKey = !evaluationType ? 'status' : evaluationType === 'COM' ? 'statusCommercial' : 'statusAdministration';

	return (
		<Tr className="hover:bg-gray-50">
			<Td
				style={{
					minWidth: '75px',
					maxWidth: '75px',
					width: '75px',
				}}
			>
				{idx + 1}
			</Td>
			<Td className="w-3/6">{vendor.company?.companyName}</Td>
			<Td className="flex gap-4">
				{!isQualified ? (
					<div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div>
				) : (
					<>
						{evaluationVendor ? (
							<>
								{evaluationVendor[statusKey] === 'ACCEPTED' ? (
									<div className="font-bold italic text-green-500 ">{t('LABEL.QUALIFIED')}</div>
								) : (
									<div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div>
								)}
							</>
						) : (
							<div className="font-bold italic">{t('LABEL.NOT_QUALIFIED')}</div>
						)}
					</>
				)}
			</Td>
		</Tr>
	);
};

const ActionModal = () => {
	const { t } = useTranslation();
	const { isActionModalOpen, setIsActionModalOpen, errorEvaluationNote, evaluationNote, loadingSubmitEvaluation, submitEvaluation, setEvaluationNote } = useContext(EvaluationContext);
	const { procurementVendors, loadingProcurementVendors, evaluation, setEvaluation } = useContext(ProcurementOfferEvaluationContext);

	const existInEvaluation = (vendor) => {
		for (let i = 0; i < evaluation.procurementOfferEvaluationVendors.length; i++) {
			const evalVendor = evaluation.procurementOfferEvaluationVendors[i];

			if (evalVendor.procurementVendorId === vendor.procurementVendorId) {
				return evalVendor;
			}
		}

		return null;
	};

	return (
		<Modal isOpen={isActionModalOpen} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('LABEL.PROCUREMENT_BID_EVALUATION')}</ModalHeader>
				<ModalBody>
					<div>
						<TitleBar title={t('LABEL.VENDOR')} />
						<Table>
							<Thead>
								<Tr>
									<Th
										style={{
											minWidth: '75px',
											maxWidth: '75px',
											width: '75px',
										}}
										className="text-red-500"
									>
										No.
									</Th>
									<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
									<Th className="text-red-500">{t('LABEL.ACTION')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{loadingProcurementVendors || !procurementVendors ? (
									<Tr>
										<Td colSpan={3}>
											<div className="w-full h-full flex justify-center items-center">
												<Spinner color="red.500" />
											</div>
										</Td>
									</Tr>
								) : (
									<>
										{procurementVendors.length === 0 ? (
											<Tr>
												<Td colSpan={3} className="text-center">
													<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
												</Td>
											</Tr>
										) : (
											procurementVendors.map((vendor, idx) => {
												const evaluationVendor = existInEvaluation(vendor);
												return <Row vendor={vendor} idx={idx} key={idx} evaluationVendor={evaluationVendor} />;
											})
										)}
									</>
								)}
							</Tbody>
						</Table>
					</div>
					<div className="text-base mt-5">
						{t('TEXT.MAKE_SURE_WARNING_EVALUATION')} <strong className="italic text-color0">{t('LABEL.CANNOT_CANCEL')}</strong>.
					</div>
					<div className="mt-5">
						<FormControl isRequired>
							<FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.EVALUATION_NOTE")}</FormLabel>
							<Textarea
								placeholder={t("LABEL.EVALUATION_NOTE")}
								className={`${errorEvaluationNote !== '' ? 'border-2 border-color0' : ''}`}
								value={evaluationNote}
								onChange={(e) => {
									setEvaluationNote(e.target.value);
								}}
							/>
							{errorEvaluationNote !== '' && <FormHelperText className="text-red-400 text-xs">{errorEvaluationNote}</FormHelperText>}
						</FormControl>
					</div>
				</ModalBody>
				<ModalFooter className="flex gap-6">
					<Button
						className="text-black font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gray-200 hover:bg-gray-300"
						onClick={() => {
							setIsActionModalOpen(false);
						}}
						isDisabled={loadingSubmitEvaluation}
					>
						<BiExit className="text-2xl" />
						{t('LABEL.CANCEL', { field: '' })}
					</Button>
					<Button
						className="text-white font-semibold transition-all duration-500 flex gap-1 items-center justify-center bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
						isLoading={loadingSubmitEvaluation}
						onClick={() => {
							submitEvaluation();
						}}
					>
						<BiPaperPlane className="text-2xl" />
						{t('LABEL.SUBMIT', { field: '' })}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ActionModal;
