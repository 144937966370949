import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftCircle } from "react-icons/bs";
import { ReqRequisitionContext } from "../Controller";
import Form from "./Form";

const ReqRequisitionNew = () => {
  const { t } = useTranslation();
  const { form, handleCreate, loadingCreate, createReqRequisition } = useContext(ReqRequisitionContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <Form isEdit={true} />

        <div className="md:col-span-2  flex justify-end">
          <Button
            size="sm"
            colorScheme="teal"
            isLoading={loadingCreate}
            onClick={form.handleSubmit(createReqRequisition)}
          >
            {t("LABEL.SAVE")}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default ReqRequisitionNew;
