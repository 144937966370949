import axios from 'axios';
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../../../../utils/apiURLs.util';
import { OFFER_2_COVER_PHASES, OFFER_2_STEP_PHASES } from '../../../../../../../../utils/offerPhases.util';
import { ProcurementDetailContext } from '../../../Controller';

export const ProcurementOfferContext = createContext();
const { Provider } = ProcurementOfferContext;

const ProcurementOfferController = ({ children }) => {
	const { t } = useTranslation();
	const { httpRequest, showToast } = useContext(HelperContext);
	const [activePageIdx, setActivePageIdx] = useState(0);
	const { userData } = useContext(AuthContext);
	const { procurement, vendorStatus } = useContext(ProcurementDetailContext);
	const [loadingCommercialOffer, setLoadingCommercialOffer] = useState(false);
	const [loadingAdministratifOffer, setLoadingAdministratifOffer] = useState(false);
	const [commercialOffer, setCommercialOffer] = useState(undefined);
	const [administratifOffer, setAdministratifOffer] = useState(undefined);
	const [isNewAdministratifOffer, setIsNewAdministratifOffer] = useState(false);
	const [isNewCommercialOffer, setIsNewCommercialOffer] = useState(false);

  const administratifForm = useForm();
  const commercialForm = useForm();
  const [priceInputs, setPriceInputs] = useState(null); // FOR COMMERCIAL OFFER
  const [errors, setErrors] = useState(null); // priceInputs Erros

	// PROCUREMENT MATERIALS
	const [materials, setMaterials] = useState(null);
	const [loadingMaterials, setLoadingMaterials] = useState(false);
	useEffect(async () => {
		if (!procurement) return;

		let reqRequisitionCodes = '';
		procurement.procurementRequisitionMaterials.forEach((req, idx) => {
			const id = req.requisitionMaterial.reqRequisitionMaterialId;
			if (idx !== procurement.procurementRequisitionMaterials.length - 1) {
				reqRequisitionCodes += id + ';';
			} else {
				reqRequisitionCodes += id;
			}
		});
		setLoadingMaterials(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/req-requisition/by-code?code=${reqRequisitionCodes}`,
		});
		setLoadingMaterials(false);

		if (!response?.reqRequisitions) {
			showToast(t('ERROR.undefined'), { success: false });
			return;
		}

		const reqRequisitions = response.reqRequisitions;
		if (reqRequisitions.length > 0) {
			let mats = [];
			for (let i = 0; i < reqRequisitions.length; i++) {
				const req = reqRequisitions[i];
				for (let i = 0; i < req.reqRequisitionMaterial.length; i++) {
					const reqMat = req.reqRequisitionMaterial[i];
					const tempProcurementRequisitionMaterial = procurement.procurementRequisitionMaterials.find((procurementRequisitionMaterial) => {
						return procurementRequisitionMaterial.requisitionMaterial.reqRequisitionMaterialId === reqMat.reqRequisitionMaterialId;
					});

					const temp = {
						id: reqMat.material.materialId,
						qty: reqMat.quantity,
						price: reqMat.price,
						description: reqMat.material.description,
						unit: reqMat.material.measureUnit?.unit,
						unitDescription: reqMat.material.measureUnit?.description,
						currency: reqMat.currency,
						procurementRequisitionMaterialId: tempProcurementRequisitionMaterial.procurementRequisitionMaterialId,
					};
					mats.push(temp);
				}
			}
			setMaterials(mats);
		}
	}, [procurement]);

  // Price Inputs && Errors Setup
  useEffect(() => {
		if (!materials) return;
		let tempInputs = [];
		let tempErrors = [];
		for (let i = 0; i < materials.length; i++) {
			tempInputs.push('');
			tempErrors.push(null);
		}
		setPriceInputs(tempInputs);
		setErrors(tempErrors);
	}, [materials]);

	useEffect(async () => {
		if (!userData || !procurement || !vendorStatus || vendorStatus.status === 'DRAFT') return;

		let mounted = true;

		const regCode = userData.company.registrationCode;
		const procNum = procurement.procurementNumber;

		setLoadingAdministratifOffer(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-administration-offer/by/${procNum}/${regCode}`,
		});
		if (!mounted) return;
		setLoadingAdministratifOffer(false);

		if (!response?.success) {
			if (response?.statusCode === 404) {
				setIsNewAdministratifOffer(true);
				setAdministratifOffer(null);
				return;
			}
			// showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		setIsNewAdministratifOffer(false);
		setAdministratifOffer(response.data?.procurementAdministrationOffer);

		return () => {
			mounted = false;
		};
	}, [procurement, userData, vendorStatus]);

	useEffect(async () => {
		if (!userData || !procurement || !vendorStatus || vendorStatus.status === 'DRAFT') return;

		let mounted = true;

		const regCode = userData.company.registrationCode;
		const procNum = procurement.procurementNumber;

		setLoadingCommercialOffer(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-commercial-offer/by/${procNum}/${regCode}`,
		});
		if (!mounted) return;
		setLoadingCommercialOffer(false);

		if (!response?.success) {
			if (response?.statusCode === 404) {
				setIsNewCommercialOffer(true);
				setCommercialOffer(null);
				return;
			}
			// showToast(t('ERROR.undefined'), { success: false });
			return;
		}
		setIsNewCommercialOffer(false);
		setCommercialOffer(response.data?.procurementCommercialOffer);

		return () => {
			mounted = false;
		};
	}, [procurement, userData, vendorStatus]);

	useEffect(() => {
		if (administratifOffer) {
			setIsNewAdministratifOffer(false);
		}
	}, [administratifOffer]);

	useEffect(() => {
		if (commercialOffer) {
			setIsNewCommercialOffer(false);
		}
	}, [commercialOffer]);

	// PROCUREMENT SCHEDULE
	const [procurementSchedule, setProcurementSchedule] = useState(null);
	const [loadingProcurementSchedule, setLoadingProcurementSchedule] = useState(null);
	useEffect(async () => {
		if (!procurement) return;

		setLoadingProcurementSchedule(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule/${procurement.procurementNumber}`,
		});
		setLoadingProcurementSchedule(false);

		if (!response?.procurementSchedule) {
			setProcurementSchedule(null);
			return;
		}

		setProcurementSchedule(response.procurementSchedule[0]);
	}, [procurement]);

	// PROCUREMENT EXTENDED SCHEDULE FOR OFFER
	const [extendedSchedule, setExtendedSchedule] = useState(null);
	const [loadingExtendedSchedule, setLoadingExtendedSchedule] = useState(false);
	useEffect(async () => {
		if (!procurement) return;

		let mounted = true;

		setLoadingExtendedSchedule(true);
		const { response } = await httpRequest({
			url: `${API_URLS.REACT_APP_TMS_API}/procurement-schedule-extend-offer/proc/${procurement.procurementId}`,
		});
		if (!mounted) return;
		setLoadingExtendedSchedule(false);
		if (!response?.success) {
			// TODO: Error
			return;
		}

		setExtendedSchedule(response?.data.procurementScheduleExtendOffer);

		return () => (mounted = false);
	}, [procurement]);

	// VALID SCHEDULE
	const [schedule, setSchedule] = useState(null);
	const [loadingSchedule, setLoadingSchedule] = useState(false);
	useEffect(() => {
		if (loadingProcurementSchedule || loadingExtendedSchedule) {
			setLoadingSchedule(true);
			return;
		} else {
			setLoadingSchedule(false);
		}

		if (!extendedSchedule) {
			setSchedule(procurementSchedule);
			return;
		}
		setSchedule(extendedSchedule);
	}, [procurementSchedule, extendedSchedule, loadingProcurementSchedule, loadingExtendedSchedule]);

	// Offer type
	const [is2Cover, setIs2Cover] = useState(null);
	useEffect(() => {
		setIs2Cover(null);
		if (!procurement) return;

		setIs2Cover(procurement.bidEntryMethod?.bidEntryMethod === '2 COVER');
	}, [procurement]);

	// OFFER PHASE
	const [offerPhase, setOfferPhase] = useState(OFFER_2_COVER_PHASES.PRA);
	useEffect(() => {
		setOfferPhase(OFFER_2_COVER_PHASES.PRA);
		if (!schedule || is2Cover === null || !vendorStatus || vendorStatus.status === 'DRAFT') return;

		if (is2Cover) {
			const start = schedule.quotationStart;
			const end = schedule.quotationEnd;

			if (!start || !end) {
				return;
			}

			const startDate = moment(start).unix();
			const endDate = moment(end).unix();
			const nowDate = moment().unix();

			if (nowDate < startDate) {
				setOfferPhase(OFFER_2_COVER_PHASES.PRA);
				return;
			}
			if (nowDate >= startDate && nowDate < endDate) {
				setOfferPhase(OFFER_2_COVER_PHASES.TIME);
				return;
			}
			setOfferPhase(OFFER_2_COVER_PHASES.POST);
			return;
		}

		const startTech = schedule.technicalQuotationStart;
		const endTech = schedule.technicalQuotationEnd;
		const startComm = schedule.commercialQuotationStart;
		const endComm = schedule.commercialQuotationEnd;

		// console.log(startTech);
		// console.log(endTech);
		// console.log(startComm);
		// console.log(endComm);

		let phase = OFFER_2_STEP_PHASES.PRA;

		if (!endTech || !startTech) {
			// console.log(1);
			phase = OFFER_2_STEP_PHASES.PRA;
		} else {
			if (!endComm || !startComm) {
				// console.log(2);
				phase = OFFER_2_STEP_PHASES.TECHNICAL;
			} else {
				const startTechDate = moment(startTech).unix();
				const endTechDate = moment(endTech).unix();
				const startCommDate = moment(startComm).unix();
				const endCommDate = moment(endComm).unix();
				const nowDate = moment().unix();

				if (nowDate > endCommDate) {
					// console.log(3);
					phase = OFFER_2_STEP_PHASES.POST;
				} else {
					if (nowDate > startCommDate) {
						// console.log(4);
						phase = OFFER_2_STEP_PHASES.COMMERCIAL;
					} else {
						if (nowDate > startTechDate) {
							// console.log(5);
							phase = OFFER_2_STEP_PHASES.TECHNICAL;
						} else {
							// console.log(6);
							phase = OFFER_2_STEP_PHASES.PRA;
						}
					}
				}
			}
		}

		setOfferPhase(phase);
	}, [schedule, vendorStatus]);

	return (
		<Provider
			value={{
				activePageIdx,
				setActivePageIdx,
				administratifOffer,
				setAdministratifOffer,
				commercialOffer,
				setCommercialOffer,
				loadingAdministratifOffer,
				loadingCommercialOffer,
				isNewAdministratifOffer,
				setIsNewAdministratifOffer,
				isNewCommercialOffer,
				setIsNewCommercialOffer,

				materials,
				setMaterials,
				loadingMaterials,
				setLoadingMaterials,

				procurementSchedule,
				setProcurementSchedule,
				loadingProcurementSchedule,
				setLoadingProcurementSchedule,

				is2Cover,
				setIs2Cover,
				offerPhase,
				setOfferPhase,

				loadingExtendedSchedule,
				setLoadingExtendedSchedule,
				extendedSchedule,
				setExtendedSchedule,
				loadingSchedule,
				setLoadingSchedule,
				schedule,
				setSchedule,

        commercialForm,
        administratifForm,
        priceInputs, setPriceInputs,
        errors, setErrors
			}}
		>
			{children}
		</Provider>
	);
};

export default ProcurementOfferController;
