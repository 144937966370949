import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCaretRightFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { userRouteSlug } from "../../../../../routes/userRoutes";
import { AdminContext } from "../../controllers/AdminController";
import _ from "lodash";
import { BiError } from "react-icons/bi";
import { Spinner } from "@chakra-ui/react";

const CompanyToApprove = () => {
  const { t } = useTranslation();
  const { companiesToApprove, loadingCompaniesToApprove } = useContext(AdminContext);

  return (
    <div className="bg-white p-4 rounded-md">
      <p className="font-semibold text-gray-600">{t("TEXT.COMPANY_PROFILE_APPROVAL")}</p>
      <hr className="my-2" />
      <div className="w-full flex flex-col ">
        {loadingCompaniesToApprove ? (
          <div className="p-2 flex justify-center items-center">
            <Spinner color="red.500" />
          </div>
        ) : !companiesToApprove.length ? (
          <div className="p-2 text-center">
            <span className="text-gray-400">{t("TEXT.NO_VENDOR_APPROVAL_FOUND")}</span>
          </div>
        ) : (
          companiesToApprove.map((company, iCompany, arrCompany) => (
            <Fragment key={iCompany}>
              <Link
                to={`${userRouteSlug.PANEL}${userRouteSlug.MASTER_VENDOR}/${company?.registrationCode}/profile/general`}
                className="p-3 px-4 hover:bg-gray-50"
              >
                <div className="flex justify-between items-center">
                  <div className="w-full flex items-center font-semibold text-gray-600">
                    <span className="flex-1">{company?.companyName}</span>
                    <span className="flex-1">
                      {_.startCase(company?.city?.toLowerCase())}, {company?.country}
                    </span>
                    <span className="flex-1">{t(company?.vendorStatus?.labelI18n)}</span>
                    <span className="flex-1 flex justify-center items-center gap-1 text-yellow-500">
                      <BiError />
                      {t("TEXT.NEED_APPROVAL")}
                    </span>
                  </div>
                  <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
                </div>
              </Link>

              {iCompany !== arrCompany.length - 1 && <hr />}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
};

export default CompanyToApprove;
