import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import DeedDetail from "./components/DeedDetail";
import DeedList from "./components/DeedList";
import DeedNew from "./components/DeedNew";
import DeedController, { DeedContext } from "./Controller";

const Deed = () => {
  const { isDetailMenu, isCreate } = useContext(DeedContext);

  return (
    <div>
      <AnimatePresence>{isCreate ? <DeedNew /> : isDetailMenu ? <DeedDetail /> : <DeedList />}</AnimatePresence>
    </div>
  );
};

export default () => (
  <DeedController>
    <Deed />
  </DeedController>
);
