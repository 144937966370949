import { Checkbox } from "@chakra-ui/react";
import { InputText } from "primereact/inputtext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import CheckboxGroup from "../../../../../components/inputForm/CheckboxGroup";
import Select from "../../../../../components/inputForm/Select";
import { VendorContext } from "../Controller";

const VendorFilter = () => {
  const { t } = useTranslation();
  const { tableForm, countries, vendorStatus } = useContext(VendorContext);
  const { register, control } = tableForm;

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 flex flex-col gap-4">
      {/* Search Bar */}
      <div className="p-input-icon-right">
        <InputText className={`w-full text-sm`} placeholder={t("LABEL.SEARCH")} {...register("search")} />
        <BiSearch />
      </div>

      <hr />

      {/* Country */}

      <Select
        filter
        showClear
        control={control}
        name="country"
        options={countries.map((country) => ({
          label: country?.description,
          value: country?.regionCode,
        }))}
        placeholder={`${t("LABEL.SEARCH")} ${t("LABEL.COUNTRY")}`}
      />

      <hr />

      {/* Vendor Status */}
      <div>
        <p className="text-xs text-gray-500 mb-2">{t("LABEL.VENDOR_STATUS")}</p>
        <CheckboxGroup name="vendorStatus" control={control}>
          {vendorStatus.map((status, iStatus) => (
            <Checkbox className="m-1" key={iStatus} value={status?.vendorStatus}>
              <span className="text-sm text-gray-600">{t(status?.labelI18n || status?.label)}</span>
            </Checkbox>
          ))}
        </CheckboxGroup>
      </div>
    </div>
  );
};

export default VendorFilter;
