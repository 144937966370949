import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DocumentContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { BiDownload } from "react-icons/bi";
import FormDescription from "../../../../../../../../../components/inputForm/FormDescription";
import Uploader from "../../../../../../../../../components/inputForm/Uploader";
import { FileserviceContext } from "../../../../../../../../../controllers/FileserviceController";
import SelectButton from "../../../../../../../../../components/inputForm/SelectButton";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, activeDocument, form } = useContext(DocumentContext);
  const {
    register,
    setValue,
    trigger,
    control,
    watch,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.DOCUMENT") }) }),
    },
  });

  const isPublic = form?.getValues("isPublic");

  return (
    <>
      {/* Title */}
      <FormControl isRequired={isEdit}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DOCUMENT_TITLE")} ({isPublic ? t("LABEL.PUBLIC") : "Internal"})
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.title && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DOCUMENT_TITLE")}
            {...register("title", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.DOCUMENT_TITLE") }),
            })}
          />
        ) : (
          <FormDescription>{activeDocument?.title}</FormDescription>
        )}
        {errors?.title && <FormHelperText className="text-red-400 text-xs">{errors?.title?.message}</FormHelperText>}
      </FormControl>

      {/* Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.DOCUMENT") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.DOCUMENT"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeDocument?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Is Public */}
      {/* <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs`}>{t("LABEL.PUBLIC")}</FormLabel>
        <SelectButton
          className="w-full grid grid-cols-2"
          control={control}
          name="isPublic"
          value={form?.getValues("isPublic")}
          options={[
            { label: t("LABEL.YES"), value: true },
            { label: t("LABEL.NO"), value: false },
          ]}
        />
        {errors?.isPublic && (
          <FormHelperText className="text-red-400 text-xs">{errors?.isPublic?.message}</FormHelperText>
        )}
      </FormControl> */}
    </>
  );
};

export default Form;
