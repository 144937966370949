import { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ProcurementOfferContext } from '../../Controller';

export const AdministratifContext = createContext();
const { Provider } = AdministratifContext;

const AdministratifController = ({ children }) => {
	const { administratifOffer, administratifForm } = useContext(ProcurementOfferContext);

	const [isEdit, setIsEdit] = useState(true);
	const [requirements, setRequirements] = useState(null);

	const form = administratifForm;

	useEffect(() => {
		if (administratifOffer) {
			setIsEdit(false);
		}
	}, [administratifOffer]);

	return <Provider value={{ isEdit, form, setIsEdit, requirements, setRequirements }}>{children}</Provider>;
};

export default AdministratifController;
