import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/auth/AuthController";

export const RequisitionerContext = createContext();
const { Provider } = RequisitionerContext;

const RequisitionerController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        requisitionerCode: newForm?.requisitionerCode,
        description: newForm?.description,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeRequisitioner, setActiveRequisitioner] = useState();
  const handleDetailMenu = (isOpen, data = null) => {
    setDetailMenu(isOpen);
    setActiveRequisitioner({ ...data, password: "....." });
    isOpen && resetForm(data);
  };

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadRequisitioner(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Requisition
  const [requisitioner, setRequisitioner] = useState([]);
  const [loadingRequisitioner, setLoadingRequisitioner] = useState(false);
  const [reloadRequisitioner, setReloadRequisitioner] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const filter = `?${search}&${limit}&${page}`;

    const getRequisitioner = async () => {
      setLoadingRequisitioner(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/requisitioner${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newRequisition = res?.data?.requisitioners;
            setRequisitioner(newRequisition);
            setTotalData(res?.data?.pagination?.totalData);
            if (activeRequisitioner) {
              const newActiveRequisition =
                newRequisition.find(
                  (requisitioner) =>
                    requisitioner?.requisitionerId ===
                    activeRequisitioner?.requisitionerId
                ) || null;
              setActiveRequisitioner(newActiveRequisition);
              resetForm(newActiveRequisition);
            }
          }
        })
        .finally(() => {
          setReloadRequisitioner(false);
          setLoadingRequisitioner(false);
        });
    };

    token && reloadRequisitioner && getRequisitioner();

    return () => {
      mounted = false;
    };
  }, [token, reloadRequisitioner]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createRequisitioner = (payload) => {
    setLoadingCreate(true);

    axios
      .post(`${process.env.REACT_APP_TMS_API}/requisitioner`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log(res);
        if (res.data.error === "") {
          toast({
            description: t("INVALID.AVAILABLE", {
              field: t("LABEL.REQUISITIONER_CODE"),
            }),
            position: "top-right",
            status: "error",
            isClosable: true,
          });
          setCreate(true);
        } else {
          toast({
            description: t(res?.data?.success),
            position: "top-right",
            status: "success",
            isClosable: true,
          });
          setCreate(false);
        }
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
        setReloadRequisitioner(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeRequisitioner);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateRequisitioner = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/requisitioner/${activeRequisitioner?.requisitionerId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadRequisitioner(true);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteRequisitioner = () => {
    setLoadingDelete(true);

    axios
      .delete(
        `${process.env.REACT_APP_TMS_API}/requisitioner/${activeRequisitioner?.requisitionerId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadRequisitioner(true);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);


  // REQUISITIONER CODE Validation
  const [isValidRequisitionerCode, setIsValidRequisitionerCode] = useState(false);
  const [loadingValidationRequisitionerCode, setLoadingValidationRequisitionerCode] = useState(false);

  return (
    <Provider
      value={{
        tableForm,
        requisitioner,
        loadingRequisitioner,
        totalData,
        limit,

        form,
        activeRequisitioner,

        isCreate,
        handleCreate,
        loadingCreate,
        createRequisitioner,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateRequisitioner,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteRequisitioner,

        isValidRequisitionerCode, 
        setIsValidRequisitionerCode,
        loadingValidationRequisitionerCode,
        setLoadingValidationRequisitionerCode
      }}
    >
      {children}
    </Provider>
  );
};

export default RequisitionerController;
