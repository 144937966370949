import { useToast } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { HelperContext } from "../../../../../../../../controllers/HelperController";
import { API_URLS } from "../../../../../../../../utils/apiURLs.util";
import { ProcurementDetailContext } from "../../../Controller";

export const ProcScheduleContext = createContext();
const { Provider } = ProcScheduleContext;

const ProcSchedule = ({ children }) => {
  const toast = useToast();
  const {showToast, httpRequest} = useContext(HelperContext);
  const { token, userData } = useContext(AuthContext);
  const { t } = useTranslation();
  const { procurement, duration } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;
  const durationSchedule = duration[0];

	const [vendors, setVendors] = useState();

	useEffect(async () => {
    if (!procurement || !userData || userData?.type === "user") return;
    let mounted = true;
    const {response} = await httpRequest({
      url: `${API_URLS.REACT_APP_TMS_API}/procurement-vendor/${procurement?.procurementNumber}`
    })
    if (!mounted) return;

    if (!response?.procurementVendors){
      // TODO: Error
      return;
    }

    setVendors(response.procurementVendors);
    return () => mounted = false;
  }, [procurement]);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        registrationStart: newForm?.registrationStart
          ? new Date(newForm?.registrationStart)
          : null,
        registrationEnd: newForm?.registrationEnd
          ? new Date(newForm?.registrationEnd)
          : null,
      });
    }
  };

  //Prequalification Init
  const prequalificationForm = useForm();
  const resetprequalificationForm = (newForm) => {
    if (newForm) {
      prequalificationForm.reset({
        registrationStart: newForm?.registrationStart
          ? new Date(newForm?.registrationStart)
          : null,
        registrationEnd: newForm?.registrationEnd
          ? new Date(newForm?.registrationEnd)
          : null,
        prequalificationStart: newForm?.prequalificationStart
          ? new Date(newForm?.prequalificationStart)
          : null,
        prequalificationEnd: newForm?.prequalificationEnd
          ? new Date(newForm?.prequalificationEnd)
          : null,
      });
    }
  };

  //Aanwijzing Init
  const aanwijzingForm = useForm();
  const resetaanwijzingForm = (newForm) => {
    if (newForm) {
      aanwijzingForm.reset({
        registrationStart: newForm?.registrationStart
          ? new Date(newForm?.registrationStart)
          : null,
        registrationEnd: newForm?.registrationEnd
          ? new Date(newForm?.registrationEnd)
          : null,
        aanwijzingStart: newForm?.aanwijzingStart
          ? new Date(newForm?.aanwijzingStart)
          : null,
        aanwijzingEnd: newForm?.aanwijzingEnd
          ? new Date(newForm?.aanwijzingEnd)
          : null,
      });
    }
  };

  //Quotation Init
  const quotationForm = useForm();
  const resetquotationForm = (newForm) => {
    if (newForm) {
      quotationForm.reset({
        registrationStart: newForm?.registrationStart
          ? new Date(newForm?.registrationStart)
          : null,
        registrationEnd: newForm?.registrationEnd
          ? new Date(newForm?.registrationEnd)
          : null,
        quotationStart: newForm?.quotationStart
          ? new Date(newForm?.quotationStart)
          : null,
        quotationEnd: newForm?.quotationEnd
          ? new Date(newForm?.quotationEnd)
          : null,
      });
    }
  };

  //Technical Quotation Init
  const technicalquotationForm = useForm();
  const resettechnicalquotationForm = (newForm) => {
    if (newForm) {
      technicalquotationForm.reset({
        registrationStart: newForm?.registrationStart
          ? new Date(newForm?.registrationStart)
          : null,
        registrationEnd: newForm?.registrationEnd
          ? new Date(newForm?.registrationEnd)
          : null,
        technicalQuotationStart: newForm?.technicalQuotationStart
          ? new Date(newForm?.technicalQuotationStart)
          : null,
        technicalQuotationEnd: newForm?.technicalQuotationEnd
          ? new Date(newForm?.technicalQuotationEnd)
          : null,
      });
    }
  };

  //Commercial Quotation Init
  const commercialquotationForm = useForm();
  const resetcommercialquotationForm = (newForm) => {
    if (newForm) {
      commercialquotationForm.reset({
        registrationStart: newForm?.registrationStart
          ? new Date(newForm?.registrationStart)
          : null,
        registrationEnd: newForm?.registrationEnd
          ? new Date(newForm?.registrationEnd)
          : null,
        commercialQuotationStart: newForm?.commercialQuotationStart
          ? new Date(newForm?.commercialQuotationStart)
          : null,
        commercialQuotationEnd: newForm?.commercialQuotationEnd
          ? new Date(newForm?.commercialQuotationEnd)
          : null,
      });
    }
  };

  //Sign Init
  const signForm = useForm();
  const resetsignForm = (newForm) => {
    if (newForm) {
      signForm.reset({
        commercialQuotationStart: newForm?.commercialQuotationStart
          ? new Date(newForm?.commercialQuotationStart)
          : null,
        commercialQuotationEnd: newForm?.commercialQuotationEnd
          ? new Date(newForm?.commercialQuotationEnd)
          : null,
        proposedBy: newForm?.proposedBy ? newForm?.proposedBy : null,
        proposedPosition: newForm?.proposedPosition
          ? newForm?.proposedPosition
          : null,
        approvedBy: newForm?.approvedBy ? newForm?.approvedBy : null,
        approvedPosition: newForm?.approvedPosition
          ? newForm?.approvedPosition
          : null,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeProcSchedule, setActiveProcSchedule] = useState();
  const handleDetailMenu = (isOpen, data) => {
    setDetailMenu(isOpen);
    setActiveProcSchedule({ ...data });
    isOpen && resetForm(data);
  };

  // -------------------------------
  // Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const [isCreatePrequalification, setCreatePrequalification] = useState(false);
  const [isCreateAanwijzing, setCreateAanwijzing] = useState(false);
  const [isCreateQuotation, setCreateQuotation] = useState(false);
  const [isCreateTechnicalQuotation, setCreateTechnicalQuotation] =
    useState(false);
  const [isCreateCommercialQuotation, setCreateCommercialQuotation] =
    useState(false);
  const [isCreateSign, setCreateSign] = useState(false);

  // Filter
  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;
    let mounted = true;
    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      if (!mounted) return;
      setReloadSchedule(true);
    }, 500);
    setFilterTimeput(newTimeout);
    return () => mounted = false;
  }, [watch]);

  const [schedules, setSchedules] = useState([]);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [reloadSchedule, setReloadSchedule] = useState(true);
  const [isExistSchedule, setIsExistSchedule] = useState(false);
  const [isSign, setIsSign] = useState(true);

  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const limitx = `limit=${limit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limitx}&${page}`;

    const getProcurementSchedule = async () => {
      setLoadingSchedules(true);

      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}${filter}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newSchedule = res?.data?.procurementSchedule;
            setSchedules(newSchedule);
            if (!newSchedule.length === 0) {
              setCreate(true);
              resetForm(newSchedule);
            } else {
              setCreate(false);
              setCreatePrequalification(false);
              setCreateAanwijzing(false);
              setCreateQuotation(false);
              setCreateTechnicalQuotation(false);
              setCreateCommercialQuotation(false);
              setCreateSign(false);
              newSchedule[0]?.approvedBy ? setIsSign(false) : setIsSign(true);
            }
            setTotalData(res?.data?.pagination?.totalData);
            procurement.procurementNumber ===
            res?.data?.procurementSchedule[0]?.procurement?.procurementNumber
              ? setIsExistSchedule(true)
              : setIsExistSchedule(false);
          }
        })
        .finally(() => {
          setReloadSchedule(false);
          setLoadingSchedules(false);
        });
    };

    procurementNumber && reloadSchedule && getProcurementSchedule();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadSchedule]);

  // SCHEDULE STATUS
  const [scheduleStatus, setScheduleStatus] = useState({});
  useEffect(() => {
    setScheduleStatus({
      submittedAt: schedules?.[0]?.submittedAt,
      shouldBeReview: schedules?.[0]?.shouldBeReview
    })
  }, [schedules]);

  // -------------------------------
  // Create
  // -------------------------------

  const [loadingCreate, setLoadingCreate] = useState(false);
  const handleCreate = (isNewCreate, data) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({ ...data[0] });
  };
  const createProcSchedule = (payload) => {

    // Schedule Validation
    const regStart = moment(payload?.registrationStart).unix();
    const regEnd = moment(payload?.registrationEnd).unix();
    const now = moment().add(1, 'minutes').unix();
    if (regStart !== moment(schedules[0]?.registrationStart).unix() && regStart < now){
      toast({
        description: "Waktu pendaftaran tidak boleh lebih kecil dari waktu sekarang",
        position: "top-right",
        status: "error",
        isClosable: true,
      });     
      // return form.setError('registrationStart', {message: 'Waktu pendaftaran tidak boleh lebih kecil dari waktu sekarang'});        
    } else 
    if (regEnd !== moment(schedules[0]?.registrationEnd).unix() && regEnd< now){
      toast({
        description: "Waktu pendaftaran tidak boleh lebih kecil dari waktu sekarang",
        position: "top-right",
        status: "error",
        isClosable: true,
      });     
      // return form.setError('registrationEnd', {message: 'Waktu pendaftaran tidak boleh lebih kecil dari waktu sekarang'});
    } else 
    if (regStart > regEnd){
      toast({
        description: "Mulai pendaftaran harus lebih kecil",
        position: "top-right",
        status: "error",
        isClosable: true,
      });     
      // return form.setError('registrationStart', {message: 'Mulai pendaftaran harus lebih kecil'});
    } else {
    // End Schedule Validation
  
    setLoadingCreate(true);

    let aanwijzingStartCalculate = new Date(
      payload?.registrationStart
        ? moment(new Date(payload?.registrationStart))
            .add(durationSchedule.aanwijzing, "days")
            .format()
        : moment(new Date(schedules[0].registrationStart))
            .add(durationSchedule.aanwijzing, "days")
            .format()
    );

    let aanwijzingEndCalculate = new Date(
      moment(new Date(aanwijzingStartCalculate))
        .add(durationSchedule.aanwijzing, "days")
        .format()
    );

    let downloadDocTenderStartCalculate = new Date(
      payload?.registrationStart
        ? moment(new Date(payload?.registrationStart))
            .add(durationSchedule.download_document, "days")
            .format()
        : moment(new Date(schedules[0].registrationStart))
            .add(durationSchedule.download_document, "days")
            .format()
    );

    const newPayload = {
      registrationStart: payload?.registrationStart
        ? moment(payload?.registrationStart).format()
        : schedules[0].registrationStart,
      registrationEnd: payload?.registrationEnd
        ? moment(payload?.registrationEnd).format()
        : schedules[0].registrationEnd,
      aanwijzingStart: moment(aanwijzingStartCalculate).format(),
      aanwijzingEnd: moment(aanwijzingEndCalculate).format(),
      downloadDocTenderStart: payload?.registrationStart
        ? moment(payload?.registrationStart).format()
        : schedules[0].registrationStart,
      downloadDocTenderEnd: moment(downloadDocTenderStartCalculate).format(),

      proposedBy: schedules[0]?.proposedBy,
      proposedPosition: schedules[0]?.proposedPosition,
      approvedBy: schedules[0]?.approvedBy,
      approvedPosition: schedules[0]?.approvedPosition,
    };

    schedules.length === 0
      ? axios
          .post(
            `${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurement.procurementNumber}`,
            newPayload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (!res?.data?.success){
              showToast('Gagal membuat jadwal', {success: false});
            }
            else{
              toast({
                description: t(res?.data?.success),
                position: "top-right",
                status: "success",
                isClosable: true,
              });
            }
            setCreate(false);
            setLoadingCreatePrequalification(false);
          })
          .catch(() => {
            toast({
              title: "System Error",
              description: t("ERROR.undefined"),
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingCreate(false);
            setLoadingCreatePrequalification(false);
            setReloadSchedule(true);
          })
      : axios
          .patch(
            `${process.env.REACT_APP_TMS_API}/procurement-schedule/${schedules[0]?.procurementScheduleId}`,
            newPayload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (!res?.data?.success){
              showToast('Jadwal gagal diubah', {success: false});
            }
            else{
              toast({
                description: t(res?.data?.success),
                position: "top-right",
                status: "success",
                isClosable: true,
              });
            }
            setEdit(false);
          })
          .catch(() => {
            toast({
              title: "System Error",
              description: t("ERROR.undefined"),
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingUpdate(false);
            setLoadingCreate(false);
            setLoadingCreatePrequalification(false);
            setReloadSchedule(true);
          });
        }
  };

  //Prequalification
  const [loadingCreatePrequalification, setLoadingCreatePrequalification] =
    useState(false);
  const handleCreatePrequalification = (isNewCreatePrequalification, data) => {
    setCreatePrequalification(isNewCreatePrequalification);
    isNewCreatePrequalification && resetprequalificationForm({ ...data[0] });
  };
  const createPrequalificationProcSchedule = (payload) => {
    setLoadingCreatePrequalification(true);

    let downloadDocTenderStartCalculate = new Date(
      payload?.registrationStart
        ? moment(new Date(payload?.registrationStart))
            .add(durationSchedule.download_document, "days")
            .format()
        : moment(new Date(schedules[0].registrationStart))
            .add(durationSchedule.download_document, "days")
            .format()
    );
    let tenderOpeningEndCalculate = new Date(
      moment(new Date(payload?.registrationEnd))
        .add(durationSchedule.tender_opening, "days")
        .format()
    );
    let tenderEvaluationEndCalculate = new Date(
      moment(new Date(payload?.registrationEnd))
        .add(durationSchedule.tender_evaluation, "days")
        .format()
    );
    let clarificationEndCalculate = new Date(
      moment(new Date(tenderEvaluationEndCalculate))
        .add(durationSchedule.clarification, "days")
        .format()
    );
    let auctionEndCalculate = new Date(
      moment(new Date(clarificationEndCalculate))
        .add(durationSchedule.auction, "days")
        .format()
    );
    let proposedListWinnerApprovedEndCalculate = new Date(
      moment(new Date(auctionEndCalculate))
        .add(durationSchedule.confirmation_winner, "days")
        .format()
    );
    let objectionEndCalculate = new Date(
      moment(new Date(proposedListWinnerApprovedEndCalculate))
        .add(durationSchedule.objection, "days")
        .format()
    );
    let winnerConfirmationEndCalculate = new Date(
      moment(new Date(objectionEndCalculate))
        .add(durationSchedule.winner_determination, "days")
        .format()
    );
    let signContractEndCalculate = new Date(
      moment(new Date(winnerConfirmationEndCalculate))
        .add(durationSchedule.sign_contract, "days")
        .format()
    );

    const newPayload = {
      prequalificationStart: payload?.prequalificationStart
        ? moment(payload?.prequalificationStart).format()
        : "",
      prequalificationEnd: payload?.prequalificationEnd
        ? moment(payload?.prequalificationEnd).format()
        : "",
      downloadDocTenderStart: payload?.registrationStart
        ? moment(payload?.registrationStart).format()
        : schedules[0].registrationStart,
      downloadDocTenderEnd: moment(downloadDocTenderStartCalculate).format(),
      tenderOpeningStart: payload?.registrationEnd
        ? moment(payload?.registrationEnd).format()
        : schedules[0].registrationEnd,
      tenderOpeningEnd: moment(tenderOpeningEndCalculate).format(),
      tenderEvaluationStart: payload?.registrationEnd
        ? moment(payload?.registrationEnd).format()
        : schedules[0].registrationEnd,
      tenderEvaluationEnd: moment(tenderEvaluationEndCalculate).format(),
      clarificationStart: moment(tenderEvaluationEndCalculate).format(),
      clarificationEnd: moment(clarificationEndCalculate).format(),
      auctionStart:
        procurement.procurementSetting.eAuction === true
          ? moment(clarificationEndCalculate).format()
          : "",
      auctionEnd:
        procurement.procurementSetting.eAuction === true
          ? moment(auctionEndCalculate).format()
          : "",
      proposedListWinnerStart: moment(auctionEndCalculate).format(),
      proposedListWinnerEnd: moment(
        proposedListWinnerApprovedEndCalculate
      ).format(),
      proposedListWinnerApprovedStart: moment(auctionEndCalculate).format(),
      proposedListWinnerApprovedEnd: moment(
        proposedListWinnerApprovedEndCalculate
      ).format(),
      objectionStart: moment(proposedListWinnerApprovedEndCalculate).format(),
      objectionEnd: moment(objectionEndCalculate).format(),
      winnerConfirmationStart: moment(objectionEndCalculate).format(),
      winnerConfirmationEnd: moment(winnerConfirmationEndCalculate).format(),
      poMakingStart: moment(objectionEndCalculate).format(),
      poMakingEnd: moment(winnerConfirmationEndCalculate).format(),
      signContractStart: moment(objectionEndCalculate).format(),
      signContractEnd: moment(signContractEndCalculate).format(),

      proposedBy: payload?.proposedBy
        ? payload?.proposedBy
        : schedules[0].proposedBy,
      proposedPosition: payload?.proposedPosition
        ? payload?.proposedPosition
        : schedules[0].proposedPosition,
      approvedBy: payload?.approvedBy
        ? payload?.approvedBy
        : schedules[0].approvedBy,
      approvedPosition: payload?.approvedPosition
        ? payload?.approvedPosition
        : schedules[0].approvedPosition,
    };

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${schedules[0]?.procurementScheduleId}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreatePrequalification(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setLoadingCreate(false);
        setLoadingCreatePrequalification(false);
        setReloadSchedule(true);
      });
  };

  //Aanwijzing
  const [loadingCreateAanwijzing, setLoadingCreateAanwijzing] = useState(false);
  const handleCreateAanwijzing = (isNewCreateAanwijzing, data) => {
    setCreateAanwijzing(isNewCreateAanwijzing);
    isNewCreateAanwijzing && resetaanwijzingForm({ ...data[0] });
  };
  const createAanwijzingProcSchedule = (payload) => {
    setLoadingCreateAanwijzing(true);

    // let downloadDocTenderStartCalculate = new Date(
    //   payload?.registrationStart
    //     ? moment(new Date(payload?.registrationStart))
    //         .add(durationSchedule.download_document, "days")
    //         .format()
    //     : moment(new Date(schedules[0].registrationStart))
    //         .add(durationSchedule.download_document, "days")
    //         .format()
    // );
    // let tenderOpeningEndCalculate = new Date(
    //   moment(new Date(payload?.registrationEnd))
    //     .add(durationSchedule.tender_opening, "days")
    //     .format()
    // );
    // let tenderEvaluationEndCalculate = new Date(
    //   moment(new Date(payload?.registrationEnd))
    //     .add(durationSchedule.tender_evaluation, "days")
    //     .format()
    // );
    // let clarificationEndCalculate = new Date(
    //   moment(new Date(tenderEvaluationEndCalculate))
    //     .add(durationSchedule.clarification, "days")
    //     .format()
    // );
    // let auctionEndCalculate = new Date(
    //   moment(new Date(clarificationEndCalculate))
    //     .add(durationSchedule.auction, "days")
    //     .format()
    // );
    // let proposedListWinnerApprovedEndCalculate = new Date(
    //   moment(new Date(auctionEndCalculate))
    //     .add(durationSchedule.confirmation_winner, "days")
    //     .format()
    // );
    // let objectionEndCalculate = new Date(
    //   moment(new Date(proposedListWinnerApprovedEndCalculate))
    //     .add(durationSchedule.objection, "days")
    //     .format()
    // );
    // let winnerConfirmationEndCalculate = new Date(
    //   moment(new Date(objectionEndCalculate))
    //     .add(durationSchedule.winner_determination, "days")
    //     .format()
    // );
    // let signContractEndCalculate = new Date(
    //   moment(new Date(winnerConfirmationEndCalculate))
    //     .add(durationSchedule.sign_contract, "days")
    //     .format()
    // );

    const newPayload = {
      aanwijzingStart: payload?.aanwijzingStart
        ? moment(payload?.aanwijzingStart).format()
        : "",
      aanwijzingEnd: payload?.aanwijzingEnd
        ? moment(payload?.aanwijzingEnd).format()
        : "",

      proposedBy: payload?.proposedBy
        ? payload?.proposedBy
        : schedules[0].proposedBy,
      proposedPosition: payload?.proposedPosition
        ? payload?.proposedPosition
        : schedules[0].proposedPosition,
      approvedBy: payload?.approvedBy
        ? payload?.approvedBy
        : schedules[0].approvedBy,
      approvedPosition: payload?.approvedPosition
        ? payload?.approvedPosition
        : schedules[0].approvedPosition,
    };

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${schedules[0]?.procurementScheduleId}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreateAanwijzing(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setLoadingCreate(false);
        setLoadingCreateAanwijzing(false);
        setReloadSchedule(true);
      });
  };

  //Quotation
  const [loadingCreateQuotation, setLoadingCreateQuotation] = useState(false);
  const handleCreateQuotation = (isNewCreateQuotation, data) => {
    setCreateQuotation(isNewCreateQuotation);
    isNewCreateQuotation && resetquotationForm({ ...data[0] });
  };
  const createQuotationProcSchedule = (payload) => {
    setLoadingCreateQuotation(true);

    let tenderOpeningEndCalculate = new Date(
      moment(new Date(payload?.quotationEnd))
        .add(durationSchedule.tender_opening, "days")
        .format()
    );
    let tenderEvaluationEndCalculate = new Date(
      moment(new Date(payload?.quotationEnd))
        .add(durationSchedule.tender_evaluation, "days")
        .format()
    );
    let clarificationEndCalculate = new Date(
      moment(new Date(tenderEvaluationEndCalculate))
        .add(durationSchedule.clarification, "days")
        .format()
    );
    let auctionEndCalculate = new Date(
      moment(new Date(clarificationEndCalculate))
        .add(durationSchedule.auction, "days")
        .format()
    );
    let proposedListWinnerApprovedEndCalculate = new Date(
      moment(new Date(auctionEndCalculate))
        .add(durationSchedule.confirmation_winner, "days")
        .format()
    );
    let objectionEndCalculate = new Date(
      moment(new Date(proposedListWinnerApprovedEndCalculate))
        .add(durationSchedule.objection, "days")
        .format()
    );
    let winnerConfirmationEndCalculate = new Date(
      moment(new Date(objectionEndCalculate))
        .add(durationSchedule.winner_determination, "days")
        .format()
    );
    let signContractEndCalculate = new Date(
      moment(new Date(winnerConfirmationEndCalculate))
        .add(durationSchedule.sign_contract, "days")
        .format()
    );

    const newPayload = {
      quotationStart: payload?.quotationStart
        ? moment(payload?.quotationStart).format()
        : schedules[0].quotationStart,
      quotationEnd: payload?.quotationEnd
        ? moment(payload?.quotationEnd).format()
        : schedules[0].quotationEnd,
      tenderOpeningStart: payload?.quotationEnd
        ? moment(payload?.quotationEnd).format()
        : schedules[0].quotationEnd,
      tenderOpeningEnd: moment(tenderOpeningEndCalculate).format(),
      tenderEvaluationStart: payload?.quotationEnd
        ? moment(payload?.quotationEnd).format()
        : schedules[0].quotationEnd,
      tenderEvaluationEnd: moment(tenderEvaluationEndCalculate).format(),
      clarificationStart: moment(tenderEvaluationEndCalculate).format(),
      clarificationEnd: moment(clarificationEndCalculate).format(),
      auctionStart:
        procurement.procurementSetting.eAuction === true
          ? moment(clarificationEndCalculate).format()
          : "",
      auctionEnd:
        procurement.procurementSetting.eAuction === true
          ? moment(auctionEndCalculate).format()
          : "",
      proposedListWinnerStart: moment(auctionEndCalculate).format(),
      proposedListWinnerEnd: moment(
        proposedListWinnerApprovedEndCalculate
      ).format(),
      proposedListWinnerApprovedStart: moment(auctionEndCalculate).format(),
      proposedListWinnerApprovedEnd: moment(
        proposedListWinnerApprovedEndCalculate
      ).format(),
      objectionStart: moment(proposedListWinnerApprovedEndCalculate).format(),
      objectionEnd: moment(objectionEndCalculate).format(),
      winnerConfirmationStart: moment(objectionEndCalculate).format(),
      winnerConfirmationEnd: moment(winnerConfirmationEndCalculate).format(),
      poMakingStart: moment(objectionEndCalculate).format(),
      poMakingEnd: moment(winnerConfirmationEndCalculate).format(),
      signContractStart: moment(winnerConfirmationEndCalculate).format(),
      signContractEnd: moment(signContractEndCalculate).format(),

      proposedBy: payload?.proposedBy
        ? payload?.proposedBy
        : schedules[0].proposedBy,
      proposedPosition: payload?.proposedPosition
        ? payload?.proposedPosition
        : schedules[0].proposedPosition,
      approvedBy: payload?.approvedBy
        ? payload?.approvedBy
        : schedules[0].approvedBy,
      approvedPosition: payload?.approvedPosition
        ? payload?.approvedPosition
        : schedules[0].approvedPosition,
    };

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${schedules[0]?.procurementScheduleId}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreateQuotation(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setLoadingCreate(false);
        setLoadingCreateQuotation(false);
        setReloadSchedule(true);
      });
  };

  //Commercial Quotation
  const [
    loadingCreateCommercialQuotation,
    setLoadingCreateCommercialQuotation,
  ] = useState(false);
  const handleCreateCommercialQuotation = (
    isNewCreateCommercialQuotation,
    data
  ) => {
    setCreateCommercialQuotation(isNewCreateCommercialQuotation);
    isNewCreateCommercialQuotation &&
      resetcommercialquotationForm({ ...data[0] });
  };
  const createCommercialQuotationProcSchedule = (payload) => {
    setLoadingCreateCommercialQuotation(true);

    let tenderOpeningEndCalculate = new Date(
      moment(new Date(payload?.commercialQuotationEnd))
        .add(durationSchedule.tender_opening, "days")
        .format()
    );
    let tenderEvaluationEndCalculate = new Date(
      moment(new Date(payload?.commercialQuotationEnd))
        .add(durationSchedule.tender_evaluation, "days")
        .format()
    );
    let clarificationEndCalculate = new Date(
      moment(new Date(tenderEvaluationEndCalculate))
        .add(durationSchedule.clarification, "days")
        .format()
    );
    let auctionEndCalculate = new Date(
      moment(new Date(clarificationEndCalculate))
        .add(durationSchedule.auction, "days")
        .format()
    );
    let proposedListWinnerApprovedEndCalculate = new Date(
      moment(new Date(auctionEndCalculate))
        .add(durationSchedule.confirmation_winner, "days")
        .format()
    );
    let objectionEndCalculate = new Date(
      moment(new Date(proposedListWinnerApprovedEndCalculate))
        .add(durationSchedule.objection, "days")
        .format()
    );
    let winnerConfirmationEndCalculate = new Date(
      moment(new Date(objectionEndCalculate))
        .add(durationSchedule.winner_determination, "days")
        .format()
    );
    let signContractEndCalculate = new Date(
      moment(new Date(winnerConfirmationEndCalculate))
        .add(durationSchedule.sign_contract, "days")
        .format()
    );

    const newPayload = {
      commercialQuotationStart: payload?.commercialQuotationStart
        ? moment(payload?.commercialQuotationStart).format()
        : schedules[0].commercialQuotationStart,
      commercialQuotationEnd: payload?.commercialQuotationEnd
        ? moment(payload?.commercialQuotationEnd).format()
        : schedules[0].commercialQuotationEnd,

      tenderOpeningStart: payload?.commercialQuotationEnd
        ? moment(payload?.commercialQuotationEnd).format()
        : schedules[0].commercialQuotationEnd,
      tenderOpeningEnd: moment(tenderOpeningEndCalculate).format(),
      tenderEvaluationStart: payload?.commercialQuotationEnd
        ? moment(payload?.commercialQuotationEnd).format()
        : schedules[0].commercialQuotationEnd,
      tenderEvaluationEnd: moment(tenderEvaluationEndCalculate).format(),
      clarificationStart: moment(tenderEvaluationEndCalculate).format(),
      clarificationEnd: moment(clarificationEndCalculate).format(),
      auctionStart:
        procurement.procurementSetting.eAuction === true
          ? moment(clarificationEndCalculate).format()
          : "",
      auctionEnd:
        procurement.procurementSetting.eAuction === true
          ? moment(auctionEndCalculate).format()
          : "",
      proposedListWinnerStart: moment(auctionEndCalculate).format(),
      proposedListWinnerEnd: moment(
        proposedListWinnerApprovedEndCalculate
      ).format(),
      proposedListWinnerApprovedStart: moment(auctionEndCalculate).format(),
      proposedListWinnerApprovedEnd: moment(
        proposedListWinnerApprovedEndCalculate
      ).format(),
      objectionStart: moment(proposedListWinnerApprovedEndCalculate).format(),
      objectionEnd: moment(objectionEndCalculate).format(),
      winnerConfirmationStart: moment(objectionEndCalculate).format(),
      winnerConfirmationEnd: moment(winnerConfirmationEndCalculate).format(),
      poMakingStart: moment(objectionEndCalculate).format(),
      poMakingEnd: moment(winnerConfirmationEndCalculate).format(),
      signContractStart: moment(winnerConfirmationEndCalculate).format(),
      signContractEnd: moment(signContractEndCalculate).format(),

      proposedBy: payload?.proposedBy
        ? payload?.proposedBy
        : schedules[0].proposedBy,
      proposedPosition: payload?.proposedPosition
        ? payload?.proposedPosition
        : schedules[0].proposedPosition,
      approvedBy: payload?.approvedBy
        ? payload?.approvedBy
        : schedules[0].approvedBy,
      approvedPosition: payload?.approvedPosition
        ? payload?.approvedPosition
        : schedules[0].approvedPosition,
    };

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${schedules[0]?.procurementScheduleId}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreateCommercialQuotation(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setLoadingCreate(false);
        setLoadingCreateCommercialQuotation(false);
        setReloadSchedule(true);
      });
  };

  //Technical Quotation
  const [loadingCreateTechnicalQuotation, setLoadingCreateTechnicalQuotation] =
    useState(false);
  const handleCreateTechnicalQuotation = (
    isNewCreateTechnicalQuotation,
    data
  ) => {
    setCreateTechnicalQuotation(isNewCreateTechnicalQuotation);
    isNewCreateTechnicalQuotation &&
      resettechnicalquotationForm({ ...data[0] });
  };
  const createTechnicalQuotationProcSchedule = (payload) => {
    setLoadingCreateTechnicalQuotation(true);

    const newPayload = {
      technicalQuotationStart: payload?.technicalQuotationStart
        ? moment(payload?.technicalQuotationStart).format()
        : schedules[0].payload?.technicalQuotationStart,
      technicalQuotationEnd: payload?.technicalQuotationEnd
        ? moment(payload?.technicalQuotationEnd).format()
        : schedules[0].payload?.technicalQuotationEnd,

      proposedBy: payload?.proposedBy
        ? payload?.proposedBy
        : schedules[0].proposedBy,
      proposedPosition: payload?.proposedPosition
        ? payload?.proposedPosition
        : schedules[0].proposedPosition,
      approvedBy: payload?.approvedBy
        ? payload?.approvedBy
        : schedules[0].approvedBy,
      approvedPosition: payload?.approvedPosition
        ? payload?.approvedPosition
        : schedules[0].approvedPosition,
    };

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${schedules[0]?.procurementScheduleId}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreateTechnicalQuotation(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setLoadingCreate(false);
        setLoadingCreateTechnicalQuotation(false);
        setReloadSchedule(true);
      });
  };

  //Sign
  const [loadingCreateSign, setLoadingCreateSign] = useState(false);

  const handleCreateSign = (isNewCreateSign, data) => {
    setCreateSign(isNewCreateSign);
    isNewCreateSign && resetsignForm({ ...data[0] });
  };
  const createSignProcSchedule = (payload) => {
    setLoadingCreateSign(true);

    const newPayload = {
    
      proposedBy: payload?.proposedBy
        ? payload?.proposedBy
        : schedules[0].proposedBy,
      proposedPosition: payload?.proposedPosition
        ? payload?.proposedPosition
        : schedules[0].proposedPosition,
      approvedBy: payload?.approvedBy
        ? payload?.approvedBy
        : schedules[0].approvedBy,
      approvedPosition: payload?.approvedPosition
        ? payload?.approvedPosition
        : schedules[0].approvedPosition,
    };

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/sign/${schedules[0]?.procurementScheduleId}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreateSign(false);
        setIsSign(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setLoadingCreate(false);
        setLoadingCreateSign(false);
        setReloadSchedule(true);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeProcSchedule);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateProcSchedule = (payload) => {
    setLoadingUpdate(true);

    axios
      .patch(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${activeProcSchedule?.procurementScheduleId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
        setReloadSchedule(true);
      });
  };

  // -------------------------------
  // Delete
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const handleDelete = (isOpen) => {
    setModalDeleteOpen(isOpen);
  };
  const deleteProcSchedule = () => {
    setLoadingDelete(true);

    axios
      .delete(
        `${process.env.REACT_APP_TMS_API}/procurement-schedule/${activeProcSchedule?.procurementScheduleId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        handleDelete(false);
        handleDetailMenu(false);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
        setReloadSchedule(true);
      });
  };

  // -------------------------------
  // Creaet Maal Schedule
  // -------------------------------

  const [loadingMail, setLoadingMail] = useState(false);

  const createProcScheduleMail = (payload) => {
    setLoadingMail(true);

    const subjectEmail = `Procurement ${procurement.procurementNumber} Schedule have been updated`

    // vendors?.filter((status) => status?.status?.status !== "ACCEPT").forEach((listVendor) => {
    //     toast({
    //       description: `Status Vendor ${listVendor?.vendor} masih DRAFT`,
    //       position: "top-right",
    //       status: "error",
    //       isClosable: true,
    //     });
    //     setLoadingMail(false)
    // });

    // vendors?.filter((status) => status?.status?.status === "ACCEPT").map((listVendor) => {
    // vendors?.map((listVendor) => {
    //     axios
    //       .get(
    //         `${process.env.REACT_APP_API}/user/${listVendor?.vendor}`,
    //         {
    //           headers: { Authorization: `Bearer ${token}` },
    //         }
    //       )
    //       .then((res) => {
    //           res?.data?.users?.map((listUsers) => {
    //               console.log(listUsers?.email)

                  // Kirim Email ke GM
                  axios
                    .post(
                      `${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurement.procurementNumber}/send-email/vendortrial3@len.co.id/${subjectEmail}`,
                      // newPayload,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    )
                    .then((res) => {
                      toast({
                        description: t(res?.data?.success),
                        position: "top-right",
                        status: "success",
                        isClosable: true,
                      });
                      setCreate(false);
                    })
                    .catch(() => {
                      toast({
                        title: "System Error",
                        description: t("ERROR.undefined"),
                        position: "top-right",
                        status: "error",
                        isClosable: true,
                      });
                    })
                    .finally(() => {
                      setLoadingMail(false);
                      setReloadSchedule(true);
                    })
                  // })
          // });
    // })
  };

  return (
    <Provider
      value={{
        tableForm,
        schedules,
        loadingSchedules,
        totalData,
        limit,
        isExistSchedule,

        form,
        prequalificationForm,
        aanwijzingForm,
        quotationForm,
        technicalquotationForm,
        commercialquotationForm,
        signForm,
        activeProcSchedule,

        isCreate,
        isCreatePrequalification,
        isCreateAanwijzing,
        isCreateQuotation,
        isCreateTechnicalQuotation,
        isCreateCommercialQuotation,
        isCreateSign,
        handleCreate,
        handleCreatePrequalification,
        handleCreateAanwijzing,
        handleCreateQuotation,
        handleCreateTechnicalQuotation,
        handleCreateCommercialQuotation,
        handleCreateSign,
        loadingCreate,
        loadingCreatePrequalification,
        loadingCreateAanwijzing,
        loadingCreateQuotation,
        loadingCreateTechnicalQuotation,
        loadingCreateCommercialQuotation,
        loadingCreateSign,
        createProcSchedule,
        createPrequalificationProcSchedule,
        createAanwijzingProcSchedule,
        createQuotationProcSchedule,
        createTechnicalQuotationProcSchedule,
        createCommercialQuotationProcSchedule,
        createSignProcSchedule,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateProcSchedule,

        isSign,
        loadingMail,
        createProcScheduleMail,

        isModalDeleteOpen,
        handleDelete,
        loadingDelete,
        deleteProcSchedule,
        setModalDeleteOpen,

        vendors,
        scheduleStatus
      }}
    >
      {children}
    </Provider>
  );
};

export default ProcSchedule;
