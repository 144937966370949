import { CheckboxGroup as CheckboxGroupComponent } from "@chakra-ui/react";
import { useController } from "react-hook-form";

const CheckboxGroup = ({ control, name, controlProps = {}, children, ...props }) => {
  const { field } = useController({
    name,
    control,
    ...controlProps,
  });

  const { ref, ...fieldWithoutRef } = field;

  return (
    <CheckboxGroupComponent {...props} {...fieldWithoutRef}>
      {children}
    </CheckboxGroupComponent>
  );
};

export default CheckboxGroup;
