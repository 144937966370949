import { Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "rsuite";
import { ReqRequisitionContext } from "../Controller";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import moment from "moment";
import PrivateComponent from "../../../../components/PrivateComponent";

const Row = ({ data }) => {
  const { t } = useTranslation();
  const { handleDetailMenu } = useContext(ReqRequisitionContext);

  return (
    <Tr className="hover:bg-gray-50 cursor-pointer" onClick={() => handleDetailMenu(true, data)}>
      <Td pr={0}>{data?.reqRequisitionCode}</Td>
      <Td>{data?.requisitioner?.description}</Td>
      <Td style={{maxWidth: "400px"}}>
        <ul className="ml-4" style={{ listStyle: "disc" }}>
          {data?.reqRequisitionMaterial?.map((reqMaterial, iReqMaterial) => (
            <div key={iReqMaterial} className="text-left">
              {iReqMaterial + 1 + "0 "} {reqMaterial?.material?.description}
            </div>
          ))}
        </ul>
      </Td>
      <Td>{moment(data?.createdAt).format("DD/MM/YYYY")}</Td>
      <Td className="px-4">
        <div className="w-full h-full flex justify-end items-center">
          <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
        </div>
      </Td>
    </Tr>
  );
};

const ReqRequisitionTable = () => {
  const { reqRequisition, loadingReqRequisition, totalData, limit, tableForm, handleCreate } =
    useContext(ReqRequisitionContext);
  const { t } = useTranslation();

  const watchTableForm = tableForm.watch();

  return (
    <div className="w-full bg-white rounded-md text-sm p-4 col-span-3">
      <PrivateComponent accessName="BUYER_EDIT">
        <div className="mb-2 flex justify-end">
          <Button size="sm" onClick={() => handleCreate(true)}>
            <BiPlus className="mr-1" /> {t("LABEL.ADD", { field: t("LABEL.REQ_REQUISITION") })}
          </Button>
        </div>
      </PrivateComponent>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th className="text-red-500">{t("LABEL.REQUISITION_CODE")}</Th>
            <Th className="text-red-500">{t("LABEL.REQUISITIONER")}</Th>
            <Th className="text-red-500">{t("Item Material")}</Th>
            <Th className="text-red-500">{t("LABEL.CREATED_AT")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {loadingReqRequisition ? (
            <Tr>
              <Td colSpan={5}>
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner color="red.500" />
                </div>
              </Td>
            </Tr>
          ) : !reqRequisition.length ? (
            <Tr>
              <Td colSpan={5} className="text-center">
                <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
              </Td>
            </Tr>
          ) : (
            reqRequisition.map((admin, iReqRequisition) => <Row key={iReqRequisition} data={admin} />)
          )}
        </Tbody>
      </Table>

      <div className="mt-3 flex justify-end">
        <Pagination
          next
          prev
          ellipsis
          boundaryLinks
          maxButtons={5}
          total={totalData}
          limit={limit}
          activePage={watchTableForm?.page}
          onChangePage={(page) => tableForm.setValue("page", page)}
        />
      </div>
    </div>
  );
};

export default ReqRequisitionTable;
