import moment from 'moment';
import { FormControl, FormLabel, FormHelperText, Textarea } from '@chakra-ui/react';
import { InputText } from 'primereact/inputtext';
import { useContext } from 'react';
import { AdministratifContext } from './Controller';
import FormDescription from '../../../../../../../../../../components/inputForm/FormDescription';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../../../../../../../../../components/TitleBar';
import Uploader from '../../../../../../../../../../components/inputForm/Uploader';
import { API_URLS } from '../../../../../../../../../../utils/apiURLs.util';
import FileAttachment from '../../../../../../../../../../components/FileAttachment';

const AdministratifForm = ({ isEdit, administratifOffer }) => {
  const {t} = useTranslation();
	const { form } = useContext(AdministratifContext);

	const {
		register,
		formState: { errors },
    getValues,
    setValue
	} = form;

	const fieldName = {
		offerNumber: t("LABEL.OFFER_NUMBER"),
		offerDate: t("LABEL.OFFER_DATE"),
		offerExpired: t("LABEL.OFFER_EXPIRED"),
		offerLetter: t("LABEL.OFFER_LETTER"),
		offerWorkTime: t("LABEL.OFFER_WORK_TIME"),
		offerNote: t("LABEL.OFFER_NOTE"),
	};

  register('offerLetter', { required:`${fieldName.offerLetter} wajib diisi` });

	return (
		<div>
			<TitleBar title={t("LABEL.CONCEPT")} />
			<div className="flex justify-between gap-5">
				<div className="w-1/2 flex flex-col gap-5">
					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerNumber}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerNumber}
									className={`w-full text-sm ${errors.offerNumber && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerNumber}
									required={true}
									{...register('offerNumber', { required: `${t("INVALID.REQUIRED", {field: fieldName.offerNumber})}` })}
								/>
								{errors.offerNumber && <FormHelperText className="text-red-400 text-xs">{errors.offerNumber.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{administratifOffer?.offerNumber}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerDate}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerDate}
									className={`w-full text-sm ${errors.offerDate && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerDate}
									required={true}
									type="date"
									{...register('offerDate', { required: `${t("INVALID.REQUIRED", {field: fieldName.offerDate})}` })}
								/>
								{errors.offerDate && <FormHelperText className="text-red-400 text-xs">{errors.offerDate.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{moment(new Date(administratifOffer?.startAt)).format('yyyy-MM-DD')}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerExpired}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerExpired}
									className={`w-full text-sm ${errors.offerExpired && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerExpired}
									required={true}
									type="date"
									{...register('offerExpired', { required: `${t("INVALID.REQUIRED", {field: fieldName.offerExpired})}` })}
								/>
								{errors.offerExpired && <FormHelperText className="text-red-400 text-xs">{errors.offerExpired.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{moment(new Date(administratifOffer?.expiredAt)).format('yyyy-MM-DD')}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerLetter}</FormLabel>

						{isEdit ? (
							<>
                <Uploader
									className={`${errors?.offerLetter && 'border-2 border-red-400 shadow-none'}`}
									label={fieldName.offerLetter}
									name={fieldName.offerLetter}
									fileList={getValues('offerLetter')}
									onChange={(files) => {
										setValue('offerLetter', files);
									}}
								/>
								{errors.offerLetter && <FormHelperText className="text-red-400 text-xs">{errors.offerLetter.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>
                <FileAttachment href={`${API_URLS.REACT_APP_FILESERVICE_API}/files/users/${administratifOffer?.letterFilename}`} />
							</FormDescription>
						)}
					</FormControl>
				</div>
				<div className="w-1/2 flex flex-col gap-5">
					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerWorkTime}</FormLabel>

						{isEdit ? (
							<>
								<InputText
									name={fieldName.offerWorkTime}
									className={`w-full text-sm ${errors.offerWorkTime && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerWorkTime}
									required={true}
									type="number"
									{...register('offerWorkTime', { required: `${t("INVALID.REQUIRED", {field: fieldName.offerWorkTime})}` })}
								/>
								{errors.offerWorkTime && <FormHelperText className="text-red-400 text-xs">{errors.offerWorkTime.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{administratifOffer?.workTime}</FormDescription>
						)}
					</FormControl>

					<FormControl isRequired={isEdit}>
						<FormLabel className={`${!isEdit && 'text-red-500'} uppercase font-semibold text-xs`}>{fieldName.offerNote}</FormLabel>

						{isEdit ? (
							<>
								<Textarea
									name={fieldName.offerNote}
									className={`w-full text-sm ${errors.offerNote && 'border-2 border-red-400 shadow-none'}`}
									placeholder={fieldName.offerNote}
									required={true}
									{...register('offerNote', { required: `${t("INVALID.REQUIRED", {field: fieldName.offerNote})}` })}
								/>

								{errors.offerNote && <FormHelperText className="text-red-400 text-xs">{errors.offerNote.message}</FormHelperText>}
							</>
						) : (
							<FormDescription>{administratifOffer?.note}</FormDescription>
						)}
					</FormControl>
				</div>
			</div>
		</div>
	);
};

export default AdministratifForm;
