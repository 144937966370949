import numeral from 'numeral';
import { useState, useEffect, useContext } from 'react';
import { Spinner } from '@chakra-ui/react';
import ItemCard from './components/ItemCard';
import { CommercialContext } from './Controller';
import { ProcurementOfferContext } from '../../Controller';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../../../../../../../../../components/TitleBar';

const CommercialItemTable = () => {
  const {t} = useTranslation();
	const { errors, priceInputs, setPriceInputs } = useContext(CommercialContext);
	const { commercialOffer, loadingMaterials, materials } = useContext(ProcurementOfferContext);

	const [isEditPrice, setIsEditPrice] = useState(true);
	const [subTotal, setSubTotal] = useState(null);

	const idxExistMaterial = (requisitionMaterialId) => {
		for (let i = 0; i < commercialOffer.procurementCommercialOfferRequisitionMaterials.length; i++) {
			const mat = commercialOffer.procurementCommercialOfferRequisitionMaterials[i];
			if (mat.requisitionMaterialId === requisitionMaterialId) {
				return i;
			}
		}

		return null;
	};

	useEffect(() => {
		if (commercialOffer && materials) {
			if (commercialOffer.procurementCommercialOfferRequisitionMaterials.length !== 0) {
				setIsEditPrice(false);
        if (priceInputs){
          const temp = [...priceInputs];
          materials?.forEach(mat => {
            const idx =  idxExistMaterial(mat.procurementRequisitionMaterialId);
            temp[idx] = +commercialOffer?.procurementCommercialOfferRequisitionMaterials[idx].price;
          });
          setPriceInputs(temp);
        }
			}
		}
	}, [commercialOffer, materials]);

	useEffect(() => {
		if (materials && priceInputs) {
			let temp = 0;
			priceInputs.forEach((val, idx) => {
				if (val !== '') {
					temp += +val * materials[idx].qty;
				}
			});
			setSubTotal(temp);
		} else {
			setSubTotal(null);
		}
	}, [materials, priceInputs]);

	return (
		<div>
			<TitleBar className="mt-4" title={t("LABEL.PROCUREMENT_ITEM")} />
			<div className="mt-3 flex flex-col gap-3">
				{loadingMaterials && (
					<div className="flex justify-center mt-5">
						<Spinner color="red.500" />
					</div>
				)}
				{!loadingMaterials &&
					materials && errors &&
					priceInputs &&
					materials.map((mat, idx) => {
						const idxSubmittedMat = commercialOffer ? idxExistMaterial(mat.procurementRequisitionMaterialId) : null;
						return (
							<ItemCard
								key={idx}
								material={mat}
								idx={idx}
								idxSubmitted={idxSubmittedMat}
								offer={commercialOffer}
								priceInputs={priceInputs}
								setPriceInputs={setPriceInputs}
								errors={errors}
								isEdit={isEditPrice}
							/>
						);
					})}
			</div>
			{!loadingMaterials && errors && materials && materials.length !== 0 && (
				<>
					<div className="flex justify-end font-bold mt-3 items-center">
						<div className="bg-color0 flex rounded-md text-white">
							<div className="px-6 py-1">Sub Total :</div>
							<div className="text-center py-1" style={{ width: '400px' }}>
								{subTotal === null || subTotal === 0 ? (
									<>-</>
								) : (
									<>
										{numeral(+subTotal).format('0,0.00')} {materials[0]?.currency.currency}
									</>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default CommercialItemTable;
