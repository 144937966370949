import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcurementOfferEvaluationContext } from '../../../Controller';
import { useState } from 'react';
import { useEffect } from 'react';
import { EvaluationContext } from '../Controller';
import { ProcurementDetailContext } from '../../../../../../../Controller';
import numeral from 'numeral';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { OFFER_2_COVER_PHASES } from '../../../../../../../../../../../../utils/offerPhases.util';

const Row = ({ vendor, idx, currency, setCurrency, offer }) => {
	const { procurement } = useContext(ProcurementDetailContext);
	const { materials, evaluation } = useContext(ProcurementOfferEvaluationContext);
	const [total, setTotal] = useState(null);

	useEffect(async () => {
		if (!procurement || !materials) return;
		if (!offer) return;
		let mounted = true;

		let temp = 0;
		offer.procurementCommercialOfferRequisitionMaterials.forEach((material) => {
			const reqMat = materials.find((req) => {
				return req.procurementRequisitionMaterialId === material.requisitionMaterialId;
			});
			if (!currency) setCurrency(reqMat.currency);
			temp += +reqMat.qty * +material.price;
		});
		if (!mounted) return;
		setTotal(temp);

		return () => {
			mounted = false;
		};
	}, [procurement, evaluation, vendor]);

	return (
		<Tr className="hover:bg-gray-50">
			<Td
				style={{
					minWidth: '75px',
					maxWidth: '75px',
					width: '75px',
				}}
			>
				{idx + 1}
			</Td>
			<Td className="w-3/6">{vendor.company?.companyName}</Td>
			<Td className="flex gap-4 font-bold">
				{!total ? (
					<>-</>
				) : (
					<>
						{numeral(+total).format('0,0.00')} {currency?.currency}
					</>
				)}
			</Td>
		</Tr>
	);
};

const VendorOffer = () => {
	const { t } = useTranslation();
	const { procurementVendors, loadingProcurementVendors, evaluation, loadingEvaluation, offerEvaluationPhase, commercialOffers } = useContext(ProcurementOfferEvaluationContext);
	const { evaluationType } = useContext(EvaluationContext);

	const statusKey = !evaluationType ? 'status' : evaluationType === 'COM' ? 'statusCommercial' : 'statusAdministration';

	const [acceptedVendor, setAcceptedVendor] = useState(null);
	const [currency, setCurrency] = useState(null);

	useEffect(() => {
		if (!evaluation || !procurementVendors) return;
		const temp = procurementVendors.filter((vendor) => {
			const { evaluationVendor, idxExist } = existInEvaluation(vendor);
			if (idxExist === null) return false;
			return evaluationVendor[statusKey] === 'ACCEPTED';
		});
		setAcceptedVendor([...temp]);
	}, [evaluation, procurementVendors]);

	const existInEvaluation = (vendor) => {
		for (let i = 0; i < evaluation.procurementOfferEvaluationVendors.length; i++) {
			const evalVendor = evaluation.procurementOfferEvaluationVendors[i];

			if (evalVendor.procurementVendorId === vendor.procurementVendorId) {
				return {
					evaluationVendor: evalVendor,
					idxExist: i,
				};
			}
		}

		return { evaluationVendor: null, idxExist: null };
	};

	return (
		<div className="mt-5">
			<TitleBar title={t('LABEL.OFFER')} />
			<Table>
				<Thead>
					<Tr>
						<Th
							style={{
								minWidth: '75px',
								maxWidth: '75px',
								width: '75px',
							}}
							className="text-red-500"
						>
							No.
						</Th>
						<Th className="text-red-500">{t('LABEL.COMPANY_NAME')}</Th>
						<Th className="text-red-500">{t('LABEL.MATERIAL_PRICE')}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loadingProcurementVendors || !procurementVendors || loadingEvaluation || !evaluation ? (
						<Tr>
							<Td colSpan={3}>
								<div className="w-full h-full flex justify-center items-center">
									<Spinner color="red.500" />
								</div>
							</Td>
						</Tr>
					) : (
						<>
							{!acceptedVendor || acceptedVendor.length === 0 ? (
								<Tr>
									<Td colSpan={3} className="text-center">
										<p className="text-sm text-gray-500">{t('TEXT.DATA_NOT_FOUND')}</p>
									</Td>
								</Tr>
							) : (
								<>
									{offerEvaluationPhase === OFFER_2_COVER_PHASES.POST && evaluation[statusKey] !== 'SUBMITTED' ? (
										<Tr>
											<Td colSpan={3} className="text-center"></Td>
										</Tr>
									) : (
										acceptedVendor.map((vendor, idx) => {
											const offer = commercialOffers?.find((offer) => offer.procurementVendorId === vendor.procurementVendorId);

											return <Row vendor={vendor} idx={idx} key={idx} currency={currency} setCurrency={setCurrency} offer={offer} />;
										})
									)}
								</>
							)}
						</>
					)}
				</Tbody>
			</Table>
		</div>
	);
};

export default VendorOffer;
