import React, { useContext, useState } from 'react';
import { FormControl, FormHelperText, FormLabel, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { VendorContext } from '../Controller';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { HelperContext } from '../../../../../../../../../controllers/HelperController';
import { ProcurementDetailContext } from '../../../../Controller';

const Form = ({ isEdit }) => {
	const { t } = useTranslation();
	const { httpRequest } = useContext(HelperContext);
  const { procurementType } = useContext(ProcurementDetailContext);
	const { form, vendors, vendor: procurementVendors, isics, chosenVendors, setChosenVendors } = useContext(VendorContext);
	const {
		formState: { errors },
	} = form;

	const [filteredVendors, setFilteredVendors] = useState([]);
	const [chosenParent, setChosenParent] = useState();
	const [childIsics, setChildIsics] = useState([]);
	const [chosenIsics, setChosenIsics] = useState({});

	useEffect(async () => {
		if (!chosenParent) return;
		let mounted = true;
		setChosenIsics({});
		const { response } = await httpRequest({
			url: `isic/${chosenParent}`,
		});
		if (!mounted) return;

		setChildIsics(response?.data);
	}, [chosenParent]);

	useEffect(() => {
		if (!vendors) return;

		let result = vendors;
		if (chosenParent) {
			const temp = result.filter((vendor) => {
				const exist = vendor.competencies.find((competency) => competency?.businessField?.isicCode === chosenParent);
				return exist;
			});
			result = temp;
		}

		const codes = Object.keys(chosenIsics);
		if (codes.length > 0) {
			const temp = result.filter((vendor) => {
				const existSub = vendor.competencies.find((competency) => chosenIsics[competency?.subBusinessField?.isicCode]);
				return existSub;
			});
			result = temp;
		}

		setFilteredVendors(result);
	}, [chosenIsics, vendors, chosenParent]);

	return (
		<>
			{/* FILTER */}
			<div className="md:col-span-2">
				<Dropdown
					filter
          resetFilterOnHide
					value={chosenParent}
					placeholder="Filter Bidang Usaha"
					className="flex w-full text-sm"
					panelClassName="text-sm"
					onChange={(val) => {
						setChosenParent(val.value);
					}}
					options={isics?.map((isic) => {
						return {
							label: isic.isicCode + ' - ' + isic.description,
							value: isic.isicCode,
						};
					})}
				/>
			</div>
			<div className="flex flex-col gap-2 md:col-span-2">
				<Dropdown
					disabled={!chosenParent || (filteredVendors?.length === 0 && Object.keys(chosenIsics).length === 0)}
					filter
          resetFilterOnHide
					value={'0'}
					placeholder="Filter Sub Bidang Usaha"
					className="flex w-full text-sm"
					panelClassName="text-sm"
					onChange={(val) => {
						const code = val.value.isicCode;
						chosenIsics[code] = val.value.isicDescription;
						setChosenIsics({
							...chosenIsics,
						});
					}}
					options={
						childIsics?.length > 0
							? [
									{
										label: 'Filter Sub Bidang Usaha',
										value: '0',
									},
									...childIsics.map((isic) => {
										return {
											label: isic.description,
											value: {
												isicCode: isic.isicCode,
												isicDescription: isic.description,
											},
											disabled: chosenIsics[isic.isicCode],
										};
									}),
							  ]
							: []
					}
				/>
				<div className="font-semibold"> Total Vendor: {filteredVendors?.length} </div>
				<div className="flex gap-2 flex-wrap">
					{Object.keys(chosenIsics).map((code, idx) => {
						return (
							<Tooltip key={idx} label={chosenIsics[code]}>
								<div style={{ width: 'fit-content' }} className="flex gap-2 bg-color0 items-center px-3 py-1 rounded-3xl text-white">
									<div style={{ width: '100px', direction: 'rtl' }} className="truncate whitespace-nowrap font-semibold text-xs">
										{chosenIsics[code]}
									</div>
									<button
										onClick={() => {
											delete chosenIsics[code];
											setChosenIsics({
												...chosenIsics,
											});
										}}
									>
										&#10006;
									</button>
								</div>
							</Tooltip>
						);
					})}
				</div>
			</div>

			{/* Vendors */}
			<FormControl isRequired className="md:col-span-2">
				<FormLabel className={`uppercase font-semibold text-xs ${!isEdit && 'text-red-500'}`}>{t('LABEL.VENDOR')}</FormLabel>
				<Dropdown
					filter
          resetFilterOnHide
          disabled={procurementType === "DIRECT" && Object.keys(chosenVendors).length > 0}
					value={'0'}
					placeholder="Pilih Penyedia"
					className="flex w-full text-sm"
					panelClassName="text-sm"
					onChange={(val) => {
						const regCode = val.value.regCode;
						chosenVendors[regCode] = val.value.name;
						setChosenVendors({
							...chosenVendors,
						});
					}}
					options={
						filteredVendors?.length > 0
							? [
									{
										label: 'Pilih Penyedia',
										value: '0',
										disabled: true,
									},
									...filteredVendors.map((vendor) => ({
										label: `${vendor?.registrationCode} - ${vendor?.companyName}`,
										value: {
											regCode: vendor?.registrationCode,
											name: vendor?.companyName,
										},
										disabled: procurementVendors?.find((v) => v.vendor === vendor.registrationCode) || chosenVendors[vendor?.registrationCode],
									})),
							  ]
							: []
					}
				/>
				{errors?.vendor && <FormHelperText className="text-red-400 text-xs">{errors?.vendor?.message}</FormHelperText>}
				<div className="flex gap-2 flex-wrap mt-4">
					{Object.keys(chosenVendors).map((code, idx) => {
						return (
							<Tooltip key={idx} label={chosenVendors[code]}>
								<div style={{ width: 'fit-content' }} className="flex gap-2 bg-color0 items-center px-3 py-1 rounded-3xl text-white">
									<div style={{}} className="font-semibold text-xs">
										{code}
									</div>
									<button
										onClick={() => {
											delete chosenVendors[code];
											setChosenVendors({
												...chosenVendors,
											});
										}}
									>
										&#10006;
									</button>
								</div>
							</Tooltip>
						);
					})}
				</div>
			</FormControl>
		</>
	);
};

export default Form;
