import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from "@chakra-ui/modal";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavbarContext } from "../Controller";
import { BsArrowRightShort, BsCaretDownFill, BsX } from "react-icons/bs";
import { motion } from "framer-motion";
import { Button } from "@chakra-ui/button";
import { publicRouteSlug } from "../../../../routes/publicRoutes";
import TranslationHandler from "../../../../components/TranslationHandler";
import { Menu, MenuButton, MenuItem, MenuList, Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/auth/AuthController";
import { AiFillCaretDown } from "react-icons/ai";
import { userRouteSlug } from "../../../../routes/userRoutes";

const SidebarItem = ({ item, handleRedirect }) => {
  const [showItems, setShowItems] = useState(false);

  return (
    <div>
      <button
        className="w-full p-2 py-3 text-lg font-medium text-gray-700 flex justify-between items-center"
        style={{ borderBottom: "1px solid #ccc" }}
        onClick={() => (item?.items?.length ? setShowItems(!showItems) : handleRedirect(item?.path || "#"))}
      >
        <span>{item?.label}</span>
        <BsCaretDownFill
          className={`text-xs ${!item?.items?.length && "hidden"} ${showItems && "transform rotate-180"}`}
        />
      </button>

      {item?.items?.length && showItems && (
        <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} className="px-4">
          {item?.items?.map((child, iChild) => (
            <SidebarItem key={iChild} item={child} handleRedirect={handleRedirect} />
          ))}
        </motion.div>
      )}
    </div>
  );
};

const MobileSidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin, userData, logout } = useContext(AuthContext);
  const { navbarItems, openMobileSidebar, setOpenMobileSidebar } = useContext(NavbarContext);

  const handleRedirect = (path) => {
    setOpenMobileSidebar(false);
    navigate(path);
  };

  return (
    <div>
      <Drawer isOpen={openMobileSidebar} onClose={() => setOpenMobileSidebar(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <div className="flex justify-between">
              <TranslationHandler />
              <button onClick={() => setOpenMobileSidebar(false)}>
                <BsX className="text-3xl text-gray-600" />
              </button>
            </div>
          </DrawerHeader>
          <DrawerBody>
            <div className="w-full h-full flex flex-col justify-between py-4">
              <div />
              <div className="flex flex-col">
                {navbarItems.map((item, iItem) => (
                  <SidebarItem key={iItem} item={item} handleRedirect={handleRedirect} />
                ))}
              </div>
              <div className="flex flex-col gap-y-2">
                {isLogin ? (
                  <div className="flex justify-between items-center">
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<AiFillCaretDown className="text-xs" />}
                        className="bg-transparent shadow-none text-gray-600"
                      >
                        {userData?.name}
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => logout()}>{t("LABEL.LOGOUT")}</MenuItem>
                      </MenuList>
                    </Menu>

                    <Button
                      className="flex items-center gap-1"
                      size="xs"
                      colorScheme="red"
                      onClick={() => handleRedirect(userRouteSlug.PANEL)}
                    >
                      <span>{t("TEXT.ENTER_PANEL")}</span>
                      <BsArrowRightShort />
                    </Button>
                  </div>
                ) : (
                  <>
                    <Button
                      color="white"
                      className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
                      onClick={() => handleRedirect(publicRouteSlug.REGISTER)}
                    >
                      {t("LABEL.REGISTER")}
                    </Button>
                    <button
                      className="font-semibold text-sm text-red-600 py-1"
                      onClick={() => handleRedirect(publicRouteSlug.LOGIN)}
                    >
                      {t("LABEL.LOGIN")}
                    </button>
                  </>
                )}
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default MobileSidebar;
