import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import General from "./components/sections/General";
import Branch from "./components/sections/Branch";
import Deed from "./components/sections/Deed";
import BOD from "./components/sections/BOD";
import Shareholder from "./components/sections/Shareholder";
import BusinessPermit from "./components/sections/BusinessPermit";
import User from "./components/sections/User";
import { BiCrown, BiMoney, BiReceipt } from "react-icons/bi";
import { AuthContext } from "../../../controllers/auth/AuthController";
import axios from "axios";
import Equipment from "./components/sections/Equipment";
import Expert from "./components/sections/Expert";
import Certification from "./components/sections/Certification";
import Competency from "./components/sections/Competency";
import Experience from "./components/sections/Experience";
import BankAccount from "./components/sections/BankAccount";
import FinancialReport from "./components/sections/FinancialReport";
import Tax from "./components/sections/Tax";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";

export const CompanyProfileContext = createContext();
const { Provider } = CompanyProfileContext;

const CompanyProfileController = ({ children }) => {
  const { t } = useTranslation();
  const { userData, token, roles, refetchToken } = useContext(AuthContext);
  const params = useParams();
  const toast = useToast();

  // Active Company
  const regCode = params?.regCode || userData?.company?.registrationCode;

  // ---------------------------
  // Sections
  // ---------------------------
  const sectionCategories = [
    { name: "Administration", labelI18n: "LABEL.ADMINISTRATION", Icon: BiReceipt },
    { name: "Expertise", labelI18n: "LABEL.EXPERTISE", Icon: BiCrown },
    { name: "Finance", labelI18n: "LABEL.FINANCE", Icon: BiMoney },
  ];

  const sections = [
    { name: "General", category: "Administration", labelI18n: "LABEL.GENERAL", to: "/general", Component: General },
    { name: "Branch", category: "Administration", labelI18n: "LABEL.BRANCH", to: "/branch", Component: Branch },
    { name: "Deed", category: "Administration", labelI18n: "LABEL.COMPANY_DEED", to: "/deed", Component: Deed },
    {
      name: "Shareholder",
      category: "Administration",
      labelI18n: "LABEL.SHAREHOLDER",
      to: "/shareholder",
      Component: Shareholder,
    },
    { name: "BOD", category: "Administration", labelI18n: "LABEL.BOD", to: "/bod", Component: BOD },
    {
      name: "BusinessPermit",
      category: "Administration",
      labelI18n: "LABEL.BUSINESS_PERMIT",
      to: "/business-permit",
      Component: BusinessPermit,
    },
    { name: "User", category: "Administration", labelI18n: "LABEL.PIC", to: "/pic", Component: User },
    { name: "Equipment", category: "Expertise", labelI18n: "LABEL.EQUIPMENT", to: "/equipment", Component: Equipment },
    { name: "Expert", category: "Expertise", labelI18n: "LABEL.EXPERT", to: "/expert", Component: Expert },
    {
      name: "Certification",
      category: "Expertise",
      labelI18n: "LABEL.CERTIFICATION",
      to: "/certification",
      Component: Certification,
    },
    {
      name: "Competency",
      category: "Expertise",
      labelI18n: "LABEL.COMPETENCY",
      to: "/competency",
      Component: Competency,
    },
    {
      name: "Experience",
      category: "Expertise",
      labelI18n: "LABEL.EXPERIENCE",
      to: "/experience",
      Component: Experience,
    },
    {
      name: "BankAccount",
      category: "Finance",
      labelI18n: "LABEL.BANK_ACCOUNT",
      to: "/bank-account",
      Component: BankAccount,
    },
    {
      name: "FinancialReport",
      category: "Finance",
      labelI18n: "LABEL.FINANCIAL_REPORT",
      to: "/financial-report",
      Component: FinancialReport,
    },
    {
      name: "Tax",
      category: "Finance",
      labelI18n: "LABEL.TAX",
      to: "/tax",
      Component: Tax,
    },
  ];
  const [activeSection, setActiveSection] = useState();

  useEffect(() => {
    setActiveSection(sections.find((section) => section.to?.includes(params?.section)));
  }, [params]);

  // Company Detail
  const [loadingCompanyOverview, setLoadingCompanyOverview] = useState(false);
  const [reloadCompanyOverview, setReloadCompanyOverview] = useState(true);
  const [companyOverview, setCompanyOverview] = useState();
  useEffect(() => {
    let mounted = true;

    const getCompanyOverview = () => {
      setLoadingCompanyOverview(true);
      axios
        .get(`company/${regCode}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (mounted) {
            setCompanyOverview(res?.data?.company);
          }
        })
        .finally(() => {
          setLoadingCompanyOverview(false);
          setReloadCompanyOverview(false);
        });
    };

    token && regCode && reloadCompanyOverview && getCompanyOverview();

    return () => {
      mounted = true;
    };
  }, [regCode, token, reloadCompanyOverview]);

  // ---------------------------
  // User
  // ---------------------------

  // Verify Submit Approval
  const [submitApprovalFails, setSubmitApprovalFails] = useState([]);
  const getSubmitApprovalFails = async () => {
    const isUser = userData?.type === "user";
    if (isUser) {
      axios
        .get(`/company/verify-submit-approval/${userData?.company?.registrationCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setSubmitApprovalFails(res.data?.fails);
        });
    }
  };
  useEffect(() => {
    userData && token && getSubmitApprovalFails();
  }, [userData, token, roles]);

  // ---------------------------
  // Vendor
  // ---------------------------

  // Is Company Need Reapproval
  const [needReapprovals, setNeedReapprovals] = useState([]);
  const getCompanyNeedReapprovals = async () => {
    const isVendorOrAdmin = !!roles?.find((role) => role.role === "Vendor" || role.role === "Admin");
    if (isVendorOrAdmin) {
      axios
        .get(`/need-reapproval/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setNeedReapprovals(res.data?.needReapproval);
        });
    }
  };
  useEffect(() => {
    userData && token && getCompanyNeedReapprovals();
  }, [userData, token, roles]);

  // ---------------------------
  // Admin
  // ---------------------------

  // Company Approval Information
  const [loadingCompanyApprovalInfo, setLoadingCompanyApprovalInfo] = useState(false);
  const [reloadCompanyApprovalInfo, setReloadCompanyApprovalInfo] = useState(true);
  const [companyApprovalInfo, setCompanyApprovalInfo] = useState();
  useEffect(() => {
    let mounted = true;

    const getCompanyApprovalInfo = () => {
      setLoadingCompanyApprovalInfo(true);
      axios
        .get(`vendor-approval/company/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            setCompanyApprovalInfo(res.data?.vendorApproval);
          }
        })
        .finally(() => {
          if (mounted) {
            setLoadingCompanyApprovalInfo(false);
            setReloadCompanyApprovalInfo(false);
          }
        });
    };

    if (userData) {
      if (userData?.type === "admin") {
        token && reloadCompanyApprovalInfo && getCompanyApprovalInfo();
      } else {
        setReloadCompanyApprovalInfo(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadCompanyApprovalInfo]);

  return (
    <Provider
      value={{
        regCode,

        setReloadCompanyOverview,
        loadingCompanyOverview,
        companyOverview,

        sectionCategories,
        sections,
        activeSection,

        submitApprovalFails,
        getSubmitApprovalFails,
        loadingCompanyApprovalInfo,
        companyApprovalInfo,
        setReloadCompanyApprovalInfo,

        needReapprovals,
        getCompanyNeedReapprovals,
      }}
    >
      {children}
    </Provider>
  );
};

export default CompanyProfileController;
