import { useContext } from 'react';
import { FaClipboardList } from 'react-icons/fa';
import TitleBar from '../../../../../../../../../components/TitleBar';
import { ProcurementDetailContext } from '../../../../Controller';
import SchedulePanel from './SchedulePanel'

const ObjectionSchedule = ({}) => {
	const { procurementSchedule, loadingProcurementSchedule } = useContext(ProcurementDetailContext);

	return (
		<div>
			<TitleBar icon={FaClipboardList} title="Jadwal Sanggahan" />
			<SchedulePanel start={procurementSchedule?.objectionStart} end={procurementSchedule?.objectionEnd} loading={loadingProcurementSchedule} />
		</div>
	);
};

export default ObjectionSchedule;
