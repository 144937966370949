import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const EquipmentContext = createContext();
const { Provider } = EquipmentContext;

const EquipmentController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        equipmentType: newForm?.equipmentType?.equipmentType || "hardware",
        description: newForm?.description,
        quantity: newForm?.quantity,
        capacity: newForm?.capacity,
        brand: newForm?.brand,
        condition: newForm?.condition?.equipmentCondition || "new",
        location: newForm?.location,
        ownership: newForm?.ownership?.equipmentOwnership || "owned",
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeEquipment, setActiveEquipment] = useState();
  const handleDetailMenu = (isOpen, equipment = null) => {
    setDetailMenu(isOpen);
    setActiveEquipment(equipment);
    isOpen && resetForm(equipment);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Equipments
  const [equipments, setEquipments] = useState([]);
  const [loadingEquipments, setLoadingEquipments] = useState(false);
  const [reloadEquipments, setReloadEquipments] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getEquipments = async () => {
      setLoadingEquipments(true);
      axios
        .get(`/equipment/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newEquipments = res?.data?.equipments;
            setEquipments(newEquipments);

            if (activeEquipment) {
              const newActiveEquipment =
                newEquipments.find((equipment) => equipment?.equipmentId === activeEquipment?.equipmentId) || null;
              setActiveEquipment(newActiveEquipment);
              resetForm(newActiveEquipment);
            }
          }
        })
        .finally(() => {
          setReloadEquipments(false);
          setLoadingEquipments(false);
        });
    };

    userData && token && reloadEquipments && getEquipments();

    if (reloadEquipments) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadEquipments]);

  // -------------------------------
  // Master
  // -------------------------------

  // Equipment Type
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/equipment-type`).then((res) => {
      if (mounted) {
        setEquipmentTypes(res?.data?.equipmentType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Equipment Condition
  const [equipmentConditions, setEquipmentConditions] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/equipment-condition`).then((res) => {
      if (mounted) {
        setEquipmentConditions(res?.data?.equipmentCondition);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // Equipment Condition
  const [equipmentOwnerships, setEquipmentOwnerships] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/equipment-ownership`).then((res) => {
      if (mounted) {
        setEquipmentOwnerships(res?.data?.equipmentOwnership);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createEquipment = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    formData.append("attachment", payload?.attachment[0]);

    axios
      .post(`equipment/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadEquipments(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeEquipment);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateEquipment = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append(
      "payload",
      JSON.stringify({
        ...payload,
        ...(payload?.equipmentDate ? { equipmentDate: new Date(payload.equipmentDate) } : {}),
        ...(payload?.skMenkumhamDate ? { skMenkumhamDate: new Date(payload.skMenkumhamDate) } : {}),
      })
    );
    payload?.attachment && formData.append("attachment", payload?.attachment[0]);

    axios
      .patch(`equipment/${regCode}/${activeEquipment?.equipmentId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadEquipments(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteEquipment = () => {
    setLoadingDelete(true);

    axios
      .delete(`equipment/${regCode}/${activeEquipment?.equipmentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadEquipments(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingEquipments,
        equipments,
        activeEquipment,

        equipmentTypes,
        equipmentConditions,
        equipmentOwnerships,

        isCreate,
        handleCreate,
        loadingCreate,
        createEquipment,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateEquipment,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteEquipment,
      }}
    >
      {children}
    </Provider>
  );
};

export default EquipmentController;
