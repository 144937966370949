import React from "react";
import { useTranslation } from "react-i18next";
import OfferTable from "./components/OfferTable";
import OfferController from "./Controller";

const Offer = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0">
      <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.PROCUREMENT_OFFER")}</h2>

      <OfferTable />
    </div>
  );
};

export default () => (
  <OfferController>
    <Offer />
  </OfferController>
);
