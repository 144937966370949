import { FormControl, FormLabel, Spinner } from '@chakra-ui/react';
import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FileAttachment from '../../../../../../../../../../../../components/FileAttachment';
import FormDescription from '../../../../../../../../../../../../components/inputForm/FormDescription';
import TitleBar from '../../../../../../../../../../../../components/TitleBar';
import { AdministratifEvaluationContext } from '../Controller';

const OfferFormEvaluation = () => {
	const { t } = useTranslation();
	const { offer } = useContext(AdministratifEvaluationContext);

	const fieldName = {
		offerNumber: t('LABEL.OFFER_NUMBER'),
		offerDate: t('LABEL.OFFER_DATE'),
		offerExpired: t('LABEL.OFFER_EXPIRED'),
		offerLetter: t('LABEL.OFFER_LETTER'),
		offerWorkTime: t('LABEL.OFFER_WORK_TIME'),
		offerNote: t('LABEL.OFFER_NOTE'),
	};

	return (
		<div>
			<TitleBar title={t('LABEL.CONCEPT')} />
			{!offer && (
				<div className="flex justify-center items-center" style={{ minHeight: '25vh' }}>
					<Spinner color="red.500" />
				</div>
			)}
			{offer && (
				<div className="flex justify-between gap-5">
					<div className="w-1/2 flex flex-col gap-5">
						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerNumber}</FormLabel>

							<FormDescription>{offer?.offerNumber}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerDate}</FormLabel>

							<FormDescription>{moment(new Date(offer?.startAt)).format('yyyy-MM-DD')}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerExpired}</FormLabel>

							<FormDescription>{moment(new Date(offer?.expiredAt)).format('yyyy-MM-DD')}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerLetter}</FormLabel>

							<FormDescription>
                <FileAttachment href={`${process.env.REACT_APP_FILESERVICE_API}/files/users/${offer?.letterFilename}`} />
							</FormDescription>
						</FormControl>
					</div>
					<div className="w-1/2 flex flex-col gap-5">
						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerWorkTime}</FormLabel>

							<FormDescription>{offer?.workTime}</FormDescription>
						</FormControl>

						<FormControl>
							<FormLabel className={`text-red-500 uppercase font-semibold text-xs`}>{fieldName.offerNote}</FormLabel>

							<FormDescription>{offer?.note}</FormDescription>
						</FormControl>
					</div>
				</div>
			)}
			{!offer && <div className="flex justify-center items-center h-20 font-bold text-color0">{t('ERROR.undefined')}</div>}
		</div>
	);
};

export default OfferFormEvaluation;
