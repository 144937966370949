import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NegotiationContext } from "../../Controller";

const ModalApprove = () => {
  const { t } = useTranslation();
  const {
    handleUpdateApproveBidOpen,
    isBidApproveOpenUpdate,
    loadingBidApproveUpdate,
    updateStatusApproveBid,
  } = useContext(NegotiationContext);

  return (
    <Modal
      onClose={() => handleUpdateApproveBidOpen(false)}
      size="md"
      isOpen={isBidApproveOpenUpdate}
    >
      <ModalOverlay />
      <ModalContent className="bg-opacity-80">
        <ModalHeader>{t("LABEL.PROCUREMENT_NEGOTIATION_APPROVE")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("TEXT.APPROVE_CONFIRMATION")}</ModalBody>
        <ModalFooter className="flex gap-2">
          <Button onClick={() => handleUpdateApproveBidOpen(false)}>
            {t("LABEL.CANCEL")}
          </Button>
          <Button
            isLoading={loadingBidApproveUpdate}
            onClick={updateStatusApproveBid}
            className="transition-all duration-500"
          >
            {t("LABEL.APPROVED")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalApprove;
