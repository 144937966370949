import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import { publicRouteSlug } from "../../../routes/publicRoutes";

export const ActivationContext = createContext();
const { Provider } = ActivationContext;

const ActivationController = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activationKey = searchParams.get("t");
  const isNewPassword = !!searchParams.get("newPassword");
  const [currentPage, setCurrentPage] = useState();

  const activateAccount = async (password = undefined) => {
    return await axios.post("/user/activate-account", { activationKey, password });
  };

  // Activate Account
  const [loadingActivate, setLoadingActivate] = useState(false);
  useEffect(() => {
    let mounted = true;

    if (!activationKey) navigate(publicRouteSlug.LOGIN);

    if (!isNewPassword) {
      setLoadingActivate(true);

      activateAccount()
        .then(() => {
          mounted && setCurrentPage("complete");
        })
        .catch(() => {
          navigate(publicRouteSlug.LOGIN);
        })
        .finally(() => {
          mounted && setLoadingActivate(false);
        });
    } else {
      setCurrentPage("newPassword");
    }

    return () => {
      mounted = false;
    };
  }, [activationKey]);

  // Create New Password
  const form = useForm();
  const [loadingCreatePassword, setLoadingCreatePassword] = useState(false);
  const createPassword = (payload) => {
    setLoadingCreatePassword(true);

    activateAccount(payload?.password)
      .then(() => {
        setCurrentPage("complete");
      })
      .finally(() => {
        setLoadingCreatePassword(false);
      });
  };

  return (
    <Provider value={{ loadingActivate, currentPage, form, createPassword, loadingCreatePassword }}>
      {children}
    </Provider>
  );
};

export default ActivationController;
