import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { CompanyProfileContext } from "../../../Controller";

export const TaxContext = createContext();
const { Provider } = TaxContext;

const TaxController = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { userData, token } = useContext(AuthContext);
  const { getSubmitApprovalFails, regCode, getCompanyNeedReapprovals } = useContext(CompanyProfileContext);

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        taxType: newForm?.taxType?.taxType,
        taxNumber: newForm?.taxNumber,
      });
    }
  };

  // -------------------------------
  // Detail Menu
  // -------------------------------

  const [isDetailMenu, setDetailMenu] = useState(false);
  const [activeTax, setActiveTax] = useState();
  const handleDetailMenu = (isOpen, tax = null) => {
    setDetailMenu(isOpen);
    setActiveTax(tax);
    isOpen && resetForm(tax);
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Taxes
  const [taxes, setTaxes] = useState([]);
  const [loadingTaxes, setLoadingTaxes] = useState(false);
  const [reloadTaxes, setReloadTaxes] = useState(true);
  useEffect(() => {
    let mounted = true;

    const getTaxes = async () => {
      setLoadingTaxes(true);
      axios
        .get(`/tax/${regCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newTaxes = res?.data?.taxes;
            setTaxes(newTaxes);

            if (activeTax) {
              const newActiveTax = newTaxes.find((tax) => tax?.taxId === activeTax?.taxId) || null;
              setActiveTax(newActiveTax);
              resetForm(newActiveTax);
            }
          }
        })
        .finally(() => {
          setReloadTaxes(false);
          setLoadingTaxes(false);
        });
    };

    userData && token && reloadTaxes && getTaxes();

    if (reloadTaxes) {
      getSubmitApprovalFails();
      getCompanyNeedReapprovals();
    }

    return () => {
      mounted = false;
    };
  }, [userData, token, reloadTaxes]);

  // -------------------------------
  // Master
  // -------------------------------

  // Tax Type
  const [taxTypes, setTaxTypes] = useState([]);
  useEffect(() => {
    let mounted = true;

    axios.get(`/tax-type`).then((res) => {
      if (mounted) {
        setTaxTypes(res?.data?.taxType);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // -------------------------------
  // Submit Approval Fail
  // -------------------------------
  useEffect(() => {
    if (reloadTaxes) getSubmitApprovalFails();
  }, [reloadTaxes]);

  // -------------------------------
  // Create Data
  // -------------------------------
  const [isCreate, setCreate] = useState(false);
  const handleCreate = (isNewCreate) => {
    setCreate(isNewCreate);
    isNewCreate && resetForm({});
  };
  const [loadingCreate, setLoadingCreate] = useState(false);
  const createTax = (payload) => {
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    formData.append("attachment", payload?.attachment?.[0]);

    axios
      .post(`tax/${regCode}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setCreate(false);
        setReloadTaxes(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingCreate(false);
      });
  };

  // -------------------------------
  // Update Data
  // -------------------------------

  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    !isEdit && resetForm(activeTax);
  }, [isEdit]);

  // Update
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const updateTax = (payload) => {
    setLoadingUpdate(true);

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    payload?.attachment && formData.append("attachment", payload?.attachment[0]);

    axios
      .patch(`tax/${regCode}/${activeTax?.taxId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        setReloadTaxes(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteTax = () => {
    setLoadingDelete(true);

    axios
      .delete(`tax/${regCode}/${activeTax?.taxId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setEdit(false);
        handleDetailMenu(false);
        setReloadTaxes(true);
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
        setModalDeleteOpen(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <Provider
      value={{
        form,
        loadingTaxes,
        taxes,
        activeTax,

        taxTypes,

        isCreate,
        handleCreate,
        loadingCreate,
        createTax,

        isDetailMenu,
        handleDetailMenu,
        isEdit,
        setEdit,
        loadingUpdate,
        updateTax,

        isModalDeleteOpen,
        setModalDeleteOpen,
        loadingDelete,
        deleteTax,
      }}
    >
      {children}
    </Provider>
  );
};

export default TaxController;
