import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ReqRequisitionNew from "./components/ReqRequisitionNew";
import ReqRequisitionFilter from "./components/ReqRequisitionFilter";
import ReqRequisitionTable from "./components/ReqRequisitionTable";
import ReqRequisitionController, { ReqRequisitionContext } from "./Controller";
import ReqRequisitionDetail from "./components/ReqRequisitionDetail";

const ReqRequisition = () => {
  const { t } = useTranslation();
  const { isDetailMenu, isCreate } = useContext(ReqRequisitionContext);

  return (
    <div className="flex flex-col gap-4 md:gap-8 p-4 md:p-0" style={{ maxHeight: "100%" }}>
      <div className="flex items-center gap-3">
        <h2 className="montserrat text-2xl text-gray-700 font-medium">{t("LABEL.REQ_REQUISITION")}</h2>
      </div>

      {isCreate ? (
        <ReqRequisitionNew />
      ) : isDetailMenu ? (
        <ReqRequisitionDetail />
      ) : (
        <div className="grid md:grid-cols-4 gap-4 md:gap-8">
          <div>
            <ReqRequisitionFilter />
          </div>
          <div className="md:col-span-3">
            <ReqRequisitionTable />
          </div>
        </div>
      )}
    </div>
  );
};

export default () => (
  <ReqRequisitionController>
    <ReqRequisition />
  </ReqRequisitionController>
);
