import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { AuctionLiveContext } from "../Controller";
import { AuctionContext } from "../../../../Controller";
import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import numeral from "numeral";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";

const Row = ({ data }) => {
  const { auction } = useContext(AuctionContext);
  const currency = auction?.procurement?.currency;
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <>
      <Tr className="cursor-pointer bg-gray-50" onClick={() => setCollapsed(!isCollapsed)}>
        <Td>{moment(data?.date).format("DD/MM/YYYY H:m:s")}</Td>
        <Td className="font-semibold">
          {numeral(data?.totalPrice).format("0,0.00")} {currency?.currency}
        </Td>
        <Td className="w-5 px-4">{isCollapsed ? <BsCaretUpFill /> : <BsCaretDownFill />}</Td>
      </Tr>

      {isCollapsed &&
        data?.history?.map((auctionBid, iAuctionBid) => (
          <Tr key={iAuctionBid}>
            <Td colSpan={3}>
              <div className="flex justify-between items-center">
                <span>{auctionBid?.auctionMaterial?.reqRequisitionMaterial?.material?.description}</span>
                <span>
                  {numeral(+auctionBid?.price).format("0,0.00")} x{" "}
                  {auctionBid?.auctionMaterial?.reqRequisitionMaterial?.quantity}
                </span>
                <span className="font-semibold">
                  {numeral(auctionBid?.subPrice).format("0,0.00")}{" "}
                  {auctionBid?.auctionMaterial?.reqRequisitionMaterial?.currency?.currency}
                </span>
              </div>
            </Td>
          </Tr>
        ))}
    </>
  );
};

const History = () => {
  const { t } = useTranslation();
  const { bidHistory, loadingBidHistory } = useContext(AuctionLiveContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
      <div className="w-full bg-white rounded-md text-sm p-4">
        <div>
          <div className="mb-2 flex justify-between items-center">
            <span className="font-semibold text-gray-700" style={{ borderBottom: "1px solid #777" }}>
              {t("LABEL.BID_HISTORY")}
            </span>
          </div>

          <Table variant="simple">
            <Tbody>
              {loadingBidHistory ? (
                <Tr>
                  <Td colSpan={5}>
                    <div className="w-full h-full flex justify-center items-center">
                      <Spinner color="red.500" />
                    </div>
                  </Td>
                </Tr>
              ) : !bidHistory.length ? (
                <Tr>
                  <Td colSpan={5} className="text-center">
                    <p className="text-sm text-gray-500">{t("TEXT.DATA_NOT_FOUND")}</p>
                  </Td>
                </Tr>
              ) : (
                bidHistory.map((history, iHistory) => <Row key={iHistory} data={history} />)
              )}
            </Tbody>
          </Table>
        </div>
      </div>
    </motion.div>
  );
};

export default History;
