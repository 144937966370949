import { Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalContext } from "../Controller";
import { BiPlus } from "react-icons/bi";

const ButtonFirstApproval = () => {
  const { t } = useTranslation();
  const { isInputFirstLevel, handleCreate } = useContext(ApprovalContext);

  const conditionApproval = isInputFirstLevel === undefined ? false : true;

  return (
    <Button
      size="sm"
      onClick={() => handleCreate(true, true, false)}
      disabled={conditionApproval}
    >
      <BiPlus className="mr-1" />{" "}
      {t("LABEL.ADD", { field: t("LABEL.PROCUREMENT_APPROVAL") })}
    </Button>
  );
};

export default ButtonFirstApproval;
