import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalDelete = ({ isOpen, onNo, onYes, title, body, loading }) => {
	const { t } = useTranslation();

	return (
		<Modal
			onClose={() => {
				typeof onNo === 'function' && onNo();
			}}
			size="md"
			isOpen={isOpen}
		>
			<ModalOverlay />
			<ModalContent className="bg-red-500 text-white bg-opacity-80">
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{body}</ModalBody>
				<ModalFooter className="flex gap-2">
					<Button
						onClick={() => {
							typeof onNo === 'function' && onNo();
						}}
						colorScheme="whiteAlpha"
					>
						{t('LABEL.CANCEL')}
					</Button>
					<Button
						isLoading={loading}
						onClick={() => {
							typeof onYes === 'function' && onYes();
						}}
						colorScheme="blackAlpha"
					>
						{t('LABEL.DELETE', { field: '' })}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ModalDelete;
