import { Button, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsArrowLeftCircle } from "react-icons/bs";
import PrivateComponent from "../../../../../components/PrivateComponent";
import { RequisitionerContext } from "../Controller";
import Form from "./Form";
import ModalDelete from "./ModalDelete";

const RequisitionerDetail = () => {
  const { t } = useTranslation();
  const {
    isEdit,
    setEdit,
    handleDetailMenu,
    form,
    loadingUpdate,
    updateRequisitioner,
    activeRequisitioner,
    setModalDeleteOpen,
    isValidRequisitionerCode,
    loadingValidationRequisitionerCode,
  } = useContext(RequisitionerContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <>
          {isEdit ? (
            <div className="md:col-span-2 flex justify-end items-center gap-2">
              <div className="flex gap-2">
                <Button size="xs" onClick={() => setEdit(false)}>
                  {t("LABEL.CANCEL")}
                </Button>

                <Button
                  size="xs"
                  colorScheme="teal"
                  disabled={!isValidRequisitionerCode || loadingValidationRequisitionerCode}
                  isLoading={loadingUpdate}
                  onClick={form.handleSubmit(updateRequisitioner)}
                >
                  {t("LABEL.SAVE")}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <PrivateComponent accessName="BUYER_EDIT">
                <div className="md:col-span-2 flex justify-between items-center gap-2">
                  <button onClick={() => handleDetailMenu(false)}>
                    <BsArrowLeftCircle className="text-xl text-gray-600" />
                  </button>
                  <div className="flex gap-2">
                    <Tooltip hasArrow label={t("TOOLTIP.EDIT")} placement="top">
                      <button onClick={() => setEdit(true)}>
                        <BiEdit className="text-lg text-gray-500" />
                      </button>
                    </Tooltip>
                    {!activeRequisitioner?.roles?.includes("Super Admin") && (
                      <Tooltip hasArrow label={t("TOOLTIP.DELETE")} placement="top">
                        <button onClick={() => setModalDeleteOpen(true)}>
                          <BiTrash className="text-lg text-red-500" />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </PrivateComponent>
            </>
          )}
        </>
      </div>
      <div className="md:col-span-2 flex justify-between items-center gap-2 py-8">
        <Form isEdit={isEdit} />
        <ModalDelete />
      </div>
    </motion.div>
  );
};

export default RequisitionerDetail;
