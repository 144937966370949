import { BrowserRouter, useRoutes } from "react-router-dom";
import AuthController from "../controllers/auth/AuthController";
import FileserviceController from "../controllers/FileserviceController";
import HelperController from "../controllers/HelperController";
import NotificationController from "../controllers/NotificationController";
import publicRoutes from "./publicRoutes";
import userRoutes from "./userRoutes";

const MainRoutes = () => {
  // Using useRoutes() and route object to enable dynamic route in different files
  const routes = [...publicRoutes, ...userRoutes];
  const renderRoutes = useRoutes(routes);

  return (
    <AuthController>
      <HelperController>
        <NotificationController>
          <FileserviceController>{renderRoutes}</FileserviceController>
        </NotificationController>
      </HelperController>
    </AuthController>
  );
};

export default () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);
