import React, { useContext } from "react";
import LogoWhite from "../../../assets/img/static/logo/logo-white.png";
import { Link } from "react-router-dom";
import TranslationHandler from "../../../components/TranslationHandler";
import { publicRouteSlug } from "../../../routes/publicRoutes";

const BaseCard = ({ children }) => {
  return (
    <div className="relative z-1 flex flex-col" style={{ minHeight: "100vh" }}>
      <div
        className="absolute w-full h-full bg-gradient-primary"
        style={{ clipPath: "polygon(0 0, 100% 0, 100% 40%, 0% 70%)", zIndex: -1, maxHeight: "100vh" }}
      />

      <div className="flex justify-between items-center p-6 md:px-20">
        <div>
          <Link to={publicRouteSlug.HOME} className="py-1">
            <img src={LogoWhite} className="w-32" />
          </Link>
        </div>
        <div>
          <TranslationHandler className="text-white" />
        </div>
      </div>

      <div className="flex-1 w-full h-full flex px-4 md:px-12 pb-12">
        <div
          className="w-full bg-white rounded-xl flex flex-col overflow-hidden"
          style={{ boxShadow: "4px 4px 40px rgba(0,0,0,0.2)" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default BaseCard;
