import { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../../../../controllers/auth/AuthController';
import { HelperContext } from '../../../../../controllers/HelperController';
import { API_URLS } from '../../../../../utils/apiURLs.util';
import { filterBuilder } from '../../../../../utils/filterBuilder';

export const OpenContext = createContext();
const { Provider } = OpenContext;

const OpenController = ({ children }) => {
	const { userData, token } = useContext(AuthContext);
	const { httpRequest } = useContext(HelperContext);
	const regCode = userData?.company?.registrationCode;

	const [pagination, setPagination] = useState({
		totalData: 0,
		limit: 10,
	});
	const tableForm = useForm({
		defaultValues: {
			page: 1,
		},
	});

	const tablePage = tableForm.watch('page');

	// Procurement
	const [procurements, setProcurements] = useState([]);
	const [loadingProcurements, setLoadingProcurements] = useState(false);
	useEffect(() => {
		if (!userData) return;
		let mounted = true;

		(async () => {
			const filter = filterBuilder({
				limit: pagination.limit,
				page: 1,
			});
			setLoadingProcurements(true);
			const { response } = await httpRequest({
				url: `${API_URLS.REACT_APP_TMS_API}/procurement/open/${regCode}?${filter}`,
			});
			if (!mounted) return;
			setLoadingProcurements(false);
			if (!response?.procurements) return;

			setProcurements(response.procurements);
			setPagination({
				totalData: response?.pagination?.totalData || 0,
				limit: 10,
			});
		})();

		return () => {
			mounted = false;
		};
	}, [userData, token, tablePage]);

	return (
		<Provider
			value={{
				procurements,
				setProcurements,
				loadingProcurements,
				tableForm,
				pagination,
			}}
		>
			{children}
		</Provider>
	);
};

export default OpenController;
