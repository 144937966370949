import React, { useContext } from "react";
import { motion } from "framer-motion";
import { BiCrown } from "react-icons/bi";
import { AuctionLiveContext } from "../Controller";
import { AuctionContext } from "../../../../Controller";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const WinnerPrice = () => {
  const { t } = useTranslation();
  const { auction } = useContext(AuctionContext);
  const { winningPrice, auctionStatus } = useContext(AuctionLiveContext);
  const currency = auction?.procurement?.currency?.currency;

  const price = winningPrice?.subPrice || winningPrice;

  return winningPrice && auction?.isBestPriceVisible && auctionStatus === "finish" ? (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="flex flex-col gap-4">
      <div className="w-full bg-white rounded-md text-sm p-4 flex items-center gap-2">
        <BiCrown className="text-xl text-yellow-500" />
        <span className="font-semibold">{t("LABEL.WINNING_PRICE")}: </span>
        <span className="font-semibold">
          {numeral(price).format("0,0.00")} {currency}
        </span>
      </div>
    </motion.div>
  ) : (
    <></>
  );
};

export default WinnerPrice;
