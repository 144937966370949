import React, { useContext } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CertificateContext } from "../Controller";
import Select from "../../../../../../../components/inputForm/Select";
import _ from "lodash";
import Datepicker from "../../../../../../../components/inputForm/Datepicker";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import { InputText } from "primereact/inputtext";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const { isCreate, loadingCompany, activeCertificate, form, certificateTypes, certificates } =
    useContext(CertificateContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.COMPANY_DEED") }) }),
    },
  });

  return (
    <>
      {/* Certificate Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CERTIFICATE_TYPE")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.CERTIFICATE_TYPE") }) },
            }}
            name="certificateType"
            options={certificateTypes.map((certificateType) => ({
              label: t(certificateType?.labelI18n || certificateType?.label),
              value: certificateType?.certificateType,
            }))}
            placeholder={`${t("LABEL.CHOOSE")} ${t("LABEL.CERTIFICATE_TYPE")}`}
            isError={!!errors?.certificateType}
          />
        ) : loadingCompany ? (
          <Skeleton startColor="gray.100" endColor="gray.200" height="26px" />
        ) : (
          <FormDescription>
            {activeCertificate?.certificateType?.labelI18n
              ? t(activeCertificate?.certificateType?.labelI18n)
              : activeCertificate?.certificateType?.label}
          </FormDescription>
        )}

        {errors?.certificateType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.certificateType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Certificate Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.CERTIFICATION") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.CERTIFICATION"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeCertificate?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Description */}
      <FormControl className="md:col-span-2" isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DESCRIPTION")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.description && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DESCRIPTION")}
            {...register("description", { required: t("INVALID.REQUIRED", { field: t("LABEL.DESCRIPTION") }) })}
          />
        ) : (
          <FormDescription>{activeCertificate?.description}</FormDescription>
        )}
        {errors?.description && (
          <FormHelperText className="text-red-400 text-xs">{errors?.description?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Valid From */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VALID_FROM")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="validFrom"
            placeholder={t("LABEL.VALID_FROM")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.VALID_FROM") }) },
            }}
            isError={!!errors?.validFrom}
          />
        ) : (
          <FormDescription>{moment(new Date(activeCertificate?.validFrom)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.validFrom && (
          <FormHelperText className="text-red-400 text-xs">{errors?.validFrom?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Valid Until */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.VALID_UNTIL")}
        </FormLabel>
        {isEdit ? (
          <Datepicker
            name="validUntil"
            placeholder={t("LABEL.VALID_UNTIL")}
            control={control}
            controlProps={{
              rules: { required: t("INVALID.REQUIRED", { field: t("LABEL.VALID_UNTIL") }) },
            }}
            isError={!!errors?.validUntil}
          />
        ) : (
          <FormDescription>{moment(new Date(activeCertificate?.validUntil)).format("DD/MM/YYYY")}</FormDescription>
        )}
        {errors?.validUntil && (
          <FormHelperText className="text-red-400 text-xs">{errors?.validUntil?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
