import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";
import { userRouteSlug } from "../../../../../../routes/userRoutes";
import { AuctionContext } from "../../../Controller";

export const GeneralContext = createContext();
const GeneralController = ({ children }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { token } = useContext(AuthContext);
  const { auction: activeAuction } = useContext(AuctionContext);
  const navigate = useNavigate();

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        ...newForm,
        timeStart: newForm?.timeStart ? new Date(newForm?.timeStart) : null,
        timeEnd: newForm?.timeEnd ? new Date(newForm?.timeEnd) : null,
        isIncrement: newForm?.isIncrement || true,
        isBestPriceVisible: newForm?.isBestPriceVisible || true,
        isTopRankVisible: newForm?.isTopRankVisible || true,
        winnerEvaluationMethod: newForm?.winnerEvaluationMethod?.winnerEvaluationMethod,
        rankMethod: newForm?.rankMethod?.rankMethod,
      });
    }
  };
  useEffect(() => {
    resetForm(activeAuction);
  }, [activeAuction]);

  // ---------------------------
  // Master
  // ---------------------------

  // Winner Evaluation Method
  const [winnerEvaluationMethods, setWinnerEvaluationMethods] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/winner-evaluation-method`).then((res) => {
        if (mounted) {
          setWinnerEvaluationMethods(res?.data?.winnerEvaluationMethods);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Rank Method
  const [rankMethods, setRankMethods] = useState([]);
  useEffect(() => {
    let mounted = true;

    token &&
      axios.get(`${process.env.REACT_APP_TMS_API}/auction-rank-method`).then((res) => {
        if (mounted) {
          setRankMethods(res?.data?.rankMethods);
        }
      });

    return () => {
      mounted = false;
    };
  }, [token]);

  // Req Requisition
  const [reqRequisition, setReqRequisition] = useState([]);
  useEffect(() => {
    let mounted = true;

    const getReqRequisition = async () => {
      axios
        .get(`${process.env.REACT_APP_TMS_API}/req-requisition`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newReqRequisition = res?.data?.reqRequisitions;
            setReqRequisition(newReqRequisition);
          }
        });
    };

    token && getReqRequisition();

    return () => {
      mounted = false;
    };
  }, [token]);

  // -------------------------------
  // Delete Data
  // -------------------------------

  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteAuction = () => {
    setLoadingDelete(true);

    axios
      .delete(`${process.env.REACT_APP_TMS_API}/procurement-auction/${activeAuction?.procurementAuctionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast({
          description: t(res?.data?.success),
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        navigate(
          userRouteSlug.PANEL +
            userRouteSlug.PROCUREMENT +
            `/${activeAuction?.procurement?.procurementNumber}/detail/auction`
        );
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  return (
    <GeneralContext.Provider
      value={{
        form,
        activeAuction,
        winnerEvaluationMethods,
        rankMethods,
        reqRequisition,

        loadingDelete,
        deleteAuction,
        isModalDeleteOpen,
        setModalDeleteOpen,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralController;
