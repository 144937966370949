import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import CompetencyDetail from "./components/CompetencyDetail";
import CompetencyList from "./components/CompetencyList";
import CompetencyNew from "./components/CompetencyNew";
import CompetencyController, { CompetencyContext } from "./Controller";

const Competency = () => {
  const { isDetailMenu, isCreate } = useContext(CompetencyContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <CompetencyNew /> : isDetailMenu ? <CompetencyDetail /> : <CompetencyList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <CompetencyController>
    <Competency />
  </CompetencyController>
);
