import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import { ProcurementContext } from "../../Controller";

export const ReqRequisitionContext = createContext();
const { Provider } = ReqRequisitionContext;

const ReqRequisitionController = ({ children }) => {
  const { token } = useContext(AuthContext);
  const { reqRequisitionMaterialsForm: form } = useContext(ProcurementContext);

  // ---------------------------
  // Data
  // ---------------------------

  const [totalData, setTotalData] = useState(0);
  const [limit] = useState(10);

  // Filter
  const tableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });
  const watch = useWatch({
    control: tableForm.control,
    defaultValue: null,
  });
  let [filterTimeout, setFilterTimeput] = useState();
  useEffect(() => {
    if (!watch) return;

    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setReloadReqRequisition(true);
    }, 500);
    setFilterTimeput(newTimeout);
  }, [watch]);

  // Req Requisition
  const [reqRequisition, setReqRequisition] = useState([]);
  const [loadingReqRequisition, setLoadingReqRequisition] = useState(false);
  const [reloadReqRequisition, setReloadReqRequisition] = useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = tableForm.getValues();

    // Filter
    const search = `search=${payload?.search || ""}`;
    const limit = `limit=${10}`;
    const page = `page=${payload?.page}`;
    const isAvailable = `status=available`;
    const filter = `?${search}&${limit}&${page}&${isAvailable}`;

    const getReqRequisition = async () => {
      setLoadingReqRequisition(true);
      axios
        .get(`${process.env.REACT_APP_TMS_API}/req-requisition${filter}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (mounted) {
            const newReqRequisition = res?.data?.reqRequisitions;
            setReqRequisition(newReqRequisition);
            setTotalData(res?.data?.pagination?.totalData);
          }
        })
        .finally(() => {
          setReloadReqRequisition(false);
          setLoadingReqRequisition(false);
        });
    };

    token && reloadReqRequisition && getReqRequisition();

    return () => {
      mounted = false;
    };
  }, [token, reloadReqRequisition]);

  return (
    <Provider
      value={{
        tableForm,
        reqRequisition,
        loadingReqRequisition,
        totalData,
        limit,

        form,
      }}
    >
      {children}
    </Provider>
  );
};

export default ReqRequisitionController;
