import React from "react";
import { useTranslation } from "react-i18next";

const FormDescription = ({ children = "" }) => {
  const { t } = useTranslation();

  return (
    <span className="font-semibold text-gray-600 text-base">
      {children || <span className="text-gray-300">{t("LABEL.EMPTY")}</span>}
    </span>
  );
};

export default FormDescription;
