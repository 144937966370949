import React, { useContext } from "react";
import { BiBell } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { NotificationContext } from "../../../../controllers/NotificationController";
import { useTranslation } from "react-i18next";
import { userRouteSlug } from "../../../../routes/userRoutes";

const NotificationItem = ({ title, date, isRead }) => {
  const { nowDate } = useContext(NotificationContext);
  return (
    <div className="p-2 px-3 pr-5 flex justify-between items-center">
      <div className="flex flex-col">
        <span className="text-xs text-gray-500">{moment(date).from(nowDate)}</span>
        <span className="font-semibold">{title}</span>
      </div>
      {!isRead && <div className="w-2 h-2 bg-red-500 rounded-full" />}
    </div>
  );
};

const Notification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasNewMessage, notifications, handleOpen } = useContext(NotificationContext);

  return (
    <Menu onOpen={handleOpen}>
      <MenuButton as={Button} className="bg-transparent shadow-none text-gray-700">
        {hasNewMessage && (
          <>
            <span className="absolute right-4 w-2 h-2 bg-red-500 rounded-full animate-ping" />
            <span className="absolute right-4 w-2 h-2 bg-red-500 rounded-full" />
          </>
        )}
        <BiBell className="text-xl text-gray-800" />
      </MenuButton>
      <MenuList style={{ width: "350px" }}>
        {!!notifications?.length ? (
          notifications?.map((notification, iNotification) => (
            <NotificationItem
              key={iNotification}
              date={notification?.createdAt}
              title={notification?.title}
              isRead={notification?.isRead}
            />
          ))
        ) : (
          <div className="text-center text-gray-400 py-2">{t("TEXT.DATA_NOT_FOUND")}</div>
        )}
        <MenuItem
          onClick={() => navigate(userRouteSlug.PANEL + userRouteSlug.NOTIFICATION)}
          className="text-center text-red-500 hover:bg-white focus:bg-white"
        >
          <div className="w-full text-center">{t("LABEL.VIEW_ALL")}</div>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default Notification;
