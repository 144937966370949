import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReqProcSubmissionContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../../../../../components/inputForm/FormDescription";
import SelectButton from "../../../../../../../../../components/inputForm/SelectButton";
import Select from "../../../../../../../../../components/inputForm/Select";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { activeReqProcSubmission, form, reqProcSubmissionSteps, reqProcSubmission, stepType } =
    useContext(ReqProcSubmissionContext);

  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Deskripsi */}
      <FormControl isRequired={isEdit}>
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.PROCUREMENT_REQUIREMENT_TITLE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            name="description"
            className={`w-full text-sm ${
              errors?.description && "border-2 border-red-400 shadow-none"
            }`}
            placeholder={t("LABEL.PROCUREMENT_REQUIREMENT_TITLE")}
            {...register("description", {
              required: t("INVALID.REQUIRED", {
                field: t("LABEL.PROCUREMENT_REQUIREMENT_TITLE"),
              }),
            })}
          />
        ) : (
          <FormDescription>
            {activeReqProcSubmission?.description}
          </FormDescription>
        )}
        {errors?.description && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.description?.message}
          </FormHelperText>
        )}
      </FormControl>

      {/* Step Type */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.PROCUREMENT_REQUIREMENT_STEP")}
        </FormLabel>
        {isEdit ? (
          <Select
            filter
            control={control}
            controlProps={{
              rules: {
                required: t("INVALID.REQUIRED", {
                  field: t("LABEL.PROCUREMENT_REQUIREMENT_STEP"),
                }),
              },
            }}
            name="requirementProcurementStep"
            options={reqProcSubmissionSteps.map(
              (requirementProcurementStep) => ({
                label: t(requirementProcurementStep?.labelI18n),
                value: requirementProcurementStep?.requirementProcurementStep,
                disabled: requirementProcurementStep.requirementProcurementStep === "PRAKUALIFIKASI" && reqProcSubmission?.find(req => req.requirementProcurementStep === "PRAKUALIFIKASI")
              })
            )}
            placeholder={`${t("LABEL.CHOOSE")} ${t(
              "LABEL.PROCUREMENT_REQUIREMENT_STEP"
            )}`}
            isError={!!errors?.requirementProcurementStep}
          />
        ) : (
          <FormDescription>
            {activeReqProcSubmission?.requirementProcurementStep}
          </FormDescription>
        )}

        {errors?.requirementProcurementStep && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.requirementProcurementStep?.message}
          </FormHelperText>
        )}
      </FormControl>

      {/* Cover Type */}
      <FormControl isRequired={isEdit} className="md:col-span-1">
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.PROCUREMENT_REQUIREMENT_COVER")}
        </FormLabel>
        {isEdit ? (
          stepType === null ? (
            <InputText
              disabled
              className={`w-full text-sm ${
                errors?.requirementProcurementCover &&
                "border-2 border-red-400 shadow-none"
              }`}
              placeholder={t("LABEL.PROCUREMENT_REQUIREMENT_COVER")}
              {...register("requirementProcurementCover", {
                required: t("INVALID.REQUIRED", {
                  field: t("LABEL.PROCUREMENT_REQUIREMENT_COVER"),
                }),
              })}
            />
          ) : (
            <Select
              filter
              className="text-sm"
              control={control}
              controlProps={{
                rules: {
                  required: t("INVALID.REQUIRED", {
                    field: t("LABEL.PROCUREMENT_REQUIREMENT_COVER"),
                  }),
                },
              }}
              name="requirementProcurementCover"
              options={stepType[0]?.map((requirementProcurementCover) => ({
                label: t(requirementProcurementCover?.labelI18n),
                value: requirementProcurementCover?.requirementProcurementCover,
              }))}
              placeholder={`${t("LABEL.CHOOSE")} ${t(
                "LABEL.PROCUREMENT_REQUIREMENT_COVER"
              )}`}
              isSearchable
              isDisabled={!stepType?.length}
              isError={!!errors?.requirementProcurementCover}
            />
          )
        ) : (
          <FormDescription>
            {activeReqProcSubmission?.requirementProcurementCover}
          </FormDescription>
        )}

        {errors?.requirementProcurementCover && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.requirementProcurementCover?.message}
          </FormHelperText>
        )}
      </FormControl>

      {/* Is Required */}
      <FormControl isRequired className="md:col-span-1">
        <FormLabel className={`uppercase font-semibold text-xs`}>
          {t("LABEL.PROCUREMENT_REQUIREMENT_REQUIRED")}
        </FormLabel>
        <SelectButton
          disabled={!isEdit}
          className="w-full grid grid-cols-2"
          control={control}
          name="isRequired"
          value={form?.getValues("isRequired")}
          options={[
            { label: t("LABEL.YES"), value: true },
            { label: t("LABEL.NO"), value: false },
          ]}
        />
        {errors?.isRequired && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.isRequired?.message}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
