import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../../controllers/auth/AuthController";

export const VendoroverviewContext = createContext();
const { Provider } = VendoroverviewContext;

const VendorOverviewController = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const params = useParams();

  const regCode = params?.regCode;

  // Company Detail
  const [loadingCompanyOverview, setLoadingCompanyOverview] = useState(false);
  const [reloadCompanyOverview, setReloadCompanyOverview] = useState(true);
  const [companyOverview, setCompanyOverview] = useState();
  useEffect(() => {
    let mounted = true;

    const getCompanyOverview = () => {
      setLoadingCompanyOverview(true);
      axios
        .get(`company/${regCode}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (mounted) {
            setCompanyOverview(res?.data?.company);
          }
        })
        .finally(() => {
          setLoadingCompanyOverview(false);
          setReloadCompanyOverview(false);
        });
    };

    token && regCode && reloadCompanyOverview && getCompanyOverview();

    return () => {
      mounted = true;
    };
  }, [regCode, token, reloadCompanyOverview]);

  return <Provider value={{ loadingCompanyOverview, companyOverview }}>{children}</Provider>;
};

export default VendorOverviewController;
