import React, { useContext } from "react";
import { Button, FormControl, FormHelperText, FormLabel, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { EquipmentContext } from "../Controller";
import _ from "lodash";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import Uploader from "../../../../../../../components/inputForm/Uploader";
import { BiDownload } from "react-icons/bi";
import { FileserviceContext } from "../../../../../../../controllers/FileserviceController";
import FormDescription from "../../../../../../../components/inputForm/FormDescription";
import SelectButton from "../../../../../../../components/inputForm/SelectButton";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { downloadFile } = useContext(FileserviceContext);
  const {
    isCreate,
    loadingCompany,
    activeEquipment,
    form,
    equipmentTypes,
    equipmentConditions,
    equipmentOwnerships,
    equipments,
  } = useContext(EquipmentContext);
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  register("attachment", {
    required: {
      value: isCreate,
      message: t("INVALID.REQUIRED", { field: t("LABEL.ATTACHMENT", { field: t("LABEL.EQUIPMENT") }) }),
    },
  });

  return (
    <>
      {/* Equipment Type */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.EQUIPMENT_TYPE")}
        </FormLabel>
        {isEdit ? (
          <SelectButton
            className="w-full grid grid-cols-2"
            control={control}
            name="equipmentType"
            value={form?.getValues("equipmentType")}
            options={equipmentTypes.map((equipmentType) => ({
              label: t(equipmentType?.labelI18n || equipmentType?.label),
              value: equipmentType?.equipmentType,
            }))}
          />
        ) : (
          <FormDescription>
            {activeEquipment?.equipmentType?.labelI18n
              ? t(activeEquipment?.equipmentType?.labelI18n)
              : activeEquipment?.equipmentType?.label}
          </FormDescription>
        )}
        {errors?.equipmentType && (
          <FormHelperText className="text-red-400 text-xs">{errors?.equipmentType?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Description */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.DESCRIPTION")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${errors?.description && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.DESCRIPTION")}
            {...register("description", { required: t("INVALID.REQUIRED", { field: t("LABEL.DESCRIPTION") }) })}
          />
        ) : (
          <FormDescription>{activeEquipment?.description}</FormDescription>
        )}
        {errors?.description && (
          <FormHelperText className="text-red-400 text-xs">{errors?.description?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Quantity */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.QUANTITY")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.quantity && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.QUANTITY")}
            type="number"
            {...register("quantity", { required: t("INVALID.REQUIRED", { field: t("LABEL.QUANTITY") }) })}
          />
        ) : (
          <FormDescription>{activeEquipment?.quantity}</FormDescription>
        )}
        {errors?.quantity && (
          <FormHelperText className="text-red-400 text-xs">{errors?.quantity?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Capacity */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CAPACITY")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.capacity && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.CAPACITY")}
            type="number"
            {...register("capacity", { required: t("INVALID.REQUIRED", { field: t("LABEL.CAPACITY") }) })}
          />
        ) : (
          <FormDescription>{activeEquipment?.capacity}</FormDescription>
        )}
        {errors?.capacity && (
          <FormHelperText className="text-red-400 text-xs">{errors?.capacity?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Brand */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.BRAND")}
        </FormLabel>
        {isEdit ? (
          <InputText
            className={`w-full text-sm ${errors?.brand && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.BRAND")}
            {...register("brand", { required: t("INVALID.REQUIRED", { field: t("LABEL.BRAND") }) })}
          />
        ) : (
          <FormDescription>{activeEquipment?.brand}</FormDescription>
        )}
        {errors?.brand && <FormHelperText className="text-red-400 text-xs">{errors?.brand?.message}</FormHelperText>}
      </FormControl>

      {/* Condition */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.CONDITION")}
        </FormLabel>
        {isEdit ? (
          <SelectButton
            className="w-full grid grid-cols-2"
            control={control}
            name="condition"
            value={form?.getValues("condition")}
            options={equipmentConditions.map((equipmentCondition) => ({
              label: t(equipmentCondition?.labelI18n || equipmentCondition?.label),
              value: equipmentCondition?.equipmentCondition,
            }))}
          />
        ) : (
          <FormDescription>
            {activeEquipment?.condition?.labelI18n
              ? t(activeEquipment?.condition?.labelI18n)
              : activeEquipment?.condition?.label}
          </FormDescription>
        )}
        {errors?.condition && (
          <FormHelperText className="text-red-400 text-xs">{errors?.condition?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Location */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.LOCATION")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${errors?.location && "border-2 border-red-400 shadow-none"}`}
            placeholder={t("LABEL.LOCATION")}
            {...register("location", { required: t("INVALID.REQUIRED", { field: t("LABEL.LOCATION") }) })}
          />
        ) : (
          <FormDescription>{activeEquipment?.location}</FormDescription>
        )}
        {errors?.location && (
          <FormHelperText className="text-red-400 text-xs">{errors?.location?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Ownership */}
      <FormControl isRequired>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.OWNERSHIP")}
        </FormLabel>
        {isEdit ? (
          <SelectButton
            className="w-full grid grid-cols-2"
            control={control}
            name="ownership"
            value={form?.getValues("ownership")}
            options={equipmentOwnerships.map((equipmentOwnership) => ({
              label: t(equipmentOwnership?.labelI18n || equipmentOwnership?.label),
              value: equipmentOwnership?.equipmentOwnership,
            }))}
          />
        ) : (
          <FormDescription>
            {activeEquipment?.ownership?.labelI18n
              ? t(activeEquipment?.ownership?.labelI18n)
              : activeEquipment?.ownership?.label}
          </FormDescription>
        )}
        {errors?.ownership && (
          <FormHelperText className="text-red-400 text-xs">{errors?.ownership?.message}</FormHelperText>
        )}
      </FormControl>

      {/* Equipment Attachment */}
      <FormControl isRequired={isCreate}>
        <FormLabel className={`uppercase font-semibold text-xs ${!isEdit && "text-red-500"}`}>
          {t("LABEL.ATTACHMENT", { field: t("LABEL.EQUIPMENT") })}
        </FormLabel>
        {isEdit ? (
          <Uploader
            className={`${errors?.attachment && "border-2 border-red-400 shadow-none"}`}
            label={`${isCreate ? t("LABEL.UPLOAD") : t("LABEL.CHANGE", { field: "" })} ${t("LABEL.ATTACHMENT", {
              field: t("LABEL.EQUIPMENT"),
            })}`}
            name="attachment"
            fileList={form.getValues("attachment")}
            onChange={(files) => {
              setValue("attachment", files);
              trigger();
            }}
          />
        ) : (
          <Button className="w-full" size="sm" onClick={() => downloadFile(activeEquipment?.attachment)}>
            <BiDownload className="mr-2" />
            {t("LABEL.DOWNLOAD")}
          </Button>
        )}
        {errors?.attachment && (
          <FormHelperText className="text-red-400 text-xs">{errors?.attachment?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
