import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileserviceContext } from "../../../controllers/FileserviceController";

export const NavbarContext = createContext();
const { Provider } = NavbarContext;

const NavbarController = ({ children }) => {
  const { downloadFile } = useContext(FileserviceContext);

  const { t } = useTranslation();
  const navbarItems = [
    { name: "About", label: t("LABEL.ABOUT") },
    { name: "Terms", label: t("LABEL.TERMS") },
    { name: "FAQ", label: "FAQ" },
    {
      name: "Guide",
      label: t("LABEL.GUIDE"),
      onClick: () => downloadFile("VMS-Guide", "public"),
    },
  ];

  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);

  return <Provider value={{ navbarItems, openMobileSidebar, setOpenMobileSidebar }}>{children}</Provider>;
};

export default NavbarController;
