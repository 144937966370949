import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ApprovalContext } from "../Controller";
import _ from "lodash";
import { InputTextarea } from "primereact/inputtextarea";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { form } = useContext(ApprovalContext);
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Notes */}
      <FormControl isRequired={isEdit}>
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.NOTES")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${
              errors?.notes && "border-2 border-red-400 shadow-none"
            }`}
            placeholder={t("LABEL.NOTES")}
            {...register("notes", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.NOTES") }),
            })}
            rows={5}
            cols={30}
          />
        ) : (
          ""
        )}
        {errors?.notes && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.notes?.message}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
