import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import BranchDetail from "./components/BranchDetail";
import BranchList from "./components/BranchList";
import BranchNew from "./components/BranchNew";
import BranchController, { BranchContext } from "./Controller";

const Branch = () => {
  const { isDetailMenu, isCreate } = useContext(BranchContext);

  return (
    <div>
      <AnimatePresence>{isCreate ? <BranchNew /> : isDetailMenu ? <BranchDetail /> : <BranchList />}</AnimatePresence>
    </div>
  );
};

export default () => (
  <BranchController>
    <Branch />
  </BranchController>
);
