import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userRouteSlug } from "../../routes/userRoutes";
import { AuthContext } from "./AuthController";

const PublicAuth = () => {
  const navigate = useNavigate();
  const { isLogin, userData } = useContext(AuthContext);

  // Redirect if user signed in
  useEffect(() => {
    if (isLogin === true && userData) {
      navigate(userRouteSlug.PANEL);
    }
  }, [isLogin, userData]);

  return <></>;
};

export default PublicAuth;
