import { Spinner } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheckDouble } from 'react-icons/bi';
import { OFFER_2_COVER_PHASES, OFFER_2_STEP_PHASES } from '../../../../../../../../../../utils/offerPhases.util';
import { ProcurementOfferEvaluationContext } from '../Controller';

const Navbar = () => {
	const { t, i18n } = useTranslation();
	const {
		activePageIdx,
		setActivePageIdx,
		administratifOffers,
		commercialOffers,
		loadingAdministratifOffers,
		loadingCommercialOffers,
		handleDetailAdministratifIdx,
		handleDetailCommercialIdx,
		setHandleDetailAdministratifIdx,
		setHandleDetailCommercialIdx,
		offerEvaluationPhase,
		is2Cover,
		evaluation,
	} = useContext(ProcurementOfferEvaluationContext);

	return (
		<div className="offer-navbar mb-3">
			<ul className="flex text-center">
				<li
					className={
						'select-none transition-all duration-300 h-auto flex-1 px-1 gap-1 items-center py-2 flex justify-center font-bold cursor-pointer rounded-t-md ' +
						(activePageIdx === 0 ? 'bg-color0 text-white' : 'bg-white text-color1 hover:bg-gray-200')
					}
					onClick={() => {
						setActivePageIdx(0);
					}}
				>
					{t('LABEL.OVERVIEW')}
				</li>

				<li
					className={`select-none transition-all duration-300 h-auto flex-1 px-1 gap-1 items-center py-2 flex justify-center font-bold rounded-t-md ${
						is2Cover
							? offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA
								? 'text-red-200 hover:bg-gray-200 cursor-default'
								: activePageIdx === 1
								? 'bg-color0 text-white cursor-pointer'
								: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
							: offerEvaluationPhase < OFFER_2_STEP_PHASES.TECHNICAL
							? 'text-red-200 hover:bg-gray-200 cursor-default'
							: activePageIdx === 1
							? 'bg-color0 text-white cursor-pointer'
							: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
					}`}
					onClick={() => {
						if (is2Cover && offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA) return;
						if (!is2Cover && offerEvaluationPhase < OFFER_2_STEP_PHASES.TECHNICAL) return;
						if (activePageIdx === 1) {
							if (handleDetailAdministratifIdx !== null) {
								setHandleDetailAdministratifIdx(null);
							}
						}
						setActivePageIdx(1);
					}}
				>
					<span className="relative">
						<div>{i18n.language === 'en' ? 'Administration' : 'Administratif'} &</div>
						<div>{i18n.language === 'en' ? 'Techinical' : 'Teknikal'}</div>
						<div style={{ top: '-3px', right: '-17px' }} className="flex absolute">
							{loadingAdministratifOffers && <Spinner style={{ width: '15px', height: '15px' }} color={activePageIdx === 1 ? 'white' : 'red.500'} />}
							{!loadingAdministratifOffers && administratifOffers !== null && administratifOffers !== undefined && administratifOffers.length !== 0 && (
								<div
									style={{ width: '15px', height: '15px' }}
									className={`flex items-center text-xs justify-center rounded-full ${activePageIdx == 1 ? 'bg-white text-color0' : 'bg-color0 text-white'}`}
								>
									{administratifOffers.length}
								</div>
							)}
						</div>
					</span>
				</li>

				{is2Cover !== null && !is2Cover && (
					<li
						className={`select-none transition-all duration-300 h-auto flex-1 px-1 gap-1 items-center py-2 flex justify-center font-bold rounded-t-md ${
							is2Cover
								? offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA
									? 'text-red-200 hover:bg-gray-200 cursor-default'
									: activePageIdx === 4
									? 'bg-color0 text-white cursor-pointer'
									: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
								: offerEvaluationPhase < OFFER_2_STEP_PHASES.TECHNICAL
								? 'text-red-200 hover:bg-gray-200 cursor-default'
								: activePageIdx === 4
								? 'bg-color0 text-white cursor-pointer'
								: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
						}
					`}
						onClick={() => {
							if (is2Cover && offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA) return;
							if (!is2Cover && offerEvaluationPhase < OFFER_2_STEP_PHASES.TECHNICAL) return;
							setActivePageIdx(4);
						}}
					>
						<span className="relative">
							<div>{i18n.language === 'en' ? 'Technical' : 'Evaluasi'}</div>
							<div>{i18n.language === 'en' ? 'Evaluation' : 'Teknikal'}</div>
							<div style={{ top: '-3px', right: '-20px' }} className="flex absolute">
								{evaluation && evaluation[is2Cover ? 'status' : 'statusAdministration'] === 'SUBMITTED' && <BiCheckDouble className="text-lg" color="green" />}
							</div>
						</span>
					</li>
				)}

				<li
					className={`select-none transition-all duration-300 h-auto flex-1 items-center px-1 gap-1 py-2 flex justify-center font-bold rounded-t-md ${
						is2Cover
							? offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA
								? 'text-red-200 hover:bg-gray-200 cursor-default'
								: activePageIdx === 2
								? 'bg-color0 text-white cursor-pointer'
								: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
							: offerEvaluationPhase < OFFER_2_STEP_PHASES.COMMERCIAL
							? 'text-red-200 hover:bg-gray-200 cursor-default'
							: activePageIdx === 2
							? 'bg-color0 text-white cursor-pointer'
							: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
					}`}
					onClick={() => {
						if (is2Cover && offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA) return;
						if (!is2Cover && offerEvaluationPhase < OFFER_2_STEP_PHASES.COMMERCIAL) return;
						if (activePageIdx === 2) {
							if (handleDetailCommercialIdx !== null) {
								setHandleDetailCommercialIdx(null);
							}
						}
						setActivePageIdx(2);
					}}
				>
					<span className="relative">
						<div>{t('LABEL.COMMERCIAL')}</div>
						<div style={{ top: '-3px', right: '-17px' }} className="flex absolute">
							{loadingCommercialOffers && <Spinner style={{ width: '15px', height: '15px' }} color={activePageIdx === 2 ? 'white' : 'red.500'} />}
							{!loadingCommercialOffers && commercialOffers !== null && commercialOffers !== undefined && commercialOffers.length !== 0 && (
								<div
									style={{ width: '15px', height: '15px' }}
									className={`flex text-xs justify-center items-center rounded-full ${activePageIdx == 2 ? 'bg-white text-color0' : 'bg-color0 text-white'}`}
								>
									{commercialOffers.length}
								</div>
							)}
						</div>
					</span>
				</li>

				<li
					className={`select-none transition-all duration-300 h-auto flex-1 px-1 gap-1 items-center py-2 flex justify-center font-bold rounded-t-md ${
						is2Cover
							? offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA
								? 'text-red-200 hover:bg-gray-200 cursor-default'
								: activePageIdx === 3
								? 'bg-color0 text-white cursor-pointer'
								: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
							: offerEvaluationPhase < OFFER_2_STEP_PHASES.COMMERCIAL
							? 'text-red-200 hover:bg-gray-200 cursor-default'
							: activePageIdx === 3
							? 'bg-color0 text-white cursor-pointer'
							: 'bg-white text-color1 hover:bg-gray-200 cursor-pointer'
					}
					`}
					onClick={() => {
						if (is2Cover && offerEvaluationPhase === OFFER_2_COVER_PHASES.PRA) return;
						if (!is2Cover && offerEvaluationPhase < OFFER_2_STEP_PHASES.COMMERCIAL) return;
						setActivePageIdx(3);
					}}
				>
					<span className="relative">
						{is2Cover ? (
							<div>{t('LABEL.EVALUATION')}</div>
						) : (
							<>
								<div>{i18n.language === 'en' ? 'Commercial' : 'Evaluasi'}</div>
								<div>{i18n.language === 'en' ? 'Evaluation' : 'Komersial'}</div>
							</>
						)}
						<div style={{ top: '-3px', right: '-20px' }} className="flex absolute">
							{evaluation && evaluation[is2Cover ? 'status' : 'statusCommercial'] === 'SUBMITTED' && <BiCheckDouble className="text-lg" color="green" />}
						</div>
					</span>
				</li>
			</ul>
			<div className="bg-color0" style={{ height: '2px' }} />
		</div>
	);
};

export default Navbar;
