import React, { useContext } from "react";
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AanwijzingContext } from "../Controller";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import FormDescription from "../../../../../../../../../components/inputForm/FormDescription";
import moment from "moment";
import { InputTextarea } from "primereact/inputtextarea";
import Datepicker from "../../../../../../../../../components/inputForm/Datepicker";

const Form = ({ isEdit }) => {
  const { t } = useTranslation();
  const { activeAanwijzing, form } = useContext(AanwijzingContext);

  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <>
      {/* Judul Aanwijzing */}
      <FormControl isRequired={isEdit}>
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.PROCUREMENT_AANWIJZING_EVENT_NAME")}
        </FormLabel>
        {isEdit ? (
          <InputText
            name="eventName"
            className={`w-full text-sm ${
              errors?.eventName && "border-2 border-red-400 shadow-none"
            }`}
            placeholder={t("LABEL.PROCUREMENT_AANWIJZING_EVENT_NAME")}
            {...register("eventName", {
              required: t("INVALID.REQUIRED", {
                field: t("LABEL.PROCUREMENT_AANWIJZING_EVENT_NAME"),
              }),
            })}
          />
        ) : (
          <FormDescription>{activeAanwijzing?.eventName}</FormDescription>
        )}
        {errors?.eventName && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.eventName?.message}
          </FormHelperText>
        )}
      </FormControl>

      {/* Aanwijzing Schedule */}
      <FormControl className="md:col-span-2">
        <div className="md:col-span-2 grid md:grid-cols-2 gap-6">
          {/* From */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_AANWIJZING_FROM")}
            </FormLabel>

            {isEdit ? (
              <Datepicker
                format="yyyy-MM-dd HH:mm:ss"
                name="aanwijzingFrom"
                placeholder={t("LABEL.PROCUREMENT_AANWIJZING_FROM")}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_AANWIJZING_FROM"),
                    }),
                  },
                }}
                isError={!!errors?.aanwijzingFrom}
              />
            ) : (
              <FormDescription>
                {activeAanwijzing.aanwijzingFrom &&
                  moment(new Date(activeAanwijzing.aanwijzingFrom)).format(
                    "LLL"
                  )}
              </FormDescription>
            )}

            {errors?.aanwijzingFrom && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.aanwijzingFrom?.message}
              </FormHelperText>
            )}
          </FormControl>

          {/* Until */}
          <FormControl isRequired={isEdit}>
            <FormLabel
              className={`uppercase font-semibold text-xs ${
                !isEdit && "text-red-500"
              }`}
            >
              {t("LABEL.PROCUREMENT_AANWIJZING_UNTIL")}
            </FormLabel>

            {isEdit ? (
              <Datepicker
                format="yyyy-MM-dd HH:mm:ss"
                name="aanwijzingUntil"
                placeholder={t("LABEL.PROCUREMENT_AANWIJZING_UNTIL")}
                control={control}
                controlProps={{
                  rules: {
                    required: t("INVALID.REQUIRED", {
                      field: t("LABEL.PROCUREMENT_AANWIJZING_UNTIL"),
                    }),
                  },
                }}
                isError={!!errors?.aanwijzingUntil}
              />
            ) : (
              <FormDescription>
                {activeAanwijzing.aanwijzingUntil &&
                  moment(new Date(activeAanwijzing.aanwijzingUntil)).format(
                    "LLL"
                  )}
              </FormDescription>
            )}

            {errors?.aanwijzingUntil && (
              <FormHelperText className="text-red-400 text-xs">
                {errors?.aanwijzingUntil?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </FormControl>

      {/* Venue */}
      <FormControl isRequired={isEdit}>
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.PROCUREMENT_AANWIJZING_VENUE")}
        </FormLabel>
        {isEdit ? (
          <InputText
            name="venue"
            className={`w-full text-sm ${
              errors?.venue && "border-2 border-red-400 shadow-none"
            }`}
            placeholder={t("LABEL.PROCUREMENT_AANWIJZING_VENUE")}
            {...register("venue", {
              required: t("INVALID.REQUIRED", {
                field: t("LABEL.PROCUREMENT_AANWIJZING_VENUE"),
              }),
            })}
          />
        ) : (
          <FormDescription>{activeAanwijzing?.venue}</FormDescription>
        )}
        {errors?.venue && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.venue?.message}
          </FormHelperText>
        )}
      </FormControl>

      {/* Notes */}
      <br />
      <FormControl isRequired={isEdit}>
        <FormLabel
          className={`uppercase font-semibold text-xs ${
            !isEdit && "text-red-500"
          }`}
        >
          {t("LABEL.NOTES")}
        </FormLabel>
        {isEdit ? (
          <InputTextarea
            className={`w-full text-sm ${
              errors?.notes && "border-2 border-red-400 shadow-none"
            }`}
            placeholder={t("LABEL.NOTES")}
            {...register("notes", {
              required: t("INVALID.REQUIRED", { field: t("LABEL.NOTES") }),
            })}
            rows={5}
            cols={30}
          />
        ) : (
          <FormDescription>{activeAanwijzing?.notes}</FormDescription>
        )}
        {errors?.notes && (
          <FormHelperText className="text-red-400 text-xs">
            {errors?.notes?.message}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Form;
