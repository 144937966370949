import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import PrivateComponent from "../../../../../../components/PrivateComponent";
import Form from "./components/Form";
import GeneralController, { GeneralContext } from "./Controller";

const General = () => {
  const { form, updateRegistration, loadingUpdate, isEdit, setEdit } = useContext(GeneralContext);
  const { t } = useTranslation();

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        <PrivateComponent accessName="UPDATE_COMPANY_PROFILE">
          <div className="md:col-span-2 flex justify-end gap-2">
            {isEdit ? (
              <>
                <Button size="xs" onClick={() => setEdit(false)}>
                  {t("LABEL.CANCEL")}
                </Button>
                <Button
                  size="xs"
                  colorScheme="teal"
                  isLoading={loadingUpdate}
                  onClick={form.handleSubmit(updateRegistration)}
                >
                  {t("LABEL.SAVE")}
                </Button>
              </>
            ) : (
              <button onClick={() => setEdit(true)}>
                <BiEdit className="text-lg text-gray-500" />
              </button>
            )}
          </div>
        </PrivateComponent>

        <Form isEdit={isEdit} />
      </div>
    </motion.div>
  );
};

export default () => (
  <GeneralController>
    <General />
  </GeneralController>
);
