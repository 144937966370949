import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../../../controllers/auth/AuthController";
import { HelperContext } from "../../../../../../../../controllers/HelperController";
import { ProcurementDetailContext } from "../../../Controller";

export const ApprovalContext = createContext();
const { Provider } = ApprovalContext;

const ApprovalController = ({ children }) => {
  const toast = useToast();
  const { token, userData } = useContext(AuthContext);
  const { showToast, httpRequest } = useContext(HelperContext);
  const { t } = useTranslation();
  const {
    procurement,
    setIsApproved,
    setIsApprovedByGm,
    procurementSchedule,
    setProcurementSchedule,
  } = useContext(ProcurementDetailContext);
  const procurementNumber = procurement?.procurementNumber;

  // -------------------------------
  // Form
  // -------------------------------

  const form = useForm();
  const resetForm = (newForm) => {
    if (newForm) {
      form.reset({
        notes: newForm?.notes,
        email: newForm?.email,
        approved_by: newForm?.approved_by,
        approved_at: newForm?.approved_at,
        created_at: newForm?.created_at,
        level: newForm?.level,
        rejected_by: newForm?.rejected_by,
        rejected_at: newForm?.rejected_at,
      });
    }
  };

  // -------------------------------
  // Data
  // -------------------------------

  // Get first Level Approval
  const [isInputFirstLevel, setIsInputFirstLevel] = useState();
  const [firstLevelTotalData, setFirstLevelTotalData] = useState(0);
  const [firstLevelLimit] = useState(10);
  const firstLevelTableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });

  const [firstLevelApprovals, setFirstLevelApprovals] = useState([]);
  const [loadingFirstLevelApprovals, setLoadingFirstLevelApprovals] =
    useState(false);
  const [reloadFirstLevelApprovals, setReloadFirstLevelApprovals] =
    useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = firstLevelTableForm.getValues();

    // Filter
    const limit = `limit=${firstLevelLimit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limit}&${page}`;

    const getFirstLevelApprovals = () => {
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurementNumber}${filter}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newApprovals = res?.data?.procurementApproval;
            const filterApproval = newApprovals
              .filter((e) => e.level === "1")
              .map((data) => {
                return data;
              });
            setFirstLevelApprovals(filterApproval);
            setFirstLevelTotalData(res?.data?.pagination?.totalData);

            // GM APPROVAL
            const isCanApprove =
              newApprovals.length === 0 || !newApprovals[0].approved_by;
            if (!isCanApprove) setIsInputFirstLevel(true);
          }
        })
        .finally(() => {
          setLoadingFirstLevelApprovals(false);
          setReloadFirstLevelApprovals(false);
        });
    };

    procurementNumber && reloadFirstLevelApprovals && getFirstLevelApprovals();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadFirstLevelApprovals]);

  // Get Second Level Approval
  const [isInputSecondLevel, setIsInputSecondLevel] = useState();
  const [secondLevelTotalData, setSecondLevelTotalData] = useState(0);
  const [secondLevelLimit] = useState(10);
  const secondLevelTableForm = useForm({
    defaultValues: {
      page: 1,
    },
  });

  const [secondLevelApprovals, setSecondLevelApprovals] = useState([]);
  const [loadingSecondLevelApprovals, setLoadingSecondLevelApprovals] =
    useState(false);
  const [reloadSecondLevelApprovals, setReloadSecondLevelApprovals] =
    useState(true);
  useEffect(() => {
    let mounted = true;
    const payload = secondLevelTableForm.getValues();

    // Filter
    const limit = `limit=${secondLevelLimit}`;
    const page = `page=${payload?.page}`;
    const filter = `?${limit}&${page}`;

    const getSecondLevelApprovals = () => {
      axios
        .get(
          `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurementNumber}${filter}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (mounted) {
            const newApprovals = res?.data?.procurementApproval;
            const filterApproval = newApprovals
              .filter((e) => e.level === "2")
              .map((data) => {
                return data;
              });
            setSecondLevelApprovals(filterApproval);
            setSecondLevelTotalData(res?.data?.pagination?.totalData);
            filterApproval.map((e) =>
              e.level === "2" && e.is_approved === true
                ? setIsInputSecondLevel(true)
                : ""
            );

            // SGM APPROVAL
            const isCanApprove =
              newApprovals.length > 0 &&
              newApprovals[0].level === "1" &&
              newApprovals[0].approved_by;
            if (!isCanApprove) setIsInputSecondLevel(true);
          }
        })
        .finally(() => {
          setLoadingSecondLevelApprovals(false);
          setReloadSecondLevelApprovals(false);
        });
    };

    procurementNumber &&
      reloadSecondLevelApprovals &&
      getSecondLevelApprovals();

    return () => {
      mounted = false;
    };
  }, [procurementNumber, reloadSecondLevelApprovals]);

  // -------------------------------
  // Create
  // -------------------------------

  const [isCreate, setCreate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [isConditionLevel, setIsConditionLevel] = useState(false);
  const [isModalApproveOpen, setModalApproveOpen] = useState(false);
  const [vendor, setVendor] = useState([]);

  const handleCreate = (isNewCreate, isFirst, isModalsApproveOpen) => {
    setCreate(isNewCreate);
    setModalApproveOpen(isModalsApproveOpen);
    setIsConditionLevel(isFirst);
    return isNewCreate && !isModalApproveOpen ? resetForm({}) : "";
  };
  const createApproval = (payload) => {
    setLoadingCreate(true);
    const newPayload = {
      approvedBy: userData.username,
      email: payload.email,
      level: isConditionLevel === true ? "1" : "2",
      notes: payload.notes,
      rejectedBy: "",
    };

    let mounted = true;
    let newBodyEmail = [{}];

    const subjectEmail = "Approval Document E-Procurement";
    const subjectEmailVendor = "Invitation Tender E-Procurement";

    newPayload.level === "1"
      ? axios
          .post(
            `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurement.procurementNumber}`,
            newPayload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (!res?.data?.success) {
              showToast("Gagal melakukan approval", { success: false });
            } else {
              setIsApprovedByGm(true);
              toast({
                description: t(res?.data?.success),
                position: "top-right",
                status: "success",
                isClosable: true,
              });
            }
            setCreate(false);
            setReloadFirstLevelApprovals(true);
            setReloadSecondLevelApprovals(true);

            // Kirim Email ke GM
            axios
              .post(
                `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurement.procurementNumber}/send-email/vendortrial3@len.co.id/${subjectEmail}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((res) => {
                toast({
                  description: t(res?.data?.success),
                  position: "top-right",
                  status: "success",
                  isClosable: true,
                });
              })
              .catch(() => {
                toast({
                  title: "System Error",
                  description: t("ERROR.undefined"),
                  position: "top-right",
                  status: "error",
                  isClosable: true,
                });
              });
          })
          .catch(() => {
            toast({
              title: "System Error",
              description: t("ERROR.undefined"),
              position: "top-right",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingCreate(false);
            setModalApproveOpen(false);
          })
      : axios
          .get(
            `${process.env.REACT_APP_TMS_API}/procurement-vendor/${procurementNumber}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (mounted) {
              const newVendor = res?.data?.procurementVendors;
              axios
                .get(
                  `${process.env.REACT_APP_TMS_API}/procurement-schedule/${procurementNumber}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((res) => {
                  const newSchedule = res?.data?.procurementSchedule;
                  newVendor?.map((vendors) => {
                    axios
                      .get(
                        `${process.env.REACT_APP_API}/user/${vendors?.vendor}`,
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        }
                      )
                      .then((res) => {
                        const newPayloads = {
                          vendor: vendors?.company?.companyName,
                          title: procurement?.title,
                          procurementNumber: procurement?.procurementNumber,
                          procurementType:
                            procurement?.procurementType?.procurementType,
                          registrationStart: newSchedule[0]?.registrationStart,
                          registrationEnd: newSchedule[0]?.registrationEnd,
                        };
                        console.log(newPayloads);
                        console.log(res?.data?.users[0]?.email);

                        newPayloads?.vendor &&
                          axios
                            .post(
                              `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurement.procurementNumber}/send-email-vendor/${res?.data?.users[0]?.email}/${subjectEmailVendor}`,
                              newPayloads,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .then((res) => {
                              console.log(t(res?.data?.success));
                            })
                            .catch(() => {
                              toast({
                                title: "System Error",
                                description: t("ERROR.undefined"),
                                position: "top-right",
                                status: "error",
                                isClosable: true,
                              });
                            });

                        // });
                      });
                  });
                  axios
                    .post(
                      `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurement.procurementNumber}`,
                      newPayload,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    )
                    .then((res) => {
                      if (res?.data?.success) {
                        setIsApproved(true);
                      }
                      toast({
                        description: t(res?.data?.success),
                        position: "top-right",
                        status: "success",
                        isClosable: true,
                      });
                      setCreate(false);
                      setReloadFirstLevelApprovals(true);
                      setReloadSecondLevelApprovals(true);

                      // console.log(res?.data?.users[0]?.email)
                      //     newBodyEmail?.map((body) => {
                      // const newPayloads = {
                      //   vendor: body?.vendor,
                      //   title: body?.title,
                      //   procurementNumber: body?.procurementNumber,
                      //   procurementType: body?.procurementType,
                      //   registrationStart: body?.registrationStart,
                      //   registrationEnd: body?.registrationEnd,
                      // };
                    })
                    .catch(() => {
                      toast({
                        title: "System Error",
                        description: t("ERROR.undefined"),
                        position: "top-right",
                        status: "error",
                        isClosable: true,
                      });
                    })
                    .finally(() => {
                      setLoadingCreate(false);
                      setModalApproveOpen(false);
                    });

                  // Kirim Email ke SGM
                  axios
                    .post(
                      `${process.env.REACT_APP_TMS_API}/procurement-approval/${procurement.procurementNumber}/send-email/vendortrial4@len.co.id/${subjectEmail}`,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    )
                    .then((res) => {
                      toast({
                        description: t(res?.data?.success),
                        position: "top-right",
                        status: "success",
                        isClosable: true,
                      });
                    })
                    .catch(() => {
                      toast({
                        title: "System Error",
                        description: t("ERROR.undefined"),
                        position: "top-right",
                        status: "error",
                        isClosable: true,
                      });
                    });
                });
            }
          });
  };

  // -------------------------------
  // Reject
  // -------------------------------

  const [loadingReject, setLoadingReject] = useState(false);
  const [rejectProcurementApproval, setRejectProcurementApproval] = useState();
  const [isModalRejectOpen, setModalRejectOpen] = useState(false);
  const handleReject = (isOpen, procurement) => {
    setModalRejectOpen(isOpen);
    setRejectProcurementApproval(procurement);
  };
  const rejectApproval = (payload) => {
    setLoadingReject(true);

    const newPayload = {
      approvedBy: "",
      rejectedBy: userData.username,
      email: payload.email,
      level: isConditionLevel === true ? "1" : "2",
      notes: payload.notes,
    };

    axios
      .post(
        `${process.env.REACT_APP_TMS_API}/procurement-approval/reject/${procurement.procurementNumber}`,
        newPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          if (newPayload.level === "1") {
            if (res?.data?.success) {
              // REJECT SCHEDULE
              procurementSchedule.shouldBeReview = true;
              setProcurementSchedule({
                ...procurementSchedule,
              });
              if (newPayload.level === "1") {
                setIsApprovedByGm(false);
              } else {
                setIsApproved(false);
              }
            }
            toast({
              description: t(res?.data?.success),
              position: "top-right",
              status: "success",
              isClosable: true,
            });
            setCreate(false);
            handleReject(false);
          }
        }
      })
      .catch(() => {
        toast({
          title: "System Error",
          description: t("ERROR.undefined"),
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingReject(false);
        setModalRejectOpen(false);
        setReloadFirstLevelApprovals(true);
        setReloadSecondLevelApprovals(true);
      });
  };

  return (
    <Provider
      value={{
        form,

        firstLevelApprovals,
        loadingFirstLevelApprovals,
        firstLevelTotalData,
        firstLevelLimit,
        firstLevelTableForm,
        isInputFirstLevel,

        secondLevelApprovals,
        loadingSecondLevelApprovals,
        secondLevelTotalData,
        secondLevelLimit,
        secondLevelTableForm,
        isInputSecondLevel,

        isCreate,
        handleCreate,
        loadingCreate,
        createApproval,
        isModalApproveOpen,
        setModalApproveOpen,

        rejectApproval,
        isModalRejectOpen,
        handleReject,
        loadingReject,
        setModalRejectOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export default ApprovalController;
