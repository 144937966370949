import { Input, InputGroup, InputRightElement, Checkbox, Button } from "@chakra-ui/react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { publicRouteSlug } from "../../../routes/publicRoutes";
import Logo from "../../../assets/img/static/logo/logo.png";
import LogoWhite from "../../../assets/img/static/logo/logo-white.png";
import MobileFooter from "../../../assets/img/static/mobile-footer.png";
import { useContext, useState } from "react";
import { AuthContext } from "../../../controllers/auth/AuthController";
import { useForm } from "react-hook-form";
import TranslationHandler from "../../../components/TranslationHandler";
import { useTranslation } from "react-i18next";
import PublicAuth from "../../../controllers/auth/PublicAuth";

const DesktopCover = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gradient-primary text-white hidden md:flex flex-col justify-between py-8 px-20">
      <div className="flex">
        <Link to={publicRouteSlug.HOME} className="py-1">
          <img src={LogoWhite} className="w-40" />
        </Link>
      </div>
      <h1 className="text-5xl lg:text-6xl font-bold montserrat break-always">{t("TEXT.SLOGAN")}</h1>
      <div />
    </div>
  );
};
const MobileHeader = () => {
  return (
    <div className="flex md:hidden p-8 montserrat font-bold text-xl">
      <div>
        <Link to={publicRouteSlug.HOME} className="py-1">
          <img src={Logo} className="w-40" />
        </Link>
      </div>
    </div>
  );
};

const Login = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { login, loginLoading, loginError } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <PublicAuth />

      <div className="w-full h-screen grid md:grid-cols-2">
        <DesktopCover />
        <div className="p-0 md:p-24 md:px-10 lg:px-32 xl:px-40 flex flex-col justify-between md:justify-center">
          <MobileHeader />
          <div className="absolute top-0 right-0 p-10 px-10 md:px-24">
            <TranslationHandler />
          </div>
          <form
            className="w-full p-12 md:p-0 flex flex-col justify-center gap-y-4 md:gap-y-5"
            onSubmit={handleSubmit((value) => login(value))}
          >
            <h2 className="font-semibold text-2xl text-gray-800">{t("LABEL.LOGIN")}</h2>
            <div className={`${!loginError && "hidden"} bg-red-200 p-4 py-3 rounded-md text-sm text-red-900`}>
              {t(loginError)}
            </div>
            <Input
              className={`bg-gray-50 ${errors?.username && "border-2 border-red-400 shadow-none"}`}
              paddingTop="1.5rem"
              paddingBottom="1.5rem"
              placeholder={t("TEXT.USERNAME_OR_EMAIL")}
              {...register("username", { required: "Username wajib diisi" })}
            />
            <InputGroup>
              <Input
                className={`bg-gray-50 ${errors?.password && "border-2 border-red-400 shadow-none"}`}
                paddingTop="1.5rem"
                paddingBottom="1.5rem"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                {...register("password", { required: "Password wajib diisi" })}
              />
              <InputRightElement className="h-full">
                <button type="button" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <BsEyeSlash /> :  <BsEye />}
                </button>
              </InputRightElement>
            </InputGroup>
            <div className="flex justify-end items-center text-sm -my-2">
              <Link to={publicRouteSlug.FORGET_PASSWORD} className="text-red-600">
                {t("TEXT.FORGET_PASSWORD")}
              </Link>
            </div>
            <Button
              color="white"
              className="transition-all duration-500 bg-gradient-primary bg-size-200 bg-pos-0 hover:bg-pos-100"
              type="submit"
              isLoading={loginLoading}
            >
              {t("LABEL.LOGIN")}
            </Button>
            <div className="text-center">
              <span>{t("TEXT.DONT_HAVE_ACCOUNT")} </span>
              <Link to={publicRouteSlug.REGISTER} className="text-red-600">
                {t("TEXT.REGISTER_NOW")}
              </Link>
            </div>
          </form>
          <div className="md:hidden">
            <img src={MobileFooter} className="w-full h-24" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
