import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import CertificateDetail from "./components/CertificateDetail";
import CertificateList from "./components/CertificateList";
import CertificateNew from "./components/CertificateNew";
import CertificateController, { CertificateContext } from "./Controller";

const Certification = () => {
  const { isDetailMenu, isCreate } = useContext(CertificateContext);

  return (
    <div>
      <AnimatePresence>
        {isCreate ? <CertificateNew /> : isDetailMenu ? <CertificateDetail /> : <CertificateList />}
      </AnimatePresence>
    </div>
  );
};

export default () => (
  <CertificateController>
    <Certification />
  </CertificateController>
);
