import { Button, Progress, Spinner } from "@chakra-ui/react";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiError } from "react-icons/bi";
import { BsFillCaretRightFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import SubmitApplicantApproval from "../../../../../components/ApplicantFirstStep/SubmitApplicantApproval";
import { AuthContext } from "../../../../../controllers/auth/AuthController";
import { userRouteSlug } from "../../../../../routes/userRoutes";
import { DashboardContext } from "../../controllers/DashboardController";

const ApplicantFirstStep = () => {
  const { userData } = useContext(AuthContext);
  const { submitApprovalFails, loadingSubmitApprovalFails } = useContext(DashboardContext);
  const { t } = useTranslation();

  // Completion Percentage
  const totalMenu = 14;
  const completionPercentage = Math.round(((totalMenu - submitApprovalFails.length) / totalMenu) * 100);

  return (
    <div>
      <div className="grid md:grid-cols-3 gap-4 md:gap-6">
        <div className="md:col-span-3 p-4 px-6 bg-gradient-primary text-white rounded-lg shadow-md">
          <p>
            <b>{t("TEXT.WELCOME_USER", { user: userData?.name })}</b> {t("TEXT.FOLLOW_STEP_BECOME_VENDOR")}
          </p>
        </div>

        <div>
          <div className="bg-white rounded-lg overflow-hidden flex gap-x-1 h-24">
            <div
              className="flex items-center px-5 pl-4 bg-gradient-primary rounded-l-none shadow-md"
              style={{ borderRadius: "30px/90px" }}
            >
              <FaUser className="text-4xl text-white " />
            </div>
            <div className="w-full px-4 flex flex-col justify-center gap-2">
              <h3 className="font-semibold text-gray-700 montserrat leading-5">{t("TEXT.COMPLETE_YOUR_PROFILE")}</h3>
              <div>
                <Progress value={completionPercentage} size="xs" colorScheme="red" />
                <p className="text-xs text-right mt-1 text-red-500 font-semibold">{completionPercentage}%</p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:col-span-2 ">
          {loadingSubmitApprovalFails ? (
            <div className="text-center">
              <Spinner color="red.500" />
            </div>
          ) : submitApprovalFails.length ? (
            <div className="bg-white rounded-lg p-2 px-4 flex flex-col gap-1 text-sm">
              {submitApprovalFails.map((fail, iItem, arrItem) => (
                <Fragment key={iItem}>
                  <Link to={`${userRouteSlug.PANEL}${userRouteSlug.COMPANY_PROFILE}${fail?.path}`} className="p-2">
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-gray-600">
                        {fail?.actionMessageI18n
                          ? t(fail?.actionMessageI18n)
                          : t("LABEL.ADD", { field: t(fail?.menuI18n) })}
                      </p>
                      <BsFillCaretRightFill style={{ fontSize: "0.7rem" }} />
                    </div>
                  </Link>
                  {iItem !== arrItem.length - 1 && <hr />}
                </Fragment>
              ))}
            </div>
          ) : (
            <SubmitApplicantApproval showCompleteDataButton />
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantFirstStep;
