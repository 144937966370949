import { FormControl, FormLabel } from "@chakra-ui/react";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import FormDescription from "../../../../../../../../components/inputForm/FormDescription";
import { ProcurementDetailContext } from "../../../Controller";

const ProcurementSettingForm = () => {
  const { t } = useTranslation();
  const { procurement } = useContext(ProcurementDetailContext);

  return (
    <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 100 }} className="bg-white rounded-md p-6">
      <div className="w-full grid md:grid-cols-2 gap-6">
        {/* Work Location */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>{t("LABEL.WORK_LOCATION")}</FormLabel>
          <FormDescription>{procurement?.procurementSetting?.workLocation}</FormDescription>
        </FormControl>

        {/* Winner Evaluation Method */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.WINNER_EVALUATION_METHOD")}
          </FormLabel>
          <FormDescription>{procurement?.procurementSetting?.winnerEvaluationMethod?.description}</FormDescription>
        </FormControl>

        {/* E-Auction */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>{t("E-AUCTION")}</FormLabel>
          <FormDescription>{t(procurement?.procurementSetting?.eAuction ? "LABEL.YES" : "LABEL.NO")}</FormDescription>
        </FormControl>

        {/* Prequalification */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.PREQUALIFICATION")}
          </FormLabel>
          <FormDescription>
            {t(procurement?.procurementSetting?.prequalification ? "LABEL.YES" : "LABEL.NO")}
          </FormDescription>
        </FormControl>

        {/* Bid Bond */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>{t("Bid Bond")}</FormLabel>
          <FormDescription>{t(procurement?.procurementSetting?.bidBond ? "LABEL.YES" : "LABEL.NO")}</FormDescription>
        </FormControl>

        {/* Bid Document Public Visibility */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>
            {t("LABEL.BID_DOCUMENT_VISIBILITY")}
          </FormLabel>
          <FormDescription>
            {t(procurement?.procurementSetting?.bidDocumentPublicVisibility ? "LABEL.PUBLIC" : "LABEL.PRIVATE")}
          </FormDescription>
        </FormControl>

        {/* Aanwijzing */}
        <FormControl>
          <FormLabel className={`uppercase font-semibold text-xs text-red-500`}>{t("Aanwijzing")}</FormLabel>
          <FormDescription>{t(procurement?.procurementSetting?.aanwijzing ? "LABEL.YES" : "LABEL.NO")}</FormDescription>
        </FormControl>
      </div>
    </motion.div>
  );
};

export default ProcurementSettingForm;
